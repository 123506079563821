import React, { useEffect, useState } from 'react'
import Modal from 'components/modal';
import parseQuery from 'helpers/parseQuery';
import { useDispatch, useSelector } from 'react-redux';
import { getRosterScheduleEmployeeList, getRosterScheduleEmployeeListWithWorkingHour, getRosterScheduleList } from 'scenes/roster/helpers/action';
import { getDatesInBetween } from 'scenes/roster/helpers/roosterDates';
import SchedulePdfContainer from './components/schedulePdfContainer';
import  {
  PDFViewer,
  StyleSheet,
} from "@react-pdf/renderer";

const styled = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
  },
});


function SchedulePdf(props) {
  const { search } = props.location;
  const query = parseQuery(search);
  const onModalClose = () => props.history.goBack();
  const [schedules, setSchedules] = useState([]);
  const [isCalled, setIsCalled] = useState(false);
  const { scheduleList, scheduleEmployeeList } = useSelector((state: any) => state.roosterReducer);
  const { companyInformation } = useSelector((state: any) => state.commonReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (scheduleList && scheduleEmployeeList) {
      const { endDate, startDate, teamId } = query;
      if (startDate && endDate) {
        const dates = getDatesInBetween(startDate, endDate, scheduleList, scheduleEmployeeList)
        setSchedules(dates)
      }
    }
  }, [scheduleList, scheduleEmployeeList]);
  useEffect(() => {
    if (query && !isCalled) {
      const { endDate, startDate, teamId } = query;
      (async () => {
        const promiseArray = [
          dispatch(getRosterScheduleList(query)),
          dispatch(getRosterScheduleEmployeeList(query)),
          dispatch(getRosterScheduleEmployeeListWithWorkingHour(query))
        ]
        await Promise.all(promiseArray);
        setIsCalled(true)
      })()

    }
  }, [query])

  if (!query) {
    return <Modal open={true} className="pdf-modal" onModalClose={onModalClose}>
      <span>No Data found for the present query</span>
    </Modal>
  }
  return (
    <Modal open={true} className="pdf-modal" onModalClose={onModalClose}>
      <PDFViewer style={styled.container}>
        <SchedulePdfContainer 
              companyInformation={companyInformation}
              schedules={schedules}
              dates={query}
          />
      </PDFViewer>
        
    </Modal>
  )
}

export default SchedulePdf