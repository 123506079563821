import React from "react";
import Table from "components/customTable";
import Pagination from "components/table/pagination";
import dayjs from "dayjs";
import Avatar from "react-avatar";
import Button from "components/button";
import {getFullURL} from "helpers/fullURL";
import {useSelector} from "react-redux";
import {DateCell, EmpCell, StatusCell, ActionCell} from "./customCell";

function AssignedEmployeeList(props) {
  const {
    data,
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    onEditClicked,
    onUnassignEmployee,
  } = props;

  const baseURL = getFullURL(useSelector, "");
  const cols = [
    {
      Header: "Employee Name",
      accessor: "EmployeeName",
      width: 140,
      Cell: (cellProps) => <EmpCell {...cellProps} baseURL={baseURL} />,
    },
    {
      Header: "Position",
      accessor: "Position",
      width: 200,
    },

    {
      Header: "Course",
      accessor: "CourseTitle",
      width: 140,
    },
    {
      Header: "Access validity",
      accessor: "AccessValidity",
      width: 140,
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: StatusCell,
      width: 140,
    },
    {
      Header: "",
      accessor: "Id",
      Cell: (cellProps) => (
        <ActionCell
          {...cellProps}
          onEditClicked={onEditClicked}
          onUnassignEmployee={onUnassignEmployee}
        />
      ),
    },
  ];
  const totalData = data && data.length > 0 ? data[0].TotalRows : 0;
  return (
    <div className="course-assign__assigned-employees">
      <Table isFixedWidth data={data ?? []} columns={cols} />

      {totalData > 0 ? (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={rowsPerPage}
          total={totalData}
        />
      ) : null}
    </div>
  );
}

export default AssignedEmployeeList;
