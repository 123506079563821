import React from 'react'
import doubleRightIcon from '../icons/angle-double-right.svg';
import doubleLeftIcon from '../icons/angle-double-left.svg';
import leftIcon from '../icons/angle-left.svg';
import reftIcon from '../icons/angle-right.svg';
import { MONTH, YEAR } from '../../constants'
import dayjs from 'dayjs';

interface PropsInterface {
    currentDate: string,
    setCurrentDate: Function,
    setTab: Function,
    tab: string,
    actions: ActionInterface
}

interface ActionInterface {
    nextYear: Function,
    prevYear: Function,
    nextMonth: Function,
    prevMonth: Function,
}

function CalendarHeader(props: PropsInterface) {
    const {
        currentDate,
        setCurrentDate,
        setTab,
        tab,
        actions
    } = props;
    let year = dayjs(currentDate).get('year');
    let month = dayjs(currentDate).format('MMM');
    let isYear = tab === YEAR;
    let isMonth = tab === MONTH;
    return (
        <div className="cal-popup__header">
            {
                isYear ? <div className="cal-popup__header-icons">
                    <CalendarIcon
                        action={actions.prevMonth}
                        icon={leftIcon} />
                </div>
                    : 
                    isMonth ? <div className=""></div> :
                        <div className="cal-popup__header-icons">
                            <CalendarIcon
                                action={actions.prevYear}
                                icon={doubleLeftIcon} />
                            <CalendarIcon
                                action={actions.prevMonth}
                                icon={leftIcon} />
                        </div>
            }
            <div className="cal-popup__header-title">
                {
                    isYear ?
                        <div className="cal-popup__head">
                            <div className="cal-popup__head-text">Choose Year</div>
                        </div>
                        // : isMonth ?
                        //     <div
                        //         className="cal-popup__head">
                        //         <div
                        //             // onClick={() => setTab(YEAR)}
                        //             className="cal-popup__head-year">Choose Month</div>
                        //     </div>
                            : <div className="cal-popup__head">
                                <div
                                    onClick={() => {setTab(YEAR)}}
                                    className="cal-popup__head-year">{year}</div>
                                <div className="cal-popup__head-text">-</div>
                                <div
                                    onClick={() => {
                                        setTab(MONTH)
                                    }}
                                    className="cal-popup__head-month">{month}</div>
                            </div>
                }
            </div>
            {
                isYear ? <div className="cal-popup__header-icons">
                    <CalendarIcon
                        action={actions.prevMonth}
                        icon={reftIcon} />
                </div>
                    : 
                    isMonth ? <div className=""></div> :
                        <div className="cal-popup__header-icons">
                            <CalendarIcon
                                action={actions.nextMonth}
                                icon={reftIcon}
                            />
                            <CalendarIcon
                                action={actions.nextYear}
                                icon={doubleRightIcon}
                            />
                        </div>}
        </div>
    )
}


const CalendarIcon = ({ icon, action }: any) => {
    return (
        <div
            onClick={() => action && action()}
            className="cal-popup__header-icon">
            <img src={icon} alt="" />
        </div>
    )
}

export default CalendarHeader