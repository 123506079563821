import React from "react";
import Icons from "components/icons";
import FormGroup from "components/form";

function AssessIDTable(props) {
  const {handleFieldChange, onClose, rowData, errors} = props;

  return (
    <div>
      <div className="flex flex-gap-6 mb-6 mt-6 genericForm-group__label">
        <span style={{width: "250px"}}>Item Name</span>
      </div>
      {rowData &&
        rowData.map((row) => {
          return (
            <div index={row.Id} className="flex mb-12 items-center">
              <FormGroup
                error={errors?.Name}
                loadingType="skeleton"
                onChange={(name, value) => handleFieldChange(name, value, row)}
                name="Name"
                value={row.Name}
                width="500px"
              />
              <span className="new-close-icon" onClick={() => onClose(row)}>
                <Icons name="Close" color="#465060" />
              </span>
            </div>
          );
        })}
    </div>
  );
}

export default AssessIDTable;
