import React, {Component} from "react";
import Table from "./table";
import Filters from "./filters";
import "./style.scss";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "isEmpty";
import {getUnitList} from "./action";
import {searchAttendance, generateAttendance} from "../attendanceRegister/action";
import {getFiscalYear, selectedPayrollPeriods} from "../action";
export class AttendanceRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: null,
      activeBranch: null,
      activeDepartment: null,
      code: null,
      unit: null,
      type: null,
      jobStatus: null,
      activePosition: null,
      rowsPerPage: 10,
      pageIndex: 0,
      initialRender: true,
    };
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }

  handleRowChange(num) {
    this.setState({rowsPerPage: num}, () => {
      this.onFilter();
    });
  }
  handleIndexChange(num) {
    this.setState({pageIndex: num}, () => {
      this.onFilter();
    });
  }
  handleAutoSelect = (name, selected) => {
    this.setState({[name]: selected, pageIndex: 0});
  };

  onFilter = () => {
    const {
      employee,
      activeBranch,
      code,
      activeDepartment,
      activePosition,
      jobStatus,
      type,
      unit,
      pageIndex,
      rowsPerPage,
    } = this.state;
    const {selectedPayPeriod} = this.props.selectReducer;
    if (selectedPayPeriod) {
      const searchedData = {
        payrollPeriodId: parseInt(selectedPayPeriod.Value),
        EmployeeId: !isEmpty(employee) ? employee.value : 0,
        branchIds: !isEmpty(activeBranch) ? activeBranch.value : "",
        departmentId: !isEmpty(activeDepartment) ? activeDepartment.value : 0,
        jobStatusIds: !isEmpty(jobStatus) ? jobStatus.value : "",
        levelId: 0,
        positionId: !isEmpty(activePosition) ? activePosition.value : 0,
        empStateId: 0,
        subDepartmentId: 0,
        locationId: 0,
        PageIndex: pageIndex,
        PageSize: rowsPerPage,
      };

      this.props.searchAttendance(searchedData);
    }
  };
  async componentDidUpdate(prevProps) {
    const {fiscalPeriodChanged, changeFiscalPeriod} = this.props;
    if (fiscalPeriodChanged && fiscalPeriodChanged !== prevProps.fiscalPeriodChanged) {
      await this.onFilter();
      changeFiscalPeriod();
    }
  }

  render() {
    const {pageIndex, rowsPerPage, initialRender} = this.state;
    let {selectedPayPeriod} = this.props.selectReducer;

    if (!isEmpty(selectedPayPeriod) && initialRender) {
      this.onFilter();
      this.setState({initialRender: false});
    }
    return (
      <div className="payroll-body">
        <div label="Common parameters" name="Common parameters">
          <Filters
            pageIndex={pageIndex}
            handleRowChange={this.handleRowChange}
            handleIndexChange={this.handleIndexChange}
            handleAutoSelect={this.handleAutoSelect}
            rowsPerPage={rowsPerPage}
            onFilter={this.onFilter}
            parentState={this.state}
          />
        </div>
        <div className="panel blue space-between payroll-panel">
          <div className="flex">
            <h4>Attendance Register</h4>
          </div>
          <div>
            <a
              href="\newhr\LeaveRequestList.aspx"
              target="_blank"
              className="btn btn-green"
            >
              Assign Leave
            </a>
          </div>
        </div>
        <Table
          pageIndex={pageIndex}
          rowsPerPage={rowsPerPage}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          handleAutoSelect={this.handleAutoSelect}
        />
      </div>
    );
  }
}

AttendanceRegister.propTypes = {
  searchLeave: PropTypes.func.isRequired,
  leaveReducer: PropTypes.object.isRequired,
  selectReducer: PropTypes.object.isRequired,
  searchAttendance: PropTypes.object.isRequired,
  generateAttendance: PropTypes.func.isRequired,
  payrollAttendanceReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveReducer: state.leaveReducer,
  selectReducer: state.selectReducer,
  payrollAttendanceReducer: state.payrollAttendanceReducer,
  employeeListSelect: state.commonReducer.employeeListSelect,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  unitlist: state.payrollAttendanceReducer.unitlist,
  statusList: state.commonReducer.jobStatusList,
});

export default connect(mapStateToProps, {
  getFiscalYear,
  searchAttendance,
  generateAttendance,
  getUnitList,
  selectedPayrollPeriods,
})(AttendanceRegister);
