import React, {useRef} from "react";
import SvgIcons from "components/icons/svgIcons";
import {FaTimes} from "react-icons/fa";
import {objectToFormData} from "object-to-formdata";
import {checkExt, validateFile} from "src/components/form/uploadInput/utils";
import {validApplicationExtension, validImageExtension} from "constants/validExtensions";
import classnames from "classnames";
import {UploadFiles} from "store/actions/common";
import {connect} from "react-redux";
import FileBlock from "./fileBlock";

const UploadInput = (props) => {
  const {
    name,
    disabled,
    isMultiple,
    maxFiles,
    maxSizeInMb,
    uploadSize,
    uploadTitle,
    isValidUser,
    value,
  } = props;

  const onDelete = (index) => {
    let newValue = [...value];
    newValue.splice(index, 1);
    props.onChange && props.onChange(name, newValue);
  };
  const ref: any = useRef();
  const uploadToServer = async (newFiles) => {
    const formData = objectToFormData({});

    for (var i = 0; i < newFiles.length; i++) {
      formData.append("files", newFiles[i]);
    }
    let response: any = await props.UploadFiles(formData);

    //files.push(response);
    const newValue = [...value, ...response];

    props.onChange && props.onChange(name, newValue);
  };

  const handleChange = async (event) => {
    // if (!showUploadSection()) return;
    const existingFile = value.map((file) => file.UserFileName);

    const {files} = event.target;
    let newFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!existingFile.includes(file.name))
        newFiles.push(
          Object.assign(file, {
            UserFileName: file.name,
            ServerFileName: file.name,
          })
        );
    }
    if (newFiles.length > 0) {
      let totalFiles = newFiles.length + existingFile.length;
      if (totalFiles > maxFiles) {
        // setError(`Max file number is ${maxFiles}`);
        return;
      }
      let isValid = true;
      for (let i = 0; i < newFiles.length; i++) {
        // isValid = validateFile(files[i], props, setError);
        // if (!isValid) break;
      }
      if (isValid) {
        // setError("");
        await uploadToServer(newFiles);
        ref.current.value = "";
      }
    }
  };

  return (
    <div className="attachments">
      <FileBlock files={value} onDelete={onDelete} isValidUser={isValidUser} />
      {isValidUser() && (
        <div className="attachments-item attachments-item-btn">
          <input
            ref={ref}
            style={{
              height: "100%", // uploadSize ? "100%" : height + "px",
            }}
            className={classnames({
              "genericForm-group__upload-input": true,
              "disable-input": disabled,
            })}
            type="file"
            multiple={isMultiple}
            name={name}
            disabled={disabled}
            onChange={(event) => handleChange && handleChange(event)}
          />
          <span className="item-label">Add an attachment </span>
        </div>
      )}
    </div>
  );
};

UploadInput.defaultProps = {
  validExtensions: [...validImageExtension, ...validApplicationExtension],
  minSizeInKb: 1,
  maxSizeInMb: 3,
  disabled: false,
  isMultiple: false,
  maxFiles: 5,
  uploadTitle: "Browse and Upload Documents",
  uploadSize: null, //{ width: "100px", height: "100px" },
  isCropImage: false,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {UploadFiles};

export default connect(mapStateToProps, mapDispatchToProps)(UploadInput);
