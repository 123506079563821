import React, { useState, useEffect } from "react";
import { IoMdArrowDropdownCircle, IoMdAdd } from "react-icons/io";
import classnames from "classnames";
import ColumnSelectPopup from "../../columnSelectPopup";
import CustomPopup from "components/common/customPopup";

const Header = (props) => {
  const {
    board,
    title,
    theme,
    cols,
    setCols,
    expand,
    setExpand,
    onColumnChange,
    setOpenCustomFiled,
    stage
  } = props;
  const { BoardId } = board || 0

  const iconClass = classnames({
    "taskBlock-title__icon": true,
    "taskBlock-title__icon-collapsed": !expand,
  });
  const taskBlockClass = classnames({
    taskBlock: true,
    ["taskBlock-" + theme]: true,
  });
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  const getColumnClassName = (column: any) => {
    let name = "taskBlock-title-section";
    if (column.className) {
      // name = `${name} ${column.className}`;
    }
    return name;
  };

  return (
    <thead>
      {/* {columns[0].cellRenderer(props)} */}
      <tr>
        <th>
          <div className="taskBlock-header">
            <div onClick={() => setExpand(!expand)} className="taskBlock-title">
              <div className={iconClass}>
                <IoMdArrowDropdownCircle />
              </div>
              <h3>{title}</h3>
            </div>
          </div>
        </th>

        {cols.map((col, index) =>
          col.hide === true ? null : (
            <th key={index}>
              <div className="taskBlock-title-section">
                <span className="taskBlock-section__title">
                  {col.headerName}
                </span>
              </div>
            </th>
          )
        )}

        <th ref={ref}>
          <span
            onClick={(e) => setOpen(!open)}
            className={classnames({
              "taskBlock-section__icon": true,
              "taskBlock-section__icon-open": open,
            })}
          >
            <IoMdAdd />
          </span>

          {open && ref && ref.current && (
            <CustomPopup
              parentRef={ref}
              open={open}
              handleOutsideClick={() => setOpen(false)}
            >
              <ColumnSelectPopup
                boardId={BoardId}
                stage={stage}
                cols={cols}
                setOpenCustomFiled={setOpenCustomFiled}
              />
            </CustomPopup>
          )}
        </th>
      </tr>
    </thead>
  );
};

export default Header;
