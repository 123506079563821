import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";

import Title from "../../common/title";
import {useSelector} from "react-redux";
import {checkShowPayslip} from "scenes/common/header/userNavTab";

const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Leave/Attendance Reports",
    feature: features?.LeaveAndTime,
    links: [
      {
        title: "Yearly Leave Report",
        link: "Employee/yearleavereport.aspx",
        isReact: false,
      },
      {
        title: "Leave Summary",
        link: "Employee/leavesummary.aspx",
        isReact: false,
      },
      {
        title: "Attendance Details",
        link: "Employee/attendancedetails.aspx",
        isReact: false,
      },
      {
        title: "Team Attendance",
        link: "Employee/otheremployeeattendance.aspx",
        isReact: false,
      },
      {
        title: "Tax Calulation",
        link: "Employee/TaxDetails.aspx",
        isReact: false,
        showPayslip: checkShowPayslip(EmpDashboardShowPayslip),
      },
    ],
  },
];

function Reports(props) {
  return (
    <div className="employee-reports">
      <div style={{padding: "10px 25px 0"}} className="">
        <Title name="Reports" />
      </div>
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Employee Reports"
        localStorageTitle="ERT"
        modules={modules}
      />
    </div>
  );
}

export default Reports;
