import React, {Component} from "react";
import "./style.scss";
import {connect} from "react-redux";
import {MdAdd} from "react-icons/md";
import Title from "../../common/title";
import DisciplinaryTabs from "./disciplinaryTabs";
import SearchAndFilter from "./searchAndFilter";
import {GetDesignations, getLevels} from "actions/commonAction";
import {GetDisciplinaryStages} from "./action";
import {
  GetDisciplinaryActionList,
  MoveDisciplinaryActionToNextStage,
  DeleteDisciplinaryCase,
  getDisciplinaryCaseDetails,
} from "./action";
import DisciplinaryCaseForm from "./form/disciplinaryCaseForm";
import isEmpty from "isEmpty";
import Button from "components/button";
import Icons from "components/icons";
import moment from "moment";
import Modal from "components/modal";

export class DisciplinaryAction extends Component {
  constructor(props) {
    var curr = new Date();
    super(props);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchEmployee: "",
      searchActionStatement: "",
      fromDate: null,
      toDate: null,
      activeJobRole: null,
      activePosition: null,
      activeStageId: 5,
      showCreateDispCaseForm: false,
      isEditPage: false,
      disciplinaryStages: [],
      date: {
        startDate: "",
        endDate: "",
      },
    };
  }

  async componentDidMount() {
    await this.props.GetDisciplinaryStages();
    await this.getDisciplinaryActionList();
    await this.props.GetDesignations();
    await this.props.getLevels();
    const {levelList} = this.props;
    let levelLists =
      levelList &&
      levelList.map((lvl) => {
        return {
          label: lvl.Text,
          value: lvl.Value,
        };
      });
    this.setState({levelLists});
  }
  componentDidUpdate(prevProps, prevState) {
    const {disciplinaryStages} = this.props;

    if (prevProps.disciplinaryStages !== disciplinaryStages) {
      this.setState({disciplinaryStages});
    }
  }

  getDisciplinaryActionList = async () => {
    this.setState({isLoading: true});
    const {
      pageIndex,
      rowsPerPage,
      activeStageId,
      searchEmployee,
      searchActionStatement,
      fromDate,
      toDate,
      activeJobRole,
      activePosition,
      date,
    } = this.state;

    const data = {
      SearchEmployee: !isEmpty(searchEmployee) ? searchEmployee?.EmployeeId : "",
      CaseTitle: searchActionStatement,
      LevelId: !isEmpty(activeJobRole) ? activeJobRole.value : -1,
      PositionId: !isEmpty(activePosition) ? activePosition.value : -1,
      ReportedDateFrom: /*date.startDate*/ fromDate,
      ReportedDateTo: /*date.endDate*/ toDate,
      StageId: activeStageId,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };

    await this.props.GetDisciplinaryActionList(data);
    this.setState({isLoading: false});
  };

  handleMoveToNextStage = async ({
    disciplinaryCaseId = 0,
    stageId = null,
    subStageId = null,
  }) => {
    const {activeStageId} = this.state;
    const data = {
      DisciplinaryCaseId: disciplinaryCaseId,
      ActionSubStageRefId: !isEmpty(stageId) ? stageId : activeStageId,
      ActionSubStageRef_Id: subStageId,
    };

    await this.props.MoveDisciplinaryActionToNextStage(data, this.props);
  };

  handleEdit = (disciplinaryCaseId) => {
    this.props.getDisciplinaryCaseDetails(disciplinaryCaseId);
    this.setState({...this.state, showCreateDispCaseForm: true, isEditPage: true});
  };

  handleDelete = async (DisciplinaryCaseId) => {
    await this.props.DeleteDisciplinaryCase(
      DisciplinaryCaseId,
      this.getDisciplinaryActionList
    );
    //await this.getDisciplinaryActionList();
  };

  handleInput = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value, pageIndex: 0}, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleDateRangePicker = (startDate, endDate) => {
    // console.log({startDate, endDate});
    this.setState({fromDate: startDate, toDate: endDate, pageIndex: 0}, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleStageChange = (stageId) => {
    this.setState({activeStageId: stageId, pageIndex: 0}, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleAutoSelect = (name, selected) => {
    this.setState({[name]: selected, pageIndex: 0}, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleRowChange = (num) => {
    this.setState({rowsPerPage: num}, async () => {
      await this.getDisciplinaryActionList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({pageIndex: num}, async () => {
      await this.getDisciplinaryActionList();
    });
  };
  handleFormDisplay = (value) => {
    this.setState({showCreateDispCaseForm: value});
    document.body.style.overflow = value ? "hidden" : "visible";
  };
  setDate = (value) => {
    this.setState(
      {
        date: value,
      },
      async () => {
        await this.getDisciplinaryActionList();
      }
    );
  };
  handleDateRangeChange = (range) => {
    this.setState({fromDate: range.fromDate, toDate: range.toDate}, async () => {
      await this.getDisciplinaryActionList();
    });
  };
  handleDateSelectForMultiple = (range) => {
    if (isEmpty(range.startDate) && isEmpty(range.endDate)) {
      this.handleDateRangeChange({fromDate: null, toDate: null});
      this.setState({fromDate: "", toDate: ""});
    } else {
      let startDate = moment(new Date(range.startDate), "YYYYMMDDHHmmss")
        .startOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      let endDate = moment(new Date(range.endDate), "YYYYMMDDHHmmss")
        .endOf("day")
        .format("YYYY/MM/DD HH:mm:ss");
      this.setState({fromDate: startDate, toDate: endDate});
      this.handleDateRangeChange({fromDate: startDate, toDate: endDate});
    }
  };
  render() {
    const {
      employeeListForAdmin,
      disciplinaryList,
      designationList,
      history,
      disciplinaryCaseDetails,
    } = this.props;
    //console.log(disciplinaryStages);
    let height = this.state.showCreateDispCaseForm ? 0 : "auto";
    const {showCreateDispCaseForm, isEditPage, disciplinaryStages, levelLists, date} =
      this.state;
    return (
      <div className="common-container" style={{height: height}}>
        <Title name="Disciplinary Action" parentProps={this.props} />

        <div className="add" style={{width: 250}}>
          <Button
            onClick={() => {
              this.setState({isEditPage: false});
              this.handleFormDisplay(true);
            }}
            buttonType="icon-button"
            type="button"
            bg="white"
            icon={<Icons name="Add" />}
          >
            Add Disciplinary Action
          </Button>
        </div>

        <SearchAndFilter
          handleAutoSelect={this.handleAutoSelect}
          handleInput={this.handleInput}
          handleDateRangePicker={this.handleDateRangePicker}
          parentState={this.state}
          designationList={designationList}
          levelList={levelLists}
          setDate={this.setDate}
          date={date}
          handleDateSelectForMultiple={this.handleDateSelectForMultiple}
        />
        {!isEmpty(disciplinaryStages) ? (
          <DisciplinaryTabs
            handleIndexChange={this.handleIndexChange}
            handleRowChange={this.handleRowChange}
            handleStageChange={this.handleStageChange}
            handleMoveToNextStage={this.handleMoveToNextStage}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            parentState={this.state}
            disciplinaryList={disciplinaryList}
            disciplinaryStages={disciplinaryStages}
          />
        ) : null}

        {showCreateDispCaseForm ? (
          <Modal
            open={showCreateDispCaseForm}
            onModalClose={() => this.handleFormDisplay(false)}
          >
            <DisciplinaryCaseForm
              formExists={true}
              getDisciplinaryActionList={this.getDisciplinaryActionList}
              handleFormDisplay={this.handleFormDisplay}
              history={history}
              isEditPage={isEditPage}
              isFormLoading={disciplinaryCaseDetails.loading}
              showForm={showCreateDispCaseForm}
              title="New Disciplinary Case"
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  designationList: state.commonReducer.designationList,
  levelList: state.commonReducer.levelList,
  disciplinaryStages: state.disciplinaryActionReducer.disciplinaryStages,
  disciplinaryList: state.disciplinaryActionReducer.disciplinaryList,
  disciplinaryCaseDetails: state.disciplinaryActionReducer.disciplinaryCaseDetails,
});

const mapDispatchToProps = {
  GetDesignations,
  getLevels,
  GetDisciplinaryStages,
  GetDisciplinaryActionList,
  MoveDisciplinaryActionToNextStage,
  DeleteDisciplinaryCase,
  getDisciplinaryCaseDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisciplinaryAction);
