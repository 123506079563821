import React from "react";
import Table from "./table";
import Modal from "components/modal";
import Loader from "components/loaders";
import TravelCategoryForm from "./travelCategoryForm";
// import "./AddExpenseAllowance.scss";
import {
  addTravelCategory,
  getTravelCategory,
  editTravelCategory,
  deleteTravelCategory,
  getAllowanceExpense,
  getLevels,
} from "./actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "./travelCategory.scss";

class Travel_Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      rowsPerPage: 10,
      pageIndex: 0,
      modalState: {show: false, type: "normal"},
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleConfirmDelRecord = this.handleConfirmDelRecord.bind(this);
  }
  componentDidMount = () => {
    this.props.getTravelCategory();
    this.props.getAllowanceExpense();
    this.props.getLevels();
  };

  closeModal = () => {
    // this.handleModalShow({show: false, type: "normal"});
    this.handleModalShow(false, "normal");
  };
  submitForm = async (formData, allowanceExpenseRate) => {
    const data = {
      TravelCategoryRate: {
        Categoryid: formData.CategoryId,
        Name: formData.CategoryName,
        Description: formData.Description,
        Type: formData.TravelCategoryType,
        TenantId: formData.TenantId,
      },
      TravelCategoryAllowanceRate: allowanceExpenseRate,
    };
    //console.log(data)
    const isEdit = formData.CategoryId != 0 ? true : false;
    isEdit
      ? await this.props.editTravelCategory(data, this.closeModal)
      : await this.props.addTravelCategory(data, this.closeModal);
  };
  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };

  handleDelRecord = (args) => {
    this.props.deleteTravelCategory(this.state.selectedId);
  };
  handleModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };
  render() {
    const {spinnerLoading} = this.props.travelCategoryReducer;
    const {modalState} = this.state;
    return (
      <div className="travel-category">
        <Table
          pageIndex={this.state.pageIndex}
          rowsPerPage={this.state.rowsPerPage}
          handleRowChange={null}
          handleIndexChange={null}
          handleConfirmDelRecord={this.handleConfirmDelRecord}
          handleModalShow={this.handleModalShow}
        />
        {modalState.show ? (
          <Modal
            title={"Travel category and rates"}
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={() => this.handleDelRecord()}
            className="modal-travel-category"
          >
            <TravelCategoryForm
              onSubmit={this.submitForm}
              handleModalShow={this.handleModalShow}
            />
          </Modal>
        ) : (
          ""
        )}
        <Loader open={spinnerLoading} />
      </div>
    );
  }
}

Travel_Categories.propTypes = {
  addTravelCategory: PropTypes.func.isRequired,
  getTravelCategory: PropTypes.func.isRequired,
  editTravelCategory: PropTypes.func.isRequired,
  travelCategoryReducer: PropTypes.object.isRequired,
  modalReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  travelCategoryReducer: state.travelCategoryReducer,
});

export default connect(mapStateToProps, {
  addTravelCategory,
  getTravelCategory,
  editTravelCategory,
  deleteTravelCategory,
  getAllowanceExpense,
  getLevels,
})(Travel_Categories);
