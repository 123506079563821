import React, { useState } from "react";
import SvgIcons from "components/icons/svgIcons";
import { FaTimes } from "react-icons/fa";
import Modal from "components/modal";
import { checkExtension } from "helpers/checkExtension";
import { fileLocation, hostedUrl } from "constants/urls";
import ImageViewer from "components/imageViewer";
import { useDispatch, useSelector } from "react-redux";
import { Apis } from "src/config/api";
import getFullStringUrl from "helpers/getFullStringUrl";
import { DeleteFiles } from "store/actions/common";
import classNames from "classnames";
import isEmpty from "helpers/isEmpty";

const AttachmentBlock = (props) => {
  const { file, handleClick, index, onRemove, showRemoveButton, isUploading } = props
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  let { baseUrl } = useSelector((state: any) => ({
    baseUrl: state.auth.baseUrl,
  }));
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";

  const getImage = () => {
    const { isDocs, isImage, isPdf } = checkExtension(file.ServerFileName);

    let icon = isDocs ? (
      <SvgIcons name="document" size="20" />
    ) : isPdf ? (
      <SvgIcons name="pdf" size="20" />
    ) : isImage ? (
      <img
        src={`${base}/${fileLocation}/${file.ServerFileName}`}
        width="20"
        height="20"
      />
    ) : (
      <SvgIcons name="fileInvoice" size="20" />
    );
    return icon;
  };

  const getDownloadLink = () => {
    const param = {
      serverFileName: file.ServerFileName,
      userFileName: file.UserFileName,
      fileType: file.FileType,
    };
    let url = getFullStringUrl(
      `${hostedUrl}${Apis.common.downloadFiles}`,
      param
    );
    return url;
  };
  const handleRemove = async (e) => {
    e.stopPropagation();
    let res: any = await dispatch(DeleteFiles([file.ServerFileName], false))
    if (res)
      onRemove && onRemove(index);

  }
  const uploadingProgress = () => {
    const AttachmentID = file.AttachmentID || 0
    return isUploading && AttachmentID === 0
  }

  const render = () => {
    let el = <div
      key={index}
      className="comment-editor-attachments-item "
      title={file.UserFileName}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

      {getImage()}

      <span className="item-label">{file.UserFileName}</span>


      <div className={classNames({ "btn-remove": showRemoveButton && !uploadingProgress(), "btn-loading": uploadingProgress() })}>
        <FaTimes
          style={{ margin: "0px" }}
          size="12"
          color="#fff"
          onClick={handleRemove}
        />
      </div>

    </div>
    if (!showRemoveButton) {
      return <a href={getDownloadLink()} target="_blank">{el}</a>
    }
    return el;
  }
  return render()
}
AttachmentBlock.defaultProps = {
  showRemoveButton: true
}
export default AttachmentBlock;