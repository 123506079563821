import React from "react";
import Incomes from "./income";
import Deduction from "./deduction";
import "./styles.scss";

function Payroll(props) {
  return (
    <div className="emp-payroll">
      <div className="emp-payroll__container">
        <Incomes {...props} />
        <Deduction {...props} />
      </div>
    </div>
  );
}

export default Payroll;
