import React, {useState} from "react";
import Icons from "components/icons";
import CommonCommentSection from "components/common/commonCommentSection";
import EmployeeDetails from "scenes/common/employeeDetails";
import {FaMinusCircle} from "react-icons/fa";
import OverTimeRequestForm from "scenes/employee/overtimeRequest/index";
import Modal from "components/modal";
import {formatedMonthDayYear} from "constants/dateFormat";
import moment from "moment";

interface IAppProps {
  empRequest?: any;
  comments: any[];
  errors: any;
  saveComment: Function;
  history?: any;
  handleApproveRequest: Function;
  handleRejectRequest?: any;
  isViewMode?: any;
  refreshRequestData?: any;
  requestData?: any;
  isDateNepali?: boolean;
}

const MyOvertimeRequest: React.FunctionComponent<IAppProps> = (props) => {
  const {
    empRequest,
    comments,
    errors,
    saveComment,
    handleApproveRequest,
    handleRejectRequest,
    isViewMode,
    refreshRequestData,
    requestData,
    isDateNepali,
  } = props;
  const [commentData, setCommentData] = useState("");
  const [isOverTimeRequestFormShown, showOverTimeRequestFrom] = useState(false);

  return (
    <div className="my-overtime-request">
      <div className="my-overtime-request__header">
        <div className="request-type">{empRequest.OvertimeType}</div>
        <div className="mt-sm">
          {empRequest &&
            empRequest.OvertimeRequests &&
            empRequest.OvertimeRequests.map((ot) => (
              <div className="flex detailSection">
                <span className="wd-40 white-space-nowrap">
                  {isDateNepali
                    ? ot.RequestDate
                    : formatedMonthDayYear(ot.RequestDateEng)}
                </span>
                <span className="wd-30 mr-sm align-center">
                  {ot.InTimeDv ? `${ot.InTimeDv}` : " - "}
                  {ot.OutTimeDv ? ` - ${ot.OutTimeDv}` : ""}
                </span>
                <span className="wd-30 mr-sm align-center">
                  {ot.StartTime} - {ot.EndTime}
                </span>
                <span className="wd-20">
                  {Math.floor(parseInt(ot.RequestedMin) / 60) < 9
                    ? "0" + Math.floor(parseInt(ot.RequestedMin) / 60)
                    : Math.floor(parseInt(ot.RequestedMin) / 60)}
                  :
                  {parseInt(ot.RequestedMin) % 60 < 9
                    ? "0" + (parseInt(ot.RequestedMin) % 60)
                    : parseInt(ot.RequestedMin) % 60}{" "}
                  hrs
                </span>
                <span className="wd-40 reason">{ot.Reason}</span>
              </div>
            ))}
        </div>
        <div className="flex mt-md">
          <span className="requestedOn mr-sm">Requested On</span>
          <span className="wd-30 mr-bg">
            {isDateNepali
              ? empRequest.RequestedOn
              : moment(new Date(empRequest.RequestedOnEng), "YYYYMMDDHHmmss").format(
                  "MMM DD, YYYY hh:mm A"
                )}
          </span>
          {/* {empRequest.RecommendedStatus === "Approved" ? (
            <span className="emp-name text-green flex mr-md">
            <Icons name="Check" className="checkmark-align" />
            <span>{empRequest.Reccommender}</span>
            </span>) :
            <span className="mr-md">{empRequest.Reccommender}</span>
          }
            {empRequest.ApprovalStatus === "Approved" ? (
          <span className="emp-name ml-md text-green flex">
            <Icons name="Check" className="checkmark-align" />
            <span>{empRequest.Approval}</span>
          </span>): 
            <span>{empRequest.Approval}</span>
          } */}
        </div>
      </div>
      <div className="my-overtime-request__body flex-column">
        <div className="approvers flex mt-sm">
          <span className="mr-md">Approvers</span>
          <div className="approver__name flex">
            {empRequest?.approvers?.map((item) => {
              return <EmployeeDetails empDetails={item} size={30} showApprovalStatus />;
            })}
          </div>
        </div>
        <div className="mt-md flex">
          <CommonCommentSection
            submitComment={saveComment}
            comments={comments}
            submitErrors={errors}
            isViewMode={isViewMode}
            setCommentData={(data) => setCommentData(data)}
          />
        </div>
        {!isViewMode && (
          <div className="flex mt-md justify-between">
            <div className="flex library flex-gap-10">
              <button
                onClick={() => {
                  handleRejectRequest(commentData);
                }}
                className="button with-icon button-white"
              >
                <FaMinusCircle />
                <span>Deny</span>
              </button>

              <button
                className="button with-icon button-white"
                onClick={() => {
                  showOverTimeRequestFrom(true);
                }}
              >
                <Icons name="Edit" className="flex" />
                <span>Edit</span>
              </button>
            </div>
            <button
              className="button with-icon button-approve"
              onClick={() => handleApproveRequest(commentData)}
            >
              <Icons name="Check" className="flex" />
              <span>Approve</span>
            </button>
          </div>
        )}
      </div>
      {isOverTimeRequestFormShown && (
        <Modal
          title="Overtime Request"
          open={isOverTimeRequestFormShown}
          onModalClose={() => showOverTimeRequestFrom(false)}
          type=""
          className="time-request-modal over-time"
        >
          <OverTimeRequestForm
            overtimeRequestId={empRequest}
            onModalClose={() => showOverTimeRequestFrom(false)}
            editMode={true}
            isApprover={true}
            refreshRequestData={refreshRequestData}
            requestData={requestData}
          />
        </Modal>
      )}
    </div>
  );
};

export default MyOvertimeRequest;
