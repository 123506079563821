import React, { useEffect, useRef, useState } from 'react'
import useEventListener from '../../helpers/useEventListener';
import CalendarPopup from './calendarPopup';
import dayjs from 'dayjs';
import './styles.scss';
import { checkDateError } from '../../helpers/checkCalendarError';
import classnames from 'classnames';
import {CustomCalendarProps,SettingsProps} from './calendarProps';

function Calendar(props:CustomCalendarProps) {
    const {
        placeholder,
        format='YYYY-MM-DD',
        onChange,
        isSaveOnButton = true,
        value=null,
        custom = null,
        name,
        isDateRange=false,
        valueType="single"
    } = props;
    const isSingle = valueType === 'single';
    const [error,setError] = useState("");
    const [currentDate, setCurrentDate] = useState(dayjs().format(format));
    let today = dayjs().format(format);
    const [open,setOpen] = useState(false);
    const [val,setValue] = useState<any>(null);
    const dateInputRef:any = useRef(null); 
    const dateRef:any = useRef(null); 
    useEventListener('click',(e:any)=>{
        if(dateRef.current){
            if(dateRef.current.contains(e.target) || e.target.className === 'month-name' || e.target.className === 'year-name'){
                return;
            }
            setOpen(false);
        }
    });
    const onDateClick = async (date:any) => {
        if(isSaveOnButton){
            setCurrentDate(isSingle ? date : date.start_date);
            setValue(date)
        }else{
            await setCurrentDate(isSingle ? date : date.start_date);
            await setValue(date)
            onOk()
        }
    }
    const onOk = () =>{
        onChange && val && onChange(name,val)
        setOpen(false)
    }
    const err = checkDateError(value,valueType);
    useEffect(()=>{
        if(!err){
            setCurrentDate(isSingle ? value : value.start_date);
            setValue(value)
        }
    },[open,value])
    useEffect(()=>{
        setError(err);
    },[value])
    let settings:SettingsProps = {
        ref:dateInputRef,
        setOpen,
        value:val,
        isSingle
    }
    return (
        <div 
            ref={dateRef}
            className='cal'>
            {custom ? custom(props,settings) : <div 
                ref={dateInputRef}
                onClick={()=>setOpen(prev=>!prev)}
                className={classnames("cal-input",{"cal-input__error":error})}>
                <div className="cal-input__container">
                    <div className="cal-input__value">
                        {(isSingle ? val : val?.start_date + ' - ' + val?.end_date) || placeholder || 'Select a Date'}
                    </div>
                </div>
            </div>}
            {error && <div className="cal-input__error-text">{error}</div>}
            <CalendarPopup 
                open={open}
                currentDate={currentDate}
                today={today}
                onDateClick={onDateClick}
                setCurrentDate={setCurrentDate}
                value={val}
                format={format}
                onOk={onOk}
                valueType={valueType}
                isSaveOnButton={isSaveOnButton}
            />
        </div>
    )
}

export default Calendar