import React from "react";
import "./styles.scss";
import {FaUserFriends, FaYoutube, FaLock} from "react-icons/fa";
import {BsClock} from "react-icons/bs";
import {MdCheckCircle, MdPlayCircleFilled} from "react-icons/md";
import {BiArrowToRight} from "react-icons/bi";
import classnames from "classnames";
import {useHistory} from "react-router";
import RouteEnum from "constants/routeEnum";
import {useSelector} from "react-redux";
import PageList from "./pageList";

function Sidebar(props) {
  const {assignedId} = props;
  const {
    coursePages,
    answers: prevAnswers,
    coursePage,
    course,
  } = useSelector((state: any) => state.lmsReducer);
  const history = useHistory();

  return (
    <div className="course-sidebar">
      {/* <div className="course-sidebar__icon">
        <BiArrowToRight />
      </div> */}
      <div className="course-sidebar-title">Outline</div>
      <ul className="course-sidebar-list">
        <PageList course={course} coursePage={coursePage} />
      </ul>
    </div>
  );
}

export default Sidebar;
