import Pagination from "components/common/pagination";
import Icons from "components/icons";
import Modal from "components/modal";
import {BASE_URL} from "constants/constants";
import isEmpty from "isEmpty";
import React, {useState} from "react";
import Avatar from "react-avatar";
import {AiOutlineDollar} from "react-icons/ai";
import {NavLink} from "react-router-dom";
import RequestBlock from "../common/requestBlock";
import LeaveRequestForm from "scenes/employee/leaves/leaveRequest/form";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import TimeRequestForm from "scenes/employee/time/timeRequest";
import {FaUmbrellaBeach} from "react-icons/fa";
import {GoComment} from "react-icons/go";
import {WiTime3} from "react-icons/wi";
import ApprovalFlow from "scenes/common/approvalFlow";
import Button from "components/button";
import GetDetailsPage from "../common/getDetailsPage";
import RequestBlockWrapper from "../common/requestBlockWrapper";
import SvgIcons from "components/icons/svgIcons";

export interface IMyRequestBlockProps {
  rows: any;
  handleRowChange: Function;
  handleIndexChange: Function;
  pageIndex: number;
  rowsPerPage: number;
  refreshRequestData: Function;
  handleConfirmCancelRequest: Function;
}

function MyRequestBlock(props: IMyRequestBlockProps) {
  let [modalOpen, setModalOpen] = useState("");
  let {handleRowChange, handleIndexChange, pageIndex, rowsPerPage, refreshRequestData} =
    props;
  const onModalShow = (id) => {
    setModalOpen(id);
  };
  const [isLeaveRequestFormShown, showLeaveRequestForm] = useState(false);
  const [isAllowanceRequestFormShown, showAllowanceRequestForm] = useState(false);
  const [isOvertimeRequestFormShown, showOvertimeRequestForm] = useState(false);
  const [isTimeRequestFormShown, showTimeRequestForm] = useState(false);
  let [selectedRow, setSelectedRow] = useState({});
  const handleCancelRequest = (data) => {
    props.handleConfirmCancelRequest(data);
  };
  const selectedRowType = (type) => {
    if (type == "Leave") showLeaveRequestForm(true);
    else if (type == "Allowance") showAllowanceRequestForm(true);
    else if (type == "Overtime") showOvertimeRequestForm(true);
  };
  const handleEdit = (data: any) => {
    showLeaveRequestForm(false);
    showLeaveRequestForm(false);
    setSelectedRow(data);

    if (data.RequestType == "Leave") {
      showLeaveRequestForm(true);
    } else if (data.RequestType == "Time") {
      showTimeRequestForm(true);
    } else if (data.RequestType == "Allowance") showAllowanceRequestForm(true);
    else if (data.RequestType == "Overtime") showOvertimeRequestForm(true);
  };
  let [isDetailsPageShow, setDetailsPageShow] = useState(false);

  return (
    <>
      {props.rows.map((row, rowIndex) => {
        const approvalStatus = [
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Reccommender,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.RecommendedStatus,
            ApproverImage: row.RecommendedImage,
          },
          {
            RequestRefId: row.RequestId,
            UserName: row.Requester,
            ActionBy: row.Approval,
            ActionOn: "",
            StepName: "",
            ApprovalStatus: row.ApprovalStatus,
            ApproverImage: row.ApprovalImage,
          },
        ];
        const isRequestNotApproved =
          row.RecommendedStatus === "Pending" && row.ApprovalStatus === "Pending";

        return (
          <div className="request-details" key={rowIndex}>
            <RequestBlock
              row={row}
              isViewMode={true}
              refreshRequestData={refreshRequestData}
              requestData={props.rows}
              page="myRequest"
            />
            <div className="request-details__footer">
              <div className="details-btn flex">
                {isRequestNotApproved ? (
                  <Button
                    title="Edit"
                    bg="subtle"
                    // type="button-outline"
                    onClick={() => handleEdit(row)}
                    buttonType={"icon-button"}
                    leftIcon={<Icons name="Edit" className="flex" />}
                  />
                ) : (
                  <Button
                    title="Details"
                    bg="subtle"
                    leftIcon={<SvgIcons name="list" color="#3a3d43" size="17px" />}
                    // type="button-outline"

                    onClick={() => {
                      setSelectedRow(row);
                      setDetailsPageShow(true);
                    }}
                  />
                )}
              </div>

              <ApprovalFlow approvalStatus={approvalStatus} rowIndex={rowIndex} />
              {isRequestNotApproved && (
                <Button
                  title="Cancel"
                  bg="subtle-error"
                  onClick={(e) => handleCancelRequest(row)}
                  buttonType={"icon-button"}
                  leftIcon={<Icons name="Close" className="flex" />}
                />
              )}

              <Modal
                open={modalOpen === row.RequestId}
                onModalClose={() => onModalShow("")}
                title="Comments History"
              ></Modal>
            </div>
          </div>
        );
      })}
      {isEmpty(props.rows) ? null : (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          searchedPage={{
            index: pageIndex,
            total: props.rows ? props.rows[0].TotalRow : 0,
          }}
          rowsPerPage={rowsPerPage}
        />
      )}
      {isLeaveRequestFormShown && (
        <LeaveRequestForm
          closeForm={showLeaveRequestForm}
          leaveRequestId={selectedRow["RequestId"]}
          refreshRequestData={refreshRequestData}
        />
      )}
      {isAllowanceRequestFormShown && (
        <Modal
          title="Allowance Request"
          open={isAllowanceRequestFormShown}
          onModalClose={() => showAllowanceRequestForm(false)}
          type=""
          className="modal-allowance-request allowance-request-form"
        >
          <AllowanceRequestForm
            closeForm={showAllowanceRequestForm}
            allowanceRequestId={selectedRow}
            editMode={true}
            refreshRequestData={props.refreshRequestData}
          />
        </Modal>
      )}
      {isOvertimeRequestFormShown && (
        <Modal
          title="Overtime Request"
          open={isOvertimeRequestFormShown}
          onModalClose={() => showOvertimeRequestForm(false)}
          type=""
          className="time-request-modal over-time"
        >
          <OvertimeRequestForm
            onModalClose={() => showOvertimeRequestForm(false)}
            overtimeRequestId={selectedRow}
            editMode={true}
            refreshRequestData={props.refreshRequestData}
          />
        </Modal>
      )}
      {isTimeRequestFormShown && (
        <TimeRequestForm
          showModal={isTimeRequestFormShown}
          onModalClose={() => showTimeRequestForm(false)}
          dataToEdit={selectedRow}
          refreshRequestData={props.refreshRequestData}
        />
      )}
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={selectedRow}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={true}
          isReview={true}
          refreshRequestData={refreshRequestData}
          requestData={props.rows}
          page="myRequest"
        />
      )}
    </>
  );
}
export default RequestBlockWrapper()(MyRequestBlock);
