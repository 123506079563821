import configureStore from "../store/configureStore";
import { getPermissionsObject } from "./getPermissions";
import isEmpty from "isEmpty";

const store = configureStore;

export const hasPermission = (module: string, action: string): boolean => {
  var permission = getPermissionsObject("user"); //get user assigned permission from an API

  if (permission && permission[module] && permission[module].includes(action))
    return true;
  return false;
};

interface featureInterface {
  Feature: string;
  Flag: boolean;
}

export const isFeatureEnabled = (featurename: string): boolean => {
  const { activeFeatureFlags } = store.getState().permissionReducer;
  const featureContains = activeFeatureFlags && activeFeatureFlags.find(
    (feature: featureInterface) => feature.Feature === featurename
  );
  return !isEmpty(featureContains);
};

export const isFeatureEnabledValue = (featurename: string): boolean => {
  const { activeFeatureFlags } = store.getState().permissionReducer;
  const featureContains: any = activeFeatureFlags && activeFeatureFlags.find(
    (feature: featureInterface) => feature.Feature === featurename
  );
  return !isEmpty(featureContains) && featureContains.Flag;
};

export const checkPermissionAndFeatureEnabled = (
  module: string,
  action: string,
  featurename: string
): boolean => {
  return hasPermission(module, action) && isFeatureEnabled(featurename);
};
