import React from "react";
import "./style.scss";
import isEmpty from "isEmpty";
import {useSelector} from "react-redux";
import {currencyCommaSeperator} from "../../helpers/constants";
import CommentsView from "./commentsView";
import Avatar from "react-avatar";

function LoanForwards(props) {
  const consumerLoanDetails = useSelector(
    (state) => state.sbiLoanReducer.consumerLoanDetails
  );
  if (isEmpty(consumerLoanDetails)) return null;
  const {
    LoanDetail: {LoanAmount, PurposeOfLoan, RepaymentTenure},
  } = consumerLoanDetails || {
    LoanDetail: {
      LoanAmount: "",
      PurposeOfLoan: "",
      RepaymentTenure: "",
    },
  };

  return <CommentsView {...props}/>;
}

// const mapStateToProps = (state) => ({
//   vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
// });

export default LoanForwards;
