import {Avatar} from "@material-ui/core";
import classnames from "classnames";
// import AutoSelect from "components/Common/AutoSelect";
import Loader from "components/loaders";
import {ADMIN_ROLE_ID} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {Arrays} from "helpers/getTextLabel";
import isEmpty from "isEmpty";
import moment from "moment";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import FormGroup from "components/form";
import {GetApprovalAuthorities} from "../travelInformation/actions";
import CommentSection from "./commentSection";
export class CommentsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: [],
      remarks: "",
      roleId: null,
      isApprove: false,
      errors: {},
      statusId: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onApproveReturn = this.onApproveReturn.bind(this);
  }

  async componentDidMount() {
    const {StatusId, childRef, RequestId, NextStepId} = this.props;
    if (!isEmpty(childRef)) {
      childRef(this);
    }

    (await this.props.GetApprovalAuthorities) &&
      this.props.GetApprovalAuthorities(StatusId);
    // if(StatusId !==0){
    //     this.props.GetApprovalHistory(RequestId);
    // }
    const roleId = RoleId();
    if (StatusId === 15 || roleId === ADMIN_ROLE_ID) {
      this.setState({isApprove: true, statusId: StatusId, roleId});
    } else {
      this.setState({isApprove: false, statusId: StatusId, roleId});
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {StatusId, isUpdate} = this.props;
    const {loading, value} = this.props.approvalAuthorities;
    if (this.state.statusId !== StatusId) {
      if (StatusId === 15 || this.state.roleId === ADMIN_ROLE_ID) {
        this.setState({isApprove: true, statusId: StatusId});
      } else {
        this.setState({isApprove: false, statusId: StatusId});
      }
    }

    if (!isEmpty(value) && value !== prevProps.approvalAuthorities.value) {
      if (value.IsFinalApprover) {
        this.setState({isApprove: true, statusId: StatusId});
      }

      if (isUpdate) {
        const approver = !isEmpty(value) ? Arrays(value.AuthoritiesList) : [];
        this.setState({employee: approver[0]});
      }
    }

    // if(this.state.roleId ===null && RoleIdByStore() !== null){
    //     this.setState({roleId :RoleIdByStore()},()=>{
    //         if(this.state.roleId ===1){
    //             this.setState({isApprove:true})
    //         }else{
    //             this.setState({isApprove:false})
    //         }
    //     })
    // }
    // if( this.state.roleId !== prevState.roleId){
    //     this.setState({roleId :RoleIdByStore()},()=>{
    //         console.log(RoleIdByStore(),this.state.roleId)
    //         if(this.state.roleId ===1){
    //             this.setState({isApprove:true})
    //         }else{
    //             this.setState({isApprove:false})
    //         }
    //     })
    // }
  }
  onChange(name, value) {
    this.setState({
      [name]: value,
      errors: {...this.state.errors, [name]: ""},
    });
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
    };

    const {isAuth, errors} = this.onValidate(data);
    if (!isAuth) {
      this.setState({errors}, () => console.log(this.state.errors));
      return false;
    }
    this.setState({errors: {}});
    this.onApprove(this.props.StatusId);
    // this.props.func();
    this.props.onModalClose();
  }
  onApprove(status) {
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
      requestId: this.props.RequestId,
      statusId: status,
    };
    // const postData={
    //     HistoryID: 0,
    //     RequestId: 0,
    //     Status: status,
    //     ActionBy: "",
    //     ActionOn: "",
    //     ActionRemarks: data.remarks,
    //     ActionDisplayTitle: '',
    //     ApprovalEmployeeId: parseInt(data.employee.value)
    // }
    // const reqData={requestId:this.props.RequestId}
    this.props.func(data);
    //this.props.ApproveOthersTravelRequests(reqData,postData)
  }
  onApproveReturn(status) {
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
    };
    const postData = {
      HistoryID: 0,
      RequestId: this.props.RequestId,
      Status: status,
      ActionBy: "",
      ActionOn: "",
      ActionRemarks: data.remarks,
      ActionDisplayTitle: "",
      ApprovalEmployeeId:
        !isEmpty(data.employee) && !isEmpty(data.employee.value)
          ? parseInt(data.employee.value)
          : 0,
    };
    const {isAuth, errors} = this.onValidate(data);
    if (!isAuth) {
      this.setState({errors}, () => console.log(this.state.errors));
      return false;
    }
    return postData;
  }
  onValidate(data) {
    let errors = {};
    if (isEmpty(data.employee) && !this.state.isApprove) {
      errors.employee = "Employee is required";
    }
    // if(isEmpty(data.remarks) && this.props.StatusId !==0){
    //     errors.remarks="Remarks is required"
    // }
    return {isAuth: isEmpty(errors), errors};
  }
  render() {
    const {loading, value} = this.props.approvalAuthorities;
    const {
      approvalHistory,
      StatusId,
      disableApprover,
      hideCommentSection,
      pendingSettlement,
    } = this.props;
    const {isModal, isViewMode, isEditPage, isApprover, isRequester} = this.props;
    const {isApprove, errors} = this.state;
    const approvers = !isEmpty(value)
      ? Arrays(!isEmpty(value.AuthoritiesList) ? value.AuthoritiesList : value)
      : [];
    if (isEmpty(approvalHistory.value) && disableApprover) return null;
    if (
      (StatusId === 0 || StatusId === 1) &&
      !isApprove &&
      !isApprover &&
      !disableApprover
    ) {
      return (
        <div
          title={this.props.errors && this.props.errors.employee}
          //style={{flexDirection: 'row-reverse',}}
          className={classnames({
            flex: true,
            "comments-error":
              !isEmpty(this.props.errors) && !isEmpty(this.props.errors.employee),
          })}
        >
          <div
          // className={"comments-footer__input"}
          >
            <FormGroup
              formName="reactselect"
              onChange={this.onChange}
              //isAbove
              value={this.state.employee}
              options={approvers}
              name="employee"
              width="250px"
              placeholder="Choose an employee"
              //isArray
              loading={loading}
              loadingType="skeleton"
              label="Select a approver"
              error={errors?.employee}
            />
          </div>
        </div>
      );
    }
    const emp = [
      {label: "Emp", value: 1},
      {label: "Mahesh Tripathis", value: 21},
      {label: "Other employee", value: 23},
      {label: "Some Name", value: 112},
    ];
    return (
      <div className="approve-container" style={isModal ? {width: "100%"} : {}}>
        {(isViewMode || isEditPage || isApprove) && (
          <div className="comments">
            {(!isModal || StatusId === 0 || StatusId === 1) && !hideCommentSection ? (
              <div className="comments-header">
                <span>Comments</span>
              </div>
            ) : null}
            <div
              className={`comments-body ${
                isEmpty(approvalHistory.value) ? "no-comments" : null
              }`}
            >
              {approvalHistory.value === null || approvalHistory.loading ? (
                <Loader open={approvalHistory.loading} loaderSize={"50"} type="normal" />
              ) : null}
              {!isEmpty(approvalHistory.value) ? (
                <ul className="comments-list">
                  {approvalHistory.value.map((item) => {
                    return <CommentSection data={item} />;
                  })}
                </ul>
              ) : !disableApprover ? (
                // <span>No Comments Found</span>
                <span></span>
              ) : null}
            </div>
            {!isViewMode && !isRequester && (
              <div
                title={this.state.errors.remarks}
                className={classnames({
                  "comments-remarks": true,
                  "comments-error": !isEmpty(this.state.errors.remarks),
                })}
              >
                <textarea
                  //autoFocus={true}
                  onChange={(e) => this.onChange(e.target.name, e.target.value)}
                  value={this.state.remarks}
                  placeholder="Remarks.."
                  name="remarks"
                  id="remarks"
                  cols="20"
                  rows=""
                ></textarea>
              </div>
            )}
          </div>
        )}
        {(isViewMode || isEditPage) && !isModal && !pendingSettlement ? null : (
          <div className="comments-footer">
            <form onSubmit={this.onSubmit}>
              <div
                title={this.state.errors.employee}
                className={classnames({
                  "flex comments-inputs": true,
                  "comments-error": !isEmpty(this.state.errors.employee),
                })}
              >
                {isApprove ? null : (
                  <div className={"comments-footer__input"}>
                    <FormGroup
                      formName="reactselect"
                      onChange={this.onChange}
                      isAbove
                      value={this.state.employee}
                      options={approvers}
                      name="employee"
                      width="250px"
                      placeholder="Choose an employee"
                      //isArray
                      loading={loading}
                      loadingType="skeleton"
                      error={errors?.employee}
                    />
                    {/* <AutoSelect
                      onChange={this.onChange}
                      isAbove
                      value={this.state.employee}
                      options={approvers}
                      name="employee"
                      // width="200px"
                      placeholder="Choose an employee"
                      //isArray
                      loading={loading}
                      loadingType="skeleton"
                    /> */}
                  </div>
                )}
                {isModal ? (
                  <div className="comments-footer__submit">
                    <button className="btn btn-blue" type="submit">
                      {isApprove ? "Approve" : "Forward"}
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

CommentsHistory.propTypes = {
  approvalAuthorities: PropTypes.object.isRequired,
  // GetApprovalAuthorities:PropTypes.func.isRequired,
  // GetApprovalHistory:PropTypes.func.isRequired,
  // ApproveOthersTravelRequests:PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};
CommentsHistory.defaultProps = {
  isModal: false,
};

export default CommentsHistory;

// const mapStateToProps = (state) => ({
//   approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
//   // approvalHistory: state.travelInformationReducer.approvalHistory,
// });

// const mapDispatchToProps = {
//   GetApprovalAuthorities,
//   // ApproveOthersTravelRequests,
//   // GetApprovalHistory
// };

// export default connect(mapStateToProps, mapDispatchToProps)(CommentsHistory);
