import React, {useEffect, useState} from "react";
import Tabs, {Tab} from "components/tabs/index.d";
import Active from "./active";
import {GetEmployeeIncomeList} from "../../action";
import {useDispatch, useSelector} from "react-redux";

function Incomes(props) {
  const [state, setState] = useState({
    data: [],
    activeIncomes: [],
    disabledCashIncomes: [],
    notAppliedIncomes: [],
  });

  const {employeeIncomes} = useSelector((state: any) => state.employeeProfile);

  useEffect(() => {
    parseIncomes(employeeIncomes);
  }, [employeeIncomes]);

  const parseIncomes = (incomes) => {
    var activeIncomes = incomes.filter((x) => x.CurrentState === "Active");
    var disabledCashIncomes = incomes.filter(
      (x) => x.CurrentState === "Income Disabled" || x.CurrentState !== "Disabled"
    );
    var notAppliedIncomes = incomes.filter((x) => x.CurrentState === "Not Applied");

    setState({
      ...state,
      data: incomes,
      activeIncomes,
      disabledCashIncomes,
      notAppliedIncomes,
    });
  };

  return (
    <div className="emp-payroll__block">
      <div className="emp-payroll__title">Incomes</div>
      <Tabs>
        <Tab label="Active" name="Active">
          <Active activeData={state.activeIncomes} />
        </Tab>
        <Tab label="Disabled" name="Disabled">
          <Active activeData={state.disabledCashIncomes} />
        </Tab>
        <Tab label="Not Applied" name="Not Applied">
          <Active activeData={state.notAppliedIncomes} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Incomes;
