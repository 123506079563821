import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDatePicker from "react-date-picker";
import Select from "react-select";
import Form from "components/form";
import Modal from "components/modal";
import {
  GetClientProjectDDl,
  GetNatureOfWorkDDL,
  CreateNewActivity,
  UpdateActivity,
  GetActivityDetailByActivityIDs,
  GetMemberByName,
} from "../../action";
import { DisplayClientSideMessage } from "actions/commonAction";
import { InputValidator } from "helpers/inputValidator";
import isEmpty from "isEmpty";
import { getDaywiseDate } from "helpers/getDaywiseDate";
import TimeField from "react-simple-timefield";
import { Date_Format_ALT } from "helpers/dateFormat";
import DatePickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import { RoleName } from "constants/userDetails";
import { BS2AD, AD2BS } from "helpers/dateConvert";

let memberTypeRadio = [
  { label: "Emloyee/Own", value: 1 },
  { label: "External Consultant", value: 2 },
];

export class NewActivityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activityId: 0,
      taskId: null,

      activeTeamMember: null,
      activityDate: Date_Format_ALT(new Date()),
      activeClientProject: null,
      natureOfWork: [],
      workedHours: 0,
      billableHrs: 0,
      nonBillableHrs: 0,
      shortDescription: "",
      pocketExpenses: "",
      isMultiBlockState: false,
      multiBlockState: [],
      isDisableActivityDate: false,
      isDisablePage: false,
      isEditPage: false,
      isEditByApproverReviewer: false,
      memberName: "",
    };
  }

  async componentDidMount() {
    const userRole = this.props.userDetail.RoleName;

    const memberType =
      userRole == "Employee"
        ? memberTypeRadio[0].value
        : memberTypeRadio[1].value;

    this.setState({ userRole, memberType });
    // this.props.childRef = this;
    await this.getClientProjectList();
    await this.props.GetNatureOfWorkDDL();
    await this.getMemberByName();

    const { dataToEdit, isEditPage, isEditByApproverReviewer } = this.props;

    if (dataToEdit) {
      this.setState({ isEditPage, isEditByApproverReviewer });
      await this.manageDateToEdit(dataToEdit);
      // await this.props.GetActivityDetailByActivityIDs(dataToView.ActivityIds);
      // await this.manageDateToView(dataToEdit);
    } else {
      await this.checkMultiTaskState();
    }
  }

  componentWillUnmount() {
    this.clearInputFields();
  }

  getMemberByName = async () => {
    const { memberName } = this.state;
    await this.props.GetMemberByName(memberName);
  };

  getClientProjectList = async () => {
    const { activeTeamMember } = this.state;

    const data = {
      memberTypeId: activeTeamMember ? activeTeamMember.memberTypeId : null,
      memberId: activeTeamMember ? activeTeamMember.value : null,
    };
    await this.props.GetClientProjectDDl(data);
    this.setState({ activeClientProject: null });
  };

  checkMultiTaskState = async () => {
    const { clientProjectListForDDl } = this.props;
    let { isMultiBlockState, multiBlockState, activityDate } = this.state;
    const { propsData } = this.props;

    let activityList = [];
    let isDisableActivityDate = false;
    if (propsData) {
      activityDate = propsData.date && Date_Format_ALT(propsData.date);
      activityList = propsData.items;

      //update state status
      isMultiBlockState = true;
      isDisableActivityDate = true;
      await Promise.all(
        activityList &&
          activityList.map((data) => {
            // const activeClientProject =
            //   clientProjectListForDDl &&
            //   clientProjectListForDDl.find((f) => f.value == data.ProjectId);
            const activeClientProject = {
              label: `${data.ClientName + ": " + data.ProjectName}`,
              value: data.ProjectId,
            };

            let formatedTaskSpentTime = 0;
            let isInvalidTimeSpent = false;
            let invalidTaskSpentTime = null;
            if (data.TaskSpentTime) {
              const taskSpentTimeInHrs = parseFloat(
                data.TaskSpentTime / 60
              ).toFixed(1);

              if (taskSpentTimeInHrs > 24) {
                isInvalidTimeSpent = true;
                invalidTaskSpentTime = this.convertMinutesIntoDesiredFormat(
                  data.TaskSpentTime
                );
              } else {
                formatedTaskSpentTime = this.convertMinutesIntoDesiredFormat(
                  data.TaskSpentTime
                );
              }
            }

            const eachBlock = {
              activeClientProject: activeClientProject,
              natureOfWork: [],
              workedHours: formatedTaskSpentTime,
              billableHrs: 0,
              nonBillableHrs: 0,
              shortDescription: "",
              pocketExpenses: "",
              taskId: data.TaskId,
              isInvalidTimeSpent,
              invalidTaskSpentTime,
            };

            multiBlockState.push({
              key: data.TaskId,
              block: eachBlock,
            });
          })
      );
    }

    this.setState({
      isDisableActivityDate,
      isMultiBlockState,
      multiBlockState,
      activityDate,
    });
  };

  manageDateToEdit = async (dataToEdit) => {
    const {
      ActivityDateEng,
      SubmittedBy,
      SubmittedByDisplayText,
      ActivityId,
      ClientId,
      ProjectId,
      TaskId,
      MemberId,
      MemberTypeId,
      ProjectName,
      BillableHours,
      NonBillableHours,
      WorkedHours,
      BillableHoursDisplayText,
      NonBillableHoursDisplayText,
      WorkedHoursDisplayText,
      NatureOfWorks,
      Description,
      Expenses,
      Status,
    } = dataToEdit;
    const { natureOfWorkDDl, membersByNameDll } = this.props;

    const formatedworkedHours = this.convertMinutesIntoDesiredFormat(
      WorkedHours
    );
    const formatedBillableHrs = this.convertMinutesIntoDesiredFormat(
      BillableHours
    );
    const formatedNonBillableHrs = this.convertMinutesIntoDesiredFormat(
      NonBillableHours
    );

    let natureOfWorks = [];
    if (NatureOfWorks) {
      let splitedNatureOfWork = NatureOfWorks.toString().split(",");

      await Promise.all(
        splitedNatureOfWork &&
          splitedNatureOfWork.map((now) => {
            const currentNatureOfWork =
              natureOfWorkDDl && natureOfWorkDDl.find((f) => f.label == now);
            currentNatureOfWork && natureOfWorks.push(currentNatureOfWork);
          })
      );
    }

    // let activeTeamMember = membersByNameDll.find(
    //   (f) => f.label == SubmittedByDisplayText
    // );

    this.setState({
      activityId: ActivityId,
      taskId: TaskId,
      memberType: MemberTypeId,
      memberId: MemberId,
      activeTeamMember: null, //activeTeamMember ? activeTeamMember : null,
      activityDate: Date_Format_ALT(new Date(ActivityDateEng)),
      activeClientProject: {
        label: ProjectName,
        value: ProjectId,
      },
      natureOfWork: natureOfWorks,
      workedHours: formatedworkedHours,
      billableHrs: formatedBillableHrs,
      nonBillableHrs: formatedNonBillableHrs,
      shortDescription: Description,
      pocketExpenses: Expenses,
    });
  };

  manageDateToView = async (dataToView) => {
    let { multiBlockState } = this.state;
    const {
      clientProjectListForDDl,
      natureOfWorkDDl,
      projectActivityDetail,
      membersByName,
    } = this.props;
    const { ActivityDateEng, ActivityDetail, ActivityIds } = dataToView;

    if (projectActivityDetail && projectActivityDetail.length > 0) {
      Promise.all(
        projectActivityDetail.map((detail) => {
          const activeClientProject = {
            label: detail.ProjectDisplayText,
            value: "0",
          };

          // const activeClientProject =clientProjectListForDDl &&
          //   clientProjectListForDDl.find(
          //     (f) => f.label == detail.ProjectDisplayText
          //   );

          let natureOfWorks = [];
          if (detail.NatureOfWork) {
            let splitedNatureOfWork = detail.NatureOfWork.toString().split(",");

            splitedNatureOfWork &&
              splitedNatureOfWork.map((now) => {
                const currentNatureOfWork =
                  natureOfWorkDDl &&
                  natureOfWorkDDl.find((f) => f.value == now);
                currentNatureOfWork && natureOfWorks.push(currentNatureOfWork);
              });
          }

          const formatedworkedHours = this.convertMinutesIntoDesiredFormat(
            detail.WorkedHours
          );
          const formatedBillableHrs = this.convertMinutesIntoDesiredFormat(
            detail.BillableHours
          );
          const formatedNonBillableHrs = this.convertMinutesIntoDesiredFormat(
            detail.NonBillableHours
          );

          const eachBlock = {
            activeClientProject: activeClientProject
              ? activeClientProject
              : null,
            natureOfWork: natureOfWorks,
            workedHours: formatedworkedHours,
            billableHrs: formatedBillableHrs,
            nonBillableHrs: formatedNonBillableHrs,
            shortDescription: detail.Description,
            pocketExpenses: detail.PocketExpenses,
            activityId: detail.ActivityId,
            taskId: detail.TaskId,
          };

          multiBlockState.push({
            key: detail.ActivityId,
            block: eachBlock,
          });
        })
      );
    }

    let activeTeamMember = null;
    let memberType = 1;
    if (projectActivityDetail) {
      memberType = projectActivityDetail[0].MemberTypeId
        ? projectActivityDetail[0].MemberTypeId
        : 1;
      activeTeamMember = membersByName.find(
        (f) => f.MemberId == projectActivityDetail[0].MemberId
      );
    }

    this.setState({
      isDisablePage: true,
      isMultiBlockState: true,
      multiBlockState: multiBlockState,
      activityDate: ActivityDateEng ? new Date(ActivityDateEng) : new Date(),
      memberType: memberType,
      activeTeamMember: activeTeamMember,
    });
  };

  convertMinutesIntoDesiredFormat = (mins) => {
    let hours = mins / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rhours.toString().length == 1) {
      rhours = "0" + rhours;
    }
    if (rminutes.toString().length == 1) {
      rminutes = "0" + rminutes;
    }
    return rhours + ":" + rminutes;
  };

  //#region SAVE
  handleSaveAndFinish = async () => {
    await this.saveActivity(true);
  };

  handleSaveAndNext = async () => {
    await this.saveActivity();
  };

  saveActivity = async (isSaveAndFinish = false) => {
    const {
      activityId,
      taskId,
      isMultiBlockState,
      multiBlockState,
      activityDate,
      activeClientProject,
      natureOfWork,
      workedHours,
      billableHrs,
      nonBillableHrs,
      shortDescription,
      pocketExpenses,
      isEditPage,
      isEditByApproverReviewer,
      memberId,
    } = this.state;

    let finalData = [];
    let errors = {};

    if (!isMultiBlockState) {
      errors = await InputValidator(document, this.state);
      if (!isEmpty(errors)) {
        this.setState({ errors });
        return;
      }

      finalData = await this.manageActivityData(
        activityDate,
        activeClientProject,
        natureOfWork,
        workedHours,
        billableHrs,
        nonBillableHrs,
        shortDescription,
        pocketExpenses,
        taskId,
        activityId,
        memberId
      );
    } else {
      await Promise.all(
        multiBlockState &&
          multiBlockState.map(async (mts) => {
            let singleTaskData = await this.manageActivityData(
              activityDate,
              mts.block.activeClientProject,
              mts.block.natureOfWork,
              mts.block.workedHours,
              mts.block.billableHrs,
              mts.block.nonBillableHrs,
              mts.block.shortDescription,
              mts.block.pocketExpenses,
              mts.block.taskId,
              mts.block.activityId,
              mts.block.memberId
            );
            await Promise.all(
              singleTaskData &&
                singleTaskData.map((std) => {
                  finalData.push(std);
                })
            );
          })
      );
    }

    if (isEmpty(errors)) {
      if (isEditPage || isEditByApproverReviewer) {
        await this.props.UpdateActivity(
          finalData[0],
          this.props,
          this.clearInputFields,
          isSaveAndFinish
        );
      } else {
        await this.props.CreateNewActivity(
          finalData,
          this.props,
          this.clearInputFields,
          isSaveAndFinish
        );
      }
    }
  };

  manageActivityData = async (
    activityDate,
    activeClientProject,
    natureOfWork,
    workedHours,
    billableHrs,
    nonBillableHrs,
    shortDescription,
    pocketExpenses,
    taskId,
    activityId,
    MemberId
  ) => {
    const { companyInformation } = this.props;
    let isNepaliDate = !companyInformation.IsEnglishDate;
    const { activeTeamMember } = this.state;
    let managedData = [];

    let natureOfWorkString = "";
    natureOfWork &&
      natureOfWork.map((now) => {
        natureOfWorkString += !isEmpty(natureOfWorkString)
          ? ", " + now.value
          : now.value;
      });

    let memberId = MemberId || null;
    let memberTypeId = 1;
    if (activeTeamMember) {
      memberId = activeTeamMember.value;
      memberTypeId = activeTeamMember.memberTypeId;
    }

    const workedTimeInMin = this.convertTimeIntoMin(workedHours);
    const billableTimeInMin = this.convertTimeIntoMin(billableHrs);
    const nonBillableTimeInMin = this.convertTimeIntoMin(nonBillableHrs);

    let activityDateEng = activityDate;
    // if (isNepaliDate && activityDateEng) {
    //   try {
    //     activityDateEng = BS2AD(activityDateEng).formatedDate;
    //   } catch (ex) {}
    // }

    managedData.push({
      ActivityId: activityId,
      TaskId: taskId,
      ProjectId: activeClientProject ? activeClientProject.value : 0,
      MemberId: memberId,
      MemberTypeId: memberTypeId,
      ActivityDateEng: activityDateEng,
      Description: shortDescription,
      PocketExpenses: pocketExpenses,
      NatureOfWork: natureOfWorkString,
      WorkedHours: workedTimeInMin,
      BillableHours: billableTimeInMin,
      NonBillableHours: nonBillableTimeInMin,
    });
    return managedData;
  };

  convertTimeIntoMin = (time) => {
    const splitedTime = time.toString().split(":");
    const timeInHrs = splitedTime[0] ? parseInt(splitedTime[0]) : 0;
    const timeInMin = splitedTime[1] ? parseInt(splitedTime[1]) : 0;
    return timeInHrs * 60 + timeInMin;
  };
  //#endregion

  clearInputFields = () => {
    this.setState({
      activityId: 0,
      taskId: null,
      memberType:
        this.state.userRole == "Employee"
          ? memberTypeRadio[0].value
          : memberTypeRadio[1],
      activeTeamMember: null,
      activityDate: Date_Format_ALT(new Date()),
      activeClientProject: null,
      natureOfWork: [],
      workedHours: 0,
      billableHrs: 0,
      nonBillableHrs: 0,
      shortDescription: "",
      pocketExpenses: "",
      isMultiBlockState: false,
      multiBlockState: [],
      isDisableActivityDate: false,
      isDisablePage: false,
      isEditPage: false,
      isEditByApproverReviewer: false,
      memberName: "",
    });
  };

  //#region  handle
  handleInput = (name, value, key) => {
    if (key <= 0) {
      this.updateErrors();
      if (name === "pocketExpenses") {
        if (parseInt(value) >= 0 || isEmpty(value)) {
          this.setState({ [name]: value });
        }
      } else {
        this.setState({ [name]: value });
      }
    } else {
      this.handleMultiTaskInput(key, name, value);
    }
  };

  handleMultiTaskInput = (key, name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      multiBlockState: prevState.multiBlockState.map((item) => {
        if (item.key === key) {
          item.block[name] = value;
        }
        return item;
      }),
    }));
  };

  handleAutoSelect = async (name, selected, key) => {
    if (key <= 0) {
      this.setState({ [name]: selected }, async () => {
        this.updateErrors();
        if (name == "activeTeamMember") {
          await this.getClientProjectList();
        }
      });
    } else {
      this.handleMultiTaskInput(key, name, selected);
    }
  };

  handleMultiSelect = (list, selected, key) => {
    const { name } = selected;
    if (key <= 0) {
      this.setState({ [name]: list }, () => {
        this.updateErrors();
      });
    } else {
      this.handleMultiTaskInput(key, name, list);
    }
  };

  onTimeChange = (event, key) => {
    let hoursError = {};
    const { name, value } = event.target;
    let {
      workedHours,
      billableHrs,
      nonBillableHrs,
      multiBlockState,
    } = this.state;
    if (key > 0) {
      let currentTaskState = multiBlockState.find((f) => f.key == key).block;
      workedHours = currentTaskState.workedHours;
      billableHrs = currentTaskState.billableHrs;
      nonBillableHrs = currentTaskState.nonBillableHrs;
    }

    if (name == "workedHours") {
      workedHours = value;
    } else {
      if (workedHours && workedHours != 0) {
        //worked
        const swt = workedHours.split(":");
        const wh = parseInt(swt[0]);
        const wm = parseInt(swt[1]);
        if (name == "billableHrs") {
          //billable
          const sbt = value.split(":");
          const bh = parseInt(sbt[0]);
          const bm = parseInt(sbt[1]);

          if (bh > wh || (bh == wh && bm > wm)) {
            billableHrs = this.handleTimeChangeError(billableHrs);
            nonBillableHrs = this.handleTimeChangeError(nonBillableHrs);
            // this.props.DisplayClientSideMessage(
            //   "Billable hours cannot be greater than worked hours."
            // );
            hoursError["billableHrs"] =
              "Billable hours cannot be greater than worked hours.";
          } else {
            //nonbillable
            let nbh = wh - bh;
            let nbm = wm - bm;
            if (nbm < 0) {
              nbh -= 1;
              nbm = 60 + nbm;
            }

            if (nbh.toString().length == 1) {
              nbh = "0" + nbh;
            }
            if (nbm.toString().length == 1) {
              nbm = "0" + nbm;
            }
            nonBillableHrs = nbh + ":" + nbm;
            billableHrs = value;
          }
        } else if (name == "nonBillableHrs") {
          //non billable
          const snbt = value.split(":");
          const nbh = parseInt(snbt[0]);
          const nbm = parseInt(snbt[1]);

          //billable
          const sbt = billableHrs && billableHrs != 0 && billableHrs.split(":");
          let bh = 0;
          let bm = 0;
          if (sbt) {
            bh = parseInt(sbt[0]);
            bm = parseInt(sbt[1]);
          }

          if (nbh > wh || (nbh == wh && nbm > wm)) {
            nonBillableHrs = this.handleTimeChangeError(nonBillableHrs);
            // this.props.DisplayClientSideMessage(
            //   "Non-Billable hours cannot be greater than worked hours."
            // );
            hoursError["nonBillableHrs"] =
              "Non-Billable hours cannot be greater than worked hours.";
          } else if (
            bh &&
            bm &&
            (wh < bh + nbh || (wh == bh + nbh && wm < bm + nbm))
          ) {
            nonBillableHrs = this.handleTimeChangeError(nonBillableHrs);
            // this.props.DisplayClientSideMessage(
            //   "Sum of Billable and Non-Billable hours cannot be greater than worked hours."
            // );
            hoursError["nonBillableHrs"] =
              "Sum of Billable and Non-Billable hours cannot be greater than worked hours.";
          } else {
            nonBillableHrs = value;
          }
        }
      } else {
        billableHrs = this.handleTimeChangeError(billableHrs);
        nonBillableHrs = this.handleTimeChangeError(nonBillableHrs);

        hoursError["workedHours"] = "Please fill Worked hours first.";
        // this.props.DisplayClientSideMessage("Please fill Worked hours first.");
      }
    }

    if (key > 0) {
      this.setState((prevState) => ({
        ...prevState,
        multiBlockState: prevState.multiBlockState.map((item) => {
          if (item.key === key) {
            item.block["workedHours"] = workedHours;
            item.block["billableHrs"] = billableHrs;
            item.block["nonBillableHrs"] = nonBillableHrs;
            item.block["errors"] = hoursError;
          }
          return item;
        }),
      }));
    } else {
      this.setState({
        workedHours,
        billableHrs,
        nonBillableHrs,
        errors: hoursError,
      });
    }
  };

  handleTimeChangeError = (value) => {
    // since same value is not being update in TimePicker.... this is a trick to handle that problem.
    return value == "00:00" ? "0" : "00:00";
  };

  onChangeDate = (name, date) => {
    this.setState({ [name]: date });
  };

  onRadioChange = (name, value) => {
    this.setState({ [name]: value, activeTeamMember: null }, async () => {
      await this.getClientProjectList();
    });
  };

  updateActtivityDate = (label) => {
    let date = getDaywiseDate(label);
    if (date) {
      this.setState({ activityDate: Date_Format_ALT(date) });
    }
  };

  updateErrors = async () => {
    let errors = await InputValidator(document, this.state);
    this.setState({ errors });
  };
  //#endregion

  //#region recursive block
  recursiveBlock = (
    activeClientProject,
    natureOfWork,
    workedHours,
    billableHrs,
    nonBillableHrs,
    shortDescription,
    pocketExpenses,
    errors,
    isInvalidTimeSpent = false,
    invalidTaskSpentTime = null,
    key = 0
  ) => {
    const { clientProjectListForDDl, natureOfWorkDDl } = this.props;
    const {
      isDisablePage,
      isDisableActivityDate,
      isEditPage,
      isEditByApproverReviewer,
      userRole,
    } = this.state;

    const errorClass =
      workedHours == 0 && isInvalidTimeSpent ? "hasError error_tooltip" : "";

    return (
      <div className="create">
        <div className="create-content create-content-clientProject">
          {/* <AutoSelect
            disabled={isDisableActivityDate || isDisablePage}
            onChange={(name, selected) =>
              this.handleAutoSelect(name, selected, key)
            }
            name="activeClientProject"
            value={activeClientProject}
            options={clientProjectListForDDl}
            label="Client: Project"
            validators={["required"]}
            error={errors && errors.activeClientProject}
          /> */}
          <Form
            formName="reactselect"
            name="activeClientProject"
            value={activeClientProject}
            options={clientProjectListForDDl}
            disabled={
              isDisableActivityDate || isDisablePage || isEditByApproverReviewer
            }
            onChange={(name, selected) =>
              this.handleAutoSelect(name, selected, key)
            }
            label="Client: Project"
            validators={["required"]}
            error={errors && errors.activeClientProject}
          />
        </div>
        <div className="create-content">
          <div className="create-content-work">
            <div>
              <label>Nature of Work</label>
              <Select
                className="create-content-work-now"
                name="natureOfWork"
                value={natureOfWork}
                closeMenuOnSelect={false}
                isMulti
                options={natureOfWorkDDl}
                onChange={(list, selected) =>
                  this.handleMultiSelect(list, selected, key)
                }
                isDisabled={isDisablePage || isEditByApproverReviewer}
                label={"Nature of Work"}
              />
              {/* <AutoSelect
                disabled={isDisablePage}
                multiple
                onChange={(name, selected) =>
                  this.handleAutoSelect(name, selected, key)
                }
                name="natureOfWork"
                label="Nature of Work"
                value={natureOfWork}
                options={natureOfWorkDDl}
              /> */}
            </div>
            <div className={"create-content-work-time_field " + errorClass}>
              <label>Worked Hrs</label>

              <TimeField
                disabled={
                  isDisablePage ||
                  (userRole != "Employee" ? false : isEditByApproverReviewer)
                }
                name="workedHours"
                value={workedHours}
                onChange={(event) => this.onTimeChange(event, key)}
                placeholder="hh:mm"
              />
              {workedHours == 0 && isInvalidTimeSpent && (
                <span className={!isEmpty(errorClass) ? "tooltiptext" : ""}>
                  {invalidTaskSpentTime +
                    " is invalid time spent. Please enter time less than 24 hours."}
                </span>
              )}

              <span className="error__message">
                {errors && errors.workedHours}
              </span>
            </div>
            <div className="create-content-work-time_field">
              <label>Billable Hrs</label>
              <TimeField
                disabled={isDisablePage}
                name="billableHrs"
                value={billableHrs}
                onChange={(event) => this.onTimeChange(event, key)}
                placeholder="hh:mm"
              />
              <span className="error__message">
                {errors && errors.billableHrs}
              </span>
            </div>
            <div className="create-content-work-time_field">
              <label>Non-Billable Hrs</label>
              <TimeField
                disabled={isDisablePage}
                name="nonBillableHrs"
                value={nonBillableHrs}
                onChange={(event) => this.onTimeChange(event, key)}
                placeholder="hh:mm"
              />
              <span className="error__message">
                {errors && errors.nonBillableHrs}
              </span>
            </div>
          </div>
        </div>

        <div className="create-content">
          <Form
            formName="textinput"
            type="number"
            disabled={isDisablePage || isEditByApproverReviewer}
            name="pocketExpenses"
            value={pocketExpenses}
            label="Pocket Expenses"
            onChange={(name, value) => this.handleInput(name, value, key)}
            width={"250px"}
          />
        </div>

        <div className="create-content">
          <Form
            formName="textarea"
            disabled={isDisablePage || isEditByApproverReviewer}
            name="shortDescription"
            value={shortDescription}
            label="Short Description"
            onChange={(name, value) => this.handleInput(name, value, key)}
            validators={["required"]}
            error={errors && errors.shortDescription}
          />
        </div>
      </div>
    );
  };

  //#endregion

  render() {
    const {
      isEditPage,
      isEditByApproverReviewer,
      memberType,
      activeTeamMember,
      activityDate,
      activeClientProject,
      natureOfWork,
      workedHours,
      billableHrs,
      nonBillableHrs,
      shortDescription,
      pocketExpenses,
      errors,
      isMultiBlockState,
      multiBlockState,
      isDisableActivityDate,
      isDisablePage,
      userRole,
    } = this.state;
    const { membersByNameDll, showForm } = this.props;
    console.log({activityDate})
    return (
      <Modal
        open={showForm}
        onModalClose={() => this.props.handleFormDisplay(false)}
        title="Activity Report"
        className="activity-report-modal"
      >
        <>
          <div className="create">
            <div className="create-content">
              {/* <label>Activity Date</label>
          <div> */}
              {/* <ReactDatePicker
              onChange={(date) => this.onChangeDate(date)}
              value={activityDate}
              disabled={isDisablePage || isDisableActivityDate ? true : false}
              //   format={format}
            /> */}
              <div className="create-content-activity_date">
                <Form
                  formName="customdateinput"
                  isNepali={false}
                  isTextInNepali={false}
                  name="activityDate"
                  value={activityDate}
                  onChange={this.onChangeDate}
                  disabled={
                    isDisablePage ||
                    isDisableActivityDate ||
                    isEditByApproverReviewer
                  }
                  width="150px"
                  label="Activity Date"
                />
                {/* <Form
                  formName="customdateinput"
                  name="activityDate"
                  value={activityDate}
                  onChange={this.onChangeDate}
                  disabled={
                    isDisablePage || isDisableActivityDate ? true : false
                  }
                  width="150px"
                  label="Activity Date"
                /> */}

                {!isDisableActivityDate && !isDisablePage && (
                  <>
                    <span
                      className="time-period"
                      onClick={() => this.updateActtivityDate("today")}
                    >
                      Today
                    </span>
                    <span
                      className="time-period"
                      onClick={() => this.updateActtivityDate("yesterday")}
                    >
                      Yesterday
                    </span>
                    <span
                      className="time-period"
                      onClick={() => this.updateActtivityDate("last_friday")}
                    >
                      Last Friday
                    </span>
                  </>
                )}
              </div>
            </div>

            {(!isMultiBlockState || isEditPage || isEditByApproverReviewer) &&
              userRole &&
              userRole != "Employee" && (
                <div className="create-content">
                  <div className="create-content-target_type">
                    <div className="create-content-target_type-radio">
                      {memberTypeRadio &&
                        memberTypeRadio.map((rdo, i) => {
                          return (
                            // <Radiogroup
                            //   name={"memberType"}
                            //   label={rdo.label}
                            //   value={rdo.value}
                            //   checked={memberType == rdo.value}
                            //   onChange={this.onRadioChange}
                            //   disabled={isDisableActivityDate || isDisablePage}
                            // />

                            <Form
                              formName="radiogroup"
                              name={"memberType"}
                              label={rdo.label}
                              value={rdo.value}
                              checked={memberType == rdo.value}
                              onChange={this.onRadioChange}
                              disabled={
                                isDisableActivityDate ||
                                isDisablePage ||
                                isEditByApproverReviewer
                              }
                            />
                          );
                        })}
                    </div>
                    {memberType == 2 && (
                      <div className="create-content-target_type-dropdown">
                        {/* <AutoSelect
                    disabled={isDisableActivityDate || isDisablePage}
                    onChange={(name, selected) =>
                      this.handleAutoSelect(name, selected, 0)
                    }
                    name="activeTeamMember"
                    value={activeTeamMember}
                    options={membersByNameDll}
                    label="Team Member"
                    validators={["required"]}
                    error={errors && errors.activeTeamMember}
                  /> */}
                        <Form
                          formName="reactselect"
                          name="activeTeamMember"
                          value={activeTeamMember}
                          options={membersByNameDll}
                          disabled={
                            isDisableActivityDate ||
                            isDisablePage ||
                            isEditByApproverReviewer
                          }
                          onChange={(name, selected) =>
                            this.handleAutoSelect(name, selected, 0)
                          }
                          label="Team Member"
                          validators={["required"]}
                          error={errors && errors.activeTeamMember}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

            {!isMultiBlockState ? (
              <div className="taskwise-block">
                {this.recursiveBlock(
                  activeClientProject,
                  natureOfWork,
                  workedHours,
                  billableHrs,
                  nonBillableHrs,
                  shortDescription,
                  pocketExpenses,
                  errors
                )}
              </div>
            ) : (
              multiBlockState &&
              multiBlockState.map((data, index) => {
                return (
                  <div className="taskwise-block" key={index}>
                    {this.recursiveBlock(
                      data.block.activeClientProject,
                      data.block.natureOfWork,
                      data.block.workedHours,
                      data.block.billableHrs,
                      data.block.nonBillableHrs,
                      data.block.shortDescription,
                      data.block.pocketExpenses,
                      data.block.errors,
                      data.block.isInvalidTimeSpent,
                      data.block.invalidTaskSpentTime,
                      data.key
                    )}
                  </div>
                );
              })
            )}
          </div>
          <div className="animated-form__footer">
            <button
              className="btn btn-del"
              type="button"
              onClick={() => this.props.handleFormDisplay(false)}
            >
              Cancel
            </button>

            {this.props.hideFooter ? null : (
              <>
                <button
                  className="btn btn-primary"
                  onClick={this.handleSaveAndFinish}
                >
                  Save and Finish
                </button>
                <button
                  className="btn btn-primary"
                  onClick={this.handleSaveAndNext}
                >
                  Save and Next
                </button>
              </>
            )}
          </div>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  clientProjectListForDDl: state.taskTrackerReducer.clientProjectListForDDl,
  natureOfWorkDDl: state.taskTrackerReducer.natureOfWorkDDl,
  projectActivityDetail: state.taskTrackerReducer.projectActivityDetail,
  membersByName: state.taskTrackerReducer.membersByName,
  membersByNameDll: state.taskTrackerReducer.membersByNameDll,
  userDetail: state.auth.user,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  GetClientProjectDDl,
  GetNatureOfWorkDDL,
  CreateNewActivity,
  UpdateActivity,
  GetActivityDetailByActivityIDs,
  GetMemberByName,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewActivityForm);
