import React from "react";

function useFormValidation(initialState, validate) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        // console.log("authenticated!", values.email, values.password);
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
  }, [errors]);

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });
    if (isSubmitted) {
      const validationErrors = validate({
        ...values,
        [name]: value,
      });
      setErrors(validationErrors);
    }
  }
  function clearValues() {
    setValues(initialState);
    setSubmitting(false);
  }

  function handleBlur() {
    const validationErrors = validate(values);
    setErrors(validationErrors);
  }

  function handleSubmit(event) {
    // debugger;
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
    setSubmitted(true);
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    clearValues,
    values,
    setValues,
    errors,
    isSubmitting,
  };
}

export default useFormValidation;
