import {v1} from 'constants/urls';

const prefix = `${v1}/appraisal/`;

export default {
    appraisal: prefix,
    reviewCycle: {
        initial: prefix + "review-cycles",
        list: prefix + "review-cycles/list",
        dropdown: prefix + "review-cycles/dropdown",
    },
    ratingScale: {
        initial: prefix + "rating-scale",
    },
    workflow: {
        initial: prefix + "work-flow",
        wrokDlowSteps: prefix + "work-flow-steps",
        threeSixtyWorkflow:prefix + "three-sixty-degreework-flow",
    },
    goalDatabse:{
        initial: prefix + "goal-databases",
        list: prefix + "goal-databases/list",
    },
    rollout:{
        initial: prefix + "appraisal-rollout",
        list: prefix + "appraisal-rollout/detail/list",
        batchRollout: prefix + "appraisal-rollout/employees/batch-rollout",
    },
}