import React, { useState, useEffect } from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import { useForm } from "react-hook-form";
import Button from "components/button";
import { forwardSbiLoans, rejectSbiLoan } from "../helpers/action";
import { useDispatch, useSelector } from "react-redux";
import { DisplayClientSideMessage } from "actions/commonAction";
import { EmployeeID } from "constants/userDetails";
import isEmpty from "helpers/isEmpty";

const ForwardLoan = ({
  approversList, setOpenModel,
  forwardSelectedData, history,
  isApprove, rejectButton, isTable,
  getSbiLoanStatusHistories = null
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [approverDropdown, setApproverDropdown] = useState([]);
  const [dropDownLabel, setDropDownLabel] = useState("");

  const dispatch = useDispatch();
  const methods = useForm();
  const { getValues } = methods;

  const onLoanForward = async (data) => {
    if (rejectButton) {
      let forwardData = {
        loanId: forwardSelectedData.LoanId,
        loanType: forwardSelectedData.LoanType,
        // statusName: data.comments,
        actionRemarks: data.comments,
        actionDisplayTitle: 'Rejected',

      };
      let rejectedStatus = await dispatch(rejectSbiLoan(forwardData));
      if (rejectedStatus) {
        setOpenModel(false);
        if (isTable)
          await getSbiLoanStatusHistories()
        else
          history && history.goBack();
      }

    } else {
      const { approvalEmployeeId } = getValues();
      if (isEmpty(approvalEmployeeId) && (!isApprove && !rejectButton)) {
        dispatch(DisplayClientSideMessage('Please Select an Approver'));
        return;
      }
      let approver: any = approversList && approversList.find((a) => a.EmployeeId === approvalEmployeeId?.value)
      let forwardData = {
        id: 0,
        loanId: forwardSelectedData.LoanId,
        loanType: forwardSelectedData.LoanType,
        // statusName: data.comments,
        actionRemarks: data.comments,
        // actionDisplayTitle: approver.EmployeeName,
        actionDisplayTitle: isApprove ? 'Approved' : dropDownLabel,
        approvalEmployeeId: isApprove ? EmployeeID() : approver.EmployeeId,
        processFlowStepId: isApprove ? 10 : approver.ProcessFlowStepId,
        // status: isApprove ? 10 : forwardSelectedData.Status,
        status: forwardSelectedData.ProcessFlowStepId,
        statusName: isApprove ? 'Approved' : approver.StatusName,
        // isApprove ? 'Approved'  :
      };
      let approvedStatus = await dispatch(forwardSbiLoans(forwardData));
      if (approvedStatus) {
        setOpenModel(false);
        if (isTable)
          await getSbiLoanStatusHistories()
        else
          history && history.goBack();
      }
    }
  };

  useEffect(() => {
    if (approversList) {
      let dropdownLabel = 'Forward';
      let testData = approversList && approversList.map((values, i) => {
        if (i == 0) dropdownLabel = values.AuthorityTypeDisplayName;
        return { label: values.EmployeeName, value: values.EmployeeId };
      });
      setDropDownLabel(dropdownLabel);
      setApproverDropdown(testData);
    }
  }, [approversList]);
  const consentLabel = forwardSelectedData.ProcessFlowStepId == 2 ?
    "I have satisfied myself with the genuiness of the request made by the applicant and accordingly recommend for sanction of the loan."
    : "I have verified  with the details provided  on Salary and Loan Installments which are in order and correct.";
  return (
    <div className="forward-loan-popup">
      <FormGroup method={methods} onSubmit={onLoanForward}>
        <FormItem
          formName="textarea"
          width="100%"
          name="comments"
          placeholder="comments"
          label="Comments (Optional)"
        />
        {!rejectButton && <FormItem
          formName="checkgroup"
          name="selected"
          onChange={(e) => {
            setIsSelected(e.target.checked);
          }}
          value={isSelected}
          checked={isSelected}
          label={consentLabel}
          labelPosition="right"
        />}

        {(!isApprove && !rejectButton) && <FormItem
          formName="reactselect"
          options={approverDropdown}
          name="approvalEmployeeId"
          label={dropDownLabel}
          width="460px"
          placeholder="Forward To"
          rules={{ required: "Forward to is required!" }}
        />
        }

        <div className="footer-reverse mt-md">
          <Button
            htmlButtonType="submit"
            title={isApprove ? 'Approve' : rejectButton ? "Reject" : "Send"}
            bg={rejectButton ? 'danger' : "green"}
            isDisabled={!isSelected && !rejectButton}
          />
          <Button
            htmlButtonType="button"
            title="Cancel"
            bg="white"
            onClick={() => setOpenModel(false)}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default ForwardLoan;
