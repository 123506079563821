import React from "react";
import {UploadProfile} from "components/uploadProfile";
import {useSelector} from "react-redux";
import {getUploadPath} from "constants/userDetails";

function ProfilePicture(props) {
  const {employeeProfile} = useSelector((state: any) => state.employeeProfile);
  const {
    EmployeeProfileHeader: {UrlPhoto},
  } = employeeProfile;
  return (
    <div className="employee-docs__profile">
      <div className="tertiary-title">
        <h3>Profile Picture</h3>
      </div>
      <div className="employee-block employee-block__profile">
        <UploadProfile
          previewImage={getUploadPath(UrlPhoto)}
          isNotModal
          EmployeeId={props.EmployeeId}
        />
      </div>
    </div>
  );
}

export default ProfilePicture;
