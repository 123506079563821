import React, {Component, useState} from "react";
import isEmpty from "isEmpty";
import Form from "components/form";
import Button from "components/button";
import environment from "environment";
import {objectToFormData} from "object-to-formdata";
import {DisplayClientSideMessage} from "actions/commonAction";
import EmployeeInfo from "../../common/employeeInfo";
import LoanDataTable from "../../common/loanDataTable";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import Breadcrumb from "../../../../common/breadcrumb";
import {useForm} from "react-hook-form";
import {getUploadPath, UserByStore} from "constants/userDetails";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import getFullStringUrl from "helpers/getFullStringUrl";
import {hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";
import Upload from "components/common/upload";
import "../loanRequest.scss";
import RouteEnum from "constants/routeEnum";
import {
  getEmployeeInfo,
  getConsumerLoanPurposeList,
  getLoanSettingsByType,
  saveSocialLoanRequest,
  calculatePaymentSchedule,
  GenerateIncomeDeductionList,
  LoanApproverList,
  getSocialLoanDetails,
  resetLoanDetails,
} from "../../helpers/action";
import {
  GetBranches,
  // GetDepartments,
  // GetDesignations,
} from "actions/commonAction";
import {LoanType} from "../../helpers/constants";
import {valueAccessor} from "@syncfusion/ej2-grids";

const formatNumber = (params) => {
  if (!params) return "0.00";
  if (params) {
    if (params == 0) return "0.00";
    params = Number.parseFloat(params).toFixed(2);
    return new Intl.NumberFormat("en-IN", {minimumFractionDigits: 2}).format(params);
  }
  // if(!params) return 0;
  // return (
  //   Math.floor(params)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
  // );
};

const consensus =
  "I authorize the Bank to recover monthly interest from account maintained with the Bank.";
const conditions =
  "I authorize the Bank to lien mark the amount of Gratuity/PF/any other receivables payable to me. Further, I assign the amount of Gratuity/PF/any other receivables to be payable to me from Bank/EPF or any other office to the Bank till the loan with the Bank is not fully settled/paid. The Bank can utilize my PF/Gratuity for settlement of loan availed from the Bank.";
const declaration =
  "I hereby certify that the tenture of previous  Social Loan has been exceeded and declare my eligibility for new Social Loan as per the existing Rule of the Bank. / Not Applicable for applying for the first time.";
// export class VehicleLoanForm extends Component<IAppProps, IAppState> {
function SocialLoanForm(props) {
  const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = useState({});
  const [FileList, setFileList] = useState([]);
  const [consensusStatus, setConsensusStatus] = useState(false);
  const [conditionsStatus, setConditionsStatus] = useState(false);
  const [declarationStatus, setDeclarationStatus] = useState(false);
  const [PaymentPeriods, setPaymentPeriods] = useState(0);
  const [loanPaymentSchedule, setLoanPaymentSchedule] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [ProposedLoanInstallment, setProposedLoanInstallment] = useState(0);
  const [
    PercentageOfTotalDeductionOnGrossSalary,
    setPercentageOfTotalDeductionOnGrossSalary,
  ] = useState(0);
  const [GrossSalary, setGrossSalary] = useState(0);
  const [ForwardTo, setForwardTo] = useState(null);
  const [PaymentScheduleButtonClicked, setPaymentScheduleButtonClicked] = useState(false);
  const [isScheduledButton, setIsScheduledButton] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loanId, setLoanId] = useState(0);
  const [isFileFetched, setIsFileFetched] = useState(false);
  const [loanDeductions, setLoanDeductions] = useState(0);

  const methods = useForm();
  const {setValue, getValues, watch, control} = methods;
  const {
    consumerLoanPurposeList,
    loanSettingByType,
    loanRequesterInformation,
    loanPaymentSchedules,
    incomeDeductionList,
    loanApproverList,
    savedConsumerLoanRequest,
    consumerLoanDetails,
    savedSocialLoanRequest,
  } = useSelector((state: any) => state.sbiLoanReducer);
  const {branchList} = useSelector((state: any) => state.commonReducer);
  useEffect(() => {
    return () => {
      dispatch(resetLoanDetails());
      // Anything in here is fired on component unmount.
    };
  }, []);
  // const { courseId } = props.match.params;
  useEffect(() => {
    const User: any = UserByStore();
    (async () => {
      let approverQuery = {
        EmployeeId: User?.EmployeeId,
        LoanType: 2,
        FlowType: 0,
        CurrentStep: 0,
      };
      let incomeDeductionData = {
        EmployeeId: User?.EmployeeId,
        LoanId: 0,
        LoanType: 2,
      };
      let apis: any = [];
      // if (props.state.)
      const {id} = props.match.params;
      if (!isEmpty(id)) {
        setIsEditMode(true);
        apis.push(dispatch(getSocialLoanDetails(id)));
      } else {
        setIsFileFetched(true);
      }
      apis = [
        ...apis,
        dispatch(getLoanSettingsByType(2)),
        dispatch(getConsumerLoanPurposeList()),
        dispatch(getEmployeeInfo(User?.EmployeeId)),
        dispatch(GetBranches()),
        dispatch(GenerateIncomeDeductionList(incomeDeductionData)),
        dispatch(LoanApproverList(approverQuery)),
      ];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(loanSettingByType)) {
      const {LoanType, InterestRate, MinServicePeriod, MaxAllowablePaymentTerms} =
        loanSettingByType;
      // setValue("RequestAmount", 12)
      setValue("InterestRate", InterestRate);
      setPaymentPeriods(MaxAllowablePaymentTerms);
      const AvailabilityBranch =
        branchList &&
        branchList.filter(
          (branch) => branch?.value == loanRequesterInformation?.BranchId
        );
      setValue("AvailabilityBranch", AvailabilityBranch ? AvailabilityBranch[0] : null);
    }
  }, [loanSettingByType, branchList]);

  useEffect(() => {
    if (!isEmpty(incomeDeductionList) && !isEmpty(loanSettingByType)) {
      const {Incomes, Deductions} = incomeDeductionList;

      // CALCULATE INCOME DISTRIBUTION
      let incomes =
        Incomes &&
        Incomes.map((income) => {
          if (income.Alias == "-1") setGrossSalary(income.Amount);
          return {
            colorCode:
              income.Alias == "-1"
                ? "gross"
                : income.Alias == "-2"
                ? "secondary"
                : "primary",
            title: income.Title,
            amount: formatNumber(income.Amount),
            ticker: income.Alias == "-2",
            alias: income.Alias,
          };
        });
      setIncomes(incomes);
      calculateDeductions(Deductions, 0);
    }
  }, [incomeDeductionList, loanSettingByType]);

  // useEffect(() => {

  // })

  useEffect(
    () => async () => {
      const {id} = props.match.params;
      if (
        !isEmpty(consumerLoanDetails) &&
        !isEmpty(id) &&
        !isFileFetched &&
        !isEmpty(loanSettingByType)
        // && !isEmpty(incomeDeductionList)
      ) {
        const {
          LoanDetail: {
            LoanAmount,
            PurposeOfLoan,
            LoanAvailingBranchId,
            CurrentApprovalSelectedEmployeeId,
            Id,
            RepaymentTenure,
            IsScheduled,
          },
          Attachments,
        } = consumerLoanDetails || {};
        const {InterestRate} = loanSettingByType;
        if (
          !isEmpty(InterestRate) &&
          !isEmpty(LoanAmount) &&
          !isEmpty(RepaymentTenure) &&
          IsScheduled
        ) {
          const paymentData = {
            InterestRate: parseInt(InterestRate),
            LoanAmount: parseInt(LoanAmount),
            PaymentPeriods: parseInt(RepaymentTenure),
          };
          let data: any = await dispatch(calculatePaymentSchedule(paymentData));
          if (data && !isEmpty(incomeDeductionList)) {
            paymentDataCalculation(data);
          }
          // await dispatch(calculatePaymentSchedule(paymentData));
        }
        let selectedBranch = branchList.find(
          (branch) => branch.value == LoanAvailingBranchId
        );
        let selectedPurpose =
          consumerLoanPurposeList &&
          consumerLoanPurposeList.filter((purpose) => purpose.value == PurposeOfLoan);
        setValue("AvailabilityBranch", !isEmpty(selectedBranch) ? selectedBranch : {});
        setValue("LoanAmount", LoanAmount);
        setValue("RepaymentTenure", RepaymentTenure);
        setForwardTo(CurrentApprovalSelectedEmployeeId);
        setLoanId(Id);
        let Files = [];
        Attachments &&
          Attachments.forEach((file) => {
            Files.push({
              ...file,
              Id: file.AttachmentID,
              OriginalName: file.UserFileName,
              DocumentPath: file.FileLocation,
              DocumentName: file.UserFileName,
              name: file.UserFileName,
              path: getUploadPath(file.ServerFileName),
              docName: file.UserFileName,
              isFetched: true,
            });
          });
        setFileList(Files);
        setValue("PurposeOfLoan", selectedPurpose[0]);
        setIsFileFetched(true);

        // Attachments && Attachments.map(file => {
        //   Files.push(file);
        // })
      }
    },
    [
      // consumerLoanPurposeList,
      loanSettingByType,
      incomeDeductionList,
      loanPaymentSchedules,
      consumerLoanDetails,
    ]
  );

  const calculateDeductions = (Deductions, loanInstall) => {
    // CALCULATE DEDUCTION DISTRIBUTION
    let subTotal;
    let deductions =
      Deductions &&
      Deductions.map((deduction, i) => {
        let Amount = deduction.Amount;
        subTotal = Deductions.find((d) => d.Alias == "-3");
        let totalDeduction = !isEmpty(loanInstall)
          ? loanInstall + subTotal.Amount
          : ProposedLoanInstallment + subTotal.Amount;
        if (deduction.Alias == "-4") {
          Amount = !isEmpty(loanInstall) ? loanInstall : ProposedLoanInstallment;
        } else if (deduction.Alias == "-5") {
          Amount = totalDeduction;
        } else if (deduction.Alias == "-6") {
          Amount =
            GrossSalary > 0 && totalDeduction > 0
              ? `${parseFloat(((totalDeduction / GrossSalary) * 100).toString()).toFixed(
                  2
                )}`
              : 0;
          setPercentageOfTotalDeductionOnGrossSalary(Amount);
        }
        let computableRow =
          deduction.Alias == "-4" || deduction.Alias == "-5" || deduction.Alias == "-6";
        return {
          colorCode: computableRow ? "secondary" : "primary",
          title: deduction.Title,
          amount:
            deduction.Alias == "-6"
              ? !isEmpty(Amount)
                ? `${formatNumber(Amount)} %`
                : formatNumber(Amount)
              : formatNumber(Amount),
          ticker: false,
        };
      });
    setDeductions(deductions);
    setLoanDeductions(!isNaN(subTotal.Amount) ? subTotal.Amount : 0);
  };

  const onFileChange = (FileList) => {
    setFileList(FileList);
  };

  const calculatePaymentSchedules = async () => {
    const {LoanAmount, InterestRate, RepaymentTenure} = getValues();
    if (RepaymentTenure < 1) {
      dispatch(DisplayClientSideMessage("Please Enter payment tenure more than 0"));
      return;
    }
    if (isEmpty(RepaymentTenure) || isEmpty(LoanAmount)) {
      dispatch(DisplayClientSideMessage("Please Enter Loan Amount and Repayment Tenure"));
      return;
    }
    const paymentData = {
      InterestRate: parseInt(InterestRate),
      LoanAmount: parseInt(LoanAmount),
      PaymentPeriods: parseInt(RepaymentTenure),
    };
    let data: any = await dispatch(calculatePaymentSchedule(paymentData));
    await paymentDataCalculation(data);
  };

  const paymentDataCalculation = async (data) => {
    let loanSchedule = [];
    let loanInstall = 0;
    if (!isEmpty(data)) {
      loanSchedule = data.map((loan, i) => {
        if (i == 0) loanInstall = loan.Amount;
        return {
          colorCode: "primary",
          title: loan.Period,
          amount: formatNumber(loan.Amount),
          period: loan.Period,
        };
      });
    }
    await setProposedLoanInstallment(loanInstall);
    await setLoanPaymentSchedule(loanSchedule);
    const {Deductions} = incomeDeductionList;
    calculateDeductions(Deductions, loanInstall);
    setPaymentScheduleButtonClicked(true);
    setIsScheduledButton(true);
  };

  const onSubmit = (data) => {
    const {LoanAmount, PurposeOfLoan, InterestRate, RepaymentTenure, AvailabilityBranch} =
      data;
    if (isEmpty(ForwardTo)) {
      dispatch(DisplayClientSideMessage("Please select an approver"));
      return;
    }
    if (!PaymentScheduleButtonClicked) {
      dispatch(
        DisplayClientSideMessage("Please calculate your payment schedule to procced")
      );
      return;
    }
    if (!consensusStatus || !conditionsStatus || !declarationStatus) {
      dispatch(
        DisplayClientSideMessage("Please provide your consent before applying the loan")
      );
      return;
    }

    let consumerLoanData = {
      SocialLoan: {
        Id: isEditMode ? loanId : 0,
        LoanAmount: LoanAmount,
        PurposeOfLoan: PurposeOfLoan.value,
        InterestRate: parseInt(InterestRate),
        // Files: FileList,
        RepaymentTenure: parseInt(RepaymentTenure),
        LoanAvailingBranchId: AvailabilityBranch.value,
        GrossSalary,
        FiftyPercentOfGrossSalary: parseFloat((GrossSalary / 2).toString()).toFixed(2),
        TotalLoanDeduction: parseFloat(loanDeductions.toString()),
        ProposedLoanInstallment: parseFloat(ProposedLoanInstallment.toString()),
        TotalDeduction:
          parseFloat(ProposedLoanInstallment.toString()) +
          parseFloat(loanDeductions.toString()),

        // TotalLoanDeduction: parseFloat(ProposedLoanInstallment.toString()),
        PercentageOfTotalDeductionOnGrossSalary,
        Status: loanApproverList[0].Status,
        StatusName: loanApproverList[0].StatusName,
        FlowType: loanApproverList[0].FlowType,
        ProcessFlowStepId: loanApproverList[0].ProcessFlowStepId,
        CurrentApprovalSelectedEmployeeId: ForwardTo,
        IsScheduled: true,
      },
      // LoanSalaryHead: incomes,
      LoanSalaryHead: [],
      PaymentSchedule: loanPaymentSchedule,
      ApproverId: ForwardTo,
      AttachmentIds: [],
    };
    let LoanSalaryHead = [];
    // let PaymentSchedule = [];
    incomes.forEach((inc) => {
      if (inc.alias >= 0)
        LoanSalaryHead.push({
          Title: inc.title,
          Amount: inc.amount,
          IsIncome: true,
        });
    });
    consumerLoanData = {
      ...consumerLoanData,
      LoanSalaryHead,
    };
    sendSaveorDraftRequest(1, consumerLoanData);
  };
  const handleDraftSubmit = (status) => {
    const {LoanAmount, PurposeOfLoan, InterestRate, RepaymentTenure, AvailabilityBranch} =
      getValues();
    let consumerLoanData: any = {};
    if (!isEmpty(LoanAmount)) consumerLoanData.LoanAmount = LoanAmount;
    if (!isEmpty(PurposeOfLoan)) consumerLoanData.PurposeOfLoan = PurposeOfLoan.value;
    if (!isEmpty(InterestRate)) consumerLoanData.InterestRate = InterestRate;
    if (!isEmpty(RepaymentTenure)) consumerLoanData.RepaymentTenure = RepaymentTenure;
    if (!isEmpty(AvailabilityBranch))
      consumerLoanData.LoanAvailingBranchId = AvailabilityBranch.value;
    consumerLoanData = {
      SocialLoan: {
        ...consumerLoanData,
        Id: isEditMode ? loanId : 0,
        StatusName: "Draft",
        Status: 0,
        IsScheduled: isScheduledButton,
        CurrentApprovalSelectedEmployeeId: ForwardTo,
      },
      LoanSalaryHead: [],
      PaymentSchedule: [],
      ApproverId: ForwardTo,
      AttachmentIds: [],
    };
    sendSaveorDraftRequest(status, consumerLoanData);
  };

  const sendSaveorDraftRequest = async (status, consumerLoanData) => {
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };
    FileList &&
      FileList.map((doc, i) => {
        if (doc.AttachmentID) consumerLoanData.AttachmentIds.push(doc.AttachmentID);
      });
    const formData: any = objectToFormData(consumerLoanData, options);
    FileList &&
      FileList.map((doc, i) => {
        if (!doc.AttachmentID) formData.append(`Files`, doc);
      });
    let consumerLoansaved = await dispatch(
      saveSocialLoanRequest(formData, status, isEditMode)
    );
    if (consumerLoansaved) {
      props.history.push(RouteEnum.MyAppliedLoanRequests);
    }
  };

  const onForwardToChange = (name, value) => {
    setForwardTo(value);
  };

  const handleFormChange = (name, value) => {
    if (name == "RepaymentTenure" && parseInt(value) > PaymentPeriods) {
      let message = {
        Message: "Your maximum Repayment Tenure is " + PaymentPeriods,
        MessageType: "warning",
      };
      dispatch(DisplayClientSideMessage(message));
      setValue(name, PaymentPeriods);
      return;
    }
    if (
      (name == "LoanAmount" || name == "RepaymentTenure") &&
      value < 1 &&
      value !== ""
    ) {
      let message = {
        Message:
          (name == "LoanAmount" ? "Loan Amount" : "Replayment Tenure") +
          " must be greater than 0",
        MessageType: "warning",
      };
      dispatch(DisplayClientSideMessage(message));
      return;
    }
    setValue(name, value);
    setPaymentScheduleButtonClicked(false);
  };

  const showDocumentModal = (file, flag) => {
    if (flag) window.open(window.URL.createObjectURL(file), "_blank");
    else if (file) {
      const param = {
        serverFileName: file.ServerFileName.replace("Uploads/", ""),
        userFileName: file.UserFileName,
        fileType: file.FileType,
      };
      window.open(getFullStringUrl(`${hostedUrl}${Apis.common.downloadFiles}`, param));

      // let fileURI = "";
      // if (!file?.isFetched) fileURI = URL.createObjectURL(file);
      // else fileURI = getUploadPath(`/${file.ServerFileName}`);
      // window.open(fileURI, "_blank");
    }
  };
  const {InterestRate} = getValues();
  return (
    <div className="consumer-loan">
      <div className="tasktracker-title-section">
        <Breadcrumb name="Loan Request" />
      </div>
      <div className="loan-name mb-md mt-md">Social Loan</div>
      <EmployeeInfo detailView={false} />
      <div className="consumer-loan-body">
        <FormGroup method={methods} defaultValues={defaultValues} onSubmit={onSubmit}>
          <div className="consumer-loan__amount consumer-loan__commonborder">
            <div className="consumer-loan__amount__name mb-md">
              Loan Amount and Purpose
            </div>
            <div className="consumer-loan__content mb-md">
              <FormItem
                name="LoanAmount"
                label="Loan Amount"
                width="200px"
                type="number"
                rules={{
                  required: "Loan Amount is required!",
                  min: {value: 1, message: "Should be greater than 0"},
                }}
                onChange={(name, value) => handleFormChange(name, value)}
              />
            </div>
            <div className="consumer-loan__content flex">
              <FormItem
                formName="reactselect"
                options={consumerLoanPurposeList || []}
                name="PurposeOfLoan"
                label="Purpose of Loan"
                width="460px"
                placeholder="Select a Loan Purpose"
                rules={{required: "Loan Purpose is required!"}}
              />
              <FormItem
                name="InterestRate"
                label="Interest Rate"
                width="150px"
                disabled={true}
                rules={null}
                value={`${InterestRate}%`}
              />
            </div>
          </div>
          <div className="consumer-loan__payment consumer-loan__commonborder">
            <div className="consumer-loan__content consumer-loan__payment__button flex mb-md">
              <FormItem
                name="RepaymentTenure"
                label="Repayment Tenure"
                width="200px"
                type="number"
                // minValue="1"
                maxValue={PaymentPeriods?.toString()}
                rules={{
                  required: "Repayment Tenure is required!",
                  min: {value: 1, message: "Should be greater than 0"},
                }}
                // rules={{ required: 'Repayment Tenure is required!' }}
                onChange={(name, value) => handleFormChange(name, value)}
              />
              <Button
                bg="primary"
                size={"large"}
                title="Payment Schedule"
                onClick={() => calculatePaymentSchedules()}
              />
            </div>
            <div className="consumer-loan__payment__schedule">
              {/* <div className="consumer-loan__payment__schedule__title mb-sm text-md">
                Payment Schedule
              </div> */}
              <LoanDataTable data={loanPaymentSchedule} title="Payment Schedule" />
            </div>
          </div>
          {isFileFetched && (
            <div className="consumer-loan__uploads consumer-loan__commonborder">
              <span className="consumer-loan__uploads__title mb-sm text-md mr-bg">
                Upload Documents
              </span>
              <span className="subtext">(JPEG, PDF supported)</span>
              <div className="consumer-loan__content consumer-loan__payment__button flex mt-sm">
                <Upload
                  fileList={FileList}
                  setSelectedFiles={(fileList) => onFileChange(fileList)}
                  uploadBtnText="Upload document"
                  noSelectedText="No document selected"
                  multiple={true}
                  // isDifferentSource={true}
                  showModal={true}
                  showDocumentModal={(file, flag) => showDocumentModal(file, flag)}
                  allowedFileTypes={["pdf", "jpeg"]}
                />
              </div>
            </div>
          )}
          <div className="consumer-loan__grosssalary consumer-loan__commonborder">
            {/* <div className="consumer-loan__grosssalary__title mb-sm text-md">
              Gross Salary
            </div> */}
            <LoanDataTable type="grosssalary" data={incomes} title="Gross Salary" />
          </div>
          <div className="consumer-loan__presentdeduction consumer-loan__commonborder">
            {/* <div className="consumer-loan__presentdeduction__title mb-sm text-md">
              Present Deductions
            </div> */}
            <LoanDataTable data={deductions} title="Present Deductions" />
          </div>
          <div className="consumer-loan__availing consumer-loan__commonborder">
            <div className="consumer-loan__content flex">
              <FormItem
                formName="reactselect"
                options={branchList}
                name="AvailabilityBranch"
                label="Loan Availing Branch"
                width="460px"
                rules={{required: "Loan Availing Branch is required!"}}
              />
            </div>
          </div>
          <div className="consumer-loan__availing consumer-loan__commonborder">
            <div className="consumer-loan__content flex">
              {/* {(!EditView || IsDraft) && ( */}
              <Form
                formName="checkBoxGroup"
                name="ForwardTo"
                label={
                  (loanApproverList && loanApproverList[0]?.AuthorityTypeDisplayName) ||
                  ""
                }
                checkGroupArray={loanApproverList}
                checkedGroupValue={ForwardTo}
                onChange={(name, value) => onForwardToChange(name, value)}
                // validators={["required"]}
                width="100%"
              />
              {/* )} */}
            </div>
          </div>
          <div className="consumer-loan__declaration">
            <div className="consumer-loan__commonborder consumer-loan__declaration">
              <FormItem
                formName="checkgroup"
                name="consensus"
                label={consensus}
                checked={consensusStatus}
                onChange={() => setConsensusStatus(!consensusStatus)}
              />
            </div>
            <div className="consumer-loan__commonborder consumer-loan__declaration">
              <FormItem
                formName="checkgroup"
                name="conditions"
                label={conditions}
                checked={conditionsStatus}
                onChange={() => setConditionsStatus(!conditionsStatus)}
              />
            </div>
            <div className="consumer-loan__commonborder consumer-loan__declaration">
              <FormItem
                formName="checkgroup"
                name="declaration"
                label={declaration}
                checked={declarationStatus}
                onChange={() => setDeclarationStatus(!declarationStatus)}
              />
            </div>
          </div>

          <div className="consumer-loan-footer mt-md">
            <div className="consumer-loan-footer__item">
              <Button bg="subtle" onClick={() => props.history.goBack()} title="Cancel" />
            </div>
            <div className="consumer-loan-footer__item flex justify-between">
              <Button
                bg="primary"
                onClick={() => handleDraftSubmit(0)}
                title="Save as Draft"
                isDisabled={savedSocialLoanRequest}
              />
              <Button
                bg="primary"
                title="Send Request"
                htmlButtonType="submit"
                isDisabled={savedSocialLoanRequest}
              />
            </div>
          </div>
        </FormGroup>
      </div>
    </div>
  );
}

export default SocialLoanForm;
