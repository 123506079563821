import React, { useEffect, useState } from 'react'
import Button from 'components/button';
import GoalFilter from './goalFilter';
import GoalModal from './goalModal';
import {getAppraisalGoalDatabases} from '../helpers/action';
import { useDispatch } from 'react-redux';
import GoalTable from './goalTable';


const initialFilters = {
  ReviewCycle: null,
  SearchEmployee: null,
  Branch: null,
  Level: null,
  Department: null,
  pageIndex: 0,
  pageSize: 10
}

function GoalDatabase() {
  const [filters, setFilters] = useState(initialFilters);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false)
  const onModalClose = () => setOpen(false);
  useEffect(()=>{
    getGoals()
  },[])
  const onChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleRowChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0
      }
    })
  };

  const handleIndexChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageIndex: num
      }
    })
  };
  const onFilter = () => {
    getGoals()
  }
  const getGoals = () => {
    dispatch(getAppraisalGoalDatabases(filters))
  }
  return (
    <div className='appraisals-container'>
      <div className="appraisals-title">
        <span className='flex'>
          <span>Goal Databases</span>
          <Button 
              title='Add'
              bg='primary'
              onClick={()=>setOpen(true)}
            />  
        </span>
      </div>
      <GoalFilter filters={filters} onChange={onChange} onFilter={onFilter} />
      <GoalModal open={open} onModalClose={onModalClose} />
      <GoalTable />
    </div>
  )
}

export default GoalDatabase