import React, {useState} from "react";
import Button from "components/button";
import Modal from "components/modal";
import AddShiftTemplate from "./addShiftTemplate";
import {MdAdd} from "react-icons/md";
import Table from "components/customTable";
import Confirm from "components/common/commonConfimation";
import {useDispatch, useSelector} from "react-redux";
import {deleteRosterShiftPattern, getRosterPatternList} from "../helpers/action";

const ButtonCell = (props) => {
  const [open, setOpen] = useState(false);
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();
  const onDelete = () => {
    dispatch(deleteRosterShiftPattern(props?.value));
  };
  return (
    <div className="shifts-table__col">
      <div className="shifts-table__links">
        <span onClick={() => onModalOpen()}>Edit</span> |{/* <span>Archive</span> | */}
        <Confirm title="Delete Shift Pattern" okButtonTitle="Delete" action={onDelete}>
          <span>Delete</span>
        </Confirm>
      </div>
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Add Shift Pattern"
        className="hide-modal-overflow"
      >
        <AddShiftTemplate
          isEdit
          editData={props?.row?.original}
          onModalClose={onModalClose}
        />
      </Modal>
    </div>
  );
};

function ShiftPattern() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const onModalOpen = () => setOpen(true);

  const onModalClose = () => setOpen(false);

  const {patternList} = useSelector((state: any) => state.roosterReducer);

  React.useEffect(() => {
    document.title = "Rooster Shift Pattern";
    getShiftPatterns();
  }, []);

  const getShiftPatterns = async () => {
    dispatch(getRosterPatternList());
  };

  const cols = [
    {
      Header: "Shift Pattern Name",
      accessor: "Name",
      width: 160,
    },
    {
      Header: "Description",
      accessor: "Description",
      width: 160,
    },
    {
      Header: "Total No Of Days",
      accessor: "TotalNoOfDays",
      width: 160,
    },
    {
      Header: "",
      accessor: "Id",
      Cell: ButtonCell,
      width: 160,
    },
  ];

  return (
    <div className="shifts">
      <div className="shifts-container">
        <div className="shifts-title">Shift Pattern</div>
        <div className="mb-sm">
          <Button
            title="Add Shift Pattern"
            bg="primary"
            buttonType="icon-button"
            icon={<MdAdd />}
            onClick={() => onModalOpen()}
          />
        </div>
        <Table isFixedWidth data={patternList} columns={cols} />
      </div>
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Add Shift Pattern"
        className="hide-modal-overflow"
      >
        <AddShiftTemplate onModalClose={onModalClose} />
      </Modal>
    </div>
  );
}

export default ShiftPattern;
