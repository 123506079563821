import React from "react";
import Icons from "components/icons";
import Button from "components/button";
import Modal from "components/modal";
import {connect} from "react-redux";
import {MdAdd} from "react-icons/md";
import AccessIdsForm from "./form";
import {
  AddAccessAndIdTemplate,
  GetAllAccessAndIdCategoryTemplate,
  GetSalaryTemplateDetails,
  UpdateAccessAndIdTemplate,
  DeleteSalaryTemplate,
} from "./accessAndIdTemplateAction";
import AccessAndIdTable from "./table";
import "./style.scss";

const initialState = {
  open: false,
  openNewTemplate: false,
  errors: null,
  editView: false,
  editViewData: {},
  selectedId: null,
  modalState: {show: false, type: "normal"},
};
interface IAppProps {
  GetPayrollIncomeHeads: any;
  AddAccessAndIdTemplate: any;
  saveAccessAndIdTemplate: any;
  GetAllAccessAndIdCategoryTemplate: any;
  accessAndIdTemplateList: any;
  GetSalaryTemplateDetails: any;
  accessAndIdTemplateDetail: any;
  UpdateAccessAndIdTemplate: any;
  DeleteSalaryTemplate: any;
}

interface State {
  open: any;
  openNewTemplate: boolean;
  errors?: any;
  editView?: boolean;
  editViewData?: any;
  selectedId?: any;
  modalState?: any;
}

class index extends React.Component<IAppProps, State> {
  state = initialState;

  componentDidMount = async () => {
    await this.props.GetAllAccessAndIdCategoryTemplate();
  };

  handleFieldChange = (name, value) => {
    this.setState({[name]: value} as Pick<State, keyof State>);
  };

  editSelectedTemplate = async (templateData) => {
    let templateDetail =
      templateData?.TemplateDetailData?.length > 0 &&
      templateData?.TemplateDetailData.map((template) => {
        return {
          CategoryRefId: template?.CategoryRefId,
          Id: template?.Id,
          TypeName: template?.TypeName,
          ResponsiblePerson: {
            label: template?.ResponsiblePersonName,
            value: template?.ResponsiblePersonId,
          },
        };
      });
    this.setState({
      editViewData: {
        categoryName: templateData?.CategoryName,
        categoryId: templateData?.Id,
        rowDatas: templateDetail,
      },
      editView: true,
      openNewTemplate: true,
    });
  };

  deleteSelectedTemplate = async () => {
    await this.props.DeleteSalaryTemplate(this.state.selectedId);
    this.props.GetAllAccessAndIdCategoryTemplate();
  };

  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };

  handleModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };

  render() {
    const {openNewTemplate, editView, editViewData} = this.state;

    const {accessAndIdTemplateList} = this.props;
    const {modalState} = this.state;

    return (
      <div className="salaryTemplate">
        <div className="flex mb-20">
          <div>
            <span>Access & ID Templates</span>
          </div>
          <div className="salaryTemplate-header ml-20">
            <Button
              bg="primary"
              buttonType="icon-button"
              icon={<Icons name="Add" />}
              leftIcon={<MdAdd />}
              onClick={() =>
                this.setState(
                  {
                    ...initialState,
                  },
                  () => this.setState({openNewTemplate: true})
                )
              }
              title="Add Template"
            />
          </div>
        </div>
        <div className="salaryTemplate-container">
          <AccessAndIdTable
            data={accessAndIdTemplateList}
            editSelectedTemplate={this.editSelectedTemplate}
            handleConfirmDelRecord={this.handleConfirmDelRecord}
            handleModalShow={this.handleModalShow}
          />
        </div>
        {openNewTemplate && (
          <Modal
            open={openNewTemplate}
            onModalClose={() => this.setState({openNewTemplate: false})}
          >
            <AccessIdsForm
              editView={editView}
              editViewData={editViewData}
              showForm={openNewTemplate}
              closeForm={() => this.setState({openNewTemplate: false})}
            />
          </Modal>
        )}

        {modalState.show && (
          <Modal
            title={"Access & ID Template"}
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={this.deleteSelectedTemplate}
          >
            <></>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveAccessAndIdTemplate: state.accessAndIdTemplateReducer.saveAccessAndIdTemplate,
  accessAndIdTemplateList: state.accessAndIdTemplateReducer.accessAndIdTemplateList,
  accessAndIdTemplateDetail: state.accessAndIdTemplateReducer.accessAndIdTemplateDetail,
});

const mapDispatchToProps = {
  AddAccessAndIdTemplate,
  GetAllAccessAndIdCategoryTemplate,
  GetSalaryTemplateDetails,
  UpdateAccessAndIdTemplate,
  DeleteSalaryTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
