import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {AiOutlineSearch} from "react-icons/ai";
import {GetPrefixedEmpNames} from "actions/commonAction";
import Avatar from "react-avatar";
import {FaFileInvoice} from "react-icons/fa";
import classNames from "classnames";
import useEventListener from "helpers/customHooks/useEventListener";
import SvgIcons from "components/icons/svgIcons";

const SearchEmployeeMenu = (props) => {
  const {auth, onModalClose} = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const [categoryWiseList, setCategoryWiseList] = useState([]);
  const dispatch = useDispatch();
  const {user, visible, baseUrl} = auth;
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
  const [searchText, setSearchText] = useState("");
  const [searchedItems, setSearchItems] = useState([]);
  const history = useHistory();

  setTimeout(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, 500);

  useEffect(() => {
    if (categoryWiseList) {
      var newItems = [];
      for (let i = 0; i < categoryWiseList.length; i++) {
        newItems = [...newItems, ...categoryWiseList[i].items];
      }
      setSearchItems(newItems);
    }
  }, [categoryWiseList]);

  const inputElement = useRef(null);

  const handleKeyUp = (event) => {
    var key = event.key;
    var block = "start";
    let newIndex = activeIndex;
    let margin = false;

    if (key === "ArrowUp") {
      newIndex--;
    } else if (key === "ArrowDown") {
      newIndex++;
      block = "end";
    } else if (key === "Enter") {
      onEnter(newIndex);
    }

    if (newIndex < 0) newIndex = 0;
    if (newIndex >= searchedItems.length) newIndex = searchedItems.length - 1;

    setActiveIndex(newIndex);

    var el: any = document.querySelector(".cat-section__active");
    if (el) {
      var position = el.getBoundingClientRect();
      if (position.bottom >= 550 || position.bottom <= 300) {
        el.scrollIntoView({behavior: "smooth", block: block, inline: "nearest"});
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();
  };
  useEventListener("keyup", handleKeyUp);
  useEventListener("keydown", handleKeyDown);

  const onEnter = (index) => {
    let item = searchedItems[index];
    if (item?.EmployeeId) {
      onClickPeople(item);
    } else {
      onClickPageReport(item);
    }
  };

  const onChangeSearch = async (e) => {
    let searchValue = (e.target.value && e.target.value.toLowerCase()) || "";

    const {features} = props;
    var pages =
      features &&
      features.filter(
        (x) =>
          x.Category !== 3 &&
          x.SkipSearch === false &&
          (x.LinkTitle.toLowerCase().includes(searchValue) || searchValue === "")
      );
    var reports =
      features &&
      features.filter(
        (x) =>
          x.Category === 3 &&
          x.SkipSearch === false &&
          (x.LinkTitle.toLowerCase().includes(searchValue) || searchValue === "")
      );

    var items = [];
    var peoples = [];
    setSearchText(searchValue);

    if (searchValue.length > 2) {
      var res = await dispatch(GetPrefixedEmpNames(searchValue));

      if (res) peoples = res.Data;

      items = [
        {title: "People", items: peoples},
        {title: "Pages", items: pages},
        {title: "Reports", items: reports},
      ];
    }
    setCategoryWiseList(items);
  };

  const onClickPageReport = (item) => {
    if (item?.IsReactBased) {
      history.push(`/${item?.Link}`);
    } else {
      window.open(`${base}/${item?.Link}`, "_blank");
    }
    onModalClose();
  };

  const onClickPeople = (item) => {
    history.push({
      pathname: `/employee/profile/${item?.EmployeeId}`,
    });
    //history.replace(`/employee/profile/${item.EmployeeId}`);
    onModalClose();
  };

  const handleMouseOver = (catIndex) => {
    setActiveIndex(catIndex);
  };

  const renderPageReport = (item, index) => {
    return (
      <li>
        <div
          key={index}
          className={classNames("cat-section__item", {
            "cat-section__active": index === activeIndex,
          })}
          onClick={() => onClickPageReport(item)}
          onMouseOver={() => handleMouseOver(index)}
        >
          <div>
            <FaFileInvoice size="30" color="d6d6d6" />
          </div>
          <div className="cat-section__item-right">
            <div className="cat-section__item-title">{item.LinkTitle}</div>
            <div className="cat-section__item-sub-title">{item.Feature}</div>
          </div>
        </div>
      </li>
    );
  };

  const renderPeople = (item, index) => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    const imageUrl = base + "Uploads/" + item.PhotoUrl;
    return (
      <li>
        <div
          key={index}
          onMouseOver={() => handleMouseOver(index)}
          className={classNames("cat-section__item", {
            "cat-section__active": index === activeIndex,
          })}
          onClick={() => onClickPeople(item)}
        >
          <div>
            <Avatar name={item.Name} size="35" round={true} src={imageUrl} />
          </div>
          <div className="cat-section__item-right">
            <div className="cat-section__item-title">{item.Name}</div>
            <div className="cat-section__item-sub-title">{`${item.Department}, ${item.Branch}`}</div>
            {/* { image:{imageUrl} } */}
          </div>
        </div>
      </li>
    );
  };

  const getItemLink = (cat, item, itemIndex, catIndex) => {
    var totalItems = 0;

    for (var i = itemIndex; i > 0; i--) {
      totalItems = totalItems + categoryWiseList[i - 1].items.length;
    }

    var index = totalItems + catIndex;

    if (cat === "People") return renderPeople(item, index);
    return renderPageReport(item, index);
  };

  return (
    <div className="header-em-box">
      <div className="header-em-box-search">
        <div className="header-em-box-search__bar">
          <div className="header-em-box-search__icon">
            <AiOutlineSearch />
          </div>
          <input
            ref={inputElement}
            className="header-em-box-search__input"
            type="text"
            id="header-search"
            autoComplete="off"
            autoFocus
            value={searchText}
            placeholder="Search employee or functions"
            onChange={(e) => onChangeSearch(e)}
          />
        </div>
      </div>
      <div className="header-em-box-body">
        {categoryWiseList.map(
          (category, index) =>
            category.items.length > 0 && (
              <div key={index} className="cat-section">
                <div className="cat-section__title">
                  <span className="cat-section__title-name">{category.title}</span>
                  <span className="cat-section__title-info">
                    {category.items.length}{" "}
                    {category.items.length === 1 ? "Result" : "Results"}
                  </span>
                </div>
                <ul>
                  {category.items.map((item, catIndex) =>
                    getItemLink(category.title, item, index, catIndex)
                  )}
                </ul>
              </div>
            )
        )}
      </div>
      <div className="header-em-box-footer">
        <div className="header-em-box-footer-item">
          <SvgIcons name="enter" color="#405471" />
          <span>to select</span>
        </div>

        <div className="header-em-box-footer-item">
          <SvgIcons name="up-arrow" color="#405471" />
          <SvgIcons name="down-arrow" color="#405471" />

          <span>to navigate</span>
        </div>

        <div className="header-em-box-footer-item">
          <SvgIcons name="escape" color="#405471" />
          <span>to close</span>
        </div>
      </div>
    </div>
  );
};
export default SearchEmployeeMenu;
