import React from "react";
import Title from "scenes/common/title";
import MessageListView from "../messageListView";
import "./style.scss";

function ViewAllMessages() {
  return (
    <div className="viewall-container">
      <Title name="Messages" />
      <MessageListView />
    </div>
  );
}

export default ViewAllMessages;
