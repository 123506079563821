import React, {Component} from "react";
import {Skeleton} from "@material-ui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import Icons from "components/icons";
import Button from "components/button";
import isEmpty from "isEmpty";

export class MessageTemplateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    this.widths = {
      name: 100,
    };
    this.gridTemplate = this.gridTemplate.bind(this);
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({data: this.props.data});
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data && prevProps.data !== this.props.data) {
      this.setState(() => ({
        data: this.props.data,
      }));
    }
  }

  gridTemplate(props) {
    return (
      <div className="btn-col flex flex-gap-10">
        <Button
          title="Edit"
          bg="primary"
          type="button-outline"
          onClick={() => this.props.editSelectedTemplate(props)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Edit" />}
        />
        <Button
          title="Delete"
          bg="primary"
          type="button-outline"
          onClick={() => this.props.handleConfirmDelRecord(props?.Id)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Delete" />}
        />
      </div>
    );
  }

  render() {
    const {data} = this.state;
    const {loading} = this.props;

    if (!data) {
      return null;
    }

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          {loading ? (
            <div className="skeleton-table">
              <Skeleton variant="rect" width={"100%"} height={55} />
              <Skeleton variant="rect" width={"100%"} height={55} />
            </div>
          ) : (
            <GridComponent
              className="custom-grid"
              dataSource={isEmpty(data) ? null : data}
              id="grid"
              ref={(g) => (this.grid = g)}
            >
              <ColumnsDirective>
                <ColumnDirective field="Name" headerText="Name" width="80%" />
                <ColumnDirective field="" width="20%" template={this.gridTemplate} />
              </ColumnsDirective>
            </GridComponent>
          )}
        </div>
      </div>
    );
  }
}

export default MessageTemplateTable;
