import React, {Component} from "react";
import man from "assets/images/man.png";
import {trash} from "assets/images/svgs/icons/trash";
import {email} from "assets/images/svgs/icons/email";
import emailOpen from "assets/images/svgs/icons/emailOpen";
import {formatedShortMonthDayYear} from "helpers/dateFormat";

function MessageCard({item}) {
  const [height, setHeight] = React.useState(false);
  return (
    <li className="messages-list__item">
      <div className="message">
        <div className="message__image">
          <div className="img-circle img-circle-60">
            <img src={man} alt="" />
          </div>
        </div>
        <div className="message__notice">
          <p className="notice-title">Notice</p>
          <span className="notice-time">
            {formatedShortMonthDayYear(item.ReceivedDateEng)}
          </span>
        </div>
        <div className="message__data">
          <div className="message-title">
            <h4>{item.Subject}</h4>
          </div>
          <div className="message-content">
            <p
              style={{height: height ? "auto" : 48}}
              dangerouslySetInnerHTML={{__html: item.Body}}
            >
              {/* {item.Body} */}
            </p>
            <div onClick={() => setHeight(!height)} className="message-content-btn">
              {height ? "Hide Message" : "View Full Message"}
            </div>
          </div>
        </div>
        {/* <div className="message__icons">
          <div className="message__icons-item">
            <div className="svg-icon email-icon">{email()}</div>
          </div>
          <div className="message__icons-item">
            <div className="svg-icon email-open-icon">{emailOpen()}</div>
          </div>
          <div className="message__icons-item">
            <div className="svg-icon trash-icon">{trash()}</div>
          </div>
        </div> */}
      </div>
    </li>
  );
}

export default MessageCard;
