import React, { Component } from "react";
import {Tabs,Tab} from 'components/tabs/index.d';
import { connect } from "react-redux";
import './styles/style.scss';
import Company from './company';
import Travel from './travel';
import Loan from './loan';
import SalaryTemplate from './salaryTemplate';
import {isFeatureEnabledValue} from 'helpers/checkPermissions';
import { Features } from "helpers/getPermissions";

interface IAppProps {
    companyInformation: any,
    employeeHealthDetail: Function,
}

interface State {
    activeTab: any,
}

export class Settings extends Component<IAppProps, State> {
    constructor(props) {
        super(props);
    
        this.state = {
          activeTab:''
        };
        this.activeTab=this.activeTab.bind(this)
      }
      activeTab(name){
        this.setState({activeTab:name})
      }
    render() {
        const { companyInformation } = this.props;
        const ref = React.createRef();
        const features = Features();
        console.log("companyInformation", isFeatureEnabledValue(features.Loan) && (companyInformation?.CompanyId == 104 ||
            companyInformation.CompanyId == 0), companyInformation.CompanyId, companyInformation)
        return (
            <div className="settings">
                <Tabs>
                    <Tab label="Company Settings" name="Company Settings" >
                        <Company />
                    </Tab>
                    {/* {
                        !isFeatureEnabledValue(features.TravelManagement) ? null :
                        <Tab 
                            label="Travel Settings" name="Travel Settings">
                            <Travel />
                        </Tab>
                    } */}

                    <Tab 
                        label="Salary Settings" name="Salary Settings">
                        <SalaryTemplate />
                    </Tab>
                    {isFeatureEnabledValue(features.Loan) && (companyInformation?.CompanyId == 104 ||
                    companyInformation.CompanyId == 0) ? 
                    <Tab 
                        label="Loan Settings" name="Loan Settings">
                        <Loan />
                    </Tab> : null
                     }
                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);