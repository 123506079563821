import {
  GET_TIME_REQUEST_DETAIL,
  GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
  CREATE_TIME_REQUEST_REQUEST,
  CREATE_TIME_REQUEST_SUCCESS,
  CREATE_TIME_REQUEST_FAILURE,
  UPDATE_TIME_REQUEST_REQUEST,
  UPDATE_TIME_REQUEST_SUCCESS,
  UPDATE_TIME_REQUEST_FAILURE,
  GET_SHIFT_NAMES_LIST,
} from "actions/types";

const initialState = {
  timeRequestDetail: null,
  timeRequestApprovalRecommended: null,
  requesting: false,
  shifts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIME_REQUEST_DETAIL:
      return {
        ...state,
        timeRequestDetail: action.payload,
      };
    case GET_TIME_REQUEST_APPROVAL_RECOMMENDED:
      return {
        ...state,
        timeRequestApprovalRecommended: action.payload,
      };
    case CREATE_TIME_REQUEST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case CREATE_TIME_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case CREATE_TIME_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case UPDATE_TIME_REQUEST_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case UPDATE_TIME_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case UPDATE_TIME_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
      };
    case GET_SHIFT_NAMES_LIST:
      return {
        ...state,
        shifts: action.payload,
      };

    default:
      return state;
  }
}
