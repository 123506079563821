import {
    GET_LEAVE_TIME_DASHBOARD,
    GET_LEAVE_BY_DATE,
    LEAVE_LOADING,
    ADD_LOADING
} from 'actions/types';
import axios from 'helpers/axiosinstance';
import isEmpty from "isEmpty";
import { toastMessage } from 'actions/validateAction';
import {loadingHeaders} from 'helpers/getHeaders'

export const getLeaveTimeDashboard = () => async dispatch => {
    await axios
      .get(
        `/v1/leave-time/admin/information`
      )
      .then(response => {
        dispatch({type:ADD_LOADING,payload:false})
        if (response.data.Status) {
          dispatch({
            type: GET_LEAVE_TIME_DASHBOARD,
            payload: response.data.Data
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        dispatch({
            type: GET_LEAVE_TIME_DASHBOARD,
            payload: null
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message?err.message:"Failed to get leave time dashboard."
          }
        };
        toastMessage(dispatch, response);
      });
  };

export const getLeaveBydate = (tab) => async dispatch =>{
  dispatch({type:LEAVE_LOADING})
  await axios
      .get(
        `/v1/leave-time/employees/leave/${tab}`,
        loadingHeaders
      )      .then(response => {
        if (response.data.Status) {
          dispatch({
            type: GET_LEAVE_BY_DATE,
            payload: response.data.Data
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        dispatch({
            type: GET_LEAVE_BY_DATE,
            payload: null
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message?err.message:"Failed to get leave by time."
          }
        };
        toastMessage(dispatch, response);
      });
}