import React, {Component} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import {InputValidator} from "helpers/inputValidator";
import {
  DisplayClientSideMessage,
  GetOverTimeTypes,
  GetCompanyInformation,
} from "actions/commonAction";
import Button from "components/button";
import isEmpty from "isEmpty";
import Icons from "components/icons";
import Form from "components/form";
import SelectEmployee from "scenes/employee/selectEmployee";
import {EmployeeID} from "constants/userDetails";
import {Date_Format_Reverse} from "constants/dateFormat";
import {convertTime12to24} from "helpers/timeConvert";
import {Date_Format} from "helpers/dateFormat";
import moment from "moment";
import {BS2AD, AD2BS} from "helpers/dateConvert";
import {MdInfo} from "react-icons/md";
import {GetAuditLogOfRequests} from "scenes/employee/requests/actions";
import {EMPLOYEE_OVERTIME_REQUEST_MODULE_VALUE} from "constants/constants";
import Loaders from "components/loaders";
import changeMinToHourMinTimeFormat from "helpers/changeMinToHourMinTimeFormat";

import "./overtime-request.style.scss";
import {GetAttendanceByDate} from "../time/attendance/action";
import {
  saveEmployeeOvertimeRequests,
  GetOverTimeApprovalReviewerList,
  updateEmployeeOvertimeRequests,
} from "./action";

const minDate = new Date();
minDate.setDate(minDate.getDate() - 6);
const maxDate = new Date();

function timeAttendanceTemplate(id) {
  return {
    id: id,
    date: null,
    startTime: null,
    endTime: null,
    deviceInTime: null,
    deviceOutTime: null,
    note: "",
    timeGap: 0,
  };
}
export class OvertimeRequest extends Component {
  state = {
    editView: false,
    checkedApprover: null,
    checkedReviewer: null,
    timeGap: 0,
    OverTimeType: null,
    Reason: "",
    overTimeApprovalReviewList: {},
    employeeToAssign: null,
  };

  async componentDidMount() {
    const {GetOverTimeTypes, editMode, overtimeRequestId, GetCompanyInformation} =
      this.props;
    // await GetCompanyInformation();
    await GetOverTimeTypes();

    const {
      overTimeTypeList,
      companyInformation,
      isApprover,
      user: {EmployeeId},
    } = this.props;

    if (editMode && !isEmpty(overtimeRequestId)) {
      this.props.GetOverTimeApprovalReviewerList(EmployeeId);
      const {attendanceByDate, overTimeApprovalReviewList} = this.props;
      let getSelectedAllowanceType =
        overTimeTypeList &&
        overTimeTypeList.filter((item) => item.label == overtimeRequestId.OvertimeType);
      let filteredApproveReviewList = {
        approverList: [
          {
            Id: overtimeRequestId.ApprovalId,
            FullName: overtimeRequestId.Approval,
            Imagepath: overtimeRequestId.ApprovalImage,
            Designation: "",
          },
        ],
        reviewerList: [
          {
            Id: overtimeRequestId.RecommenderId,
            FullName: overtimeRequestId.Reccommender,
            Imagepath: overtimeRequestId.RecommendedImage,
            Designation: "",
          },
        ],
      };

      let timeAttendance =
        overtimeRequestId.OvertimeRequests &&
        overtimeRequestId.OvertimeRequests.map((ot, i) => {
          let convertedRequestedDate = moment(ot.RequestDateEng)
            .format("YYYY/MM/DD")
            .toString();
          if (!companyInformation.IsEnglishDate) {
            let conversionDate = AD2BS(convertedRequestedDate).en;
            convertedRequestedDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
          }

          const attendanceForDate = attendanceByDate.find(
            (f) => Date_Format(f.DateEng) == Date_Format(ot.RequestDateEng)
          );
          return {
            id: i + 1,
            date: convertedRequestedDate,
            startTime: ot.StartTime ? convertTime12to24(ot.StartTime) : null,
            endTime: ot.EndTime ? convertTime12to24(ot.EndTime) : null,
            timeGap: ot.RequestedMin,
            note: ot.Reason,
            requestId: ot.RequestId,
            deviceInTime:
              ot.InTimeDv || attendanceForDate?.InTimeDv || attendanceForDate?.InTime,
            deviceOutTime:
              ot.OutTimeDv || attendanceForDate?.OutTimeDv || attendanceForDate?.OutTime,
            overtimeRequestLineId: ot.OvertimeRequestLineId,
          };
        });
      if (isApprover) filteredApproveReviewList = filteredApproveReviewList;
      else filteredApproveReviewList = overTimeApprovalReviewList;

      let checkedApprover = overtimeRequestId.ApprovalId;
      let checkedReviewer = overtimeRequestId.RecommenderId;
      this.setState({
        checkedReviewer,
        checkedApprover,
        OverTimeType: getSelectedAllowanceType[0],
        overTimeApprovalReviewList: filteredApproveReviewList,
        timeAttendance,
        editView: true,
      });
    } else {
      this.manipulateApproverReviewer(EmployeeId);
    }
  }

  manipulateApproverReviewer = async (employeeId = null) => {
    await this.props.GetAttendanceByDate(minDate, maxDate, employeeId);
    await this.props.GetOverTimeApprovalReviewerList(employeeId);
    const {overTimeApprovalReviewList} = this.props;

    let checkedReviewer = null;
    let checkedApprover = null;
    if (overTimeApprovalReviewList?.reviewerList?.length == 1)
      checkedReviewer = overTimeApprovalReviewList.reviewerList[0].Id;
    if (overTimeApprovalReviewList?.approverList?.length == 1)
      checkedApprover = overTimeApprovalReviewList.approverList[0].Id;
    this.setState({
      timeAttendance: [timeAttendanceTemplate(1)],
      overTimeApprovalReviewList,
      checkedReviewer,
      checkedApprover,
    });
  };

  handleChange = (name, value, id) => {
    let {timeAttendance} = this.state;
    let selectedOvertime = timeAttendance.find((f) => f.id == id);
    selectedOvertime[name] = value;
    if (value && name == "date") {
      // let selectedDate =
      let convertedSelectedDate = null;
      if (!this.props.companyInformation.IsEnglishDate) convertedSelectedDate = value;
      else convertedSelectedDate = Date_Format_Reverse(value);

      selectedOvertime[name] = convertedSelectedDate;
      const {attendanceByDate} = this.props;
      if (!this.props.companyInformation.IsEnglishDate) {
        convertedSelectedDate = `${BS2AD(convertedSelectedDate).year}/${
          BS2AD(convertedSelectedDate).month
        }/${BS2AD(convertedSelectedDate).day}`;
      }
      const attendanceForDate = attendanceByDate.find(
        (f) => Date_Format(f.DateEng) == Date_Format(convertedSelectedDate)
      );
      if (attendanceForDate) {
        selectedOvertime.deviceInTime =
          attendanceForDate.InTimeDv || attendanceForDate.InTime;
        selectedOvertime.deviceOutTime =
          attendanceForDate.OutTimeDv || attendanceForDate.OutTime;
      } else {
        selectedOvertime.deviceInTime = null;
        selectedOvertime.deviceOutTime = null;
      }
    } else if (
      (name == "startTime" || name == "endTime") &&
      selectedOvertime.startTime &&
      selectedOvertime.endTime
    ) {
      const splitedStart = this.splitTime(selectedOvertime.startTime);
      const splitedEnd = this.splitTime(selectedOvertime.endTime);
      let hrsGap = parseInt(splitedEnd.hrs) - parseInt(splitedStart.hrs);
      let minGap = parseInt(splitedEnd.min) - parseInt(splitedStart.min);

      if (minGap < 0) {
        hrsGap = hrsGap - 1;
        minGap = 60 + minGap;
      }

      let timeGap = hrsGap * 60 + minGap;
      selectedOvertime.timeGap = timeGap;
    }
    this.setState({selectedOvertime});
  };

  splitTime = (time) => {
    const splitedTime = time.split(" ");
    const meridiem = splitedTime[1];
    const st = splitedTime[0].split(":");
    let hrs = parseInt(st[0]);
    let min = parseInt(st[1]);
    // console.log({st, hrs, min, meridiem});
    if (meridiem && meridiem.toLowerCase() == "pm" && hrs == 12) {
      hrs = hrs;
    } else if (meridiem && meridiem.toLowerCase() == "am" && hrs == 12) {
      hrs = 0;
    } else if (meridiem && meridiem.toLowerCase() == "pm") {
      hrs = 12 + hrs;
    }
    return {hrs, min};
  };

  calculateTimeDifference = () => {};

  handleFieldChange = (name, value) => {
    this.setState({[name]: value});
    if (name == "employeeToAssign") {
      this.manipulateApproverReviewer(value.value);
    }
  };

  addNewAttendance = () => {
    let {timeAttendance} = this.state;

    if (timeAttendance.length <= 6) {
      const id = timeAttendance[timeAttendance.length - 1].id + 1;
      timeAttendance.push(timeAttendanceTemplate(id));
      this.setState({timeAttendance});
    }
  };

  removeAttendance = (id) => {
    let {timeAttendance} = this.state;
    if (timeAttendance.length > 1) {
      timeAttendance = timeAttendance.filter((f) => f.id != id);
      this.setState({timeAttendance});
    } else {
      this.props.DisplayClientSideMessage("At least one time period is required");
    }
  };

  handleAutoSelect = (label, value) => {
    const {errors} = this.state;
    this.setState({[label]: value}, () => this.updateErrors());
  };
  handleInput = (name, value) => {
    this.setState({[name]: value}, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  handleSaveOvertimeRequest = async () => {
    const {
      editView,
      OverTimeType,
      timeAttendance,
      checkedApprover,
      checkedReviewer,
      employeeToAssign,
    } = this.state;
    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    } else {
      let {timeAttendance} = this.state;
      let isTimeDurationInvalid = false;
      let isNoteInvalid = false;
      let isDateInvalid = false;
      timeAttendance.forEach((t) => {
        if (t.timeGap === 0) isTimeDurationInvalid = true;
        if (t.note == "") isNoteInvalid = true;
        if (t.date == "") isDateInvalid = true;
      });
      if (isTimeDurationInvalid) {
        this.props.DisplayClientSideMessage(
          // "One of the requests has end time less than or equal to start time"
          "Select a valid end time and start time"
        );
        return;
      }
      if (isNoteInvalid) {
        this.props.DisplayClientSideMessage(
          "One of the requests has no reason mentioned"
        );
        return;
      }
      if (isDateInvalid) {
        this.props.DisplayClientSideMessage("One of the requests has no date mentioned");
        return;
      }
      if (checkedReviewer == null && !this.props.isAdmin) {
        this.props.DisplayClientSideMessage("At least one reviewer is requied");
        return;
      }
      if (checkedApprover == null && !this.props.isAdmin) {
        this.props.DisplayClientSideMessage("At least one approver is requied");
        return;
      }
    }
    let data = {
      IsAssigned: this.props.isAssign || false,
      OvertimeTypeId: OverTimeType.value,
      ApprovalEmployeeId: checkedApprover,
      RecommenderEmployeeId: checkedReviewer,
      EmployeeID: this.props.isAssign
        ? employeeToAssign.value
        : editView
        ? this.props.overtimeRequestId.EmployeeId
        : this.props.user.EmployeeId,
    };
    let overTimeData = [];

    timeAttendance.forEach((ta) => {
      let startDate = moment(new Date(ta.date));
      let endDate = moment(new Date(ta.date));
      if (ta.timeGap < 0) {
        endDate = moment(new Date(ta.date)).add(1, "days");
      }
      overTimeData.push({
        OvertimeRequestID: !editView ? 0 : ta.requestId,
        // StartTime: this.getSelectedDateTimings(ta.date, ta.startTime),
        StartTime: this.getSelectedDateTimings(startDate, ta.startTime),
        // EndTime: this.getSelectedDateTimings(ta.date, ta.endTime),
        EndTime: this.getSelectedDateTimings(endDate, ta.endTime),
        RequestedTimeinMin: ta.timeGap,
        Reason: ta.note,
        OvertimeRequestLineId: !editView ? 0 : ta.overtimeRequestLineId,
      });
    });
    // console.log({overTimeData});
    data.OvertimeRequestLineDto = overTimeData;

    if (editView) {
      await this.props.updateEmployeeOvertimeRequests(data);
    } else {
      await this.props.saveEmployeeOvertimeRequests(data);
    }
    const {
      isEmployeeOvertimeSaved,
      onModalClose,
      refreshRequestData,
      GetAuditLogOfRequests,
      overtimeRequestId,
    } = this.props;
    if (isEmployeeOvertimeSaved) {
      onModalClose(false);
      if (editView) {
        refreshRequestData();
        GetAuditLogOfRequests({
          requestId: overtimeRequestId.RequestId,
          moduleId: EMPLOYEE_OVERTIME_REQUEST_MODULE_VALUE,
        });
      }
    }
  };

  getSelectedDateTimings(date, time) {
    if (time.includes("am") || time.includes("pm")) time = convertTime12to24(time);
    let [hh, mm] = time.split(":");

    let convertedRequestedDate = date;
    const {companyInformation} = this.props;
    if (companyInformation.IsEnglishDate)
      convertedRequestedDate = moment(date).format("YYYY/MM/DD").toString();

    if (!companyInformation.IsEnglishDate) {
      let conversionDate = BS2AD(convertedRequestedDate);
      convertedRequestedDate = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
    }

    let selectedDateDay = new Date(convertedRequestedDate).getDate();
    let selectedDateMonth = new Date(convertedRequestedDate).getMonth();
    let selectedDateYear = new Date(convertedRequestedDate).getFullYear();
    let fieldTime;
    fieldTime = new Date(new Date(convertedRequestedDate).setUTCHours(hh));
    fieldTime = new Date(new Date(fieldTime).setUTCMinutes(mm));
    fieldTime = new Date(new Date(fieldTime).setUTCDate(selectedDateDay));
    fieldTime = new Date(new Date(fieldTime).setUTCMonth(selectedDateMonth));
    fieldTime = new Date(new Date(fieldTime).setUTCFullYear(selectedDateYear));
    return fieldTime;
  }

  renderDuration(attendance) {
    if (attendance.timeGap && attendance.date) {
      let duration = "";
      let diff = parseInt(attendance.timeGap);
      duration = changeMinToHourMinTimeFormat(diff);
      return duration;
    }
  }

  render() {
    const {
      timeAttendance,
      OverTimeType,
      checkedApprover,
      checkedReviewer,
      errors,
      overTimeApprovalReviewList,
      employeeToAssign,
    } = this.state;
    const {overTimeTypeList, isApprover, editMode, companyInformation, isLoading} =
      this.props;
    const approverReviewerFlag = overTimeApprovalReviewList.approverList?.length == 0;
    return (
      <div className="overtime-request">
        <div className="overtime-request-body">
          {this.props.isAssign && (
            <div className="overtime-request-header">
              <SelectEmployee
                employeeToAssign={employeeToAssign}
                error={errors && errors.employeeToAssign}
                onChange={this.handleFieldChange}
              />
            </div>
          )}
          <div className="overtime-request-header">
            <Form
              formName="reactselect"
              name="OverTimeType"
              value={OverTimeType}
              options={overTimeTypeList}
              onChange={this.handleAutoSelect}
              label="Select overtime type"
              placeholder="Select.."
              validators={["required"]}
              error={errors && errors.OverTimeType}
              width="250px"
            />
          </div>
          <div className="overtime-request-container">
            <div className="overtime-request-list">
              <span className="heading heading_date">Date</span>
              <span className="heading devicein">Device in</span>
              <span className="heading deviceout">Device out</span>
              <span className="heading heading_starttime">Start time</span>
              <span className="heading heading_endtime">End time</span>
              <span className="heading heading_gap"> </span>
              <span className="heading heading_reason">Reason</span>
            </div>

            {timeAttendance &&
              timeAttendance.map((attendance, index) => (
                <div className="overtime-request-list overtime-request-forms" key={index}>
                  <span className="desc desc_date">
                    <Form
                      enableOkButton
                      formName="customdateinput"
                      isNepali={!companyInformation?.IsEnglishDate}
                      isTextInNepali={!companyInformation?.IsEnglishDate}
                      name="date"
                      onChange={(name, value) =>
                        this.handleChange(name, value, attendance.id)
                      }
                      placeholder="Date.."
                      value={attendance.date || ""}
                    />
                  </span>
                  <span className="device-time">{attendance.deviceInTime || "-"}</span>
                  <span className="device-time">{attendance.deviceOutTime || "-"}</span>
                  <span className="desc desc_starttime">
                    <Form
                      formName="timepicker"
                      name="startTime"
                      value={attendance.startTime}
                      onChange={(name, value) =>
                        this.handleChange(name, value, attendance.id)
                      }
                      placeholder="Time.."
                      defaultMeridiem={"am"}
                      validators={["required"]}
                    />
                  </span>
                  <span className="desc desc_endtime">
                    <Form
                      formName="timepicker"
                      name="endTime"
                      value={attendance.endTime}
                      onChange={(name, value) =>
                        this.handleChange(name, value, attendance.id)
                      }
                      placeholder="Time.."
                      defaultMeridiem={"pm"}
                      validators={["required"]}
                    />
                  </span>
                  <span className="desc desc_gap">{this.renderDuration(attendance)}</span>
                  <span className="note">
                    <Form
                      formName="textinput"
                      name="note"
                      value={attendance.note}
                      onChange={(name, value) =>
                        this.handleChange(name, value, attendance.id)
                      }
                      placeholder="Reason"
                    />
                  </span>
                  {timeAttendance?.length == 0 ? null : (
                    <span
                      className="delete-time-attendance"
                      onClick={() => this.removeAttendance(attendance.id)}
                    >
                      <Icons name="Delete" className="flex" />
                    </span>
                  )}
                </div>
              ))}
          </div>
          {timeAttendance?.length <= 6 && (
            <div className="overtime-request-add">
              <Button
                leftIcon={<Icons name="Add" className="flex" />}
                title="Add Another"
                buttonType="icon-button"
                onClick={() => this.addNewAttendance()}
                bg="primary-light"
              />
            </div>
          )}
          {this.props.isAdmin ? null : (
            <div
              className={classNames({
                "form-row ml-sm mr-sm items-start": true,
                "mt-sm": true,
                flex: true,
              })}
            >
              <Form
                formName="checkBoxGroup"
                name="checkedReviewer"
                label="Reviewer"
                checkGroupArray={overTimeApprovalReviewList.reviewerList}
                checkedGroupValue={checkedReviewer}
                onChange={this.handleFieldChange}
                // validators={['required']}
                multiple={false}
                disabled={isApprover}
              />
              <Form
                formName="checkBoxGroup"
                name="checkedApprover"
                // validators={['required']}
                label="Approver"
                checkGroupArray={overTimeApprovalReviewList.approverList}
                checkedGroupValue={checkedApprover}
                onChange={this.handleFieldChange}
                multiple={false}
                disabled={isApprover}
              />
            </div>
          )}
          {approverReviewerFlag && (
            <div
              className={classNames({
                "form-row ml-sm mr-sm items-start": true,
              })}
            >
              <div className="genericForm-group__label allowance-noapprover">
                <label className="genericForm-group__label allowance-noapprover">
                  {"Approver *"}
                </label>
                <div className="flex items-start mt-xsm">
                  <MdInfo className="info-icon" />
                  <span className="ml-xsm text-md">
                    There is no approver set for this request type. Please contact your HR
                    Administrator. At least one approver is required to initiate the
                    request
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="overtime-request-footer flex-gap-10">
          <Button
            onClick={() => this.props.onModalClose(false)}
            bg="white"
            title="Cancel"
          />
          <Button
            bg="primary"
            title={isApprover || editMode ? "Update Request" : "Send Request"}
            onClick={() => this.handleSaveOvertimeRequest()}
            isDisabled={isLoading}
          />
        </div>
        {isLoading ? <Loaders loading={isLoading} /> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isEmployeeOvertimeSaved: state.employeeOvertimeRequestReducer.isEmployeeOvertimeSaved,
  overTimeApprovalReviewList:
    state.employeeOvertimeRequestReducer.overTimeApprovalReviewList || [],
  overTimeTypeList: state.commonReducer.overTimeTypes,
  user: state.auth.user,
  companyInformation: state.commonReducer.companyInformation,
  attendanceByDate: state.attendanceReducer.attendanceByDate,
  isLoading: state.employeeOvertimeRequestReducer.isLoading,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  saveEmployeeOvertimeRequests,
  GetOverTimeTypes,
  GetOverTimeApprovalReviewerList,
  updateEmployeeOvertimeRequests,
  GetAttendanceByDate,
  GetCompanyInformation,
  GetAuditLogOfRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeRequest);
