import * as React from "react";
import Workbook from "react-excel-workbook-emptystring";
import isEmpty from "isEmpty";
import {IExcelSheet, IExcelColumn} from "ts/interfaces/excel/exportExcel";
import {BiExport} from "react-icons/bi";

interface IAppProps {
  displayText?: string;
  waitingText?: string;
  onClick: Function;
  className?: string;
  showIcon?: boolean;
}

const ExportButton = (props: IAppProps) => {
  const {displayText, className, showIcon, waitingText} = props;
  const [state, setState] = React.useState({isDisable: false, isLoading: false});
  const {isLoading} = state;

  const handleClick = async (e) => {
    setState({...state, isLoading: true});
    props.onClick && (await props.onClick());
    setState({...state, isLoading: false});
  };

  return (
    <button className={className} onClick={handleClick} disabled={isLoading}>
      {showIcon && <BiExport />}
      {isLoading ? waitingText : displayText}
    </button>
  );
};
ExportButton.defaultProps = {
  className: "button button-primary",
  showIcon: false,
  displayText: "Download",
  waitingText: "Waiting...",
};
export default ExportButton;
