import React, {useState, useRef} from "react";
import Icons from "components/icons";
import {menuProps, menuNavProps} from "../types";
import classnames from "classnames";
import isEmpty from "isEmpty";
import Button from "components/button";
import FormGroup from "components/form";
import BoardView from "./boardView";
import TableView from "./tableView";
import {useSelector, useDispatch} from "react-redux";

import {TaskHeader, MenuNavBar, NavItem} from "./common";
import TaskModal from "./common/taskModal";
import {getBoardLabel, getTableView} from "../../helpers/action";
import TeamMember from "./common/member/teamMember";
import {FaRegUserCircle} from "react-icons/fa";
import CustomPopup from "components/common/customPopup";

const tableView = "Table View";
const boardView = "Board View";
const ganttView = "Gantt View";

function TaskBody(props) {
  const [active, setActive] = React.useState(tableView);
  const dispacth = useDispatch();
  const {boards, board, tableViewLoading} = useSelector(
    (state: any) => state.taskReducer
  );
  // const [board, setBoard] = React.useState({});
  const currentBoard = props.match.params.board;
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    stage: 0,
    rowIndex: -1,
  });

  const [task, setTask] = useState(null);

  React.useEffect(() => {
    const boardId = props.match.params.board;
    fetchData(boardId);
  }, [currentBoard]);

  const fetchData = async (boardId) => {
    await dispacth(getTableView(boardId));
    dispacth(getBoardLabel(boardId));
  };

  const _renderView = () => {
    switch (active) {
      case tableView:
        return (
          <TableView
            boardId={(board as any).BoardId}
            // board={board}
            onTaskClicked={(task, stage, rowIndex) => {
              setTask(task);
              setOpenTaskModal(true);
              setSelectedData({stage, rowIndex});
            }}
          />
        );
      case boardView:
        return (
          <BoardView
            board={board}
            onTaskClicked={(task, stage, rowIndex) => {
              setTask(task);
              setOpenTaskModal(true);
              setSelectedData({stage, rowIndex});
            }}
          />
        );
    }
  };

  const handleChange = (search) => {
    const boardId = props.match.params.board;
    const {taskName, selectedTeam} = search;
    const employeeIds = selectedTeam.map((x) => x.EmployeeId).join() || "";
    dispacth(getTableView(boardId, taskName, employeeIds));
  };

  if (!isEmpty(board)) {
    return (
      <>
        {tableViewLoading && <div className="board-loader"></div>}

        <div className="task">
          <TaskHeader
            onOpenTaskModal={(task) => {
              setTask(task);
              setOpenTaskModal(true);
            }}
          />
          <TaskView active={active} setActive={setActive} onChange={handleChange} />
          <div className="task-body">{_renderView()}</div>
          <TaskModal
            board={board}
            rowIndex={selectedData.rowIndex}
            stage={selectedData.stage}
            open={openTaskModal}
            onClose={setOpenTaskModal}
            task={task}
          />
        </div>
      </>
    );
  }
  return null;
}

const TaskView = ({active, setActive, onChange}) => {
  const onClick = (title: string): void => {
    setActive(title);
  };
  const [searchState, setSearchState] = useState({
    taskName: "",
    selectedTeam: [],
  });

  const handleChange = (name, value) => {
    const nextState = {...searchState};
    nextState[name] = value;
    setSearchState(nextState);
    onChange(nextState);
  };

  return (
    <div className="task-view">
      <div className="task-view__container">
        <div className="task-view__menu">
          <MenuNavBar isView>
            <NavItem
              active={active}
              onClick={onClick}
              title={tableView}
              icon="Users"
              isView
            />
            <NavItem
              active={active}
              onClick={onClick}
              title={boardView}
              icon="Users"
              isView
            />
            {/* <NavItem
              active={active}
              onClick={onClick}
              title={ganttView}
              icon="Users"
              isView
            /> */}
          </MenuNavBar>
        </div>
        {/* <div className="task-view__new">
          <Button
            buttonType="drop-button"
            title="New Section"
            bg="primary-dark"
            dropComponent={
              <ul className="button-drop-list">
                <li className={"button-drop-list__item"}>
                  <span className="button-drop-name">Some Link</span>
                </li>
              </ul>
            }
          />
        </div> */}
        <div className="task-view__search">
          <FormGroup
            name="taskName"
            placeholder="Search or filter board"
            onChange={handleChange}
            value={searchState.taskName}
          />
          <TeamMemberButton
            name="selectedTeam"
            value={searchState.selectedTeam}
            onChange={handleChange}
            isSearchMode
          />
        </div>
      </div>
    </div>
  );
};

const TeamMemberButton = (props) => {
  const {name, onChange, value, isSearchMode} = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onSelectionChange = (members) => {
    onChange && onChange(name, members);
  };
  return (
    <div ref={ref}>
      <div
        className={classnames("task-view__search-team-icon", {
          "task-view__search-team-active": value && value.length,
          "task-view__search-team": !(value && value.length),
        })}
        onClick={(e) => setOpen(!open)}
      >
        <Icons name="Users" size={17} />
      </div>

      {open && (
        <CustomPopup
          parentRef={ref}
          open={open}
          handleOutsideClick={() => setOpen(false)}
        >
          <div className="task-view__search-team-container">
            <div className="task-view__search-team-clear">
              <span
                className="task-view__search-team-clear-btn"
                onClick={() => {
                  onChange && onChange(name, []);
                  //  setOpen(false);
                }}
              >
                Clear All
              </span>
            </div>
            <TeamMember
              selectedMembers={value}
              onSelectionChange={onSelectionChange}
              isSearchMode={isSearchMode}
            />
          </div>
        </CustomPopup>
      )}
    </div>
  );
};

export default TaskBody;
