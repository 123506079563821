import React, {Component} from "react";
import {MdSettings} from "react-icons/md";
import drag from "assets/images/icons/ellipse.svg";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import shortid from "shortid";
import GenderRatio from "./dashboard/genderRatio";
import EmployeeList from "./dashboard/employeeList";
import {ContractExpiring, LatestEvent, Retiring, NewJoin} from "./dashboard/employeeList";
import Absentism from "./dashboard/absentism";
import Pending from "./dashboard/pending";
import RouteEnum from "constants/routeEnum";
import {Link} from "react-router-dom";
import HRPieChart from "./dashboard/hrPieChart";
import Button from "components/button";
import isEmpty from "isEmpty";

const blockProps = {
  "Gender Ratio": {id: shortid.generate(), component: GenderRatio},
  "New Joins": {
    id: shortid.generate(),
    component: NewJoin,
    props: {type: "NewJoiningEmployee"},
    footer: {title: "Review new joins", link: "Cp/Report/HRNewJoinList.aspx"},
  },
  "Long Absenteism": {
    id: shortid.generate(),
    component: Absentism,
    footer: "Review Absentism",
    props: {absent: []},
  },
  "Status Distribition": {id: shortid.generate(), component: HRPieChart},
  Retirement: {
    id: shortid.generate(),
    component: Retiring,
    props: {type: "RetiringEmployee"},
    footer: {title: "Check Retirements", link: "newhr/retirement.aspx"},
  },
  Transfers: {
    id: shortid.generate(),
    component: EmployeeList,
    props: {type: "EmployeeTransfer"},
    footer: {title: "Review Transfers", link: "cp/BranchTransferList.aspx"},
  },
  "Pending Request": {id: shortid.generate(), component: Pending, props: {pending: []}},
  "Expiring Contracts": {
    id: shortid.generate(),
    component: ContractExpiring,
    props: {type: "EmployeeContractExpiring"},
    footer: {title: "Review Contracts", link: "newhr/EmployeeContractExpiring.aspx"},
  },
  "Latest Events": {
    id: shortid.generate(),
    component: LatestEvent,
    props: {type: "EmployeeLatestEvent"},
    footer: {title: "All Events", link: "cp/EmployeeLatestEvent.aspx"},
  },
  // 'Latest Events': { id:shortid.generate(), component:LatestEvent, props:{type:'EmployeeLatestEvent'}, footer:{title:'All Events', link:RouteEnum.EmployeeListPath,isReact:true}}
};

const initial = [
  {
    id: shortid.generate(),
    cards: [
      {name: "Gender Ratio", ...blockProps["Gender Ratio"]},
      {name: "New Joins", ...blockProps["New Joins"]},
      {name: "Long Absenteism", ...blockProps["Long Absenteism"]},
    ],
  },
  {
    id: shortid.generate(),
    cards: [
      {name: "Status Distribition", ...blockProps["Status Distribition"]},
      {name: "Retirement", ...blockProps["Retirement"]},
      {name: "Transfers", ...blockProps["Transfers"]},
    ],
  },
  {
    id: shortid.generate(),
    cards: [
      {name: "Pending Request", ...blockProps["Pending Request"]},
      {name: "Expiring Contracts", ...blockProps["Expiring Contracts"]},
      {name: "Latest Events", ...blockProps["Latest Events"]},
    ],
  },
];

export class DragBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: initial,
    };
    this._renderBlock = this._renderBlock.bind(this);
    this.onDragChange = this.onDragChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {blocks} = this.props;
    const {lists} = this.state;
    if (!isEmpty(blocks) && blocks !== prevProps.blocks) {
      let hrBlocks = blocks.filter((item) => item.DashboardName === "HR");
      if (!isEmpty(hrBlocks)) {
        let sortedBlock;
        sortedBlock = hrBlocks.sort((a, b) => a.BlockOrder - b.BlockOrder);

        const newList = lists.map((item, index) => {
          let num = index + 3;
          let threes = sortedBlock.slice(index * 3, (index + 1) * 3);
          let newCards = threes.map((card) => {
            return {
              name: card.BlockName,
              ...blockProps[card.BlockName],
            };
          });
          item.cards = [...newCards];

          return item;
        });
        this.setState({lists: newList});
      }
    }
  }
  onDragChange = () => {
    const {lists} = this.state;
    const toPost = lists.map((item, ind) => {
      let b = item.cards.map((card, i) => {
        return {
          DashboardName: "HR",
          BlockName: card.name,
          // BlockOrder:(i +1) + (ind * 3)
        };
      });
      return b;
    });
    let c = toPost.flat();
    c = c.map((item, i) => {
      return {
        BlockOrder: i + 1,
        ...item,
      };
    });
    this.props.addDashboardBlocks(c);
  };

  onDragEnd = (result) => {
    const {destination, source, draggableId, type} = result;
    if (!destination) {
      return;
    }
    const {lists} = this.state;
    const droppableIdStart = source.droppableId;
    const droppableIdEnd = destination.droppableId;
    const droppableIndexEnd = destination.index;
    const droppableIndexStart = source.index;

    if (droppableIdStart === droppableIdEnd) {
      const listStart = lists.find((list) => list.id === droppableIdStart);

      const card = listStart.cards.splice(droppableIndexStart, 1);
      listStart.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    if (droppableIdStart !== droppableIdEnd) {
      // find the list where the drag happened
      const listStart = lists.find((list) => list.id === droppableIdStart);
      // pull out the card from this list
      const card = listStart.cards.splice(droppableIndexStart, 1);
      // find the list where the drag ended
      const listEnd = lists.find((list) => list.id === droppableIdEnd);
      // put the card in the new list
      listEnd.cards.splice(droppableIndexEnd, 0, ...card);
      this.setState((prevState) => ({
        ...prevState,
        lists: prevState.lists.map((list) => {
          if (list.id === listEnd.id) {
            return listEnd;
          } else if (list.id === listStart.id) {
            return listStart;
          }
          return list;
        }),
      }));
    }
    this.onDragChange();
  };
  _renderBlock(props, index) {
    return (
      <Draggable draggableId={String(props.id)} index={index} key={props.id}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="drag-block__item drag-box"
          >
            <div className="drag-box__header">
              <div className="drag-box__title">
                <h4>{props.name}</h4>
              </div>
              <div className="drag-box__icons">
                <span className="drag-box__icon">
                  <MdSettings />
                </span>
                <span {...provided.dragHandleProps} className="drag-box__icon drag">
                  <img src={drag} width="16" height="16" />
                </span>
              </div>
            </div>
            <div className="drag-box__body">
              <props.component base={this.props.base} {...props.props} />
            </div>
            {props.footer ? (
              props.footer.link ? (
                props.footer.isReact ? (
                  <div className="drag-box__footer">
                    <Link to={props.footer.link}>
                      <span>{props.footer.title}</span>
                    </Link>
                  </div>
                ) : (
                  <div className="drag-box__footer">
                    <a href={this.props.base + props.footer.link}>
                      <span>{props.footer.title}</span>
                    </a>
                  </div>
                )
              ) : (
                <div className="drag-box__footer">
                  <span>{props.footer.title}</span>
                </div>
              )
            ) : null}
          </div>
        )}
      </Draggable>
    );
  }
  render() {
    const {lists} = this.state;

    return (
      <div className="drag-block">
        <div className="drag-block__container">
          <div className="drag-block__items">
            <DragDropContext onDragEnd={this.onDragEnd}>
              {lists.map((item, index) => {
                return (
                  <Droppable
                    droppableId={String(item.id)}
                    index={index}
                    key={item.id}
                    type="list"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        nf={provided}
                        index={index}
                        className="drag-block__items-list"
                      >
                        {item.cards.map(this._renderBlock)}
                      </div>
                    )}
                  </Droppable>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}

export default DragBlock;
