import React, { useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";

const data = [
    {name:"80-100",value:"Start Performer"},
    {name:"60-79",value:"Above Average"},
    {name:"40-59",value:"Below Average"},
    {name:"0-39",value:"Poor Performer"},
]


function FinalScoreTable() {
    const cols = [
        {
          Header: "Final Performance Review Score",
          accessor: "name",
          width: 250,
        },
        {
            Header: "Result to display",
            accessor: "value",
            width: 180,
        },
    ];
  return (
    <div className="appraisals-table">
        <Table isFixedWidth data={ data ?? []} columns={cols} />
    </div>
  )
}

export default FinalScoreTable