import React from "react";
import Icons from "components/icons";
import Button from "components/button";
import Modal from "components/modal";
import {connect} from "react-redux";
import {MdAdd} from "react-icons/md";
import MessageForm from "./form";
import {GetAllMessageTemplate, DeleteMessageTemplate} from "./messageTemplateAction";
import MessageTable from "./table";
import "./style.scss";

const initialState = {
  open: false,
  openNewTemplate: false,
  errors: null,
  editView: false,
  editViewData: {},
  selectedId: null,
  modalState: {show: false, type: "normal"},
};
interface IAppProps {
  GetPayrollIncomeHeads: any;
  saveMessageTemplate: any;
  GetAllMessageTemplate: any;
  messageTemplateList: any;
  DeleteMessageTemplate: any;
  loading: any;
}

interface State {
  open: any;
  openNewTemplate: boolean;
  errors?: any;
  editView?: boolean;
  editViewData?: any;
  selectedId?: any;
  modalState?: any;
}

class index extends React.Component<IAppProps, State> {
  state = initialState;

  componentDidMount = async () => {
    await this.props.GetAllMessageTemplate();
  };

  handleFieldChange = (name: any, value: any) => {
    this.setState({[name]: value} as Pick<State, keyof State>);
  };

  editSelectedTemplate = async (templateData: any) => {
    this.setState({
      editViewData: {
        Id: templateData?.Id,
        Message: templateData?.Message,
        Name: templateData?.Name,
      },
      editView: true,
      openNewTemplate: true,
    });
  };

  deleteSelectedTemplate = async () => {
    await this.props.DeleteMessageTemplate(this.state.selectedId);
    this.props.GetAllMessageTemplate();
  };

  handleConfirmDelRecord = (id: any) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };

  handleModalShow = (show: any, type: any) => {
    this.setState({modalState: {show, type}});
  };

  render() {
    const {openNewTemplate, editView, editViewData} = this.state;

    const {messageTemplateList} = this.props;
    const {modalState} = this.state;

    return (
      <div className="salaryTemplate">
        <div className="flex mb-20">
          <div>
            <span>Message Templates</span>
          </div>
          <div className="salaryTemplate-header ml-20">
            <Button
              bg="primary"
              buttonType="icon-button"
              icon={<Icons name="Add" />}
              leftIcon={<MdAdd />}
              onClick={() =>
                this.setState(
                  {
                    ...initialState,
                  },
                  () => this.setState({openNewTemplate: true})
                )
              }
              title="Add Template"
            />
          </div>
        </div>
        <div className="salaryTemplate-container">
          <MessageTable
            data={messageTemplateList}
            editSelectedTemplate={this.editSelectedTemplate}
            handleConfirmDelRecord={this.handleConfirmDelRecord}
            handleModalShow={this.handleModalShow}
            loading={this.props.loading}
          />
        </div>
        {openNewTemplate && (
          <Modal
            open={openNewTemplate}
            onModalClose={() => this.setState({openNewTemplate: false})}
          >
            <MessageForm
              editView={editView}
              editViewData={editViewData}
              showForm={openNewTemplate}
              closeForm={() => this.setState({openNewTemplate: false})}
            />
          </Modal>
        )}

        {modalState.show && (
          <Modal
            title={"Message Template"}
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={this.deleteSelectedTemplate}
          >
            <></>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveMessageTemplate: state.messageTemplateReducer.saveMessageTemplate,
  messageTemplateList: state.messageTemplateReducer.messageTemplateList,
  loading: state.messageTemplateReducer.loading,
});

const mapDispatchToProps = {
  GetAllMessageTemplate,
  DeleteMessageTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
