import {
  CLEAR_LEAVE_REQUEST_DATAS,
  GET_LEAVE_REQUEST_APPROVER,
  GET_LEAVE_REQUEST_BY_ID,
  GET_LEAVE_REQUEST_DAYS,
  GET_LEAVE_REQUEST_TYPE,
  GET_TEAM_MEMBER_LEAVE_DETAILS,
  SET_LEAVE_REQUEST_APPROVER_LOADING,
  SET_LEAVE_REQUEST_DAYS_LOADING,
  SET_LEAVE_REQUEST_DETAILS_LOADING,
  SET_LEAVE_REQUEST_TYPE_LOADING,
} from "actions/types";
import {toastMessage} from "actions/validateAction";
import * as leaveRequestService from "services/leaveRequest";

const urlPrefix_ksa = "v1/jp/";
//#region leave request
export const GetLeaveRequestType = (searchData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LEAVE_REQUEST_TYPE_LOADING,
      payload: true,
    });
    let result = await leaveRequestService.getLeaveRequestType(searchData);
    if (result.Status) {
      dispatch({
        type: GET_LEAVE_REQUEST_TYPE,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetLeaveRequestApprover = (employeeId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LEAVE_REQUEST_APPROVER_LOADING,
      payload: true,
    });
    let result = await leaveRequestService.getLeaveRequestApprover(employeeId);
    if (result.Status) {
      dispatch({
        type: GET_LEAVE_REQUEST_APPROVER,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetLeaveRequestById = (leaveRequestId) => async (dispatch) => {
  if (leaveRequestId) {
    try {
      dispatch({
        type: SET_LEAVE_REQUEST_DETAILS_LOADING,
        payload: true,
      });
      let result = await leaveRequestService.getLeaveRequestById(leaveRequestId);
      if (result.Status) {
        dispatch({
          type: GET_LEAVE_REQUEST_BY_ID,
          payload: result.Data,
        });
      } else {
        toastMessage(dispatch, result);
      }
    } catch (err) {
      toastMessage(dispatch, err.message);
    }
  } else {
    dispatch({
      type: GET_LEAVE_REQUEST_BY_ID,
      payload: {loading: true, value: {}},
    });
  }
};
export const GetTeamMemberLeaveDetails = (requestData) => async (dispatch) => {
  try {
    let result = await leaveRequestService.getTeamMemberLeaveDetails(requestData);
    if (result.Status) {
      dispatch({
        type: GET_TEAM_MEMBER_LEAVE_DETAILS,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};
export const GetLeaveRequestDays = (requestData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LEAVE_REQUEST_DAYS_LOADING,
      payload: true,
    });
    let result = await leaveRequestService.getLeaveRequestDays(requestData);
    if (result.Status) {
      dispatch({
        type: GET_LEAVE_REQUEST_DAYS,
        payload: result.Data,
      });
    } else {
      toastMessage(dispatch, result);
    }
  } catch (err) {
    toastMessage(dispatch, err.message);
    dispatch({
      type: SET_LEAVE_REQUEST_DAYS_LOADING,
      payload: false,
    });
  }
};
export const ClearLeaveRequestDatas = () => (dispatch) => {
  dispatch({
    type: CLEAR_LEAVE_REQUEST_DATAS,
  });
};
//#endregion
