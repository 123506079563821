import React, {Component} from "react";
import ExportExcel from "components/excel/exportExcel";
import {AgGridReact} from "ag-grid-react";
import CommonPagination from "components/common/pagination";
import Icons from "components/icons";
import SvgIcons from "components/icons/svgIcons";
import Button from "components/button";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {rejectAllowance} from "./helpers/action";
import * as employeeRequestService from "services/employeeRequests";
import Modal from "components/modal";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import GetDetailsPage from "scenes/employee/requests/common/getDetailsPage";
import {useDispatch, useSelector, connect} from "react-redux";

import {ExportAllowancesRequest} from "./helpers/action";
import {initialHeader} from "./constants";

const Status = (props) => {
  const isActive = props.data.Status === "Approved";
  const isPending = props.data.Status === "Pending";
  const isForwarded = props.data.Status === "Forwarded";
  const isRejected = props.data.Status === "Rejected";
  const isRecommended = props.data.Status === "Recommended";
  return (
    <div
      className={`working-state ${
        isActive
          ? "working"
          : isPending
          ? "pending"
          : isForwarded
          ? "forwarded"
          : isRecommended
          ? "recom"
          : "retired"
      }`}
    >
      {isActive ? (
        <>
          <Icons name="Check" justSVG />
        </>
      ) : isPending ? (
        <SvgIcons color="#ffa126" size="17" name="clock" />
      ) : isForwarded ? (
        <SvgIcons color="#40a3ff" size="17" name="forwarded" />
      ) : isRecommended ? (
        <SvgIcons color="#0052ab" size="17" name="thumbs-up" />
      ) : (
        <>
          <Icons name="Close" justSVG />
        </>
      )}
      {props.data.Status}
    </div>
  );
};

const DateCommon = (props) => {
  return (
    <div className="">
      {props.data.StartDate} - {props.data.EndDate}
    </div>
  );
};

const Buttons = (props) => {
  const isEdit = props.data.Status === "Approved" || props.data.Status === "Rejected";
  const isForwarded = props.data.Status === "Forwarded";
  const [open, setOpen] = React.useState(false);
  const {employeeRequest} = useSelector((state: any) => state.allowanceReducer);
  const onClose = () => setOpen(false);
  const onModalSet = (val) => setOpen(val);
  let [isDetailsPageShow, setDetailsPageShow] = React.useState(false);

  const onApprove = async () => {
    let requestData = {
      requestId: props.data.RequestId,
      requestType: "Allowance",
      reason: "",
    };
    let result = await employeeRequestService.approveReviewRequests(requestData);
    if (result.Status) {
      await props.colDef.initialize();
    }
  };
  const rowData = Object.assign(
    {
      RequestType: "Allowance",
      ...employeeRequest,
      AllowanceType: props.data.Allowance,
      Units: props.data.Unit,
    },
    props.data
  );

  const onDropClick = (e) => {
    const {
      eGridCell,
      eGridCell: {offsetParent: row},
    } = props;
    // if(row.style)
    let rows = document.getElementsByClassName("ag-row");
    //@ts-ignore
    rows.forEach((item) => {
      if (item.style.zIndex) {
        item.style.zIndex = null;
      }
    });
    row.style.zIndex = 10;
  };
  React.useEffect(() => {}, [employeeRequest]);
  const dispatch = useDispatch();
  const onReject = () => {
    dispatch(rejectAllowance(rowData.RequestId, props.colDef.initialize));
  };

  // RequestId
  return (
    <div className="flex allowance-buttons">
      {isEdit ? (
        <Button
          title="Edit"
          buttonType="icon-button"
          onClick={() => onModalSet(true)}
          leftIcon={<Icons name="Edit" justSVG />}
          bg="primary"
        />
      ) : isForwarded ? (
        <Button
          title="Details"
          leftIcon={<SvgIcons size="17" name="list" />}
          onClick={() => {
            setDetailsPageShow(true);
          }}
          buttonType="icon-button"
          bg="subtle"
        />
      ) : (
        <Button
          title="Approve"
          onClick={() => onApprove()}
          leftIcon={<Icons name="CircleCheck" justSVG />}
          buttonType="icon-button"
          bg="success"
        />
      )}
      <Button
        customDropIcon={<Icons name="Ellipse" justSVG />}
        buttonType="drop-button"
        buttonClass="allowance-buttons__drop"
        onDropClick={(e) => onDropClick(e)}
        dropAbove={rowData.RowNum !== 1}
        dropComponent={
          <ul>
            <li className="flex" onClick={() => onReject()}>
              <Icons name="Close" justSVG />
              <span>Reject</span>
            </li>
          </ul>
        }
        justDrop={true}
        bg="subtle"
      />
      <Modal
        title="Allowance Request"
        open={open}
        onModalClose={() => onModalSet(false)}
        type=""
        className="modal-allowance-request allowance-request-form"
      >
        <AllowanceRequestForm
          closeForm={onModalSet}
          allowanceRequestId={rowData}
          editMode={true}
          isAdmin={true}
          refreshRequestData={props.colDef.initialize}
        />
      </Modal>
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={rowData}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={true}
          isAdmin={true}
          // isReview={isReview}
          refreshRequestData={props.colDef.initialize}
          requestData={props.agGridReact.props.rowData}
          page={"Admin "}
        />
      )}
    </div>
  );
};

class Tables extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: "EmployeeName",
          headerName: "Name",
          // checkboxSelection:true
          pinned: "left",
        },
        {
          field: "Allowance",
          headerName: "Allowance",
        },
        {
          field: "RequestedOnEng",
          headerName: "Date",
          width: 300,
          cellRenderer: "DateCommon",
        },
        {field: "Unit", pinned: "left"},
        {field: "Reason", width: 300},
        {field: "Status", cellRenderer: "Status"},
        {
          field: "Id",
          headerName: "",
          cellRenderer: "Buttons",
          width: 200,
          initialize: this.props.initialize,
        },
      ],
      exportColumn: [],
      exportData: [],
      domLayout: "autoHeight",
      defaultColDef: {
        width: 145,
        resizable: false,
      },
      rowSelection: "multiple",
      frameworkComponents: {
        Status,
        DateCommon,
        Buttons,
        initialize: this.props.initialize,
      },
      getRowHeight: function (params) {
        return 58;
      },
      rowData: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allowancesExportList !== this.props.allowancesExportList) {
      const exportData =
        this.props.allowancesExportList &&
        this.props.allowancesExportList.length > 0 &&
        this.props.allowancesExportList.map((allowance) => {
          return {
            EmployeeName: allowance.EmployeeName,
            Allowance: allowance.Allowance,
            Date: `${allowance.StartDate} - ${allowance.EndDate}`,
            Unit: allowance.Unit,
            Reason: allowance.Reason,
            Status: allowance.Status,
          };
        });
      this.setState({exportData});
      const newArray = initialHeader.map((item) => {
        return {label: item.headerName, value: item.field};
      });
      this.setState({exportColumn: newArray});
    }
  }

  public gridApi;
  public gridColumnApi;

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    const updateData = (data) => {
      this.setState({rowData: data});
    };

    if (this.props.data) {
      updateData(this.props.data);
    }
  };

  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid: any = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };

  getSelectedRowData = () => {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    const ids = selectedData.map((item) => item.RequestId);
    this.props.postToPay(ids);
  };

  handleExcelExport = async () => {
    const {
      pageIndex,
      rowsPerPage,
      activeTab,
      employeeName,
      startDate,
      endDate,
      allowanceType,
      departments,
      branches,
    } = this.props;
    let searchData = {
      Allowance: allowanceType,
      Branch: branches,
      Department: departments,
      EmployeeName: employeeName,
      PageIndex: 0,
      PageSize: 99999,
      RequestEndDate: endDate,
      RequestStartDate: startDate,
      Status: activeTab,
    };
    await this.props.ExportAllowancesRequest(searchData);
  };

  render() {
    const {exportColumn, exportData} = this.state;
    const {data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage, activeTab} =
      this.props;
    const isApprovedTab = activeTab === "2";
    return (
      <>
        {isApprovedTab ? (
          <div className="flex">
            <Button
              title="Post to pay"
              onClick={() => this.getSelectedRowData()}
              bg="primary"
            />
            <ExportExcel
              onClick={this.handleExcelExport}
              fileName="Allowance Request"
              columns={exportColumn}
              sheets={[{sheetName: "Allowance Request", data: exportData}]}
              className="button button-primary"
            />
          </div>
        ) : null}
        <div className="table empTable">
          <div className="arrear-table">
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                onGridReady={this.onGridReady}
                getRowHeight={this.state.getRowHeight}
                rowSelection={this.state.rowSelection}
                rowMultiSelectWithClick={isApprovedTab}
                // loadingCellRenderer={this.state.loadingCellRenderer}
                // loadingCellRendererParams={this.state.loadingCellRendererParams}
                rowData={data}
                //   rowData={this.props.data}
              />
            </div>
            {data && data.length > 0 && (
              <CommonPagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRow}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  ExportAllowancesRequest,
};

export default connect(null, mapDispatchToProps)(Tables);
