import React, {useState, useEffect} from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useResizeColumns,
  useFlexLayout,
  usePagination,
  useExpanded,
} from "react-table";
import Icons from "components/icons/svgIcons";
import "./styles.scss";
import isEmpty from "isEmpty";
import {useSticky} from "react-table-sticky";
import EditableCell from "./components/editableCell";
import RowSelectionCheckbox from "./components/rowSelectionCheckbox";
import classNames from "classnames";

interface TableInterface {
  columns: any;
  data: any;
  isRowSelect?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  onRowClick?: Function;
  isFixedWidth?: boolean;
  onSaveData?: Function;
  onRowSelect?: Function;
  updateMyData?: Function;
  disablePageResetOnDataChange?: any;
}

function Table(props: TableInterface) {
  const {
    columns,
    data,
    isRowSelect,
    isEditable,
    onRowClick,
    isExpanded = false,
    updateMyData,
    onSaveData,
    onRowSelect,
    disablePageResetOnDataChange,
  } = props;

  const [edit, setEditable] = useState(false);
  const onEdit = async () => {
    await setEditable(true);
  };

  const defaultColumn = {
    edit,
    Cell: (cprops) => (
      <EditableCell {...cprops} updateMyData={updateMyData} onSaveData={onSaveData} />
    ),
  };
  const {
    getTableBodyProps,
    headerGroups,
    setHiddenColumns,
    rows,
    columns: cls,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      edit,
      data,
      defaultColumn,
      disablePageResetOnDataChange,
      onSaveData,
      initialState: {expanded: isExpanded ? {"0": true, "2": true} : false},
    },
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,

    useSticky,

    (hooks) => {
      if (isRowSelect) {
        hooks.visibleColumns.push((columns: any) => [
          {
            id: "selection",
            width: 50,
            maxWidth: 50,
            minWidth: 50,
            Header: ({getToggleAllRowsSelectedProps}) => (
              <div>
                <RowSelectionCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({row}: any) => (
              <div>
                <RowSelectionCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  useEffect(() => {
    setEditable(isEditable);
  }, [isEditable]);
  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedFlatRows);
    }
  }, [onRowSelect, selectedFlatRows]);
  React.useEffect(() => {
    const hiddenColumns = cls
      .filter((column: any) => {
        if ("show" in column) {
          return !column.show;
        }
      })
      .map((column: any) => column.id);
    setHiddenColumns(hiddenColumns);
  }, []);

  return (
    <div className="table-contain">
      <div className="table-container">
        <div className="table-table sticky shift-table">
          <div className="table-header">
            {headerGroups.map((headerGroup, key) => {
              return (
                <div
                  key={"Table-Header-" + key}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map((column: any, key) => {
                    const isShow = "show" in column ? !column.show : true;
                    const {style, ...rest} = column.getHeaderProps();
                    const extraStyle = column?.maxWidth
                      ? {maxWidth: column.maxWidth}
                      : {};
                    return (
                      <div
                        {...rest}
                        key={"Table-Row-" + key}
                        style={{...style, ...extraStyle}}
                        className={
                          column.show && column.show === false ? "hide-th th" : "th"
                        }
                      >
                        {column.render("Header")}
                        {column?.id !== "selection" && column?.isSort && (
                          <span
                            style={{padding: "0 8px", marginLeft: 0}}
                            onClick={
                              column?.isSort
                                ? column.getSortByToggleProps().onClick
                                : null
                            }
                            className="table-sort"
                          >
                            {column.isSorted && column.isSortedDesc ? (
                              <Icons size={15} color={"#333"} name="arrow-down" />
                            ) : column.isSorted && !column.isSortedDesc ? (
                              <Icons size={15} color={"#333"} name="arrow-up" />
                            ) : (
                              <Icons size={15} color={"#DBDDE0"} name="arrow-down" />
                            )}
                          </span>
                        )}
                        {column?.Filter && <column.Filter />}
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div {...getTableBodyProps()} className="table-body">
            {rows.map((row, key) => {
              prepareRow(row);
              return (
                <div
                  key={"Table-Row-" + key}
                  {...row.getRowProps()}
                  onClick={() => onRowClick && onRowClick(row)}
                  className={classNames("tr", {"tr-sub": row.depth === 1})}
                >
                  {row.cells.map((cell: any, any) => {
                    const {style, ...otherProps} = cell.getCellProps();
                    const extraStyle =
                      cell.column.Header === "Status" ? {overflow: "unset"} : {};
                    const extraStyleNew = cell.column?.maxWidth
                      ? {maxWidth: cell.column.maxWidth}
                      : {};
                    return (
                      <div
                        key={"Table-Cell-" + key}
                        className="role-td"
                        style={{
                          ...style,
                          ...extraStyle,
                          ...extraStyleNew,
                          position: "relative",
                        }}
                        {...otherProps}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {isEmpty(rows) && (
              <div className="table-no-data">
                <span>No Data Found For This Table</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
