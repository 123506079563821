import React, {Component} from "react";
import {connect} from "react-redux";
import "./style.scss";
import Form from "components/form";
import Button from "components/button";
import {InputValidator} from "helpers/inputValidator";
import isEmpty from "isEmpty";
import {objectToFormData} from "object-to-formdata";
import {DisplayClientSideMessage} from "actions/commonAction";
import {
  saveEmployeeEducationDetails,
  updateEmployeeEducationDetails,
  getEmployeeEducationList,
  GetQualificationList,
} from "../action";
import {isNull} from "util";

class EducationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddSection: false,
      editView: false,
      errors: {},
      Course: "",
      Qualification: null,
      College: "",
      Country: null,
      University: "",
      PassedYear: null,
      GradeValue: null,
      MajorSubjects: null,
      IsGPA: true,
      IsFileEdit: true,
    };
  }

  async componentDidMount() {
    const {
      editView,
      editViewData,
      countryList,
      GetQualificationList,
      educationQualificationList,
    } = this.props;
    if (isEmpty(educationQualificationList)) {
      await GetQualificationList();
    }
    // educationQualificationList = this.props.educationQualificationList;
    if (editView) {
      const index =
        countryList && countryList.findIndex((e) => e.label == editViewData.Country);
      const qualificationIndex =
        educationQualificationList &&
        educationQualificationList.findIndex((e) => e.value == editViewData.LevelTypeID);
      this.setState({
        ...editViewData,
        Files: {
          name: editViewData.UserFileName,
        },
        IsFileEdit: false,
        Qualification: educationQualificationList[qualificationIndex],
        Country: countryList[index],
      });
    }
  }

  handleInput = (name, value) => {
    console.log();
    this.setState({[name]: value}, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  setSelectedFiles = (name, file) => {
    this.setState({Files: file, IsFileEdit: true});
  };

  changeGradingSystem = (IsGPA) => this.setState({IsGPA});

  handleAutoSelect = (label, value) => {
    this.setState({[label]: value}, () => {
      this.updateErrors();
    });
  };

  // onValidate = () =>{
  //     let errs = {};
  //     const { Files } = this.state;
  //     if(isEmpty(Files?.name)){
  //         errs.Name = 'Document field is required'
  //     }
  //     if(Files === null){
  //         errs.File = 'Document File field is required'
  //     }
  //     return {errs, isValid:isEmpty(errs)}
  // }

  handleSave = async () => {
    let errors = await InputValidator(document, this.state);
    // const { errs, isValid } = this.onValidate();
    // errors = { ...errors, ...errs }
    // if (errs.Name) {
    //     this.props.DisplayClientSideMessage('Document is Required');
    // }
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }
    let {
      Course,
      University,
      PassedYear,
      College,
      LevelTypeID,
      Country,
      MajorSubjects,
      IsGPA,
      GradeValue,
      Files,
      Qualification,
      IsFileEdit,
    } = this.state;

    let data = {
      EductionId: this.props.editView ? this.props.editViewData.EductionId : 0,
      EmployeeId: parseInt(this.props.employeeId),
      Course: Course,
      University,
      PassedYear,
      College,
      LevelTypeID: Qualification.value,
      Country: Country.label,
      MajorSubjects,
      IsGPA,
      GradeValue,
      IsFileEdit,
    };
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };
    const formData = objectToFormData(data, options);
    // if (IsFileEdit == true) formData.append(`File`, null);
    formData.append(`File`, Files);

    if (this.props.editView) await this.props.updateEmployeeEducationDetails(formData);
    else await this.props.saveEmployeeEducationDetails(formData);
    if (this.props.employeeEducationSaved) {
      this.props.getEmployeeEducationList(this.props.employeeId);
      this.closeAddSection(false);
    }
  };

  closeAddSection = () => {
    if (this.props.editView) this.props.toggleEditView();
    else this.props.closeAddSection();
  };

  render() {
    const {
      Course,
      Qualification,
      College,
      PassedYear,
      University,
      GradeValue,
      errors,
      Country,
      MajorSubjects,
      Files,
      IsGPA,
      QualificationList,
    } = this.state;

    const {editView, countryList, educationQualificationList} = this.props;

    return (
      <div className="education__education__body">
        <div className="education__education__div">
          <div className="education__education__div__label">
            <div className="education__education__labels">
              <Form
                name="Course"
                value={Course}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Name of the Degree"
                validators={["required"]}
                error={errors && errors.Course}
              />
            </div>
            <div className="education__education__labels">
              <Form
                formName="reactselect"
                name="Qualification"
                value={Qualification}
                options={educationQualificationList}
                onChange={this.handleAutoSelect}
                alignment="horizontal"
                label="Qualification Level"
                placeholder="Select.."
                validators={["required"]}
                error={errors && errors.Qualification}
                // width="200px"
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="College"
                value={College}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Institution"
                validators={["required"]}
                error={errors && errors.College}
              />
            </div>
            <div className="education__education__labels">
              <Form
                formName="reactselect"
                name="Country"
                value={Country}
                options={countryList}
                onChange={this.handleAutoSelect}
                alignment="horizontal"
                label="Country"
                placeholder="Select.."
                validators={["required"]}
                error={errors && errors.Country}
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="University"
                value={University}
                onChange={this.handleInput}
                alignment="horizontal"
                label="University or Board"
                validators={["required"]}
                error={errors && errors.University}
              />
            </div>
            <div className="education__education__labels education-smallSection">
              <Form
                name="PassedYear"
                value={PassedYear}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Year"
                validators={["upToCurrentYear"]}
                error={errors && errors.PassedYear}
              />
            </div>
            <div className="education__education__labels education-gradeSection">
              <span className="education__education__div__title">Grade</span>
              <div className="education__education__grade">
                <div className="education__education__grade__options">
                  <span
                    onClick={() => this.changeGradingSystem(false)}
                    className={!IsGPA ? "backgroundToBlue" : "backgroundToGrey"}
                  >
                    %
                  </span>
                  <span
                    onClick={() => this.changeGradingSystem(true)}
                    className={IsGPA ? "backgroundToBlue" : "backgroundToGrey"}
                  >
                    GPA
                  </span>
                </div>
                <Form name="GradeValue" value={GradeValue} onChange={this.handleInput} />
              </div>
            </div>
            <div className="education__education__labels">
              <Form
                name="MajorSubjects"
                value={MajorSubjects}
                onChange={this.handleInput}
                label="Major Subjects"
                alignment="horizontal"
                validators={["required"]}
                error={errors && errors.MajorSubjects}
              />
            </div>
            <div className="button-group button-group__right education__savebutton">
              <Button bg="primary" onClick={this.handleSave}>
                {!editView ? "Add" : "Update"}
              </Button>
              <Button onClick={() => this.closeAddSection()} bg="secondary">
                Cancel
              </Button>
            </div>
          </div>
          <div className="education__education__attachments">
            <Form
              formName="singledocumentinput"
              value={Files}
              onChange={this.setSelectedFiles}
              btnText="Add Documents"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeEducationSaved: state.employeeProfile.employeeEducationSaved,
  educationQualificationList: state.employeeProfile.educationQualificationList,
  countryList: state.commonReducer.countryList,
});

const mapDispatchToProps = {
  saveEmployeeEducationDetails,
  updateEmployeeEducationDetails,
  getEmployeeEducationList,
  GetQualificationList,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);
