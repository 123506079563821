import React from 'react'
import { 
    calendarDates,
    isSameMonth,
    isSameDay,
    inBetween,
} from '../../helpers/date'
import classnames from 'classnames';
import dayjs from 'dayjs';

interface PopupInterface{
    today:any,
    currentDate:any,
    onDateClick:Function,
    setCurrentDate:Function,
    value:any,
    isSingle:boolean,
    valueType:'single' | 'weekly' | 'monthly' | 'quaterly',
    format:any
}

function CalendarDates(props:PopupInterface) {
    const {
        today,
        currentDate,
        onDateClick,
        value,
        setCurrentDate,
        isSingle,
        format,
        valueType,
    } = props;
    const dates = calendarDates(currentDate);
    let selectedDate:any = null;
    const onClick = (date:any) =>{
        if(valueType === 'weekly'){
            var start_date = dayjs(dayjs(date).startOf('week').toDate()).format(format)
            var end_date = dayjs(dayjs(date).endOf('week').toDate()).format(format);
            onDateClick({start_date,end_date})
            return;
        }
        onDateClick(date)
    }
    return (
        <div>
            <div className="cal-popup__grid">
                {
                    dates && dates.map(dateArray => {
                        const [year, month, day] = dateArray;
                        const ndate = new Date(year,month-1,day);
                        const fdate = dayjs(ndate).format("YYYY-MM-DD");
                        const inMonth = isSameMonth(fdate, currentDate);
                        const isToday = isSameDay(fdate, today);
                        const isCurrent = value && isSingle && isSameDay(fdate, value);
                        const startDate = value && !isSingle && value?.start_date &&  isSameDay(fdate, value?.start_date);
                        const endDate = value && !isSingle && value?.end_date &&  isSameDay(fdate, value?.end_date);
                        const inBet = value && !isSingle && value?.end_date && value?.start_date &&  inBetween(value?.start_date, value?.end_date,fdate);
                        
                        const onMouseOver = (e:any) => {
                            e.currentTarget.classList.add("cal-popup__over");
                            if(valueType === 'weekly'){
                                let overDate = e.target.title;
                                var firstDayOfWeek = dayjs(dayjs(overDate).startOf('week').toDate()).format(format)
                                var lastDayOfWeek = dayjs(dayjs(overDate).endOf('week').toDate()).format(format);
                                var firstEle = document.querySelector(`[title="${firstDayOfWeek}"]`);
                                var endEle = document.querySelector(`[title="${lastDayOfWeek}"]`);
                                if(firstEle){
                                    firstEle.classList.add("cal-popup__week-start")
                                }
                                if(endEle){
                                    endEle.classList.add("cal-popup__week-end")
                                }
                            }
                            // if (dateRange.startDate && !dateRange.endDate) {
                            //   // console.log(e.currentTarget.title);
                            //   const dateTitle = e.currentTarget.title;
                            //   if (engCalendarFuncs.dateDiff(dateRange.startDate, dateTitle) <= 0) {
                            //     e.currentTarget.classList.add("cell-over-end");
                            //   } else {
                            //     e.currentTarget.classList.add("cell-over-start");
                            //   }
                        
                            //   if (hoveredDate !== dateTitle) {
                            //     setHoveredDate(dateTitle);
                            //   }
                            // }
                          };
                        const onMouseOut = (e:any) => {
                            e.currentTarget.classList.remove("cal-popup__over");
                            e.currentTarget.classList.remove("cal-popup__over-end");
                            e.currentTarget.classList.remove("cal-popup__over-start");
                            if(valueType === 'weekly'){
                                let overDate = e.target.title;
                                var firstDayOfWeek = dayjs(dayjs(overDate).startOf('week').toDate()).format(format)
                                var lastDayOfWeek = dayjs(dayjs(overDate).endOf('week').toDate()).format(format);
                                var firstEle = document.querySelector(`[title="${firstDayOfWeek}"]`);
                                var endEle = document.querySelector(`[title="${lastDayOfWeek}"]`);
                                if(firstEle){
                                    firstEle.classList.remove("cal-popup__week-start")
                                }
                                if(endEle){
                                    endEle.classList.remove("cal-popup__week-end")
                                }
                            }
                            // if (hoveredDate) {
                            //   setHoveredDate("");
                            // }
                        };
                        const dateClass = classnames("cal-popup__cell cal-popup__date",{
                            "cal-popup__date-out":!inMonth,
                            "cal-popup__date-today":isToday,
                            "cal-popup__date-active":isCurrent,
                            "cal-popup__date-start":startDate,
                            "cal-popup__date-end":endDate,
                            "cal-popup__date-between":inBet,
                        })
                        return (
                            <div 
                                key={fdate} 
                                className={dateClass} 
                                title={fdate}
                                onMouseOver={(e) => onMouseOver(e)}
                                onMouseOut={(e) => onMouseOut(e)}
                                onClick={()=>!isSingle && onClick(fdate)}
                            >
                                <div 
                                    onClick={()=>isSingle && onClick(fdate)}
                                    title={fdate}
                                    className="cal-popup__date-value"
                                >
                                    {day}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CalendarDates