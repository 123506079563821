import React, {Component, useEffect, useState} from "react";
import man from "assets/images/man.png";
import {trash} from "assets/images/svgs/icons/trash";
import {email} from "assets/images/svgs/icons/email";
import emailOpen from "assets/images/svgs/icons/emailOpen";
import {FaRegTimesCircle} from "react-icons/fa";
import isEmpty from "isEmpty";
import {getEmployeeMessages} from "../../action";
import {connect, useDispatch, useSelector} from "react-redux";
import {UserByStore} from "constants/userDetails";
import MessageCard from "./messageCard";
import {formatedShortMonthDayYear} from "helpers/dateFormat";
import Pagination from "components/common/pagination";
import {Link} from "react-router-dom";
import RouteEnum from "constants/routeEnum";

function MessageListView(props) {
  const {disablePaging} = props;
  const {employeeMessages} = useSelector((state: any) => state.employeeReducer);

  const [state, setState] = useState({
    open: false,
    pageIndex: 0,
    rowsPerPage: 5,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    onDataLoad(state);
  }, []);

  const onDataLoad = (param) => {
    const user: any = UserByStore();
    if (user) {
      const data = {
        userName: user.UserName,
        pageIndex: state.pageIndex,
        pageSize: state.rowsPerPage,
      };
      dispatch(getEmployeeMessages(data));
    }
  };
  const onViewOpen = () => {};
  const handleRowChange = (num) => {
    setState((prev) => {
      let newState = {...prev, rowsPerPage: num};
      onDataLoad(newState);
      return newState;
    });
  };

  const handleIndexChange = (num) => {
    setState((prev) => {
      let newState = {...prev, pageIndex: num};
      onDataLoad(newState);
      return newState;
    });
  };
  return (
    <div id="messages" className="messages-div">
      <div className="messages">
        <h3 className="mypage-section-title">
          <span>Messages</span>
          <span className="close-icon" onClick={() => onViewOpen()}>
            <FaRegTimesCircle />
          </span>
        </h3>
        {isEmpty(employeeMessages) ? (
          <div className="no-found-container">
            <span className="no-found">No Messages Found</span>
          </div>
        ) : (
          <div className="messages-container">
            <div>
              <ul className="messages-list">
                {employeeMessages.map((item, index) => (
                  <MessageCard item={item} key={index} />
                ))}
              </ul>
            </div>
            <div className="messages-paginate">
              {disablePaging === true ? (
                <div className="view-btn">
                  <Link to={RouteEnum.EmployeeViewAllMessage}>
                    <span>View All</span>
                  </Link>
                </div>
              ) : (
                <Pagination
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  pageIndex={state.pageIndex}
                  rowsPerPage={state.rowsPerPage}
                  total={employeeMessages[0].TotalRows}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageListView;
