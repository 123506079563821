import React from "react";
import Button from "components/button";
import {MdEdit} from "react-icons/md";
import Avatar from "react-avatar";
import {connect} from "react-redux";
import RouteEnum from "constants/routeEnum";
import {BrowserRouter as Router, Link} from "react-router-dom";

function JobHeader(props) {
  let {EmployeeName, Designation, Branch, Department} = props.profileInfo;

  return (
    <div className="jobdesc-header">
      <div className="jobdesc-left">
        <div className="jobdesc-profile">
          <div className="jobdesc-profile__image">
            <Avatar
              className="person-logo"
              name={"Sumnima Shahi"}
              size={60}
              round={true}
              src={""}
            />
          </div>
          <div className="jobdesc-profile__info">
            <h3>{EmployeeName}</h3>
            <span className="position">{Branch}</span>
            <span className="departmant">{Department}</span>
          </div>
        </div>
      </div>
      {/* <div className="jobdesc-right">
            {
                props.isEmployee ? null : (
                    <Link to={RouteEnum.UpdateJobDescriptionPath + props.jobDesc.JobDescriptionId + `?emp=${props.employeeId}`} target="_blank">
                        <Button 
                            icon={<MdEdit />}
                            bg="highlight"
                            >
                            Edit
                        </Button>
                    </Link>
                )
            }
            </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  profileInfo:
    state.auth.user && state.auth.user.RoleId === 2
      ? state.auth.user
      : state.employeeProfile.employeeProfile &&
        state.employeeProfile.employeeProfile.EmployeeProfileHeader,
});

export default connect(mapStateToProps, null)(JobHeader);
