import React, {useState, useEffect, useRef} from "react";
import Unavaiiability from "./unavailability";
import Locations from "./locations";
import Team from "./team";
import Schedule from "./schedules";
import Requests from "./requests";
import Roles from "./roles";
import "./styles.scss";
import classnames from "classnames";
import {BiCalendarCheck, BiCopy} from "react-icons/bi";
import ScheduleCopyModal from "./schedules/components/scheduleCopyModal";
import {IoCopyOutline} from "react-icons/io5";
import {FiPrinter} from "react-icons/fi";
import Publish from "./schedules/components/publish";
import {useHistory} from "react-router";
import RouteEnum from "constants/routeEnum";
import {useDispatch, useSelector} from "react-redux";
import {getRosterTeamRoleList, getPublishedHistory} from "scenes/roster/helpers/action";
import {getDatesInBetween} from "../helpers/roosterDates";
import PublishedShifts from "./publishedShifts";
import {motion} from "framer-motion";

const tabs = [
  {id: 1, name: "schedule", label: "Schedule", component: Schedule},
  // {id: 2, name: "requests", label: "Requests", component: Requests},
  {id: 3, name: "unavailability", label: "Unavailability", component: Unavaiiability},
  {id: 4, name: "team", label: "Team", component: Team},
  // {id: 5, name: "locations", label: "Locations", component: Locations},
  {id: 6, name: "roles", label: "Roles", component: Roles},
  {
    id: 7,
    name: "published_shifts",
    label: "Published Shifts",
    component: PublishedShifts,
  },
];

const tabContentVariant = {
  active: {
    display: "block",
    transition: {
      staggerChildren: 0.2,
    },
  },
  inactive: {
    display: "none",
  },
};

function Schedules() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [open, setOpen] = useState(false);
  const [isPublishable, setIsPublishable] = useState({
    isLeaveError: false,
    isWorkOffError: false,
    isPositionError: false,
    isEmptyEmployeeError: false,
    workOffNo: 0,
    scheduleListCount: 0,
  });
  const [state, setState] = useState(null);
  const history = useHistory();
  const onTeamSet = (st) => setState(st);
  const onModalClose = () => setOpen(false);
  const {scheduleList, scheduleEmployeeList} = useSelector(
    (state: any) => state.roosterReducer
  );

  const dispatch = useDispatch();

  const childRef = useRef(null);

  useEffect(() => {
    if (childRef?.current !== null) {
      (async () => {
        const scheduleListLength = scheduleList?.length || 0;
        let newPublishable = {...isPublishable, scheduleListCount: scheduleListLength};
        if (scheduleListLength > 0 && scheduleEmployeeList?.length > 0) {
          const scheduleState = await childRef.current.onSave();
          if (
            scheduleState?.endDate &&
            scheduleState?.startDate &&
            scheduleState?.teamId
          ) {
            const dates = getDatesInBetween(
              scheduleState?.startDate,
              scheduleState?.endDate,
              scheduleList,
              scheduleEmployeeList
            );
            if (dates && dates.length > 1) {
              const isNotLeaveError = dates.every((item) => item.isLeaveError == false);
              const isNotWorkOffError = dates.every(
                (item) => item.isWorkOffError == false
              );
              const isNotPositionError = dates.every(
                (item) => item.isPositionError == false
              );
              const isNotEmptyEmployeeError = dates.every(
                (item) => item.isEmptyEmployee == false
              );
              const workOffNo = dates.reduce((total, item) => total + item.workOffNo, 0);
              newPublishable = {
                ...newPublishable,
                isLeaveError: !isNotLeaveError,
                isWorkOffError: !isNotWorkOffError,
                isPositionError: !isNotPositionError,
                isEmptyEmployeeError: !isNotEmptyEmployeeError,
                workOffNo,
              };
              // setIsPublishable({
              //   scheduleListCount: scheduleListLength,
              //   isLeaveError: !isNotLeaveError,
              //   isWorkOffError: !isNotWorkOffError,
              //   isPositionError: !isNotPositionError,
              //   isEmptyEmployeeError: !isNotEmptyEmployeeError,
              //   workOffNo,
              // });
            }
          }
        }
        setIsPublishable(newPublishable);
      })();
    }
  }, [scheduleList, scheduleEmployeeList]);

  useEffect(() => {
    if (activeTab?.label === "Roles") {
      dispatch(getRosterTeamRoleList(null));
    }
  }, [activeTab]);

  // console.log({childRef})
  const onTabClick = (tabItem) => {
    setActiveTab(tabItem);
  };
  const handleTabIndexChange = (index,isId = false) => {
    setActiveTab(isId ? tabs.find(item=>item.id === index) : tabs[index]);
  };
  // const ActiveComponent: any = activeTab.component;
  useEffect(() => {
    document.title = "Rooster Schedule";
  }, []);
  const onPdf = async () => {
    if (childRef && childRef.current) {
      const state = await childRef.current.onSave();
      const {teamId, startDate, endDate} = state;
      if (state) {
        history.push(
          `${RouteEnum.roosterSchedulePdfRoutes}/?teamId=${teamId}&startDate=${startDate}&endDate=${endDate}`
        );
      }
    }
    // const tId = tea
    // history.push(RouteEnum.roosterSchedulePdfRoutes)
  };
  const getPublishHistory = () => {
    if (state.startDate && state.endDate && state.teamId) {
      const data = {
        StartDate: state.startDate,
        EndDate: state.endDate,
        TeamId: state.teamId.value,
        PageIndex: 0,
        PageSize: 10,
      };
      dispatch(getPublishedHistory(data));
    }
  };
  return (
    <div className="scheduler">
      <div className="scheduler-tabs">
        <div className="scheduler-tabs__container">
          <ul className="scheduler-tabs__list">
            {tabs &&
              tabs.map(
                (tab) =>
                  tab.id !== 7 && (
                    <SchedulerTab
                      active={activeTab}
                      key={tab.id}
                      tab={tab}
                      onTabClick={onTabClick}
                    />
                  )
              )}
          </ul>

          {/* {state &&
            state.teamId &&
            state.startDate &&
            state.endDate &&
            activeTab.id == 1 && (
              <div className="scheduler-tabs__btns">
                <div onClick={() => setOpen(true)} className="scheduler-tabs__btn">
                  <IoCopyOutline />
                  <span>Copy</span>
                </div>
                <div onClick={() => onTabClick(tabs[6])} className="scheduler-tabs__btn">
                  <BiCalendarCheck />
                  <span>Schedules</span>
                </div>
                <ScheduleCopyModal
                  open={open}
                  onModalClose={onModalClose}
                  teamId={state.teamId}
                  isSingle={false}
                  startDate={state.startDate}
                  endDate={state.endDate}
                />
               
                <Publish
                  teamId={state.teamId}
                  startDate={state.startDate}
                  endDate={state.endDate}
                  errors={isPublishable}
                  successSubmitting={() => getPublishHistory()}
                />
              </div>
            )} */}
        </div>
        <div className="scheduler-tabs__body">
          {/* <ActiveComponent onTeamSet={onTeamSet} ref={childRef} /> */}
          {tabs &&
            tabs.map((tab) => {
              let Component = tab.component;
              return (
                // <div
                //   style={{display: activeTab.id === tab.id ? "block" : "none"}}
                //   className=""
                // >
                <motion.div
                  role="tabpanel"
                  id={tab.id + ""}
                  // className="tab-scontents"
                  variants={tabContentVariant}
                  animate={activeTab.id === tab.id ? "active" : "inactive"}
                  initial="inactive"
                >
                  <Component
                    stDate={state?.startDate}
                    edDate={state?.endDate}
                    tId={state?.teamId}
                    onTeamSet={onTeamSet}
                    onTabIndexChange={handleTabIndexChange}
                    isPublishable={isPublishable}
                    ref={childRef}
                  />
                </motion.div>

                // </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export const SchedulerTab = ({tab, onTabClick, active}) => {
  const tabClass = classnames("scheduler-tabs__item", {
    "scheduler-tabs__active": active.id === tab.id,
  });
  return (
    <li className={tabClass} onClick={() => onTabClick(tab)}>
      <div className="scheduler-tabs__label">{tab.label}</div>
    </li>
  );
};

export default Schedules;
