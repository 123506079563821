import classNames from "classnames";
import {DateFormat} from "helpers/dateFormat";
import isEmpty from "helpers/isEmpty";
import React from "react";
import Table from "components/customTable";

const WorkScheduleTable = (props) => {
  const {data, type} = props;

  if (data.length === 0)
    return <div className="no-found">Nothing scheduled for this {type}</div>;

  var cols = [];
  if (data && data.length > 0) {
    for (var key in data[0]) {
      var obj = {Header: "", accessor: key};
      if (key === "name") obj["maxWidth"] = 200;
      else obj["maxWidth"] = 350;
      cols.push(obj);
    }
  }

  return <Table data={data} columns={cols} hideHeader />;
};

export default WorkScheduleTable;
