import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {deleteCourse, updateCourseStatus} from "../../helpers/action";
import Dropdown from "components/dropdown";
import RouteEnum from "constants/routeEnum";
import {CourseStatus} from "scenes/lms/helpers/enums";
import Modal from "components/modal";

const AssignedDrop = (props) => {
  const [confirm, setConfirm] = useState({
    open: false,
    title: "",
    message: "",
    type: "",
    okButtonText: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onStatusChanges = async () => {
    const {Status, Id} = props.row?.original;
    await dispatch(
      updateCourseStatus(
        Id,
        Status === CourseStatus.Publish || Status === CourseStatus.Discontinue
          ? CourseStatus.Unpublish
          : CourseStatus.Publish
      )
    );
    props.onStatusChange();
  };
  const onStatusDiscontinue = async () => {
    const {Id} = props.row?.original;
    await dispatch(updateCourseStatus(Id, CourseStatus.Discontinue));

    props.onStatusChange();
  };

  const onConfirmed = () => {
    if (confirm.type === "delete") {
      onDeleteCourse();
    } else if (confirm.type === "discontinue") {
      onStatusDiscontinue();
    } else if (confirm.type === "statusChange") {
      onStatusChanges();
    }
  };

  const onDeleteCourse = async () => {
    const {Id} = props.row?.original;
    var res = await dispatch(deleteCourse(Id));

    res && props.onStatusChange();
  };

  const showConfirm = (title, message, type, okButton) => {
    setConfirm({title, message, type, open: true, okButtonText: okButton});
  };
  const getTitle = () =>
    props.row?.original?.Status === CourseStatus.Discontinue
      ? "Enable"
      : props.row?.original?.Status === CourseStatus.Publish
      ? "Unpublish"
      : "Publish";

  const getStatusMessage = () =>
    props.row?.original?.Status === CourseStatus.Discontinue
      ? "Are you sure you want to enable this course?"
      : props.row?.original?.Status === CourseStatus.Publish
      ? "Are you sure you want to unpublish this course?"
      : "Are you sure you want to publish this course?";

  const isPrimarButtonType = () => {
    if (
      confirm.type === "discontinue" ||
      confirm.type === "delete" ||
      confirm.title === "Unpublish"
    )
      return false;
    return true;
  };

  const showDiscontinueAction = () => {
    const {Status, Assigned, AssignedCompleted} = props.row?.original;
    if (Status === CourseStatus.Discontinue) return false;
    if (Assigned > 0 && Assigned !== AssignedCompleted) return false;
    return true;
  };

  // if (Status === CourseStatus.Discontinue) return null;

  return (
    <div className="course-drop">
      <Dropdown isFixed>
        <div className="course-drop__list">
          <div
            onClick={() =>
              history.push(RouteEnum.lmsCourseAdd + "/" + props.row?.original?.Id)
            }
            className="course-drop__item"
          >
            <span>Edit</span>
          </div>
          <div
            onClick={() =>
              history.push(RouteEnum.lmsCourseSettings + "/" + props.row?.original?.Id)
            }
            className="course-drop__item"
          >
            <span>Course Options</span>
          </div>
          <div
            onClick={() =>
              showConfirm(getTitle(), getStatusMessage(), "statusChange", getTitle())
            }
            // onClick={() => onStatusChanges()}
            className="course-drop__item course-drop__item-blue"
          >
            <span>{getTitle()}</span>
          </div>
          {showDiscontinueAction() && (
            <div
              onClick={() =>
                showConfirm(
                  "Discontinue",
                  "Are you sure you want to discontinue this course?",
                  "discontinue",
                  "Discontinue"
                )
              }
              // onClick={() => onStatusDiscontinue()}
              className="course-drop__item course-drop__item-red"
            >
              <span>Discontinue </span>
            </div>
          )}
          {props.row?.original?.Assigned === 0 && (
            <div
              onClick={() =>
                showConfirm(
                  "Delete",
                  "Are you sure you want to delete this course?",
                  "delete",
                  "Delete"
                )
              }
              className="course-drop__item course-drop__item-red"
            >
              <span>Delete </span>
            </div>
          )}
        </div>
      </Dropdown>

      <Modal
        type="confirm"
        title={confirm.title}
        func={() => onConfirmed && onConfirmed()}
        open={confirm.open}
        modalMessage={confirm.message}
        isOkButtonPrimary={isPrimarButtonType()}
        okButtonTitle={confirm.okButtonText}
        onModalClose={() =>
          setConfirm({open: false, title: "", message: "", type: "", okButtonText: ""})
        }
      />
    </div>
  );
};

export default AssignedDrop;
