export enum AprAssessmentOptionType
{
    PerformanceAppraisals = 1,
    DegreeFeedback = 2,
    CheckIns = 3
}


export enum AprSignatureType
{
    Employee = 1,
    Manager = 2,
    HR_Manager = 3
}