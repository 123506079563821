import React, {useState, useCallback, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import {Paper} from "@material-ui/core";
import "./profile.styles.scss";
import isEmpty from "isEmpty";
import dataURLtoFile from "helpers/dataURLtoFile";
import Icons from "components/icons";
import {getUploadPath} from "constants/userDetails";
import CropModal from "./cropModal";

const validateImage = (elem) => {
  const validImageExtension = ["png", "jpg", "jpeg"];
  const file = elem[0];
  const validExtensions = validImageExtension;
  const extension = file.name.split(".")[1];
  let errs = {};
  const isValidExtension = validExtensions.find((f) => f.toLowerCase() == extension.toLowerCase()) ? true : false;
  if (file.size > 556000) {
    //556000 Byte==556 Kilobyte
    errs.images = "Maximum valid file size is 556 Kb.";
  } else if (!isValidExtension) {
    errs.images = "Invalid Extension! Valid extension are " + validExtensions;
  }
  return {errs, isValid: isEmpty(errs)};
};

export const UploadProfile = (props) => {
  let {previewImage, uploadProfileImageToParent, uploadCroppedImageImageToParent} = props;

  const [images, setImages] = useState([]);
  const [src, setSrc] = useState("");
  const [cropImages, setCropImages] = useState("");
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const getFileImage = async (croppedFile) => {
    const cropedPhotoName = "croped_" + images[0].name;
    const base64_photo = croppedFile;

    const file_photo = await dataURLtoFile(base64_photo, cropedPhotoName);
    return file_photo;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    const {errs, isValid} = validateImage(acceptedFiles);
    setErrors(errs);
    if (!isValid) {
      return false;
    }
    await setImages(acceptedFiles);
    uploadProfileImageToParent(acceptedFiles);
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setSrc(URL.createObjectURL(images[0]));
    }
  }, [images]);

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();
  const removeimage = () => {
    setImages([]);
    uploadProfileImageToParent([]);
    uploadCroppedImageImageToParent([]);
  };

  const setCropImagesToParent = async (croppedFile) => {
    await setCropImages(croppedFile);

    let croppedPhoto = await getFileImage(croppedFile);
    uploadCroppedImageImageToParent(croppedPhoto);
  };

  previewImage = getUploadPath(previewImage);

  return (
    <div className="profile-change">
      <div className="profile-change__container">
        <div className="profile-change__input">
          <RootRef rootRef={ref}>
            <Paper {...rootProps}>
              <input {...getInputProps()} />
              {!isEmpty(previewImage) && isEmpty(images) ? (
                <div className="profile-change__file">
                  <div style={{display: "block"}} className="profile-change__images">
                    <div className="profile-change__images-block">
                      <div className="profile-change__image">
                        <img src={previewImage} alt="Profile Image" />
                      </div>
                      <h5>Profile Image</h5>
                    </div>
                  </div>
                  <p>
                    <div className="text-blue">Replace photo</div>
                    <div className="">or</div>
                    <span>Drag and drop here</span>
                    {!isEmpty(errors.images) ? (
                      <div className="profile-change__error">{errors.images}</div>
                    ) : null}
                  </p>
                </div>
              ) : !isEmpty(images) ? (
                <div className="profile-change__file">
                  <div className={`${props.isCropped ? "" : "profile-change__images"}`}>
                    {!props.isCropped && (
                      <div className="profile-change__images-block">
                        <div className="profile-change__image">
                          <img src={src} alt="" />
                          <div
                            onClick={() => removeimage()}
                            className="profile-change__image-remove"
                          >
                            <Icons name="Close" justSVG />
                          </div>
                        </div>
                        <h5>Original Image</h5>
                      </div>
                    )}
                    {
                      <div className="profile-change__images-block">
                        <div className="profile-change__image cropped">
                          <img src={cropImages} alt="" />
                          <div
                            onClick={() => setCropImages(null)}
                            className="profile-change__image-remove"
                          >
                            <Icons name="Close" justSVG />
                          </div>
                          {errors.cropped ? (
                            <div className="profile-change__image-error">
                              <span>{errors.cropped}</span>
                            </div>
                          ) : null}
                        </div>
                        {!props.isCropped && <h5>Cropped Image</h5>}
                      </div>
                    }
                  </div>
                  <p>
                    <div className="text-blue">Replace photo</div>
                    <div className="">or</div>
                    <span>Drag and drop here</span>
                  </p>
                </div>
              ) : (
                <p>
                  <div className="text-blue">Upload a profile picture</div>
                  <div className="">or</div>
                  <span>Drag and drop here</span>
                  {!isEmpty(errors.images) ? (
                    <div className="profile-change__error">{errors.images}</div>
                  ) : null}
                </p>
              )}
            </Paper>
          </RootRef>
        </div>
      </div>
      <CropModal
        cropImages={cropImages}
        setCropImage={setCropImagesToParent}
        setImages={setImages}
        open={open}
        setOpen={setOpen}
        src={src}
      />
    </div>
  );
};
