import FormGroup from "components/form";
import * as React from "react";
import {useSelector} from "react-redux";
import {BS2AD, AD2BS} from "helpers/dateConvert";
import {Nepali_Date_Format} from "helpers/dateFormat";
import isEmpty from "isEmpty";
interface IAppProps {
  formName?: any;
  name: string;
  placeholder: string;
  size?: string;
  value: any;
  minDate?: any;
  maxDate?: any;
  error?: any;
  width: string;
  label?: string;
  handleBlur?: Function;
  onChange: Function;
  onKeyUp?: Function;
  disabled?: boolean;
  isFullDetails?: boolean;
  isNepali?: boolean;
  isDateRange?: boolean;
  isTextInNepali?: boolean;
  validators?: any;
}
const getFormattedDate = (date) => {
  // let newDate = Nepali_Date_Format(date.startDate);
  // const conversionDateStart = AD2BS(newDate).en;
  // newDate = `${conversionDateStart.year}/${conversionDateStart.month}/${conversionDateStart.day}`;
  let newDate = date;
  return newDate;
};
const DatepickerAsPerSetting: React.FunctionComponent<IAppProps> = (props) => {
  let state = {...props};
  const {companyInformation} = useSelector((state: any) => state.commonReducer);
  if (!companyInformation.IsEnglishDate) {
    try {
      if (props.isDateRange) {
        let nepaliFormatedStartDate = "";
        let nepaliFormatedEndDate = "";
        if (props.value.startDate) {
          //for start date
          nepaliFormatedStartDate = getFormattedDate(props.value.startDate);
        }
        if (props.value.endDate) {
          //for end date
          nepaliFormatedEndDate = getFormattedDate(props.value.endDate);
        }
        state.value = {
          startDate: nepaliFormatedStartDate,
          endDate: nepaliFormatedEndDate,
        };
      } else if (props.value) {
        let nepaliFormatedDate = getFormattedDate(props.value);
        state.value = nepaliFormatedDate;
      }
    } catch (ex) {}
  }
  return (
    <FormGroup
      // enableOkButton
      formName="customdateinput"
      {...state}
      isNepali={!companyInformation.IsEnglishDate}
      isTextInNepali={
        !isEmpty(props?.isTextInNepali)
          ? props?.isTextInNepali
          : !companyInformation.IsEnglishDate
      }
    />
  );
};
export default DatepickerAsPerSetting;
