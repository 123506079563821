import React from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import styled from "styled-components";

const TData = styled.td`
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(195, 195, 195);
  color: rgb(81, 81, 81);
`;

const Input = styled.input`
  width: 50px;
  padding: 2px 4px;
`;

const EditableRow = ({
  editLevelData,
  handleEditLevelDataChange,
  handleCancelClick,
  handleEditLevelDataSubmit,
}) => {
  return (
    <tr>
      <TData style={{width: "250px"}}>{editLevelData.Name}</TData>
      <TData style={{width: "60px"}}>
        <Input
          type="number"
          required="required"
          placeholder="Rate"
          name="Amount"
          value={editLevelData.Amount}
          onChange={handleEditLevelDataChange}
        />
      </TData>
      <TData>
        <FaCheck
          className="edit-box cursor-pointer"
          onClick={handleEditLevelDataSubmit}
        />
        <FaTimes className="ml-xsm edit-box cursor-pointer" onClick={handleCancelClick} />
      </TData>
    </tr>
  );
};

export default EditableRow;
