import React, {useState} from "react";
import shortid from "shortid";
import Modal from "components/modal";
import Form from "components/form";
import Button from "components/button";
import {
  BsFillQuestionSquareFill,
  BsQuestionCircleFill,
  BsTextareaT,
} from "react-icons/bs";
import QuestionControl from "../../../common/questionControl";
import {MdCheckBox, MdClose} from "react-icons/md";
import {AnswerType, LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";
import questionTypeOptions from "scenes/lms/helpers/questionTypeOptions";

import {getNewQuestionObject} from "../../../helpers/constants";
import {v4 as uuidv4} from "uuid";
import {BsPlus} from "react-icons/bs";
const QuestionSection = (props) => {
  const {setSections, section, sectionIndex, methods} = props;

  const onQuestionAdd = () => {
    setSections((prev) => {
      let newSections = [...prev];
      let obj = getNewQuestionObject(newSections[0].section);
      newSections[sectionIndex].push(
        getNewQuestionObject(newSections[sectionIndex][0].section)
      );
      return newSections;
    });
  };
  const handleChange = async (sIndex, qnIndex, name, value) =>
    setSections((prev) => {
      let newData = [...prev];
      newData[sIndex][qnIndex][name] = value;
      if (name === "questionType") {
        let options = [
          {isChecked: false, answerOption: "", marks: 0},
          {isChecked: false, answerOption: "", marks: 0},
        ];
        if (value?.value === LMSQuestionTypeEnum.CheckBox) {
          options.push({isChecked: false, answerOption: "", marks: 0});
          options.push({isChecked: false, answerOption: "", marks: 0});
        }

        newData[sIndex][qnIndex]["answerOptions"] = options;
      }
      return newData;
    });
  const handleDuplicate = (itemIndex) => {
    setSections((sections) => {
      let newSections = [...sections];
      var newSectionItem = {...newSections[sectionIndex][itemIndex]};
      newSections[sectionIndex].push(newSectionItem);
      return newSections;
    });
  };
  const handleDelete = (itemIndex) => {
    setSections((sections) => {
      let newSections = [...sections];
      newSections[sectionIndex].splice(itemIndex, 1);
      if (newSections[sectionIndex].length == 0) newSections.splice(sectionIndex, 1);
      return newSections;
    });
  };
  const onSetItem = (callBack, itemIndex) => {
    let oldSectionItem = section[itemIndex];
    let newSectionItem = callBack(oldSectionItem);
    setSections((sections) => {
      let newSections = [...sections];
      newSections[sectionIndex][itemIndex] = newSectionItem;
      return newSections;
    });
  };

  return (
    <ul className="lms-questions__list nogap">
      <li className="lms-questions__item course-card">
        <div className="lms-questions__title">Questions</div>
        {section?.map((item, i) => (
          <div className="lms-question active" key={`section${i}`}>
            <QuestionControl
              methods={methods}
              onQuestionRemove={() => {}}
              onChange={(name, value) => handleChange(sectionIndex, i, name, value)}
              item={item}
              questionIndex={i}
              setItem={onSetItem}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
            />
          </div>
        ))}

        <div className="lms-questions__footer">
          <a onClick={() => onQuestionAdd()}>
            <BsPlus />
            Add another question
          </a>
        </div>
      </li>
    </ul>
  );
};

export default QuestionSection;
