import {
  GET_DASHBOARD_DETAIL,
  GET_JOB_STAGES,
  GET_APPLIED_CANDIDATE_LIST_BY_JOBID,
  GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID,
  GET_CANDIDATE_DETAILS_BY_JOB_ID,
  GET_CANDIDATE_DETAIL,
  GET_CANDIDATE_DETAIL_LOADING,
  GET_MILESTONE_DETAIL,
  UPDATE_CANDIDATE_DETAILS_FAV,
  GET_HIRING_STAGES_BY_JOB_ID,
  HIRING_STAGES_BY_JOB_ID_LOADING,
  GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID,
  GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING,
  CHANGE_CANDIDATE_STAGE,
  UPDATE_CANDIDATE_DETAILS,
  UPDATE_TAGS,
  GET_MILESTONE_DETAIL_LOADING,
  CURRENT_CANDIDATE_DETAIL,
  CURRENT_CANDIDATE_DETAIL_LOADING,
  PROFILEVIEW_CANDIDATE_DETAILS,
  GET_DASHBOARD_INFO,
  UPDATE_CANDIDATE_REJECTION,
  EXPORT_CANDIDATE_DETAILS_BY_JOB_ID
} from "store/types";
import { api, get, get_by_id, put, put_inline_param, post } from "networkService";
import { RecruitDashboardInformationPath } from "routes/commonLinks";
import async from "react-select/async";
import action from "scenes/recruit/dashboard/information/tableView/table/cells/action";
import environment from 'environment'

export const GetDashboardDetail =
  (jobType: any) => async (dispatch: Function) => {
    const param = { jobType };
    const res: any = await get(
      environment.api.v1.recruitment.dashboard.detail,
      dispatch,
      param
    );
    dispatch({ type: GET_DASHBOARD_DETAIL, payload: res });
  };

export const GetDashboardInfo =
  (jobType: any) => async (dispatch: Function) => {
    const param = { jobType };
    const res: any = await get(environment.api.v1.recruitment.dashboard.info, dispatch, param);
    dispatch({ type: GET_DASHBOARD_INFO, payload: res });
  };

export const GetJobStages = () => async (dispatch: Function) => {
  const res: any = await get(environment.api.v1.recruitment.hiring.stages, dispatch);
  dispatch({ type: GET_JOB_STAGES, payload: res.Data });
};

export const GetAppliedCandidateListByJobId = (jobId) => async (dispatch) => {
  dispatch({
    type: GET_APPLIED_CANDIDATE_LIST_BY_JOBID,
    appliedCandidateList: null,
    candidateListByStage: null,
  });

  const res: any = await get_by_id(
    environment.api.v1.recruitment.candidate.candidates,
    dispatch,
    jobId
  );

  dispatch({
    type: GET_APPLIED_CANDIDATE_LIST_BY_JOBID,
    appliedCandidateList: res.Data.AppliedCandidate,
    candidateListByStage: res.Data.ShortListedCandidate,
  });
};
export const GetCandidateDetailsByJobId = (param, isExport = false) => async (dispatch) => {
  // dispatch({
  //   type: CANDIDATE_DETAILS_LOADING,
  // });
  const res: any = await get(
    environment.api.v1.recruitment.candidate.detailsByJobId,
    dispatch,
    param
  );

  dispatch({
    type: isExport ? EXPORT_CANDIDATE_DETAILS_BY_JOB_ID : GET_CANDIDATE_DETAILS_BY_JOB_ID,
    payload: res.Data,
  });
};
export const GetCandidatesForProfileView = (param) => async (dispatch) => {
  const res: any = await get(
    environment.api.v1.recruitment.candidate.detailsByJobId,
    dispatch,
    param
  );
  dispatch({
    type: PROFILEVIEW_CANDIDATE_DETAILS,
    payload: { candidates: res.Data, ...param },
  });
  return res.Data;
};

export const GetCandidateListByJobAndStageId =
  (jobId, stageId) => async (dispatch) => {
    dispatch({
      type: GET_APPLIED_CANDIDATE_LIST_BY_JOBID,
      appliedCandidateList: null,
      candidateListByStage: null,
    });

    const param = { jobId, stageId };
    const res: any = await get(
      environment.api.v1.recruitment.candidate.listByJobAndStage,
      dispatch,
      param
    );

    dispatch({
      type: GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID,
      payload: res.Data,
    });
  };

export const GetCandidateDetail = (candidateId) => async (dispatch) => {
  dispatch({
    type: GET_CANDIDATE_DETAIL_LOADING,
    payload: true,
  });
  const res: any = await get_by_id(
    environment.api.v1.recruitment.candidate.details,
    dispatch,
    candidateId
  );

  dispatch({
    type: GET_CANDIDATE_DETAIL,
    payload: res.Data,
  });
};

export const ChangeCandidateStage = (param) => async (dispatch) => {
  const res: any = await put_inline_param(
    environment.api.v1.recruitment.candidate.status,
    dispatch,
    param
  );

  dispatch({
    type: CHANGE_CANDIDATE_STAGE,
    currentDashboardDetail: res.Data.RecruitmentDashboard,
  });
};

export const getHiringStagesByJobId = (jobId) => async (dispatch) => {
  dispatch({ type: HIRING_STAGES_BY_JOB_ID_LOADING });

  const res: any = await get_by_id(
    environment.api.v1.recruitment.hiring.stagesByJobId,
    dispatch,
    jobId
  );

  dispatch({
    type: GET_HIRING_STAGES_BY_JOB_ID,
    payload: res.Data,
  });
};

export const GetHiringStagAndSubstageByJobId = (jobId) => async (dispatch) => {
  dispatch({ type: GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING });

  const res: any = await get_by_id(
    environment.api.v1.recruitment.hiring.stageAndSubStagesByJobId,
    dispatch,
    jobId
  );

  dispatch({
    type: GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID,
    payload: res.Data,
  });
};

export const GetMilestoneDetail = (param) => async (dispatch) => {
  dispatch({
    type: GET_MILESTONE_DETAIL_LOADING,
    payload: true,
  });

  const res: any = await get(
    environment.api.v1.recruitment.hiring.stageChangeDetail,
    dispatch,
    param
  );

  dispatch({
    type: GET_MILESTONE_DETAIL,
    payload: res.Data,
  });
};

export const makeCandidateFavorite = (param) => async (dispatch) => {
  //   addLoading(dispatch, true);
  const res: any = await put_inline_param(
    environment.api.v1.recruitment.candidate.makeFavAndUnfav,
    dispatch,
    param
  );

  //   addLoading(dispatch, false);
  dispatch({
    type: UPDATE_CANDIDATE_DETAILS_FAV,
    payload: { candidateId: param.candidateId, isFavourite: param.isFavourite },
  });
};

export const addRemoveTags = (param) => async (dispatch) => {
  //   addLoading(dispatch, true);

  const res: any = await put_inline_param(
    environment.api.v1.recruitment.candidate.tag,
    dispatch,
    param
  );

  dispatch({
    type: UPDATE_TAGS,
    payload: { candidateId: param.candidateId, Tag: param.tag },
  });
};

export const updateMultipleCandidateDetails =
  (param, history) => async (dispatch) => {
    const res: any = await put_inline_param(
      environment.api.v1.recruitment.candidate.multipleStatus,
      dispatch,
      param
    );

    dispatch({
      type: UPDATE_CANDIDATE_DETAILS,
      candidates: res.Data.CandidatesDetailsList,
      RecruitmentDashboard: res.Data.RecruitmentDashboard,
    });

    // history.push({
    //   pathname: RecruitDashboardInformationPath,
    //   state: { propsData: res.Data.RecruitmentDashboard },
    // });
  };

export const moveCandidateToNextStage =
  (param, history) => async (dispatch) => {
    // addLoading(dispatch, true);

    const res: any = await put_inline_param(
      environment.api.v1.recruitment.candidate.moveToNextStage,
      dispatch,
      param
    );
    //   addLoading(dispatch, false);

    dispatch({
      type: UPDATE_CANDIDATE_DETAILS,
      candidates: res.Data.CandidatesDetailsList,
      RecruitmentDashboard: res.Data.RecruitmentDashboard,
    });

    // history.push({
    //   pathname: RecruitDashboardInformationPath,
    //   state: { propsData: res.Data.RecruitmentDashboard },
    // });
  };

export const SetAsDelete =
  (param, history) => async (dispatch) => {
    // addLoading(dispatch, true);

    const res: any = await put_inline_param(
      environment.api.v1.recruitment.candidate.setDeleteStatus,
      dispatch,
      param
    );
    //   addLoading(dispatch, false);

    dispatch({
      type: UPDATE_CANDIDATE_DETAILS,
      candidates: res.Data.CandidatesDetailsList,
      RecruitmentDashboard: res.Data.RecruitmentDashboard,
    });

    // history.push({
    //   pathname: RecruitDashboardInformationPath,
    //   state: { propsData: res.Data.RecruitmentDashboard },
    // });
  };

export const SetCurrentCandidateDetail = (detail) => (dispatch) => {
  console.log("setCurrentCandidateDetail", detail);
  dispatch({ type: CURRENT_CANDIDATE_DETAIL, payload: detail });
};
export const GetRecruitmentDashboardDetail = (jobId) => async (dispatch) => {
  var res: any = await get(
    `${environment.api.v1.recruitment.candidate.dashboardDetail}/${jobId}`,
    dispatch
  );
  dispatch({ type: CURRENT_CANDIDATE_DETAIL, payload: res.Data });
};

export const GetSelectedCandidateWithDetail = (param) => async (dispatch) => {
  // dispatch({
  //   type: CANDIDATE_DETAILS_LOADING,
  // });
  const res: any = await get(
    environment.api.v1.recruitment.candidate.selectedCandidateWithDetailsByJobId,
    dispatch,
    param
  );
  // dispatch({
  //   type: CURRENT_CANDIDATE_DETAIL,
  //   payload: res.Data.SelectedCandidate,
  // });
  return res.Data;
};

// const addLoading = (dispatch, loading) => {
//   dispatch({
//     type: ADD_LOADING,
//     payload: loading,
//   });
// };

export const UndoCandidateReject =
  ({ candidateId }) =>
    async (dispatch) => {
      const res: any = await put_inline_param(
        `${environment.api.v1.recruitment.candidate.undoDelete}/${candidateId}`,
        dispatch,
        candidateId
      );

      dispatch({
        type: UPDATE_CANDIDATE_REJECTION,
        // updateCandiateRejection: res.Data,
        payload: res.Data,
      });
    };


export const GetDashboardInfoJobs = (param) => async (dispatch) => {
  const res: any = await post(
    `${environment.api.v1.recruitment.dashboard.jobs}`,
    dispatch,
    param,
    false

  );

  return res.Data;

}

export const GetDashboardInfoJobsDetails = (jobId) => async (dispatch) => {
  const res: any = await get(
    `${environment.api.v1.recruitment.dashboard.jobDetails}/${jobId}`,
    dispatch);

  return res.Data;

}