import React, { useState } from 'react'
import Button from 'components/button';
import FinalScoreTable from './finalScoreTable';
import FinalScoreModal from './finalScoreModal';

function FinalScore() {
  const [open,setOpen] = useState(false)
  const onModalClose = () => setOpen(false);

  return (
    <div className='appraisals-container'>
      <div className="appraisals-title">
        <span>Final Score</span>
      </div>
      <div className="">
        <Button 
          title='Edit Logic'
          bg='primary'
          onClick={()=>setOpen(true)}
        />  
      </div>
      <FinalScoreModal open={open} onModalClose={onModalClose} />
      <FinalScoreTable />
    </div>
  )
}

export default FinalScore