import React, {useEffect, useState} from "react";
import Form from "components/form";
import Button from "components/button";
import ExportExcel from "components/excel/exportExcel";
import {useDispatch, useSelector} from "react-redux";
import {getRosterTeamList, getAllShift} from "../helpers/action";
import {getDateISO} from "components/calendar/helpers/engCalendar";

function Filter(props) {
  const {state, onChange, onSearch} = props;
  const [shifts, setShifts] = useState([]);
  const {teamList} = useSelector((state: any) => state.roosterReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRosterTeamList());
    fetchShifts();
  }, []);

  const fetchShifts = async () => {
    var res: any = await dispatch(getAllShift());
    setShifts(
      res.map((x) => ({
        ...x,
        label: x.ShortName ? `${x.Name} (${x.ShortName})` : x.ShortName,
        value: x.Id,
      }))
    );
  };
  const showByOptions = [
    {label: "All", value: "all"},
    {label: "Match", value: "match"},
    {label: "Error", value: "error"},
  ];

  return (
    <div>
      <div className="flex workHours-filters">
        <Form
          name="daterange"
          width="200px"
          formName="customdateinput"
          isDateRange
          isNepali={false}
          label="Date Range"
          onChange={onChange}
          value={state?.daterange}
          enableOkButton
        />
        <Form
          formName="reactselect"
          onChange={onChange}
          name="teamId"
          placeholder="Filter Team"
          value={state?.teamId}
          width="200px"
          options={teamList}
        />
        <Form
          formName="reactselect"
          onChange={onChange}
          name="shiftId"
          placeholder="Shift"
          value={state?.shiftId}
          width="200px"
          options={shifts}
        />
        <Form
          formName="reactselect"
          onChange={onChange}
          name="showBy"
          placeholder="Show By"
          value={state?.showBy}
          width="200px"
          options={showByOptions}
        />
        <Form
          onChange={onChange}
          name="searchEmployee"
          placeholder="Search Employee"
          value={state?.searchEmployee}
          width="200px"
        />
        <Button title="Search" bg="primary" onClick={() => onSearch()} />
      </div>
    </div>
  );
}

export default Filter;
