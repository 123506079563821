import React from "react";
import { MONTH, YEAR } from "./helpers";
import classnames from "classnames";
import { convertNumToNepali } from "./helpers";
import isEmpty from "isEmpty";
import * as engCalendarFuncs from "./engHelpers";
import * as nepCalendarFuncs from "./helpers";
import { useSelector } from "react-redux";

interface CoreProps {
  tab: String;
  header: Function;
  monthYearTabs: Function;
  days: Function;
  cells: Function;
  actions: Function;
  noFooter?: boolean;
  footer: Function;
}

const getCalendarNum = (num, isInNepali) => {
  // const { isInNepali } = props;
  if (isInNepali) {
    return convertNumToNepali(num);
  } else {
    return num;
  }
};

export const useHolidays = (isNepali = false) => {
  const [holidays, setHolidays] = React.useState({});
  const companyHolidays = useSelector(
    (state: any) => state.commonReducer.companyHolidaysWhole
  );
  React.useEffect(() => {
    if (!isEmpty(companyHolidays)) {
      setHolidays((holiday) => {
        let a = holiday;
        companyHolidays.map((item) => {
          // Removing 0s from the month and day
          if (!isNepali) {
            const engDate = engCalendarFuncs.getDateISO(new Date(item.EngDate));
            const [year, month, day] = engDate.split("/");
                let newMonth = month.replace(/^0/, '');;
                let newDay = day.replace(/^0/, '');
            let newDate = `${year}/${newMonth}/${newDay}`;
            a[newDate] = item.Description;
          } else {
            const nepDate = item.Date;
            // const nepDate = nepCalendarFuncs.getDateSlash(new Date(item.Date));
            const [year, month, day] = nepDate.split("/");
            let newMonth = month.replace(/^0/, '');
            let newDay = day.replace(/^0/, '');
            let newDate = `${year}/${newMonth}/${newDay}`;
            a[newDate] = item.Description;
          }
          // a = isEmpty(a) ? {[newDate]:item.Description} : {[newDate]:item.Description,...a}
        });
        return a;
      });
    }
  }, [companyHolidays]);
  return holidays;
};

export const CommonCore = (props: CoreProps) => {
  const { tab, header, monthYearTabs, days, cells, actions, noFooter, footer } =
    props;
  let style = actions ? {} : { maxWidth: 267 };
  return (
    <div className="calendar" style={style}>
      {header()}
      {/* <div>{days()}</div> */}
      {
        <div className="calendar-body">
          {tab ? (
            monthYearTabs()
          ) : (
            <div className="calendar-body__dates">
              <div className="calendar-body__week">{days()}</div>
              <div className="calendar-body__days">{cells()}</div>
            </div>
          )}
          {actions && <div className="calendar-body__actions">{actions()}</div>}
        </div>
      }

      {noFooter && noFooter ? null : footer()}
    </div>
  );
};

interface CellProps {
  onCellClick: Function;
  date: any;
  holiday: any;
  cell: any;
  inMonth: boolean;
  isToday: boolean;
  isCurrent: boolean;
  isHoliday: boolean;
  isDateRange?: boolean;

  isStartNeutral?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  isInBetweenHoverDate?: boolean;
  isInBetweenDate?: boolean;
  isNepali?: boolean;
  isDisabled?: boolean;

  setHoveredDate?: Function;
  hoveredDate?: string;
  dateRange?: any;
  isInNepali?: boolean;
}

export const CommonCell = (props: CellProps) => {
  const {
    onCellClick,
    dateRange,
    date,
    isStart,
    holiday,
    cell,
    isNepali,
    hoveredDate,
    isInNepali,
    setHoveredDate,
    isDisabled,
    inMonth,
    isToday,
    isCurrent,
    isHoliday,
    isStartNeutral,
    isInBetweenHoverDate,
    isInBetweenDate,
    isEnd,
  } = props;

  const dateClass = classnames("calendar-body__cell calendar-body__date", {
    disabled: !inMonth,
    selected: isToday || isCurrent || isHoliday,
    "cell-start": isStart && !isStartNeutral,
    "cell-start-active":
      isStart && !isStartNeutral && !isEmpty(dateRange.endDate),
    "cell-end": isEnd && !isStartNeutral,
    "cell-center": isStartNeutral,
    "is-holiday": isHoliday,
    "selected-date": isCurrent,
    "cell-between": isInBetweenHoverDate,
    "cell-between-active": isInBetweenDate,
    "cell-disabled": isDisabled,
    "cell-in-nepali": isInNepali,
  });
  const dayClass = classnames({
    today: isToday,
    "day-selected": isCurrent,
    day: true,
  });
  const onMouseOver = (e) => {
    e.currentTarget.classList.add("cell-over");
    if (dateRange.startDate && !dateRange.endDate) {
      const dateTitle = e.currentTarget.title;
      if (isNepali) {
        if (nepCalendarFuncs.nepDateDiff(dateRange.startDate, dateTitle) <= 0) {
          e.currentTarget.classList.add("cell-over-end");
        } else {
          e.currentTarget.classList.add("cell-over-start");
        }
      } else {
        if (engCalendarFuncs.dateDiff(dateRange.startDate, dateTitle) <= 0) {
          e.currentTarget.classList.add("cell-over-end");
        } else {
          e.currentTarget.classList.add("cell-over-start");
        }
      }

      if (hoveredDate !== dateTitle) {
        setHoveredDate(dateTitle);
      }
    }
  };
  const onMouseOut = (e) => {
    e.currentTarget.classList.remove("cell-over");
    e.currentTarget.classList.remove("cell-over-end");
    e.currentTarget.classList.remove("cell-over-start");
    if (hoveredDate) {
      setHoveredDate("");
    }
  };
  return (
    <div
      onClick={() => onCellClick(date)}
      title={date}
      onMouseOver={(e) => onMouseOver(e)}
      onMouseOut={(e) => onMouseOut(e)}
      key={`${date}`}
      className={dateClass}
    >
      <span className={dayClass}>{cell}</span>
      {holiday && <span className="holiday-tool">{holiday}</span>}
    </div>
  );
};

interface MonthTabsProps {
  onMonthClick: Function;
  months: any[];
  currentMonth: String;
  isInNepali?: boolean;
}

export const CommonMonthTab = (props: MonthTabsProps) => {
  const { onMonthClick, months, currentMonth, isInNepali } = props;
  return (
    <div className="calendar-tabs__body">
      <div className="calendar-tabs__body-month">
        {months.map((monthItem, key) => {
          return (
            <div
              key={monthItem}
              className={`month-name 
                                    ${isInNepali ? "cell-in-nepali" : null}
                                    ${
                                      currentMonth === monthItem
                                        ? "active-name"
                                        : ""
                                    }`}
              onClick={() => onMonthClick(key + 1)}
            >
              {monthItem}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface YearProps {
  leftArrow: any;
  rightArrow: any;
  year: any;
  yearSetting: any;
  SplittedYears: any;
  OnYearClick: Function;
  setYearSetting: Function;
  isNepali?: Boolean;
  isInNepali?: boolean;
}

export const CommonYearTab = (props: YearProps) => {
  const {
    leftArrow,
    year,
    isInNepali,
    yearSetting,
    rightArrow,
    SplittedYears,
    setYearSetting,
    OnYearClick,
    isNepali,
  } = props;
  return (
    <div className="calendar-tabs__body">
      <div className="calendar-tabs__body-year">
        {leftArrow ? null : (
          <div
            onClick={() =>
              setYearSetting({
                indexPoint: yearSetting.indexPoint - 1,
                indexLeap: 6,
              })
            }
            className="calendar-tabs__body-arrow arrow-left"
          ></div>
        )}

        {SplittedYears.map((val, index) => {
          return (
            <div
              className={`year-name 
                                    ${isInNepali ? "cell-in-nepali" : null} 
                                    ${year === val.year ? "active-name" : ""}`}
              key={val.year}
              onClick={() => OnYearClick(val.year)}
            >
              {getCalendarNum(val.year, isInNepali)}
            </div>
          );
        })}
        {rightArrow ? null : (
          <div
            onClick={() =>
              setYearSetting({
                indexPoint: yearSetting.indexPoint + 1,
                indexLeap: 6,
              })
            }
            className="calendar-tabs__body-arrow arrow-right"
          ></div>
        )}
      </div>
    </div>
  );
};

CommonYearTab.defaultProps = {
  isNepali: false,
};

interface HeaderProps {
  tab: String;
  setTab: Function;
  month: String;
  secondaryMonth: String;
  year?: String | Number;
}

export const CommonHeader = (props: HeaderProps) => {
  const { tab, setTab, year, month, secondaryMonth } = props;
  return (
    <div className={`calendar-header ${tab ? "calendar-tab" : ""}`}>
      <div
        onClick={() => setTab(MONTH)}
        className={`calendar-header-item calendar-header__month ${
          tab === MONTH ? "calendar-tab-active" : ""
        }`}
      >
        {month}
      </div>
      <div
        onClick={() => setTab(YEAR)}
        className={`calendar-header-item calendar-header__year ${
          tab === YEAR ? "calendar-tab-active" : ""
        }`}
      >
        {year}
      </div>
      <div className="calendar-header-item calendar-header__sub">
        {secondaryMonth}
      </div>
    </div>
  );
};

interface FooterProps {
  onClick: Function;
  maxDate: Date | string;
  minDate: Date | string;
  yesterday: Date | string;
  today: Date | string;
  tomorrow: Date | string;
  enableOkButton?: boolean;
  onOkClick?: Function;
}

export const CommonFooter = (props: FooterProps) => {
  const {
    yesterday,
    today,
    tomorrow,
    onClick,
    maxDate,
    minDate,
    enableOkButton,
    onOkClick,
  } = props;
  return (
    <div className="calendar-footer">
      {yesterday >= minDate && (
        <div
          onClick={() => onClick(yesterday)}
          className="calendar-footer__item"
        >
          Yesterday
        </div>
      )}
      <div
        onClick={() => onClick(today)}
        className="calendar-footer__item calendar-footer__item-today"
      >
        Today
      </div>
      {maxDate >= tomorrow && (
        <div
          onClick={() => onClick(tomorrow)}
          className="calendar-footer__item"
        >
          Tomorrow
        </div>
      )}
      {enableOkButton && (
        <div
          onClick={() => onOkClick && onOkClick()}
          className="calendar-footer__item"
        >
          Ok
        </div>
      )}
    </div>
  );
};

interface ActionProps {
  current: Function;
  prevMonth: Function;
  nextMonth: Function;
  prevYear: Function;
  nextYear: Function;
}

export const CommonAaction = (props: ActionProps) => {
  const { current, prevMonth, nextMonth, prevYear, nextYear } = props;
  return (
    <div className="calendar-body__actions-container">
      <div
        onClick={() => current()}
        className="calendar-body__actions-item"
        title="Current Month"
      >
        <span>Current Month</span>
      </div>
      <div
        onClick={() => prevMonth()}
        className="calendar-body__actions-item"
        title="Previous Month"
      >
        <span>Previous Month</span>
      </div>
      <div
        onClick={() => nextMonth()}
        className="calendar-body__actions-item"
        title="Next Month"
      >
        <span>Next Month</span>
      </div>
      <div
        onClick={() => prevYear()}
        className="calendar-body__actions-item"
        title="Previous Year"
      >
        <span>Previous Year</span>
      </div>
      <div
        onClick={() => nextYear()}
        className="calendar-body__actions-item"
        title="Next Year"
      >
        <span>Next Year</span>
      </div>
    </div>
  );
};
