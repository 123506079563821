import Button from "components/button";
import React from "react";

function EstimatedHourCell(props) {
  const onRenewContract = (isRenew, selectedProjectForRenew) => {
    // handleFormDisplay
    props.handleRenewContract(isRenew, selectedProjectForRenew);
  };
  const data: any = props.row?.original;
  if (!data) return null;
  let estimatedHoursToDisplay = null;
  let estimatedHrs = 0;
  let estimatedMins = 0;

  if (data.EstimatedHours) {
    estimatedHrs = parseInt(`${data.EstimatedHours / 60}`);
    estimatedMins = parseInt(`${data.EstimatedHours % 60}`);
  }
  if (!data.IsParent) {
    if (estimatedHrs > 0 && estimatedMins > 0) {
      estimatedHoursToDisplay = estimatedHrs + " hr " + estimatedMins + " min";
    } else if (estimatedHrs > 0) {
      estimatedHoursToDisplay = estimatedHrs + " hr";
    } else if (estimatedMins > 0) {
      estimatedHoursToDisplay = estimatedMins + " min";
    }

    return (
      <span className="detail">
        <span className="hoursAndMore">
          <span>{estimatedHoursToDisplay}</span>

          <span className="flex">
            {data.IsExpired && (
              <>
                <span className="color-red">Expired</span>

                <Button
                  onClick={() => onRenewContract(true, data)}
                  buttonClass="button-renew"
                >
                  <span className="color-white">Renew</span>
                </Button>
              </>
            )}
          </span>
        </span>
      </span>
    );
  }
  return null;
}

export default EstimatedHourCell;
