import React, {useState} from "react";
import Button from "components/button";
import {MdEdit, MdClose} from "react-icons/md";
import {AiOutlineStop} from "react-icons/ai";
import Modal from "components/modal";
import EditIncome from "../editIncome";

const SampleHeaderBody = ({activeData}) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return (
    <div className="emp-payroll__body">
      {activeData?.map?.((item, index) => {
        return (
          <div className="emp-payroll__row" key={index}>
            <div className="emp-payroll__col">
              <div className="flex">
                <div className="emp-payroll__circle"></div>
                <span className="text-primary">{item.Title}</span>
              </div>
            </div>
            <div className="emp-payroll__col">
              <span>{item.FormattedAmount || item.RateAmountText}</span>
            </div>
            <div className="emp-payroll__col">
              <span>{item.EffectiveDate}</span>
            </div>
            <div className="emp-payroll__col">
              <span>{item.Frequency}</span>
            </div>
            <div className="emp-payroll__col">
              <div className="emp-payroll__buttons">
                {/* <Button
                  buttonType="icon-button"
                  leftIcon={<MdEdit />}
                  title={"Edit"}
                  onClick={() => onOpen()}
                  bg="primary"
                />
                <Button
                  buttonType="icon-button"
                  leftIcon={<AiOutlineStop />}
                  title={"Disabled"}
                  bg="secondary-dark"
                />
                <Button
                  buttonType="icon-button"
                  leftIcon={<MdClose />}
                  title={"Remove"}
                  bg="white"
                /> */}
              </div>
            </div>
          </div>
        );
      })}
      <Modal
        open={open}
        onModalClose={onClose}
        title="Edit Income"
        className="payroll-edit__modal"
      >
        <EditIncome onClose={onClose} />
      </Modal>
    </div>
  );
};

export default SampleHeaderBody;
