import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import classnames from "classnames";
import FormGroup from "components/form";
import React from "react";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import {addLoanPurpose, editLoanPurpose} from "../helpers/action";

export class AddLoanPurpose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        Id: 0,
        Name: "",
      },
      errors: {},
      pristine: true,
    };
  }

  componentDidMount() {
    this.setState({formData: this.props.initialValues});
  }
  handleInputChange = (name, value) => {
    this.setFormState(name, value);
  };
  handleRadioChange = (event) => {
    let {name, value} = event.target;
    this.setFormState(name, value);
  };
  handleCheckboxChange = (event) => {
    let {name, checked} = event.target;
    this.setFormState(name, checked);
  };

  setFormState = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      pristine:
        JSON.stringify({
          ...prevState.formData,
          [name]: value,
        }) === JSON.stringify(this.props.initialValues)
          ? true
          : false,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    const {formData} = this.state;
    let errors = {};
    if (isEmpty(formData.Name)) {
      errors.Name = "Required";
    } else {
      if (
        this.props.sbiLoanPurpose &&
        this.props.sbiLoanPurpose.find(
          (x) => x.Id !== formData.Id && x.Name === formData.Name
        )
      ) {
        errors.Name = "Loan purpose name already taken. Please choose another name!";
      } else {
        const {isEdit} = this.props.sbiLoanSettingReducer;
        isEdit
          ? this.props.editLoanPurpose(formData, this.props.handleModalShow)
          : this.props.addLoanPurpose(formData, this.props.handleModalShow);
      }
    }
    this.setState({errors});
  };
  render() {
    const {submitting} = this.props;
    const {formData, errors, pristine} = this.state;
    return (
      <form onSubmit={this.handleFormSubmit}>
        <input type="hidden" name="Id"></input>
        <div className="form-body">
          <div className="form-row">
            <div className="filters-input__item">
              <FormGroup
                name="Name"
                type="text"
                size="med"
                error={errors.Name}
                label="Name"
                value={formData.Name}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="form-row footer-grp button-group justify-center flex-gap-10">
            <button
              type="button"
              className={classnames({
                "footer__cancel button button-white": true,
              })}
              onClick={() => this.props.handleModalShow(false, "normal")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={classnames({
                "footer__save button button-primary": true,
                disabled: pristine || submitting,
              })}
              disabled={pristine || submitting}
            >
              {submitting ? "Saving" : "Save"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  sbiLoanSettingReducer: state.sbiLoanSettingReducer,
  initialValues: state.sbiLoanSettingReducer.sbiLoanPurposeFormData, // pull initial values from account reducer pull initial values from account reducer
  sbiLoanPurpose: state.sbiLoanSettingReducer.sbiLoanPurpose,
});

export default connect(mapStateToProps, {
  addLoanPurpose,
  editLoanPurpose,
})(AddLoanPurpose);
