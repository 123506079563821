import React, {useState, useCallback, useEffect} from "react";
import Avatar from "react-avatar";
import employeeInfo from "scenes/employee/employeeProfile/personal/employeeInfo";
import {getUploadPath} from "constants/userDetails";
import man from "assets/images/man_small.png";

export default function OnBoardingEmpProfile({employeeInfo}) {
  let urlImage = employeeInfo.Photo ? getUploadPath(employeeInfo.Photo) : man;

  return (
    <div className="profile-row employee-onboarding-profile">
      <div className="profile-column">
        <div className="profile-header">Employee Onboarding Package</div>
        <div className="profile-info">
          <div className="profile-left">
            <div className="profile-info__body profile-image">
              <Avatar name={"Image"} size={170} round={false} src={urlImage} />
            </div>
            <div className="profile-info__container">
              <div className="profile-info__body">
                <div className="profile-info__title">
                  <h2>{employeeInfo && employeeInfo.Name}</h2>
                  <h4>
                    {employeeInfo &&
                      employeeInfo.Designation &&
                      employeeInfo.Designation.label}
                  </h4>
                  <span>
                    {employeeInfo && employeeInfo.Branch && employeeInfo.Branch.label}
                    {employeeInfo &&
                      employeeInfo.Department &&
                      ", " + employeeInfo.Department.label}
                  </span>
                </div>
                <div className="flex profile-secondary">
                  <div className="profile-secondary__left">
                    <div className="flex-column">
                      <span>
                        <strong>Appointment Date: </strong>
                        {employeeInfo && employeeInfo.AppointmentDate}
                      </span>
                      <span>
                        <strong>Hired Method</strong>
                        {employeeInfo && employeeInfo.HiringMethod}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
