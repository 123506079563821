import React, {Component} from "react";
import JobTabs from "./jobTabs";
import "./jobProfile.scss";
import RouteEnum from "constants/routeEnum";

class JobProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobProfileForm: false,
      activeTabId:
        props.location.state && props.location.state.activeTabId
          ? props.location.state.activeTabId
          : 1,
    };
  }
  handleJobProfileFormShow = (show) => {
    this.setState({
      showJobProfileForm: show,
    });
    this.props.history.push(RouteEnum.CreateJobProfilePath);
  };

  setActiveTabId = (id) => {
    this.setState({activeTabId: id});
  };

  render() {
    const {showJobProfileForm, activeTabId} = this.state;
    const {history} = this.props;
    return (
      <div className="job-pages">
        <JobTabs
          activeTabId={activeTabId}
          setActiveTabId={this.setActiveTabId}
          handleJobProfileFormShow={this.handleJobProfileFormShow}
        />
        {/* {showJobProfileForm ? (
          <JobProfileForm
            history={history}
            title="New Job Profile"
            handleFormDisplay={this.handleJobProfileFormShow}
            isEditPage={false}
            showForm={showJobProfileForm}
            formExists={true}
            isFormLoading={false}
          />
        ) : null} */}
      </div>
    );
  }
}

export default JobProfile;
