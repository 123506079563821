import {BS2AD} from "helpers/dateConvert";
import store from "src/store/configureStore";

export const getSettingWiseDate = (date) => {
  //console.log("state", store.getState());

  const {commonReducer} = store.getState();

  const isNepaliDate = !commonReducer.companyInformation.IsEnglishDate;
  let engDate = isNepaliDate && BS2AD(date);
  return isNepaliDate ? `${engDate?.year}/${engDate?.month}/${engDate?.day}` : date;
};
