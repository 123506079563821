import React, {useEffect, useState} from "react";
import Form from "components/form";
import Button from "components/button";
import isEmpty from "isEmpty";
import FroalaEditor from "react-froala-wysiwyg";
import {useDispatch, useSelector} from "react-redux";
import {addCourse, updateCourse} from "../helpers/action";
import RouteEnum from "constants/routeEnum";

import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import ImageRenderer from "../common/imageRenderer";
import {IoMdCloseCircle} from "react-icons/io";
import {GrAttachment} from "react-icons/gr";
import Modal from "components/modal";
import {UploadCoverPhoto} from "./uploadCoverPhoto";
import {Paper} from "@material-ui/core";
const initState = {
  Title: "",
  ServerFileName: null,
  Author: "",
  Description: "",
  CoverPicture: [],
  Duration: null,
  DurationInMin: null,
};

function MainPageForm(props) {
  const [state, setState] = useState(initState);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const {course} = useSelector((state: any) => state.lmsReducer);
  const {courseId} = props.match.params;

  const method = useForm();

  useEffect(() => {
    if (courseId) {
      setIsEdit(true);
    }
  }, [courseId]);
  useEffect(() => {
    if (course && courseId) {
      setState((prev) => {
        return {
          ...prev,
          Title: course.Title,
          Description: course.Description,
          Author: course.Author,
          ServerFileName: course.ServerFileName,
          Duration: course.Duration,
          DurationInMin: course.DurationInMin,
        };
      });
    }
  }, [course]);

  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const config = {
    placeholder: "Edit Me",
    events: {
      focus: function (e, editor) {},
    },
  };

  const submitCourse = async (newData) => {
    const data = {
      ...state,
      CoverPicture: !isEmpty(state.CoverPicture) ? state.CoverPicture[0] : null,
      IsFileEdit: !isEmpty(state.CoverPicture),
    };
    const fd = new FormData();

    Object.keys(data).map((item) => {
      data[item] && fd.append(item, data[item]);
    });

    if (isEdit) {
      fd.append("Id", course.Id);
      console.log({fd, data});
      await dispatch(updateCourse(fd));
      setState({...state});
      return;
    }
    await dispatch(addCourse(fd, props.history));
  };

  const renderCoverImage = () => {
    let imgSrc = null;
    if (!isEmpty(state.CoverPicture)) {
      imgSrc = (
        <ImageRenderer src={URL.createObjectURL(state.CoverPicture[0])} disableBaseUrl />
      );
    } else if (!isEmpty(state.ServerFileName)) {
      imgSrc = <ImageRenderer src={state.ServerFileName} />;
    }

    return !isEmpty(imgSrc) ? (
      <div className="courseForm__form-image">
        {imgSrc}
        <div
          onClick={() =>
            setState((prev) => ({...prev, CoverPicture: [], ServerFileName: null}))
          }
          className="courseForm__form-icon"
        >
          <IoMdCloseCircle />
        </div>
      </div>
    ) : (
      <div className="attachment">
        <div className="attachment__title flex">
          <h3>Attachment</h3>
          <GrAttachment className="ml-sm" />
        </div>
        <div className="attachment__upload mt-sm">
          <Paper onClick={() => setOpen(true)}>
            <p>
              <span className="text-blue dragAndDropUploadHover">
                Upload a cover photo
              </span>
            </p>
          </Paper>
        </div>
      </div>
    );
  };

  return (
    <>
      <FormGroup method={method} onSubmit={submitCourse}>
        <div className="courseForm__form-main">
          <div className="courseForm__form course-card formgroup">
            <FormItem
              name="Title"
              label="Add a course title"
              onChange={onChange}
              value={state.Title}
              setValue={method.setValue}
              rules={{
                required: "Course title is required.",
              }}
            />
          </div>
          <div className="courseForm__form course-card  formgroup">
            <div className="courseForm__form-label">Description</div>
            <div id="edit"></div>
            <FroalaEditor
              model={state.Description}
              onModelChange={(model) => onChange("Description", model)}
            />
          </div>
          <div className="courseForm__form course-card formgroup">
            <FormItem
              name="Author"
              label="Author"
              setValue={method.setValue}
              onChange={onChange}
              value={state.Author}
              rules={{
                required: "Author is required.",
              }}
            />
          </div>
          <div className="courseForm__form course-card formgroup">
            <div className="courseForm__form-duration">
              <div className="courseForm__form-duration-label">Duration</div>
              <div className="courseForm__form-duration-controls">
                <FormItem
                  type="number"
                  name="Duration"
                  width="150px"
                  onChange={onChange}
                  value={state.Duration}
                  setValue={method.setValue}
                />
                <span>hour</span>

                <FormItem
                  type="number"
                  name="DurationInMin"
                  width="150px"
                  onChange={onChange}
                  value={state.DurationInMin}
                  setValue={method.setValue}
                />
                <span>minutes</span>
              </div>
            </div>
          </div>
          <div className="courseForm__form course-card formgroup">
            <div className="courseForm__form-label">Cover</div>
            {renderCoverImage()}
          </div>
          <div className="flex  ">
            <Button
              bg="secondary"
              onClick={() => props.history.push(RouteEnum.lmsCourseManagement)}
              title={"Cancel"}
            />
            <Button
              bg="primary"
              htmlButtonType="submit"
              title={isEdit ? "Update" : "Save"}
              buttonType="icon-button"
            />
          </div>
        </div>
      </FormGroup>
      <Modal
        className="profile-change__modal"
        open={open}
        onModalClose={() => setOpen(false)}
      >
        <UploadCoverPhoto setState={setState} onModalClose={() => setOpen(false)} />
      </Modal>
    </>
  );
}

export default MainPageForm;
