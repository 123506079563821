import React, {Component} from "react";
import {connect} from "react-redux";
import {getTravelSettings, updateTravelSettings} from "./helpers/actions";
import FormGroup from "components/form";
import "./styles/travel.styles.scss";
import {AD2BS} from "components/calendar/helpers/dateConvert";
import isEmpty from "isEmpty";

const getSlashedDate = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const date = new Date().getDate();

  return `${year}/${month}/${date}`;
};

function padNumber(number) {
  number = number.toString();
  while (number.length < 4) {
    number = "0" + number;
  }
  return number;
}

export class Travel extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      travelSettings: {},
      SuffixInternational: "",
      SuffixDomestic: "",
      include: true,
      IncludeYearType: "",
      IncludeYearDigit: null,
      PreviewITRA: "",
      PreviewDTRA: "",
      FinalDayPercent: 100,
    };
    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    await this.props.getTravelSettings();
    const {travelSettings} = this.props;
    if (travelSettings) {
      const {
        IncludeYearDigit,
        IncludeYearType,
        SuffixInternational,
        SuffixDomestic,
        FinalDayPercent,
      } = travelSettings;
      this.setState(
        {
          travelSettings,
          include: !isEmpty(IncludeYearDigit) || !isEmpty(IncludeYearType),
          SuffixInternational,
          SuffixDomestic,
          IncludeYearType,
          IncludeYearDigit,
          FinalDayPercent,
        },
        () => this.getPreview()
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {travelSettings} = this.props;
    const {travelSettings: oldSettings} = prevProps;
    if (!isEmpty(travelSettings) && travelSettings !== oldSettings) {
      const {IncludeYearDigit, IncludeYearType, SuffixInternational, SuffixDomestic} =
        travelSettings;
      this.setState(
        {
          travelSettings,
          include: !isEmpty(IncludeYearDigit) || !isEmpty(IncludeYearType),
          SuffixInternational,
          SuffixDomestic,
          IncludeYearType,
          IncludeYearDigit,
        },
        () => this.getPreview()
      );
    }
  }
  getPreview() {
    // a.toString().slice(-2)
    const {
      travelSettings,
      travelSettings: {PrefilledDigits, DTRANextNumber, ITRANextNumber},
    } = this.props;
    const {
      IncludeYearDigit,
      IncludeYearType,
      SuffixInternational,
      SuffixDomestic,
      include,
    } = this.state;
    if (!include || isEmpty(IncludeYearDigit) || isEmpty(IncludeYearType)) {
      this.setState({
        PreviewITRA:
          SuffixInternational +
          "-" +
          padNumber(parseInt(PrefilledDigits) + parseInt(ITRANextNumber)),
        PreviewDTRA:
          SuffixDomestic +
          "-" +
          padNumber(parseInt(PrefilledDigits) + parseInt(DTRANextNumber)),
      });
      return false;
    }
    let toYear;
    const currentYear = new Date().getFullYear();
    const engDate = getSlashedDate();
    const nepDate = AD2BS(engDate).en;
    if (IncludeYearType === "BS") {
      toYear = IncludeYearDigit === 2 ? nepDate.year.toString().slice(-2) : nepDate.year;
    } else {
      toYear = IncludeYearDigit === 2 ? currentYear.toString().slice(-2) : currentYear;
    }
    this.setState({
      PreviewITRA:
        SuffixInternational +
        "-" +
        toYear +
        "-" +
        padNumber(parseInt(PrefilledDigits) + parseInt(ITRANextNumber)),
      PreviewDTRA:
        SuffixDomestic +
        "-" +
        toYear +
        "-" +
        padNumber(parseInt(PrefilledDigits) + parseInt(DTRANextNumber)),
    });
  }
  onChange(name, value) {
    if (name === "IncludeYearDigit") {
      this.setState({[name]: parseInt(value)}, () => this.getPreview());
      return false;
    }
    this.setState({[name]: value}, () => this.getPreview());
  }
  onCheckChange(e) {
    const {name, value} = e.target;
    if (name === "include") {
      this.setState({include: e.target.checked}, () => {
        if (!this.state.include) {
          this.setState(
            {
              IncludeYearDigit: null,
              IncludeYearType: null,
            },
            () => this.getPreview()
          );
          return false;
        }
        this.getPreview();
      });
      return false;
    }
  }
  onSave() {
    const {
      IncludeYearDigit,
      IncludeYearType,
      SuffixDomestic,
      SuffixInternational,
      FinalDayPercent,
    } = this.state;
    const initial = this.props.travelSettings;
    const data = {
      ...initial,
      SuffixInternational,
      SuffixDomestic,
      IncludeYearType,
      IncludeYearDigit,
      FinalDayPercent,
    };
    this.props.updateTravelSettings(data);
  }
  render() {
    const {travelSettings} = this.state;
    console.log(travelSettings);

    // console.log(this.state.include)
    return (
      <div className="settings">
        <div className="tabs-list">
          <div className="travel-settings">
            <div className="travel-settings-header">
              <h2>Travel Document Numbering Rule</h2>
            </div>
            <div className="travel-settings-container">
              <div className="travel-form-group">
                <h4>Prefix</h4>
                <FormGroup
                  label="International Travel"
                  name="SuffixInternational"
                  value={this.state.SuffixInternational}
                  type="text"
                  onChange={this.onChange}
                  error=""
                />
                <FormGroup
                  label="Domestic Travel"
                  name="SuffixDomestic"
                  type="text"
                  value={this.state.SuffixDomestic}
                  onChange={this.onChange}
                  error=""
                />
              </div>
              <div className="travel-check-group">
                <FormGroup
                  formName="checkgroup"
                  name="include"
                  label="Include Year"
                  value={this.state.include}
                  checked={this.state.include}
                  labelPosition="right"
                  onChange={this.onCheckChange}
                />
              </div>
              {this.state.include ? (
                <>
                  <div className="travel-radio-group">
                    <FormGroup
                      formName="radiogroup"
                      name="IncludeYearType"
                      label="AD"
                      value="AD"
                      checked={this.state.IncludeYearType === "AD"}
                      labelPosition="right"
                      onChange={this.onChange}
                    />
                    <FormGroup
                      formName="radiogroup"
                      name="IncludeYearType"
                      label="BS"
                      value="BS"
                      checked={this.state.IncludeYearType === "BS"}
                      labelPosition="right"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="travel-radio-group">
                    <FormGroup
                      formName="radiogroup"
                      name="IncludeYearDigit"
                      label="YYYY"
                      value={4}
                      checked={this.state.IncludeYearDigit === 4}
                      labelPosition="right"
                      onChange={this.onChange}
                    />
                    <FormGroup
                      formName="radiogroup"
                      name="IncludeYearDigit"
                      label="YY"
                      value={2}
                      checked={this.state.IncludeYearDigit === 2}
                      labelPosition="right"
                      onChange={this.onChange}
                    />
                  </div>
                </>
              ) : null}

              <div className="travel-form-group no-label">
                <h4>Preview</h4>
                <FormGroup
                  name="PreviewITRA"
                  value={this.state.PreviewITRA}
                  type="text"
                  disabled={true}
                />
                <FormGroup
                  name="PreviewDTRA"
                  type="text"
                  value={this.state.PreviewDTRA}
                  disabled={true}
                />
              </div>
              <div className="travel-form-group no-label">
                <h4>Last Day Allowance Percentage</h4>
                <FormGroup
                  name="FinalDayPercent"
                  value={this.state.FinalDayPercent}
                  type="text"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="travel-settings-footer">
              <button onClick={() => this.onSave()} className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTravelSettings,
  updateTravelSettings,
};

const mapStateToProps = (state) => ({
  travelSettings: state.settingsReducer.travelSettings,
});

export default connect(mapStateToProps, mapDispatchToProps)(Travel);
