import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import dayjs from "dayjs";
import RouteEnum from "constants/routeEnum";
import {useHistory} from "react-router";
import Form from "components/form";
import {
  downloadSchedule,
  getPublishedHistory,
  getRosterScheduleEmployeeList,
  getRosterScheduleEmployeeListWithWorkingHour,
  getRosterScheduleList,
} from "../../helpers/action";
import Pagination from "components/common/pagination";
import {PDFDownloadLink} from "@react-pdf/renderer";
import SchedulePdfContainer from "../schedules/components/schedulePdfContainer";
import {getDatesInBetween} from "scenes/roster/helpers/roosterDates";
import ExportButton from "components/excel/exportButton";
import {Row} from "reactstrap";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const DateCell = ({value}) => {
  return <span>{dayjs(value).format("D MMM, YYYY hh:mm a")}</span>;
};

const NotificationSent = ({value}) => {
  return <span>{value === true ? "Notification sent" : "Notification not sent"}</span>;
};

const Action = ({row: {original}, value}) => {
  const {companyInformation} = useSelector((state: any) => state.commonReducer);
  const {scheduleList, scheduleEmployeeList} = useSelector(
    (state: any) => state.roosterReducer
  );

  const history = useHistory();
  const [schedules, setSchedules] = useState([]);
  const dispatch = useDispatch();
  // const [instance, updateInstance] = usePDF({ document: MyDoc });
  if (original?.isSubrow === true || original?.isLast === false) {
    return null;
  }
  let startDate = dayjs(original?.StartDate).format("YYYY-MM-DD");
  let endDate = dayjs(original?.EndDate).format("YYYY-MM-DD");
  let query = {endDate, startDate, teamId: value};

  const onPdf = async () => {
    history.push(
      `${RouteEnum.roosterSchedulePdfRoutes}/?teamId=${value}&startDate=${startDate}&endDate=${endDate}`
    );
  };
  const onView = async () => {
    const promiseArray = [
      dispatch(getRosterScheduleList(query)),
      dispatch(getRosterScheduleEmployeeList(query)),
      dispatch(getRosterScheduleEmployeeListWithWorkingHour(query)),
    ];
    await Promise.all(promiseArray);
    if (scheduleList && scheduleEmployeeList) {
      const {endDate, startDate, teamId} = query;
      if (startDate && endDate) {
        const dates = getDatesInBetween(
          startDate,
          endDate,
          scheduleList,
          scheduleEmployeeList
        );
        setSchedules(dates);
      }
    }
  };
  return (
    <div className="published-links">
      <div onClick={() => onPdf()} className="published-link">
        View
      </div>

      <div onClick={() => onView()} className="published-link">
        Download
      </div>
      {schedules && schedules.length > 0 && (
        <DownloadPdf
          setSchedules={setSchedules}
          companyInformation={companyInformation}
          schedules={schedules}
          dates={query}
        />
      )}
    </div>
  );
};

const DownloadAction = ({row: {original}, value}) => {
  const dispatch = useDispatch();

  let startDate = dayjs(original?.StartDate).format("YYYY-MM-DD");
  let endDate = dayjs(original?.EndDate).format("YYYY-MM-DD");
  let query = {endDate, startDate, teamId: value};

  const handleDownloadClick = async () => {
    await dispatch(downloadSchedule(query));
  };
  return original.isLast || original.TotalRows === 1 ? (
    <div className="published-links">
      <ExportButton onClick={handleDownloadClick} waitingText="Preparing..." />
    </div>
  ) : null;
};

const DownloadPdf = (props) => {
  const {
    dates: {endDate, startDate, teamId: value},
    setSchedules,
  } = props;
  return (
    <PDFDownloadLink
      className="doc-linked"
      document={<SchedulePdfContainer {...props} />}
      fileName={`schedule-${value}-${startDate}-${endDate}.pdf`}
    >
      {({blob, url, loading, error}) => {
        let b: any = document.querySelector(".doc-linked");
        if (!loading) {
          if (b) {
            var link = document.createElement("a");
            link.href = url;
            link.download = `schedule-${value}-${startDate}-${endDate}.pdf`;
            link.dispatchEvent(new MouseEvent("click"));
            setSchedules([]);
          }
        }
        return loading ? "" : "";
      }}
    </PDFDownloadLink>
  );
};

const initialState = {
  pageIndex: 0,
  pageSize: 10,
};

function PublishedShifts(props) {
  const {stDate, edDate, tId} = props;
  const [state, setState] = useState(initialState);

  const [data, setData] = useState([]);
  const {publishedHistory} = useSelector((state: any) => state.roosterReducer);
  //   console.log({publishedHistory})
  const dispatch = useDispatch();
  let formatedDate = (dt) => dayjs(dt).format("YYYY-MM-DD");
  let formatedDate2 = (dt) => dayjs(dt).format("YYYY/MM/DD");
  const [date, setDate] = useState({
    startDate: formatedDate2(stDate),
    endDate: formatedDate2(edDate),
  });
  // console.log({ startDate: formatedDate2(stDate), endDate: formatedDate2(edDate),date })
  useEffect(() => {
    if (publishedHistory && publishedHistory.length > 0) {
      let published = [];
      let updated = [];
      publishedHistory.map((item) => {
        if (item.EventText === "Published") {
          let st = dayjs(item.StartDate).format("D MMM");
          let ed = dayjs(item.EndDate).format("D MMM, YYYY");
          published.push({
            ...item,
            subRows: [],
            isSubrow: false,
            DateRange: `${st} - ${ed}`,
          });
        } else {
          updated.push(item);
        }
      });
      if (published.length > 0) {
        published.map((item) => {
          if (updated && updated.length > 0) {
            updated.map((up) => {
              if (
                formatedDate(up.StartDate) === formatedDate(item.StartDate) &&
                formatedDate(up.EndDate) === formatedDate(item.EndDate)
              ) {
                item.isSubrow = true;
                item.subRows.push({...up, isLast: false});
              }
            });
            if (item.subRows.length > 0) {
              item.subRows[item.subRows.length - 1].isLast = true;
            }
          }
        });
        setData(published);
      }
    }
  }, [publishedHistory]);
  // useEffect(() => {
  //     getPublishHistory()
  // }, [date.startDate,date.endDate])
  useEffect(() => {
    getPublishHistory();
  }, [state.pageIndex, state.pageSize]);
  const getPublishHistory = () => {
    if (date.startDate && date.endDate && tId) {
      const data = {
        StartDate: date.startDate,
        EndDate: date.endDate,
        TeamId: tId?.value,
        PageIndex: state.pageIndex,
        PageSize: state.pageSize,
      };
      dispatch(getPublishedHistory(data));
    }
  };
  const cols = [
    {
      Header: "Date Range",
      accessor: "DateRange",
      width: 180,
    },
    {
      Header: "Event",
      accessor: "EventText",
      width: 110,
    },
    {
      Header: "Event Date",
      accessor: "EventDate",
      Cell: DateCell,
      width: 200,
    },
    {
      Header: "Published By",
      accessor: "PublishedBy",
      width: 160,
    },
    {
      Header: "Notification",
      accessor: "IsNotificationSent",
      width: 180,
      Cell: NotificationSent,
    },
    {
      Header: "",
      accessor: "TeamId",
      width: 250,
      Cell: DownloadAction,
    },
  ];

  const handleRowChange = (num) => {
    setState((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0,
      };
    });
  };

  const handleIndexChange = (num) => {
    setState((filter: any) => {
      return {
        ...filter,
        pageIndex: num,
      };
    });
  };
  return (
    <div className="shifts published-table pd-md">
      <div className="shifts-title">Published Shifts History</div>
      <div className="mb-sm">
        <Form
          name="daterange"
          width="200px"
          formName="customdateinput"
          isDateRange
          isNepali={false}
          onChange={(name, value) => {
            setDate(value);
            getPublishHistory();
          }}
          value={date}
        />
      </div>
      <Table isFixedWidth data={data ?? []} columns={cols} isExpanded={true} />
      {data && data.length > 0 && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={state.pageIndex}
          rowsPerPage={state.pageSize}
          total={data[0]?.TotalRows}
        />
      )}
    </div>
  );
}

export default PublishedShifts;
