import {GET_OVERTIME_REQUEST} from "actions/types";
import types from "constants/types";

const initialState = {
  overtimeRequest: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeRequest: action.payload,
      };
    case types.EXPORT_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeExportList: action.payload,
      };
    default:
      return state;
  }
}
