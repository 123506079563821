import React, {useEffect, useState} from "react";
import {IoCopyOutline} from "react-icons/io5";
import {BiCalendarCheck, BiCopy} from "react-icons/bi";
import ScheduleCopyModal from "./scheduleCopyModal";
import Publish from "./publish";
import {useDispatch, useSelector} from "react-redux";
import {getPublishedHistory} from "scenes/roster/helpers/action";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";

const ScheduleTools = ({teamId, startDate, endDate, onTabIndexChange, isPublishable}) => {
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log(data);
  };

  const getPublishHistory = () => {
    if (startDate && endDate && teamId) {
      const data = {
        StartDate: startDate,
        EndDate: endDate,
        TeamId: teamId.value,
        PageIndex: 0,
        PageSize: 10,
      };
      dispatch(getPublishedHistory(data));
    }
  };

  return (
    <>
      {teamId && startDate && endDate && (
        <div className="scheduler-tabs__btns">
          <div onClick={() => setOpen(true)} className="scheduler-tabs__btn">
            <IoCopyOutline />
            <span>Copy</span>
          </div>
          <div
            onClick={() => onTabIndexChange && onTabIndexChange(7,true)}
            className="scheduler-tabs__btn"
          >
            <BiCalendarCheck />
            <span>Schedules</span>
          </div>
          <ScheduleCopyModal
            open={open}
            onModalClose={onModalClose}
            teamId={teamId}
            isSingle={false}
            startDate={startDate}
            endDate={endDate}
          />
          {/* <div 
            onClick={()=>onPdf()}
            className="scheduler-tabs__btn">
            <FiPrinter />
            <span>Export PDF</span>
          </div> */}
          <Publish
            teamId={teamId}
            startDate={startDate}
            endDate={endDate}
            errors={isPublishable || false}
            successSubmitting={() => getPublishHistory()}
          />
        </div>
      )}
    </>
  );
};

export default ScheduleTools;
