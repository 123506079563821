import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FaUserFriends, FaYoutube, FaLock} from "react-icons/fa";
import {BsClock} from "react-icons/bs";
import {Text} from "../../../addCourse/contents/showItem";
import Question from "../question";
import RouteEnum from "constants/routeEnum";
import {useHistory} from "react-router";
import {
  getCoursePages,
  postAnswers,
  getAnswers,
  getCourseContent,
  getCoursePage,
  getEmployeeCourse,
  getQuestionDetails,
  addCourseCompleted,
  getCourseCompleted,
} from "scenes/lms/helpers/action";
import ContentTilte from "./contentTitle";
import Contents from "./conents";
import ContentFooter from "./contentFooter";
import QuestionDetails from "./questionDetails";

const PageContent = ({courseId, pageId, assignedId}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({currentPage: null, nextPage: null});
  useEffect(() => {
    if (pageId) {
      dispatch(getCoursePage(pageId));
      dispatch(getCourseContent(pageId));
    }
  }, [pageId]);

  const {course, courseContent} = useSelector((state: any) => state.lmsReducer);

  useEffect(() => {
    if (pageId && course?.EmployeeCoursePageDetail) {
      const coursePages = course?.EmployeeCoursePageDetail || [];
      const currentPageIndex =
        coursePages && coursePages.findIndex((x) => x.CoursePageId === parseInt(pageId));

      const currentPage = coursePages[currentPageIndex];
      const nextPage = coursePages[currentPageIndex + 1];
      setState({currentPage, nextPage});
    }
  }, [pageId, course?.EmployeeCoursePageDetail]);
  console.clear();
  console.log(course);
  const contentTitleProps = {state, course};
  const contentFooterProps = {state, assignedId};
  const questionDetailsProps = {pageId, assignedId, course};
  return (
    <>
      <ContentTilte {...contentTitleProps} />
      <Contents courseContent={courseContent} />
      {assignedId && <QuestionDetails {...questionDetailsProps} />}
      <ContentFooter {...contentFooterProps} />
    </>
  );
};
export default PageContent;
