import isEmpty from "helpers/isEmpty";
import React from "react";

const checkInOutCell = (props) => {
  const {
    getShiftList,
    row: { original },
    onEdit,
  } = props;
  const { ShiftShortName, InTime, OutTime } = original;

  const renderMatchedTime = () => {
    let el = (
      <>
        {!isEmpty(InTime) ? (
          <div className={`stime-block stime-block-matched`}>{InTime}</div>
        ) : (
          <div className={`stime-block stime-block-error`}>???</div>
        )}
        <span className="shift-seperator">-</span>
        {!isEmpty(OutTime) ? (
          <div className={`stime-block stime-block-matched`}>{OutTime}</div>
        ) : (
          <div className={`stime-block stime-block-error`}>???</div>
        )}
      </>
    );
    return el;
  };

  return (
    <div className="stime-container">
      {/* <span className="stime-shift">{ShiftShortName}</span> */}
      {renderMatchedTime()}
    </div>
  );
};

export default checkInOutCell;
