import React, {useState, useEffect} from "react";
import Modal from "components/modal";
import Icon from "components/icons";
import FromGroup from "components/form";
import Button from "components/button";
import isEmpty from "isEmpty";
import {addBoard} from "../helpers/action";
import {useDispatch} from "react-redux";
import {FaTimes} from "react-icons/fa";
interface ErrorsInterface {}

function CustomFields(props) {
  const {open, onModalClose} = props;
  const [data, setData] = useState({
    BoardId: 0,
    BoardName: "",
    BoardDescription: "",
    BoardType: 1,
    FieldLabel1: "",
    FieldLabel2: "",
    FieldLabel3: "",
    IsArchived: false,
  });

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  const onSave = async () => {
    const {customErrors, isValid} = onValidate(data);
    setErrors(customErrors);
    if (!isValid) {
      return false;
    }
    if (data.BoardId === 0) data.BoardType = props.boardType;
    await dispatch(addBoard(data));
    onModalClose(false);
  };
  const onValidate = (data) => {
    let customErrors: ErrorsInterface = {};

    return {isValid: isEmpty(customErrors), customErrors};
  };

  const onChange = (name, value) => {
    let newData = {...data};
    newData[name] = value;
    setData(newData);
  };
  return (
    <Modal
      onModalClose={onModalClose}
      open={open}
      className="board-add-modal"
      width="600px"
      title="Add Custom Fields"
    >
      <div className="board-add">
        <div className="board-add__form">
          <div className="board-add__row">
            <div className="genericForm-group__label">
              <label className="genericForm-group__label">Custom Fields</label>
            </div>
            <div className="flex-row">
              {!isEmpty(data.FieldLabel1) && !data.IsArchived && (
                <div className="board-add__remove-section">
                  <span>
                    <FaTimes onClick={(e) => onChange("FieldLabel1", "")} />
                  </span>
                </div>
              )}
              <FromGroup
                value={data.FieldLabel1}
                onChange={onChange}
                name="FieldLabel1"
                placeholder="#field label 1"
              />
            </div>
            <div className="flex-row">
              {!isEmpty(data.FieldLabel2) && !data.IsArchived && (
                <div className="board-add__remove-section">
                  <span>
                    <FaTimes onClick={(e) => onChange("FieldLabel2", "")} />
                  </span>
                </div>
              )}
              <FromGroup
                value={data.FieldLabel2}
                onChange={onChange}
                name="FieldLabel2"
                placeholder="#field label 2"
              />
            </div>
            <div className="flex-row">
              {!isEmpty(data.FieldLabel3) && !data.IsArchived && (
                <div className="board-add__remove-section">
                  <span>
                    <FaTimes onClick={(e) => onChange("FieldLabel3", "")} />
                  </span>
                </div>
              )}
              <FromGroup
                value={data.FieldLabel3}
                onChange={onChange}
                name="FieldLabel3"
                placeholder="#field label 3"
              />
            </div>
          </div>
        </div>

        <div className="board-add__footer">
          <Button
            title="Save"
            bg="primary"
            onClick={() => onSave()}
            isDisabled={data.IsArchived}
          />
          <Button title="Cancel" bg="secondary-dark" onClick={(e) => onModalClose(e)} />
        </div>
      </div>
    </Modal>
  );
}

export default CustomFields;
