import React, {useEffect} from "react";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import Button from "components/button";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {dateSelectOptions, getDateRange} from "../../helpers/roosterDates";
import dayjs from "dayjs";
import DatePicker, {
  CustomCalendarProps,
  SettingsProps,
} from "components/customDatePicker";
import {FaRegCalendarAlt} from "react-icons/fa";
import {IoMdArrowForward} from "react-icons/io";
import ScheduleTools from "./components/scheduleTools";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const getThisWeek = (date) => {
  var curr = new Date();
  var firstDayOfWeek = dayjs(dayjs(curr).startOf("week").toDate()).format("YYYY-MM-DD");
  var lastDayOfWeek = dayjs(dayjs(curr).endOf("week").toDate()).format("YYYY-MM-DD");
  var dateRange = {
    start_date: firstDayOfWeek,
    end_date: lastDayOfWeek,
  };
  if (!date) {
    return {
      dateRange,
      isThisWeek: false,
    };
  }
  return {
    dateRange,
    isThisWeek:
      dayjs(firstDayOfWeek).isSame(dayjs(date.startDate)) &&
      dayjs(lastDayOfWeek).isSame(dayjs(date?.endDate)),
  };
};

function ScheduleFilter(props) {
  const {onChange, filter, onChangeDate, onTabIndexChange, isPublishable} = props;
  const method = useForm();
  const {control, setValue} = method;
  const {teamList, teamRoleList} = useSelector((state: any) => state.roosterReducer);
  const startDateShort = filter?.startDate && dayjs(filter?.startDate).format("DD MMM");
  const startDate = filter?.startDate && dayjs(filter?.startDate).format("YYYY-MM-DD");
  const endDateShort = filter?.endDate && dayjs(filter?.endDate).format("DD MMM");
  const endDate = filter?.endDate && dayjs(filter?.endDate).format("YYYY-MM-DD");
  useEffect(() => {
    onChange("dates", dateSelectOptions[0]);
  }, []);
  let {dateRange, isThisWeek} = getThisWeek(filter);
  return (
    <div className="schedule-filters">
      <div className="schedule-filters__col">
        <FormItem
          control={control}
          width="200px"
          name="teamId"
          value={filter.teamId}
          onChange={onChange}
          isClearable={false}
          formName="reactselect"
          placeholder="Select Team"
          options={teamList}
        />
        {/* <div className="schedule-action__btns">
                    <div className="schedule-action__btn">
                        <Button 
                            onClick={()=>onChange('dateIndex',filter?.dateIndex + 1)}
                            buttonType="icon-button" bg="secondary" icon={<ChevronLeft />} />
                    </div>
                    <div className="schedule-action__btn">
                        <Button 
                            onClick={()=>onChange('dateIndex',filter?.dateIndex - 1)}
                            buttonType="icon-button" bg="secondary" icon={<ChevronRight />} />
                    </div>
                </div> */}

        <DatePicker
          value={{start_date: startDate ?? "", end_date: endDate ?? ""}}
          name="date"
          onChange={(name, value) => {
            if (value) {
              onChangeDate(value);
            }
          }}
          placeholder="Choose a date"
          valueType="weekly"
          custom={(props: CustomCalendarProps, settings: SettingsProps): any => {
            const firstDate = settings?.value?.start_date ?? startDate;
            const lastDate = settings?.value?.end_date ?? endDate;
            return (
              <div className="schedule-action__btns">
                <div className="schedule-action__btn">
                  <Button
                    onClick={() =>
                      onChangeDate({
                        start_date: dayjs(firstDate).subtract(7, "day"),
                        end_date: dayjs(lastDate).subtract(7, "day"),
                      })
                    }
                    buttonType="icon-button"
                    bg="secondary"
                    icon={<ChevronLeft />}
                  />
                </div>
                <div className="schedule-action__calendar">
                  <div
                    onClick={() => settings.setOpen((prev) => !prev)}
                    className="schedule-calendar"
                  >
                    <div className="schedule-calendar-icon">
                      <FaRegCalendarAlt />
                    </div>
                    <div className="schedule-calendar-date">
                      {dayjs(firstDate).format("DD MMM")}
                    </div>
                    <div className="schedule-calendar-icon">
                      <IoMdArrowForward />
                    </div>
                    <div className="schedule-calendar-date">
                      {dayjs(lastDate).format("DD MMM")}
                    </div>
                  </div>
                </div>
                <div className="schedule-action__btn">
                  <Button
                    onClick={() =>
                      onChangeDate({
                        start_date: dayjs(firstDate).add(7, "day"),
                        end_date: dayjs(lastDate).add(7, "day"),
                      })
                    }
                    buttonType="icon-button"
                    bg="secondary"
                    icon={<ChevronRight />}
                  />
                </div>
              </div>
            );
          }}
        />

        <div className="schedule-action__btn">
          <Button
            onClick={() => onChangeDate(dateRange)}
            isDisabled={isThisWeek}
            title="This Week"
            bg="secondary"
          />
        </div>
        {/* <FormItem
                    control={control}
                    formName="reactselect"
                    name="dates"
                    width="150px"
                    value={filter.dates}
                    onChange={onChange}
                    placeholder='Select Dates'
                    options={dateSelectOptions}
                /> */}

        {/* {
                    endDateShort && startDateShort && <div className="schedule-action__dates">
                        {startDateShort + '  -  ' + endDateShort}
                    </div>
                } */}
      </div>
      <div className="schedule-filters__col">
        <ScheduleTools
          activeTab=""
          {...filter}
          onTabIndexChange={onTabIndexChange}
          isPublishable={isPublishable}
        />
        {/* <div className="schedule-filters__label">
          <span>Group By</span>
        </div>
        <FormItem
          control={control}
          width="200px"
          name="group"
          formName="reactselect"
          placeholder="Role, None"
          options={[]}
          disabled
        /> */}
      </div>
    </div>
  );
}

export default ScheduleFilter;
