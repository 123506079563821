import React, {useState, useRef} from "react";
import CustomPopup from "components/common/customPopup";
import DatePicker from "components/datePicker";
import {getDateISOMDY, getDateISO} from "components/calendar/helpers/engCalendar";
import {TmViewType} from "scenes/tasks/helpers/enum";
import {taskUpdateDetails} from "../../../../../../helpers/action";
import {connect, useDispatch} from "react-redux";
import {formatedShortMonthDayYear} from "helpers/dateFormat";
import {toastMessage} from "actions/validateAction";

const DueDateCell = (props) => {
  const {task, isValidUser, stage, rowIndex, board} = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };
  const convertDateISOMDY = (date) => {
    return date ? getDateISOMDY(new Date(date)) : "";
  };
  const onDateChange = async (date) => {
    let boardDueDate = board.DueDate && new Date(board.DueDate);
    const dueDate = new Date(date);
    if (task.StartDate) {
      const startDate = new Date(task.StartDate);

      if (dueDate < startDate) {
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Due date must be greater than start date",
          },
        };
        toastMessage(dispatch, response);
        return;
      }
    }
    if (boardDueDate && boardDueDate < dueDate) {
      const response = {
        data: {
          MessageType: "Danger",
          Message: `Due date must be smaller than board due date (${formatedShortMonthDayYear(
            board.DueDate
          )})`,
        },
      };
      toastMessage(dispatch, response);
      return;
    }
    const updatedTask = {
      ...task,
      DueDate: date && convertDateISOMDY(date),
    };

    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updatedTask,
      stage,
      rowIndex,
    });
  };

  return (
    <div className="cell-due-date" ref={ref} onClick={() => setOpen(!open)}>
      {task.DueDate && formatedShortMonthDayYear(task.DueDate)}

      {open && ref && ref.current && !board.IsArchived && (
        <CustomPopup
          parentRef={ref}
          open={open}
          handleOutsideClick={() => setOpen(false)}
        >
          <DatePicker
            date={convertDateISO(task.DueDate)}
            enableOkButton={true}
            hideAction={true}
            onOkButtonClicked={(date, fullDetails) => {
              onDateChange(date);
              setOpen(false);
            }}
          />
        </CustomPopup>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {taskUpdateDetails};

export default connect(mapStateToProps, mapDispatchToProps)(DueDateCell);
