import {v1} from 'constants/urls';

export default {
    // sbi loans
    getConsumerLoanPurposeList: `${v1}/sbiloan/purposes`,
    // getConsumerLoanPurposeList: `${v1}​/sbiloan​/settings​/`,
    getLoanSettingsByType: `${v1}/sbiloan/settings`,
    saveConsumerLoanRequest: `${v1}/sbiloan/consumerloan`,
    draftConsumerLoanRequest: `${v1}/sbiloan/consumerloan/draft`,
    getAppliedLoanList: `${v1}/sbiloan/requests-list/`,
    getEmployeeInfo: `${v1}/sbiloan/employee-info/`,
    calculatePaymentSchedule: `${v1}/sbiloan/payment-schedule`,
    generateIncomeDeductionList: `${v1}/sbiloan/incomededuction-list/`,
    deleteConsumerLoan: `${v1}/sbiloan/consumer/`,
    loanApproverList: `${v1}/sbiloan/settings/approvers`,
    getConsumerLoanDetails: `${v1}/sbiloan/consumerloan/`,
    getOutstandingLoans: `${v1}/sbiloan/outstanding-loans`,
    getSbiLoanTypes: `${v1}/sbiloan/types`,
    getLoanCommentsHistory: `${v1}/sbiloan/loan-status-histories-detail/list`,
    // saveOutstandingLoan: `${v1}/sbiloan/outstanding-loans/`,
    // deleteOutstandingLoan: `${v1}/sbiloan/outstanding-loans/`,
    saveSocialLoanRequest: `${v1}/sbiloan/social-loan`,
    draftSocialLoanRequest: `${v1}/sbiloan/social-loan/draft`,
    deleteSocialLoan: `${v1}/sbiloan/social/`,
    getSocialLoanDetails: `${v1}/sbiloan/social-loan/`,

    savePersonalLoanRequest: `${v1}/sbiloan/personal-loan`,
    draftPersonalLoanRequest: `${v1}/sbiloan/personal-loan/draft`,
    deletePersonalLoan: `${v1}/sbiloan/personal/`,
    getPersonalLoanDetails: `${v1}/sbiloan/personal-loan/`,

    // sbi loan setting
    sbiLoanSettingList: `${v1}/sbiloan/settings-list`,
    sbiLoanPurposeList: `${v1}/sbiloan/purposes`,
    addLoanPurpose: `${v1}/sbiloan/purposes`,
    editLoanPurpose: `${v1}/sbiloan/purposes`,
    sbiLoanTypeList: `${v1}/sbiloan/settings-details/`,
    sbiGeneralLoanSave: `${v1}/sbiloan/settings`,
    sbiSettingAmountLimit: `${v1}/sbiloan/settings-amountlimit`,
    sbiSettingApprovalFlow: `${v1}/sbiloan/settings-approvalflow`,
    employeeListForSbiLoan: `${v1}/common/employees`,
    sbiLoanSettingsApprovers: `${v1}/sbiloan/settings/approvers`,

    // sbi loan status history
    sbiLoanStatusHistory: `${v1}/sbiloan/loan-status-histories/list`,
    sbiLoanTypes: `${v1}/sbiloan/types`,
    forwardSbiLoan: `${v1}/sbiloan/forward`,
    rejectSbiLoan: `${v1}/sbiloan/reject`,
}