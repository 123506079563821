import React, {useRef, useState} from "react";
import Form from "components/form";
import useDebounce from "helpers/customHooks/useDebounce";

const EditableCell = (props) => {
  const {
    edit,
    onSaveData,
    value: initialValue,
    row: {index, original},
    column: {
      id,
      isEditable = false,
      editableType = "input",
      selectOptions = [],
      isMultiple = false,
      loadOptions,
      isFirstRowDisabled = false,
    },
    updateMyData, // This is a custom function that we supplied to our table instance
  } = props;
  const isSelect = editableType === "select";
  const isCustomDate = editableType === "customdateinput";
  const isSearch = editableType === "search";

  // console.log({props})
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(null);
  const onChange = (name, value) => {
    setValue(value);
    // if (isSelect) {
    const newOriginal = Object.assign({}, original, {
      [name]: isSelect ? value?.value : value,
    });
    onSaveData && onSaveData(newOriginal, index, name, value);
    // }
    // if (isMultiple) {
    //   if (value.length > 1) {
    //     value.map((item) => item.value).join(",");
    //   }
    // }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (name, value) => {
    // updateMyData(index, id, value)
    if (!isSelect) {
      const newOriginal = Object.assign({}, original, {
        [name]: isSelect ? value?.value : value,
      });
      onSaveData && onSaveData(newOriginal);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    const initValue = isSelect
      ? selectOptions && selectOptions.find((item) => item.value === initialValue)
      : initialValue;
    setValue(initValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (isSearch) {
      console.log(`selectOptions`, selectOptions);
      console.log(`initialValue`, initialValue);
      const initValue = isSearch
        ? selectOptions &&
          selectOptions.find((item) => item?.value?.includes(initialValue?.split(",")))
        : initialValue;
      console.log(`initValue`, initValue);
    }
  }, [initialValue]);

  if (!isEditable || !edit) {
    return value || "";
  }

  if (isEditable && edit) {
    return (
      <div>
        <Form
          name={id}
          formName={
            isSelect
              ? "reactselect"
              : isSearch
              ? "asyncautoselect"
              : isCustomDate
              ? "customdateinput"
              : "textinput"
          }
          value={value}
          multiple={isSearch}
          options={selectOptions}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isFirstRowDisabled && index === 0}
          loadOptions={loadOptions}
        />
      </div>
    );
  }
  return null;
};
export default EditableCell;
