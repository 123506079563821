import React, {useEffect} from "react";
import "./style.scss";
import {getLoanCommentsHistory} from "../../helpers/action";
import isEmpty from "isEmpty";
import {useDispatch, useSelector} from "react-redux";
import {currencyCommaSeperator} from "../../helpers/constants";
import {getUploadPath} from "constants/userDetails";
import Avatar from "react-avatar";

function CommentsView({location}) {
  const {state} = location;
  const {LoanId, EmployeeId, LoanType, CurrentStep, FlowType, ProcessFlowStepId} = state;
  const dispatch = useDispatch();

  useEffect(() => {
    let commentParams = {
      loanType: LoanType,
      loanId: LoanId,
    };
    dispatch(getLoanCommentsHistory(commentParams));
  }, []);
  const loanCommentHistory = useSelector(
    (state) => state.sbiLoanReducer.loanCommentHistory
  );
  if (isEmpty(loanCommentHistory)) return <div> No Comments </div>;
  return (
    <div>
      {loanCommentHistory &&
        loanCommentHistory.map((comment) => (
          <div className="consumer-loan__view">
            <div className="consumer-loan__view__header">
              <div className="consumer-loan__view__header1 bold-500">
                {comment.ActionDisplayTitle}
              </div>
              <div className="consumer-loan__view__header2 bold-500">
                {comment.Department}
              </div>
            </div>
            {/* <div className="consumer-loan__view__commenttop mt-sm text-md">
              {comment.ActionRemarks}
            </div> */}
            <div className="consumer-loan__view__photosection flex justify-between mt-md mb-md">
              <div className="consumer-loan__view__avatar flex">
                <Avatar
                  alt={"x.FullName"}
                  src={getUploadPath(comment.UrlPhoto)}
                  src={"google.com"}
                  className="emp-image"
                  size={"40"}
                  round={50}
                />
                <div className="consumer-loan__view__avatarbody ml-sm">
                  <span className="consumer-loan__view__avatarname">
                    {comment.EmployeeName}
                  </span>
                  <span className="consumer-loan__view__avatarposition">
                    {comment.Designation}
                  </span>
                </div>
              </div>
              <div className="consumer-loan__view__avatarposition bold-500">
                {comment.ActionOn}
              </div>
            </div>
            <div className="consumer-loan__view__commentbottom">
              <div className="consumer-loan__view__commentbottom-header text-blue mb-sm">
                Comments
              </div>
              <div className="consumer-loan__view__commentbottom-body text-md">
                {comment.ActionRemarks}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
// });

export default CommentsView;
