import {BS2AD} from "helpers/dateConvert";
import {EmployeeID} from "constants/userDetails";
import isEmpty from "isEmpty";
import {getEnglishDate} from "src/components/datePicker/helpers";
export const getSettlementSaveData = (
  props,
  localState,
  approverData,
  isNepaliDate = false
) => {
  const {billsAndClaimsList, otherExpenses} = props;
  const travelRequestDetails = props.travelRequestDetails.value;
  const pendingSettlement = props.pendingSettlement.value;
  const travelInformation = localState.travelInformationState;
  let billsAndClaimsDocObj = [];
  let otherExpensesDocObj = [];
  let summaryDocObj = localState.travelSummaryFileList.map((x, i) => {
    return {
      Id: i + 1,
      SettlementRef_Id: localState.settlementId,
      DocumentName: !isEmpty(x.docName) ? x.docName : "",
      DocumentPath: !isEmpty(x.path) ? x.path : "",
      OriginalName: !isEmpty(x.name) ? x.name : "",
    };
  });

  billsAndClaimsList.map((x) => {
    x.BillList.map((file, i) => {
      billsAndClaimsDocObj.push({
        Id: i + 1,
        ExpenseRefId: !isEmpty(x.ExpenseId) ? x.ExpenseId : 0,
        SettlementRefId: localState.settlementId,
        DocumentName: !isEmpty(file.docName) ? file.docName : "",
        DocumentPath: !isEmpty(file.path) ? file.path : "",
        OriginalName: !isEmpty(file.name) ? file.name : "",
        BillAmount: x.BillAmount,
      });
    });
  });

  otherExpenses.map((x) => {
    x.FileList.map((file, i) => {
      otherExpensesDocObj.push({
        Id: i + 1,
        OtherExpensesRefId: !isEmpty(file.expenseId) ? file.expenseId : 0,
        DocumentName: !isEmpty(file.docName) ? file.docName : "",
        DocumentPath: !isEmpty(file.path) ? file.path : "",
        OriginalName: !isEmpty(file.name) ? file.name : "",
      });
    });
  });

  let returnedDateEng = null;
  if (localState.selectedJoinedDate && isNepaliDate) {
    let conversionDate = BS2AD(localState.selectedJoinedDate);
    returnedDateEng = `${conversionDate.year}/${conversionDate.month}/${conversionDate.day}`;
  } else {
    returnedDateEng = localState.selectedJoinedDate;
  }

  return {
    TravelSettlementDto: {
      SettlementId: localState.settlementId,
      RequestRefId: localState.requestId,
      ReturnDate: localState.selectedJoinedDate,
      ReturnDateEng: returnedDateEng,
      IsTravelAsPlan: localState.isAsPlanned,
      TravelSummary: localState.travelSummary,
      SettelementOn: new Date(),
      SettlementOnEng: new Date().toJSON(),
      TravelType: isEmpty(travelInformation) ? 0 : travelInformation.travelTypeId,
      DestinationType: isEmpty(travelInformation)
        ? 0
        : travelInformation.destinationTypeId,
      Reason: isEmpty(travelInformation) ? "" : travelInformation.reason,
      RequestedBy: EmployeeID(),
      RequestedOn: pendingSettlement[0].RequestedOn,
      RequestedOnEng: pendingSettlement[0].RequestedOnEng,
      StartDate: pendingSettlement[0].TravelStart,
      EndDate: pendingSettlement[0].TravelEnd,

      StartDateEng: pendingSettlement[0].TravelStartEng,
      EndDateEng: pendingSettlement[0].TravelEndEng,
      AdvanceTakenAmount: travelRequestDetails.AdvanceTakenAmount,
      LeaveApprovalDelegateId: !isEmpty(
        travelRequestDetails.TravelDelegatesApprovalResult[2]
      )
        ? travelRequestDetails.TravelDelegatesApprovalResult[2].EmployeeId
        : 0,
      TimeApprovalDelegateId: !isEmpty(
        travelRequestDetails.TravelDelegatesApprovalResult[0]
      )
        ? travelRequestDetails.TravelDelegatesApprovalResult[0].EmployeeId
        : 0,
      TravelApprovalDelegateId: !isEmpty(
        travelRequestDetails.TravelDelegatesApprovalResult[1]
      )
        ? travelRequestDetails.TravelDelegatesApprovalResult[1].EmployeeId
        : 0,
      //CurrencyCode: travelInformation.selectedCurrency,
      CurrencyCode: 0,
      ApprovalEmployeeId: approverData.ApprovalEmployeeId,
      StatusId: approverData.Status,
      StatusName: pendingSettlement[0].StatusName,
      //ApprovedBy: pendingSettlement[0].ApprovedBy,
      ApprovedBy: 0,
      ApprovedOn: pendingSettlement[0].ApprovedOn,
      ApprovedOnEng: pendingSettlement[0].ApprovedOnEng,
      AssessedEligibilityAmt: !isEmpty(pendingSettlement[0].AssessedEligibilityAmt)
        ? pendingSettlement[0].AssessedEligibilityAmt
        : 0,
      TotalClaimAmt: localState.totalClaimAmount,
      // CountryId: travelInformation.selectedCountry,
      CountryId: 0,
      IsInformationChanged: true,
      Note: localState.Note,
    },
    TravelSettlementJobDocumentsDto: summaryDocObj,
    TravelSettlementAllowanceExpenseDetailsDto: billsAndClaimsList.map((x) => {
      return {
        // Id: x.Id,
        Id: 0,
        SettlementRefId: localState.settlementId,
        CategoryRefId: localState.categoryId,
        AllowanceName: x.Name,
        AllowanceRef_Id: x.AllowanceRef_Id,
        Units: x.Units,
        Rate: x.Rate,
        Amount: x.Amount,
        IsBillRequired: x.IsBillRequired,
        CalculationMethod: x.CalculationMethod,
        HasBill: !isEmpty(x.BillList) ? true : false,
      };
    }),
    TravelSettlementDestinationsDto: isEmpty(travelInformation)
      ? []
      : travelInformation.selectedTravelDestList.map((x) => {
          let dest = x.travelDestination;
          let modeOfTransportation = "";
          dest.selectedModeOfTransport.map((t) => {
            modeOfTransportation += "," + t.value;
          });

          return {
            Id: x.key,
            SettlementRefId: localState.settlementId,
            DistrictRefId: dest.selectedDistrict.value,
            PlaceName: dest.placeName,
            TransportModeRefId: modeOfTransportation,
            StartDate: dest.startDate,
            EndDate: dest.endDate,
            StartDateEng: isNepaliDate
              ? getEnglishDate(dest.startDateEng)
              : dest.startDateEng,
            EndDateEng: isNepaliDate ? getEnglishDate(dest.endDateEng) : dest.endDateEng,
            TravelDays: dest.days,
            TravelNights: dest.nights,
            HostName: dest.hostName,
            HotelName: dest.hotelName,
            PhoneNo: dest.phoneNumber,
          };
        }),
    TravelSettlementBillsDocumentsDto: billsAndClaimsDocObj,
    TravelSettlementDelegatesDto: travelRequestDetails.TravelDelegatesApprovalResult.map(
      (x) => {
        return {
          Id: 0,
          SettlementRefId: localState.settlementId,
          DelegationType: 0,
          LeaveTimeTravelId: x.DelegateType,
          EmployeeRefId: x.EmployeeId,
          ChangeStatus: 0,
        };
      }
    ),
    TravelSettlementOtherExpensesDto: otherExpenses.map((x) => {
      return {
        Id: x.Id,
        SettlementRefId: localState.settlementId,
        Name: x.ExpenseName,
        Amount: x.Amount,
        Note: x.Note,
        HasBill: !isEmpty(x.FileList) ? true : false,
      };
    }),
    TravelSettlementOtherExpensesDocDto: otherExpensesDocObj,
  };
};
