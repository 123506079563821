export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const GET_EDUCATION_LEVELS = "GET_EDUCATION_LEVELS";
export const GET_JOB_STATUS = "GET_JOB_STATUS";
export const GET_PROVINCE_LIST = "GET_PROVINCE_LIST";
export const GET_DISTRICT_BY_PROVINCE = "GET_DISTRICT_BY_PROVINCE";
export const GET_LOCALBODY_BY_DISTRICT = "GET_LOCALBODY_BY_DISTRICT";
export const GET_COMPANY_INFORMATION = "GET_COMPANY_INFORMATION";
export const GET_NATIONALITY = "GET_NATIONALITY";
export const UPLOAD_FILES = "UPLOAD_FILES";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_SALARY_PAY_DURATION = "GET_SALARY_PAY_DURATION";
export const GET_EXPERIENCE_DURATION = "GET_EXPERIENCE_DURATION";
export const GET_SERVER_DATE_TIME = "GET_SERVER_DATE_TIME";