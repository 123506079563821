import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import RolesTable from "./rolesTable";
import Button from "components/button";
import AddRole from "./addRole";
import {getRosterTeamRoleList} from "scenes/roster/helpers/action";
import Icons from "components/icons";

const intialState = {
  teamId: null,
};

function Roles() {
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const [state, setState] = useState(intialState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRosterTeamRoleList(null));
  }, []);
  useEffect(() => {
    if (state.teamId) {
      dispatch(getRosterTeamRoleList(state.teamId.value));
    }
  }, [state.teamId]);
  return (
    <div className="schedule rolesgrid">
      <div className="schedule-filters mb-sm">
        <Button
          onClick={() => setOpen(true)}
          title="Add Role"
          bg="primary"
          buttonType={"icon-button"}
          leftIcon={<Icons name="Add" />}
        />
      </div>
      <div className="schedule-filters__table">
        <RolesTable />
      </div>
      <AddRole onModalClose={onModalClose} open={open} isEdit={false} />
    </div>
  );
}

export default Roles;
