import dayjs from 'dayjs';
import React from 'react'
import { CALENDAR_MONTHS } from '../../constants';
import classNames from 'classnames';

function MonthTab(props:any) {
  const months = Object.keys(CALENDAR_MONTHS.short);
  const {currentDate,onMonthClick,format,setCurrentDate,setTab} = props;
  let month = dayjs(currentDate).format('MMM')??"";
  return (
    <div className="cal-tabs">
 <div className="cal-tabs__body">
    <div className="cal-tabs__body-months">
      {months.map((monthItem:any, key) => {
        const monthDate = dayjs(currentDate).set("month", key).format(format);
        return (
          <div
            key={monthItem}
            className={classNames(`month-name`, 
                                  { //@ts-ignore
                                    "active-name":month.toLowerCase() === CALENDAR_MONTHS.short[monthItem].toLowerCase()
                                      
                                  })}
            onClick={() => {
              setCurrentDate(monthDate)
              setTab("")
            }}
          >
            {
            //@ts-ignore
            CALENDAR_MONTHS.short[monthItem]}
          </div>
        );
      })}
    </div>
  </div>
    </div>
   
  )
}

export default MonthTab