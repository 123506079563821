import Loaders from "components/loaders";
import React, {useEffect, useState} from "react";
import {GiReceiveMoney} from "react-icons/gi";
import {useSelector} from "react-redux";
import HeaderCol from "../common/headerCol";
import SampleHeaderBody from "../common/sampleHeaderBody";
import SampleTotal from "../common/sampleTotal";

function Active({activeData}) {
  const [state, setState] = useState({
    cashIncomes: [],
    deemedIncomes: [],
    totalCashIncome: 0,
    totalDeemedInncome: 0,
  });

  const {employeeIncomesLoading} = useSelector((state: any) => state.employeeProfile);

  useEffect(() => {
    if (activeData) {
      const cashIncomes = activeData.filter((x) => x.Calculation !== "DeemedIncome");
      const deemedIncomes = activeData.filter((x) => x.Calculation === "DeemedIncome");
      const totalCashIncome = activeData
        .filter((x) => x.Calculation !== "DeemedIncome")
        .reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.Amount;
        }, 0);
      const totalDeemedInncome = activeData
        .filter((x) => x.Calculation === "DeemedIncome")
        .reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.Amount;
        }, 0);
      setState({cashIncomes, deemedIncomes, totalCashIncome, totalDeemedInncome});
    }
  }, [activeData]);

  return (
    <div className="emp-payroll__tables">
      {employeeIncomesLoading ? (
        <Loaders loading={true} type="skeleton" name="custom-table" />
      ) : (
        <>
          <div className="emp-payroll__table">
            <div className="emp-payroll__header">
              <HeaderCol
                icon={<GiReceiveMoney />}
                headings={["Cash Income", "Amount", "Effective from", "Frequency"]}
              />
            </div>

            <SampleHeaderBody activeData={state.cashIncomes} />
          </div>
          <SampleTotal total={state.totalCashIncome} />
          {state.deemedIncomes.length > 0 && (
            <>
              <div className="emp-payroll__table">
                <div className="emp-payroll__header">
                  <HeaderCol
                    icon={<GiReceiveMoney />}
                    headings={[
                      "Non-Cash Incomes (Deemed Income)",
                      "Amount",
                      "Effective from",
                      "Frequency",
                    ]}
                  />
                </div>
                <SampleHeaderBody activeData={state.deemedIncomes} />
              </div>
              <SampleTotal total={state.totalDeemedInncome} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Active;
