import React, { Component } from "react";
import Table from "./table";

export class Education extends Component {
  constructor(props) {
    super(props);
    this.tableStructure = [
      {
        field: "EducationQualification",
        headerText: "Education Qualification",
        width: 350,
      },
      { field: "TypeName", headerText: "Type", width: 160 },
      { field: "LevelName", headerText: "Education Level", width: 160 },
      { field: "Action", headerText: "", width: 180 },
    ];
  }

  render() {
    const { educationList } = this.props;
    let tableData = [];
    educationList &&
      educationList.map((education) => {
        tableData.push({
          Id: education.EducationId,
          EducationQualification: education.Name,
          TypeName: education.TypeName,
          LevelName: education.LevelName,
          Actions: "",
        });
      });
    return (
      <Table
        tableStructure={this.tableStructure}
        tableData={tableData}
        handleDelete={this.props.handleDelete}
        handleEdit={this.props.handleEdit}
      />
    );
  }
}

export default Education;
