import { useQuery } from "react-query";
import axios from 'helpers/axiosinstance';
import {loadingHeaders} from 'helpers/getHeaders'

const getNotificationStatus = async () => {
    const { data } = await axios.get("/v1/notices/isAnyUnreadNotification",loadingHeaders);
    return data;
  };
  
  export function useNotificationHeader() {
    return useQuery("notification-header", getNotificationStatus,{
      enabled: true, // to stop automatic refetch api make it false
      retry: 5,
    });
  }