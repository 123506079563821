import React, { useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Modal from "components/modal";
import Button from "components/button";
import { deleteAppraisalRatingScale } from '../helpers/action';


const ButtonCell = (props) => {
    const {original,value} = props.row;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true);
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
      console.log("Edit");
    };
    const onDelete = () => dispatch(deleteAppraisalRatingScale(props.value))
    return (
      <div className="appraisals-table__col">
        <div className="appraisals-table__links">
          <Button onClick={() => onModalOpen()} size="small" title="Edit" bg="secondary" />
          <Button 
        //   onClick={() => onDelete()} 
          size="small" title="Delete" bg="secondary" />
        </div>
        <Modal
          open={open}
          onModalClose={onModalClose}
          className="hide-modal-overflow"
          title={
            original?.EmployeeId + " - " + original?.EmployeeName ?? "Edit Employee Team"
          }
          width="450px"
        >
          <span>Emmp</span>
        </Modal>
      </div>
    );
};

const RequiredCell = (props) => {
    const {value} = props;
    return(
        <div>
            {value ? "Required" : "Not Required"}
        </div>
    )
}

function WorkflowTable(props) {
    const {threeSixtyWorkflows} = useSelector((state:any)=>state.appraisalReducer);
    const cols = [
        {
          Header: "Workflow Name",
          accessor: "WorkflowName",
          width: 280,
        },
        {
            Header: "Approvals",
            accessor: "IsRequireApproval",
            width: 160,
            Cell:RequiredCell
        },
        {
            Header: "Signatures",
            accessor: "IsRequireSignature",
            width: 220,
            Cell:RequiredCell
        },
        {
          Header: "",
          accessor: "Id",
          Cell: ButtonCell,
          width: 140,
        },
    ];
  return (
    <div className="appraisals-table">
        <Table isFixedWidth data={ threeSixtyWorkflows ?? []} columns={cols} />
    </div>
  )
}

export default WorkflowTable