import React from "react";

const HeaderCol = (props) => {
  const {headings, icon} = props;
  return (
    <div className="emp-payroll__row">
      {headings.map((item, key) => {
        return (
          <div className="emp-payroll__header-col">
            {key == 0 ? icon : null}
            <span>{item}</span>
          </div>
        );
      })}
      <div className="emp-payroll__header-col"></div>
    </div>
  );
};
export default HeaderCol;
