import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import Button from "components/button";
import {FcCheckmark} from 'react-icons/fc';


const ButtonCell = (props) => {
    const {original} = props.row;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true);
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
      console.log("Edit");
    };
    return (
      <div className="appraisals-table__col">
        <div className="appraisals-table__links">
          <Button onClick={() => onModalOpen()} size="small" title="Edit" bg="secondary" />
        </div>
        <Modal
          open={open}
          onModalClose={onModalClose}
          className="hide-modal-overflow"
          title={
            original?.EmployeeId + " - " + original?.EmployeeName ?? "Edit Employee Team"
          }
          width="450px"
        >
          <span>Emmp</span>
        </Modal>
      </div>
    );
};

const EmpCell = (props) => {
    const {original} = props.row;
    return(
        <div className="appraisals-table__col">
            <div className="appraisals-table__emp">
                <div className="appraisals-table__emp-image">
                    <img src={original.Photo} alt="" />
                </div>
                <div className="appraisals-table__emp-link">
                    {original.EmployeeName}
                </div>
            </div>
        </div>
    )
}

function GoalTable(props) {
    const {pageIndex, pageSize, handleRowChange, handleIndexChange} = props;
    const {goalDatabases} = useSelector((state: any) => state.appraisalReducer);
    const cols = [
        {
          Header: "Employee Name",
          accessor: "Id",
          Cell: EmpCell,
          width: 200,
        },
        {
          Header: "Position",
          accessor: "Position",
          width: 150,
        },
        {
          Header: "Level",
          accessor: "Level",
          width: 120
        },
        {
            Header: "Department",
            accessor: "Department",
            width: 120,
        },
        {
            Header: "Goal",
            accessor: "Goal",
            width: 120,
        },
        {
            Header: "Target",
            accessor: "Target",
            width: 130
        },
        {
          Header: "Actions",
          accessor: "EmployeeId",
          Cell: ButtonCell,
          width: 120,
        },
      ];
    return (
        <div className="appraisals-table">
            <Table isFixedWidth data={ goalDatabases ?? []} columns={cols} />
        </div>
    )
}

export default GoalTable