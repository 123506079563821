const commonPrefix = "/common/";

export const common = {
  nationalities: commonPrefix + "nationalities",
  countries: commonPrefix + "countries",
  districts: commonPrefix + "districts",
  provinces: commonPrefix + "provinces",
  districtByProvience: commonPrefix + "districts/province",
  localBodyByDistrict: commonPrefix + "local-bodies",
  educationLevel: commonPrefix + "educations",

  branches: commonPrefix + "branches",
  departments: commonPrefix + "departments",
  locations: commonPrefix + "locations",
  status: commonPrefix + "shared/status",
  experienceDuration: commonPrefix + "experience-duration",

  companyInformation: commonPrefix + "company-information",
  serverDateTime: commonPrefix + "server-date-time",

  uploadFiles: commonPrefix + "shared/UploadFiles",
  deleteFiles: commonPrefix + "shared/DeleteFiles",
};
