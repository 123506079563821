import React, { Fragment } from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { getUploadPath } from "constants/userDetails";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import { numberToNepaliCurrency } from "helpers/regex";
import { getLoanTypeText } from "./index";
import moment from "moment";
import Logo from "../../../helpers/PrimeLogo.png";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

export default function StaffOverDraftPDF(props) {
  //   const { Requester, LoanType, Approve, Salary } = props;
  // const { LoanTitle, LoanType, NoOfLoanTaken, DisbursementDate } = props || {};
  const {
    EmployeeDetail,
    LoanDetails,
    OutstandingLoan,
    TotalGrossSalary,
    FourWheelerNoOfLoanTaken,
    FourWheelerDisbursementDate,
    LoanFilledInformation,
    LoanType,
  } = props || {};

  const EmployeeInfoTable = ({ data }) => <ItemsTable data={data} />;

  const ItemsTable = ({ data }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <TableRow items={data} />
    </View>
  );

  const TableRow = ({ items }) => {
    let length = items.length;
    const rows =
      items &&
      items.map((item, i) => (
        <View
          style={i + 1 != length ? stylesTableRow.row : stylesTableRow.lastRow}
          key={i}
        >
          <Text style={stylesTableRow.title}>{item.title}</Text>
          <Text style={stylesTableRow.description}>{item.description}</Text>
        </View>
      ));
    return <Fragment>{rows}</Fragment>;
  };

  // --------------------- outstanding loan ---------------------
  const OutstandingTable = ({ data }) => <OutItemsTable data={data} />;

  const OutItemsTable = ({ data }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <OutTableRow items={data} />
    </View>
  );

  const OutTableRow = ({ items }) => {
    let length = items.length;
    if (length == 0) {
      return (
        <View style={{ padding: 10 }}>
          <Text style={{ fontSize: 10 }}>No Outstanding Loan</Text>
        </View>
      );
    } else {
      const rows = [
        <View
          wrap={false}
          style={outStylesTableRow.headerRow}
          key={"456t78iuu"}
        >
          <Text style={{ ...outStylesTableRow.header, fontWeight: "600" }}>
            Loan
          </Text>
          <Text
            style={{ ...outStylesTableRow.headerAmount, fontWeight: "600" }}
          >
            Outstanding Amount(NPR)
          </Text>
          <Text style={{ ...outStylesTableRow.headerDates, fontWeight: "600" }}>
            As on
          </Text>
          <Text
            style={{ ...outStylesTableRow.headerAmount, fontWeight: "600" }}
          >
            Monthly Deduction(NPR)
          </Text>
          <Text
            style={{
              ...outStylesTableRow.headerDates,
              fontWeight: "600",
              borderRight: "unset",
            }}
          >
            Completes On
          </Text>
        </View>,
      ];
      let dataRows =
        items &&
        items.map((item, i) => (
          <View
            style={i == 0 ? outStylesTableRow.row : outStylesTableRow.lastRow}
            key={i}
          >
            <>
              <Text style={outStylesTableRow.header}>{item.LoanName}</Text>
              <Text style={outStylesTableRow.headerAmount}>
                {currencyCommaSeperator(item.Outstanding)}
              </Text>
              <Text style={outStylesTableRow.headerDates}>{item.AsOn}</Text>
              <Text style={outStylesTableRow.headerAmount}>
                {currencyCommaSeperator(item.MonthlyDeduction)}
              </Text>
              <Text
                style={{
                  ...outStylesTableRow.headerDates,
                  borderRight: "unset",
                }}
              >
                {item.CompletesOn}
              </Text>
            </>
          </View>
        ));
      rows.push(dataRows);
      return <Fragment>{rows}</Fragment>;
    }
  };

  // --------------------- APPROVER LIST SECTION TABLE ---------------------
  // const ApproverListTable = ({ data, position }) => <ApproverItemsTable data={data} />;

  const ApproverListTable = ({ data, position }) => (
    <View wrap={false} style={stylesItemTable.tableContainer}>
      <ApproverTableRow items={data} position={position} />
    </View>
  );

  const ApproverTableRow = ({ items, position }) => {
    let length = items.length;
    if (position !== "first" && length < 4) return null;

    position == "first"
      ? (items = items.slice(0, 3))
      : (items = items.slice(3, 6));
    return (
      <Fragment>
        <View style={approverStylesTableRow.row}>
          {items.map((item, i) => (
            <View
              style={approverStylesTableRow.column}
              // style={
              //   i + 1 != length ? approverStylesTableRow.row : approverStylesTableRow.lastRow
              // }
              key={i}
            >
              <>
                <Text style={approverStylesTableRow.header}>
                  {item.StatusName ? `${item.StatusName}` : ((i + 1) % 3 == 1
                    ? "HR Forward"
                    : "Loan information filled by")}
                </Text>
                <Text style={approverStylesTableRow.header}>{item.EmployeeName}</Text>
                <Text style={approverStylesTableRow.header}>{item.DepartmentName}</Text>
                <Text style={approverStylesTableRow.header}>
                  {moment(item.FilledDate).format("YYYY/MM/DD hh:mm A")}
                </Text>
              </>
            </View>
          ))}
        </View>
      </Fragment>
    );
    // rows.push(dataRows);
    // return <Fragment>{rows}</Fragment>;
  };

  // let advanceAndExpense_Total = 0;
  return (
    <Document title={`${LoanDetails?.LoanTitle} Application Form.pdf`}>
      <Page style={styles.body} size="A4" orientation="portrait" wrap={false}>
        {/* ------------------------BANK HEADER----------------------- */}
        {/* <Text style={styles.bankNameHeader}>
          {bankName}
        </Text>
        <Text style={styles.loanTypeHeader}>
          {LoanDetails && LoanDetails.LoanTitle + " APPLICATION FORM"}
        </Text> */}
        {/* ------------------------SUBJECT INFORMATION----------------------- */}
        <Image style={styles.image} src={Logo} />
        <View wrap={false} style={approverStylesTableRow.lastRow}>
          <View wrap={false} /*  style={{ width: "33%" }} */>
            <Text style={styles.greetingHeader}>To,</Text>
          </View>
          <View>
            <Text style={styles.applicationDate}>
              Date: {LoanDetails && LoanDetails.RequestedOn}
            </Text>
          </View>
        </View>
        <Text style={styles.greetingHeader}>Human Resources Department</Text>
        <Text style={styles.greetingHeader}>Prime Commercial Bank Ltd.</Text>
        <Text style={styles.greetingHeader}>Central Office, Kamalpokhari</Text>
        <Text style={{ ...styles.greetingHeader, marginBottom: 10 }}>
          Kathmandu, Nepal
        </Text>
        <Text style={styles.loanSubject}>
          Subject: Application for {LoanDetails && LoanDetails.LoanTitle}
        </Text>
        <Text style={styles.titleSubHeader}>Dear Sir/Madam</Text>
        <Text style={{ ...styles.titleSubHeader }}>
          I hereby request you to grant me{" "}
          <View>
            <Text style={{ fontWeight: "700" }}>
              {LoanDetails && LoanDetails.LoanTitle}
            </Text>
          </View>{" "}
          of NPR {currencyCommaSeperator(LoanDetails?.RequestAmount) || "-"}{" "}
          {LoanType == "PrimeVehicleLoan" ? "to buy a" : "for the purpose of"}{" "}
          {LoanDetails && LoanDetails.LoanPurpose}.{" "}
          {LoanDetails && LoanDetails.LoanTitle == "Staff Personal Loan" && (
            <Text style={{ ...styles.titleSubHeader }}>
              I'll repay the loan in {LoanDetails && LoanDetails.RepayIn} equal
              installments along with interest from my salary.
            </Text>
          )}
        </Text>
        {/* ------------------------EMPLOYEE INFORMATION----------------------- */}
        <Text style={styles.titleHeader}>Employee Information</Text>
        <View wrap={false} style={{ marginBottom: "20px" }}>
          <EmployeeInfoTable data={EmployeeDetail || []} />
        </View>

        {/* ------------------------GROSS SALARY SECTION----------------------- */}
        {/* <Text style={styles.titleHeader}>
          Gross Salary
        </Text>
        <Text style={styles.salaryAmount}>
          {grossSalary}
        </Text> */}

        {/* ------------------------OUTSTANDING LOAN SECTION----------------------- */}
        <Text style={styles.titleHeader}>Outstanding Loans</Text>
        <View wrap={false} style={{ marginBottom: "32px" }}>
          <OutstandingTable data={OutstandingLoan || []} />
        </View>
        {/* ------------------------ NUMBER OF DISTRIBUTUION SECTION ----------------------- */}
        {(LoanType == "PrimeStaffPersonalLoan" ||
          LoanType == "PrimeVehicleLoan") && (
          <View
            wrap={false}
            style={{ ...approverStylesTableRow.lastRow, marginBottom: "20px" }}
          >
            {/* <View wrap={false} style={styles.loantakenDiv}> */}
            {/* <Fragment> */}
            <View wrap={false} style={{ width: "33%" }}>
              <Text style={{ display: "visible", ...styles.greetingHeader }}>
                {" "}
              </Text>
              <Text style={styles.greetingHeader}>
                Number of times loan taken:{" "}
              </Text>
              <Text style={{ ...styles.greetingHeader }}>
                Latest Disbursement Date:{" "}
              </Text>
            </View>
            <View wrap={false} style={{ width: "33%" }}>
              {LoanType == "PrimeVehicleLoan" ? (
                <Text style={styles.greetingHeader}>Two Wheeler</Text>
              ) : (
                <Text style={{ display: "visible", ...styles.greetingHeader }}>
                  {" "}
                </Text>
              )}
              <Text style={styles.greetingHeader}>
                {(LoanDetails && LoanDetails.NoOfLoanTaken) || "-"}
              </Text>
              <Text style={{ ...styles.greetingHeader }}>
                {(LoanDetails && LoanDetails.DisbursementDate) || "-"}
              </Text>
            </View>
            <View wrap={false} style={{ width: "33%" }}>
              {LoanType == "PrimeVehicleLoan" ? (
                <Text style={styles.greetingHeader}>Four Wheeler</Text>
              ) : (
                <Text style={{ display: "visible", ...styles.greetingHeader }}>
                  {" "}
                </Text>
              )}
              <Text style={{ display: "visible", ...styles.greetingHeader }}>
                {(LoanDetails && LoanDetails.FourWheelerNoOfLoanTaken) || " "}
              </Text>
              <Text style={{ display: "visible", ...styles.greetingHeader }}>
                {(LoanDetails && LoanDetails.FourWheelerDisbursementDate) ||
                  " "}
              </Text>
            </View>
            {/* </Fragment> */}
          </View>
        )}
        <Text
          style={[
            {
              // borderBottomWidth: 0.5,
              width: "100%",
              marginBottom: "20px",
              borderBottom: "1px solid #000000",
            },
          ]}
        >
          {" "}
        </Text>
        {/* ------------------------APPROVER LISTS SECTION----------------------- */}
        <View wrap={false} style={{ marginBottom: "20px" }}>
          <ApproverListTable
            data={LoanFilledInformation || {}}
            position={"first"}
          />
        </View>
        <View wrap={false}>
          <ApproverListTable
            data={LoanFilledInformation || {}}
            position={"second"}
          />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
}

const stylesItemTable = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const stylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    // border: "1px solid #b6bec9",
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
    // border: "1px solid #b6bec9",
    // borderTop: "unset !important",
  },
  title: {
    width: "50%",
    // borderRight: "1px solid #b6bec9",
    padding: "4px",
    // paddingLeft: "10px",
    fontSize: "10px",
    fontWeight: "300",
    // color: "#28417b",
    color: "#000000",
  },
  description: {
    width: "50%",
    fontSize: "10px",
    fontWeight: "300",
    padding: 4,
    paddingLeft: "10px",
    color: "#000000",
  },
});

const approverStylesTableRow = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  column: {
    flexDirection: "column",
    // alignItems: "center",
    width: "100%",
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    width: "33.33%",
    // paddingLeft: "10px",
    fontSize: "10px",
    marginBottom: 2,
  },
  firstHeader: {
    width: "33.33%",
    fontSize: "10px",
  },
});

const outStylesTableRow = StyleSheet.create({
  headerRow: {
    flexDirection: "row",
    // alignItems: "center",
    // padding: "2px",
    paddingLeft: "2px",
    border: "1px solid #b6bec9",
    borderBottom: "unset",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "2px",
    border: "1px solid #b6bec9",
    // borderBottom: "unset"
  },
  lastRow: {
    flexDirection: "row",
    // alignItems: "center",
    // padding: "2px",
    paddingLeft: "2px",
    border: "1px solid #b6bec9",
    borderTop: "unset !important",
  },
  header: {
    width: "20%",
    borderRight: "1px solid #b6bec9",
    padding: "2px",
    paddingLeft: "2px",
    fontSize: "10px",
  },
  headerDates: {
    width: "15%",
    borderRight: "1px solid #b6bec9",
    padding: "2px",
    paddingLeft: "2px",
    fontSize: "10px",
  },
  headerAmount: {
    width: "25%",
    borderRight: "1px solid #b6bec9",
    padding: "2px",
    paddingLeft: "2px",
    fontSize: "10px",
  },
});

const styles = StyleSheet.create({
  loantakenDiv: {
    // display: "flex",
    // flex: 1,
    // flexDirection: "column",
    // marginVertical: 0.1,
    // marginBottom: "3px",
    // gridTemplateColumns: "1fr 1fr 1fr"
    // "grid-template-column": repeat(1, "1fr", "1fr", "1fr")
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  row: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 2.5,
  },

  image: {
    maxWidth: "50%",
    maxHeight: 30,
    objectFit: "contain",
    marginRight: "auto",
    marginBottom: 16,
  },
  bankNameHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "#000000",
    fontWeight: 600,
    textDecoration: "underline",
  },
  loanTypeHeader: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#000000",
  },
  applicationDate: {
    fontSize: 10,
    // marginBottom: 20,
    textAlign: "right",
    color: "#000000",
  },
  titleHeader: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "left",
    color: "#000000",
    fontWeight: 600,
    textDecoration: "underline",
  },
  titleSubHeader: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: "left",
    color: "#000000",
  },
  greetingHeader: {
    fontSize: 10,
    textAlign: "left",
    marginBottom: 4,
    fontWeight: 600,
  },
  loanSubject: {
    fontSize: 11,
    marginBottom: 10,
    textAlign: "center",
    color: "#000000",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  propertyHeader: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: "left",
    fontWeight: "600",
    color: "#000000",
  },
  salaryAmount: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: "left",
    color: "#28417b",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
