import Http from "src/utils/http/http";
import axios from 'helpers/axiosinstance';
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import { ADD_MESSAGE } from "actions/types";

export const getSbiLoanSettingList = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.sbi.sbiLoanSettingList);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_SETTING_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// Add Loan Purpose
export const addLoanPurpose = (data, handleModalShow) => (dispatch) => {
  dispatch(setLoanPurposeLoading(true));
  const res = Http.post(environment.api.v1.sbi.addLoanPurpose, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_LOAN_PURPOSE,
    effect: res,
    isLoading: true,
  };
  handleCreateThunkEffect(actionConfig, handleModalShow);
};

// Edit Loan Purpose
export const editLoanPurpose = (data, handleModalShow) => async (dispatch) => {
  dispatch(setLoanPurposeLoading(true));
  const res = Http.put(environment.api.v1.sbi.editLoanPurpose, data);
  const actionConfig = {
    dispatch,
    actionType: types.EDIT_LOAN_PURPOSE,
    effect: res,
    isLoading: true,
  };
  handleCreateThunkEffect(actionConfig, handleModalShow);
};

// Delete Loan Purpose
export const deleteLoanPurpose = (id, handleModalShow) => async (dispatch) => {
  dispatch(setLoanPurposeLoading(true));
  const res = Http.delete(`/v1/sbiloan/purposes/${id}`)
    const actionConfig = {
		dispatch,
		actionType:types.DELETE_LOAN_PURPOSE,
		effect:res,
    type: "delete",
    id,
    isLoading:true
	}
  handleCreateThunkEffect(actionConfig, handleModalShow);
};

export const loadLoanPurpose = (data) => (dispatch) => {
  dispatch({
    type: types.LOAD_LOAN_PURPOSE,
    payload: data,
  });
};

export const setEditMode = () => (dispatch) => {
  dispatch({
    type: types.SET_EDIT_MODE,
  });
};

export const getSbiLoanPurposeList = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.sbi.sbiLoanPurposeList);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_PURPOSE_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getSbiLoanTypeList = (param) => async (dispatch) => {
  const res = Http.get(environment.api.v1.sbi.sbiLoanTypeList + `${param}`);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_TYPE_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const updateSbiLoanSetting = (data) => async (dispatch) => {
  const res: any = Http.post(environment.api.v1.sbi.sbiGeneralLoanSave, data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_LOAN_SETTING,
    effect: res,
    message: "Loan Settings has been updated successfully.",
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const updateSbiSettingAmountLimit = (loanType, data) => async (dispatch) => {
  const res = Http.post(
    environment.api.v1.sbi.sbiSettingAmountLimit + "/?loanType=" + loanType,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_SETTING_AMOUNT_LIMIT,
    effect: res,
    isLoading: true,
    message: "Loan Amount Limit has been created successfully.",
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const getSbiSettingApprovalFlow = (loanType, flowType) => async (dispatch) => {
  const res = Http.get(
    environment.api.v1.sbi.sbiSettingApprovalFlow + `?loanType=${loanType}&flowType=${flowType}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_SETTINGS_APPROVAL_FLOW,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const postSbiSettingApprovalFlow = (loanType, data) => async (dispatch) => {
  const res = Http.post(
    environment.api.v1.sbi.sbiSettingApprovalFlow + "/?loanType=" + loanType,
    data
  );
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_SBI_SETTING_AMOUNT_LIMIT,
    effect: res,
    isLoading: true,
    message: "Approval flow setting has been created successfully.",
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const getEmployeeListing = (search) => async (dispatch) => {
  const res = Http.get(environment.api.v1.sbi.employeeListForSbiLoan + `?searchTerm=${search}`);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_ALL_EMPLOYEES_SPECIFIC,
    effect: res,
    isJustData: true,
    isLoading: false,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getSbiLoanStatusHistory =
  (search, data, isExport) =>
  async (dispatch) => {
    let alldatas = {
      ...search,
      loanType: data.value ?? 0,
    };
    const res: any = await Http.post(environment.api.v1.sbi.sbiLoanStatusHistory, alldatas);

    const actionConfig = {
      dispatch,
      actionType: isExport
        ? types.EXPORT_SBI_LOAN_STATUS_HISTORY
        : types.GET_SBI_LOAN_STATUS_HISTORY,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
    if (res.Status && isExport) return res.data.Data;
  };

export const getSbiLoanApprovers =
  ({EmployeeId, LoanType, FlowType, CurrentStep}) =>
  async (dispatch) => {
    const res: any = await Http.get(
      environment.api.v1.sbi.sbiLoanSettingsApprovers +
        `?employeeId=${EmployeeId}&loanType=${LoanType}&flowType=${FlowType}&currentStep=${CurrentStep}`
    );
    const actionConfig = {
      dispatch,
      actionType: types.GET_SBI_LOAN_APPROVERS_LIST,
      effect: res,
      message: "",
      isLoading: true,
      isJustData: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

export const forwardSbiLoans = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.v1.sbi.forwardSbiLoan, data);
  const actionConfig = {
    dispatch,
    actionType: types.FORWARD_SBI_LOAN,
    effect: res,
    message: "Loan has been Forwarded successfully.",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.MessageType === "Success"

};

export const rejectSbiLoan = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.v1.sbi.rejectSbiLoan, data);
  const actionConfig = {
    dispatch,
    actionType: types.REJECT_SBI_LOAN,
    effect: res,
    message: "Loan has been Rejected successfully.",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.MessageType === "Success"
};

export const getSbiLoanType = () => async (dispatch) => {
  const res: any = Http.get(environment.api.v1.sbi.sbiLoanTypes);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SBI_LOAN_TYPES,
    effect: res,
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
};
export const toastMessage = (res) => {
  return {
    type: ADD_MESSAGE,
    payload: {
      type: res.data.MessageType,
      message: res.data.Message,
      position: "bottom-right",
    },
  };
};

// Set loading state
export const setLoanPurposeLoading = (data) => {
  return {
    type: types.SET_LOAN_PURPOSE_LOADING,
    payload: data,
  };
};

// Handle Create Thunk Effect
export const handleCreateThunkEffect = async (actionConfig, handleModalShow) => {
  const resp = await Action.createThunkEffect(actionConfig);
  if (resp && resp ?.Status) {
    handleModalShow(false, "normal");
  }
}
