import React, {useState, useRef, useEffect} from "react";
import {GoComment} from "react-icons/go";
import isEmpty from "isEmpty";
import EmployeeDetails from "scenes/common/employeeDetails";

import TimeAttendanceTable from "./timeAttendanceTable";
import OverTimeRequestTable from "./overTimeRequestTable";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import {DateTime_Format, Time_Format} from "helpers/dateFormat";
import GetDetailsPage from "./getDetailsPage";
import {IoIosAddCircle} from "react-icons/io";
import {AiFillMinusCircle} from "react-icons/ai";

interface IAppProps {
  row: any;
  isViewMode: boolean;
  isReview?: boolean;
  refreshRequestData: Function;
  requestData: any;
  page: string;
}

const RequestBlock: React.FunctionComponent<IAppProps> = (props) => {
  const {row, isViewMode, isReview, refreshRequestData, requestData, page} = props;
  const employeeDetail = {
    Id: row["EmployeeId"],
    FullName: row["Requester"],
    Imagepath: row["RequesterImage"],
    Designation: row["Designation"],
    Branch: row["Branch"],
    Department: row["Department"],
  };
  let [isDetailsPageShow, setDetailsPageShow] = useState(false);
  return (
    <>
      <div className="request-details__header">
        <EmployeeDetails empDetails={employeeDetail} size={50} />
        <div className="flex">
          <SvgIcons name={row.RequestType} className="review-type-icon" />

          {row.RequestType === "Leave" ? (
            <span className="review-type ml-xsm">{row.LeaveName}</span>
          ) : row.RequestType === "Time" ? (
            <span className="review-type ml-xsm">{"Time Request"}</span>
          ) : row.RequestType === "Overtime" ? (
            <span className="review-type ml-xsm">{row.OvertimeType}</span>
          ) : (
            <span className="review-type ml-xsm">{row.AllowanceType}</span>
          )}
          {!isEmpty(row.CompensatorType) ? (
            row.CompensatorType === "Add" ? (
              <IoIosAddCircle className="comp-leave-plus-icon ml-xsm" />
            ) : (
              <AiFillMinusCircle className="comp-leave-minus-icon ml-xsm" />
            )
          ) : null}
        </div>
        {row.RequestType === "Leave" ? (
          <div>
            <span className="review-days">
              {parseFloat(row.AppliedDays) === 0.5
                ? row.LeaveDayType
                : row.AppliedDays + (parseInt(row.AppliedDays) === 1 ? " day" : " days")}
            </span>
          </div>
        ) : (
          <div>
            <span className="review-days"></span>
          </div>
        )}
        <div>
          {row.RequestType === "Leave" || row.RequestType == "Allowance" ? (
            <span className="review-period">
              {row.FromDate} - {row.ToDate}
            </span>
          ) : (
            <span className="review-period">
              <span>{row.RequestedOn + "  "}</span>
              <span>{row.RequestedOnEng && Time_Format(row.RequestedOnEng)}</span>
            </span>
          )}
        </div>

        <div className="comment flex">
          {row.IsCommented && !isEmpty(row.Comments) ? (
            <SvgIcons
              name="CommentLine"
              className="comment__icon cursor-pointer link-color"
              onClick={() => {
                setDetailsPageShow(true);
              }}
            />
          ) : null}
        </div>
      </div>
      <div className="request-details__body">
        <div className="body-row">
          <div className="body-col wd-100">
            <div className="body-col__header">
              {(row.RequestType === "Leave" ||
                row.RequestType === "Allowance" ||
                row.RequestType === "Overtime") && (
                <span className="reason">{row?.Reason}</span>
              )}
            </div>
            <div className="body-col__header">
              {row.RequestType === "Allowance" && (
                <span className="reason">
                  {row?.Units} {row?.Units <= 1 ? " Unit" : " Units"}
                </span>
              )}
            </div>
            {row.RequestType === "Time" && (
              <TimeAttendanceTable timeAttendanceRequest={row.TimeRequests} />
            )}
            {row.RequestType === "Leave" && !isEmpty(row.SubstituteEmployee) && (
              <div className="flex substitute mt-sm">
                <span className="substitute__name">{row.SubstituteEmployee}</span>
                <span className="substitute__type ml-md">Replacement</span>
              </div>
            )}
            {row.RequestType === "Overtime" && (
              <OverTimeRequestTable overTimeRequest={row.OvertimeRequests} />
            )}
          </div>
        </div>
      </div>
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={row}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={isViewMode}
          isReview={isReview}
          refreshRequestData={refreshRequestData}
          requestData={requestData}
          page={page}
        />
      )}
    </>
  );
};

export default RequestBlock;
