import React from "react";
import classnames from "classnames";
import { MdPlayCircleFilled } from "react-icons/md";
import RouteEnum from "constants/routeEnum";
import { useHistory } from "react-router";
import {
  EmployeeCourseAssignStatus,
  LMSCourseNavigationPolicyType,
} from "../helpers/enums";

interface IProps {
  course: any;
  coursePage?: any;
}

function PageList(props: IProps) {
  const { course, coursePage } = props;
  const history = useHistory();

  const handleClickPage = (index, item) => {
    const { CourseId, CoursePageId, AssignedId } = item;
    if (checkIfLocked(index, item)) return;

    let url = "";
    if (AssignedId) {
      url = `${RouteEnum.lmsCourse}/${CourseId}/${AssignedId}/pages/${CoursePageId}`;
    } else url = `${RouteEnum.lmsCourse}/${item.CourseId}/pages/${CoursePageId}`;

    history.push(url);
  };
  const checkIfLocked = (index, item) => {
    let isLocked =
      !item?.IsRead &&
      course?.NavigationPolicy === LMSCourseNavigationPolicyType.Sequential;

    if (isLocked && index == 0)
      isLocked = course.AssignedStatus === EmployeeCourseAssignStatus.NotStarted;

    return isLocked;
  };

  return (
    <div>
      <ul className="course-sidebar-list">
        {course?.EmployeeCoursePageDetail?.map((item, index) => {
          const classes = classnames("course-sidebar-item cursor-pointer", {
            active: item.CoursePageId === coursePage?.Id,
            read: item.IsRead,
            locked: checkIfLocked(index, item),
          });
          return (
            <li
              key={index}
              onClick={() => handleClickPage(index, item)}
              className={classes}
            >
              {/* {`isRead:${item.IsRead}`} */}
              {/* <MdPlayCircleFilled /> */}
              <span className="status-course"></span>
              <span>{item.PageName}</span>

            </li >
          );
        })}
      </ul >
    </div >
  );
}

export default PageList;
