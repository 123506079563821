import React,{useState} from 'react'
import Modal from 'components/modal'
import Button from 'components/button'

interface ConfProps{
    action:Function,
    title?:any,
    onButton?:boolean,
    okButtonTitle?:string,
    children?:any
}

function Confirm(props:ConfProps) {
    const {action,title = null,onButton,okButtonTitle,children} = props;
    const [open, setOpen] = useState(false);
    let childProps;
    if(children){
        childProps = {
            onClick:()=>setOpen(true),
            ...children.props
        }
    }
    return (
        <>
        {
            children ? (
                React.cloneElement(
                    children, 
                    childProps
                )
            )
            :
            (
                <Button 
                    bg="white"
                    type="button"
                    onClick={()=>setOpen(true)}
                    title={title ? title : "Delete"}
                />
            )
        }

            <Modal 
                open={open}
                type="confirm"
                onModalClose={()=>setOpen(false)}
                func={()=>action()}
                okButtonTitle={okButtonTitle ?? ""}
                title={title}
            />
        </>
    )
}

export default Confirm
