import types from "constants/types";

const initialState = {
    reviewCycles: [],
    rollouts: [],
    employeeBatchRollouts: [],
    reviewCyclesDropDown: [],
    ratingScales:[],
    workflows:[],
    threeSixtyWorkflows:[],
    workflowSteps:[],
    goalDatabases:[],
};


export default function (state: any = initialState, action: any) {
    switch (action.type) {
        case types.GET_APPRAISALS_ROLLOUTS: {
            return {
                ...state,
                rollouts: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_EMPLOYEE_BATCH_ROLLOUTS: {
            return {
                ...state,
                employeeBatchRollouts: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_GOAL_DATABASES: {
            return {
                ...state,
                goalDatabases: action.payload ?? [],
            };
        }
        case types.DELETE_APPRAISALS_GOAL_DATABASE: {
            return {
                ...state,
                goalDatabases: state.goalDatabases.filter((item) => item.Id !== action.payload),
            };
        }
        case types.ADD_APPRAISALS_GOAL_DATABASE: {
            return {
                ...state,
                goalDatabases: [...state.goalDatabases, action.payload],
            };
        }
        case types.GET_APPRAISALS_WORKFLOW: {
            return {
                ...state,
                workflows: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_THREESIXTY_WORKFLOW: {
            return {
                ...state,
                threeSixtyWorkflows: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_WORKFLOW_STEPS: {
            return {
                ...state,
                workflowSteps: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_REVIEW_CYCLES: {
            return {
                ...state,
                reviewCycles: action.payload ?? [],
            };
        }
        case types.GET_APPRAISALS_REVIEW_CYCLES_DROPDOWN: {
            return {
                ...state,
                reviewCyclesDropDown: (action.payload && action.payload.map(item=>({...item,label:item.Text,value:item.Value}))) ?? [],
            };
        }
        case types.ADD_APPRAISALS_REVIEW_CYCLE: {
            return {
                ...state,
                reviewCycles: [...state.reviewCycles, action.payload],
            };
        }
        case types.UPDATE_APPRAISALS_REVIEW_CYCLE: {
            return {
                ...state,
                reviewCycles: state.reviewCycles.map((item) => {
                    if (item.Id === action.payload.Id) {
                    return action.payload
                    }
                    return item;
                })
            };
        }
        case types.DELETE_APPRAISALS_REVIEW_CYCLE: {
            return {
                ...state,
                reviewCycles: state.reviewCycles.filter((item) => item.Id !== action.payload),
            };
        }
        case types.GET_APPRAISALS_RATING_SCALE:{
            return {
                ...state,
                ratingScales: action.payload ?? [],
            };
        }
        case types.DELETE_APPRAISALS_RATING_SCALE:{
            return {
                ...state,
                ratingScales: state.ratingScales.filter((item) => item.Id !== action.payload),
            };
        }
      default:
        return state;
    }
  }
  