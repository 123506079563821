export default {
  //LMS
  GET_COURSES: "GET_COURSES",
  GET_COURSE_LIST: "GET_COURSE_LIST",
  GET_FILTERED_COURSE_LIST: "GET_FILTERED_COURSE_LIST",
  GET_SEARCHED_FILTERED_COURSE_LIST: "GET_SEARCHED_FILTERED_COURSE_LIST",
  GET_COURSE_RESULTS_BY_COURSE_ID: "GET_COURSE_RESULTS_BY_COURSE_ID",
  GET_COURSE_RESULT_DETAIL_BY_COURSE_ID: "GET_COURSE_RESULT_DETAIL_BY_COURSE_ID",
  GET_COURSE: "GET_COURSE",
  GET_COURSE_PAGES: "GET_COURSE_PAGES",
  GET_COURSE_PAGE: "GET_COURSE_PAGE",
  ADD_COURSE_PAGE: "ADD_COURSE_PAGE",
  UPDATE_COURSE_PAGE: "UPDATE_COURSE_PAGE",
  DELETE_COURSE_PAGE: "DELETE_COURSE_PAGE",
  GET_COURSE_COURSE_NAVIGATION_POLICY: "GET_COURSE_COURSE_NAVIGATION_POLICY",
  GET_COURSE_CATEGORIES: "GET_COURSE_CATEGORIES",
  ADD_COURSE_CATEGORY: "ADD_COURSE_CATEGORY",
  UPDATE_COURSE_CATEGORY: "UPDATE_COURSE_CATEGORY",
  DELETE_COURSE_CATEGORY: "DELETE_COURSE_CATEGORY",
  GET_RESULT_SUMMARY_DETAIL: "GET_RESULT_SUMMARY_DETAIL",
  Add_COURSE: "Add_COURSE",
  Delete_COURSE: "Delete_COURSE",
  Update_COURSE: "Update_COURSE",
  GET_COURSE_ENROLLMENT: "GET_COURSE_ENROLLMENT",
  GET_SHOW_COURSE_TO: "GET_SHOW_COURSE_TO",
  GET_COURSE_OPTIONS: "GET_COURSE_OPTIONS",
  ADD_COURSE_OPTIONS: "ADD_COURSE_OPTIONS",
  UPDATE_COURSE_OPTIONS: "UPDATE_COURSE_OPTIONS",
  GET_COURSE_CONTENT: "GET_COURSE_CONTENT",
  ADD_COURSE_CONTENT: "ADD_COURSE_CONTENT",
  UPDATE_COURSE_ORDER: "UPDATE_COURSE_ORDER",
  EDIT_COURSE_STATUS: "EDIT_COURSE_STATUS",
  UPDATE_COURSE_CONTENT: "UPDATE_COURSE_CONTENT",
  DELETE_COURSE_CONTENT: "DELETE_COURSE_CONTENT",
  GET_ASSIGNED_COURSE: "GET_ASSIGNED_COURSE",
  GET_ASSIGNED_COURSE_DASHBOARD: "GET_ASSIGNED_COURSE_DASHBOARD",
  GET_COURSE_DASHBOARD: "GET_COURSE_DASHBOARD",
  GET_COURSE_DASHBOARD_CONCAT: "GET_COURSE_DASHBOARD_CONCAT",
  GET_COURSE_DASHBOARD_COUNT: "GET_COURSE_DASHBOARD_COUNT",
  GET_COURSE_QUESTION_DETAILS: "GET_COURSE_QUESTION_DETAILS",
  ADD_COURSE_QUESTION_DETAILS: "ADD_COURSE_QUESTION_DETAILS",
  UPDATE_COURSE_QUESTION_DETAILS: "UPDATE_COURSE_QUESTION_DETAILS",
  DELETE_COURSE_QUESTION_DETAILS: "DELETE_COURSE_QUESTION_DETAILS",
  ASSIGN_COURSES: "ASSIGN_COURSES",
  ADD_ANSWER: "ADD_ANSWER",
  GET_ANSWERS: "GET_ANSWERS",
  GET_COURSE_COMPLETED: "GET_COURSE_COMPLETED",
  ADD_COURSE_COMPLETED: "ADD_COURSE_COMPLETED",
  UPDATE_COURSE_ASSIGN_STATUS: "UPDATE_COURSE_ASSIGN_STATUS",
  HANDLE_CHANGE_FILTER_VALUE: "HANDLE_CHANGE_FILTER_VALUE",
  CLEAR_STATE: "CLEAR_STATE",

  //Common Private
  GET_BRANCHES_DETAILS: "GET_BRANCHES_DETAILS",
  GET_DEPARTMENT_DETAILS: "GET_DEPARTMENT_DETAILS",

  // HR
  GET_ADVANCE_TRAVEL_REQUEST: "GET_ADVANCE_TRAVEL_REQUEST",
  GET_ADVANCE_TRAVEL_CATEGORY_LIST: "GET_ADVANCE_TRAVEL_CATEGORY_LIST",
  EXPORT_ADVANCE_TRAVEL_REQUEST: "EXPORT_ADVANCE_TRAVEL_REQUEST",

  // Overtime
  EXPORT_OVERTIME_REQUEST: "EXPORT_OVERTIME_REQUEST",

  // allowances
  EXPORT_ALLOWANCES_REQUEST: "EXPORT_ALLOWANCES_REQUEST",

  // sbi loan
  GET_CONSUMER_LOAN_PURPOSE_LIST: "GET_CONSUMER_LOAN_PURPOSE_LIST",
  MY_APPLIED_LOAN_LIST_REQUEST: "MY_APPLIED_LOAN_LIST_REQUEST",
  GET_REQUESTER_INFORMATION: "GET_REQUESTER_INFORMATION",
  LOAN_SETTING_BY_TYPE: "LOAN_SETTING_BY_TYPE",
  CALCULATE_PAYMENT_SCHEDULE: "CALCULATE_PAYMENT_SCHEDULE",
  GENERATE_INCOME_DEDUCTION_LIST: "GENERATE_INCOME_DEDUCTION_LIST",
  SAVE_CONSUMER_LOAN_REQUEST: "SAVE_CONSUMER_LOAN_REQUEST",
  DELETE_REQUESTED_LOAN: "DELETE_REQUESTED_LOAN",
  LOAN_APPROVER_LIST: "LOAN_APPROVER_LIST",
  CONSUMER_LOAN_DETAILS: "CONSUMER_LOAN_DETAILS",
  GET_LOAN_DETAILS: "GET_LOAN_DETAILS",
  GET_OUTSTANDING_LOANS: "GET_OUTSTANDING_LOANS",
  SAVE_OUTSTANDING_LOANS: "SAVE_OUTSTANDING_LOANS",
  DELETE_OUTSTANDING_LOANS: "DELETE_OUTSTANDING_LOANS",
  SBI_LOAN_TYPES: "SBI_LOAN_TYPES",
  LOAN_COMMENT_HISTORY: "LOAN_COMMENT_HISTORY",
  SAVE_SOCIAL_LOAN_REQUEST: "SAVE_SOCIAL_LOAN_REQUEST",
  DELETE_SOCIAL_LOAN: "DELETE_SOCIAL_LOAN",
  GET_SOCIAL_LOAN_DETAILS: "GET_SOCIAL_LOAN_DETAILS",
  SAVE_PERSONAL_LOAN_REQUEST: "SAVE_PERSONAL_LOAN_REQUEST",
  GET_PERSONAL_LOAN_DETAILS: "GET_PERSONAL_LOAN_DETAILS",
  LOAN_DETAILS_LOADING: "LOAN_DETAILS_LOADING",

  // SBI LOAN settings
  GET_SBI_LOAN_SETTING_LIST: "GET_SBI_LOAN_SETTING_LIST",
  LOAD_LOAN_PURPOSE: "LOAD_LOAN_PURPOSE",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  ADD_LOAN_PURPOSE: "ADD_LOAN_PURPOSE",
  EDIT_LOAN_PURPOSE: "EDIT_LOAN_PURPOSE",
  DELETE_LOAN_PURPOSE: "DELETE_LOAN_PURPOSE",
  SET_LOAN_PURPOSE_LOADING: "SET_LOAN_PURPOSE_LOADING",
  GET_SBI_LOAN_PURPOSE_LIST: "GET_SBI_LOAN_PURPOSE_LIST",
  GET_SBI_LOAN_TYPE_LIST: "GET_SBI_LOAN_TYPE_LIST",
  UPDATE_SBI_LOAN_SETTING: "UPDATE_SBI_LOAN_SETTING",
  UPDATE_SBI_SETTING_AMOUNT_LIMIT: "UPDATE_SBI_SETTING_AMOUNT_LIMIT",
  GET_SBI_SETTINGS_APPROVAL_FLOW: "GET_SBI_SETTINGS_APPROVAL_FLOW",
  GET_SBI_ALL_EMPLOYEES_SPECIFIC: "GET_SBI_ALL_EMPLOYEES_SPECIFIC",
  GET_SBI_LOAN_APPROVERS_LIST: "GET_SBI_LOAN_APPROVERS_LIST",
  GET_SBI_LOAN_TYPES: "GET_SBI_LOAN_TYPES",

  // SBI LOAN STATUS HISTORY
  GET_SBI_LOAN_STATUS_HISTORY: "GET_SBI_LOAN_STATUS_HISTORY",
  EXPORT_SBI_LOAN_STATUS_HISTORY: "EXPORT_SBI_LOAN_STATUS_HISTORY",
  FORWARD_SBI_LOAN: "FORWARD_SBI_LOAN",
  REJECT_SBI_LOAN: "REJECT_SBI_LOAN",

  // TASK TRACKERPROJECT DETAILS LIST
  TASK_TRACKER_PROJECT_DETAILS_LIST: "TASK_TRACKER_PROJECT_DETAILS_LIST",
  EXPORT_TASK_TRACKER_PROJECT_DETAILS_LIST: "EXPORT_TASK_TRACKER_PROJECT_DETAILS_LIST",
  TASK_TRACKER_GET_PROJECT_TYPES: "TASK_TRACKER_GET_PROJECT_TYPES",

  //Appraisals
  GET_APPRAISALS_REVIEW_CYCLES: "GET_APPRAISALS_REVIEW_CYCLES",
  GET_APPRAISALS_REVIEW_CYCLES_DROPDOWN: "GET_APPRAISALS_REVIEW_CYCLES_DROPDOWN",
  ADD_APPRAISALS_REVIEW_CYCLE: "ADD_APPRAISALS_REVIEW_CYCLE",
  DELETE_APPRAISALS_REVIEW_CYCLE: "DELETE_APPRAISALS_REVIEW_CYCLE",
  UPDATE_APPRAISALS_REVIEW_CYCLE: "UPDATE_APPRAISALS_REVIEW_CYCLE",

  GET_APPRAISALS_RATING_SCALE: "GET_APPRAISALS_RATING_SCALE",
  DELETE_APPRAISALS_RATING_SCALE: "DELETE_APPRAISALS_RATING_SCALE",

  GET_APPRAISALS_WORKFLOW: "GET_APPRAISALS_WORKFLOW",
  GET_APPRAISALS_WORKFLOW_STEPS: "GET_APPRAISALS_WORKFLOW_STEPS",
  DELETE_APPRAISALS_WORKFLOW: "DELETE_APPRAISALS_WORKFLOW",
  ADD_APPRAISALS_WORKFLOW: "ADD_APPRAISALS_WORKFLOW",
  UPDATE_APPRAISALS_WORKFLOW: "UPDATE_APPRAISALS_WORKFLOW",
  GET_APPRAISALS_THREESIXTY_WORKFLOW: "GET_APPRAISALS_THREESIXTY_WORKFLOW",
  DELETE_APPRAISALS_THREESIXTY_WORKFLOW: "DELETE_APPRAISALS_THREESIXTY_WORKFLOW",
  ADD_APPRAISALS_THREESIXTY_WORKFLOW: "ADD_APPRAISALS_THREESIXTY_WORKFLOW",
  UPDATE_APPRAISALS_THREESIXTY_WORKFLOW: "UPDATE_APPRAISALS_THREESIXTY_WORKFLOW",

  GET_APPRAISALS_GOAL_DATABASES: "GET_APPRAISALS_GOAL_DATABASES",
  ADD_APPRAISALS_GOAL_DATABASE: "ADD_APPRAISALS_GOAL_DATABASE",
  DELETE_APPRAISALS_GOAL_DATABASE: "DELETE_APPRAISALS_GOAL_DATABASE",
  UPDATE_APPRAISALS_GOAL_DATABASE: "UPDATE_APPRAISALS_GOAL_DATABASE",

  GET_APPRAISALS_ROLLOUTS: "GET_APPRAISALS_ROLLOUTS",
  GET_APPRAISALS_EMPLOYEE_BATCH_ROLLOUTS: "GET_APPRAISALS_EMPLOYEE_BATCH_ROLLOUTS",
  ADD_APPRAISALS_ROLLOUT: "ADD_APPRAISALS_ROLLOUT",
  DELETE_APPRAISALS_ROLLOUT: "DELETE_APPRAISALS_ROLLOUT",
  UPDATE_APPRAISALS_ROLLOUT: "UPDATE_APPRAISALS_ROLLOUT",

  //Roster Settinfs
  GET_ROOSTER_LOCATION_LIST: "GET_ROOSTER_LOCATION_LIST",
  GET_ROOSTER_LOCATION: "GET_ROOSTER_LOCATION",
  ADD_ROOSTER_LOCATION: "ADD_ROOSTER_LOCATION",
  UPDATE_ROOSTER_LOCATION: "UPDATE_ROOSTER_LOCATION",
  DELETE_ROOSTER_LOCATION: "DELETE_ROOSTER_LOCATION",
  GET_SCHEDULE_EMPLOYEE_UNAVAILABILITY: "GET_SCHEDULE_EMPLOYEE_UNAVAILABILITY",
  GET_WORKING_HOURS: "GET_WORKING_HOURS",
  EXPORT_GET_WORKING_HOURS: "EXPORT_GET_WORKING_HOURS",
  GET_ROSTER_PUBLISHED_HISTORY: "GET_ROSTER_PUBLISHED_HISTORY",
  GET_ROOSTER_SHIFTS_LIST: "GET_ROOSTER_SHIFTS_LIST",
  GET_ROOSTER_SHIFT: "GET_ROOSTER_SHIFT",
  ADD_ROOSTER_SHIFT: "ADD_ROOSTER_SHIFT",
  UPDATE_ROOSTER_SHIFT: "UPDATE_ROOSTER_SHIFT",
  UPDATE_ROOSTER_SHIFT_ARCHIVE: "UPDATE_ROOSTER_SHIFT_ARCHIVE",
  DELETE_ROOSTER_SHIFT: "DELETE_ROOSTER_SHIFT",
  GET_ROOSTER_SHIFTS_DROPDOWN_LIST: "GET_ROOSTER_SHIFTS_DROPDOWN_LIST",
  GET_ROOSTER_PATTERN_LIST: "GET_ROOSTER_PATTERN_LIST",
  GET_ROOSTER_SHIFT_PATTERN_DETAIL_BY_MASTER:
    "GET_ROOSTER_SHIFT_PATTERN_DETAIL_BY_MASTER",
  ADD_ROOSTER_SHIFT_PATTERN_DETAIL: "ADD_ROOSTER_SHIFT_PATTERN_DETAIL",
  GET_ROOSTER_SHIFT_PATTERN: "GET_ROOSTER_SHIFT_PATTERN",
  ADD_ROOSTER_SHIFT_PATTERN: "ADD_ROOSTER_SHIFT_PATTERN",
  UPDATE_ROOSTER_SHIFT_PATTERN: "UPDATE_ROOSTER_SHIFT_PATTERN",
  DELETE_ROOSTER_SHIFT_PATTERN: "DELETE_ROOSTER_SHIFT_PATTERN",
  GET_ROOSTER_SCHEDULE_LIST: "GET_ROOSTER_SCHEDULE_LIST",
  ADD_ROOSTER_SCHEDULE: "ADD_ROOSTER_SCHEDULE",
  PUBLISH_ROOSTER_SCHEDULE: "PUBLISH_ROOSTER_SCHEDULE",
  UPDATE_ROOSTER_SCHEDULE: "UPDATE_ROOSTER_SCHEDULE",
  DELETE_ROOSTER_SCHEDULE: "DELETE_ROOSTER_SCHEDULE",
  GET_ROOSTER_SCHEDULE_EMPLOYEE_LIST: "GET_ROOSTER_SCHEDULE_EMPLOYEE_LIST",
  GET_ROOSTER_SCHEDULE_ALL_EMPLOYEE_LIST: "GET_ROOSTER_SCHEDULE_ALL_EMPLOYEE_LIST",
  GET_ROOSTER_SCHEDULE_EMPLOYEE_WITH_WORKING_HOURS:
    "GET_ROOSTER_SCHEDULE_EMPLOYEE_WITH_WORKING_HOURS",
  ADD_ROOSTER_SCHEDULE_EMPLOYEE: "ADD_ROOSTER_SCHEDULE_EMPLOYEE",
  ADD_ROOSTER_SCHEDULE_EMPLOYEE_ALL: "ADD_ROOSTER_SCHEDULE_EMPLOYEE_ALL",
  UPDATE_ROOSTER_SCHEDULE_EMPLOYEE: "UPDATE_ROOSTER_SCHEDULE_EMPLOYEE",
  REMOVE_ROOSTER_SCHEDULE_EMPLOYEE: "REMOVE_ROOSTER_SCHEDULE_EMPLOYEE",
  GET_ROOSTER_TEAM_LIST: "GET_ROOSTER_TEAM_LIST",
  GET_ROOSTER_TEAM: "GET_ROOSTER_TEAM",
  ADD_ROOSTER_TEAM: "ADD_ROOSTER_TEAM",
  UPDATE_ROOSTER_TEAM: "UPDATE_ROOSTER_TEAM",
  DELETE_ROOSTER_TEAM: "DELETE_ROOSTER_TEAM",
  GET_ROOSTER_TEAM_EMPLOYEE: "GET_ROOSTER_TEAM_EMPLOYEE",
  ADD_ROOSTER_TEAM_EMPLOYEE: "ADD_ROOSTER_TEAM_EMPLOYEE",
  UPDATE_ROOSTER_TEAM_EMPLOYEE: "UPDATE_ROOSTER_TEAM_EMPLOYEE",
  DELETE_ROOSTER_TEAM_EMPLOYEE: "DELETE_ROOSTER_TEAM_EMPLOYEE",
  GET_ROOSTER_TEAM_ROLE_LIST: "GET_ROOSTER_TEAM_ROLE_LIST",
  GET_ROOSTER_TEAM_ROLE_TABLE: "GET_ROOSTER_TEAM_ROLE_TABLE",
  GET_ROOSTER_TEAM_ROLE: "GET_ROOSTER_TEAM_ROLE",
  ADD_ROOSTER_TEAM_ROLE: "ADD_ROOSTER_TEAM_ROLE",
  UPDATE_ROOSTER_TEAM_ROLE: "UPDATE_ROOSTER_TEAM_ROLE",
  DELETE_ROOSTER_TEAM_ROLE: "DELETE_ROOSTER_TEAM_ROLE",
};
