import {ADD_LOADING, GET_ERRORS, CLEAR_ERRORS, ADD_MESSAGE} from "actions/types";
import HttpErrorResponseModel from "./httpErrorResponseModel";
import DataFormator, {valuKeyFormator} from "helpers/dataFormator";
import {Modal} from "reactstrap";
import isEmpty from "helpers/isEmpty";

interface actionProps {
  dispatch: any;
  actionType: string;
  effect: any;
  type?: string;
  message?: string;
  id?: string | number;
  isSelect?: boolean;
  isValueKey?: boolean;
  isLoading?: boolean;
  isJustData?: boolean;
  isReturn?: boolean;
  extraPayload?: {};
}

export default class ActionUtility {
  static async createThunkEffect({
    dispatch,
    isValueKey,
    extraPayload,
    actionType,
    isSelect,
    isJustData,
    effect,
    id,
    message,
    type,
    isLoading = false,
    isReturn = false,
  }: actionProps) {
    // if(type === 'get') dispatch(ActionUtility.createAction(actionType));
    if (isLoading) dispatch({type: ADD_LOADING, payload: true});
    const model = await effect;

    if (isLoading) dispatch({type: ADD_LOADING, payload: false});
    const isError = model instanceof HttpErrorResponseModel;
    if (isError) {
      dispatch({type: `GET_ERRORS`, payload: model});
      if (isReturn) {
        return model;
      }
      return model.errors;
    }
    if (
      (!model?.data?.Status && model?.data?.Message) ||
      model?.data?.Message?.length > 0
    ) {
      dispatch(toastMessage({message: model.data.Message, type: model.data.MessageType}));
    }

    if (isReturn) {
      return model?.data;
    }
    if (isEmpty(actionType)) {
      return model.data;
    }

    if (isSelect) {
      dispatch(
        ActionUtility.createAction(
          actionType,
          DataFormator(isJustData ? model.data : model.data.Data, isValueKey),
          isError,
          extraPayload
        )
      );
      return model.data;
    }
    if (isJustData) {
      dispatch(ActionUtility.createAction(actionType, model.data, isError, extraPayload));
      return model.data;
    }
    if (type === "get")
      dispatch(ActionUtility.createAction(actionType, model.data, isError, extraPayload));

    if (!model.data.Status) {
      dispatch({type: GET_ERRORS, payload: model.data.Message});
      return model.data;
    }

    if (type === "delete") {
      dispatch(ActionUtility.createAction(actionType, id, isError, extraPayload));
    } else {
      dispatch(
        ActionUtility.createAction(actionType, model.data.Data, isError, extraPayload)
      );
    }
    if (message) {
      dispatch(toastMessage({message, type: "success"}));
    }
    dispatch({type: CLEAR_ERRORS});
    return model.data;
  }
  static createAction(type, payload = null, error = false, extraPayload = null) {
    return extraPayload
      ? {type, payload, error, ...extraPayload}
      : {type, payload, error};
  }
}

export const toastMessage = (data) => (dispatch) => {
  let MessageType = data.type ? data.type : null;
  let Message = data.message ? data.message : [];

  dispatch({
    type: ADD_MESSAGE,
    payload: {type: MessageType, message: Message},
  });
};
