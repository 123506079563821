import React from "react";
import {formatedShortMonthDayYear} from "helpers/dateFormat";

function ActiveProjectOrDetail(props) {
  const data = props.row?.original || {};
  if (!data) return null;
  if (data.IsParent) return <span>{data.ActiveProjectCount}</span>;
  return (
    <span className="detail" style={{display: "flex"}}>
      <p>{data.EventStartDateEng && formatedShortMonthDayYear(data.EventStartDateEng)}</p>
      <p>{" - "}</p>
      <p>{data.EventEndDateEng && formatedShortMonthDayYear(data.EventEndDateEng)}</p>
    </span>
  );
}

export default ActiveProjectOrDetail;
