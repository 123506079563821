import React from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

function FinalScoreModal(props) {
    const {onModalClose,open} = props;
    return (
        <Modal
            open={open}
            onModalClose={onModalClose}
            title="Finnal Score Logic"
            width='650px'
            className='appraisals-modal'
        >
            <FinalScoreForm {...props} />
        </Modal>
    )
}


const FinalScoreForm = (props) => {
    const {onModalClose,isEdit} = props;
    const method = useForm();
    const dispatch = useDispatch()
    const onSubmit = (data) =>{
        console.log({data})
    }
    return(
        <FormGroup method={method} onSubmit={onSubmit} >
            <div className='appraisals-modal__container'>
                <div className="appraisals-modal__body">
                    <span>Modal</span>
                </div>
                <div className="appraisals-modal__footer appraisals-modal__footer-center">
                    <Button
                        onClick={() => onModalClose()}
                        bg="subtle" title="Cancel" />
                    <Button
                        bg='primary'
                        title={isEdit ? "Update" : "Save"}
                        htmlButtonType="submit"
                    />
                </div>
            </div>
        </FormGroup>
    )
}


export default FinalScoreModal