import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";

const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Leave Management",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Leave Names", link: "CP/ManageLeaves.aspx"},
      {title: "Office Timings", link: "cp/AttendanceTime.aspx"},
      {title: "Organizations Holiday", link: "CP/Holiday.aspx"},
      {title: "Employee Weekly Holiday", link: "newhr/weeklyholidaysettings.aspx"},
      {title: "Ignore Late In", link: "attendance/LateEntrySkipDate.aspx"},
      {title: "Attendance Device Mapping", link: "cp/AttendanceDeviceMapping.aspx"},
    ],
  },
  {
    groupTitle: "Team Management",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Team Employee", link: "CP/Extension/ManageEmployeeProjects.aspx"},
      {title: "Leave Teams", link: "newhr/ManageTeams.aspx"},
    ],
  },
  {
    groupTitle: "Logs",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Attendance Sync Status", link: "cp/AttendanceUploadStatus.aspx"},
      {title: "Attendance Sync Logs", link: "cp/AttendanceSyncLog.aspx"},
      {title: "Attendance Logs", link: "newhr/attendaneLogs.aspx"},
    ],
  },
];

function Settings(props) {
  return (
    <div>
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Leave & Time Settings"
        localStorageTitle="LTS"
        modules={modules}
      />
    </div>
  );
}

export default Settings;
