import Button from "components/button";
import React from "react";

function RowActionCell(props) {
  const data = props.row?.original || {};
  const onAddNewProject = (e, data) => {
    e.stopPropagation();
    props.handleEditProject(data);
  };

  const onEditClient = (e, data) => {
    e.stopPropagation();
    props?.handleEditClient(data);
  };
  const onDeleteClient = (e, data) => {
    e.stopPropagation();
    props?.handleDeleteClient(data);
  };
  const onArchiveClient = (e, clientId) => {
    e.stopPropagation();
    props?.handleArchiveClient(clientId);
  };
  const onRestoreClient = (e, clientId) => {
    e.stopPropagation();
    props.handleRestoreClient(clientId);
  };

  return (
    <div>
      <div className="flex">
        {!data.isArchivedTab && (
          <button
            className="button with-icon button-secondary"
            onClick={(e) => onAddNewProject(e, {ClientId: data.ClientId})}
          >
            <span>New Project</span>
          </button>
        )}
        <div className="actions">
          {!data.isArchivedTab ? (
            <Button
              buttonType="drop-button"
              bg="secondary"
              title="Edit"
              onClick={(e) => onEditClient(e, data)}
              dropClass={"common-drop"}
              dropComponent={
                <ul className="common-drop-list">
                  <li
                    className={"common-drop-list__item"}
                    onClick={(e) => onArchiveClient(e, data.ClientId)}
                  >
                    <span className="common-drop-name">Archive</span>
                  </li>

                  <li
                    className={"common-drop-list__item"}
                    onClick={(e) => onDeleteClient(e, data)}
                  >
                    <span className="common-drop-name">Delete</span>
                  </li>
                </ul>
              }
            />
          ) : (
            <Button
              buttonType="drop-button"
              bg="secondary"
              title="Restore"
              onClick={(e) => onRestoreClient(e, data.ClientId)}
              dropClass={"common-drop"}
              dropComponent={
                <ul className="common-drop-list">
                  <li
                    className={"common-drop-list__item"}
                    onClick={(e) => onDeleteClient(e, data)}
                  >
                    <span className="common-drop-name">Delete</span>
                  </li>
                </ul>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RowActionCell;
