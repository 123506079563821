import React from "react";
import {getUploadPath} from "constants/userDetails";
import styled from "styled-components";
import {AiOutlineCloseCircle} from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width};
  margin-bottom: 1rem;
  align-items: center;
  @media (max-width: 1124px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: ${(props) => (props.imageWidth ? `${props.imageWidth}px` : "35px")};
  height: ${(props) => (props.imageHeight ? `${props.imageHeight}px` : "35px")};
  border-radius: 50%;
  border: 1px solid #e3e5e7;
`;

const RightContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  display: flex;
`;

const Name = styled.div`
  margin-right: 0.5rem;
`;

const Bottom = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  letter-spacing: 0.2px;
`;

export const EmployeeNameImageCard = ({
  designation,
  handleRemove,
  image,
  imageWidth,
  imageHeight,
  name,
  showRemoveIcon,
  width,
}) => {
  return (
    <Container width={width}>
      <Image
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        src={getUploadPath(image)}
      />
      <RightContainer>
        <Top>
          <Name>{name}</Name>
          {showRemoveIcon && (
            <AiOutlineCloseCircle
              style={{cursor: "pointer"}}
              color="#b0b9c2"
              onClick={handleRemove}
            />
          )}
        </Top>
        <Bottom>{designation}</Bottom>
      </RightContainer>
    </Container>
  );
};
