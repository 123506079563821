import {
  GET_APPLICANTS,
  GET_APPLICANTS_LOADING,
  GET_EXPORT_APPLICANTS,
} from "store/types";
import { api, post } from "networkService";
import environment from 'environment'

export const FetchCandidates = (param, isExport) => async (dispatch: any) => {
  dispatch({ type: GET_APPLICANTS_LOADING, payload: true });
  const res: any = await post(
    environment.api.v1.recruitment.candidate.candidates,
    dispatch,
    param
  );

  dispatch({
    type: isExport ? GET_EXPORT_APPLICANTS : GET_APPLICANTS,
    payload: res.Data,
  });
};
