import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";

export const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Attendance",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Attendance Register", link: "CP/AttendanceTracking.aspx"},
      {title: "Daily Attendance", link: "Attendance/AttendanceDailyReport.aspx"},
      {title: "Late Entry Deduction", link: "Attendance/LateProcessingDetails.aspx"},
      {title: "Overnight Work Check", link: "newhr/ManageOvernightShift.aspx"},
    ],
  },
  {
    groupTitle: "Leave",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Leave Request", link: "newhr/LeaveRequestList.aspx"},
      {title: "Leave Adjustment", link: "CP/LeaveAdjustmentPage.aspx"},
    ],
  },
  {
    groupTitle: "Other",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Exception Entry", link: "attendance/AttendanceExceptionReport.aspx"},
      {
        title: "Absent Leave Marking",
        link: "attendance/AttendanceAbsentLeaveMarking.aspx",
      },
      {title: "Employee on Leave", link: "newhr/EmployeesOnLeaveList.aspx"},
    ],
  },
];

function CommonTasks(props) {
  return (
    <div>
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Leave & Time Common Task"
        localStorageTitle="LTCT"
        modules={modules}
      />
    </div>
  );
}

export default CommonTasks;
