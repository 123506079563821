import {
  REQUEST_ONBOARDING_EMPLOYEE,
  SAVE_ONBOARDING_EMPLOYEE,
  GET_ONBOARDING_EMPLOYEE_DETAIL,
  SAVE_EMPLOYEE_ONBOARDING_CHECKLIST,
  SET_CHECKLIST,
  CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST,
  GET_ONBOARDING_EMPLOYEE_LIST,
  SAVE_EMPLOYEE_SALARY_TEMPLATE,
  DELETE_ONBOARDING_EMPLOYEE,
  GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
  GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS,
  GET_ON_BOARDING_ACCESS_ID_CATEGORIES,
  GET_ON_BOARDING_CHECKLIST_CATEGORIES,
  SAVE_ONBOARDING_ACCESSID,
  SAVE_ONBOARDING_CHECKLISTS,
  SAVE_EMPLOYEE_INTRO,
  MESSAGE_TEMPLATE_LIST,
  APPROVE_EMPLOYEE_ONBOARDING,
  COMPLETE_ONBOARDING_EMPLOYEE,
  SEND_ONBOARDING_EMAIL,
  RESEND_ONBOARDING_EMAIL,
} from "actions/types";
import axios from "helpers/axiosinstance";
import isEmpty from "isEmpty";
import shortid from "shortid";
import {toastMessage} from "actions/validateAction";
import RouteEnum from "constants/routeEnum";
import {getErrorResponse} from "helpers/getApiResponse";
import {post} from "src/networkService";

export const GetAllMessageTemplate = () => async (dispatch) => {
  await axios
    .get(`/v1/onBoarding/message-template`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: MESSAGE_TEMPLATE_LIST,
          payload: data,
        });
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to fetch message Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const SaveOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
    requesting: true,
  });
  var res = await post(`/v1/onBoarding/employee`, dispatch, data, true).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: SAVE_ONBOARDING_EMPLOYEE,
      payload: res.Data,
      requesting: false,
    });
    toastMessage(dispatch, res);
  } else {
    dispatch({
      type: SAVE_ONBOARDING_EMPLOYEE,
      payload: false,
      requesting: false,
    });
    toastMessage(dispatch, res);
  }
};

export const UpdateOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
    requesting: true,
  });
  await axios
    .put(`/v1/onBoarding/employee`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: response.data.Data,
          requesting: false,
        });
        toastMessage(dispatch, response);
      } else {
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Failed to Save Onboarding Employee Details",
          },
        };
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: false,
          requesting: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee Details",
        },
      };
      dispatch({
        type: SAVE_ONBOARDING_EMPLOYEE,
        payload: false,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};

export const InitiateOnboardingEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_EMPLOYEE,
    payload: false,
    requesting: true,
  });
  await axios
    .put(`/v1/onBoarding/package`, data)
    .then((response) => {
      console.log({response});
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_EMPLOYEE,
          payload: response.data,
          requesting: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee Details",
        },
      };
      dispatch({
        type: SAVE_ONBOARDING_EMPLOYEE,
        payload: false,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};

export const GetOnboardingEmployeeDetail = (employeeId) => async (dispatch) => {
  dispatch({
    type: GET_ONBOARDING_EMPLOYEE_DETAIL,
    payload: null,
    requesting: true,
  });
  await axios
    .get(`/v1/onBoarding/employees/${employeeId}`)
    .then((response) => {
      if (response.status === 200) {
        let data = {...response.data.Data};

        let AccessData = response.data.Data.EmployeeOnboardingAccessAndId;
        let checkListMenu = response.data.Data.EmployeeOnboardingCheckList;
        AccessData =
          AccessData &&
          AccessData.map((template, index) => {
            return {
              TypeName: template.TypeName,
              ResponsiblePerson: {
                value: template.ResponsiblePersonId,
                label: template.ResponsiblePersonName,
              },
              // DueDate: null,
              DueDate: template.DueDate,
              Note: template.Note,
              Id: index,
            };
          });
        checkListMenu =
          checkListMenu &&
          checkListMenu.map((item) => {
            return {
              ...item,
              Checked: item.IsSelected,
            };
          });
        data.EmployeeOnboardingAccessAndId = AccessData;
        data.EmployeeOnboardingCheckList = checkListMenu;
        dispatch({
          type: GET_ONBOARDING_EMPLOYEE_DETAIL,
          payload: data,
          requesting: false,
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get Onboarding Employee Details",
        },
      };
      dispatch({
        type: GET_ONBOARDING_EMPLOYEE_DETAIL,
        payload: null,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};

export const SaveEmployeeOnboardingCheckList = (checkListMenu) => (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_ONBOARDING_CHECKLIST,
    payload: checkListMenu,
  });
};

export const setCheckList = (checkList) => (dispatch) => {
  dispatch({
    type: SET_CHECKLIST,
    payload: checkList,
  });
};

export const clearEmployeeOnboardingCheckList = () => (dispatch) => {
  dispatch({
    type: CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST,
  });
};

export const GetOnBoardingEmployeeList = (data) => async (dispatch) => {
  await axios
    .get(data.requestUrl)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ONBOARDING_EMPLOYEE_LIST,
          payload: response.data.Data,
        });
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get Onboarding employee list",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const SaveEmployeeSalaryTemplate = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_SALARY_TEMPLATE,
    payload: false,
  });
  await axios
    .post(`/v1/onBoarding/employeeSalaryTemplate`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_EMPLOYEE_SALARY_TEMPLATE,
          payload: true,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const DeleteOnBoardingEmployee = (employeeId) => async (dispatch) => {
  dispatch({
    type: DELETE_ONBOARDING_EMPLOYEE,
    payload: false,
  });
  await axios
    .delete(`/v1/onBoarding/employees/${employeeId}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_ONBOARDING_EMPLOYEE,
          payload: employeeId,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetEmployeeEmailPreview = (data) => async (dispatch) => {
  await axios
    .post(`/v1/onBoarding/welcomeMessage?isPackage=true`, data)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
          payload: res.data,
        });
        // toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get employee email preview",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetEmployeeSalaryTemplateDetails = (employeeId) => async (dispatch) => {
  await axios
    .get(`v1/onBoarding/employeeSalaryTemplate/${employeeId}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS,
          payload: res.data.Data,
        });
        // toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get employee salary template details",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetonBoardingAccessID = (employeeId) => async (dispatch) => {
  await axios
    .get(`v1/onBoarding/access-id-categories`)
    .then((res) => {
      if (res.data) {
        if (!isEmpty(res.data.Data)) {
          let templates = res.data.Data.map((template, index) => {
            return {
              label: template.CategoryName,
              value: template.Id,
              subCategory:
                !isEmpty(template.TemplateDetailData) &&
                template.TemplateDetailData.map((detail, index) => {
                  return {
                    TypeName: detail.TypeName,
                    ResponsiblePerson: {
                      value: detail.ResponsiblePersonId,
                      label: detail.ResponsiblePersonName,
                    },
                    DueDate: "",
                    Note: "",
                    Id: detail.Id,
                  };
                }),
            };
          });
          dispatch({
            type: GET_ON_BOARDING_ACCESS_ID_CATEGORIES,
            payload: templates,
          });
        }
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get employee salary template details",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetChecklistItems = (employeeId) => async (dispatch) => {
  await axios
    .get(`v1/onBoarding/checklist`)
    .then((res) => {
      if (res.data) {
        if (!isEmpty(res.data.Data)) {
          let templates = res.data.Data.map((template, index) => {
            let indexI = 0;
            return {
              label: template.CheckListName,
              value: template.Id,
              Id: template.Id,
              subCategory:
                !isEmpty(template.TemplateDetail) &&
                template.TemplateDetail.map((detail, index) => {
                  indexI = indexI + 1;
                  return {
                    Name: detail.Name,
                    Id: indexI,
                    Ids: index,
                    Checked: false,
                  };
                }),
            };
          });
          dispatch({
            type: GET_ON_BOARDING_CHECKLIST_CATEGORIES,
            payload: templates,
          });
        }
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to get Employee Onboarding Checklist Items",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const SaveAccessIds = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_ACCESSID,
    payload: false,
    requesting: true,
  });
  await axios
    .post(
      `v1/employee-onBoarding/access-id-categories/${data.OnBoardRefId}`,
      data.AccessIds
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_ACCESSID,
          payload: response.data,
          requesting: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee's Access Ids",
        },
      };
      dispatch({
        type: SAVE_ONBOARDING_ACCESSID,
        payload: false,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};

export const SaveCheckLists = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_CHECKLISTS,
    payload: false,
    requesting: true,
  });
  await axios
    .post(`v1/employee-onBoarding/checklist/${data.OnBoardRefId}`, data.CheckLists)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_CHECKLISTS,
          payload: response.data,
          requesting: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee's Checklists",
        },
      };
      dispatch({
        type: SAVE_ONBOARDING_CHECKLISTS,
        payload: false,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};
export const SaveCheckListsAndFinish = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ONBOARDING_CHECKLISTS,
    payload: false,
    requesting: true,
  });
  await axios
    .post(`v1/employee-onBoarding/checklist-finish/${data.OnBoardRefId}`, data.CheckLists)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_ONBOARDING_CHECKLISTS,
          payload: response.data,
          requesting: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee's Checklists",
        },
      };
      dispatch({
        type: SAVE_ONBOARDING_CHECKLISTS,
        payload: false,
        requesting: false,
      });
      toastMessage(dispatch, response);
    });
};

export const saveEmployeeIntroduction = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_INTRO,
    payload: false,
  });
  await axios
    .put(`v1/onBoarding/employee/personalBio/${data.onboardId}`, data)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SAVE_EMPLOYEE_INTRO,
          payload: response.data,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message
            ? err.message
            : "Failed to Save Onboarding Employee's Introduction",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const approveEmployeeOnboarding =
  (onboardId, GetEmployeeDataForRequestUrl) => async (dispatch) => {
    dispatch({
      type: APPROVE_EMPLOYEE_ONBOARDING,
      payload: onboardId,
    });
    const data = GetEmployeeDataForRequestUrl();
    await axios
      .put(`v1/onBoarding/approve-employee/${onboardId}`)
      .then((response) => {
        if (response.status) {
          dispatch(GetOnBoardingEmployeeList(data));
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Save Onboarding Employee's Introduction",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const completeOnboardingEmployee =
  (onboardId, GetEmployeeDataForRequestUrl) => async (dispatch) => {
    dispatch({
      type: COMPLETE_ONBOARDING_EMPLOYEE,
      payload: onboardId,
    });
    const data = GetEmployeeDataForRequestUrl();
    await axios
      .put(`v1/onBoarding/employees/onboarding-complete/${onboardId}`)
      .then((response) => {
        if (response.status) {
          dispatch(GetOnBoardingEmployeeList(data));
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message
              ? err.message
              : "Failed to Save Onboarding Employee's Introduction",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const sendOnboardingEmail =
  (onboardId, GetEmployeeDataForRequestUrl) => async (dispatch) => {
    dispatch({
      type: SEND_ONBOARDING_EMAIL,
      payload: true,
    });
    var res = await post(
      `v1/onBoarding/send-email/${onboardId}`,
      dispatch,
      null,
      false
    ).catch((ex) => {});

    dispatch({
      type: SEND_ONBOARDING_EMAIL,
      payload: false,
    });

    const data = GetEmployeeDataForRequestUrl();

    if (res.Status) dispatch(GetOnBoardingEmployeeList(data));
    toastMessage(dispatch, res);
  };

export const resendOnboardingEmail =
  (onboardId, GetEmployeeDataForRequestUrl) => async (dispatch) => {
    dispatch({
      type: RESEND_ONBOARDING_EMAIL,
      payload: true,
    });
    var res = await post(
      `v1/onBoarding/resend-email/${onboardId}`,
      dispatch,
      null,
      false
    ).catch((ex) => {});

    dispatch({
      type: RESEND_ONBOARDING_EMAIL,
      payload: false,
    });

    const data = GetEmployeeDataForRequestUrl();

    if (res.Status) dispatch(GetOnBoardingEmployeeList(data));
    toastMessage(dispatch, res);
  };
