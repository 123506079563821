import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "components/form";
import isEmpty from "isEmpty";
import "../style.scss";
import {InputValidator} from "helpers/inputValidator";
import Button from "components/button";
import Title from "../../../common/title";
import {
  getStaffODLoanPurposeList,
  saveStaffPersonalLoanRequest,
  updateStaffPersonalLoanRequest,
  getStaffPersonalLoanDetails,
  getQualifiedApprovers,
} from "../../helpers/action";
import RouteEnum from "constants/routeEnum";
import {DisplayClientSideMessage} from "actions/commonAction";

const purposeOptions = [
  {label: "Repair & Maintainence of House", value: 1},
  {label: "Purchase of Household Furniture, Appliances", value: 2},
  {label: "Performing Rituals", value: 3},
  {label: "Others (please specify)", value: 4},
];
export class StaffPersonalLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RequestAmount: "",
      Purpose: "",
      OtherPurpose: "",
      Note: "",
      RepayIn: "",
      EditView: false,
      ForwardTo: "",
      isSelfApplied: true,
      loanType: "1",
      errors: {},
    };
  }

  async componentDidMount() {
    let {
      getStaffODLoanPurposeList,
      getStaffPersonalLoanDetails,
      match,
      loanApproverList,
      user: {EmployeeId},
      getQualifiedApprovers,
      location,
    } = this.props;
    let loanType = location?.state?.data || "1";

    getStaffODLoanPurposeList();
    const {id} = match.params;
    // if (isEmpty(loanApproverList)) {
    await getQualifiedApprovers(EmployeeId);
    // }
    loanApproverList = this.props.loanApproverList;

    loanApproverList =
      // loanApproverList && loanApproverList.filter((loan) => loan.IsHR == true);
      loanApproverList && loanApproverList.filter((loan) => loan.Role == 1);
    if (id) await getStaffPersonalLoanDetails(id, loanType);
    let {staffPersonalLoanDetail} = this.props;
    if (id && staffPersonalLoanDetail) {
      // let ForwardTo = [];
      // staffPersonalLoanDetail.LoanForwards.forEach((loan) => {
      //   ForwardTo.push(loan.ForwardTo);
      // });
      let isSelfApplied = EmployeeId == staffPersonalLoanDetail?.EmployeeId;

      this.setState({
        ...staffPersonalLoanDetail,
        EditView: true,
        Id: id,
        ForwardTo: staffPersonalLoanDetail.ForwardTo
          ? staffPersonalLoanDetail.ForwardTo
          : "",
        // ForwardTo,
        loanApproverList,
        EmployeeId: staffPersonalLoanDetail.EmployeeId,
        isSelfApplied,
        loanType,
      });
    } else this.setState({loanApproverList, loanType});
  }

  onChange = (name, value) => {
    this.setState({[name]: value}, () => {
      this.updateErrors(name);
    });
  };

  updateErrors = async (name) => {
    let {errors, Purpose} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      if (Purpose == "") {
        errors = {
          ...errors,
          Purpose: "Required",
        };
      } else {
        errors = {
          ...errors,
          Purpose: false,
        };
      }
      this.setState({errors});
    }
  };

  handleFormSubmit = async (IsDraft) => {
    const {
      Id,
      RequestAmount,
      Purpose,
      OtherPurpose,
      Note,
      EditView,
      RepayIn,
      ForwardTo,
      isSelfApplied,
      loanType,
    } = this.state;
    let errors = await InputValidator(document, this.state);
    if ((!isEmpty(errors) && !IsDraft) || Purpose == "") {
      if (this.state.Purpose == "") {
        errors = {
          ...errors,
          Purpose: true,
        };
      }
      this.setState({errors});
      return;
    }
    const {
      updateStaffPersonalLoanRequest,
      saveStaffPersonalLoanRequest,
      DisplayClientSideMessage,
      user: {EmployeeId},
      history,
    } = this.props;
    if (RepayIn > 999) {
      DisplayClientSideMessage("Installments should be less than 999");
      return;
    }

    if (Purpose == "" && !IsDraft) {
      DisplayClientSideMessage("Please Select your loan purpose");
      return;
    }
    let staffODLoanData = {
      Id: EditView ? Id : 0,
      EmployeeId: EditView ? this.state.EmployeeId : EmployeeId,
      RequestAmount,
      Purpose: parseInt(Purpose),
      OtherPurpose,
      Note,
      RepayIn,
      ForwardTo,
      IsDraft,
    };
    if (EditView) await updateStaffPersonalLoanRequest(staffODLoanData, loanType);
    else await saveStaffPersonalLoanRequest(staffODLoanData, loanType);
    const {staffPersonalLoanSaved} = this.props;
    if (staffPersonalLoanSaved) {
      if (isSelfApplied) history.push(RouteEnum.EmployeeLoanList);
      else history.push(RouteEnum.LoanManagement);
    }
  };

  render() {
    let {
      RequestAmount,
      Purpose,
      Note,
      RepayIn,
      OtherPurpose,
      EditView,
      loanApproverList,
      ForwardTo,
      errors,
      isSelfApplied,
      IsDraft,
      loanType,
    } = this.state;
    const {
      staffODLoanPurposeList,
      user: {EmployeeId},
      isLoading,
    } = this.props;
    loanApproverList = loanApproverList?.filter((appr) => appr.Id != EmployeeId);
    return (
      <div className="personal">
        <div className="personal-container">
          {/* <Title name="Staff Personal Loan Request" /> */}
          <div className="homeloan wd-60">
            <div className="homeloan-body">
              <div className="homeloan-header mb-bg">
                <h3>
                  Staff {loanType == "1" ? "Personal Loan" : "Advance Salary"} Request
                </h3>
              </div>
              <div className="homeloan-section-content mb-md">
                <div className="text-md mb-sm">
                  <span>
                    Staff {loanType == "1" ? "Personal Loan" : "Advance Salary"} Request
                    Amount *
                  </span>
                </div>
                <Form
                  name="RequestAmount"
                  value={RequestAmount}
                  onChange={this.onChange}
                  type="number"
                  validators={["required", "posNumbers"]}
                  error={errors && errors.RequestAmount}
                  width={"30%"}
                />
              </div>
              <div className="homeloan-header mb-sm">
                <div className="text-md bold-500 mb-sm">
                  <span>Purpose of the Loan *</span>
                  {/* <span>Required</span> */}
                </div>
              </div>
              <div className="homeloan-section-content homeloan-checkboxes-od flex-column">
                {staffODLoanPurposeList &&
                  staffODLoanPurposeList.map((radio, index) => {
                    return (
                      <label class="checkbox-container">
                        {radio.label}
                        <input
                          type="checkbox"
                          checked={radio.value == Purpose}
                          onChange={() => this.onChange("Purpose", radio.value)}
                        />
                        <span class="checkmark"></span>
                      </label>
                    );
                  })}
              </div>
              {errors && errors.Purpose && (
                <div className="homeloan__checkbox__required text-red mt-md">
                  Required
                </div>
              )}
              {Purpose == "4" && (
                <div className="homeloan-otherPurpose mt-md">
                  <Form
                    name="OtherPurpose"
                    value={OtherPurpose}
                    onChange={this.onChange}
                    validators={["required"]}
                    width={"47%"}
                    error={errors && errors.OtherPurpose}
                  />
                </div>
              )}
              <div className="homeloan-section-content mt-bg">
                <div className="text-md mb-sm">
                  <span>I'll repay the loan in</span>
                </div>
                <div className="flex">
                  <Form
                    name="RepayIn"
                    value={RepayIn}
                    onChange={this.onChange}
                    width={"18%"}
                    type="number"
                    validators={["required", "posNumbers", "posMonths"]}
                    error={errors && errors.RepayIn}
                    minValue={1}
                  />
                  <div className="text-md">
                    <span>equal installments along with interest from my salary</span>
                  </div>
                </div>
              </div>
              {(!EditView || IsDraft) && (
                <div className="homeloan-propertydetail-section flex mt-md">
                  <Form
                    formName="checkBoxGroup"
                    name="ForwardTo"
                    label="Human Resource Department"
                    checkGroupArray={loanApproverList}
                    checkedGroupValue={ForwardTo}
                    onChange={(name, value) => this.onChange(name, value)}
                    multiple={false}
                    validators={["required"]}
                    error={errors && errors.ForwardTo}
                    disabled={EditView && !IsDraft}
                    width="100%"
                  />
                </div>
              )}
              <div className="homeloan-view-recommended text-black mt-sm">
                <h5>Notes</h5>
                <Form
                  type="textArea"
                  name="Note"
                  value={Note}
                  onChange={this.onChange}
                  width={"50%"}
                />
              </div>
            </div>
            <div className="homeloan-section-footer justify-between flex">
              <div className="homeloan-section-footer-item">
                <Button
                  bg="subtle"
                  onClick={() => this.props.history.goBack()}
                  title="Cancel"
                />
              </div>
              {/* <div className="homeloan-section-footer-item">
                <Button bg="primary" title="Previous" />
              </div>
              <div className="homeloan-section-footer-item homeloan-section-footer-next_button center-text">
                <Button bg="primary" title="Next" />
              </div> */}
              <div className="homeloan-section-footer-item flex-end flex">
                {isSelfApplied && (
                  <div className="homeloan-section-footer-item mr-md">
                    <Button
                      bg="primary"
                      onClick={() => this.handleFormSubmit(true)}
                      title="Save as Draft"
                      isDisabled={isLoading}
                    />
                  </div>
                )}
                <Button
                  bg="primary"
                  title="Send Request"
                  onClick={() => this.handleFormSubmit(false)}
                  isDisabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  staffODLoanPurposeList: state.loanReducer.staffODLoanPurposeList,
  staffPersonalLoanDetail: state.loanReducer.staffPersonalLoanDetail,
  staffPersonalLoanSaved: state.loanReducer.staffPersonalLoanSaved,
  loanApproverList: state.loanReducer.loanApproverList,
  isLoading: state.loanReducer.isLoading,
});

const mapDispatchToProps = {
  getStaffODLoanPurposeList,
  saveStaffPersonalLoanRequest,
  updateStaffPersonalLoanRequest,
  getStaffPersonalLoanDetails,
  getQualifiedApprovers,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffPersonalLoan);
