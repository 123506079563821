import {
  CHECK_CLOCK_IN_OUT_AUTHORITY,
  GET_ATTENDANCE_REPORT,
  GET_ATTENDANCE_BY_DATE,
  SAVE_ATTENDANCE_TIME,
  SAVE_ATTENDANCE_NOTE,
} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";
import {catchError} from "helpers";
import {loadingHeaders} from "helpers/getHeaders";
import {Date_Format, startDatetime} from "helpers/dateFormat";

const urlPrefix = "/v1/leave-time/attendance/";

export const CheckClockInOutAuthority = () => async (dispatch: any) => {
  await axios
    .get(`${urlPrefix}is/manual`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: CHECK_CLOCK_IN_OUT_AUTHORITY,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const SaveAttendanceTime =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .post(`${urlPrefix}manual?isCheckIn=${isCheckIn}`, param)
      .then((resp) => {
        const response = resp.data;
        // if (response.Status) {
        //   dispatch({
        //     type: SAVE_ATTENDANCE_TIME,
        //     isCheckIn: isCheckIn,
        //     clockTime: param.ManualVisibleTime,
        //   });
        // }
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const SaveAttendanceNote =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .post(`${urlPrefix}comments?isCheckIn=${isCheckIn}`, param)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: SAVE_ATTENDANCE_NOTE,
            isCheckIn: isCheckIn,
            clockNote: param.note,
          });
        }
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const UpdateAttendanceNote =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .put(`${urlPrefix}comments?isCheckIn=${isCheckIn}`, param)
      .then((resp) => {
        const response = resp.data;
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const GetAttendanceReport =
  (fromDate, toDate, attPeriod) => async (dispatch: any) => {
    // let startDate = Date_Format(fromDate);
    // let endDate = Date_Format(toDate);
    await axios
      .get(
        `${urlPrefix}report?startDate=${fromDate}&endDate=${toDate}&attPeriod=${attPeriod}`
      )
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: GET_ATTENDANCE_REPORT,
            payload: response.Data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const GetAttendanceByDate =
  (fromDate, toDate, employeeId = null) =>
  async (dispatch) => {
    const startDate = Date_Format(fromDate);
    const endDate = Date_Format(toDate);
    await axios
      .get(
        `/v1/leave-time/time/line?startDate=${startDate}&endDate=${endDate}&employeeId=${employeeId}`
      )
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          // let formatedData = [];
          // response.Data.map((d) => {
          //   formatedData.push({
          //     date: d.DateEng,
          //     inTime: d.InTime,
          //     outTime: d.OutTime,
          //   });
          // });
          dispatch({
            type: GET_ATTENDANCE_BY_DATE,
            payload: response.Data,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };
