import React from "react";
import {contentTypes} from "constants/enums";
import {FaRegFileImage, FaRegFilePdf, FaRegFileVideo, FaYoutube} from "react-icons/fa";
import {FiFileText} from "react-icons/fi";
import {ImAttachment} from "react-icons/im";
import {BsFillQuestionSquareFill} from "react-icons/bs";
import questionTypeOptions from "scenes/lms/helpers/questionTypeOptions";
import {v4 as uuidv4} from "uuid";

interface contentInter {
  title: string;
  icon: any;
  id: number | string;
  type: string;
}

export const contents: Array<contentInter> = [
  {
    title: "Text",
    icon: <FiFileText />,
    id: contentTypes.text.id,
    type: contentTypes.text.title,
  },
  {
    title: "Image",
    icon: <FaRegFileImage />,
    id: contentTypes.image.id,
    type: contentTypes.image.title,
  },
  {
    title: "Video",
    icon: <FaRegFileVideo />,
    id: contentTypes.video.id,
    type: contentTypes.video.title,
  },
  {
    title: "Video Link",
    icon: <FaYoutube />,
    id: contentTypes.video_link.id,
    type: contentTypes.video_link.title,
  },
  {
    title: "PDF",
    icon: <FaRegFilePdf />,
    id: contentTypes.pdf.id,
    type: contentTypes.pdf.title,
  },
  {
    title: "Attachment",
    icon: <ImAttachment />,
    id: contentTypes.attachment.id,
    type: contentTypes.attachment.title,
  },
];

export const questionContent = {
  title: "Question",
  icon: <BsFillQuestionSquareFill />,
  id: 6,
  type: contentTypes.questions,
};

export const getNewQuestionObject = (sectionId) => {
  let obj = {
    question: "",
    questionType: questionTypeOptions[0],
    section: sectionId,
    radioOptions: null,
    answerOptions: [
      {isChecked: false, answerOption: "", marks: 0},
      {isChecked: false, answerOption: "", marks: 0},
    ],
    isRandomised: false,
    selectionFrom: 0,
    selectionTo: 0,
  };
  return {...obj};
  //   return JSON.parse(JSON.stringify(obj));
};

export const getNewRadioOption = () => {
  let obj = {id: "", isChecked: false, answerOption: "", point: 0};
  return {...obj};
};
