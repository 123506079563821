import React from "react";
import {connect} from "react-redux";
import RequestButton from "components/common/commonRequestButton";
import LoanButton from "components/common/commonLoanModuleButton";
import SBILoanButton from "components/common/commonSBILoanButton";
import {Features} from "helpers/getPermissions";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import styled from "styled-components";

const CommonRouteButtons = styled.div`
  display: flex;
  gap: 0.25rem;
`;

function RequestAndAssign(props: any) {
  const {user, isRecommenderOrApprover, companyInformation, showUserRequest} = props;
  const features = Features();
  // console.log({props});

  return (
    <>
      {user.RoleName === "Employee" && showUserRequest ? (
        <CommonRouteButtons>
          {isRecommenderOrApprover &&
            (isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover ||
              isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover ||
              isRecommenderOrApprover.IsTravelReviewerOrApprover) && (
              <RequestButton
                buttonTitle="Assign Request"
                isAssign={true}
                isLeaveTimeReviewerOrApprover={
                  isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover
                }
                isAllowanceOvertimeReviewerOrApprover={
                  isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover
                }
                isTravelReviewerOrApprover={
                  isRecommenderOrApprover.IsTravelReviewerOrApprover
                }
                employeeId={user.EmployeeId}
              />
            )}
          <RequestButton
            buttonTitle="New Request"
            isAssign={false}
            isTravelReviewerOrApprover={true}
          />
          {isFeatureEnabledValue(features.Loan) &&
            (companyInformation?.CompanyId == 104 ||
              companyInformation.CompanyId == 0) && (
              <LoanButton buttonTitle="Loan Request" isAssign={false} />
            )}
          {isFeatureEnabledValue(features.Loan) &&
            (companyInformation?.CompanyId == 105 ||
              companyInformation.CompanyId == 0) && (
              <SBILoanButton buttonTitle="SBI Loan Request" isAssign={false} />
            )}
        </CommonRouteButtons>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  isRecommenderOrApprover: state.auth.isRecommenderOrApprover,
  user: state.auth.user,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RequestAndAssign);
