import React, {useState} from "react";
import {LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";
import {RiArrowDownLine, RiArrowUpLine, RiEdit2Line} from "react-icons/ri";
import classnames from "classnames";
import AddEditQuestion from "../editQuestion";
import {useDispatch} from "react-redux";
import {deleteCourseQuestion, updateQuestionOrder} from "scenes/lms/helpers/action";
import DeleteBtn from "../../../common/deleteBtn";
import {MdDelete} from "react-icons/md";
import {BsArrowDown, BsArrowUp, BsPencilSquare} from "react-icons/bs";
import {HiArrowNarrowDown, HiArrowNarrowUp} from "react-icons/hi";
import classNames from "classnames";
import {FiEdit} from "react-icons/fi";
import SvgIcons from "components/icons/svgIcons";
const QuestionContainer = (props) => {
  const {
    item,
    sectionIndex,
    questionIndex,
    setSections,
    totalData,
    setEnableUpdateQuestion,
    enableUpdateQuestion,
  } = props;
  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    await dispatch(deleteCourseQuestion({coursePageId: props.pageId, id}));
  };
  const setIsEdit = (val) => {
    setEdit(val);
    setEnableUpdateQuestion(!val);
  };

  const handleOrderBtnClick = (type) => {
    let newOrder = questionIndex + 1;
    if (type === "up") newOrder--;
    else newOrder++;

    let param = {coursePageId: props.pageId, questionId: item.id, newOrder};
    dispatch(updateQuestionOrder(param));
  };
  return (
    <div className={classNames("main-question", {active: isEdit})}>
      {isEdit ? (
        <AddEditQuestion
          {...props}
          onCancel={() => setIsEdit(false)}
          item={{...item}}
          courseId={props.courseId}
          pageId={props.pageId}
        />
      ) : (
        <>
          <div className="lms-questions__item-action">
            <DeleteBtn
              title="Delete question!!"
              onConfirm={() => handleDelete(item.id)}
              className="course-content__icon"
            />
            {totalData != questionIndex + 1 && (
              <div
                onClick={() => handleOrderBtnClick("down")}
                className="course-content__icon"
              >
                <SvgIcons name="downarrow" color="#8d8d8d" size="14px" />
              </div>
            )}
            {questionIndex > 0 && (
              <div
                onClick={() => handleOrderBtnClick("up")}
                className="course-content__icon"
              >
                <SvgIcons name="uparrow" color="#8d8d8d" size="14px" />
              </div>
            )}
            <div
              onClick={() => enableUpdateQuestion && setIsEdit(true)}
              className={classNames("course-content__icon", {
                "lms-disable": !enableUpdateQuestion,
              })}
            >
              <SvgIcons
                name="edit"
                color={enableUpdateQuestion ? "#8d8d8d" : "#d0d0d0"}
                size="14px"
              />
            </div>
          </div>
          <div className="lms-questions__q">{item.question}</div>

          {(item?.questionType?.value === LMSQuestionTypeEnum.ShortText ||
            item?.questionType?.value === LMSQuestionTypeEnum.LongText) && (
            <div className="lms-questions__box"></div>
          )}
          {(item?.questionType?.value === LMSQuestionTypeEnum.RadioButton ||
            item?.questionType?.value === LMSQuestionTypeEnum.CheckBox) && (
            <div className="lms-questions__answers">
              {item.answerOptions &&
                item.answerOptions.map((ans, index) => {
                  return (
                    <div className="lms-questions__answer" key={index}>
                      <div
                        className={classnames("lms-questions__answer-circle", {
                          active: ans?.isChecked,
                          "check-box":
                            item?.questionType?.value === LMSQuestionTypeEnum.CheckBox,
                        })}
                      ></div>
                      <div className="lms-questions__answer-text">{ans.answerOption}</div>
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default QuestionContainer;
