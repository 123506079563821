import React, { useState } from "react";
import Modal from "components/modal";
import { MdDelete } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import SvgIcons from 'components/icons/svgIcons';
const DeleteBtn = (props) => {
  const { className, title, onConfirm } = props;
  const [state, setState] = useState({ show: false });
  return (
    <>
      <div onClick={() => setState({ show: true })} className={className}>

        <SvgIcons name="trash" color="#8d8d8d" size="14px" />
      </div>
      <Modal
        type="confirm"
        title={title}
        func={() => onConfirm && onConfirm()}
        open={state.show}
        modalMessage="Are you sure you want to delete?"
        onModalClose={() => setState({ show: false })}
      />
    </>
  );
};
export default DeleteBtn;
