import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteRosterShift,
  getRosterShitsList,
  updateRosterShiftArchive,
} from "../helpers/action";
import Button from "components/button";
import Modal from "components/modal";
import AddShift from "./addShift";
import {MdAdd} from "react-icons/md";
import Confirm from "components/common/commonConfimation";
import "./styles.scss";
import classNames from "classnames";
import Dropdown from "components/dropdown";
import Icons from "components/icons/svgIcons";
import {Tabs, Tab} from "components/tabs/index.d";
import ShiftTable from "./shiftTable";

const ButtonCell = (props) => {
  const {
    getShiftList,
    row: {original},
    onEdit,
  } = props;

  const dispatch = useDispatch();
  // IsArchived

  const onDelete = async () => {
    const res: any = await dispatch(deleteRosterShift(props?.value));
    if (res && res?.Status) {
      getShiftList();
    }
  };
  const onArchive = async () => {
    const res: any = await dispatch(updateRosterShiftArchive(props?.value));
    if (res && res?.Status) {
      getShiftList();
    }
  };

  const onEditClicked = (e) => {
    onEdit(original);
  };
  const isArc = original?.IsArchived;
  return (
    <div className="shifts-table__col">
      <div className="shifts-table__links">
        <Button isDisabled={isArc} bg="primary" onClick={onEditClicked}>
          Edit
        </Button>
        {!isArc && (
          <Dropdown>
            <span onClick={() => onArchive()}>Archive</span>
            <Confirm action={onDelete}>
              <span>Delete</span>
            </Confirm>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

const ArchivedCell = ({value}) => {
  return (
    <div
      className={classNames("shifts-table__archive", {
        "shifts-table__archive-err": value,
      })}
    >
      {/* {!value ? <IoCheckmarkCircleOutline /> : <IoIosCloseCircleOutline /> } */}
      {value ? <Icons name="archive" size={14} /> : <Icons name="check" size={14} />}
    </div>
  );
};
const TitleCell = ({value}) => <div title={value}>{value}</div>;

const initialState = {
  editData: null,
  modelTitle: "Add Shift",
  open: false,
  activeTab: "ActiveShift",
};

function Shifts() {
  const [state, setState] = useState(initialState);
  const onModalClose = () => setState({...state, open: false, editData: null});
  const dispatch = useDispatch();

  const getShiftList = async (param) => await dispatch(getRosterShitsList(param));

  const cols = [
    {
      Header: "Shift Name",
      accessor: "Name",
      Cell: TitleCell,
      width: 200,
    },
    {
      Header: "Short Name",
      accessor: "ShortName",
      width: 120,
    },
    {
      Header: "Begin Time",
      accessor: "BeginsAt",
      width: 160,
    },
    {
      Header: "End Time",
      accessor: "EndsAt",
      width: 160,
    },
    {
      Header: "Hours",
      accessor: "Duration",
      width: 160,
    },
    {
      Header: "State",
      accessor: "IsArchived",
      Cell: ArchivedCell,
      width: 120,
    },
  ];
  const activeCols = [
    ...cols,
    {
      Header: "Actions",
      accessor: "Id",
      Cell: (val) => (
        <ButtonCell
          {...val}
          getShiftList={() =>
            getShiftList({
              page: 0,
              size: 10,
              isArchived: state.activeTab == "ArchivedShift",
            })
          }
          onEdit={handleEditClicked}
        />
      ),
      width: 160,
    },
  ];

  const handleEditClicked = (obj) => {
    setState({...state, editData: obj, open: true});
  };

  return (
    <div className="shifts">
      <div className="shifts-container">
        <div className="shifts-title">Shifts</div>
        <div className="shifts-adds mb-sm">
          <Button
            title="Add Shift"
            bg="primary"
            buttonType="icon-button"
            icon={<MdAdd />}
            onClick={() => setState({...state, editData: null, open: true})}
          />
        </div>

        <Tabs noBackground activeTab={state.activeTab}>
          <Tab
            name="ActiveShift"
            id="1"
            label="Active"
            onClick={() => setState({...state, activeTab: "ActiveShift"})}
          >
            <ShiftTable cols={activeCols} getShiftList={getShiftList} />
          </Tab>
          <Tab
            name="ArchivedShift"
            id="2"
            label="Archived"
            onClick={() => setState({...state, activeTab: "ArchivedShift"})}
          >
            <ShiftTable cols={cols} isArchived={true} getShiftList={getShiftList} />
          </Tab>
        </Tabs>
      </div>
      <Modal
        open={state.open}
        onModalClose={onModalClose}
        title={state.editData ? "Update Shift" : "Add Shift"}
      >
        <AddShift onModalClose={onModalClose} editData={state.editData} />
      </Modal>
    </div>
  );
}

export default Shifts;
