import React, {useState, useEffect} from "react";
import Button from "components/button";
import Modal from "components/modal";
import {NavLink, Link} from "react-router-dom";
import {BsFillCaretDownFill} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import SvgIcons from "components/svgIcons";
import SvgIcon from "components//icons/svgIcons";
import {Features} from "helpers/getPermissions";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import TimeRequestForm from "scenes/employee/time/timeRequest";
import LeaveRequestForm from "scenes/employee/leaves/leaveRequest/form";
import {useDispatch, useSelector} from "react-redux";
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";

// import {GetTimeApprovalRecommendedForEmployee} from "src/scenes/employee/time/action";

function CommonRequestButton(props) {
  const {
    user,
    baseUrl,
    hrCommonSettings: {
      EmpDashboardShowTimeRequest,
      EmpDashboardShowOvertimeRequest,
      EmpDashboardShowAllowanceRequest,
    },
  } = useSelector((state: any) => state.auth);
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
  const history = useHistory();
  const {
    REACT_APP_IS_LEAVE_OLD_URL,
    REACT_APP_IS_TIME_OLD_URL,
    REACT_APP_IS_OVERTIME_OLD_URL,
    REACT_APP_IS_TRAVEL_OLD_URL,
    REACT_APP_IS_ALLOWANCE_OLD_URL,
  } = process.env;
  const getLink = (type) => {
    switch (type) {
      case "employee":
        return base + "Employee/Dashboard.aspx";
      case "leave":
        return base + "employee/LeaveRequest.aspx";
      case "time":
        return base + "employee/EmpAttTimeRequestList.aspx";
      case "allowance":
        return base + "employee/overtimerequester.aspx";
      case "overtime":
        return base + "employee/allowancerequest.aspx";
      case "travel":
        return REACT_APP_IS_TRAVEL_OLD_URL === "true"
          ? base + "travelrequest/TravelRequestListingAdminView.aspx"
          : "/travel/create_travel_request";
        return "/";
    }
  };
  const features = Features();
  const [showTimeRequestForm, setShowTimeRequestForm] = useState(false);
  const [showOvertimeRequestForm, setShowOvertimeRequestForm] = useState(false);
  const [isLeaveRequestFormShown, showLeaveRequestForm] = useState(false);
  const [isAllowanceRequestFormShown, showAllowanceRequestForm] = useState(false);

  const dispatch = useDispatch();

  const {timeRequestApprovalRecommended} = useSelector(
    (state: any) => state.employeeTimeReducer
  );

  // console.log({timeRequestApprovalRecommended});

  // useEffect(() => {
  //   if (props.employeeId) {
  //     dispatch(GetTimeApprovalRecommendedForEmployee(props.employeeId));
  //   }
  // }, []);

  const sendDataToNewTab = () => {
    localStorage.setItem(
      "travelRequestDataToNewTab",
      JSON.stringify({
        selectedTabId: -1,
        isAssign: props.isAssign,
      })
    );
  };

  const handleTimeRequest = () => {
    // if (user?.IsShiftEmployee) {
    //   history.push("/roster/employee-attendance");
    // }
    REACT_APP_IS_TIME_OLD_URL === "true"
      ? window.open(getLink("time"))
      : setShowTimeRequestForm(true);
  };

  return (
    <>
      <Button
        buttonType="drop-button"
        title={!props.isAssign ? props.buttonTitle : ""}
        customDropIcon={<BsFillCaretDownFill style={{fontSize: 15}} />}
        justDrop
        bg={props.isAssign ? "primary" : "success"}
        dropComponent={
          <div className="request-drop">
            <ul className="request-drop__list">
              {(!props.isAssign ||
                (props.isAssign && props.isLeaveTimeReviewerOrApprover)) && (
                <>
                  {isFeatureEnabledValue(features.CoreHR) ? (
                    <li
                      onClick={() =>
                        REACT_APP_IS_LEAVE_OLD_URL === "true"
                          ? window.open(getLink("leave"))
                          : showLeaveRequestForm(true)
                      }
                      className="request-drop__item"
                    >
                      <div className="request-drop__item-icon">
                        <SvgIcon name="leave" size="18px" color="#1f262e" />
                      </div>
                      <div className="request-drop__item-name">
                        <span>{props.isAssign ? "Assign Leave" : "Leave Request"}</span>
                      </div>
                    </li>
                  ) : null}
                  {isFeatureEnabledValue(features.CoreHR) &&
                  EmpDashboardShowTimeRequest ? (
                    <li onClick={handleTimeRequest} className="request-drop__item">
                      <div className="request-drop__item-icon">
                        <SvgIcon name="time" size="18px" color="#1f262e" />
                      </div>
                      <div className="request-drop__item-name">
                        <span>{props.isAssign ? "Assign Time" : "Time Request"}</span>
                      </div>
                    </li>
                  ) : null}
                </>
              )}

              {(!props.isAssign ||
                (props.isAssign && props.isAllowanceOvertimeReviewerOrApprover)) && (
                <>
                  {isFeatureEnabledValue(features.CoreHR) &&
                  EmpDashboardShowAllowanceRequest ? (
                    <li
                      className="request-drop__item"
                      onClick={() => {
                        REACT_APP_IS_ALLOWANCE_OLD_URL === "true"
                          ? window.open(getLink("allowance"))
                          : showAllowanceRequestForm(true);
                      }}
                    >
                      <div className="request-drop__item-icon">
                        <SvgIcon name="allowance" size="18px" color="#1f262e" />
                      </div>
                      <div className="request-drop__item-name">
                        <span>
                          {props.isAssign ? "Assign Allowance" : "Allowance Request"}
                        </span>
                      </div>
                    </li>
                  ) : null}
                  {isFeatureEnabledValue(features.CoreHR) &&
                  EmpDashboardShowOvertimeRequest ? (
                    <li
                      className="request-drop__item"
                      onClick={() => {
                        REACT_APP_IS_OVERTIME_OLD_URL === "true"
                          ? window.open(getLink("overtime"))
                          : setShowOvertimeRequestForm(true);
                      }}
                    >
                      <div className="request-drop__item-icon">
                        <SvgIcon name="overtime" size="18px" color="#1f262e" />
                      </div>
                      <div className="request-drop__item-name">
                        <span>
                          {props.isAssign ? "Assign Overtime" : "Overtime Request"}
                        </span>
                      </div>
                    </li>
                  ) : null}
                </>
              )}

              {isFeatureEnabledValue(features.TravelManagement) &&
              props.isTravelReviewerOrApprover ? (
                <NavLink
                  to={
                    REACT_APP_IS_TIME_OLD_URL === "true"
                      ? getLink("travel")
                      : {
                          pathname: getLink("travel"),
                          state: {
                            selectedTabId: -1,
                            isAssign: props.isAssign,
                            hideCommonTab: true,
                          },
                        }
                  }
                  // target="_blank"
                  onClick={() => sendDataToNewTab()}
                  className="request-drop__item"
                >
                  <div className="request-drop__item-icon">
                    <SvgIcon name="travel" size="18px" color="#1f262e" />
                  </div>
                  <div className="request-drop__item-name">
                    <span>Travel Request</span>
                  </div>
                </NavLink>
              ) : null}
            </ul>
          </div>
        }
      >
        {props.isAssign && <SvgIcons name="PeopleArrows" color="#fff" size={28} />}
      </Button>

      {showTimeRequestForm && (
        <TimeRequestForm
          showModal={showTimeRequestForm}
          isAssign={props.isAssign}
          onModalClose={(value) => setShowTimeRequestForm(value)}
        />
      )}

      {showOvertimeRequestForm && (
        <Modal
          title={props.isAssign ? "Assign Overtime" : "Overtime Request"}
          open={showOvertimeRequestForm}
          onModalClose={() => setShowOvertimeRequestForm(false)}
          type=""
          className="time-request-modal over-time"
        >
          <OvertimeRequestForm
            isAssign={props.isAssign}
            onModalClose={() => setShowOvertimeRequestForm(false)}
          />
        </Modal>
      )}
      {isAllowanceRequestFormShown && (
        <Modal
          title={props.isAssign ? "Assign Allowance" : "Allowance Request"}
          open={isAllowanceRequestFormShown}
          onModalClose={() => showAllowanceRequestForm(false)}
          type=""
          className="modal-allowance-request allowance-request-form"
        >
          <AllowanceRequestForm
            isAssign={props.isAssign}
            closeForm={() => showAllowanceRequestForm(false)}
          />
        </Modal>
      )}

      {isLeaveRequestFormShown && (
        <LeaveRequestForm isAssign={props.isAssign} closeForm={showLeaveRequestForm} />
      )}
    </>
  );
}

export default CommonRequestButton;
