import React from "react";
import {Tooltip} from "@material-ui/core";

interface TooltipProps {
  title: string;
  placement?: any;
  children: any;
}

const MyTooltip = ({title, placement, children}: TooltipProps) => {
  return (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default MyTooltip;
