import React, {Component} from "react";
import {connect} from "react-redux";
import Loaders from "../loaders";

interface LaodingInterface {
  loading: boolean;
  loadingType: string;
}

export class Loading extends Component<LaodingInterface> {
  render() {
    const {loading, loadingType} = this.props;
    return <Loaders type={loadingType} loading={loading} />;
  }
}

const mapStateToProps = (state) => ({
  loading: state.loadingReducer.loading,
  loadingType: state.loadingReducer.loadingType,
});

export default connect(mapStateToProps, {})(Loading);
