import classnames from "classnames";
import Modal from "components/modal";
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import isEmpty from "isEmpty";
import {RejectTravelRequest as RejectTravelRequestAction} from "../actions";

class RejectTravelRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remarks: "",
      errors: {},
    };
  }

  onChange(name, value) {
    this.setState({[name]: value});
  }

  handleRejectRequest = async () => {
    const {remarks} = this.state;
    const {RequestId, StatusId} = this.props.request;
    // debugger;
    console.log(this.props);
    if (!isEmpty(remarks)) {
      let rejectData = {
        RequestId: RequestId,
        FlowHistoryDto: {
          HistoryID: 0,
          RequestId: 0,
          Status: StatusId,
          ActionBy: "",
          ActionOn: "",
          ActionRemarks: remarks,
          ActionDisplayTitle: "",
          ApprovalEmployeeId: this.props?.myDetail?.value,
        },
        props: this.props,
        type: "directReject",
      };

      await this.props.RejectTravelRequestAction(rejectData);
    } else {
      let errors = {};
      errors.remarks = "Required";
      this.setState({errors});
    }
  };
  render() {
    const {remarks, errors} = this.state;
    const {isModalShow, closeModal} = this.props;
    return (
      <Modal
        title="Reject Travel Request"
        open={isModalShow}
        onModalClose={() => closeModal(false)}
        type=""
        className="modal-travel-reject"
      >
        <form className="reject_travel_request">
          <div className="form-body">
            <div className="form-row">
              <div className="comments">
                <div className="comments-body">
                  <div
                    className={classnames({
                      "comments-remarks": true,
                      "comments-error": !isEmpty(errors.remarks),
                    })}
                  >
                    <textarea
                      //autoFocus={true}
                      onChange={(e) => this.onChange(e.target.name, e.target.value)}
                      value={remarks}
                      placeholder="Remarks...."
                      name="remarks"
                      cols="20"
                      rows=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              onClick={() => this.handleRejectRequest()}
              type="button"
              className="btn btn-red"
            >
              Reject
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  myDetail: state.commonReducer.myDetail,
});

const mapDispatchToProps = {
  RejectTravelRequestAction,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RejectTravelRequest)
);
