import React from "react";
import {BsClock} from "react-icons/bs";
const ContentTilte = ({state, course}) => {
  return (
    <div className="course-page__title">
      <h2>{state?.currentPage?.PageName}</h2>
      <div className="course-page__title-length">
        <div className="course-page__title-status">
          <BsClock />
          <span className="bold">{course?.Duration} min</span>
        </div>
        {/* <div className="course-page__title-status">
        <FaYoutube />
        <span className="bold">15 min</span>
      </div> */}
      </div>
    </div>
  );
};
export default ContentTilte;
