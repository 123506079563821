import Button from "components/button";
import ApproveButton from "components/button/approveButton";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import RouteEnum from "constants/routeEnum";
import getFormattedNumber from "helpers/getFormattedNumber";
import isEmpty from "isEmpty";
import React, {useState} from "react";
import {GoComment} from "react-icons/go";
import {MdCloudDownload} from "react-icons/md";
import {RiChatDeleteLine, RiGroupLine, RiUserLine} from "react-icons/ri";
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import SvgIcons from "components/svgIcons";
import {RoleId} from "constants/userDetails";
import {AiFillCloseCircle} from "react-icons/ai";

import EmployeeDetails from "scenes/common/employeeDetails";
import ApprovalFlow from "../../../../common/approvalFlow";
import CommentsHistory from "../../commonLayout/commentsHistory";
import RejectTravelRequest from "./rejectTravelRequest";

function TravelDetailsBlock(props) {
  let [modalOpen, setModalOpen] = useState("");
  let [isRejectModalShow, setRejectModalShow] = useState(false);
  let [selectedRequest, setSelectedRequest] = useState({});
  let [cancelModalShow, setCancelModalShow] = useState(false);
  const dispatch = useDispatch();
  const onModalShow = (id) => {
    setModalOpen(id);
  };

  const handleEditTravelRequest = (data, isEdit) => {
    let requestData = {...data, page: props.page};
    props.clearEditTravelRequest();
    let approvedList = requestData.ApprovalStatus.find(
      (x) => x.ApprovalStatus === "Approved"
    );
    requestData.update =
      props.page === "myTravelRequest" &&
      (requestData.StatusId === 1 || isEmpty(approvedList))
        ? true
        : false;

    localStorage.setItem(
      "travelRequestDataToNewTab",
      JSON.stringify({
        selectedTabId: -2,
        travelRequestId: requestData.RequestId,
        isEditPage: isEdit,
        isViewPage: !isEdit,
        title: `${requestData.Name} - ${requestData.PlaceName} (${requestData.TravelStart} - ${requestData.TravelEnd})`,
        requestDetails: requestData,
      })
    );
  };

  const handleRejectTravelRequest = (data) => {
    setRejectModalShow(true);
    setSelectedRequest(data);
  };

  const handleCancelRequest = (data) => {
    setCancelModalShow(true);
    setSelectedRequest(data);
  };

  let {handleRowChange, handleIndexChange, pageIndex, rowsPerPage, isLoading} = props;
  const roleId = RoleId();
  return (
    <>
      {props.rows.map((row, rowIndex) => {
        const employeeDetail = {
          Id: row["EmployeeId"],
          FullName: row["Name"],
          Imagepath: row["UrlPhoto"],
          Designation: row["Desingnation"],
          Branch: row["Branch"],
          Department: row["Department"],
        };
        const isEditAllowedForMyRequest =
          props.page === "myTravelRequest" &&
          isEmpty(
            row.ApprovalStatus.find(
              (x) => x.ApprovalStatus === "Approved" || x.ApprovalStatus === "Rejected"
            )
          );

        return (
          <div className="request-details" key={rowIndex}>
            <div className="request-details__header">
              <EmployeeDetails empDetails={employeeDetail} size={50} />
              <div>
                <span>{row.PlaceName}</span>
              </div>
              <div>
                <span>
                  {row.TotalTravelDays} {row.TotalTravelNight}
                </span>
              </div>
              <div>
                <span>{row.TravelStartEndDate}</span>
              </div>
              {row.IsCommented ? (
                <div className="comment">
                  <GoComment className="comment__icon"></GoComment>
                </div>
              ) : null}

              {row.StatusId === 0 ? (
                <span className="badges badges-rounded badges-warning">Draft</span>
              ) : (
                <div className="requested-on">
                  <span>Requested on: </span>
                  <span>{row.RequestedOn}</span>
                </div>
              )}
            </div>
            <div className="request-details__body">
              <div className="body-row">
                <div className="body-col">
                  {row.Reason && (
                    <div className="reason">
                      <span>{row.Reason}</span>
                    </div>
                  )}

                  {row.TravelRequestDestination.map((dest, destIndex) => {
                    return (
                      <div className="destination dest" key={destIndex}>
                        <div className="dest__row">
                          {dest.District && (
                            <span className="district">
                              {dest.District}
                              {!isEmpty(dest.District) ? "," : null}
                            </span>
                          )}

                          <span>{dest.PlaceName}</span>
                          {destIndex === 0 && !isEmpty(row.GroupEmployee) ? (
                            <div className="travel-people">
                              {row.IsGroup ? <RiGroupLine /> : <RiUserLine />}
                              <span className="travel-people__name">
                                {row.GroupEmployee.map((emp, i) => {
                                  return i + 1 === row.GroupEmployee.length
                                    ? emp
                                    : emp + ", ";
                                })}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="dest__row">
                          <span className="travel-date">{dest.Destinationstart}</span>
                          <span className="divider"> - </span>
                          <span className="travel-date">{dest.Destinationed}</span>
                        </div>
                        <div className="dest__row">
                          <span>{dest.Totaldays}</span>
                          <span className="divider"></span>
                          <span> {dest.Totalnight}</span>
                          <span className="hotel-name">{dest.HotelName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="body-col">
                  <div className="amount-row">
                    <span>Eligible Amount</span>
                    <span>{getFormattedNumber(row.EligibileAmount)}</span>
                  </div>
                  <div className="amount-row">
                    <span>Advance</span>
                    <span>{getFormattedNumber(row.Advancerequestamt)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="request-details__footer">
              {row.StatusId !== 0 ? (
                <div className="details-btn flex flex-gap-10">
                  <NavLink
                    to={{pathname: RouteEnum.UpdateTravelRequestPath}}
                    onClick={(e) => {
                      handleEditTravelRequest(row, isEditAllowedForMyRequest);
                    }}
                  >
                    <Button
                      title={isEditAllowedForMyRequest ? "Edit" : "Details"}
                      bg="subtle"
                      buttonType="icon-button"
                      leftIcon={
                        <SvgIcons
                          name={isEditAllowedForMyRequest ? "EditPen" : "DetailsList"}
                          size={16}
                        />
                      }
                    />
                  </NavLink>

                  <NavLink
                    to={{
                      pathname: RouteEnum.ExportPdfPath,
                      state: {
                        id: row.RequestId,
                        source: "travelReport",
                      },
                      search: "travelReport/" + row.RequestId,
                    }}
                  >
                    <Button
                      title="Download"
                      buttonType="icon-button"
                      leftIcon={<MdCloudDownload />}
                      bg="subtle"
                    />
                  </NavLink>

                  {isEditAllowedForMyRequest && (
                    <Button
                      bg="subtle-error"
                      buttonType="icon-button"
                      leftIcon={<AiFillCloseCircle />}
                      onClick={() => handleCancelRequest(row)}
                      title="Cancel"
                    />
                  )}

                  {(row.ApprovedBy !== null && roleId !== 1) ||
                  props.page === "myTravelRequest" ? null : (
                    <Button
                      title="Reject"
                      bg="subtle-error"
                      buttonType="icon-button"
                      leftIcon={<RiChatDeleteLine />}
                      onClick={() => handleRejectTravelRequest(row)}
                    />
                  )}
                </div>
              ) : (
                <div className="details-btn flex">
                  {isEditAllowedForMyRequest ? (
                    <Button
                      onClick={() =>
                        props.handleDelete && props.handleDelete(row.RequestId)
                      }
                      title="Delete"
                      bg="subtle-error"
                      buttonType="icon-button"
                      leftIcon={<SvgIcons name="Delete" size={16} />}
                    />
                  ) : null}
                </div>
              )}
              {!isLoading && (
                <ApprovalFlow approvalStatus={row.ApprovalStatus} rowIndex={rowIndex} />
              )}
              {row.StatusId === 0 ? (
                <NavLink
                  to={{pathname: RouteEnum.UpdateTravelRequestPath}}
                  onClick={(e) => {
                    handleEditTravelRequest(row, true);
                  }}
                >
                  <Button title="Complete" bg="primary" />
                </NavLink>
              ) : null}
              {row.ApprovedBy !== null || props.page === "myTravelRequest" ? null : ( //&&!isEmpty(row.ApprovedBy)
                <>
                  <ApproveButton
                    handleApproveRequest={async () => {
                      await props.GetApprovalAuthorities(row.StatusId, row.EmployeeId);
                      if (row.StatusId !== 0) {
                        await props.GetApprovalHistory(row.RequestId);
                      }
                      onModalShow(row.RequestId);
                    }}
                  />
                  <Modal
                    open={modalOpen === row.RequestId}
                    onModalClose={() => onModalShow("")}
                    title="Comments History"
                  >
                    <CommentsHistory
                      isApprover={props.page === "otherTravelRequest"}
                      isModal={true}
                      RequestId={row.RequestId}
                      StatusId={row.StatusId}
                      NextStepId={row.NextStepId}
                      onModalClose={onModalShow}
                      func={(data) => props.handleApprove && props.handleApprove(data)}
                      approvalAuthorities={props.approvalAuthorities}
                      approvalHistory={props.approvalHistory}
                      isEditPage={true}
                      isViewPage={false}
                    />
                  </Modal>
                </>
              )}
            </div>
            {isRejectModalShow && (
              <RejectTravelRequest
                isModalShow={isRejectModalShow}
                closeModal={setRejectModalShow}
                request={selectedRequest}
                getTravelRequest={props.getTravelRequest}
              />
            )}
            <Modal
              className="modal-travel-category"
              func={() => props.handleDelete(selectedRequest.RequestId)}
              open={cancelModalShow}
              onModalClose={() => setCancelModalShow(false)}
              title="Cancel"
              type="confirm"
            ></Modal>
          </div>
        );
      })}
      {isEmpty(props.rows) ? null : (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex - 1}
          searchedPage={{
            index: pageIndex,
            total: props.rows ? props.rows[0].TotalRows : 0,
          }}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
}

export default TravelDetailsBlock;
