import axios from 'helpers/axiosinstance';
import {
  ADD_EMPLOYEE,
  GET_EMPLOYEES,
  GET_EMPLOYEE,
  DELETE_EMPLOYEE,
  CLEAR_ERRORS,
  GET_ERRORS,
  EMPLOYEES_LOADING,
} from "actions/types";

// Add Employee
export const AddEmployee = (postData: any) => (dispatch: Function) => {
  dispatch(clearErrors());
  axios
    .post("http://192.168.0.62/NewHR/food/api/foods", postData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) =>
      dispatch({
        type: ADD_EMPLOYEE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Get Employees
export const GetEmployees = () => (dispatch: Function) => {
  dispatch(setEmployeesLoading());
  axios
    .get(`http://192.168.0.62/NewHR/api/v1/employees`)
    .then((res) => {
      dispatch({
        type: GET_EMPLOYEES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_EMPLOYEES,
        payload: null,
      })
    );
};
// Get Filtered Employees
export const PostFilter = (data: any) => (dispatch: Function) => {
  dispatch(setEmployeesLoading());
  axios
    .post(`http://192.168.0.62/NewHR/api/v1/getemployees`, data)
    .then((res) => {
      dispatch({
        type: GET_EMPLOYEES,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_EMPLOYEES,
        payload: null,
      })
    );
};

// Get Employee
export const GetEmployee = (id: any) => (dispatch: Function) => {
  dispatch(setEmployeesLoading());
  axios
    .get(`http://192.168.0.62/NewHR/food/api/foods/${id}`)
    .then((res) =>
      dispatch({
        type: GET_EMPLOYEE,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_EMPLOYEE,
        payload: null,
      })
    );
};

// Set loading state
export const setEmployeesLoading = () => {
  return {
    type: EMPLOYEES_LOADING,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
