import man from "assets/images/man.png";
import FormGroup from "components/form";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import React, {Component} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {connect} from "react-redux";
import {getUploadPath} from "constants/userDetails";
const BaseUrl = getBaseAxios() + "/";

export class Create_ApproversTab extends Component {
  handleApproverDropdown = (name, selected) => {
    let selectedApprovalList = [];
    if (!isEmpty(selected)) {
      selectedApprovalList.push(selected);
    }
    this.props.handleApproversTabState &&
      this.props.handleApproversTabState(name, selectedApprovalList);
  };

  handleRemove = (value) => {
    let {selectedApprovalList} = this.props.approverState;
    selectedApprovalList = selectedApprovalList.filter((f) => f.value != value);
    this.props.handleApproversTabState &&
      this.props.handleApproversTabState("selectedApprovalList", selectedApprovalList);
  };

  render() {
    const {isViewMode} = this.props;
    const {selectedApprovalList, selectedContributorList} = this.props.approverState;

    const {loading, value} = this.props.approvalAuthorities;
    let approvers = null;
    if (!isEmpty(value)) {
      approvers = value.AuthoritiesList;
    }

    return (
      <div className="travel-document-create">
        <div className="travel-create__title">
          <h3>Approvers</h3>
        </div>
        <div className="travel-create__body">
          <div className="travel-detail-row">
            <div className="travel-field">
              <FormGroup
                // multiple={true}
                formName="reactselect"
                name="selectedApprovalList"
                placeholder="Select approval"
                value={
                  selectedApprovalList && selectedApprovalList[0]
                    ? selectedApprovalList[0]
                    : null
                }
                options={approvers}
                onChange={this.handleApproverDropdown}
                disabled={isViewMode}
              />

              {/* <br /> */}
              {selectedApprovalList &&
                selectedApprovalList.map((approver, index) => {
                  return (
                    <div className="selected-user" key={index}>
                      <img
                        src={
                          !isEmpty(approver.Imagepath)
                            ? // ? BaseUrl + approver.Imagepath
                              getUploadPath(approver.Imagepath)
                            : man
                        }
                        className="avatar avatar-sm"
                        alt="Avatar"
                      />
                      <h5 className="selected-user__name">{approver.label}</h5>
                      {!isViewMode && (
                        <span onClick={() => this.handleRemove(approver.value)}>
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
});

export default connect(mapStateToProps, null)(Create_ApproversTab);
