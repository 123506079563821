import React, {Component} from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
} from "@syncfusion/ej2-react-grids";
import {Tooltip} from "@material-ui/core";
import Pagination from "components/common/pagination";
import OnBoardingTableRow from "./onBoardingTableRow";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Avatar from "react-avatar";
import Button from "components/button";
import {getUploadPath} from "constants/userDetails";
import {CurrentStageItem} from "./currentStageItem";
import {
  approveEmployeeOnboarding,
  completeOnboardingEmployee,
  sendOnboardingEmail,
  resendOnboardingEmail,
} from "./action";

export class OnBoardingTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      branchAndDepartment: 220,
      date: 100,
      jobRolePosition: 220,
      name: 260,
      stage: 200,
    };

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  gridTemplate(props) {
    const {history} = this.props;
    return (
      <OnBoardingTableRow
        {...props}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
      />
    );
  }
  onPackageInitiate = async (EmployeeId) => {
    await this.props.GetOnboardingEmployeeDetail(EmployeeId);
    this.props.history.push(`/admin/employee_onboarding/packages/${EmployeeId}`);
  };

  dropdownTemplate = (props) => {
    return (
      <Button
        buttonType="drop-button"
        bg="secondary"
        title={props.IsIncludePackage ? "Edit" : "Approve"}
        onClick={() =>
          props.IsIncludePackage
            ? this.props.history.push(
                `/admin/employee_onboarding/packages/${props?.EmployeeId}`
              )
            : this.props.approveEmployeeOnboarding(
                props?.EmpOnBoardId,
                this.props.GetEmployeeDataForRequestUrl
              )
        }
        dropClass={"common-drop onboarding-dropdown"}
        dropComponent={
          <ul id={"Project" + 1} className="common-drop-list">
            {props.Stage === 1 || //1:Draft, 2=Onboarding Process
            props.Stage === 2 ? (
              <li
                className={"common-drop-list__item"}
                onClick={(e) => this.props.handleConfirmDelRecord(props?.EmployeeId)}
              >
                <span className="common-drop-name">Delete</span>
              </li>
            ) : null}

            {props.Stage === 1 && props.IsIncludePackage ? (
              <li
                className={"common-drop-list__item"}
                onClick={(e) =>
                  this.props.sendOnboardingEmail(
                    props?.EmpOnBoardId,
                    this.props.GetEmployeeDataForRequestUrl
                  )
                }
              >
                <span className="common-drop-name">Send Onboarding E-mail</span>
              </li>
            ) : null}

            {props.Stage === 2 ? ( //Onboarding Process
              <>
                {props.IsIncludePackage && (
                  <li
                    className={"common-drop-list__item"}
                    onClick={(e) =>
                      this.props.resendOnboardingEmail(
                        props?.EmpOnBoardId,
                        this.props.GetEmployeeDataForRequestUrl
                      )
                    }
                  >
                    <span className="common-drop-name">Resend Onboarding E-mail</span>
                  </li>
                )}
                <li
                  className={"common-drop-list__item"}
                  onClick={(e) =>
                    this.props.completeOnboardingEmployee(
                      props?.EmpOnBoardId,
                      this.props.GetEmployeeDataForRequestUrl
                    )
                  }
                >
                  <span className="common-drop-name">Complete Onboarding</span>
                </li>
              </>
            ) : null}
          </ul>
        }
      />
    );
  };

  EmployeeProfile = (props) => {
    return (
      <div className="flex">
        <Avatar
          name={props.EmployeeName}
          size={45}
          round={true}
          src={props.EmployeeImage && getUploadPath(props.EmployeeImage)}
        />
        <Tooltip title={props?.EmployeeName} placement="top-start">
          <div
            style={{overflowX: "hidden"}}
            className="clickable ml-sm"
            onClick={(e) => this.props.handleEdit(props)}
          >
            {props.EmployeeName}
          </div>
        </Tooltip>
      </div>
    );
  };

  JobRolePosition = (props) => {
    return (
      <Tooltip title={props.Level} placement="top-start">
        <div className="flex-column" style={{overflowX: "hidden"}}>
          <span>{props.Designation}</span>
          <span style={{overflowX: "hidden"}} className="color-light">
            {props.Level}
          </span>
        </div>
      </Tooltip>
    );
  };

  Branch = (props) => {
    return (
      <Tooltip title={props?.Department} placeholder="top-start">
        <div style={{overflow: "hidden"}} className="flex-column">
          <span>{props.Branch}</span>
          <span className="color-light">{props.Department}</span>
        </div>
      </Tooltip>
    );
  };

  CurrentStage = (props) => {
    return (
      <div className="flex">
        <CurrentStageItem currentStage={props?.CurrentStage} />
      </div>
    );
  };

  render() {
    const {data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage, stageId} =
      this.props;
    return (
      <div className="table empTable">
        <div className="arrear-table">
          <div className="pending-settlement-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              toolbar={this.toolbar}
              filterSettings={false}
              id="grid"
              ref={(g) => (this.grid = g)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  filterSettings={false}
                  headerText="Employee Name"
                  template={this.EmployeeProfile}
                  // width={this.widths.name}
                />
                <ColumnDirective
                  headerText="Job Role and Position"
                  template={this.JobRolePosition}
                  // width={this.widths.jobRolePosition}
                />
                <ColumnDirective
                  headerText="Branch & Department"
                  template={this.Branch}
                  // width={this.widths.branchAndDepartment}
                />
                <ColumnDirective
                  field="Added"
                  headerText="Added"
                  // width={this.widths.date}
                />
                <ColumnDirective
                  field="JoinDate"
                  headerText="Join Date"
                  // width={this.widths.date}
                />
                <ColumnDirective
                  headerText="Current Stage"
                  template={this.CurrentStage}
                  // width={this.widths.stage}
                />
                {stageId === 1 && (
                  <ColumnDirective
                    field="IsIncludePackage"
                    headerText="Is Package Included"
                  />
                )}
                {stageId != 0 && stageId != 3 ? (
                  <ColumnDirective template={this.dropdownTemplate} />
                ) : null}
              </ColumnsDirective>
              <Inject services={[Filter]} />
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRow}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  approveEmployeeOnboarding,
  completeOnboardingEmployee,
  sendOnboardingEmail,
  resendOnboardingEmail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnBoardingTable));
