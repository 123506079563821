import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_LOGS,
    SET_NOTIFICATIONS_READ,
    SET_NOTIFICATIONS_LOADING
  } from "actions/types";
import { toastMessage } from "actions/validateAction";
import axios from 'helpers/axiosinstance';
import queryString  from 'query-string';
import isEmpty from 'isEmpty';

const getEmptyorFull = (name) =>{
    return isEmpty(name) ? '' : name;
}
//type - notification ,payroll
export const getNotifications = data => async dispatch => {
    addLoading(dispatch, true);
    let query = queryString.stringify({
            noticeType:getEmptyorFull(data.noticeType), // leave requets
            sortBy:getEmptyorFull(data.sortBy),
            requestType:getEmptyorFull(data.requestType), // '' 
            startDate:getEmptyorFull(data.startDate),
            endDate:getEmptyorFull(data.endDate),
    });
    await axios
        .get(`/v1/notices/notifications?${query}`)
        .then(res => {
            addLoading(dispatch, false);
            if (res.data.Status) {
                dispatch({ type: GET_NOTIFICATIONS, payload: res.data.Data })
            } else {
                toastMessage(dispatch, res);
            }
        })
        .catch(err => {
            addLoading(dispatch, false);
            dispatch({ type: GET_NOTIFICATIONS, payload: null })
            const response = {
                data: {
                    MessageType: "Danger",
                    Message: "Failed to get notificationa."
                }
            };
            toastMessage(dispatch, response);
        });
}

export const notificationMarkedAsRead = data => async dispatch => {
    addLoading(dispatch, true);
    let query = queryString.stringify({
        notificationId:getEmptyorFull(data.notificationId),
        isRead:getEmptyorFull(data.isRead),
        type:getEmptyorFull(data.type), 
    });
    await axios
        .get(`/v1/notices/markedAsReadUnread?${query}`)
        .then(res => {
            addLoading(dispatch, false);
            if (res.data.Status) {
                
                dispatch({ type: SET_NOTIFICATIONS_READ, payload: data })
            } else {
                toastMessage(dispatch, res);
            }
        })
        .catch(err => {
            console.log({err,a:err.response})
            addLoading(dispatch, false);
            // dispatch({ type: GET_NOTIFICATIONS, payload: null })
            const response = {
                data: {
                    MessageType: "Danger",
                    Message: "Failed to get notificationa."
                }
            };
            toastMessage(dispatch, response);
        });
}

// ​
export const getLogs = id => async dispatch => {
    addLoading(dispatch, true);
    let query = queryString.stringify({
        moduleId:getEmptyorFull(id)
    });
    await axios
        .get('/v1/ltoa/audit/log?'+query)
        .then(res => {
            addLoading(dispatch, false);
            if (res.data.Status) {
                dispatch({ type: GET_NOTIFICATIONS_LOGS, payload: res.data.Data })
            } else {
                toastMessage(dispatch, res);
            }
        })
        .catch(err => {
            addLoading(dispatch, false);
            dispatch({ type: GET_NOTIFICATIONS_LOGS, payload: null })
            const response = {
                data: {
                    MessageType: "Danger",
                    Message: "Failed to get logs."
                }
            };
            toastMessage(dispatch, response);
        });
}

const addLoading = (dispatch, loading) => {
    dispatch({
        type: SET_NOTIFICATIONS_LOADING,
        payload: loading
    })
}