import types from "constants/types";

const initialState = {
  consumerLoanPurposeList: [],
  savedConsumerLoanRequest: false,
  savedSocialLoanRequest: false,
  savedPersonalLoanRequest: false,
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.GET_CONSUMER_LOAN_PURPOSE_LIST: {
      return {
        ...state,
        consumerLoanPurposeList: action.payload,
      };
    }
    case types.MY_APPLIED_LOAN_LIST_REQUEST: {
      return {
        ...state,
        myAppliedLoanList: action.payload,
      };
    }
    case types.GET_REQUESTER_INFORMATION: {
      return {
        ...state,
        loanRequesterInformation: action.payload,
      };
    }
    case types.LOAN_SETTING_BY_TYPE: {
      return {
        ...state,
        loanSettingByType: action.payload,
      };
    }
    case types.CALCULATE_PAYMENT_SCHEDULE: {
      return {
        ...state,
        loanPaymentSchedules: action.payload,
      };
    }
    case types.GENERATE_INCOME_DEDUCTION_LIST: {
      return {
        ...state,
        incomeDeductionList: action.payload,
      };
    }
    case types.DELETE_REQUESTED_LOAN: {
      return {
        ...state,
        deletedRequestedLoan: action.payload,
      };
    }
    case types.LOAN_APPROVER_LIST: {
      return {
        ...state,
        loanApproverList: action.payload,
      };
    }
    case types.SAVE_CONSUMER_LOAN_REQUEST: {
      return {
        ...state,
        savedConsumerLoanRequest: action.payload,
      };
    }
    case types.CONSUMER_LOAN_DETAILS: {
      return {
        ...state,
        consumerLoanDetails: action.payload,
      };
    }
    case types.GET_OUTSTANDING_LOANS: {
      return {
        ...state,
        outstandingLoans: action.payload,
      };
    }
    case types.SAVE_OUTSTANDING_LOANS: {
      return {
        ...state,
        outstandingLoanSaved: action.payload,
      };
    }
    case types.SAVE_OUTSTANDING_LOANS: {
      return {
        ...state,
        outstandingLoanDeleted: action.payload,
      };
    }
    case types.SBI_LOAN_TYPES: {
      return {
        ...state,
        sbiLoanTypes: action.payload,
      };
    }
    case types.LOAN_COMMENT_HISTORY: {
      return {
        ...state,
        loanCommentHistory: action.payload,
      };
    }
    case types.SAVE_SOCIAL_LOAN_REQUEST: {
      return {
        ...state,
        savedSocialLoanRequest: action.payload,
      };
    }
    case types.DELETE_REQUESTED_LOAN: {
      return {
        ...state,
        deletedSocialLoan: action.payload,
      };
    }
    case types.SAVE_PERSONAL_LOAN_REQUEST: {
      return {
        ...state,
        savedPersonalLoanRequest: action.payload,
      };
    }
    default:
      return state;
  }
}
