import React, { useState, useEffect } from "react";
import Modal from "components/modal";
import Icon from "components/icons";
import FormGroup from "components/form";
import FormItem from 'components/formGroup/formItem';
import Button from "components/button";
import isEmpty from "isEmpty";
import { addBoard } from "../helpers/action";
import { useDispatch, useSelector } from "react-redux";
import { getDateISO } from "components/calendar/helpers/engCalendar";
import moment from "moment";
import EmployeeSearch from "components/common/employeeSearch";

interface ErrorsInterface {
  BoardName?: string;
  BoardDescription?: string;
  DueDate?: string;
}
const boardInitialState = {
  BoardId: 0,
  BoardName: "",
  BoardDescription: "",
  BoardType: 1,
  StartDate: null,
  DueDate: null,
  CreatedBy: null,
  BoardOwner: null,
  EnableRatingAndApproval: false
};
const BoardAdd = (props) => {
  const { open, onModalClose } = props;
  const [data, setData] = useState(boardInitialState);
  const [days, setDays] = useState("");
  const [boardOwner, setBoardOwner] = useState(null)


  useEffect(() => {

    if (props.data) {
      if (props.data.BoardId === 0) {

      }
      setData(props.data);
      setDays(calculateDaysRemaining(props.data.DueDate))
      var name = props.data.BoardOwnerName;
      const { UserID } = user;
      if (UserID === props.data.BoardOwner)
        name = `Me (${name})`
      setBoardOwner({ label: name, value: props.data.EmployeeId, UserId: props.data.BoardOwner })
    }
    else {
      const { EmployeeId, UserID, UserName, EmployeeName, UserImage } = user;
      
      let newData = { ...data };
      newData["BoardOwner"] = UserID;
      let empName = isEmpty(EmployeeName) ? UserName : EmployeeName
      setData(newData);
      setBoardOwner({ label: `Me (${empName})`, value: EmployeeId })
    }
  }, [props.data]);



  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  let { user } = useSelector((state: any) => ({
    user: state.auth.user,
  }));



  const isValidUser = () => {
    if (data.BoardId === 0) {
      return true
    }
    return (
      user.UserID === data.CreatedBy ||
      user.RoleName === "Administrator"
    );
  };

  const getTitle = () => {
    if (data.BoardId === 0) return "Add Board";
    return "Edit Board";
  };

  const onSave = async () => {
    const { customErrors, isValid } = onValidate(data);
    setErrors(customErrors);
    if (!isValid) {
      return false;
    }
    if (data.BoardId === 0) {
      const { UserID } = user;

      data.BoardType = props.boardType;
      if (isEmpty(data.BoardOwner) && isEmpty(boardOwner)) {
        //set default user as boardowner
        data.BoardOwner = UserID
      }
    }
    let isSuccess = await dispatch(addBoard(data));
    if (isSuccess) {
      setData(boardInitialState);
      onModalClose(false);
    }
  };

  const onValidate = (data) => {
    let customErrors: ErrorsInterface = {};
    if (isEmpty(data.BoardName)) {
      customErrors.BoardName = "Board Name field is required";
    }
    if (isEmpty(data.BoardDescription)) {
      customErrors.BoardDescription = "Board Description field is required";
    }
    if (!isEmpty(data.StartDate) && !isEmpty(data.DueDate)) {
      if (new Date(data.StartDate) > new Date(data.DueDate)) {
        customErrors.DueDate = "DueDate must be greater than Start Date";
      }
    }
    return { isValid: isEmpty(customErrors), customErrors };
  };

  const onChange = (name, value) => {
    let newData = { ...data };
    newData[name] = value;
    if (name === "DueDate") setDays(calculateDaysRemaining(value));
    setData(newData);
  };

  const onEmployeeSearchChange = (name, value) => {

    let UserId: any = null;
    if (!isEmpty(value))
      UserId = value.UserId

    let newData = { ...data };
    newData["BoardOwner"] = UserId;
    setBoardOwner(value);
    setData(newData);
  }
  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };

  const calculateDaysRemaining = (date) => {
    let res: any;
    let day = "days";
    let dayDiff = 0;
    let type = "overdue";
    if (date) {
      var dueDate = moment(date);
      var start = moment(Date()).format("MM/DD/YYYY");
      dayDiff = dueDate.diff(start, "days");

      if (dayDiff >= -1 && dayDiff <= 1) day = "day";
      if (dayDiff >= 0) type = "";
      res = `${Math.abs(dayDiff)} ${day} ${type}`;
    }
    return res;
  };

  const maxLengthValidation = (e: any) => {

    const { value, name } = e.target
    let len = value && value.trim().length || 0
    let maxlength = 254
    if (name === "BoardName") {
      maxlength = 99
    }

    if (len > maxlength) {
      e.preventDefault();
    }
  }


  return (
    <Modal
      onModalClose={onModalClose}
      open={open}
      className="board-add-modal"
      width="600px"
      title={getTitle()}
    >
      <div className="board-add">
        <div className="board-add__form">
          <div className="board-add__row">

            <FormGroup
              value={data.BoardName}
              onChange={onChange}
              label="Board Title"
              name="BoardName"
              error={errors.BoardName}
              onKeyPress={maxLengthValidation}
            />
          </div>
          <div className="board-add__row">
            <FormGroup
              formName="textarea"
              value={data.BoardDescription}
              onChange={onChange}
              label="Board Description"
              name="BoardDescription"
              error={errors.BoardDescription}
              onKeyPress={maxLengthValidation}
            />
          </div>
          <div className="board-add__row board-add__row-flex">
            <div className="board-add__row-flex-date">

              <FormGroup
                disabled={!isValidUser()}
                formName="customdateinput"
                name="StartDate"
                onChange={onChange}
                label="Start date"
                placeholder="YYYY-MM-DD"
                value={convertDateISO(data.StartDate)}
                closeOnClick
                hideAction
              />
            </div>
            <div className="board-add__row-flex-date">
              <FormGroup
                disabled={!isValidUser()}
                formName="customdateinput"
                name="DueDate"
                placeholder="YYYY-MM-DD"
                onChange={onChange}
                label="Due date"
                value={convertDateISO(data.DueDate)}
                closeOnClick
                hideAction
                error={errors.DueDate}
              />
            </div>
            <span className="board-add__row-flex-day">{days}</span>
          </div>

          <div className="board-add__row">
            <EmployeeSearch
              onChange={onEmployeeSearchChange}
              width={'100%'}
              name="BoardOwner"
              value={boardOwner}
              // placeholder="Search (3 letters..) and Select"
              label="Owner (Search And Select: Type at least 3 letters...)"
            />
            <span></span>
          </div>
          <div className="board-add__row">
            <FormGroup
              formName="switch"
              name="EnableRatingAndApproval"
              alignment="horizontal"
              rightLabel="Enable Task Rating and Owner Approval"
              onChange={onChange}
              value={data.EnableRatingAndApproval}
              checked={data.EnableRatingAndApproval}
            />
          </div>
        </div>
        <div className="board-add__footer">
          <Button title="Save" bg="primary" onClick={() => onSave()} />
          <Button
            title="Cancel"
            bg="secondary-dark"
            onClick={(e) => onModalClose(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BoardAdd;
