import {
  EMPLOYEE_ALLOWANCE_HISTORY,
  EMPLOYEE_ALLOWANCE_TYPELIST,
  EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST,
  EMPLOYEE_SINGLE_ALLOWANCE_REQUEST,
  SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";
import {catchError} from "helpers";

// get allowance history
export const getEmployeeAllowanceHistory =
  (empId = null) =>
  async (dispatch) => {
    const url = empId
      ? `/v1/payroll/allowances/histories/${empId}`
      : `/v1/payroll/allowances/histories`;
    await axios
      .get(url)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          if (response.Data) {
            dispatch({
              type: EMPLOYEE_ALLOWANCE_HISTORY,
              payload: response.Data,
            });
          }
        } else {
          dispatch({
            type: EMPLOYEE_ALLOWANCE_HISTORY,
            payload: null,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        dispatch({
          type: EMPLOYEE_ALLOWANCE_HISTORY,
          payload: null,
        });
        catchError(dispatch, err);
      });
  };

// get allowance request type
export const GetAllowanceRequestType = () => async (dispatch) => {
  await axios
    .get(`/v1/payroll/employee/allowance-type`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          let formattedData = response.Data.map((a) => {
            return {
              label: a.Name,
              value: a.EveningCounterTypeId,
              warn: a.DoNotShowDaysWarning,
            };
          });
          dispatch({
            type: EMPLOYEE_ALLOWANCE_TYPELIST,
            payload: formattedData,
          });
        }
      } else {
        dispatch({
          type: EMPLOYEE_ALLOWANCE_TYPELIST,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_ALLOWANCE_TYPELIST,
        payload: null,
      });
      catchError(dispatch, err);
    });
};

// get allowance request type
export const GetAllowanceApproverReviewerList =
  (employeeId = null) =>
  async (dispatch) => {
    await axios
      .get(`/v1/payroll/allowances/approval-persons?employeeId=${employeeId}`)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          if (response.Data) {
            let formattedData = {
              approverList: [],
              reviewerList: [],
            };
            response.Data.Approvals.forEach((a) =>
              formattedData.approverList.push({
                Id: a.EmployeeId,
                FullName: a.EmployeeName,
                Imagepath: a.Image,
                Designation: a.Position,
              })
            );
            response.Data.Recommended.forEach((a) =>
              formattedData.reviewerList.push({
                Id: a.EmployeeId,
                FullName: a.EmployeeName,
                Imagepath: a.Image,
                Designation: a.Position,
              })
            );
            dispatch({
              type: EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST,
              payload: formattedData,
            });
          }
        } else {
          dispatch({
            type: EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST,
            payload: null,
          });
          toastMessage(dispatch, response);
        }
      })
      .catch((err) => {
        dispatch({
          type: EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST,
          payload: null,
        });
        catchError(dispatch, err);
      });
  };

export const getEmployeeAllowanceRequest = (id) => async (dispatch) => {
  await axios
    .get(`/v1/payroll/allowances/${id}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          // let formattedData = response.Data.map((a) => {
          //   return {
          //     label: a.Name,
          //     value: a.EveningCounterTypeId,
          //     warn: a.DoNotShowDaysWarning,
          //   };
          // });
          // dispatch({
          //   type: EMPLOYEE_ALLOWANCE_TYPELIST,
          //   payload: formattedData,
          // });
          dispatch({
            type: EMPLOYEE_SINGLE_ALLOWANCE_REQUEST,
            payload: response.Data,
          });
        }
      } else {
        dispatch({
          type: EMPLOYEE_SINGLE_ALLOWANCE_REQUEST,
          payload: null,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: EMPLOYEE_SINGLE_ALLOWANCE_REQUEST,
        payload: null,
      });
      catchError(dispatch, err);
    });
};

export const saveEmployeeAllowanceRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
    payload: true,
    isLoading: true,
  });
  await axios
    .post(`/v1/payroll/allowances`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
            payload: true,
            isLoading: false,
          });
          toastMessage(dispatch, response);
        }
      } else {
        dispatch({
          type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
          payload: false,
          isLoading: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
        payload: false,
        isLoading: false,
      });
      catchError(dispatch, err);
    });
};

export const updateEmployeeAllowanceRequest = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
    payload: false,
    isLoading: true,
  });
  await axios
    .put(`/v1/payroll/allowances`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        if (response.Data) {
          dispatch({
            type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
            payload: true,
            isLoading: false,
          });
          toastMessage(dispatch, response);
        }
      } else {
        dispatch({
          type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
          payload: false,
          isLoading: false,
        });
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      dispatch({
        type: SAVE_EMPLOYEE_ALLOWANCE_REQUESTS,
        payload: false,
        isLoading: false,
      });
      catchError(dispatch, err);
    });
};
