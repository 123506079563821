import React from "react";
import {FaRegFilePdf, FaEdit} from "react-icons/fa";
import {connect} from "react-redux";
import Button from "components/button";
export class DocumentsDetail extends React.Component {
  render() {
    const documents = this.props.documentInfo || [];
    return (
      <div className="personal-row">
        <div className="personal-column">
          <div className="personal-block">
            <div className="personal-block__title">
              <h2>Identification and Documents</h2>
              <Button title="Change" bg="subtle" buttonClass="personal-block__btn" />
            </div>
            <div className="personal-block__body">
              {/* <div className="personal-block__icon">
                                            <FaEdit />
                                        </div> */}
              {documents.length > 0 ? (
                documents.map((docs, i) => (
                  <div key={i + Math.random()} className="personal-block__div">
                    <div className="personal-label">{docs.Document}</div>
                    <div className="value">************</div>
                    {docs.Place} on {docs.IssueDate}
                    <span className="document-icon">
                      <FaRegFilePdf />
                    </span>
                    {docs.ExpireIn && (
                      <span className="document-expires">expires in {docs.ExpireIn}</span>
                    )}
                  </div>
                ))
              ) : (
                <div className="profile-block__title nodata">
                  <h2>No Document Available</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documentInfo:
    (state.employeeDashboard.employeeDetail &&
      state.employeeDashboard.employeeDetail.Identification) ||
    [],
});

export default connect(mapStateToProps, null)(DocumentsDetail);
