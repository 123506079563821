import isEmpty from "isEmpty";
export default function validateDisciplinaryCase(values) {
  let errors = {};

  const disciplinaryCaseValidateField = [
    "DisciplinaryCaseId",
    "Employee",
    "CaseTitle",
    "CaseStatement",
    "CaseDate",
    "Notify",
    "ReportedBy",
  ];
  disciplinaryCaseValidateField.map((x) => {
    if (x === "CaseDate") {
      if (values[x] === null || isEmpty(values[x])) {
        errors[x] = "Required";
      }
    } else if (isEmpty(values[x])) {
      errors[x] = "Required";
    }
  });
  return errors;
}
