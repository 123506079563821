import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";

const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Settings",
    feature: features?.CoreHR,
    links: [
      {title: "Lists of Incomes", link: "CP/ManageIncomes.aspx"},
      {title: "List of Deductions", link: "cp/ManageDeductions.aspx"},
      {title: "Employee Icome Summary", link: "cp/AllEmployeeIncomeList.aspx"},
      {title: "Payroll Checklists", link: "cp/extension/ManageCheckList.aspx"},
    ],
  },
  {
    groupTitle: "Additional Settings",
    feature: features?.CoreHR,
    links: [
      {title: "Employee Gratuity Class", link: "CP/EmployeeGratuityClass.aspx"},
      {title: "Fixed Provident Fund", link: "cp/FixedPFDeduction.aspx"},
    ],
  },
];

function Settings(props) {
  return (
    <div>
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Payroll Settings"
        localStorageTitle="PayrollSettingsFav"
        modules={modules}
      />
    </div>
  );
}

export default Settings;
