import axios from 'helpers/axiosinstance';
import isEmpty from 'isEmpty'
import {
    SEARCHED_TAX_ADJUSTMENT,
    CLEAR_ERRORS,
    GET_ERRORS,
    TAX_ADJUSTMENT_LOADING,
    ADD_MESSAGE
} from "../../../actions/types";
import {toastMessage} from '../../../actions/validateAction'
import { taxAdjustmentData } from './data';

// Get Filtered Leave
export const searchTaxAdjustment = data => dispatch => {
    dispatch(setTaxLoading());
    axios
        .post(`/v1/payroll/tax-adjustment/search`, data)
        .then(res => {
            toastMessage(dispatch,res);
            dispatch({
                type: SEARCHED_TAX_ADJUSTMENT,
                // payload: taxAdjustmentData.Data,
                payload: res.data.Data,
                page: {
                    index: res.data.PageIndex,
                    total: res.data.RecordsTotal
                    // index: taxAdjustmentData.PageIndex,
                    // total: taxAdjustmentData.RecordsTotal
                }
            });
        })
        .catch(err =>
            dispatch({
                type: SEARCHED_TAX_ADJUSTMENT,
                payload: null
            })
        );
};

// Set loading state
export const setTaxLoading = () => {
    return {
        type: TAX_ADJUSTMENT_LOADING
    };
};


// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
