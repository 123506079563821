import React from "react";
import FormGroup from "components/form";
import Icons from "components/icons";
import Button from "components/button";
import {connect} from "react-redux";
import {MdAdd} from "react-icons/md";
import isEmpty from "isEmpty";
import {InputValidator} from "helpers/inputValidator";
import {
  DisplayClientSideMessage,
  getLevels,
  GetPayrollIncomeHeads,
} from "actions/commonAction";
import TableData from "./accessIDTable";
import {
  AddAccessAndIdTemplate,
  GetAllAccessAndIdCategoryTemplate,
  GetSalaryTemplateDetails,
  UpdateAccessAndIdTemplate,
  DeleteSalaryTemplate,
  ClearSaveAccessAndIdTemplate,
} from "../accessAndIdTemplateAction";
import "../style.scss";

const initialState = {
  date: "",
  open: false,
  categoryId: 0,
  categoryName: null,
  incomeHeadOpen: false,
  payrollIncomeHeads: [],
  openNewTemplate: false,
  templateLists: null,
  errors: null,
  editView: false,
  rowDatas: [],
  editViewData: {},
};
interface IAppProps {
  employeeId?: any;
  employeeFamiliesDetail?: any;
  getLevels: any;
  GetPayrollIncomeHeads: any;
  levelList: any;
  payrollIncomeHeads: any;
  DisplayClientSideMessage: any;
  AddAccessAndIdTemplate: any;
  saveAccessAndIdTemplate: any;
  GetAllAccessAndIdCategoryTemplate: any;
  GetSalaryTemplateDetails: any;
  ClearSaveAccessAndIdTemplate: any;
  salaryTemplateDetail: any;
  UpdateAccessAndIdTemplate: any;
  DeleteSalaryTemplate: any;
  showForm: any;
  closeForm: any;
  editViewData?: any;
  editView: any;
}

interface State {
  date: any;
  open: any;
  categoryName: any;
  incomeHeadOpen?: any;
  payrollIncomeHeads: any;
  openNewTemplate: boolean;
  templateLists: any;
  errors?: any;
  editView?: boolean;
  rowDatas?: any;
}

class index extends React.Component<IAppProps, State> {
  state = initialState;

  componentDidMount = async () => {
    const {editView, editViewData} = this.props;
    this.props.ClearSaveAccessAndIdTemplate();
    if (editView) {
      this.setState({
        ...editViewData,
        editView: true,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {}

  handleFieldChange = (name, value) => {
    this.setState({[name]: value} as Pick<State, keyof State>, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  handleFormSubmit = async () => {
    let {categoryId, categoryName, editView, rowDatas} = this.state;

    let formData = [...rowDatas];
    let isNameBlank = false;
    let isNameMissing = false;
    if (rowDatas.length === 0) {
      isNameMissing = true;
    }
    formData =
      formData &&
      formData.map((f) => {
        if (isEmpty(f.TypeName)) isNameBlank = true;
        return {
          ...f,
        };
      });

    let errors = await InputValidator(document, this.state);

    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }

    if (isNameMissing) {
      this.props.DisplayClientSideMessage("Please add an item");
      return;
    }

    if (isNameBlank) {
      this.props.DisplayClientSideMessage("Please fill all the item name");
      return;
    }

    let TemplateDetail =
      rowDatas?.length > 0 &&
      rowDatas.map((row) => {
        return {
          TypeName: row?.TypeName,
          ResponsiblePersonId: row?.ResponsiblePerson?.value,
        };
      });

    let templateData = {
      Id: categoryId,
      CategoryName: categoryName,
      TemplateDetail,
    };

    if (editView) {
      await this.props.UpdateAccessAndIdTemplate(templateData, this.props.closeForm);
      this.props.GetAllAccessAndIdCategoryTemplate();
    } else {
      await this.props.AddAccessAndIdTemplate(templateData, this.props.closeForm);
      this.props.GetAllAccessAndIdCategoryTemplate();
    }
  };

  removeAccessIdRow = (rowData) => {
    let newData = [...this.state.rowDatas];
    let sIndex = newData.findIndex((f) => f.Id == rowData.Id);
    newData.splice(sIndex, 1);
    this.setState({rowDatas: newData});
  };

  handleRowFieldChange = (name, value, rowData) => {
    if (rowData) {
      let newData = [...this.state.rowDatas];
      console.log({rowData, newData});
      let sIndex = newData.findIndex((f) => f?.Id == rowData?.Id);
      let getData = {...newData[sIndex]};
      getData[name] = value;
      newData[sIndex] = getData;
      this.setState({rowDatas: newData});
    }
  };

  addRow = () => {
    let rowData = [...this.state.rowDatas];
    let allValues = rowData.concat({
      TypeName: "",
      ResponsiblePerson: null,
      Id: !isEmpty(rowData) ? rowData[rowData.length - 1].Id + 1 : 0,
      // Id: 0,
      CategoryRefId: 0,
    });
    this.setState({rowDatas: allValues});
  };

  render() {
    const {categoryName, errors, editView, rowDatas} = this.state;

    return (
      <div className="salaryTemplate-form">
        <div className="salaryTemplate-form__header ml-12">Access & ID Template</div>
        <div className="salaryTemplate-form__body">
          <div className="form-row">
            <FormGroup
              autoFocus
              name="categoryName"
              value={categoryName}
              onChange={this.handleFieldChange}
              label="Employee Group"
              error={errors?.categoryName}
              width="500px"
              validators={["required"]}
            />
          </div>
          <div className="form-row">
            <TableData
              rowData={rowDatas}
              onClose={(data) => this.removeAccessIdRow(data)}
              handleFieldChange={(name, value, rowData) =>
                this.handleRowFieldChange(name, value, rowData)
              }
              errors={errors}
            />
          </div>
          <div className="ml-12">
            <Button
              bg="white"
              buttonType="icon-button"
              icon={<Icons name="Add" />}
              leftIcon={<MdAdd />}
              onClick={() => this.addRow()}
              title="Add Item"
            />
          </div>
        </div>
        <div className="salaryTemplate-form__footer flex justify-center">
          <Button bg="highlight" title="Cancel" onClick={this.props.closeForm} />
          <Button
            bg="primary"
            title={editView ? "Update" : "Save"}
            onClick={() => this.handleFormSubmit()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeFamiliesDetail: state.employeeProfile.employeeFamiliesDetail,
  levelList: state.commonReducer.levelList,
  payrollIncomeHeads: state.commonReducer.payrollIncomeHeads,
  saveAccessAndIdTemplate: state.salaryTemplateReducer.saveAccessAndIdTemplate,
  salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
});

const mapDispatchToProps = {
  getLevels,
  GetPayrollIncomeHeads,
  DisplayClientSideMessage,
  AddAccessAndIdTemplate,
  GetAllAccessAndIdCategoryTemplate,
  GetSalaryTemplateDetails,
  UpdateAccessAndIdTemplate,
  DeleteSalaryTemplate,
  ClearSaveAccessAndIdTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
