import axios from "helpers/axiosinstance";

import {
  TRAVEL_CATEGORY_LOADING,
  GET_TRAVEL_CATEGORY,
  ADD_MESSAGE,
  LOAD_TRAVEL_CATEGORY,
  GET_ALLOWANCE_EXPENSE,
  GET_LEVELS,
} from "actions/types";
import {put} from "src/networkService";

// Add Travel-Category
export const addTravelCategory = (data, handleModalShow) => (dispatch) => {
  dispatch(setTravelCategoryLoading(true));
  axios
    .post(`/v1/travel/travel-categories`, data)
    .then((res) => {
      if (res.data.MessageType === "Success" && res.data.Status === true) {
        dispatch(getTravelCategory());
        handleModalShow(false, "normal");
      }
      //dispatch(handleModalShow({ show: false, type: "normal" }));
      dispatch(setTravelCategoryLoading(false));
      dispatch(toastMessage(res));
    })
    .catch((err) => {
      dispatch(setTravelCategoryLoading(false));
    });
};

// Edit Travel-Category
export const editTravelCategory = (data, closeModal) => async (dispatch) => {
  dispatch(setTravelCategoryLoading(true));
  await axios
    .put(`/v1/travel/travel-categories`, data)
    .then((res) => {
      console.log({res});
      if (res.data.MessageType === "Success" && res.data.Status === true) {
        dispatch(getTravelCategory());
        closeModal();
      }
      dispatch(setTravelCategoryLoading(false));
      dispatch(toastMessage(res));
    })
    .catch((err) => {
      dispatch(setTravelCategoryLoading(false));
    });
};

// Delete Travel-Category
export const deleteTravelCategory = (id) => (dispatch) => {
  dispatch(setTravelCategoryLoading(true));
  axios
    .delete(`/v1/travel/travel-categories?id=${id}`)
    .then((res) => {
      //console.log(res);
      if (res.data.Status === true) {
        dispatch(getTravelCategory());
      }
      dispatch(setTravelCategoryLoading(false));
      dispatch(toastMessage(res));
    })
    .catch((err) => {
      dispatch(setTravelCategoryLoading(false));
    });
};

// Get Travel-Category
export const getTravelCategory = (id) => (dispatch) => {
  //dispatch(setAllowancExpenseLoading());
  axios
    .get(`/v1/travel/travel-categories`)
    .then((res) => {
      //console.log(res);
      dispatch({
        type: GET_TRAVEL_CATEGORY,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_TRAVEL_CATEGORY,
        payload: null,
      })
    );
};

// Get Allowance-Expense
export const getAllowanceExpense = () => (dispatch) => {
  //dispatch(setAllowancExpenseLoading());
  axios
    .get(`/v1/travel/allowance-expenses/dropdown`)
    .then((res) => {
      //console.log(res);
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// Get Level-Dropdown
export const getLevels = () => (dispatch) => {
  //dispatch(setAllowancExpenseLoading());
  axios
    .get(`/v1/common/levels`)
    .then((res) => {
      //console.log(res);
      dispatch({
        type: GET_LEVELS,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_LEVELS,
        payload: null,
      })
    );
};

//Get Load-Travel-Category
export const loadTravelCategory = (data) => async (dispatch) => {
  dispatch(setTravelCategoryLoading(true));
  data.CategoryId === 0
    ? dispatch({
        type: LOAD_TRAVEL_CATEGORY,
        payload: {
          ...data,
          validateAllowanceExpenseRate: [],
          allowanceExpenseRate: [],
        },
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      })
    : await axios
        .post(
          `/v1/travel/travel-categories/allowance-expense-rates?categoryId=${data.CategoryId}`
        )
        .then((res) => {
          //console.log(res);
          dispatch({
            type: LOAD_TRAVEL_CATEGORY,
            payload: {
              ...data,
              validateAllowanceExpenseRate: res.data.map((x) => {
                return {...x};
              }),
              allowanceExpenseRate: res.data,
            },
            //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
          });
          //dispatch(setEditMode());
        })
        .catch((err) =>
          dispatch({
            type: LOAD_TRAVEL_CATEGORY,
            payload: null,
          })
        );
};

// export const handleModalShow = (data) => (dispatch) => {
//   dispatch({
//     type: HANDLE_MODAL_SHOW,
//     payload: data,
//   });
// };

// export const setEditMode = () => (dispatch) => {
//   dispatch({
//     type: SET_EDIT_MODE,
//   });
// };

export const toastMessage = (res) => {
  return {
    type: ADD_MESSAGE,
    payload: {
      type: res.data.MessageType,
      message: res.data.Message,
      position: "bottom-right",
    },
  };
};

// Set loading state
export const setTravelCategoryLoading = (data) => {
  return {
    type: TRAVEL_CATEGORY_LOADING,
    payload: data,
  };
};
