import React from "react";

export const RegularList = ({
  items,
  resourceName,
  itemComponent: ItemComponent,
  ...props
}) => {
  return (
    <>
      {items.map((item, i) => (
        <ItemComponent key={i} {...{[resourceName]: item}} index={i} {...props} />
      ))}
    </>
  );
};
