import React from "react";
import Loaders from "components/loaders";
import UpdateItem from "./updateItem";
import {getmyUpDates} from "../getMyUpdates";

const MyUpdateView = ({dashboardLoding, isAllUpdateFieldEmpty, MyUpdate}) => {
  let myUpdateList = MyUpdate && !isAllUpdateFieldEmpty ? getmyUpDates(MyUpdate) : [];
  return (
    <div className="mypage-section">
      <div className="bloch__title">My Update</div>
      {/* <div className="mypage-section-grid"> */}
      {dashboardLoding ? (
        <Loaders
          name="card"
          type="skeleton"
          noRows={3}
          cardType="dashboard2"
          loading={true}
        />
      ) : !isAllUpdateFieldEmpty ? (
        <UpdateItem myUpdateList={myUpdateList} />
      ) : (
        // <>
        //   <UpdateItem
        //     title="UnApproved Leave"
        //     data={MyUpdate?.UnApprovedLeave}
        //     link={MyRequestPath}
        //     requestType="Leave"
        //     Icon={LeaveIcon}
        //   />

        //   <UpdateItem
        //     title="UnApproved Time"
        //     data={MyUpdate?.UnApprovedTime}
        //     link={MyRequestPath}
        //     requestType="Time"
        //     Icon={TimeIcon}
        //   />

        //   <UpdateItem
        //     title="UnApproved Overtime"
        //     data={MyUpdate?.UnApprovedOverTime}
        //     link={MyRequestPath}
        //     Icon={AllowanceIcon}
        //     requestType="Overtime"
        //   />

        //   <UpdateItem
        //     title="UnApproved Allowance"
        //     data={MyUpdate?.UnApprovedAllowance}
        //     link={MyRequestPath}
        //     Icon={OvertimeIcon}
        //     requestType="Allowance"
        //   />

        //   <UpdateItem
        //     title="Approved Leave"
        //     data={MyUpdate?.ApprovedLeave}
        //     link={MyRequestHistoryPath}
        //     requestType="Leave"
        //   />
        //   <UpdateItem
        //     title="Approved Time"
        //     data={MyUpdate?.ApprovedTime}
        //     link={MyRequestHistoryPath}
        //     requestType="Time"
        //   />
        //   <UpdateItem
        //     title="Approved OverTime"
        //     data={MyUpdate?.ApprovedOverTime}
        //     link={MyRequestHistoryPath}
        //     requestType="Overtime"
        //   />
        //   <UpdateItem
        //     title="Approved Allowance"
        //     data={MyUpdate?.ApprovedAllowance}
        //     link={MyRequestHistoryPath}
        //     requestType="Allowance"
        //     Icon={AllowanceIcon}
        //   />
        //   <UpdateItem
        //     title="Settlement Request"
        //     data={MyUpdate?.SettlementRequest}
        //     link={PendingSettlementPath}
        //     requestType="Settlement"
        //   />
        //   <UpdateItem
        //     title="Travel Request"
        //     data={MyUpdate?.TravelRequest}
        //     link={MyTravelRequestPath}
        //     requestType={"Travel"}
        //   />
        // </>
        <div className="no-found-container" style={{width: "190px"}}>
          <span className="no-found">No Update Found</span>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};
export default MyUpdateView;
