import React, {useState} from "react";
import Form from "components/form";
import Button from "components/button";
import {useDropzone} from "react-dropzone";
import {contentTypes} from "constants/enums";
import classnames from "classnames";
import {FaRegFileImage} from "react-icons/fa";
import {MdClose} from "react-icons/md";
import Docs from "assets/images/document.svg";
import PDF from "assets/images/pdf.svg";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {addCourseContent} from "scenes/lms/helpers/action";
import jsonToFormData from "helpers/jsonToFormData";

const AttachmentSidebar = (props) => {
  const [image, setImage] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const pageParams: any = useParams();
  const {courseId, pageId} = pageParams;
  const dispatch = useDispatch();
  const {coursePages, course, coursePage, courseContent} = useSelector(
    (state: any) => state.lmsReducer
  );
  const vid: any = document.getElementById("content_video");
  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept:
      props.type === contentTypes.image.title
        ? ".jpg,.jpeg,.png"
        : props.type === contentTypes.video.title
        ? "video/mp4,video/x-m4v,video/*"
        : props.type === contentTypes.pdf.title
        ? ".pdf"
        : props.type === contentTypes.attachment.title
        ? ".pdf,.docs,.docx,.doc"
        : null,
  });
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const onSave = () => {
    const data = {
      Id: 0,
      CoursePageId: pageId,
      Type: props.id,
      Description: "",
      VideoDuration: vid ? vid.duration : "",
      ContentOrder: 0,
      attachments: [],
      AttachmentIds: [],
      Files: [image],
    };
    // const fd = jsonToFormData([data]);
    const fd = new FormData();
    fd.append("[0].CoursePageId", pageId);
    fd.append("[0].Type", props.id);
    fd.append("[0].ContentOrder", courseContent.length + 1);
    fd.append("[0].VideoDuration", vid ? vid.duration : "");
    fd.append("[0].VideoLink", videoLink);
    fd.append("[0].Files", image);
    dispatch(addCourseContent(fd));
    onCancel();
  };
  const onCancel = () => {
    setImage(null);
    setVideoLink(null);
    props.onClose();
    props.onClear();
  };
  return (
    <div className={classnames("attachment-sidebar", {open: props.open})}>
      <div className="attachment-sidebar__delete">
        <Button onClick={() => onCancel()} bg="secondary-dark" title="Delete Block" />
      </div>
      <div className="attachment-sidebar__body">
        {props.type === contentTypes.video_link.title ? (
          <div className="">
            <Form
              name="video_link"
              placeholder="paste video link"
              onChange={(name, value) => setVideoLink(value)}
              value={videoLink}
            />
          </div>
        ) : (
          <div className="courseForm-upload attachment-sidebar__upload">
            <div className="" key="uniqueKey">
              <div className="attachment-sidebar__upload-alt" {...getRootProps()}>
                <input name={"image"} {...getInputProps({onChange: handleImageChange})} />
                <div className="attachment-sidebar__upload-alt-icon">
                  <FaRegFileImage />
                </div>
                <div className="attachment-sidebar__upload-alt-title">
                  Drag and Drop here or <span>Browse a File</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {image && props.type === contentTypes.image.title ? (
          <div className="attachment-sidebar__image">
            <div className="courseForm-image">
              <img src={URL.createObjectURL(image)} alt="" />
              <div onClick={() => setImage(null)} className="courseForm-image__remove">
                <MdClose />
              </div>
            </div>
          </div>
        ) : image && props.type === contentTypes.video.title ? (
          <div className="attachment-sidebar__image">
            <div className="courseForm-image">
              <video id="content_video" width="320" height="240" controls>
                <source src={URL.createObjectURL(image)} type={image.type} />
                Your browser does not support the video tag.
              </video>
              <div onClick={() => setImage(null)} className="courseForm-image__remove">
                <MdClose />
              </div>
            </div>
          </div>
        ) : videoLink && props.type === contentTypes.video_link.title ? (
          <div className="attachment-sidebar__image">
            <div className="courseForm-image">
              <video width="320" height="240" controls>
                <source src={videoLink} type={"video/mp4"} />
                Your browser does not support the video tag.
              </video>
              <div
                onClick={() => setVideoLink(null)}
                className="courseForm-image__remove"
              >
                <MdClose />
              </div>
            </div>
          </div>
        ) : image && props.type === contentTypes.pdf.title ? (
          <div className="attachment-sidebar__docs">
            <div className="attachment-sidebar__docs-icon">
              <img src={PDF} />
            </div>
            <div className="attachment-sidebar__docs-text">{image.name}</div>
            <div
              onClick={() => setImage(null)}
              className="attachment-sidebar__docs-remove"
            >
              <MdClose />
            </div>
          </div>
        ) : image && props.type === contentTypes.attachment.title ? (
          <div className="attachment-sidebar__docs">
            <div className="attachment-sidebar__docs-icon">
              <img src={Docs} />
            </div>
            <div className="attachment-sidebar__docs-text">{image.name}</div>
            <div
              onClick={() => setImage(null)}
              className="attachment-sidebar__docs-remove"
            >
              <MdClose />
            </div>
          </div>
        ) : null}
        <div className="attachment-sidebar__btn">
          <Button onClick={() => onSave()} bg="primary" title="Save" />
        </div>
      </div>
    </div>
  );
};

export default AttachmentSidebar;
