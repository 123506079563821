import React from "react";
import {FaCheck, FaPencilRuler, FaRegPaperPlane} from "react-icons/fa";

export const CurrentStageItem = ({currentStage}) => {
  switch (currentStage) {
    case "Draft":
      return (
        <>
          <FaPencilRuler className="orange-color" />
          <span className="ml-16 orange-color">{currentStage}</span>
        </>
      );
    case "Onboarding Process":
      return (
        <>
          <FaRegPaperPlane className="primary-color" />
          <span className="ml-16 primary-color">{currentStage}</span>
        </>
      );
    case "Onboarding mail sent":
      return (
        <>
          <FaCheck className="check-color" />
          <span className="ml-16 check-color">{currentStage}</span>
        </>
      );
    default:
      return <span>{currentStage}</span>;
  }
};
