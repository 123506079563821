import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/button";
import { updateCourseContent, addCourseContent } from "scenes/lms/helpers/action";
import FrEditor from "../../common/FrEditor";

interface IContentProps {
  CoursePageId: any;
  Type: any;
  Id: any;
  ContentOrder: any;
  VideoDuration: any;
  VideoLink: string;
  Description: string;
  handleCancel: Function;
}

const Content = (props: IContentProps) => {
  const [state, setState] = useState({ Description: "" });
  useEffect(() => {
    const { } = props;
    setState({ ...state, Description: props.Description });
  }, [props.Description]);

  const dispatch = useDispatch();

  const onSave = async () => {
    const fd = new FormData();
    fd.append("[0].CoursePageId", props.CoursePageId);
    fd.append("[0].Type", props.Type);
    fd.append("[0].Id", props.Id);
    fd.append("[0].ContentOrder", "0");
    fd.append("[0].VideoDuration", props.VideoDuration);
    fd.append("[0].VideoLink", props.VideoLink);
    fd.append("[0].Description", state.Description);

    if ((props.Id || 0) !== 0) {
      await dispatch(updateCourseContent(fd));
    } else {
      await dispatch(addCourseContent(fd));
    }
    props.handleCancel();
  };

  const handleChange = (name, val) => {
    setState({ ...state, [name]: val });
  };
  return (
    <div>
      <div className="mb-md">
        <FrEditor name="Description" value={state.Description} onChange={handleChange} />
      </div>
      <div className="content-buttonwrapper">
        <Button onClick={() => props.handleCancel()} title="Cancel" />
        <Button onClick={() => onSave()} bg="primary" title="Save Content" />

      </div>
    </div>
  );
};

export default Content;
