import React from "react";
import {TabInterface} from "ts/interfaces/componentInterface";
import classnames from "classnames";

// declare module 'react' {
//     interface HTMLAttributes<T> extends DOMAttributes<T> {
//       name?: []
//     }
// }

function Tab(props: TabInterface) {
  const onClick = (e: any) => {
    const {onClick, id, setExpand, onTabClick, isDisabled, setActiveTabId} = props;
    e.stopPropagation();
    !isDisabled && onClick(props.name, id);
    setActiveTabId && setActiveTabId(id);
    setExpand && setExpand(false);
    !isDisabled && onTabClick && onTabClick(e);
  };

  const tabClasses = classnames({
    "tabs-list__item": !props.isBlock,
    "tabs-block__item": props.isBlock,
    "tab-active": props.isActive,
    "tab-disabled": props.isDisabled,
  });
  return (
    <li
      id={props.name}
      data-tool={props.disabledMessage}
      className={tabClasses}
      onClick={(e) => onClick(e)}
    >
      {props.label}
    </li>
  );
}

Tab.defaultProps = {
  isDisabled: false,
  disabledMessage: "",
};

export default Tab;
