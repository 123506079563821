import React, {useState, useEffect} from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import Button from "components/button";
import {useSelector, useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {
  addRosterShiftPattern,
  updateRosterShiftPattern,
  getRosterShiftPattern,
} from "../helpers/action";
// import AddShiftPattern from './addShiftPattern';

function AddShiftTemplate(props) {
  const {onModalClose, isEdit = false, editData = null} = props;
  const {shiftPatternDetails} = useSelector((state: any) => state.roosterReducer);
  const [showShiftPattern, setShowShiftPattern] = useState(false);
  const [totalDays, setTotalDays] = useState(null);
  const method = useForm();
  const {watch, setValue, errors, clearErrors, setError} = method;

  const watchAllFields = watch();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit && editData) {
      dispatch(getRosterShiftPattern(editData.Id));
    }
  }, []);

  const setShiftValue = (shiftPatternDetails) => {
    if (shiftPatternDetails.Details && shiftPatternDetails.Details.length > 0) {
      setShowShiftPattern(true);
      shiftPatternDetails.Details.map((detail) => {
        const shift = detail.IsWorkDay
          ? {label: "Working Day", value: true}
          : {label: "Day Off", value: false};
        setValue("shift-" + detail?.Day, shift);
      });
    }
  };

  // console.log(errors, 'errors')

  useEffect(() => {
    if (isEdit && shiftPatternDetails) {
      Object.keys(shiftPatternDetails).map((item) => {
        setTotalDays(parseInt(shiftPatternDetails.Details.length));
        setShiftValue(shiftPatternDetails);
        setValue(item, shiftPatternDetails[item]);
      });
    }
  }, [shiftPatternDetails]);

  useEffect(() => {
    if (isEdit && shiftPatternDetails) {
      setShiftValue(shiftPatternDetails);
    }
  }, [totalDays]);

  const onSubmit = async (data) => {
    let tempDetails = [];
    for (let i = 0; i < totalDays; i++) {
      tempDetails.push({
        Day: i + 1,
        IsWorkDay: data[`shift-${i + 1}`].value,
      });
    }
    const final = {
      Id: editData?.Id ?? 0,
      Name: data.Name,
      Description: data.Description,
      Details: tempDetails,
      TotalNoOfDays: totalDays,
    };
    let res = null;
    if (isEdit) {
      // console.log(final, 'edit final')
      res = await dispatch(updateRosterShiftPattern(final));
    } else {
      // console.log(final, 'add final')
      res = await dispatch(addRosterShiftPattern(final));
    }
    if (res?.Status) {
      onModalClose && onModalClose();
    }
  };
  const handleOnGenerateClick = () => {
    let totalDays = parseInt(watchAllFields.TotalNoOfDays);
    if (totalDays > 7) {
      setError("TotalNoOfDays", {
        type: "warning",
        message: "Number of days must not be greater than 7",
      });
      return;
    }
    setTotalDays(totalDays);
    clearErrors("TotalNoOfDays");
    setShowShiftPattern(true);
  };

  return (
    <FormGroup method={method} onSubmit={onSubmit}>
      <div className="shifts-add__form">
        <div className="shifts-add__form-left">
          <div className="shifts-add__row">
            <FormItem
              label="Shift Pattern Name"
              name="Name"
              rules={{required: "Shift Pattern Name is required!"}}
              // width="250px"
            />
          </div>
          <div className="shifts-add__row">
            <FormItem
              label="Description"
              name="Description"
              // width="250px"
            />
          </div>
          <div className="shifts-add__row-label">No of Days in Pattern</div>
          <FormItem
            name="TotalNoOfDays"
            type="number"
            // formName="selectInput"
            // options={[...Array(12).keys()].map(item=>({label:item,value:item}))}
            rules={{
              required: "Total No Of Days Required",
              validate: {
                min: (v) => v > 0,
                max: (v) => v < 100,
                equalToTotalDays: (v) => parseInt(v) === totalDays,
              },
            }}
            width="80px"
            className="mb-xsm"
          />
          {errors.TotalNoOfDays && errors.TotalNoOfDays.type === "min" && (
            <div className="genericForm-group error mb-xsm">
              <div className="genericForm-group__message">
                Total No Of Days should be greater than 0
              </div>
            </div>
          )}
          {errors.TotalNoOfDays && errors.TotalNoOfDays.type === "max" && (
            <div className="genericForm-group error mb-xsm">
              <div className="genericForm-group__message">
                Total No Of Days should be less than 100
              </div>
            </div>
          )}
          {errors.TotalNoOfDays && errors.TotalNoOfDays.type === "equalToTotalDays" && (
            <div className="genericForm-group error mb-xsm">
              <div className="genericForm-group__message">
                Total No Of Days should be equal to shift days
              </div>
            </div>
          )}
          {watchAllFields?.TotalNoOfDays > 0 && (
            <Button bg="primary" title="Generate" onClick={handleOnGenerateClick} />
          )}
        </div>

        {showShiftPattern && totalDays > 0 && (
          <div className="shifts-add__form-right">
            {/* <div className="shifts-add__title">
                                Shift Pattern
                            </div> */}
            {[...Array(totalDays)].map((e, i) => (
              <div className="shifts-form__row">
                <div className="shifts-form__row-label">Day {i + 1}</div>
                <FormItem
                  // label={"Day "+(i+1)}
                  name={"shift-" + (i + 1)}
                  formName="reactselect"
                  width="170px"
                  rules={{required: "Shift Type is required!"}}
                  options={[
                    {label: "Working Day", value: true},
                    {label: "Day Off", value: false},
                  ]}
                  defaultValue={{label: "Working Day", value: true}}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="shifts-add__row shifts-add__row-btns flex">
        <Button onClick={() => onModalClose()} bg="subtle" title="Cancel" />
        <Button
          htmlButtonType="submit"
          bg="primary"
          isDisabled={!showShiftPattern && totalDays <= 0}
          title="Save"
        />
      </div>
    </FormGroup>
  );
}

export default AddShiftTemplate;
