import dayjs from 'dayjs';
import React, { useState } from 'react'
import { getPagedYears } from '../../helpers/date';
import classNames from 'classnames';
import leftIcon from '../icons/angle-left.svg';
import reftIcon from '../icons/angle-right.svg';
import { MONTH, YEAR } from '../../constants';

function YearTab({currentDate,format,setCurrentDate,setTab}:any) {
  let year = dayjs(currentDate).get('year');
  const [yearSetting, setYearSetting] = useState({
    indexPoint: 0,
    indexLeap: 6,
  });
  const { leftArrow, rightArrow, SplittedYears } = getPagedYears(
    year,
    yearSetting
  );
  return (
    <div className="cal-tabs">
<div className="cal-tabs__body">
      <div className="cal-tabs__body-year">
        {leftArrow ? null : (
          <div
            onClick={() =>
              setYearSetting({
                indexPoint: yearSetting.indexPoint - 1,
                indexLeap: 6,
              })
            }
            className="cal-tabs__body-arrow arrow-left"
          ><img src={leftIcon} alt="" /></div>
        )}

        {SplittedYears.map((val, index) => {
          const monthDate = dayjs(currentDate).set("year", val.year).format(format);
          return (
            <div
              className={classNames(`year-name`,{
              "active-name":year === val.year })}
              key={val.year}
              onClick={() => {
                setCurrentDate(monthDate)
                setTab(MONTH)
              }}
            >
              {val.year}
            </div>
          );
        })}
        {rightArrow ? null : (
          <div
            onClick={() =>
              setYearSetting({
                indexPoint: yearSetting.indexPoint + 1,
                indexLeap: 6,
              })
            }
            className="cal-tabs__body-arrow arrow-right"
          ><img src={reftIcon} alt="" /></div>
        )}
      </div>
    </div>
    </div> 
    
  )
}

export default YearTab