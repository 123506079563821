import React from "react";
import Modal from "components/modal";
import Avatar from "react-avatar";
import {getFullURL} from "helpers/fullURL";
import {useSelector} from "react-redux";
import {getDateISO} from "components/calendar/helpers/engCalendar";

import Button from "components/button";
// import FormItem from "components/form";
import {RiBook2Line} from "react-icons/ri";
import {DateFormat} from "helpers/dateFormat";

import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import isEmpty from "helpers/isEmpty";

const AccessValidityForm = (props) => {
  const {value} = props;
  const method = useForm();
  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };

  const calculateDaysBetweenDates = (date1, date2) => {
    let days = 0;

    if (date1 && date2) {
      var oneDay = 24 * 60 * 60 * 1000;
      var date1InMillis = new Date(date1).getTime();
      var date2InMillis = new Date(date2).getTime();
      days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
      days = days + 1;
    }

    return days > 0 ? `${days}` : "";
  };
  const handleChange = (name, val) => {
    const {handleSubmit, setError, trigger} = method;
    if (isValidDate(name, val)) {
      if (name === "startDate") onChangeDate(val, value.endDate);
      else if (name === "endDate") onChangeDate(value.startDate, val);
      else if (name === "validDays") onValidDayChange(val);
    }
  };
  const onChangeDate = (startDate, endDate) => {
    let validDays = calculateDaysBetweenDates(startDate, endDate);
    props.setValue && props.setValue({...value, validDays, startDate, endDate});
  };
  const isValidDate = (name, val) => {
    if (name === "startDate") {
      const {endDate} = value;
      if (!isEmpty(val) && !isEmpty(endDate) && new Date(endDate) < new Date(val)) {
        props.setValue && props.setValue({...value, validDays: "", [name]: val});
        return false;
      }
    } else if (name === "endDate") {
      const {startDate} = value;
      if (!isEmpty(startDate) && !isEmpty(val) && new Date(val) < new Date(startDate)) {
        props.setValue && props.setValue({...value, validDays: "", [name]: val});
        return false;
      }
    }
    return true;
  };
  const onValidDayChange = (val) => {
    const {startDate, endDate} = value;
    let newStartDate = startDate;
    let newEndDate = endDate;
    let validDays = "";
    if (val) {
      validDays = `${val}`;
      if (startDate) {
        var date = new Date(startDate);
        date.setDate(date.getDate() + parseInt(val) - 1);
        newEndDate = DateFormat(new Date(date), "YYYY-MM-DD");
      } else if (endDate) {
        var date = new Date(endDate);
        date.setDate(date.getDate() - (parseInt(val) - 1));
        newStartDate = DateFormat(new Date(date), "YYYY-MM-DD");
      }
    }
    props.setValue &&
      props.setValue({...value, validDays, startDate: newStartDate, endDate: newEndDate});
  };
  console.clear();
  console.log(value);
  return (
    <>
      <div className="course-assign__access-validity-title">Access Validity</div>
      <FormGroup onSubmit={(val) => {}} method={method}>
        <div className="course-assign__access-validity">
          <FormItem
            formName="customdateinput"
            name="startDate"
            placeholder="YYYY-MM-DD"
            width="230px"
            onChange={handleChange}
            value={convertDateISO(value.startDate)}
            label="Start date"
            enableOkButton
            rules={{required: "Start Date is required"}}
            hideAction
            disabled={!isEmpty(value?.id)}
            minDate={convertDateISO(new Date())}
            maxDate={convertDateISO(value.endDate)}
          />
          <div className="course-assign__access-validity-days">
            <FormItem
              name="validDays"
              value={value.validDays}
              width="120px"
              label="Valid for"
              rules={{required: "Valid Days is required"}}
              onChange={(name, val) => props.setValue({...value, [name]: val})}
              onBlur={handleChange}
            />
            <span> days</span>
          </div>
          <FormItem
            formName="customdateinput"
            name="endDate"
            placeholder="YYYY-MM-DD"
            width="250px"
            disabled={isEmpty(value.startDate)}
            value={convertDateISO(value.endDate)}
            onChange={handleChange}
            label="End date"
            rules={{required: "End Date is required"}}
            minDate={convertDateISO(value.startDate)}
            enableOkButton
            hideAction
          />
        </div>
      </FormGroup>
    </>
  );
};

export default AccessValidityForm;
