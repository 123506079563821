import React, {useState} from "react";
import classnames from "classnames";
import {IoMdAddCircleOutline} from "react-icons/io";
import {AiOutlineDoubleRight} from "react-icons/ai";
import {FaArrowRight, FaFileContract} from "react-icons/fa";
import {connect} from "react-redux";

function ServiceHistory(props) {
  const [viewAll, setViewAll] = useState(false);
  const travelClasses = () => {
    return classnames({
      badges: true,
      "badges-rounded": true,
      "badges-secondary": true,
    });
  };

  let serviceHistory = props.serviceInfo;
  let serviceToShow = [...serviceHistory];
  if (!viewAll) serviceToShow = serviceToShow.slice(0, 3);
  // console.log("this.props", props)
  return (
    <div className="profile-row">
      <div className="profile-column">
        <div className="profile-block">
          <div className="profile-block__title">
            <h2>SERVICE HISTORY</h2>
          </div>

          <div className="profile-address__block">
            {serviceHistory.length > 0 ? (
              serviceToShow.map((service, i) => (
                <div key={i + Math.random()} className="profile-block__div  service-row">
                  <span className="profile-serial">{service.SN}</span>
                  <div className="profile-contact__label">{service.Event}</div>
                  <div className="value2-passout" style={{fontWeight: 400}}>
                    {service.FromDate}
                  </div>
                  <div className="value2-institution service-date">
                    {service.FromDateEng}
                  </div>
                  <span className="document-icon">
                    <AiOutlineDoubleRight className="right-arrow" />
                  </span>
                </div>
              ))
            ) : (
              <div className="profile-block__title nodata">
                <h2>No Service History</h2>
              </div>
            )}
            {/* <div className="profile-block__div">
                            <span className="document-serial">
                                4
                            </span>
                            <div className="profile-contact__label education">
                                Bacholar in Business Studies
                            </div>
                            <div className="value2-passout">
                                2070, Magh 1
                            </div>
                            <div className="value2-institution service-date">
                                2013, January - 1
                            </div>
                            <span className="document-icon">
                                <AiOutlineDoubleRight className="right-arrow" />
                            </span>
                        </div>
                        <div className="profile-block__div">
                            <span className="document-serial">
                                3
                            </span>
                            <div className="profile-contact__label education">
                                Higher Secondary Education (10+2)
                            </div>
                            <div className="value2-passout">
                                2066 Chaitra
                            </div>
                            <div className="value2-institution service-date">
                                    2013, January - 1
                            </div>
                            <span className="document-icon">
                                <AiOutlineDoubleRight className="right-arrow" />
                            </span>
                        </div>
                        <div className="profile-block__div">
                            <span className="document-serial">
                                3
                            </span>
                            <div className="profile-contact__label education">
                                Higher Secondary Education (10+2)
                            </div>
                            <div className="value2-passout">
                                2066 Chaitra
                            </div>
                            <div className="value2-institution service-date">
                                    2013, January - 1
                            </div>
                            <span className="document-icon">
                                <IoMdAddCircleOutline />
                            </span>
                            <div className="value2-passout">
                                    Financial Assistant
                            </div>
                            <span className="document-icon">
                                <FaArrowRight />
                            </span>
                            <div className="value2-passout">
                                    Financial Manager
                            </div>
                            <span className="document-icon">
                                <FaFileContract />
                            </span>
                        </div> */}
            {serviceHistory && serviceHistory.length > 3 && (
              <div className="profile-block__div">
                <div className="viewall-academics" onClick={() => setViewAll(!viewAll)}>
                  {!viewAll ? "View all" : "Show Less"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  serviceInfo:
    (state.employeeProfile.employeeProfile &&
      state.employeeProfile.employeeProfile.ServiceHistory) ||
    [],
});

export default connect(mapStateToProps, null)(ServiceHistory);
