import React, {useRef, useState, useEffect} from "react";
import FormGroup from "components/form";
import Button from "components/button";
import {getDateISOMDY, getDateISO} from "components/calendar/helpers/engCalendar";
import classNames from "classnames";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import {toastMessage} from "actions/validateAction";
import {useDispatch} from "react-redux";
import isEmpty from "helpers/isEmpty";
import moment from "moment";
let isClicked = false;
const RecurringSection = (props) => {
  const {
    data,
    onInputChange,
    onDateChange,
    onEnter,
    onPeriodChange,
    onChange,
    isValidUser,
    board,
    taskLoading,
  } = props;
  const [edited, setEdited] = useState(false);
  const [selectedRepeatPeriod, setSelectedRepeatPeriod] = useState("month");
  const [selectedPeriodValues, setSelectedPeriodValues] = useState({});
  const [selectedRepetitionType, setSelectedRepetitionType] = useState("");
  const [recurringParams, setRecurringParams] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    isClicked = false;
  }, []);

  useEffect(() => {
    const {RepeatPeriodValue, RepeatPeriod, RepeatNos, RepeatDueDate} =
      data.TmTaskDetailsDto;
    setSelectedRepeatPeriod(RepeatPeriod);

    if (!isClicked) {
      if (!isEmpty(RepeatDueDate)) {
        setSelectedRepetitionType("date");
      } else {
        setSelectedRepetitionType("count");
      }
    }

    var periodValues = (RepeatPeriodValue && RepeatPeriodValue.split(",")) || [];
    let periods = {};
    for (let i = 0; i < periodValues.length; i++) {
      periods[periodValues[i]] = periodValues[i];
    }
    setSelectedPeriodValues(periods);
  }, [data]);

  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };
  const getFomattedDateISO = (date) => {
    return date ? getDateISO(new Date(date)).replace(new RegExp("/", "g"), "-") : "";
  };
  const ref = useRef<any>(null);

  useOutsideClick(ref, () => {
    edited &&
      onEnter &&
      onEnter({key: "Enter"}, "EstimatedHour", data.TmTaskDetailsDto.EstimatedHour);

    edited && setEdited(false);
  });
  const getRecurringPeriod = (type) => {
    if (type === "month")
      return [
        {label: "JAN", value: 1},
        {label: "FEB", value: 2},
        {label: "MAR", value: 3},
        {label: "APR", value: 4},
        {label: "MAY", value: 5},
        {label: "JUN", value: 6},
        {label: "JUL", value: 7},
        {label: "AUG", value: 8},
        {label: "SEP", value: 9},
        {label: "OCT", value: 10},
        {label: "NOV", value: 11},
        {label: "DEC", value: 12},
      ];
    else if (type == "day")
      return [
        {label: "SUN", value: 1},
        {label: "MON", value: 2},
        {label: "TUE", value: 3},
        {label: "WED", value: 4},
        {label: "THU", value: 5},
        {label: "FRI", value: 6},
        {label: "SAT", value: 7},
      ];
    else return [];
  };
  const handleChangeRecurringPeriod = (type) => {
    const {RepeatNos} = data.TmTaskDetailsDto;
    onInputChange("RepeatPeriod", type);
    updateRecurringPeriods(type, RepeatNos);
  };

  const handleRecurringPeriodValueClick = (item) => {
    let value = item.value;
    let periods = {...selectedPeriodValues};
    const {RepeatNos, RepeatPeriod, DueDate} = data.TmTaskDetailsDto;
    let val = periods[item.value];

    if (isEmpty(val)) {
      periods[item.value] = item.value;
    } else delete periods[item.value];

    updateRecurringPeriods(RepeatPeriod, RepeatNos, periods, true);
  };

  const getPeriodValues = (periods) => {
    return Object.keys(periods).toString();
  };

  const updateRecurringPeriods = (
    repeatPeriod,
    noOfRepeats,
    periodValue = {},
    update = true
  ) => {
    var newPeriodValues = [];

    for (var key in periodValue) {
      newPeriodValues.push(periodValue[key]);
    }
    let newValues = [
      {name: "RepeatPeriod", value: repeatPeriod},
      {name: "RepeatNos", value: noOfRepeats},
      {name: "RepeatPeriodValue", value: newPeriodValues.toString()},
    ];
    if (repeatPeriod === "month") {
      newValues.push({name: "RepeatTime", value: ""});
    }
    setRecurringParams(newValues);
    onPeriodChange(newValues, false);
    return newPeriodValues.toString();
  };
  const handleSaveRecurringParams = () => {
    if (recurringParams.length > 0) {
      onPeriodChange(recurringParams, true);
      setRecurringParams([]);
    }
  };
  const handleChange = (event) => {
    const {name, value} = event.target;
    const {RepeatPeriod} = data.TmTaskDetailsDto;
    setEdited(true);
    if (name === "RepeatNos") {
      updateRecurringPeriods(RepeatPeriod, value, 1, false);
    } else {
      onInputChange(name, value);

      var newRecurringParams = [...recurringParams, {name: name, value: value}];
      setRecurringParams(newRecurringParams);
      //   setRecurringParams(newValues);
      //   onPeriodChange(newValues, false);
    }
  };
  const convertDateISOMDY = (date) => {
    return date ? getDateISOMDY(new Date(date)) : "";
  };
  const handleDateChange = (name, value) => {
    let cdate = new Date();
    cdate.setHours(0, 0, 0, 0);
    if (value && new Date(value) < cdate) {
      toastMessage(dispatch, {
        MessageType: "warning",
        Message: "Back date can not be selected.",
      });
      return;
    }

    var newDate = value && convertDateISOMDY(value);
    var newRecurringParams = [...recurringParams, {name: name, value: newDate}];
    setRecurringParams(newRecurringParams);
    onPeriodChange(newRecurringParams, false);
  };
  const handleKeyUp = (e) => {
    const {name, value} = e.target;
    if (e.key === "Enter") {
      onEnter(e, name, value);
    }
  };

  const onBlur = (e) => {
    const {name, value} = e.target;
    edited && onEnter && onEnter({key: "Enter"}, name, value);
    setEdited(false);
  };
  const onChangeRepitationType = (value) => {
    isClicked = true;
    setSelectedRepetitionType(value);
    if (value === "count") {
      onPeriodChange([{name: "RepeatDueDate", value: ""}], false);
    } else {
      onPeriodChange([{name: "RepeatTime", value: ""}], false);
    }
  };
  const GetTextForRecurringButton = () => {
    var text = "Save";
    if (data.TmTaskDetailsDto && data.TmTaskDetailsDto.TaskRecurringDetailId > 0)
      text = "Update";
    return text;
  };
  return (
    <div className={classNames("taskmodal__recurring", {disabled: taskLoading})}>
      <h4>Repeat from Due Date</h4>
      <div className="taskmodal__recurring-body">
        <div className="recurring-right-section">
          <span className="recurring-label">Repeat every</span>
        </div>
        <div className="recurring-right-months disabled">
          <div className="taskmodal__recurring-section">
            {/* <div className="recurring-text-group">
                        <input type="text"
                            name="RepeatNos"
                            value={data.TmTaskDetailsDto.RepeatNos || ""}
                            onChange={handleChange}
                            onKeyUp={(e) => handleKeyUp(e)}
                            onBlur={onBlur}
                        />
                    </div> */}
            <span
              className={classNames("recurring-type", {
                active: selectedRepeatPeriod === "day",
              })}
              onClick={() => handleChangeRecurringPeriod("day")}
            >
              Day
            </span>
            <span
              className={classNames("recurring-type", {
                active: selectedRepeatPeriod === "week",
              })}
              onClick={() => handleChangeRecurringPeriod("week")}
            >
              Week
            </span>
            <span
              className={classNames("recurring-type", {
                active: selectedRepeatPeriod === "month",
              })}
              onClick={() => handleChangeRecurringPeriod("month")}
            >
              Month
            </span>
          </div>
          <div className="taskmodal__recurring-section">
            {getRecurringPeriod(selectedRepeatPeriod).map((x) => (
              <span
                className={classNames("recurring-period", {
                  active: !isEmpty(selectedPeriodValues[x.value]),
                })}
                onClick={() => handleRecurringPeriodValueClick(x)}
              >
                {x.label}
              </span>
            ))}
          </div>
          <div className="taskmodal__recurring-section">
            {selectedRepeatPeriod !== "month" && (
              <div className="recurring-radiogroup">
                <FormGroup
                  formName="radiogroup"
                  onChange={(event, value) => onChangeRepitationType(value)}
                  name="repetitionType"
                  value="count"
                  checked={selectedRepetitionType === "count" ? true : false}
                  label="Repeat"
                  labelPosition="right"
                />
                <div className="recurring-text-group">
                  <input
                    type="text"
                    disabled={selectedRepetitionType === "date"}
                    value={data.TmTaskDetailsDto.RepeatTime || ""}
                    name="RepeatTime"
                    onChange={handleChange}
                  />
                </div>
                <span>times</span>
              </div>
            )}
            <div className="recurring-radiogroup">
              <FormGroup
                formName="radiogroup"
                onChange={(event, value) => onChangeRepitationType(value)}
                value="date"
                name="radiogroup"
                checked={selectedRepetitionType === "date" ? true : false}
                label="Repeat till"
                labelPosition="right"
              />
              {/* <FormGroup /> */}
              <FormGroup
                disabled={selectedRepetitionType === "count"}
                formName="customdateinput"
                name="RepeatDueDate"
                placeholder="YYYY-MM-DD"
                onChange={handleDateChange}
                value={convertDateISO(data.TmTaskDetailsDto.RepeatDueDate)}
                enableOkButton
                hideAction
              />
            </div>
          </div>

          <div className="taskmodal__recurring-section">
            <span className="recurring-label-1">Show the task</span>
            <div className="recurring-text-group">
              <input
                type="text"
                name="ShowBeforeRepeatDueDate"
                onChange={handleChange}
                value={data.TmTaskDetailsDto.ShowBeforeRepeatDueDate || ""}
              />
            </div>
            <span className="recurring-label-1">days before due date</span>
          </div>
          {!board.IsArchived && (
            <div className="taskmodal__recurring-section">
              <Button
                title={GetTextForRecurringButton()}
                bg="primary"
                isDisabled={recurringParams.length === 0}
                onClick={() => handleSaveRecurringParams()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RecurringSection;
