import React, {useEffect, useRef, useState} from "react";
import {AiOutlineCloudUpload, AiOutlineExclamationCircle} from "react-icons/ai";
import useEventListener from "hooks/useEventListener";
import {MdClose} from "react-icons/md";
import FormItem from "components/formGroup/formItem";
import FormGroup from "components/formGroup";
import Button from "components/button";
import classnames from "classnames";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {publishSchdule} from "scenes/roster/helpers/action";
import isEmpty from "isEmpty";
import {useForm} from "react-hook-form";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function Publish(props) {
  const {teamId, startDate, endDate, errors, successSubmitting} = props;
  const [open, setOpen] = useState(false);
  const [continuePublish, setContinuePublish] = useState(2);
  const ref = useRef();
  const dispatch = useDispatch();
  const {publishedHistory} = useSelector((state: any) => state.roosterReducer);

  useEffect(() => {
    !open && setContinuePublish(2);
  }, [open]);

  const pubs =
    publishedHistory &&
    publishedHistory.find(
      (item) =>
        dayjs(item.StartDate).format("YYYY-MM-DD") === startDate &&
        dayjs(item.EndDate).format("YYYY-MM-DD") === endDate
    );
  const isPublished = !isEmpty(pubs);

  const onSubmit = async (data) => {
    const fd = {
      isNotify: data.isNotify === "1",
      startDate,
      endDate,
      teamId: teamId?.value,
    };
    const res: any = await dispatch(publishSchdule(fd));
    if (res && res?.Status) {
      setOpen(false);
      successSubmitting && successSubmitting(true);
    }
  };
  const start = dayjs(startDate).format("D MMMM YYYY");
  const end = dayjs(endDate).format("D MMMM YYYY");
  let justOffDay =
    !errors.isLeaveError && !errors.isPositionError && errors.isWorkOffError;

  console.clear();
  console.log(justOffDay);
  console.log("publishedHistory", publishedHistory);

  const isPublishButtonDisabled = continuePublish == 2 && justOffDay;

  return (
    <div className="scheduler-publish">
      <div
        ref={ref}
        onClick={() => setOpen((prev) => !prev)}
        className={classnames("scheduler-tabs__btn", {
          "scheduler-tabs__btn-active": isPublished && errors.scheduleListCount > 0,
          "scheduler-tabs__btn-disabled":
            errors.isLeaveError ||
            errors.isPositionError ||
            errors.isEmptyEmployeeError ||
            errors.scheduleListCount === 0,
          "scheduler-tabs__btn-primary":
            !errors.isLeaveError &&
            !errors.isPositionError &&
            !errors.isWorkOffError! &&
            errors.scheduleListCount > 0 &&
            !isPublished,
        })}
      >
        <AiOutlineCloudUpload />
        <span>{isPublished ? "Update" : "Publish"}</span>
      </div>
      <div className={classnames("scheduler-publish__pop", {active: open})}>
        <FormGroup onSubmit={onSubmit}>
          <div className="scheduler-publish__header">
            <span>{isPublished ? "Update" : "Publish"} Schedule for</span>
            <span onClick={() => setOpen(false)}>
              <MdClose />
            </span>
          </div>
          <div className="scheduler-publish__body">
            <div className="scheduler-publish__date">
              {start} - {end}
            </div>
            {justOffDay && (
              <>
                <div className="scheduler-publish__error">
                  <AiOutlineExclamationCircle />
                  <span>
                    You are scheduling with {errors.workOffNo} persons on Off Day.
                  </span>
                </div>
                <div className="scheduler-publish__form">
                  <div className="scheduler-publish__label">Do you want to continue?</div>
                  <div className="">
                    <FormItem
                      formName="radioList"
                      options={[
                        {label: "Yes", value: 1},
                        {label: "No", value: 2},
                      ]}
                      value={continuePublish}
                      name="continuePublish"
                      onChange={(name, val) => setContinuePublish(val)}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="scheduler-publish__form">
              <div className="scheduler-publish__label">
                Who would you like to notify?
              </div>
              <div className="">
                <FormItem
                  formName="radioList"
                  options={[
                    {label: "Notify everyone", value: 1},
                    {label: "Do not notify anyone", value: 2},
                  ]}
                  name="isNotify"
                  rules={{required: "This field is required"}}
                />
              </div>
            </div>
          </div>
          <div className="scheduler-publish__footer">
            <Button
              title="Cancel"
              onClick={() => setOpen(false)}
              bg="secondary-dark"
              htmlButtonType="button"
            />
            <Button
              isDisabled={isPublishButtonDisabled}
              title={isPublished ? "Update" : "Publish"}
              // onClick={()=>setOpen(false)}
              bg="primary"
              htmlButtonType="submit"
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
}

export default Publish;
