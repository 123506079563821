import Table from "components/customTable";
import Pagination from "components/table/pagination";
import React from "react";
import dayjs from "dayjs";

const DateCell = ({ value }) => {
  return <span>{dayjs(value).format("YYYY-MM-DD")}</span>;
};

const AssignedShiftCell = ({ value }) => {
  return (
    <span style={{ cursor: "pointer" }} title={value}>
      {value}
    </span>
  );
};

function WorkHoursTable(props) {
  const { data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage } = props;

  const cols = [
    {
      Header: "Date",
      accessor: "ScheduleDate",
      width: 140,
      Cell: DateCell,
    },
    {
      Header: "Name",
      accessor: "EmployeeName",
      width: 200,
    },

    {
      Header: "Assigned Shift",
      accessor: "AssignedShift",
      width: 140,
      Cell: AssignedShiftCell,
    },
    {
      Header: "Shift Start",
      accessor: "ShiftStart",
      width: 140,
    },
    {
      Header: "Shift End",
      accessor: "ShiftEnd",
      width: 140,
    },
    {
      Header: "Shift Hours",
      accessor: "ShiftHours",
      width: 110,
    },
    {
      Header: "CheckIn",
      accessor: "CheckedInTime",
      width: 140,
    },
    {
      Header: "CheckOut",
      accessor: "CheckedOutTime",
      width: 140,
    },
    {
      Header: "Workhours",
      accessor: "WorkingHours",
      width: 100,
    },
    {
      Header: "OTHours",
      accessor: "OT",
      width: 90,
    },

    {
      Header: "",
      accessor: "Id",
      width: 90,
    },
  ];
  const totalData = data && data.length > 0 ? data[0].TotalData : 0;
  return (
    <div className="workHours-table ">
      <Table isFixedWidth data={data ?? []} columns={cols} />

      {totalData > 0 ? (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={rowsPerPage}
          total={totalData}
        />
      ) : null}
    </div>
  );
}

export default WorkHoursTable;
