import React, {useState} from "react";
import styled from "styled-components";

import EditableRow from "./editableRow";
import ReadOnlyRow from "./readOnlyRow";

const Table = styled.table`
  width: 100%;
  text-align: left;
`;

const THead = styled.th`
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(195, 195, 195);
`;

export const GridListTable = (props) => {
  const {
    data = [],
    editLevelId,
    setEditLevelId,
    editLevelData,
    setEditLevelData,
    handleEditLevelDataSubmit,
  } = props;

  const handleEditLevelDataChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newLevelData = {...editLevelData};
    newLevelData[fieldName] = fieldValue;

    setEditLevelData(newLevelData);
  };

  const handleEditClick = (event, levelData) => {
    event.preventDefault();
    setEditLevelId(levelData.LevelId);

    setEditLevelData(levelData);
  };

  const handleCancelClick = () => {
    setEditLevelId(null);
  };

  return (
    <Table>
      <thead>
        <tr>
          <THead>Position</THead>
          <THead>Rate</THead>
          <THead></THead>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <>
            {editLevelId === item.LevelId ? (
              <EditableRow
                editLevelData={editLevelData}
                handleEditLevelDataChange={handleEditLevelDataChange}
                handleCancelClick={handleCancelClick}
                handleEditLevelDataSubmit={handleEditLevelDataSubmit}
              />
            ) : (
              <ReadOnlyRow levelData={item} handleEditClick={handleEditClick} />
            )}
          </>
        ))}
      </tbody>
    </Table>
  );
};
