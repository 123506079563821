import React, {Component} from "react";
import "./errors.style.scss";
import Button from "components/button";
import {withRouter} from "react-router";
import errorImage from "assets/vectors/bugs.svg";
import RouteEnum from "constants/routeEnum";

class GlobalErrorBoundry extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    console.log({error, info});
    // Display fallback UI
    this.setState({hasError: true});
  }

  render() {
    // console.log({props:this.props})
    // console.log({state:this.state})
    const {history} = this.props;
    if (this.state.hasError) {
      return (
        <div className="error-block">
          <div className="error-block__container">
            <div className="error-block__image">
              <img src={errorImage} alt="Bugs Error Image" />
            </div>
            <div className="error-block__content">
              <span>
                Something went wrong with your app. Please try again later or contact your
                system admin.
              </span>
            </div>
            {/* <div className="error-block__buttons">
                  <Button 
                    title="Go Back"
                    onClick={()=>{
                      history.goBack()
                    }}
                    bg="secondary"
                  />
                  <Button 
                    title="Dashboard"
                    onClick={()=>{
                      history.push(RouteEnum.HRDashboardPath)
                    }}
                    bg="primary"
                  />
                </div> */}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(GlobalErrorBoundry);
