import React, {useState} from "react";
import Avatar from "react-avatar";
import moment from "moment";
import Modal from "components/modal";
import {useSelector} from "react-redux";
import {UploadProfile} from "components/uploadProfile";
import {getUploadPath} from "constants/userDetails";

export default function OnBoardingEmpProfile() {
  const [open, setOpen] = useState(false);

  const {onboardingEmployeeDetail} = useSelector((state) => state.employeeOnboarding);
  const changeImage = async () => {
    setOpen(false);
  };
  const user = useSelector((state) => state.auth.user);
  const EmpID = user.EmployeeId;

  if (Object.keys(onboardingEmployeeDetail).length > 0) {
    const {EmployeeOnBoardingDto, EmployeeDto} = onboardingEmployeeDetail || {
      EmployeePhotoDto: {},
    };
    let fullName = EmployeeDto
      ? EmployeeDto.Salutation +
        " " +
        EmployeeDto.FirstName +
        " " +
        (EmployeeDto.MiddleName ? EmployeeDto.MiddleName : "") +
        " " +
        EmployeeDto.LastName
      : null;

    return (
      <div className="profile-row employee-onboarding-profile">
        <div className="">
          <div className="profile-info">
            <div className="profile-left">
              <div className="profile-info__body center-text mr-md">
                <Modal
                  className="profile-change__modal"
                  open={open}
                  onModalClose={() => setOpen(false)}
                >
                  <UploadProfile EmployeeId={EmpID} onModalClose={() => changeImage()} />
                </Modal>
                <Avatar
                  name={"Image"}
                  round={10}
                  size={135}
                  src={getUploadPath(user?.UserImage)}
                />
                <a className="mt-sm cursor-pointer" onClick={() => setOpen(true)}>
                  {" "}
                  Change Photo
                </a>
              </div>
              <div className="profile-info__container">
                <div className="profile-info__body">
                  <div className="profile-info__title">
                    <h2>{fullName || ""}</h2>
                    <h4>{EmployeeDto && EmployeeDto.Designation}</h4>
                    <span>
                      {EmployeeDto && EmployeeDto.Branch},
                      {` ${EmployeeDto && EmployeeDto.Department}`}
                    </span>
                  </div>
                  <div className="flex profile-secondary">
                    <div className="profile-secondary__left">
                      <div className="flex-column">
                        <span>
                          <strong>Appointment Date: </strong>
                          {EmployeeOnBoardingDto &&
                            moment(EmployeeOnBoardingDto.AppointmentDate).format(
                              "MMM DD, YYYY"
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
}
