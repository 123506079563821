import Loaders from "components/loaders";
import React from "react";
import CommonFilter from "./commmonFilter";
import CommonTable from "./taskReportTable";

const CommonView = ({
  filter,
  setActiveTabFilter,
  exportName,
  exportData,
  tableData,
  setActiveTabTableData,
  activeTab,
  isLoading,
  handleExcelExport,
  handleSearchClicked,
  handleRowChange,
  handleIndexChange,
  handleSort,

  ...rest
}) => {
  const onChange = (name, value) => {
    setActiveTabFilter({
      ...filter,
      pageIndex: 0,
      [name]: value,
    });
  };
  const handleDateRangePicker = (name, date) => {
    const {startDate, endDate} = date;
    setActiveTabFilter({
      ...filter,
      pageIndex: 0,
      startDate,
      endDate,
    });
  };

  return (
    <>
      <CommonFilter
        onChange={onChange}
        filter={filter}
        handleExcelExport={handleExcelExport}
        handleDateRangePicker={handleDateRangePicker}
        onSearchClicked={handleSearchClicked}
      />

      <CommonTable
        {...rest}
        tableData={tableData}
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        handleExcelExport={handleExcelExport}
        handleSort={handleSort}
        handleDateRangePicker={handleDateRangePicker}
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
      />
    </>
  );
};

export default CommonView;
