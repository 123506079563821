import React, {useEffect, useState} from "react";
import {Date_Format} from "../../../../../constants/dateFormat";
import {connect} from "react-redux";
import {assignJobDescriptionHistory} from "../../action";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/modal";
import AssignJobModal from "./assignJobModal";
import JobDescription from "./jobDescription";
import moment from "moment";
import {
  assignJobDescription,
  changeAssignedJobDescription,
  getJDViewDetails,
  getJobDescriptionByLevel,
} from "../../action";
function History(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.employeeId) {
      dispatch(assignJobDescriptionHistory(props.employeeId));
    }
  }, []);
  const {
    assignJobDescriptionHistoryData,
    employeeAssignedJobDescription,
    getJDViewDetails,
  } = props;
  console.log("props on history", props, assignJobDescriptionHistoryData);

  return (
    <div className="reporting-history">
      {assignJobDescriptionHistoryData &&
        assignJobDescriptionHistoryData.map((a) => {
          return (
            <HistoryBlock
              data={a}
              getJDViewDetails={getJDViewDetails}
              employeeAssignedJobDescription={employeeAssignedJobDescription}
              employeeProfile={props.employeeProfile}
            />
          );
        })}
    </div>
  );
}

function HistoryBlock({data, employeeProfile}) {
  const [openDesc, setOpenDesc] = useState(false);
  const dispatch = useDispatch();
  const onDescOpen = async () => {
    await dispatch(getJDViewDetails(data.JobDescriptionId));
    setOpenDesc(true);
  };
  const onDescClose = () => setOpenDesc(false);
  const {employeeAssignedJobDescription} = useSelector((state) => state.employeeProfile);
  console.log("job description", employeeAssignedJobDescription);
  return (
    <div className="current">
      <div className="current-row">
        <div className="current-label">
          <span>
            {moment.parseZone(data.EffectiveDateEng).local().format("MMMM DD, YYYY")}
          </span>
        </div>
        <div className="current-label">
          <span>Job Description</span>
        </div>
        <>
          <div className="current-link">
            <a className="current-link__anchor" onClick={() => onDescOpen()}>
              <span>
                {data.JobTitle} [{data.JobCode}]{" "}
              </span>
            </a>
          </div>
        </>
        <Modal
          onModalClose={onDescClose}
          title={"Job Description"}
          className="job-description-modal"
          open={openDesc}
          width="80%"
        >
          {employeeAssignedJobDescription && (
            <JobDescription
              jobDesc={employeeAssignedJobDescription}
              closeModal={onDescClose}
              employeeProfile={employeeProfile}
            />
          )}
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employeeRolesHistory: state.employeeProfile.employeeRolesHistory || [],
  assignJobDescriptionHistoryData:
    state.employeeProfile.assignJobDescriptionHistoryData || [],
  employeeAssignedJobDescription: state.employeeProfile.employeeAssignedJobDescription,
  employeeProfile: state.employeeProfile.employeeProfile,
});
const mapDispatchToProps = {
  getJDViewDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
