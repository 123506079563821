import React, {useState} from "react";
import {AnswerType} from "scenes/lms/helpers/enums";
import questionTypeOptions from "scenes/lms/helpers/questionTypeOptions";
import AddEditQuestion from "../editQuestion";
import QuestionContainer from "./questionConainter";
import {BsPlus} from "react-icons/bs";
import classNames from "classnames";
const QuestionSection = (props) => {
  const {
    section,
    sectionIndex,
    setSections,
    onChange,
    courseId,
    pageId,
    enableUpdateQuestion,
    setEnableUpdateQuestion,
  } = props;
  const [showQuestion, setShowQuestion] = useState(false);
  const handleSetQuestions = (val) => {
    setShowQuestion(val);
    setEnableUpdateQuestion(!val);
  };
  const totalData = section.length;
  const lastItem = section[section.length - 1];
  return (
    <div className="course-card">
      <div className="course-questions">
        <div className="lms-questions__title">
          <span>Questions</span>
        </div>

        <ul className="question-block nospacing nogap">
          <li className="lms-questions__item ">
            {section?.map((item, index) => {
              return (
                <QuestionContainer
                  item={item}
                  key={index}
                  setSections={setSections}
                  onChange={onChange}
                  courseId={courseId}
                  pageId={pageId}
                  sectionIndex={sectionIndex}
                  totalData={totalData}
                  questionIndex={index}
                  enableUpdateQuestion={enableUpdateQuestion}
                  setEnableUpdateQuestion={setEnableUpdateQuestion}
                />
              );
            })}

            <div className="lms-questions__footer">
              {showQuestion ? (
                <AddEditQuestion
                  onCancel={() => handleSetQuestions(false)}
                  setSections={setSections}
                  sectionIndex={sectionIndex}
                  item={{
                    answerLimitFrom: 1,
                    answerLimitTo: "",
                    answerOptions: [
                      {isChecked: false, answerOption: "", marks: 0},
                      {isChecked: false, answerOption: "", marks: 0},
                    ],
                    answerType: AnswerType.normal,
                    isOtherPresent: null,
                    isRandomised: false,
                    question: "",
                    questionOrder: parseInt(lastItem?.questionOrder) + 1,
                    questionType: questionTypeOptions[0],
                    section: lastItem?.section,
                    sectionOrder: lastItem?.sectionOrder + 1,
                  }}
                  courseId={courseId}
                  pageId={pageId}
                />
              ) : (
                <a
                  onClick={() => enableUpdateQuestion && handleSetQuestions(true)}
                  className={classNames({"lms-disable": !enableUpdateQuestion})}
                >
                  <BsPlus />
                  Add another question
                </a>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default QuestionSection;
