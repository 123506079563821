import React from "react";
import {PRIMARY} from "constants/colors";
import {Text} from "components/text";
import Icons from "components/icons";

const CourseTitle = ({title}) => {
  return (
    <div className="flex">
      <Icons color={PRIMARY} name="Book" size={18} />
      <Text
        color={PRIMARY}
        fontSize="18px"
        fontWeight="600"
        margin="0px 8px"
        title={title}
      />
    </div>
  );
};

CourseTitle.defaultProps = {
  title: "",
};

export default CourseTitle;
