import React from 'react'

function CalendarFooter(props:any) {
    const {setCurrentDate,onOk,isSaveOnButton} = props;
    return (
        <div className="cal-popup__footer">
            <div className="cal-popup__btn">
                <button
                    onClick={()=>setCurrentDate(new Date())}    
                >Today</button>
            </div>
            {isSaveOnButton && <div 
                onClick={()=>onOk && onOk()}
                className="cal-popup__btn">
                <button>Ok</button>
            </div>}
        </div>
    )
}

export default CalendarFooter