import React, {useEffect, useState} from "react";
import HiringInformation from "./hiringInformation";
import isEmpty from "isEmpty";
import {useDispatch, useSelector} from "react-redux";
import Empty from "assets/images/emtpy-jobspanel.svg";
import RecruitmentFunnelChart from "./RecruitmentFunnelChart";
import {JobDetailsInterface} from "./model";
import {GetDashboardInfoJobsDetails} from "store/actions/recruitment/dashboard";
import {getUploadPath} from "constants/userDetails";
import Avatar from "react-avatar";
//isEmpty
interface props {
  selectedJob: any;
}
const DashboardInfo = ({selectedJob}) => {
  //const { // selectedJob } = props
  const [jobDetail, setJobDetail] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(selectedJob)) {
      const {JobId} = selectedJob;
      fetchJobDetails(JobId);
    }
  }, [selectedJob]);
  const fetchJobDetails = async (jobId) => {
    let details = await dispatch(GetDashboardInfoJobsDetails(jobId));
    setJobDetail(details);
  };

  const percentageCalculation = (upperValue: number, lowerValue: number) => {
    let percentage: any = ((upperValue / lowerValue) * 100).toFixed(2);

    return isNaN(percentage) ? 0 : percentage;
  };

  const renderJobDetail = () => {
    if (isEmpty(jobDetail)) return null;

    const {
      Applicants,
      Disqualified,
      Exam,
      Hired,
      Interview,
      JobOffer,
      Openings,
      Rejected,
      ShortListed,
      Sourced,
    } = jobDetail.JobStageDetail;
    return (
      <div className="recruitmentinfo-card">
        <div className="recruitment__dashboard-item ">
          <div>
            <p>Openings</p>
            <h1>{Openings}</h1>
          </div>
        </div>
        <div className="recruitment__dashboard-item ">
          <div>
            <p>Applications</p>
            <h1>{Applicants}</h1>
          </div>
          {/* <div>
    <p className="blue-text">41 X</p>
    </div> */}
        </div>
        <div className="recruitment__dashboard-item ">
          <div>
            <p>Shortlist</p>
            <div className="percentvalue">
              <h1>{ShortListed}</h1>
              <h4 className="datavalue shortlist">
                {percentageCalculation(ShortListed, Applicants)}%
              </h4>
            </div>
          </div>
          {/* <div>
      <RecruitmentPieChart
        percentage={percentageCalculation(ShortListed, Applicants)}
      />
    </div> */}
        </div>
        <div className="recruitment__dashboard-item ">
          <div>
            <p>Hired</p>
            <div className="percentvalue">
              <h1> {Hired} </h1>
              <h4 className="datavalue hired">
                {percentageCalculation(Hired, ShortListed)}%
              </h4>
            </div>
          </div>
          {/* <div>
      <RecruitmentPieChart
        percentage={percentageCalculation(Hired, ShortListed)}
      />
    </div> */}
        </div>
      </div>
    );
  };

  const renderCandidates = () => {
    if (isEmpty(jobDetail)) return null;
    const {Candidates} = jobDetail;

    let el =
      Candidates &&
      Candidates.map((item, index) => (
        <div className="employee-row" key={index}>
          <div className="value-avatar">
            <div className="employee-avatar">
              <Avatar
                className="company-logo"
                name={item.CandidateName}
                size={"30"}
                round={true}
                src={getUploadPath(`Uploads/${item.ProfileImageThumbnail}`)}
              ></Avatar>
            </div>
          </div>
          <div className="employee-value employee-title">
            <span className=" text-blue2">{item.CandidateName}</span>
          </div>
          <div className="employee-value">{item.Position}</div>
          <div className="employee-value">{item.EducationLevel}</div>
          <div className="employee-value">{item.Age}</div>
        </div>
      ));

    return el;
  };

  return (
    <div className="recruitment-panel">
      {isEmpty(selectedJob) ? (
        <div className="emptystate-jobs">
          <div className="emptyimage">
            <img src={Empty} alt="" />
          </div>
          <p>Please select a job in the left to view analytics</p>
        </div>
      ) : (
        <div className="recruitment-info">
          <div className="recruitmentinfo-blocks secondarylists">
            {renderJobDetail()}
            <div className="flex recruitmentinfo-analytics">
              <div className="recruitment-hiringstages">
                <div className="recruitment__dashboard-item item7">
                  <div className="recruitment__dashboard-item_review-top">
                    <span> Hiring Stages</span>
                  </div>

                  {!isEmpty(jobDetail) ? (
                    <HiringInformation jobStageDetail={jobDetail.JobStageDetail} />
                  ) : (
                    <div className="employees-list">
                      <div className="no-found">No Hiring Stages </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="recruitment-funnelchart">
                            <div className="recruitment__dashboard-item">
                                {!isEmpty(jobDetail) &&
                                    <RecruitmentFunnelChart jobStageDetails={jobDetail.JobStageDetail} />
                                }
                            </div>
                        </div> */}
            </div>
          </div>
          <div className="recruitmentinfo-blocks applicantlists">
            <div className="recruitment__dashboard-item item5">
              <div className="recruitment__dashboard-item_review-top">
                <span> New Applicants</span>
              </div>
              <div className="employees-list">{renderCandidates()}</div>
              {/* <div className="employees-list">
            {!isEmpty(candidateInformation(candidates, baseUrls)) ? (
              candidateInformation(candidates, baseUrls)
            ) : (
              <div className="employees-list">
                <div className="no-found">No New Applicants Found</div>
              </div>
            )}
          </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DashboardInfo;
