import React from "react";
import FormGroup from "components/form";
import Button from "components/button";

function IncomeHeadOptions({
  title,
  onModalClose,
  incomeHeads,
  onIncomeHeadChecked,
  addIncomeHeads,
}) {
  console.log("income head", incomeHeads);
  return (
    <div>
      <div className="salaryTemplate-incomeHead">
        {incomeHeads &&
          incomeHeads.map((a, i) => (
            <FormGroup
              checked={a.IsBasicIncome === true ? true : a.checked}
              // checked={a.Abbreviation == "BS" ? true : a.checked}
              formName="checkgroup"
              label={a.Title}
              name={a.IncomeId}
              onChange={(e) => onIncomeHeadChecked(e, a)}
              // type={""}
              value={a.IncomeId}
            />
          ))}
      </div>
      <div className="additional-documents__footer">
        <Button title={"Add"} bg="primary" onClick={() => addIncomeHeads()} />
        <Button title="Cancel" bg="secondary-dark" onClick={() => onModalClose()} />
      </div>
    </div>
  );
}
export default IncomeHeadOptions;
