import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";

export const postProjectDetailsList =
  (data, value, isExport = false) =>
  async (dispatch) => {
    let alldatas = {
      ...data,
      clientType: data.clientType?.value ?? 0,
      projectStatus:
        typeof data.projectStatus === "object" ? data.projectStatus?.value : value ?? "",
      pageSize: isExport ? 9999999 : data.pageSize,
    };

    const res = Http.post(environment.api.v1.taskTracker.taskTrackerPorjectDetailsList, alldatas);
    const actionConfig = {
      dispatch,
      actionType: isExport
        ? types.EXPORT_TASK_TRACKER_PROJECT_DETAILS_LIST
        : types.TASK_TRACKER_PROJECT_DETAILS_LIST,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

export const getProjectType = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.taskTracker.taskTrackerProjectTypes);

  const actionConfig = {
    dispatch,
    actionType: types.TASK_TRACKER_GET_PROJECT_TYPES,
    effect: res,
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
};
