import {
  DisplayClientSideMessage,
  GetLevelsWithGroups,
  GetJobProfiles,
  GetJobDescriptions,
} from "actions/commonAction";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, {Component} from "react";
import {connect} from "react-redux";
import queryString from "query-string";
import RouteEnum from "constants/routeEnum";
import {
  GetAbilityList,
  GetCertificationList,
  GetDefaultJobProfile,
  GetEducationList,
  GetJobProfileById,
  GetKnowledgeList,
  GetSkillsList,
  GetJobDescriptionById,
} from "../../actions";
import {getProfileInitialBlocks, getSectionsDataForSave} from "../../getProfileData";
import SummaryOfPosition from "./summaryOfPosition";
import * as jobProfileService from "services/jobProfile";
import {
  KNOWLEDGE_SKILL_ABILITY_ID,
  JOB_PROFILE_DEFAULT_BLOCKS,
} from "constants/constants";
import Button from "components/button";
import EmployeeSearch from "components/common/employeeSearch";
import ProfileBlockSkeleton from "../../skeleton/profileBlockSkeleton";

class JobDescriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDescriptionId: 0,
      // jobTitle: "",
      // jobCode: "",
      jobProfile: {},
      jobFamily: "",
      jobRole: "",
      reportsTo: {},
      errors: {},
      profileBlocks: [],
      initialBlocks: [],
      isSpecific: true,
      isAll: false,
      searchEmployee: {},
      employeeName: "",
    };
  }

  async componentDidMount() {
    await this.initialize();
    const {
      match: {params},
    } = this.props;
    if (!isEmpty(params)) {
      await this.props.GetJobDescriptionById(params.descriptionId);
    }
  }

  initialize = async () => {
    const {
      GetDefaultJobProfile,
      GetJobProfiles,
      GetJobDescriptions,
      GetEducationList,
      GetCertificationList,
      GetKnowledgeList,
      GetSkillsList,
      GetAbilityList,
    } = this.props;
    let ajaxApi = [
      GetDefaultJobProfile(),
      GetJobProfiles(),
      GetJobDescriptions(),
      GetEducationList(),
      GetCertificationList(),
      GetKnowledgeList(),
      GetSkillsList(),
      GetAbilityList(),
    ];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item;
      })
    );
  };
  componentDidUpdate(prevProps, prevState) {
    const {jobDescriptionDetails} = this.props;
    const {
      match: {params},
    } = this.props;
    if (!isEmpty(params) && prevProps.jobDescriptionDetails !== jobDescriptionDetails) {
      let initialData = jobDescriptionDetails;
      const {profileBlocks, initialBlocks} = getProfileInitialBlocks(
        initialData,
        "jobDescription"
      );
      // console.log(profileBlocks);
      this.setState({
        profileBlocks,
        initialBlocks,
        jobDescriptionId: initialData.JobDescriptionId,
        // jobTitle: initialData.JobTitle,
        // jobCode: initialData.JobCode,
        jobFamily: initialData.LevelName,
        jobRole: initialData.LevelGroupName,
        jobProfile: {
          label: initialData.ProfileName,
          value: initialData.JobProfileRefId,
        },
        // reportsTo: {
        //   value: initialData.ReportsToJDRefId,
        //   label: initialData.ReportsToJobTitle,
        // },
        searchEmployee: {
          label: initialData.EmployeeName,
          value: initialData.EmployeeRefId,
        },
        employeeName: initialData.EmployeeName,
      });
    }
  }
  handleProfileChange = async (name, selected) => {
    if (!isEmpty(selected)) {
      await this.props.GetJobProfileById(selected.value);
      let initialData = this.props.jobProfileDetails.value;
      const {profileBlocks, initialBlocks} = getProfileInitialBlocks(
        initialData,
        "jobDescription"
      );

      const {jobProfileWithFamilyAndRoleList} = this.props;
      const selectedJobProfile = jobProfileWithFamilyAndRoleList.find(
        (x) => x.ProfileId === selected.value
      );
      const jobFamily = selectedJobProfile?.LevelName;
      const jobRole = selectedJobProfile?.LevelGroupName;
      this.setState({
        profileBlocks,
        initialBlocks,
        [name]: selected,
        jobFamily,
        jobRole,
      });
    }
  };
  handleFieldChange = (name, value) => {
    this.setState({[name]: value});
  };
  handleComponentFieldChange = (type, componentId, name, value) => {
    //const { name, value } = event.target;
    let profileBlocks = this.state.profileBlocks;
    let currentComponents = this.state.profileBlocks.find(
      (x) => x.type === type
    ).components;

    let newComponents = currentComponents.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === componentId) {
        o[name] = value;
      }
      return o;
    });
    profileBlocks.find((x) => x.type === type).components = newComponents;
    this.setState({
      profileBlocks,
    });
  };
  handleAddSubSections = (sectionId, type, SelectedEduQualList) => {
    let {profileBlocks} = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        o["components"] = x.components.map((c) => {
          const p = {
            ...c,
          };
          if (c.type === type) {
            p[type.toLowerCase()] = SelectedEduQualList.map((x) => {
              return sectionId === KNOWLEDGE_SKILL_ABILITY_ID
                ? {id: x.Id, name: x.Name, level: x.Level}
                : {id: x.Id, name: x.Name};
            });
          }
          return p;
        });
      }
      return o;
    });

    this.setState({profileBlocks: stateCopy});
  };

  handleRemoveSubSection = (sectionId, type, id) => {
    let {profileBlocks} = this.state;
    let stateCopy = profileBlocks.map((x) => {
      const o = {
        ...x,
      };
      if (x.id === sectionId) {
        o["components"] = x.components.map((c) => {
          const p = {
            ...c,
          };
          if (c.type === type) {
            p[type.toLowerCase()] = c[type.toLowerCase()].filter(
              (type) => type.id !== id
            );
          }
          return p;
        });
      }
      return o;
    });

    this.setState({profileBlocks: stateCopy});
  };
  handleFormSubmit = async () => {
    let errors = {};
    const {jobDescriptionList} = this.props;
    const params = queryString.parse(this.props.location.search);

    const {
      jobDescriptionId,
      // jobTitle,
      // jobCode,
      jobProfile,
      reportsTo,
      profileBlocks,
      isSpecific,
      searchEmployee,
    } = this.state;
    // if (!jobTitle) {
    //   errors.jobTitle = "Required";
    // }
    // if (!jobCode) {
    //   errors.jobCode = "Required";
    // }
    if (!searchEmployee) {
      errors.searchEmployee = "Required";
    }
    if (isEmpty(jobProfile)) {
      errors.jobProfile = "Required";
    }
    // if (isEmpty(reportsTo)) {
    //   errors.reportsTo = "Required";
    // }

    this.setState({errors});
    if (isEmpty(errors)) {
      // if (
      //   !isEmpty(jobDescriptionList.value) &&
      //   jobDescriptionList?.value?.find(
      //     (x) => x.value != jobDescriptionId && x.label === jobTitle
      //   )
      // ) {
      //   errors.jobTitle = "Job title already taken . Please choose another title!";
      //   let message = {
      //     MessageType: "danger",
      //     Message: "Job title already taken. Please choose another title!",
      //   };
      //   this.props.DisplayClientSideMessage(message);
      //   return false;
      // }
      const data = {
        jobDescriptionId: jobDescriptionId,
        // jobTitle: jobTitle,
        // jobCode: jobCode,
        jobProfileRefId: jobProfile?.value,
        employeeRefId: searchEmployee?.value,
        // reportsToJDRefId: reportsTo.value,
        sections: getSectionsDataForSave(profileBlocks),
      };
      // if (!isEmpty(params.emp) && isSpecific == "isSpecific")
      //   data.employeeRefId = params.emp;

      // console.log(data);
      // debugger;
      let result =
        data.jobDescriptionId === 0
          ? await jobProfileService.createJobDescription(data)
          : await jobProfileService.updateJobDescription(data);
      if (result.Status) {
        this.props.history.push(RouteEnum.JobProfilePath);
      }
      this.props.DisplayClientSideMessage(result);

      // console.log(result);
    } else {
      let message = {
        MessageType: "danger",
        Message: "You cannot save or draft without filling all required fields.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };
  onCheckChange = (e) => {
    // this.setState({ [e.target.name]: e.target.checked });
    this.setState({isSpecific: e.target.name});
  };
  render() {
    const {
      // jobTitle,
      // jobCode,
      jobProfile,
      jobFamily,
      jobRole,
      errors,
      profileBlocks,
      initialBlocks,
      reportsTo,
      isSpecific,
      isAll,
      searchEmployee,
      employeeName,
    } = this.state;
    const {
      defaultJobProfiles,
      jobProfileList,
      jobDescriptionList,
      jobProfileWithFamilyAndRoleList,
      jobProfileDetails,
      match,
    } = this.props;
    const isViewMode = false;
    const params = queryString.parse(this.props.location.search);

    // console.log(
    //   "isViewMode==>",
    //   this.props,
    //   queryString.parse(this.props.location.search),
    //   params.emp
    // );
    return (
      <div className="job-profile-form jd-form">
        <div className="job-profile-form__header">
          <span>New Job Description</span>
          {/* <span className="close-icon" onClick={() => this.props.onClose()}>
                <IoIosCloseCircleOutline />
              </span> */}
        </div>
        <div className="job-profile-form__body">
          <div className="form-row ml-sm mr-sm">
            {/* <FormGroup
              autoFocus
              name="jobTitle"
              value={jobTitle}
              onChange={this.handleFieldChange}
              label="Job Title"
              error={errors?.jobTitle}
              width="500px"
              validators={["required"]}
              disabled={isViewMode}
            /> */}
            {!isEmpty(match.params) ? (
              <div className="form-row">
                <FormGroup
                  name="employeeName"
                  value={employeeName}
                  onChange={this.handleFieldChange}
                  label="Employee Name"
                  width="250px"
                  disabled={true}
                />
              </div>
            ) : (
              <EmployeeSearch
                onChange={this.handleFieldChange}
                value={searchEmployee}
                label="Employee"
                name="searchEmployee"
                placeholder="search and select"
              />
            )}

            {/* <FormGroup
              autoFocus
              name="jobCode"
              value={jobCode}
              onChange={this.handleFieldChange}
              label="Job Code"
              error={errors?.jobCode}
              width="150px"
              validators={["required"]}
              disabled={isViewMode}
            /> */}
          </div>
          <div className="form-row ml-sm mr-sm">
            <FormGroup
              formName="reactselect"
              onChange={this.handleProfileChange}
              name="jobProfile"
              value={jobProfile}
              options={jobProfileList.value}
              loading={jobProfileList.loading}
              loadingType="skeleton"
              placeholder="Select"
              disabled={isViewMode}
              validators={["required"]}
              error={errors?.jobProfile}
              width="500px"
              label="Select Job Profile"
            />
          </div>
          {Object.keys(jobProfile).length > 0 && (
            <div className="wd-100 ml-sm mr-sm">
              <div className="job-role flex mt-md">
                <label htmlFor="jobRole" className="form-group__label">
                  Job Family
                </label>
                <span className="ml-md text-md">{jobFamily}</span>
              </div>
              <div className="job-role flex mt-md">
                <label htmlFor="jobRole" className="form-group__label">
                  Job Role or Level
                </label>
                <span className="ml-md text-md">{jobRole}</span>
              </div>
            </div>
          )}
          {/* <div className="form-row ml-sm mr-sm">
            <FormGroup
              formName="reactselect"
              onChange={this.handleFieldChange}
              name="reportsTo"
              value={reportsTo}
              options={jobDescriptionList?.value}
              loading={jobDescriptionList?.loading}
              loadingType="skeleton"
              placeholder="Select"
              disabled={isViewMode}
              width="500px"
              label="Reports to"
            />
          </div> */}
          {jobProfileDetails?.loading &&
            JOB_PROFILE_DEFAULT_BLOCKS?.map((x, i) => (
              <div className="form-row section">
                <div className="ml-sm mr-sm wd-100">
                  <ProfileBlockSkeleton height={201} />
                </div>
              </div>
            ))}
          <div className="form-row section">
            {!isEmpty(profileBlocks) && (
              <SummaryOfPosition
                handleInput={this.handleInput}
                state={this.state}
                isViewMode={isViewMode}
                handleProfileBlockTitle={this.handleProfileBlockTitle}
                handleComponentFieldChange={this.handleComponentFieldChange}
              />
            )}
          </div>
          {initialBlocks.map((x, i) => {
            return (
              <div className="form-row section">
                <x.component
                  handleInput={this.handleInput}
                  state={this.state}
                  isViewMode={isViewMode}
                  handleBlockMove={this.handleBlockMove}
                  index={i}
                  id={x.id}
                  handleProfileBlockTitle={this.handleProfileBlockTitle}
                  handleComponentFieldChange={this.handleComponentFieldChange}
                  handleRemoveComponent={this.handleRemoveComponent}
                  handleRemoveSection={this.handleRemoveSection}
                  handleAddDefaultComponent={this.handleAddDefaultComponent}
                  handleAddComponent={this.handleAddComponent}
                  handleAddSubSections={this.handleAddSubSections}
                  handleRemoveSubSection={this.handleRemoveSubSection}
                  defaultJobProfiles={defaultJobProfiles}
                  profileBlockType={x.type}
                  isComponentEditable
                />
              </div>
            );
          })}
          {!isEmpty(params.emp) && (
            <div className="form-row ml-sm mr-sm">
              <FormGroup
                formName="checkgroup"
                name="isSpecific"
                label="This Specific Employee"
                value={isSpecific}
                checked={isSpecific == "isSpecific"}
                labelPosition="right"
                onChange={this.onCheckChange}
              />
              <FormGroup
                formName="checkgroup"
                name="isAll"
                label="All Employees"
                value={isSpecific}
                checked={isSpecific == "isAll"}
                labelPosition="right"
                onChange={this.onCheckChange}
              />
            </div>
          )}
        </div>

        <div className="job-profile-form__footer flex justify-end">
          <Button
            bg="highlight"
            title="Cancel"
            onClick={() => this.props.history.push(RouteEnum.JobProfilePath)}
          />
          {/* <Button
            bg="warning"
            title="Save as Draft"
            onClick={() => this.handleFormSubmit()}
          /> */}
          <Button bg="primary" title="Save" onClick={() => this.handleFormSubmit()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  levelsWithGroupsList: state.commonReducer.levelsWithGroupsList,
  defaultJobProfiles: state.jobProfileReducer.defaultJobProfiles,
  jobProfileDetails: state.jobProfileReducer.jobProfileDetails,
  jobFamilyList: state.commonReducer.jobFamilyList,
  jobProfileList: state.commonReducer.jobProfileList,
  jobDescriptionList: state.commonReducer.jobDescriptionList,
  jobProfileWithFamilyAndRoleList: state.commonReducer.jobProfileWithFamilyAndRoleList,
  jobDescriptionDetails: state.jobProfileReducer.jobDescriptionDetails,
});

const mapDispatchToProps = {
  GetLevelsWithGroups,
  GetJobProfileById,
  GetDefaultJobProfile,
  GetEducationList,
  GetCertificationList,
  GetKnowledgeList,
  GetSkillsList,
  GetAbilityList,
  DisplayClientSideMessage,
  GetJobProfiles,
  GetJobDescriptions,
  GetJobDescriptionById,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDescriptionForm);
