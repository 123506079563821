import React, {Component} from "react";
import {connect} from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Avatar from "react-avatar";
import FormGroup from "components/form";
import {getBaseAxios} from "helpers/constants";
const BaseUrl = getBaseAxios();

export class ActivitiesCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMember: props.myTeamMembers ? props.myTeamMembers[0] : null,
      teamMember: "",
      selectedDate: new Date(),
      myTeamMembers: [],
    };
  }

  componentDidMount() {
    this.setState({
      myTeamMembers: this.props.myTeamMembers,
    });
  }

  onChange = (name, value) => {
    let memberToFilter = [...this.props.myTeamMembers];
    this.setState(
      {
        teamMember: value,
      },
      () => {
        const {teamMember} = this.state;
        let myTeamMembers = [];
        if (!teamMember) {
          myTeamMembers = this.props.myTeamMembers;
        } else {
          memberToFilter.forEach((member) => {
            if (member.MemberName.toLowerCase().includes(teamMember.toLowerCase()))
              return myTeamMembers.push(member);
          });
        }
        this.setState({
          myTeamMembers,
        });
      }
    );
  };

  onClickMember = (activeMember) => {
    // const memberId = activeMember ? activeMember.MemberId : 0;
    this.setState({activeMember, teamMember: activeMember.MemberName}, () => {
      this.handleCalendarActivities();
    });
  };

  handleCalendarActivities = () => {
    const {selectedDate, activeMember} = this.state;
    this.props.getCalendarActivities(selectedDate, activeMember);
  };

  changeCalendarMonths = (event) => {
    let selectedDate = new Date(event.view.currentStart);
    this.setState({selectedDate}, () => this.handleCalendarActivities());
  };

  render() {
    const {activeMember, teamMember, myTeamMembers} = this.state;
    const {calendarActivities} = this.props;
    let topTwelveMembers = myTeamMembers.slice(0, 12);

    return (
      <div className="activity_calendar">
        <label htmlFor="clock">Activity Calendar</label>
        <div className="activity_calendar_body">
          <div className="activity_calendar_member">
            {/* <InputGroup
              name="teamMember"
              value={teamMember}
              label="Select team member"
              onChange={this.onChange}
              className="form-group select_member_input"
              width="100%"
            /> */}
            <div className="mb-sm">
              <FormGroup
                formName="textinput"
                name="teamMember"
                value={teamMember}
                onChange={this.onChange}
                label="Team member"
              />
            </div>

            <div className="members">
              {topTwelveMembers &&
                topTwelveMembers.map((tm, i) => {
                  const activeClass =
                    activeMember && activeMember.MemberId === tm.MemberId
                      ? "activity_active"
                      : "";

                  return (
                    <div
                      className={`activity_searched ${activeClass}`}
                      key={tm.value}
                      onClick={() => this.onClickMember(tm)}
                    >
                      <Avatar
                        name={tm.MemberName}
                        size={35}
                        round={true}
                        src={BaseUrl + tm.ImgPath}
                      />
                      <span className="name">{tm.MemberName}</span>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="activity_calendar_calendar">
            <FullCalendar
              defaultView="dayGridMonth"
              events={calendarActivities}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              // ref={ this.calendarComponentRef }
              datesRender={(event) => this.changeCalendarMonths(event)}
              header={{
                left: "today, prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
              }}
              displayEventTime={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesCalendar);
