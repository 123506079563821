import React, {useEffect, useState, useRef} from "react";
import {GetTimeApprovalRecommendedForEmployee} from "scenes/employee/time/action";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/button";
import "./style.scss";
import {useForm} from "react-hook-form";
import FormItem from "components/formGroup/formItem";
import FormGroup from "components/formGroup";
import {CreateTimeRequest} from "../../helpers/action";
import moment from "moment";
import LoaderContainer from "components/loaderContainer";

const timeOptions = [
  {label: "Check-in time", value: "checkin"},
  {label: "Check-out time", value: "checkout"},
];

function TimeRequest(props) {
  //   const employeeId = ``
  const {
    ShiftName,
    shiftIndex,
    EmployeeName,
    EmployeeId,
    ScheduleDate,
    TimeMode,
    ShiftScheduleEmployeeId,
    onCancel,
    onSuccesTimeRequest,
  } = props;
  const dispatch = useDispatch();
  const {user} = useSelector((state: any) => state.auth);
  const [approverList, setApproverList] = useState({
    approver: [],
    recommender: [],
  });
  const [state, setState] = useState({
    approver: "",
    reviewer: "",
    timeMode: TimeMode,
    notes: "",
    actualInTime: "",
    isLoading: false,
  });
  const {timeRequestApprovalRecommended} = useSelector((state: any) => {
    return state?.employeeTimeReducer;
  });

  const method = useForm();
  const {handleSubmit} = method;

  useEffect(() => {
    if (timeRequestApprovalRecommended) {
      let approver = timeRequestApprovalRecommended?.Approvals?.map?.((item) => ({
        FullName: item?.EmployeeName,
        Id: item.EmployeeId,
        Imagepath: item.Image,
        ...item,
      }));
      let recommender = timeRequestApprovalRecommended?.Recommended?.map?.((item) => ({
        FullName: item?.EmployeeName,
        Id: item.EmployeeId,
        Imagepath: item.Image,
        ...item,
      }));
      setApproverList({approver, recommender});
    }
  }, [timeRequestApprovalRecommended]);

  useEffect(() => {
    if (user?.RoleName !== "Administrator")
      dispatch(GetTimeApprovalRecommendedForEmployee(EmployeeId));
  }, []);

  const formatDate = () => {
    return moment(ScheduleDate).format("MMMM d, YYYY dddd");
  };
  const formatTime = (time) => {
    let date = moment(ScheduleDate).format("DD/MM/YYYY");
    console.log("date", date);
    return moment(`${date} ${time}`).format("hh:mm A");
  };
  const onSubmit = (obj) => requestTime(obj);

  const onSaveAndApproveSubmit = (obj) => requestTime(obj, true);

  const requestTime = async (obj, isAssigned = false) => {
    let requestLine: any = {
      dateEng: ScheduleDate,
      shiftScheduleEmployeeId: ShiftScheduleEmployeeId,
    };

    if (TimeMode === "checkin") {
      requestLine.actualInTime = obj.time;
      requestLine.inNote = obj.notes;
    } else {
      requestLine.actualOutTime = obj.time;
      requestLine.outNote = obj.notes;
    }

    const param = {
      isAssigned: isAssigned,
      employeeId: EmployeeId,
      timeRequestId: 0,
      approvalEmployeeId: obj.approver,
      recommendEmployeeId: obj.reviewer,
      startDateEng: ScheduleDate,
      endDateEng: ScheduleDate,
      timeRequestLineDto: [requestLine],
    };
    setState({...state, isLoading: true});
    let res: any = await dispatch(CreateTimeRequest(param));
    setState({...state, isLoading: false});
    if (res.Status) {
      const {RequestID, Status} = res.Data;

      var timeObj = {
        Date: ScheduleDate,
        ShiftScheduleEmployeeId,
        IsSelected: false,
        Time: formatTime(obj.time),
        IsPending: Status !== 1,
        Type: "",
      };
      onSuccesTimeRequest(timeObj, shiftIndex, TimeMode);
    }
  };

  return (
    <LoaderContainer isLoading={state.isLoading}>
      <FormGroup method={method} onSubmit={onSubmit} defaultValues={state}>
        <div className="attrequest">
          <div className="attrequest__body">
            <div className="attrequest__emp">
              <div className="attrequest__emp-name">{EmployeeName}</div>
              <div className="attrequest__emp-date">{formatDate()}</div>
            </div>
            <div className="attrequest__shift">{ShiftName}</div>

            <div className="attrequest__type">
              <FormItem
                disabled
                radioPosition="horizontal"
                formName="radioList"
                name="timeMode"
                options={timeOptions}
                rules={{required: "This is required."}}
              />
            </div>

            <div className="attrequest__time">
              <FormItem
                className="attrequest__time-picker"
                label="Select time"
                name="time"
                type="time"
                width="150px"
                rules={{required: "Time is required!"}}
              />

              <FormItem
                label="Note"
                labelPosition="top"
                formName="textinput"
                name="notes"
              />
            </div>

            <div className="attrequest__approvers">
              {approverList.recommender?.length > 0 && (
                <div>
                  <div className="attrequest__approvers-title">Reviewer</div>

                  <FormItem
                    formName="checkBoxGroup"
                    name="reviewer"
                    label=""
                    checkGroupArray={approverList.recommender}
                    value={state.reviewer}
                    multiple={false}
                    rules={{required: "Reviewer is required."}}
                  />
                </div>
              )}
              {approverList.approver?.length > 0 && (
                <div>
                  <div className="attrequest__approvers-title">Approver</div>

                  <FormItem
                    formName="checkBoxGroup"
                    name="approver"
                    label=""
                    checkGroupArray={approverList.approver}
                    value={state.approver}
                    multiple={false}
                    rules={{required: "Approver is required."}}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="attrequest__footer">
            <Button onClick={() => onCancel?.()} bg="subtle" title="Cancel" />

            {user.RoleName === "Administrator" ? (
              <Button
                bg="green"
                title="Save and Approve"
                onClick={() => handleSubmit(onSaveAndApproveSubmit)()}
              />
            ) : (
              <Button bg="primary" title="Send Request" htmlButtonType="submit" />
            )}
          </div>
        </div>
      </FormGroup>
    </LoaderContainer>
  );
}

export default TimeRequest;
