import {v1} from "constants/urls";

export default {
  signIn: `${v1}/auth/signin`,
  signOut: `${v1}/auth/signOut`,
  refreshToken: `${v1}/auth/refresh-token`,
  baseUrl: `${v1}/common/net-framework-app-base-url`,
  publicBaseUrl: `${v1}/common/public-recruitment-base-url`,
  signInWithOauth: `${v1}/auth/signin-with-oauth`,
  signInViaVianet: `${v1}/ext/callback-oauth`,
};
