import React, {useState, useEffect} from "react";
import FormInput from "components/form";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {addRosterTeamRole, getRosterTeamRoleList} from "../../helpers/action";
import Button from "components/button";
import Modal from "components/modal";
import isEmpty from "helpers/isEmpty";

function TeamFilters(props) {
  const {teamList} = useSelector((state: any) => state.roosterReducer);
  const [state, setState] = useState({teamRoleList: []});

  const {filter, onChange} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (filter.teamID) {
      dispatch(addRosterTeamRole(filter.teamID?.value));
    }
  }, [filter.teamId]);
  const onChangeTeam = async (name, value) => {
    onChange(name, value);
    onChange("roleId", null);
    if (!isEmpty(value)) {
      var res: any = await dispatch(getRosterTeamRoleList(value.value, false));
      var roles =
        (res && res.Data.map((x) => ({...x, value: x.Id, label: x.RoleName}))) || [];
      setState({...state, teamRoleList: roles});
    }
  };
  return (
    <div className="space-between">
      <div className="tasksReport-filters">
        <FormInput
          formName="searchInput"
          name="employee"
          placeholder="Search employee"
          value={filter.employee}
          onChange={onChange}
          width="250px"
        />
        <FormInput
          formName="reactselect"
          onChange={onChangeTeam}
          name="teamId"
          placeholder="Filter Team"
          value={filter.teamId}
          width="200px"
          options={teamList}
        />

        <FormInput
          formName="reactselect"
          onChange={onChange}
          name="roleId"
          placeholder="Filter Role"
          value={filter.roleId}
          width="200px"
          disabled={isEmpty(filter.teamId)}
          options={state.teamRoleList}
        />
      </div>
    </div>
  );
}

export default TeamFilters;
