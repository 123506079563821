import React, { useState, useEffect } from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import ReviewCycleTable from './ratingScaleTable'
// import ReviewCycleModal from './reviewCycleModal';
import { useDispatch, useSelector } from "react-redux";
import { getAppraisalRatingScale } from '../helpers/action';



function RatingScale() {
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppraisalRatingScale())
  }, [])
  return (
    <div className='appraisals-container'>
      <div className="appraisals-title">
        <span>Rating Scales</span>
        <Button
          onClick={() => setOpen(true)}
          title='Add Rating Scale' bg="primary" size="small" />
        {/* <ReviewCycleModal 
        open={open}
        onModalClose={onModalClose}
      /> */}
      </div>
      <ReviewCycleTable />
    </div>
  )
}

export default RatingScale