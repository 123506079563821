import axios from "helpers/axiosinstance";
import isEmpty from "isEmpty";
import {toastMessage} from "actions/validateAction";
import {
  DELETE_EDUCATION,
  DELETE_KSA_LIBRARIES_LOADING,
  GET_JOBPROFILE_KSA_EDUCATION_LIST,
  GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
  GET_JOBPROFILE_KSA_SKILL_TYPE,
  GET_JOBPROFILE_KSA_BY_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_LEVEL,
  GET_JOBPROFILE_KSA_EDUCATION_TYPE,
  GET_JOBPROFILE_KSA_CERTIFICATION_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_BY_ID,
  GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID,
  GET_JOBPROFILE_KSA__BY_ID,
  DELETE_CERTIFICATION,
  DELETE_KSA_LIST_BY_TYPE,
  DELETE_SKILL,
  DELETE_ABILITY,
} from "actions/types";
import {Enum_KSA_SkillType} from "./enums";
import {Arrays} from "helpers/getTextLabel";
import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";

const urlPrefix_ksa = "v1/jp/";

//#region Education
export const GetEducationList = () => async (dispatch) => {
  const res = Http.get(`${urlPrefix_ksa}educations`);
  const actionConfig = {
    dispatch,
    actionType: GET_JOBPROFILE_KSA_EDUCATION_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const GetEducationById = (educationId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}educations/${educationId}`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA_EDUCATION_BY_ID,
          payload: resp.data.Data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetEducationLevelForDDL = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}educations/level`)
    .then((resp) => {
      if (resp.status == 200) {
        // let formatedData = [];
        // resp.data &&
        //   resp.data.map((d) => {
        //     formatedData.push({ label: d.Text, value: d.Value });
        //   });
        dispatch({
          type: GET_JOBPROFILE_KSA_EDUCATION_LEVEL,
          payload: Arrays(resp.data.Data),
        });
      } else {
        toastMessage(dispatch, resp);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetEducationTypeForDDL = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}educations/type`)
    .then((resp) => {
      if (resp.status == 200) {
        // let formatedData = [];
        // resp.data &&
        //   resp.data.map((d) => {
        //     formatedData.push({ label: d.Text, value: d.Value });
        //   });
        dispatch({
          type: GET_JOBPROFILE_KSA_EDUCATION_TYPE,
          payload: Arrays(resp.data.Data),
        });
      } else {
        toastMessage(dispatch, resp);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const CreateEducation = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix_ksa}educations`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateEducation = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix_ksa}educations`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteEducation = (educationId, props) => async (dispatch) => {
  await dispatch({
    type: DELETE_KSA_LIBRARIES_LOADING,
    payload: true,
  });
  const res = Http.delete(`${urlPrefix_ksa}educations?educationId=${educationId}`);
  const actionConfig = {
    dispatch,
    actionType: DELETE_EDUCATION,
    effect: res,
    id: educationId,
    isLoading: true,
    message: "Education has been deleted successfully.",
    type: "delete",
  };
  await Action.createThunkEffect(actionConfig);
  const response = await res;
  if (!response?.data?.Status) {
    dispatch({
      type: DELETE_KSA_LIBRARIES_LOADING,
      payload: false,
    });
  }
};
//#endregion Education

//#region Certification
export const GetCertificationList = () => async (dispatch) => {
  const res = Http.get(`${urlPrefix_ksa}certifications`);
  const actionConfig = {
    dispatch,
    actionType: GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const GetCertificationById = (certificationId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}certifications/${certificationId}`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID,
          payload: resp.data.Data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetCertificationTypeForDDL = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}certifications/type`)
    .then((resp) => {
      if (resp.status == 200) {
        // let formatedData = [];
        // resp.data &&
        //   resp.data.map((d) => {
        //     formatedData.push({ label: d.Text, value: d.Value });
        //   });
        dispatch({
          type: GET_JOBPROFILE_KSA_CERTIFICATION_TYPE,
          payload: Arrays(resp.data.Data),
        });
      } else {
        toastMessage(dispatch, resp);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const CreateCertifications = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix_ksa}certifications`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateCertifications = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix_ksa}certifications`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteCertification = (certificationId, props) => async (dispatch) => {
  await dispatch({
    type: DELETE_KSA_LIBRARIES_LOADING,
    payload: true,
  });
  const res = Http.delete(
    `${urlPrefix_ksa}certifications?certificationId=${certificationId}`
  );
  const actionConfig = {
    dispatch,
    actionType: DELETE_CERTIFICATION,
    effect: res,
    id: certificationId,
    isLoading: true,
    message: "Certification has been deleted successfully.",
    type: "delete",
  };
  await Action.createThunkEffect(actionConfig);
  const response = await res;
  if (!response?.data?.Status) {
    dispatch({
      type: DELETE_KSA_LIBRARIES_LOADING,
      payload: false,
    });
  }
};
//#endregion Certification

//#region Skill
export const GetSkillList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}skills`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA_BY_TYPE,
          payload: resp.data.Data,
          ksaType: Enum_KSA_SkillType.Skills,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetSkillById = (skillId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}skills/${skillId}`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA__BY_ID,
          payload: resp.data.Data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetSkillTypeForDDL = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}skills/type`)
    .then((resp) => {
      if (resp.status == 200) {
        let formatedData = [];
        resp.data &&
          resp.data.map((d) => {
            formatedData.push({label: d.Text, value: d.Value});
          });
        dispatch({
          type: GET_JOBPROFILE_KSA_CERTIFICATION_TYPE,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, resp);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const CreateSkills = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix_ksa}skills`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateSkills = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix_ksa}skills`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteSkill = (skillId, props) => async (dispatch) => {
  await dispatch({
    type: DELETE_KSA_LIBRARIES_LOADING,
    payload: true,
  });
  const res = Http.delete(`${urlPrefix_ksa}skills?skillsId=${skillId}`);
  const actionConfig = {
    dispatch,
    actionType: DELETE_SKILL,
    effect: res,
    id: skillId,
    isLoading: true,
    message: "Skill has been deleted successfully.",
    type: "delete",
  };
  await Action.createThunkEffect(actionConfig);
  const response = await res;
  if (!response?.data?.Status) {
    dispatch({
      type: DELETE_KSA_LIBRARIES_LOADING,
      payload: false,
    });
  }
};

//#endregion Skill

//#region Knowledge
export const GetKnowledgeList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}knowledges`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA_BY_TYPE,
          payload: resp.data.Data,
          ksaType: Enum_KSA_SkillType.Knowledge,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetKnowledgeById = (knowledgeId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}knowledges/${knowledgeId}`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA__BY_ID,
          payload: resp.data.Data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const CreateKnowledges = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix_ksa}knowledges`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateKnowledges = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix_ksa}knowledges`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteKnowledge = (knowledgeId, props) => async (dispatch) => {
  await dispatch({
    type: DELETE_KSA_LIBRARIES_LOADING,
    payload: true,
  });
  const res = Http.delete(`${urlPrefix_ksa}knowledges?knowledgeId=${knowledgeId}`);
  const actionConfig = {
    dispatch,
    actionType: DELETE_KSA_LIST_BY_TYPE,
    effect: res,
    id: knowledgeId,
    isLoading: true,
    message: "Knowledge has been deleted successfully.",
    type: "delete",
  };
  await Action.createThunkEffect(actionConfig);
  const response = await res;
  if (!response?.data?.Status) {
    dispatch({
      type: DELETE_KSA_LIBRARIES_LOADING,
      payload: false,
    });
  }
};

//#endregion Skill

//#region Ability
export const GetAbilityList = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}abilities`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA_BY_TYPE,
          payload: resp.data.Data,
          ksaType: Enum_KSA_SkillType.Abilities,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetAbilityById = (abilityId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}abilities/${abilityId}`)
    .then((resp) => {
      if (resp.data.Status) {
        dispatch({
          type: GET_JOBPROFILE_KSA__BY_ID,
          payload: resp.data.Data,
        });
      } else {
        toastMessage(dispatch, resp.data);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const CreateAbilities = (data, props) => async (dispatch) => {
  await axios
    .post(`${urlPrefix_ksa}abilities`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const UpdateAbilities = (data, props) => async (dispatch) => {
  await axios
    .put(`${urlPrefix_ksa}abilities`, data)
    .then((resp) => {
      if (resp.data.Status) {
        props.refreshScreen && props.refreshScreen();
      }
      toastMessage(dispatch, resp.data);
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const DeleteAbility = (abilityId, props) => async (dispatch) => {
  await dispatch({
    type: DELETE_KSA_LIBRARIES_LOADING,
    payload: true,
  });
  const res = Http.delete(`${urlPrefix_ksa}abilities?abilitiesId=${abilityId}`);
  const actionConfig = {
    dispatch,
    actionType: DELETE_ABILITY,
    effect: res,
    id: abilityId,
    isLoading: true,
    message: "Ability has been deleted successfully.",
    type: "delete",
  };
  await Action.createThunkEffect(actionConfig);
  const response = await res;
  if (!response?.data?.Status) {
    dispatch({
      type: DELETE_KSA_LIBRARIES_LOADING,
      payload: false,
    });
  }
};

//#endregion Skill

//#region KSA
export const GetKSA_Types = () => async (dispatch) => {
  await axios
    .get(`${urlPrefix_ksa}skills-type`)
    .then((resp) => {
      if (resp.status == 200) {
        let formatedData = [];
        resp.data.map((d) => {
          formatedData.push({label: d.Text, value: d.Value});
        });
        dispatch({
          type: GET_JOBPROFILE_KSA_SKILL_TYPE,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, resp);
      }
    })
    .catch((err) => {
      toastMessage(dispatch, err.message);
    });
};

export const GetKSA_ListByType = (type) => async (dispatch) => {
  if (type === Enum_KSA_SkillType.Knowledge) {
    await dispatch(GetKnowledgeList());
  } else if (type === Enum_KSA_SkillType.Skills) {
    await dispatch(GetSkillList());
  } else if (type === Enum_KSA_SkillType.Abilities) {
    await dispatch(GetAbilityList());
  }
  // dispatch({
  //   type: GET_JOBPROFILE_KSA_BY_TYPE,
  //   payload: data,
  // });
  // await axios
  //   .get(`${urlPrefix_ksa}KSA-by-type?type=${type}`)
  //   .then((resp) => {
  //     if (resp.data.Status) {
  //       dispatch({
  //         type: GET_JOBPROFILE_KSA_BY_TYPE,
  //         payload: resp.data.Data,
  //       });
  //     } else {
  //       toastMessage(dispatch, resp.data);
  //     }
  //   })
  //   .catch((err) => {
  //     toastMessage(dispatch, err.message);
  //   });
};

export const GetKSA_ById = (type, ksaId) => async (dispatch) => {
  if (type === Enum_KSA_SkillType.Knowledge) {
    await dispatch(GetKnowledgeById(ksaId));
  } else if (type === Enum_KSA_SkillType.Skills) {
    await dispatch(GetSkillById(ksaId));
  } else if (type === Enum_KSA_SkillType.Abilities) {
    await dispatch(GetAbilityById(ksaId));
  }
  // await axios
  //   .get(`${urlPrefix_ksa}KSA-by-id?ksaId=${ksaId}`)
  //   .then((resp) => {
  //     if (resp.data.Status) {
  //       dispatch({
  //         type: GET_JOBPROFILE_KSA__BY_ID,
  //         payload: resp.data.Data,
  //       });
  //     } else {
  //       toastMessage(dispatch, resp.data);
  //     }
  //   })
  //   .catch((err) => {
  //     toastMessage(dispatch, err.message);
  //   });
};

export const CreateKSA = (data, props) => async (dispatch) => {
  if (data.Type === Enum_KSA_SkillType.Knowledge) {
    await dispatch(CreateKnowledges(data, props));
  } else if (data.Type === Enum_KSA_SkillType.Skills) {
    await dispatch(CreateSkills(data, props));
  } else if (data.Type === Enum_KSA_SkillType.Abilities) {
    await dispatch(CreateAbilities(data, props));
  }
  // await axios
  //   .post(`${urlPrefix_ksa}create-ksa`, data)
  //   .then((resp) => {
  //     if (resp.data.Status) {
  //       props.refreshScreen && props.refreshScreen();
  //     }
  //     toastMessage(dispatch, resp.data);
  //   })
  //   .catch((err) => {
  //     toastMessage(dispatch, err.message);
  //   });
};

export const UpdateKSA = (data, props) => async (dispatch) => {
  if (data.Type === Enum_KSA_SkillType.Knowledge) {
    await dispatch(UpdateKnowledges(data, props));
  } else if (data.Type === Enum_KSA_SkillType.Skills) {
    await dispatch(UpdateSkills(data, props));
  } else if (data.Type === Enum_KSA_SkillType.Abilities) {
    await dispatch(UpdateAbilities(data, props));
  }
  // await axios
  //   .put(`${urlPrefix_ksa}update-ksa`, data)
  //   .then((resp) => {
  //     if (resp.data.Status) {
  //       props.refreshScreen && props.refreshScreen();
  //     }
  //     toastMessage(dispatch, resp.data);
  //   })
  //   .catch((err) => {
  //     toastMessage(dispatch, err.message);
  //   });
};

export const DeleteKSA = (type, ksaId, props) => async (dispatch) => {
  if (type === Enum_KSA_SkillType.Knowledge) {
    await dispatch(DeleteKnowledge(ksaId, props));
  } else if (type === Enum_KSA_SkillType.Skills) {
    await dispatch(DeleteSkill(ksaId, props));
  } else if (type === Enum_KSA_SkillType.Abilities) {
    await dispatch(DeleteAbility(ksaId, props));
  }
  // await axios
  //   .put(`${urlPrefix_ksa}delete-ksa?ksaId=${ksaId}`)
  //   .then((resp) => {
  //     if (resp.data.Status) {
  //       props.refreshScreen && props.refreshScreen();
  //     }
  //     toastMessage(dispatch, resp.data);
  //   })
  //   .catch((err) => {
  //     toastMessage(dispatch, err.message);
  //   });
};
//#endregion KSA
