import {
  SAVE_MESSAGE_TEMPLATE,
  MESSAGE_TEMPLATE_LIST,
  DELETE_SAVE_MESSAGE_TEMPLATE,
  CLEAR_SAVE_MESSAGE_TEMPLATE,
} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";

export const GetAllMessageTemplate = () => async (dispatch) => {
  await axios
    .get(`/v1/onBoarding/message-template`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: MESSAGE_TEMPLATE_LIST,
          payload: data,
        });
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to fetch message Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const AddMessageTemplate = (data, closeForm) => async (dispatch) => {
  dispatch({
    type: SAVE_MESSAGE_TEMPLATE,
    payload: false,
  });
  await axios
    .post(`/v1/onBoarding/message-template`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_MESSAGE_TEMPLATE,
          payload: true,
        });
        closeForm();
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const UpdateMessageTemplate = (data, closeForm) => async (dispatch) => {
  dispatch({
    type: SAVE_MESSAGE_TEMPLATE,
    payload: false,
  });
  await axios
    .put(`/v1/onBoarding/message-template`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_MESSAGE_TEMPLATE,
          payload: true,
        });
        closeForm();
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const DeleteMessageTemplate = (templateId) => async (dispatch) => {
  dispatch({
    type: DELETE_SAVE_MESSAGE_TEMPLATE,
    payload: false,
  });
  await axios
    .delete(`/v1/onBoarding/message-template/${templateId}`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: DELETE_SAVE_MESSAGE_TEMPLATE,
          payload: true,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to delte salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const ClearSaveMessageTemplate = () => (dispatch) => {
  dispatch({
    type: CLEAR_SAVE_MESSAGE_TEMPLATE,
  });
};
