import React, {Component} from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";
import RouteEnum from "constants/routeEnum";

const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Common Tasks",
    feature: features.TaskTracker,
    links: [
      {title: "Clients", link: RouteEnum.LegalClientPath},
      {
        title: "Activity And Billing Roles",
        link: RouteEnum.ActivityAndBillingRolesPath,
      },
    ],
  },
];

function index(props) {
  return (
    <div className="admin-contain">
      <CommonModuleLinks
        title="Task Tracker Common Task"
        localStorageTitle="TaskTrackerCT"
        modules={modules}
      />
    </div>
  );
}

export default index;
