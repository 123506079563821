import React from "react";
import "./style.scss";
import Avatar from "react-avatar";
import moment from "moment";
import EmployeeDetails from "./employeeDetail";

export default function DateTimeContacts({data}) {
  const {OfficeInTime, JoinDate, Instruction, Place, Contacts, GoogleMapsURL} = data || {
    OfficeInTime: "",
    JoinDate: "",
    Instruction: "",
    GoogleMapsURL: "",
    Place: "",
    Contacts: [],
  };
  return (
    <div className="employee-onboarding__datetime mb-md">
      <div className="employee-onboarding__datetime__header mb-sm text-bold">
        Date, Time and Contacts
      </div>
      <div className="employee-onboarding__datetime__body">
        <div className="employee-onboarding__datetime__date withborder mb-md">
          <div className="employee-onboarding__datetime__date_first flex">
            <div className="wd-20 mb-sm">First day of Office</div>
            <div className="wd-20 mb-sm">Reach Office by</div>
          </div>
          <div className="employee-onboarding__datetime__date_reach flex">
            <div className="wd-20 softtext text-sm mb-sm">
              {moment(JoinDate).format("MMM DD, YYYY")}
            </div>
            <div className="wd-20 softtext text-sm">{OfficeInTime} am</div>
          </div>
        </div>
        <div className="employee-onboarding__datetime__place withborder">
          <div className="mb-sm">PLACE</div>
          <div className="softtext">{Place}</div>
          <a
            href={GoogleMapsURL}
            style={{cursor: "pointer", color: "#0364a5"}}
            target="_blank"
          >
            View in Google Maps
          </a>
        </div>
        <div className="employee-onboarding__contacts withborder">
          {Contacts &&
            Contacts.map((cont, index) => (
              <div className="flex">
                <div className="employee-onboarding__contacts__name">
                  <div className="employee-onboarding__contacts__person .text-md mb-sm bold-500">
                    {" "}
                    {index == 0
                      ? "Contact Person"
                      : index == 1
                      ? "Your Buddy"
                      : "Your Manger"}{" "}
                  </div>
                  <div className="flex">
                    <div className="employee-onboarding__contacts__photo">
                      <Avatar
                        name={"Image"}
                        size={50}
                        // round={false}
                        src={cont?.Photo}
                        round={50}
                      />
                    </div>
                    <div>
                      <div className="contacts__name text-bg">{cont?.EmployeeName}</div>
                      <div className="softtext">{cont?.PhoneNumber}</div>
                      <div className="softtext">{cont?.Email}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="employee-onboarding__contacts__person flex">
            <div className="employee-onboarding__contacts__name">
              <div className="employee-onboarding__contacts__person mb-sm">
                {" "}
                Your Buddy{" "}
              </div>
              <div className="flex">
                <div className="employee-onboarding__contacts__photo">
                  <Avatar
                    name={"Image"}
                    size={50}
                    round={false}
                    src={"https://picsum.photos/200/300"}
                    round={50}
                  />
                </div>
                <div>
                  <div className="contacts__name softtext"> Keshari Shrestha</div>
                  <div className="softtext"> 9878767857</div>
                  <div className="softtext"> 9878767857</div>
                </div>
              </div>
            </div>
          </div>
          <div className="employee-onboarding__contacts__person flex">
            <div className="employee-onboarding__contacts__name">
              <div className="employee-onboarding__contacts__person mb-sm">
                {" "}
                Your Manager{" "}
              </div>
              <div className="flex">
                <div className="employee-onboarding__contacts__photo">
                  <Avatar
                    name={"Image"}
                    size={50}
                    round={false}
                    src={"https://picsum.photos/200/300"}
                    round={50}
                  />
                </div>
                <div>
                  <div className="contacts__name softtext"> Keshari Shrestha</div>
                  <div className="softtext"> 9878767857</div>
                  <div className="softtext"> 9878767857</div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="employee-onboarding__contacts__person flex ml-sm">
            <div className="employee-onboarding__contacts__photo">
              <Avatar
                name={"Image"}
                size={42}
                round={false}
                src={"https://picsum.photos/200/300"}
                round={24}
              />
            </div>
            <div className="employee-onboarding__contacts__name ml-sm">
              <div className="employee-onboarding__contacts__person mb-sm"> Contact Person </div>
              <div className="contacts__name softtext"> Keshari Shrestha</div>
              <div className="softtext"> 9878767857</div>
              <div className="softtext"> 9878767857</div>
            </div>
          </div>
          <div className="employee-onboarding__contacts__person flex ml-sm">
            <div className="employee-onboarding__contacts__photo">
              <Avatar
                name={"Image"}
                size={42}
                round={false}
                src={"https://picsum.photos/200/300"}
                round={24}
              />
            </div>
            <div className="employee-onboarding__contacts__name ml-sm">
              <div className="employee-onboarding__contacts__photo">Contact Person</div>
              <div className="contacts__name softtext">Keshari Shrestha</div>
              <div className="softtext">9878767857</div>
              <div className="softtext">9878767857</div>
            </div>
          </div> */}
        </div>
        <div className="employee-onboarding__datetime__place mb-sm">
          <div className="wd-20 mb-sm">Other things to note</div>
          <div className="wd-20 softtext">{Instruction}</div>
        </div>
      </div>
    </div>
  );
}
