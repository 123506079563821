import React, { useState, useEffect } from 'react'
import PerformanceFilters from './performanceFilters';
import Button from 'components/button';
import PerformanceTable from './performanceTable';
import BatchRollOutModal from './batchRollOutModal';
import Modal from 'components/modal';
import { useDispatch } from 'react-redux';
import { getAppraisalRollout } from '../helpers/action';

const initialFilters = {
  reviewCycleId: null,
  // employee: null,
  managerId: null,
  branchId: null,
  departmentId: null,
  status: null,
  pageIndex: 0,
  pageSize: 10
}

function PerformanceAppraisals() {
  const [filters, setFilters] = useState(initialFilters);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  
  useEffect(()=>{
    getRollout()
  },[])
  const getRollout = () => dispatch(getAppraisalRollout({
    ...filters,
    branchId:filters?.branchId?.value,
    reviewCycleId:filters?.reviewCycleId?.value,
    managerId:filters?.managerId?.value,
    departmentId:filters?.departmentId?.value,
    status:filters?.status?.value,
  }))
  const onChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleRowChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0
      }
    })
  };

  const handleIndexChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageIndex: num
      }
    })
  };
  return (
    <div className='appraisals-container'>
      <div className="appraisals-title">
        <span>Performance Appraisals</span>
        <Button 
          onClick={()=>setOpen(true)}
          title='Batch Roll out' bg="secondary-light" size="small" />
        <Modal
          open={open}
          onModalClose={onModalClose}
          title="Batch Roll Out"
          width='800px'
          className='appraisals-modal'  
        >
            <BatchRollOutModal onModalClose={onModalClose} />
        </Modal>
      </div>
      <PerformanceFilters getRollout={getRollout} onChange={onChange} filters={filters} />
      <PerformanceTable  handleIndexChange={handleIndexChange} handleRowChange={handleRowChange} {...filters} />
    </div>
  )
}

export default PerformanceAppraisals