import React, {Component} from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
  // SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import TableRows from "./tableRow";

export class ActivityReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.widths = {
      checklist: 100,
      name: 100,
    };
    this.gridTemplate = this.gridTemplate.bind(this);
    this.row = [];
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({data: this.props.data});
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data && prevProps.data !== this.props.data) {
      this.setState({data: this.props.data});
      const ele = document.querySelector(".e-headercell.e-mousepointer");
      setTimeout(() => {
        if (ele) {
          ele.click();
        }
      }, [500]);
    }
  }

  gridTemplate(props, history) {
    return (
      <TableRows
        {...props}
        {...history}
        ref={(row) => {
          this.row = [...this.row, row];
        }}
        editSelectedTemplate={this.props.editSelectedTemplate}
        handleConfirmDelRecord={this.props.handleConfirmDelRecord}
        handleModalShow={this.props.handleModalShow}
      />
    );
  }

  render() {
    const {data} = this.state;

    if (!data) {
      return null;
    }

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <GridComponent
            className="custom-grid"
            dataSource={data}
            id="grid"
            ref={(g) => (this.grid = g)}
            rowTemplate={this.gridTemplate}
          >
            <ColumnsDirective>
              <ColumnDirective width={30} />
              <ColumnDirective
                field="CheckListName"
                headerText="Employee Group"
                width={this.widths.checklist}
              />
              <ColumnDirective field="Name" headerText="Item" width={this.widths.name} />
              <ColumnDirective width={100} />
            </ColumnsDirective>
          </GridComponent>
        </div>
      </div>
    );
  }
}

export default ActivityReportTable;
