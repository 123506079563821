import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween)

export const CALENDAR_WEEKS = 6;

export const getDateArray = (date: any = new Date()) => {
    let formatedDate = dayjs(date);
    let year = formatedDate.get('year');
    let month = formatedDate.get('month') + 1;
    let day = formatedDate.get('date');
    return [year, month, day];
};



export const getMonthDays = (date: any = new Date()) => dayjs(date).daysInMonth();
  

export const getMonthFirstDay = (date: any = new Date()) => dayjs(date).startOf("month").day() ;


export const getPreviousMonth = (date: any = new Date()) => {
    const [year, month, day] = getDateArray(date);
    let yr =  month > 1 ? year : year - 1;
    let mt = month > 1 ? month - 1 : 12;
    return {
      month: mt,
      year:yr,
      day,
      date:new Date(yr,mt - 1,day)
    };
};
  

export const getNextMonth = (date: any = new Date()) => {
    const [year, month, day] = getDateArray(date);
    let yr = month < 12 ? year : year + 1;
    let mt = month < 12 ? month + 1 : 1
    return {
      month: mt,
      year: yr,
      day,
      date:new Date(yr,mt-1,day)
    };
};


export const getPreviousYear = (date: any = new Date()) => {
    const [year, month, day] = getDateArray(date);
    return {
      month: month,
      year: year - 1,
      day,
      date:new Date(year-1,month-1,day)
    };
};


export const getNextYear = (date: any = new Date()) => {
    const [year, month, day] = getDateArray(date);
    return {
      month: month,
      year: year + 1,
      day,
      date:new Date(year+1,month-1,day)
    };
};


export const inBetween = (from: any, to: any, date: any) => dayjs(date).isBetween(from, dayjs(to));

export const isBeforeDay = (date1: any, date2: any) =>
    dayjs(date1).isBefore(dayjs(date2));


export const isAfterDay = (date1: any, date2: any) =>
    dayjs(date1).isAfter(dayjs(date2));


export const isSameDay = (date1: any, date2: any) =>
    dayjs(date1).isSame(dayjs(date2));


export const isSameMonth = (date1: any, date2: any) =>
    dayjs(date1).isSame(dayjs(date2),'month');



export const calendarDates = (date: any = new Date()) => {
    
    const monthDays = getMonthDays(date);
    
    const monthFirstDay = getMonthFirstDay(date) + 1;
    const [year, month] = getDateArray(date);
    const daysFromPrevMonth = monthFirstDay ? monthFirstDay - 1 : 6;
    const daysFromNextMonth =
        CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);
    
    const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(date);
    const { month: nextMonth, year: nextMonthYear } = getNextMonth(date);
    
    const prevMonthDays = getMonthDays(new Date(prevMonthYear, prevMonth - 1));
    const prevMonthDates =
        daysFromPrevMonth ?
        [...new Array(daysFromPrevMonth)]?.map((n, index) => [
        prevMonthYear,
        prevMonth,
        index + 1 + (prevMonthDays - daysFromPrevMonth),
        ]) : [];
    const thisMonthDates =
        monthDays ?
        [...new Array(monthDays)]?.map((n, index) => [year, month, index + 1]) : [];
    
    const nextMonthDates =
        daysFromNextMonth ?
        [...new Array(daysFromNextMonth)]?.map((n, index) => [
        nextMonthYear,
        nextMonth,
        index + 1,
        ]) : [];
    
    return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
      

export const getPagedYears = (year:any, yearSetting:any) => {
    // const { maxdate, mindate, year, month, today } = this.state;
    // const [maxYear] = getNepDateArray(maxdate);
    // const [minYear] = getNepDateArray(mindate);
    const maxYear = 2100;
    const minYear = 0;
  
    let rightArrow = false;
    let leftArrow = false;
    const allYears = Array(maxYear - minYear)
      .fill(minYear)
      .map((val, index) => {
        return { year: val + index };
      });
    const yearIndex = allYears.findIndex((item) => item.year === year);
    // const yearIndex = allYears.findIndex((item)=>item.year===thisYear);
    const minIndex = allYears.findIndex((item) => item.year === minYear);
    const maxIndex = allYears.findIndex((item) => item.year === maxYear - 1);
    var SplittedYears = [];
    const { indexPoint, indexLeap } = yearSetting;
    if (maxIndex - yearIndex < 11) {
      if (maxIndex - yearIndex - indexPoint * indexLeap < 11) {
        SplittedYears = allYears.slice(
          yearIndex - 6 + indexPoint * indexLeap,
          maxIndex + 1 + indexPoint * indexLeap
        );
        rightArrow = true;
      } else if (yearIndex - minIndex + indexLeap * indexPoint < 11) {
        SplittedYears = allYears.slice(0, 18);
        leftArrow = true;
      } else {
        SplittedYears = allYears.slice(
          yearIndex - 6 + indexPoint * indexLeap,
          yearIndex + 10 + indexPoint * indexLeap
        );
        rightArrow = false;
      }
    } else if (maxIndex - yearIndex - indexPoint * indexLeap < 11) {
      SplittedYears = allYears.slice(
        yearIndex - 6 + indexPoint * indexLeap,
        maxIndex + indexPoint * indexLeap
      );
      rightArrow = true;
    } else if (yearIndex - minIndex + indexLeap * indexPoint < 11) {
      SplittedYears = allYears.slice(0, 18);
      leftArrow = true;
    } else {
      SplittedYears = allYears.slice(
        yearIndex - 6 + indexPoint * indexLeap,
        yearIndex + 12 + indexPoint * indexLeap
      );
    }
    return { leftArrow, rightArrow, SplittedYears };
  };
  