import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Avatar from "react-avatar";
import {formatedFullDayMMYearTime} from "helpers/dateFormat";
import {Rating} from "react-simple-star-rating";
import isEmpty from "helpers/isEmpty";
import {updateTaskRating} from "../../../../../helpers/action";

const TaskRating = (props) => {
  const {data, onRatingsUpdated, showAddBtn, setShowAddBtn} = props;

  useEffect(() => {
    setRating(data.Rating);
  }, [data.Rating]);

  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const {auth} = useSelector((state: any) => state);
  const base = auth && auth.baseUrl ? auth.baseUrl.NetFrameworkAppBaseUrl : "";

  const submitRating = async (value) => {
    var res = await dispatch(updateTaskRating({TaskId: data.TaskRefId, Rating: value}));

    if (!isEmpty(res)) {
      onRatingsUpdated(res);
    } else {
      setRating(data.Rating);
    }
  };

  const handleRating = (rate: number) => {
    setRating(rate);
    submitRating(rate);
  };

  useEffect(() => {
    if (data && isEmpty(data.Comments)) {
      setShowAddBtn(true);
    } else setShowAddBtn(false);
  }, [data]);
  return (
    <div className="taskmodal__ratings-comment">
      <div className="comment">
        {data && !isEmpty(data.RatedOn) && (
          <div className="comment-header">
            <div className="comment-header-item">
              <div className="comment-avatar">
                <Avatar
                  className="avatar"
                  src={`${base}${data.RatedByImagePath}`}
                  name={data.RatedBy}
                  size={"30"}
                  round={true}
                />
              </div>
              <div className="comment-title">
                <p> {data.RatedBy}</p>
                <span>{formatedFullDayMMYearTime(data.RatedOn)}</span>
              </div>
            </div>
          </div>
        )}
        <div className="comment-block">
          <Rating
            onClick={handleRating}
            ratingValue={rating}
            size={22}
            fillColor="#f59213"
          />
          {data.Rating > 0 && (
            <div
              className="comment-block-action active"
              style={{marginLeft: 0}}
              onClick={() => submitRating(0)}
            >
              <span>Clear Rating</span>
            </div>
          )}
        </div>

        {showAddBtn && (
          <div className="comment-block">
            <div
              className="comment-block-action active"
              style={{marginLeft: 0}}
              onClick={() => setShowAddBtn(false)}
            >
              <span>Add Comment</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskRating;
