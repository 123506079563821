export default (diff) => {
  let minutes = diff % 60;
  let hours = (diff - minutes) / 60;

  if (diff < 0) {
    diff = 1440 + diff;
    minutes = diff % 60;
    hours = (diff - minutes) / 60;
  }
  return `${hours}h ${minutes}m`;
};
