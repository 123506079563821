import Table from "components/customTable";
import Pagination from "components/table/pagination";
import React from "react";
import dayjs from "dayjs";
import AttendanceReview from "./attendanceReviewButton";
import CheckInOutCell from "./tableCell/checkInOutCell";
import AttendanceCell from "./tableCell/attendanceCell";
const DateCell = ({ value }) => {
  return <span>{dayjs(value).format("YYYY-MM-DD")}</span>;
};

function ShiftTable(props) {
  const {
    data,
    handleRowChange,
    handleIndexChange,
    pageIndex,
    rowsPerPage,
    filterState,
    onSuccessSavingAttendance,
  } = props;

  const cols = [
    {
      Header: "Date",
      accessor: "ScheduleDate",
      width: 140,
      Cell: DateCell,
    },
    {
      Header: "Name",
      accessor: "EmployeeName",
      width: 200,
    },
    {
      Header: "Team",
      accessor: "TeamName",
      width: 200,
    },
    {
      Header: "Shift",
      accessor: "ShiftName",
    },
    {
      Header: "Attendance",
      accessor: "ErrorInfo",
      Cell: AttendanceCell,
    },
    {
      Header: "In/Out Time",
      accessor: "IsMathced",
      Cell: CheckInOutCell,
      width: 200,
    },
    {
      Header: "",
      accessor: "TeamId",
      Cell: (cellProps) => (
        <AttendanceReview
          {...cellProps}
          filterState={filterState}
          onSuccessSavingAttendance={onSuccessSavingAttendance}
        />
      ),
    },
  ];
  const totalData = data && data.length > 0 ? data[0].TotalData : 0;
  return (
    <div className="workHours-table ">
      <Table isFixedWidth data={data ?? []} columns={cols} />

      {totalData > 0 ? (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={rowsPerPage}
          total={totalData}
        />
      ) : null}
    </div>
  );
}

export default ShiftTable;
