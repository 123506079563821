import React, { useEffect, useState, useRef } from "react";
import Button from "components/button";
import { FaRegSmile } from "react-icons/fa";
import { IoIosClose, IoIosAt } from "react-icons/io";
import { IoIosPaperPlane } from "react-icons/io";
import EmojiButton from "./emojiButton";
import classNames from "classnames";
import isEmpty from "helpers/isEmpty";
import AttachmentButton from "./attachmentButton"

const CustomToolbar = (props) => {
  const { enableCancelButton, onCancelClicked, value, onAttachmentChange, files, setUploading } = props;

  const [showEmoji, setShowEmoji] = useState(false);
  const ref = useRef(null);
  const onClickEmoji = (e: any) => {
    setShowEmoji((showEmoji) => !showEmoji);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.classList.remove("ql-toolbar");
      ref.current.classList.remove("ql-snow");
    }
  }, []);
  const isEmtpyValue = () => {
    return isEmpty(value);
  };
  return (
    <div id="toolbar" className="quill-toolbar" ref={ref}>
      <div className="quill-toolbar-tools">
        <div className="quill-toolbar-tools-left">
          {enableCancelButton && (
            <>
              <Button
                isDisabled={isEmtpyValue()}
                bg="primary-dark"
                title="Save"
                onClick={() =>
                  !isEmtpyValue() && props.onSubmit && props.onSubmit(false)
                }
              />

              <Button
                bg="secondary-dark"
                title="Cancel"
                onClick={() => {
                  onCancelClicked && onCancelClicked();
                }}
              />
            </>
          )}
        </div>
        <div className="quill-toolbar-tools-right">
          <div className="quill-toolbar-tools-icon">
            <span onClick={() => props.onMentionClicked()}>
              <IoIosAt />
            </span>
          </div>
          <div className="quill-toolbar-tools-icon">
            <EmojiButton
              rootEl=".modal__content-body"
              scrollableEl=".taskmodal__activities-content"
              iconSize={16}
              iconName="simle"
              onEmojiSelected={(item) => props.onEmojiClicked(item)}
            />
          </div>
          <div className="quill-toolbar-tools-icon">
            <AttachmentButton
              name="attachment"
              value={files}
              setUploading={setUploading}
              onChange={(name, items) => onAttachmentChange && onAttachmentChange(name, items)}
              isMultiple />
          </div>
          {!enableCancelButton && (
            <div
              onClick={() =>
                !isEmtpyValue() && props.onSubmit && props.onSubmit(true)
              }
              className={classNames("quill-toolbar-tools-icon", {
                disabled: isEmtpyValue(),
                selected: !isEmtpyValue(),
              })}
            >
              <span>
                <IoIosPaperPlane fontSize="21px" />
              </span>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default CustomToolbar;
