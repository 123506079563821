import React, {useEffect, useState} from "react";
import "./styles.scss";
import sample from "assets/images/sample.jpg";
import {IoMdArrowBack} from "react-icons/io";
import {FaTimesCircle, FaPlusCircle} from "react-icons/fa";
import {GrDrag} from "react-icons/gr";
import MainPageForm from "./mainPageForm";
import shortid from "shortid";
import PageForm from "./pageForm";
import {useDispatch, useSelector} from "react-redux";
import SvgIcons from "components/icons/svgIcons";
import {AiOutlineDrag} from "react-icons/ai";
import {
  deleteCoursePage,
  getCourse,
  getCourseContent,
  getCoursePage,
  getCoursePages,
  getQuestionDetails,
  resetCourseReducer,
  updateMultipleCoursePage,
} from "../helpers/action";
import RouteEnum from "constants/routeEnum";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

import {Link as ReactLink} from "react-router-dom";
import isEmpty from "helpers/isEmpty";
import {MdDelete, MdEdit} from "react-icons/md";
import classNames from "classnames";
import DeleteBtn from "../common/deleteBtn";
import ImageRenderer from "../common/imageRenderer";
import {BsPlus} from "react-icons/bs";
const pages: any = [
  {
    id: shortid.generate(),
    data: null,
    isMain: true,
  },
];
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#2693FF" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function AddCourse(props) {
  const [state, setState] = useState(pages);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const {courseId, pageType, pageId} = props.match.params;

  const {coursePages, course} = useSelector((state: any) => state.lmsReducer);

  useEffect(() => {
    if (pageId) {
      dispatch(getCoursePage(pageId));
      dispatch(getCourseContent(pageId));
      dispatch(getQuestionDetails(pageId));
    }
  }, [pageId]);
  useEffect(() => {
    if (coursePages) {
      setData(coursePages);
    }
  }, [coursePages]);
  const addPage = () => {
    setState((prev) => [
      ...prev,
      {
        id: shortid.generate(),
        data: null,
      },
    ]);
  };
  const onActive = (id) => props.history.push(RouteEnum.lmsCourseAdd + "/" + courseId);

  const onDeleteCoursePage = async (courseId, id) => {
    const res: any = await dispatch(deleteCoursePage(id));
    if (res && res.Status && `${id}` == pageId) {
      props.history.push(RouteEnum.lmsCourseAdd + "/" + courseId);
    }
  };

  useEffect(() => {
    if (courseId) {
      dispatch(getCourse(courseId));
      dispatch(getCoursePages(courseId));
    } else {
      dispatch(resetCourseReducer());
    }
  }, [courseId]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    const newItems = items.map((item: any, index) => ({...item, PageOrder: index}));
    await dispatch(updateMultipleCoursePage(newItems));
    setData(newItems);
  };

  const isEmptyFile = (file) => {
    if (isEmpty(file)) return true;
    if (file.toLowerCase() === "uploads/") return true;
    return false;
  };
  return (
    <div className="courseForm">
      <div className="courseForm__header">
        <div
          onClick={() => props.history.push(RouteEnum.lmsCourseManagement)}
          className="courseForm__header-back"
        >
          <IoMdArrowBack />
        </div>

        <>
          <div className="courseForm__header-cover">
            <ImageRenderer src={course?.ServerFileName} alt={course?.Title} />
          </div>

          <div className="courseForm__header-title">
            <span>{course?.Title}</span>
          </div>
        </>
      </div>
      <div className="courseForm__body froala-addcontainer">
        <div className="courseForm__body-container">
          {pageType ? (
            <PageForm pageId={pageId} courseId={courseId} {...props} />
          ) : (
            <MainPageForm {...props} />
          )}
        </div>

        {course && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="courseForm__body-sidebar">
              <div className="course-card">
                <div className="courseForm__body-sidebar-title">Outline</div>
                <Droppable droppableId="course-pages" type="collection">
                  {(provided, snapshot) => (
                    <ul
                      className="courseForm__body-sidebar-list"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      nf={provided}
                    >
                      <li
                        onClick={() =>
                          props.history.push(`${RouteEnum.lmsCourseAdd}/${courseId}`)
                        }
                        className="courseForm-sidebar-title"
                      >
                        <div className="courseForm__body-sidebar-cover">
                          <ImageRenderer
                            src={course?.ServerFileName}
                            alt={course?.Title}
                          />
                        </div>

                        <div className="courseForm__body-sidebar-content">
                          {course?.Title}
                        </div>
                      </li>
                      {data &&
                        data.map((item, index) => {
                          return (
                            <Draggable
                              key={"Page-" + item.Id}
                              draggableId={"Page-" + item.Id}
                              index={index}
                              disableInteractiveElementBlocking="true"
                            >
                              {(provided, snapshot) => (
                                <li
                                  {...provided.draggableProps}
                                  // {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className={classNames("courseForm__body-sidebar-item", {
                                    selected: pageId == item.Id,
                                  })}
                                  onClick={() =>
                                    props.history.push(
                                      `${RouteEnum.lmsCourseAdd}/${item.CourseId}/page/${item.Id}`
                                    )
                                  }
                                  // className="courseForm__body-sidebar-item selected"
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className="courseForm__body-sidebar-icon"
                                  >
                                    {/* <SvgIcons name="dragger" /> */}
                                    <AiOutlineDrag />
                                  </div>
                                  <div className="courseForm__body-sidebar-content">
                                    {item.PageName}
                                  </div>
                                  <div
                                    className="courseForm__body-sidebar-item-edit"
                                    onClick={() =>
                                      onDeleteCoursePage(item.CourseId, item.Id)
                                    }
                                  >
                                    <MdDelete />
                                  </div>

                                  {/* <DeleteBtn
                                    title="Delete page!!"
                                    onConfirm={() =>
                                      onDeleteCoursePage(item.CourseId, item.Id)
                                    }
                                    className="courseForm__body-sidebar-item-edit"
                                  /> */}
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>

                <ReactLink
                  to={`${RouteEnum.lmsCourseAdd}/${courseId}/page`}
                  className="courseForm__body-sidebar-add justify-end"
                >
                  <BsPlus /> Add page
                </ReactLink>
              </div>
            </div>
          </DragDropContext>
        )}
      </div>
    </div>
  );
}

export default AddCourse;
