import React from "react";
import {connect} from "react-redux";
import {JobApplyPath, JobDetailsPath} from "routes/commonLinks";
import {withRouter} from "react-router-dom";
import isEmpty from "helpers/isEmpty";
export class CareerDetails extends React.Component {
  onBlockClick = (event, jobDetails) => {
    if (event?.target?.id === "applyBtn") {
      this.props.history.push({
        pathname: JobApplyPath,
        state: {jobDetails},
      });
    } else {
      this.props.history.push(JobDetailsPath + jobDetails.Jobid);
    }
  };
  render() {
    let {activeJobs} = this.props.jobListingReducer;
    let {onBlockClick} = this.props;
    debugger;
    return (
      <div className="career-details">
        {!isEmpty(activeJobs) &&
          activeJobs?.map((x) => {
            return (
              <div
                className="career-details__block block"
                onClick={(event) => this.onBlockClick(event, x)}
                key={x.Jobid}
              >
                <div className="block__row">
                  <span className="career-block__header">{x.ExternalJobTitle}</span>
                </div>
                <div className="block__row">
                  <span className="career-block__position">{x.Education}</span>
                  {x.IsExperience ? (
                    <div>
                      <span>
                        {x.Experience} {x.Experience === 1 ? "Year" : "Years"} Experience
                      </span>
                      <span>{x.Department}</span>
                    </div>
                  ) : (
                    <span>No experience required</span>
                  )}
                </div>
                <div className="block__row">
                  <span>{x.PublisheDate}</span>
                  <span>{x.EndDate}</span>
                </div>
                <div className="block__row flex">
                  <div className="grid">
                    <span className="remaining-days">{x.DaysRemaining}</span>
                    <button
                      onClick={(event) => this.onBlockClick(event, x)}
                      type="button"
                      id="applyBtn"
                      className="apply-btn"
                    >
                      Apply
                    </button>
                  </div>
                  <div>
                    <span className="contract-type">{x.EmployementType}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobListingReducer: state.jobListingReducer,
});

export default withRouter(connect(mapStateToProps, {})(CareerDetails));
