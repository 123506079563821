import * as React from "react";
import EmployeeDetails from "scenes/common/employeeDetails";
import {FaThumbsUp, FaMinusCircle} from "react-icons/fa";
import isEmpty from "isEmpty";
import classNames from "classnames";

interface IAppProps {
  employeeOnLeave: any[];
  division: string;
  handleDivisionChange: Function;
}

const EmployeeOnLeave: React.FunctionComponent<IAppProps> = (props) => {
  const {employeeOnLeave, division} = props;
  return (
    <div className="emp-onleave ">
      <div className="emp-onleave__header flex justify-between">
        <span className="header-title">
          These team members are on leave on those dates
        </span>
        <div className="flex">
          <span
            className={classNames({
              "ml-sm tab-title": true,
              active: division === "dept",
            })}
            onClick={() => props.handleDivisionChange("dept")}
          >
            DEPT
          </span>
          <span
            className={classNames({
              "ml-sm tab-title": true,
              active: division === "branch",
            })}
            onClick={() => props.handleDivisionChange("branch")}
          >
            BRANCH
          </span>
        </div>
      </div>
      <div className="emp-onleave__body flex-column">
        {!isEmpty(employeeOnLeave) ? (
          employeeOnLeave?.map((item) => {
            return (
              <div className="emp-onleave__body-row">
                <div>
                  <EmployeeDetails empDetails={item} size={40} />
                </div>
                <div className="flex-column">
                  <span>{item.LeaveType}</span>
                  <span className="date">{item.LeaveDate}</span>
                </div>
                <div className="flex-column">
                  <span>{item.Status}</span>
                  <span className="date">{item.RequestedDate}</span>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex">
            <div className="thump-up">
              <FaThumbsUp />
            </div>
            <span className="ml-sm">Nobody is on leave on those days, yet.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeOnLeave;
