import React from "react";
import FormGroup from "components/form";
import Button from "components/button";
import Icons from "components/icons";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import isEmpty from "isEmpty";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import {Features} from "helpers/getPermissions";
import getModules from "scenes/employee/reports/getModules";
import {useSelector} from "react-redux";

// const favTitle = 'FavLinks'

interface commonProps {
  title: string;
  localStorageTitle: string;
  modules: Function;
  // modules: moduleProps[];
  baseUrl?: string;
}

interface moduleProps {
  groupTitle: string;
  feature?: string;
  links: linksProps[];
}

interface linksProps {
  title: string;
  link: string;
  isReact?: boolean;
}

interface moduleCompProps {
  groupTitle: string;
  localStorageTitle?: string;
  searchValue?: string;
  links: any;
  isReact?: boolean;
  isFavorite?: boolean;
  setFav?: any;
  ExpandAll?: boolean;
  baseUrl?: string;
  isBaseUrl?: boolean;
}

function CommonTasks({baseUrl, title, localStorageTitle, modules}: commonProps) {
  const [search, setSearch] = React.useState("");
  const [items, setItems] = useLocalStorage(localStorageTitle, []);
  const setFav = (fav) => setItems(fav);
  const features = Features();

  const {
    hrCommonSettings: {EmpDashboardShowPayslip},
  } = useSelector((state: any) => state.auth);

  // const {modules} = getModules(features, EmpDashboardShowPayslip);
  const mods = modules(features, EmpDashboardShowPayslip);

  return (
    <div className="admin admin-common">
      <div className="admin-container">
        <div className="admin-title">
          <h1>{title}</h1>
        </div>
        <div className="admin-modules">
          <div className="admin-modules__container">
            <div className="admin-modules__block">
              <div className="admin-modules__search">
                <FormGroup
                  formName="searchInput"
                  name="search"
                  value={search}
                  onChange={(name, value) => setSearch(value)}
                  placeholder="Search.."
                />
              </div>

              <div className="admin-modules__contain">
                {items && items.length > 0 && (
                  <div className="admin-modules__list admin-modules__list-fav">
                    <ModuleMain
                      localStorageTitle={localStorageTitle}
                      groupTitle="Favorites"
                      searchValue={search}
                      isFavorite
                      setFav={setFav}
                      links={items}
                      baseUrl={baseUrl}
                      isBaseUrl={!isEmpty(baseUrl)}
                    />
                  </div>
                )}
                <ul className="admin-modules__list">
                  {mods &&
                    mods.map((item, index) => {
                      // isFeatureEnabledValue
                      const feat = item.feature ? item.feature : "CoreHR";

                      if (!isFeatureEnabledValue(feat)) {
                        return false;
                      }
                      return (
                        <ModuleMain
                          key={`${index}-${item.groupTitle}`}
                          localStorageTitle={localStorageTitle}
                          setFav={setFav}
                          searchValue={search}
                          groupTitle={item.groupTitle}
                          links={item.links}
                          baseUrl={baseUrl}
                          isBaseUrl={!isEmpty(baseUrl)}
                        />
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ModuleMain = ({
  groupTitle,
  links,
  isFavorite,
  setFav,
  localStorageTitle,
  searchValue,
  baseUrl,
  isBaseUrl,
}: moduleCompProps) => {
  const [listItems, setListItems] = React.useState([]);

  const moduleClasses = classnames({
    "admin-module": true,
  });
  const onFavicon = (fav) => setFav(fav);
  React.useEffect(() => {
    setListItems(links);
  }, [links]);
  React.useEffect(() => {
    if (!isEmpty(searchValue)) {
      setListItems(
        links.filter((item) =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setListItems(links);
    }
  }, [searchValue]);

  const mainClass = classnames({
    "admin-module__main": true,
    "admin-module__main-fav": isFavorite,
  });

  return (
    <li className="admin-modules__item">
      <div className={moduleClasses}>
        <div className={mainClass}>
          <div className="admin-module__title">
            <span>{groupTitle}</span>
          </div>
        </div>
        <div className="admin-module__links">
          {!isEmpty(listItems) ? (
            <ul className="admin-module__links-list">
              {
                <TransitionGroup className="todo-list">
                  {listItems.map((item, id) => {
                    return (
                      <CSSTransition key={id} timeout={500} classNames="item">
                        <ModuleLinks
                          localStorageTitle={localStorageTitle}
                          onFavLink={onFavicon}
                          link={item.link}
                          title={item.title}
                          isReact={item.isReact ? true : false}
                          baseUrl={baseUrl}
                          isBaseUrl={isBaseUrl}
                          showPayslip={item?.showPayslip}
                        />
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              }
            </ul>
          ) : null}
        </div>
      </div>
    </li>
  );
};

const ModuleLinks = ({
  title,
  link,
  onFavLink,
  localStorageTitle,
  baseUrl,
  isBaseUrl,
  isReact,
  showPayslip,
}) => {
  const localItem = JSON.parse(localStorage.getItem(localStorageTitle));
  const isThere = localItem && !isEmpty(localItem.find((item) => item.title === title));
  const activeClass = classnames({
    "admin-module__link-favicon": true,
    "active-icon": isThere,
  });
  if (showPayslip === false) {
    return null;
  }
  return (
    <li className="admin-module__links-item">
      <div className="admin-module__link">
        {isThere ? (
          <span onClick={() => onFavLink({link, title})} className={activeClass}>
            <Icons name="Fav" justSVG />
          </span>
        ) : (
          <span onClick={() => onFavLink({link, title})} className={activeClass}>
            <Icons name="FavBorder" justSVG />
          </span>
        )}

        {isBaseUrl && !isReact ? (
          <a href={baseUrl + link} className="admin-module__link-click" target="_blank">
            <div className="admin-module__link-main">
              <div className="admin-module__link-title">
                <span>{title}</span>
              </div>
            </div>
          </a>
        ) : (
          <Link to={link} className="admin-module__link-click">
            <div className="admin-module__link-main">
              <div className="admin-module__link-title">
                <span>{title}</span>
              </div>
            </div>
          </Link>
        )}

        <div className="admin-module__link-new"></div>
      </div>
    </li>
  );
};

// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue

      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      if (isEmpty(localStorage.getItem(key))) {
        let items = [];
        items.push(value);
        setStoredValue(items);
        localStorage.setItem(key, JSON.stringify(items));
      } else {
        let a = JSON.parse(localStorage.getItem(key));

        if (!isEmpty(a.find((item) => item.title === value.title)))
          a = a.filter((item: any) => item.title !== value.title);
        else a.push(value);
        setStoredValue(a);
        localStorage.setItem(key, JSON.stringify(a));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default CommonTasks;
