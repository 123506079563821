import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UploadFiles} from "actions/commonAction";
import isEmpty from "isEmpty";
import {GoCloudUpload} from "react-icons/go";
import Modal from "components/modal";
// import LoginPreview from '../../Login/LoginPreview'
// import { AnimateShowHide } from 'helpers/animation';
import {FiUpload} from "react-icons/fi";
import {FaRegFileImage, FaImage} from "react-icons/fa";
import {IoIosMove} from "react-icons/io";
import Cropper from "react-easy-crop";
import rigo from "assets/images/rigo-logo-vector.svg";
import getCroppedImg from "helpers/cropImage";
import banner from "assets/images/Login-Banner.png";
import Button from "components/button";

const CropCoverImage = function (props) {
  const {isOpen, onModalClose, companyInformation, resetImages} = props;
  const dispatch = useDispatch();
  const {auth} = useSelector((state: any) => state);

  const [zoom, setZoom] = useState(1);

  const [state, setState] = useState({
    coverImage: "",
    coverImage_url: "",
    crop: {x: 0, y: 0},
    croppedAreaPixels: "",
    croppedImage: "",
  });

  const base = auth.baseUrl ? auth.baseUrl.NetFrameworkAppBaseUrl : "";
  const publicRecruitmentBaseUrl = auth.publicRecruitmentBaseUrl
    ? auth.publicRecruitmentBaseUrl.PublicRecruitmentBaseUrl
    : "";

  const imageUrl = base + companyInformation.CoverImage;

  const onImageChange = (e) => {
    const accept = ["image/png", "image/jpeg"];
    const {name, files} = e.target;

    if (accept.includes(files[0].type)) {
      setState({
        ...state,
        [name + "_url"]: URL.createObjectURL(files[0]),
        [name]: files[0],
      });
    }
  };

  const handleSave = async () => {
    const coverImage: any = state.coverImage;
    if (!coverImage) return;

    const fd = new FormData();
    fd.append("files", coverImage);
    dispatch(UploadFiles(fd, true, "coverImage"));
  };
  const handleModelClose = () => {
    onModalClose && onModalClose();
    setState({
      coverImage: "",
      coverImage_url: "",
      crop: {x: 0, y: 0},
      croppedAreaPixels: "",
      croppedImage: "",
    });
  };
  const reset = () => {
    setState({
      coverImage: "",
      coverImage_url: "",
      crop: {x: 0, y: 0},
      croppedAreaPixels: "",
      croppedImage: "",
    });
  };

  const imgSrc: any = isEmpty(state.coverImage_url) ? imageUrl : state.coverImage_url;
  return (
    <Modal
      open={isOpen}
      onModalClose={handleModelClose}
      title={"Update Cover Image"}
      className="cover-image-modal"
    >
      <div>
        <div className="flex">
          <div className="cover-image-preview">
            <img className="cover-image-preview-img" src={imgSrc} />
          </div>
        </div>
        <div className="cover-image-footer">
          <div className="cover-image-footer-action">
            <div>
              <div className="upload-btn-wrapper">
                <input
                  type="file"
                  name="coverImage"
                  accept="image/png, image/jpeg"
                  onChange={(e) => onImageChange(e)}
                />
                <button className="upload-btn upload-btn-white with-icon">
                  <FaImage />
                  Upload New Cover Image
                </button>
              </div>
            </div>
            <div className="btn-group">
              <Button
                isDisabled={isEmpty(state.coverImage_url)}
                title="Save Changes"
                onClick={() => handleSave()}
                bg="primary"
              />
              <Button title="Close" bg="secondary" onClick={handleModelClose} />
            </div>
          </div>

          <div className="cover-image-footer-instruction">
            <span>
              Please upload JPG or PNG image. The image should be 1600 x 600 px (width x
              height )
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CropCoverImage;
