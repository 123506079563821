import React from "react";
import Table from "components/customTable";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import Pagination from "components/common/pagination";
import {TaskBoards} from "common-links";
import {Link} from "react-router-dom";
import Icons from "components/icons";
import value from "environment";
import isEmpty from "helpers/isEmpty";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const LastActivityDate = ({value}) => {
  return (
    <div>
      {
        //@ts-ignore
        value && dayjs(value).fromNow(true)
      }
      {value && " ago"}
    </div>
  );
};

const NumberCell = ({value}) => {
  return (
    <div className="task-summary-number">
      {!isEmpty(value) && value != "0" ? value : "-"}
    </div>
  );
};

function TaskReportTable(props) {
  const {
    isComplete,
    handleRowChange,
    handleIndexChange,
    pageIndex,
    pageSize,
    tableData,
    handleSort,
  } = props;
  // const {boardDetails} = useSelector((state: any) => state.taskReducer);

  const BoardViewCell = (props) => {
    const {value, cell} = props;
    const {original} = cell.row;
    const {BoardId, DataType} = original;
    return (
      <div>
        {DataType === "board" ? (
          <Link
            to={{
              pathname: `${TaskBoards}board/${BoardId}`,
              search: ``,
            }}
          >
            {value}
          </Link>
        ) : (
          value
        )}
      </div>
    );
  };
  const RatingViewCell = (props) => {
    const {value, cell} = props;
    const {original} = cell.row;
    const {EnableRatingAndApproval, Rating, DataType} = original;
    const getTextFor = () => {
      if (DataType === "board") {
        if (EnableRatingAndApproval) return "Yes";
        else return "No";
      } else {
        if (!EnableRatingAndApproval) return "NA";
        else if (Rating > 0) return `${Rating}`;
        else return "-";
      }
    };

    return <div className="task-summary-number"> {getTextFor()} </div>;
  };

  let beforecolumns = [
    {
      // Build our expander column
      id: "expander", // Make sure it has an ID
      Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}) => (
        <div {...getToggleAllRowsExpandedProps()}>
          <div className="task-todo-assignments__block-icon">
            {isAllRowsExpanded ? (
              <Icons name="ArrowDown" justSVG />
            ) : (
              <Icons name="ArrowRight" justSVG />
            )}
          </div>
        </div>
      ),
      width: 50,
      Cell: ({row}) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <div
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            <div className="task-todo-assignments__block-icon">
              {row.isExpanded ? (
                <Icons name="ArrowDown" justSVG />
              ) : (
                <Icons name="ArrowRight" justSVG />
              )}
            </div>
          </div>
        ) : null,
    },
    {
      Header: "Board Name",
      accessor: "Name",
      width: 200,
      isSort: true,
      Cell: BoardViewCell,
    },
    {
      Header: "Owner",
      accessor: "Owner",
      width: 140,
      isSort: false,
    },
    {
      Header: "Members",
      accessor: "Members",
      width: 125,
    },
    {
      Header: "Start Date",
      accessor: "StartDate",
      width: 150,
      isSort: false,
    },
    {
      Header: "End Date",
      accessor: "EndDate",
      width: 150,
      isSort: false,
    },
    {
      Header: "Progress",
      accessor: "Progress",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "To Do",
      accessor: "ToDo",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "In Progress",
      accessor: "InProgress",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "Completed",
      accessor: "Completed",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "On Hold",
      accessor: "OnHold",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "Cancelled",
      accessor: "Cancelled",
      width: 140,
      Cell: NumberCell,
    },
    {
      Header: "Last Activity",
      accessor: "LastActivityDate",
      width: 140,
      Cell: LastActivityDate,
      isSort: true,
    },
  ];
  const completeCol = [
    {
      Header: "Completion Date",
      accessor: "CompletedDate",
      width: 170,
    },
    {
      Header: "Efficiency",
      accessor: "Efficiency",
      width: 150,
      Cell: NumberCell,
    },
    {
      Header: "Rating",
      accessor: "Rating",
      width: 140,
      Cell: RatingViewCell,
    },
  ];

  if (isComplete) {
    beforecolumns.splice(4, 0, ...completeCol);
  }

  return (
    <div className="tasksReport-container">
      <div className="tasksReport-table">
        {
          <Table
            isFixedWidth
            columns={beforecolumns} // Columns Settings
            data={tableData || []} // Data list
            enableRemoteSort
            onRemoteSorting={handleSort}
          />
        }
      </div>
      <div className="">
        {tableData && tableData.length > 0 && (
          <Pagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={pageIndex}
            rowsPerPage={pageSize}
            total={tableData[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
}

export default TaskReportTable;
