import React, {useState, useEffect} from "react";
import Modal from "components/modal";
import getFullStringUrl from "helpers/getFullStringUrl";
import {hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";
import {getFullURL} from "helpers/fullURL";
import {useSelector} from "react-redux";

interface Props {
  children: any;
  document: any;
  detail?: any;
}

const imageExtentions = ["png", "jpeg", "jpg", "svg"];
const pdfExtentiosn = ["pdf", "txt", "logs"];
const docsExtentiosn = "docs";
const excelsExtentiosn = "excel";

function CommonDocumentViewer(props: Props) {
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const baseURL = getFullURL(useSelector, "");
  const {children, document, detail} = props;
  const childProps = {
    onClick: () => setOpen(true),
    ...children.props,
  };
  const ext = document.split(".").pop();
  let docs;
  if (imageExtentions.includes(ext)) {
    docs = (
      <div className="documents-image">
        <img src={document} alt="Document Image" />
      </div>
    );
  }
  if (pdfExtentiosn.includes(ext)) {
    let documentUrl;
    if (detail) {
      documentUrl = `${hostedUrl}/v1/common/files/download?serverFileName=${detail.ServerFileName}&userFileName=${detail.UserFileName}fileType=${detail.FileType}`;
    } else {
      documentUrl = {document};
    }
    console.log({documentUrl});
    docs = (
      <div className="documents-pdf">
        <iframe src={documentUrl} frameBorder="0"></iframe>
      </div>
    );
  }
  return (
    <>
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Document Viewer"
        width="700px"
      >
        <div className="documents">
          <div className="documents-file">{docs}</div>
        </div>
      </Modal>
      {React.cloneElement(children, childProps)}
    </>
  );
}

export default CommonDocumentViewer;
