import React from "react";
import {loginUserViaVianet} from "actions/authAction";
import isEmpty from "helpers/isEmpty";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {roles} from "constants/constants";
import {useHistory} from "react-router-dom";
import environment from "environment";
import Loaders from "components/loaders";

const CallbackOauth = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);

  let history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const emp_id = queryParams.get("emp_id");
    const device = queryParams.get("device");
    // if (!code && !emp_id && !device) {
    let searchData = {
      code,
      emp_id,
      device,
    };
    dispatch(loginUserViaVianet(searchData));
    // }
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (!isEmpty(auth.user)) {
        let a = roles();
        if (a.includes(auth.user.RoleId)) {
          if (environment.isProduction) {
            // if (!this.state.loadingState) {
            //   this.setState({loadingState: true});
            // }
            // goToOldAdminDashabord(baseUrl)
            history.push("/");
            return;
          } else {
            history.push("/");
          }
        } else {
          if (environment.isProduction) {
            // if (!this.state.loadingState) {
            //   this.setState({loadingState: true});
            // }
            // goToOldEmployeeDashabord(baseUrl)
            history.push("/");
            return;
          }
          history.push("/");
        }
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      if (typeof errors === "string") history.push("/login");
    }
  }, [errors]);

  if (auth.loginLoading) {
    return <Loaders loading={true} type="rigoLoading" />;
  }

  return null;
};

export default CallbackOauth;
