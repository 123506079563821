import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import {taskUpdateDetails} from "scenes/tasks/helpers/action";
import AvatarGroup from "scenes/tasks/board/taskBody/tableView/table/component/avatarGroup";

const TeamCell = (props) => {
  const {task} = props;
  const ref = useRef(null);
  const [taskOpen, setTaskOpen] = useState(false);
  useEffect(() => {
    if (task && task.TeamMembers) {
      setSelectedMembers(task.TeamMembers);
      canModify();
    }
  }, [task]);
  const canModify = () => {
    const {auth, board} = props;
    const {user} = auth;
    if (board.IsArchived) return false;

    if (user.RoleName === "Administrator") return true;

    if (board.CreatedBy == user.UserID) return true;
    if (task.CreatedBy == user.UserID) return true;
    return false;
  };

  const [selectedMembers, setSelectedMembers] = React.useState([]);
  return (
    <div className="taskBlock-section taskBlock-section-team">
      {/* <AvatarGroup appearance="stack" data={data} maxCount={3} size="small" /> */}
      <AvatarGroup
        maxCount={2}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        {...props}
        hideAddButton={!canModify()}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({auth: state.auth});
const mapDispatchToProps = {
  taskUpdateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCell);
