import React, {Component} from "react";
import {connect} from "react-redux";
import "./style.scss";
import Form from "components/form";
import Button from "components/button";
import {InputValidator} from "helpers/inputValidator";
import isEmpty from "isEmpty";

import {
  saveEmployeeReferenceDetails,
  updateEmployeeReferenceDetails,
  getEmployeeReferenceList,
} from "../action";

class EducationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddSection: false,
      editView: false,
      errors: {},
      Phone: "",
      PersonName: "",
      Organization: "",
      Position: "",
      Email: "",
    };
  }

  componentDidMount() {
    if (this.props.editView) {
      this.setState({
        ...this.props.editViewData,
      });
    }
  }

  handleInput = (name, value) => {
    console.log();
    this.setState({[name]: value}, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  handleSave = async () => {
    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }
    let {PersonName, Organization, Position, Email, Phone} = this.state;

    let data = {
      EmployeeId: parseInt(this.props.employeeId),
      ReferenceId: this.props.editView ? this.props.editViewData.ReferenceId : 0,
      PersonName,
      Email,
      Organization,
      Phone,
      Position,
    };

    if (this.props.editView) await this.props.updateEmployeeReferenceDetails(data);
    else await this.props.saveEmployeeReferenceDetails(data);
    if (this.props.employeeReferenceSaved) {
      this.props.getEmployeeReferenceList(this.props.employeeId);
      this.closeAddSection(false);
    }
  };

  toggleAddSecton = (showAddSection, editView) =>
    this.setState({showAddSection, editView, editViewData: null});

  closeAddSection = () => {
    if (this.props.editView) this.props.toggleEditView();
    else this.props.closeAddSection();
  };

  toggleEditView = (flag, editViewData) =>
    this.setState({showAddSection: false, editView: flag, ...editViewData});

  onDateChange = (name, date) => this.setState({[name]: date});

  render() {
    const {PersonName, Organization, errors, Phone, Email, Position} = this.state;

    const {editView} = this.props;

    return (
      <div className="education__education__body referenceSection">
        <div className="education__education__div">
          <div className="education__education__div__label referenceSection__body">
            <div className="education__education__labels">
              <Form
                name="PersonName"
                value={PersonName}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Name"
                validators={["required"]}
                error={errors && errors.PersonName}
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="Organization"
                value={Organization}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Organization"
                validators={["required"]}
                error={errors && errors.Organization}
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="Position"
                value={Position}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Position"
                validators={["required"]}
                error={errors && errors.Position}
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="Phone"
                value={Phone}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Phone"
                validators={["phone", "required"]}
                error={errors && errors.Phone}
              />
            </div>
            <div className="education__education__labels">
              <Form
                name="Email"
                value={Email}
                onChange={this.handleInput}
                alignment="horizontal"
                label="Email"
                validators={["email", "required"]}
                error={errors && errors.Email}
              />
            </div>
            <div className="button-group button-group__right education__savebutton">
              <Button bg="primary" onClick={this.handleSave}>
                {!editView ? "Add" : "Update"}
              </Button>
              <Button onClick={() => this.closeAddSection(false)} bg="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeReferenceSaved: state.employeeProfile.employeeReferenceSaved,
});

const mapDispatchToProps = {
  saveEmployeeReferenceDetails,
  updateEmployeeReferenceDetails,
  getEmployeeReferenceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationForm);
