import Icons from "components/icons";
import Button from "components/button";
import React from "react";
import {MdDelete, MdEdit} from "react-icons/md";
import {RiBarChartFill} from "react-icons/ri";

const ButtonsFrame = (props) => {
  const {onEditCategory, onDelCategory} = props;

  return (
    <div className="flex">
      <Button
        title="Edit"
        icon={<Icons name="Edit" />}
        leftIcon={<MdEdit />}
        buttonType="icon-button"
        bg="white"
        onClick={(e) => onEditCategory(e, props.row?.original)}
      />
      <Button
        title="Delete"
        icon={<Icons name="Delete" />}
        leftIcon={<MdDelete />}
        buttonType="icon-button"
        bg="white"
        onClick={(e) => onDelCategory(e, props.row?.original)}
      />
    </div>
  );
};

export {ButtonsFrame};
