import React from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Avatar from 'react-avatar'

function GoalModal(props) {
    const {onModalClose,open} = props;
    return (
        <Modal
            open={open}
            onModalClose={onModalClose}
            title="Finnal Score Logic"
            width='500px'
            className='appraisals-modal'
        >
            <FinalScoreForm {...props} />
        </Modal>
    )
}


const FinalScoreForm = (props) => {
    const {onModalClose,isEdit} = props;
    const method = useForm();
    const dispatch = useDispatch()
    const onSubmit = (data) =>{
        console.log({data})
    }
    return(
        <FormGroup method={method} onSubmit={onSubmit} >
            <div className='appraisals-modal__container'>
                <div className="appraisals-modal__body">
                    <FormItem 
                        name='SearchEmployee'
                        label="Employee Name or ID"
                        width='200px'
                    />
                    <div className="appraisals-modal__emp">
                        <div className="appraisals-modal__emp-img">
                            <Avatar 
                                name='Keshari Panta'
                                src=''
                                size='80px'
                                round
                            />
                        </div>
                        <div className="appraisals-modal__emp-details">
                            <div className="appraisals-modal__emp-name">Keshari Panta</div>
                            <div className="appraisals-modal__emp-designation">HR Assistant</div>
                            <div className="appraisals-modal__emp-department">HR Department</div>
                            <div className="appraisals-modal__emp-department">Kathmandu</div>
                        </div>
                    </div>
                    <FormItem 
                        name='Review'
                        label="Review Cycle"
                        options={[]}
                        formName="reactselect"
                        width="250px"
                    />
                    <FormItem 
                        name='Goal'
                        label="Goal"
                    />
                    <FormItem 
                        name='Target'
                        label="Target"
                        width='250px'
                    />
                </div>
                <div className="appraisals-modal__footer appraisals-modal__footer-center">
                    <Button
                        onClick={() => onModalClose()}
                        bg="subtle" title="Cancel" />
                    <Button
                        bg='primary'
                        title={isEdit ? "Update" : "Save"}
                        htmlButtonType="submit"
                    />
                </div>
            </div>
        </FormGroup>
    )
}

export default GoalModal