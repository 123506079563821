import {
  GetLevelsWithGroups,
  DisplayClientSideMessage,
  SetSelectedRecords,
} from "actions/commonAction";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as jobProfileService from "services/jobProfile";
import {GetJobProfile} from "./actions";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table/jobProfileTable";
import moment from "moment";

class jobProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobFamily: {},
      jobRole: {},
      jobCode: "",
      jobTitle: "",
      pageIndex: 0,
      pageSize: 10,
      modalState: {show: false, type: "normal"},
      exportName: "Job Profile",
      exportColumns: [
        {label: "Job Profile Name", value: "ProfileName"},
        {label: "Job Family", value: "LevelName"},
        {label: "Job Role", value: "LevelGroupName"},
        {label: "Last Updated", value: "LastUpdatedDate"},
      ],
      exportData: [],
      isMultiDeleteEnable: false,
    };
  }

  async componentDidMount() {
    await this.props.GetLevelsWithGroups();
    await this.getJobProfileList();
  }

  getJobProfileList = async () => {
    const {jobFamily, jobRole, jobCode, jobTitle, pageIndex, pageSize} = this.state;
    let searchData = {
      levelGroupId: !isEmpty(jobRole) ? jobRole.value : 0,
      levelId: !isEmpty(jobFamily) ? jobFamily.value : 0,
      jobCode: jobCode,
      jobTitle: jobTitle,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    await this.props.GetJobProfile(searchData);
  };
  handleInputChange = (name, value) => {
    //const { name, value } = e.target;
    this.setState({[name]: value, pageIndex: 0}, async () => {
      await this.getJobProfileList();
    });
  };

  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };
  handleModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };
  handleDelRecord = async (args) => {
    let result = await jobProfileService.deleteJobProfile(this.state.selectedId);
    if (result.Status) {
      await this.getJobProfileList();
      this.props.SetSelectedRecords([]);
    }
    this.props.DisplayClientSideMessage(result);
  };

  handleRowChange = (num) => {
    this.setState({pageSize: num}, async () => {
      await this.getJobProfileList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({pageIndex: num}, async () => {
      await this.getJobProfileList();
    });
  };
  handleModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };
  handleExcelExport = async () => {
    const {jobFamily, jobRole} = this.state;
    let searchData = {
      levelGroupId: !isEmpty(jobRole) ? jobRole.value : 0,
      levelId: !isEmpty(jobFamily) ? jobFamily.value : 0,
      jobCode: "",
      jobTitle: "",
      pageIndex: 0,
      pageSize: 99999999,
    };
    let result = await jobProfileService.getJobProfile(searchData);
    if (result.Status) {
      let data = result.Data.map((x) => {
        x.LastUpdatedDate =
          x.LastUpdatedDate &&
          moment(new Date(x.LastUpdatedDate), "YYYYMMDDHHmmss").format("MMMM D, YYYY");
        return x;
      });
      this.setState({exportData: data});
    }
  };
  handleMultiDeleteOption = (enable) => {
    this.setState({isMultiDeleteEnable: enable});
  };
  handleDelMultipleRecord = () => {
    let selectedId = this.props.selectedRecords.join(",");
    this.setState({selectedId}, async () => {
      await this.handleDelRecord();
    });
  };
  render() {
    const {
      jobFamily,
      jobRole,
      jobCode,
      jobTitle,
      pageIndex,
      pageSize,
      exportName,
      exportColumns,
      exportData,
      isMultiDeleteEnable,
    } = this.state;
    const {jobProfileList, history, jobFamilyList, jobRoleList, activeTabId} = this.props;
    const {modalState} = this.state;
    return (
      <div className="job-profile">
        <SearchAndFilter
          handleInputChange={this.handleInputChange}
          jobFamily={jobFamily}
          jobRole={jobRole}
          jobCode={jobCode}
          jobTitle={jobTitle}
          jobFamilyList={jobFamilyList}
          jobRoleList={jobRoleList}
          type="jobProfile"
          exportName={exportName}
          exportColumns={exportColumns}
          exportData={exportData}
          handleExcelExport={this.handleExcelExport}
          isMultiDeleteEnable={isMultiDeleteEnable}
          handleDelMultipleRecord={this.handleDelMultipleRecord}
        />
        <Table
          data={jobProfileList}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleRowChange={this.handleRowChange}
          handleIndexChange={this.handleIndexChange}
          handleEdit={this.handleEdit}
          handleConfirmDelRecord={this.handleConfirmDelRecord}
          history={history}
          handleMultiDeleteOption={this.handleMultiDeleteOption}
          activeTabId={activeTabId}
        />
        {modalState.show && (
          <Modal
            title=""
            open={modalState.show}
            onModalClose={() => this.handleModalShow(false, "normal")}
            type={modalState.type}
            func={() => this.handleDelRecord()}
          ></Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobProfileList: state.jobProfileReducer.jobProfileList,
  jobFamilyList: state.commonReducer.jobFamilyList,
  jobRoleList: state.commonReducer.jobRoleList,
  selectedRecords: state.commonReducer.selectedRecords,
});

const mapDispatchToProps = {
  GetJobProfile,
  GetLevelsWithGroups,
  DisplayClientSideMessage,
  SetSelectedRecords,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(jobProfile));
