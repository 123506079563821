import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routeEnum";
import {post, get, put, deletion} from "networkService";
import {toastMessage} from "actions/validateAction";

export const getCourseEnrollment = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.getCourseEnrollment);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_ENROLLMENT,
    effect: res,
    isSelect: true,
    isValueKey: true,
    isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getShowCoursesTo = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.getShowCoursesTo);
  const actionConfig = {
    dispatch,
    actionType: types.GET_SHOW_COURSE_TO,
    effect: res,
    isSelect: true,
    isValueKey: true,
    isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCourseNavigationPolicies = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.getCourseNavigationPolicies);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_COURSE_NAVIGATION_POLICY,
    effect: res,
    isSelect: true,
    isValueKey: true,
    isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCoursesOptions = (courseId) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.courseOptions + "/?courseId=" + courseId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_OPTIONS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addCoursesOptions = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.courseOptions, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_COURSE_OPTIONS,
    effect: res,
    message: "Course Options added successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const updateCoursesOptions = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.courseOptions, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_COURSE_OPTIONS,
    effect: res,
    message: "Course Options updated successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCourse = (courseId) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.courses + "/" + courseId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const deleteCourse = (courseId) => async (dispatch) => {
  const res = Http.delete(environment.api.v1.lms.courses + "/" + courseId);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  var result = await Action.createThunkEffect(actionConfig);
  return result?.Status;
};
export const getMulitpleCourses = (courseIds) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.multipleCourses + "/" + courseIds);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  const result = await Action.createThunkEffect(actionConfig);
  return result && result.Data;
};

export const resetCourseReducer = () => (dispatch) => {
  dispatch({type: types.GET_COURSE, paylod: {}});
  dispatch({type: types.GET_COURSE_PAGES});
  dispatch({type: types.GET_COURSE_COMPLETED});
};
export const getEmployeeCourse =
  (courseId, assignedId = null) =>
  async (dispatch) => {
    let url = environment.api.v1.lms.courseDetails + `/${courseId}`;
    if (assignedId) url = `${url}?assignedId=${assignedId}`;
    const res = Http.get(url);
    const actionConfig = {
      dispatch,
      actionType: types.GET_COURSE,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
  };
export const getEmployeeCourseByAssignId = (assignId) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.assignedCourseDetail + `/${assignId}`);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};
export const getCoursePages = (courseId) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.coursePages + "?courseId=" + courseId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_PAGES,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCoursePage = (pageId) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.coursePages + "/" + pageId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_PAGE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addCoursePage =
  (data, history = null) =>
  async (dispatch) => {
    const res = Http.post(environment.api.v1.lms.coursePages, data);
    const actionConfig = {
      dispatch,
      actionType: types.ADD_COURSE_PAGE,
      effect: res,
      isLoading: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
      history.push(
        `${RouteEnum.lmsCourseAdd}/${response.Data.CourseId}/page/${response.Data.Id}`
      );
    }
  };

export const updateCoursePage = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.coursePages, data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_COURSE_PAGE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const updateMultipleCoursePage = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.courseMultiplePages, data);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_PAGES,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const deleteCoursePage = (pageId) => async (dispatch) => {
  const res = Http.delete(environment.api.v1.lms.coursePages + "/" + pageId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_COURSE_PAGE,
    effect: res,
    type: "delete",
    id: pageId,
    isLoading: true,
  };
  return await Action.createThunkEffect(actionConfig);
};

export const addCourse =
  (data, history = null) =>
  async (dispatch) => {
    const res = Http.post(environment.api.v1.lms.courses, data);
    const actionConfig = {
      dispatch,
      actionType: types.Add_COURSE,
      message: "Successfully Added Course",
      type: "post",
      effect: res,
      isLoading: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response && response.Status) {
      history.push(RouteEnum.lmsCourseAdd + "/" + response.Data.Id);
    }
  };

export const updateCourse = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.courses, data);
  const actionConfig = {
    dispatch,
    actionType: types.Update_COURSE,
    message: "Successfully Updated Course",
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  if (response && response.Status) {
    // history.push(RouteEnum.Login)
  }
};

export const getCourseList = (param) => async (dispatch) => {
  var res: any = await post(
    environment.api.v1.lms.courseList,
    dispatch,
    param,
    false
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.GET_COURSE_LIST,
      payload: res.Data,
    });
  }
};

export const getFilteredCourseList = (status) => async (dispatch) => {
  dispatch({
    type: types.GET_FILTERED_COURSE_LIST,
    payload: status,
  });
};

export const getSearchedFilteredCourseList = (payload) => async (dispatch) => {
  dispatch({
    type: types.GET_SEARCHED_FILTERED_COURSE_LIST,
    payload,
  });
};

export const getCourseResultsByCourseId = (param) => async (dispatch) => {
  dispatch({
    type: types.GET_COURSE_RESULTS_BY_COURSE_ID,
    payload: null,
    requesting: true,
  });

  var res: any = await post(
    environment.api.v1.lms.courseResultsByCourseId,
    dispatch,
    param,
    false
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.GET_COURSE_RESULTS_BY_COURSE_ID,
      payload: res.Data,
      requesting: false,
    });
  } else {
    dispatch({
      type: types.GET_COURSE_RESULTS_BY_COURSE_ID,
      payload: null,
      requesting: false,
    });
  }
};

export const getCourseResultDetailById = (id) => async (dispatch) => {
  dispatch({
    type: types.GET_COURSE_RESULT_DETAIL_BY_COURSE_ID,
    payload: null,
    requesting: true,
  });

  var res: any = await post(
    `${environment.api.v1.lms.courseResultDetailByCourseId}?id=${id}`,
    dispatch,
    null,
    false
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.GET_COURSE_RESULT_DETAIL_BY_COURSE_ID,
      payload: res.Data,
      requesting: false,
    });
  } else {
    dispatch({
      type: types.GET_COURSE_RESULT_DETAIL_BY_COURSE_ID,
      payload: null,
      requesting: false,
    });
  }
};

export const getCourseByTitle = (courseTitle) => async (dispatch) => {
  const res = Http.get(`${environment.api.v1.lms.courseListTitle}?title=${courseTitle}`);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: false,
    isReturn: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  return response?.Data ?? [];
};

export const getCourseCategories = () => async (dispatch) => {
  var res: any = await get(
    `${environment.api.v1.lms.courseCategories}`,
    dispatch,
    null,
    false
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.GET_COURSE_CATEGORIES,
      payload: res.Data,
    });
  }
};

export const addCourseCategory = (data, setShowCategoryModal) => async (dispatch) => {
  dispatch({
    type: types.ADD_COURSE_CATEGORY,
    payload: null,
    requesting: true,
  });
  var res: any = await post(
    `${environment.api.v1.lms.courseCategories}`,
    dispatch,
    data,
    true
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.ADD_COURSE_CATEGORY,
      requesting: false,
    });
    dispatch(getCourseCategories());
    setShowCategoryModal(false);
  } else {
    dispatch({
      type: types.ADD_COURSE_CATEGORY,
      payload: null,
      requesting: false,
    });
  }
};

export const updateCourseCategory = (data, setShowCategoryModal) => async (dispatch) => {
  dispatch({
    type: types.UPDATE_COURSE_CATEGORY,
    payload: null,
    requesting: true,
  });

  var res: any = await put(
    `${environment.api.v1.lms.courseCategories}`,
    dispatch,
    data,
    true
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.UPDATE_COURSE_CATEGORY,
      payload: res.Data,
      requesting: false,
    });
    setShowCategoryModal(false);
  } else {
    dispatch({
      type: types.UPDATE_COURSE_CATEGORY,
      payload: null,
      requesting: false,
    });
  }
};

export const deleteCourseCategory = (id) => async (dispatch) => {
  dispatch({
    type: types.DELETE_COURSE_CATEGORY,
    payload: true,
  });
  var res: any = await deletion(
    `${environment.api.v1.lms.courseCategories}`,
    dispatch,
    id,
    true
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch(getCourseCategories());
  }

  dispatch({
    type: types.DELETE_COURSE_CATEGORY,
    payload: false,
  });
};

export const getResultSummaryDetail = (courseId, dateType) => async (dispatch) => {
  dispatch({
    type: types.GET_RESULT_SUMMARY_DETAIL,
    payload: null,
    requesting: true,
  });

  var res: any = await get(
    `${environment.api.v1.lms.resultSummaryDetail}?courseId=${courseId}&dateType=${dateType}`,
    dispatch,
    null,
    true
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: types.GET_RESULT_SUMMARY_DETAIL,
      payload: res.Data,
      requesting: false,
    });
  } else {
    dispatch({
      type: types.GET_RESULT_SUMMARY_DETAIL,
      payload: null,
      requesting: false,
    });
  }
};

export const getAssignedCourses = (id) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.assignCourses + "?courseId=" + id);
  const actionConfig = {
    dispatch,
    actionType: types.GET_ASSIGNED_COURSE,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const assignCourse = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.assignCourses, data);
  const actionConfig = {
    dispatch,
    message: "Assigned course to user successfully",
    actionType: types.ASSIGN_COURSES,
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  return response?.Status;
};
export const editAssignCourse = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.assignCourses, data);
  const actionConfig = {
    dispatch,
    message: "Update successfully",
    actionType: types.ASSIGN_COURSES,
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  return response?.Status;
};

export const unAssignCourse = (id) => async (dispatch) => {
  const res = Http.delete(`${environment.api.v1.lms.assignCourses}/${id}`);
  const actionConfig = {
    dispatch,
    message: "Unassigned successfully",
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
  return response?.Status;
};

export const getAssignedEmployeeList = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.assignedEmployeeList, data);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  const result: any = await Action.createThunkEffect(actionConfig);
  return result;
};

export const getCourseContent = (id) => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.courseContent + "?courseOutlineId=" + id);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_CONTENT,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const addCourseContent = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.courseContent, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_COURSE_CONTENT,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};
export const updateContentOrder =
  ({id, newOrder}) =>
  async (dispatch) => {
    const res = Http.post(environment.api.v1.lms.updateContentOrder, {id, newOrder});
    const actionConfig = {
      dispatch,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
  };
//
export const updateCourseStatus = (courseId, status) => async (dispatch) => {
  const res = Http.put(
    environment.api.v1.lms.updateCourseState + `?courseId=${courseId}&status=${status}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.EDIT_COURSE_STATUS,
    effect: res,
    extraPayload: {courseId, status},
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const employeeCourseView = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.courseView, data);
  const actionConfig = {
    dispatch,
    actionType: types.EDIT_COURSE_STATUS,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const updateCourseContent = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.courseContent, data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_COURSE_CONTENT,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const deleteCourseContent = (id) => async (dispatch) => {
  const res = Http.delete(environment.api.v1.lms.courseContent + "?id=" + id);
  const actionConfig = {
    dispatch,
    id,
    type: "delete",
    actionType: types.DELETE_COURSE_CONTENT,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const getAssignedCoursesDashboad = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.assignCoursesDashboard, data);
  const actionConfig = {
    dispatch,
    actionType: types.GET_ASSIGNED_COURSE_DASHBOARD,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCoursesDashboad = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.coursesDashboard, data);

  const actionConfig = {
    dispatch,
    actionType:
      data.pageIndex === 0
        ? types.GET_COURSE_DASHBOARD
        : types.GET_COURSE_DASHBOARD_CONCAT,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};
export const getDashboadCourseCount = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.lms.dashboardCourseCount);
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_DASHBOARD_COUNT,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getQuestionDetails = (coursePageId) => async (dispatch) => {
  const res = Http.get(
    environment.api.v1.lms.getQuestionAnswers + "?coursePageId=" + coursePageId
  );
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_QUESTION_DETAILS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getBranchTable = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.common.getBranchDetails, data);
  const actionConfig = {
    dispatch,
    actionType: types.GET_BRANCHES_DETAILS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getDepartmentTable = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.common.getDepartmentDetails, data);
  const actionConfig = {
    dispatch,
    actionType: types.GET_DEPARTMENT_DETAILS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addCourseQuestion = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.courseQuestions, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_COURSE_QUESTION_DETAILS,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};
export const updateCourseQuestion = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.lms.courseQuestions, data);
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_COURSE_QUESTION_DETAILS,
    effect: res,
    isLoading: true,
  };
  const response = await Action.createThunkEffect(actionConfig);
};

export const deleteCourseQuestion =
  ({coursePageId, id}) =>
  async (dispatch) => {
    const res = Http.delete(environment.api.v1.lms.courseQuestions + "/" + id);
    const actionConfig = {
      dispatch,
      id,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response?.Status) await dispatch(getQuestionDetails(coursePageId));
  };
export const updateQuestionOrder =
  ({coursePageId, questionId, newOrder}) =>
  async (dispatch) => {
    const res = Http.post(environment.api.v1.lms.updateQuestionOrder, {
      questionId,
      newOrder,
    });
    const actionConfig = {
      dispatch,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    const response = await Action.createThunkEffect(actionConfig);
    if (response?.Status) await dispatch(getQuestionDetails(coursePageId));
  };
export const postAnswers = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.addMultipleAnswers, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_ANSWER,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getAnswers = (assignedId, coursePageId) => async (dispatch) => {
  const res = Http.get(
    environment.api.v1.lms.addAnswers + `/${assignedId}?coursePageId=${coursePageId}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.GET_ANSWERS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getCourseCompleted = (empId, courseId) => async (dispatch) => {
  const res = Http.get(
    environment.api.v1.lms.courseCompleted + `?employeeId=${empId}&courseId=${courseId}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.GET_COURSE_COMPLETED,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addCourseCompleted = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.courseCompleted, data);
  const actionConfig = {
    dispatch,
    actionType: types.ADD_COURSE_COMPLETED,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};
export const leaveCourse = (assignedId) => async (dispatch) => {
  const res = Http.delete(`${environment.api.v1.lms.leaveCourse}/${assignedId}`);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  var resOut = await Action.createThunkEffect(actionConfig);
  return resOut?.Status;
};

export const completeCourse = (assignedId, pageId) => async (dispatch) => {
  const res = Http.put(
    `${environment.api.v1.lms.completeCourse}/${assignedId}?pageId=${pageId}`
  );
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
    isReturn: true,
  };
  var resOut = await Action.createThunkEffect(actionConfig);
  return resOut?.Status;
};

export const getUnAssignedEmployeeList = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.lms.assignCoursesNewEmployees, data);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
  };
  var result: any = await Action.createThunkEffect(actionConfig);
  if (result && result.Status) return result.Data || [];
  return [];
};

export const updateCourseAssignStatus =
  ({courseId, assignedId, status}) =>
  async (dispatch) => {
    const res = Http.put(environment.api.v1.lms.assignCoursesStatus, {
      courseId,
      assignedId,
      status,
    });
    const actionConfig = {
      dispatch,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    var result = await Action.createThunkEffect(actionConfig);

    dispatch({type: types.UPDATE_COURSE_ASSIGN_STATUS, payload: result?.Data});
    if (result?.Status) {
      return result?.Data;
    }
    return null;
  };

export const handleChangeFilterValue = (name, value) => (dispatch) => {
  dispatch({type: types.HANDLE_CHANGE_FILTER_VALUE, payload: {name, value}});
};

export const clearState = () => (dispatch) => {
  dispatch({type: types.CLEAR_STATE});
};
