import React from "react";
import Button from "components/button";
import FormGroup from "components/form";
import Modal from "components/modal";
import {GetEmployeeList} from "actions/commonAction";
import {
  UpdateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
  GetEmployeeEmailPreview,
  GetAllMessageTemplate,
} from "../action";
import {connect} from "react-redux";
import {DisplayClientSideMessage} from "actions/commonAction";
import Loaders from "components/loaders";
class WelcomeMessage extends React.Component {
  state = {
    errors: {},
    ManagerId: null,
    messageTemplate: [],
    messageTemplates: [],
    paragraph: "",
    previewMail: false,
    WelcomeMessage: null,
  };
  async componentDidMount() {
    // if (!onboardingEmployeeDetail) {
    await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id);
    //   onboardingEmployeeDetail = this.props.onboardingEmployeeDetail;
    // }
    await this.props.GetAllMessageTemplate();
    let {onboardingEmployeeDetail} = this.props;
    // onboardingEmployeeDetail =  this.props.onboardingEmployeeDetail;
    if (onboardingEmployeeDetail) {
      const {
        EmployeeOnBoardingDto: {ManagerId, WelcomeMessage},
      } = onboardingEmployeeDetail;
      this.setState({
        WelcomeMessage,
        dataToSubmit: onboardingEmployeeDetail.EmployeeOnBoardingDto,
        ManagerId,
      });
    }
    this.props.changeActiveTab("1");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messageTemplateList !== this.props.messageTemplateList) {
      const messageTemplateOptions =
        this.props.messageTemplateList?.length > 0 &&
        this.props.messageTemplateList.map((template) => {
          return {
            label: template?.Name,
            value: template?.Id,
            message: template?.Message,
          };
        });
      this.setState(() => ({
        messageTemplates: messageTemplateOptions,
      }));
    }
  }

  handleFieldChange = (name, value) => {
    if (name === "messageTemplate") {
      this.setState({WelcomeMessage: value?.message});
    }
    this.setState({[name]: value});
  };

  handleFormSubmit = (stage) => {
    const {dataToSubmit, WelcomeMessage, ManagerId} = this.state;
    let data = {
      ...dataToSubmit,
      WelcomeMessage,
    };
    if (ManagerId == 0) {
      this.props.DisplayClientSideMessage("Fill in the details on Date and Place tab");
      return;
    } else this.props.handleSubmit(data, "1", stage);
  };

  onClose = () => {
    this.props.history.push("/admin/employee_onboarding/");
  };

  handlePreviewMail = async () => {
    await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id);
    let {onboardingEmployeeDetail} = this.props;
    if (onboardingEmployeeDetail) {
      let EmployeeOnBoardingDto = {
        ...onboardingEmployeeDetail.EmployeeOnBoardingDto,
        WelcomeMessage: this.state.WelcomeMessage,
      };
      let data = {
        AddressDto: onboardingEmployeeDetail.AddressDto,
        EmployeeDto: onboardingEmployeeDetail.EmployeeDto,
        EmployeeOnBoardingDto,
        EmployeePhotoDto: onboardingEmployeeDetail.EmployeePhotoDto,
      };
      await this.props.GetEmployeeEmailPreview(data);
      this.setState({previewMail: true});
    }
  };
  onModalClose = () => {
    this.setState({previewMail: false});
  };

  render() {
    const {messageTemplate, messageTemplates, errors, WelcomeMessage, previewMail} =
      this.state;
    const {employeePreviewEmail, requesting} = this.props;
    return (
      <div className="date-and-place">
        {employeePreviewEmail && (
          <Modal
            open={previewMail}
            onModalClose={this.onModalClose}
            title="E-mail Previewer"
            width="800px"
            className="previewMail"
          >
            <p
              style={{height: "auto"}}
              dangerouslySetInnerHTML={{__html: employeePreviewEmail}}
            ></p>
          </Modal>
        )}
        <div className="date-and-place__container">
          <div className="date-and-place__body">
            <div className="date-and-place__header">WELCOME MESSAGE</div>
            <div className="date-and-place__header">
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="messageTemplate"
                value={messageTemplate}
                options={messageTemplates}
                loadingType="skeleton"
                placeholder="Message template"
                width="250px"
              />
            </div>
            <div className="date-and-place mb-20">
              <FormGroup
                formName="texteditor"
                value={WelcomeMessage}
                toolbarId={"welcomeMessage"}
                placeholder={"Write a welcome message"}
                name="WelcomeMessage"
                onChange={(name, value) => {
                  this.handleFieldChange(name, value);
                }}
                errors={errors && errors.WelcomeMessage}
                width="60%"
              />
            </div>
            <div className="date-and-place__footer">
              <Button
                bg="primary"
                title="Save and Next"
                onClick={() => this.handleFormSubmit(2)}
                isDisabled={requesting}
              />
              <Button
                bg="primary"
                title="Preview"
                onClick={() => this.handlePreviewMail()}
              />
              <Button bg="subtle" onClick={() => this.onClose()} title="Cancel" />
            </div>
          </div>
        </div>
        {requesting ? <Loaders loading={requesting} /> : null}
      </div>
    );
  }
}

WelcomeMessage = connect(
  (state) => ({
    employeeListSelect: state.commonReducer.employeeListSelect,
    companyInformation: state.commonReducer.companyInformation,
    onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
    employeePreviewEmail: state.employeeOnboarding.employeePreviewEmail,
    messageTemplateList: state.employeeOnboarding.messageTemplateList,
    requesting: state.employeeOnboarding.requesting,
  }),
  {
    GetEmployeeList,
    UpdateOnboardingEmployee,
    GetOnboardingEmployeeDetail,
    GetEmployeeEmailPreview,
    DisplayClientSideMessage,
    GetAllMessageTemplate,
  }
)(WelcomeMessage);

export default WelcomeMessage;
