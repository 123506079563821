import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import Button from "components/button";
import {MdClose} from "react-icons/md";
import ListItem from "./listItem";
import {
  getBoardLabel,
  saveBoardLabel,
  deleteBoardLabel,
} from "../../../../../helpers/action";
import isEmpty from "helpers/isEmpty";
import TextInput from "./textInput";

const AddLabelModal = (props) => {
  const {board, onLabelUpdate, task} = props;
  const [newLabel, setNewLabel] = useState("");
  // useEffect(() => {
  //   if (board && board.BoardId) {
  //     getLabels(board.BoardId);
  //   }
  // }, [board]);

  // const getLabels = async (boardId) => {
  //   props.getBoardLabel(boardId);
  // };

  const handleInputChange = (name, value) => {
    setNewLabel(value);
  };
  const handleSubmit = async (name, value) => {
    let param = {BoardId: board.BoardId, Name: value, Color: ""};
    await props.saveBoardLabel(param);
    setNewLabel("");

    onLabelUpdate && onLabelUpdate(param);
  };
  const onSaveLabel = async (param) => {
    // let param = { BoardId: board.BoardId, Name: value, Color: "" };
    await props.saveBoardLabel(param);
    setNewLabel("");
    onLabelUpdate && onLabelUpdate(param);
  };
  const deleteLabel = async (id) => {
    await props.deleteBoardLabel(id);
  };

  return (
    <div className="board-label">
      <div className="board-label__body">
        <div className="board-label__header">
          <div className="board-label__header-title">Labels</div>
          {/* <div
            className="board-label__header-button"
            onClick={() => props.onModalClose()}
          >
            <MdClose size={25} />
          </div> */}
        </div>
        <ul className="board-label__list">
          {props.boardLabels &&
            props.boardLabels.map((label, index) => (
              <ListItem
                key={index}
                value={label}
                onSaveLabel={onSaveLabel}
                onDelete={deleteLabel}
                //onSelectColor={(color) => setCol1(color)}
              />
            ))}

          <li className="board-label__item item-input">
            <div className="board-label__item-input">
              <TextInput
                value={newLabel}
                placeHolder="Add New label"
                name="label"
                disableSubmitForBlur
                handleChange={handleInputChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="board-label__footer">
        <Button bg="secondary" title="Close" onClick={() => props.onModalClose()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  boardLabels: state.taskReducer.boardLabels,
});
const mapDispatchToProps = {getBoardLabel, saveBoardLabel, deleteBoardLabel};
export default connect(mapStateToProps, mapDispatchToProps)(AddLabelModal);
