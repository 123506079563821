import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {History} from "history";
import {ApplicationState, reducers} from "../reducers";
import {newReducers} from "store/reducers";
import checkTokenExpirationMiddleware from "src/middleware/checkTokenExpirationMiddleware";
import {nodeEnv} from "constants/constants";
import {composeWithDevTools} from "redux-devtools-extension/logOnlyInProduction";
import reduxFreeze from "redux-freeze";
import environment from "environment";
import {createBrowserHistory} from "history";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync";

const initialState = {};

const history = createBrowserHistory<any>({basename: "/"});

const enhancers = [];
const isDev = nodeEnv !== "production";
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
if (
  environment.isDevelopment &&
  windowIfDefined &&
  windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const config = {
  whitelist: ["SET_CURRENT_USER", "SET_CURRENT_TOKEN"],
};

const middleware = [
  environment.isDevelopment ? reduxFreeze : null,
  thunk,
  routerMiddleware(history),
  checkTokenExpirationMiddleware,
  createStateSyncMiddleware(config),
  // errorToastMiddleware()
].filter(Boolean);
const rootReducer = combineReducers({
  ...reducers,
  ...newReducers,
  router: connectRouter(history),
});

const store = createStore(
  withReduxStateSync(rootReducer),
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
// export default initStateWithPrevTab(store);
