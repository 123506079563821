import {GET_EMPLOYEE_LEAVE_LIST2} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";
import {catchError} from "helpers";
import {EmployeeID} from "constants/userDetails";

const urlPrefix = "/v1/leave-time/employees/";

//#region TTClient

export const GetEmployeeLeaveList = () => async (dispatch) => {
  const employeeId = EmployeeID();
  await axios
    .get(`${urlPrefix}leave-list/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        let headings = [];
        let rows = [];
        if (response.Data) {
          const {LeaveType, LeaveTracking} = response.Data;
          headings.push({
            LeaveTypeId: "Tracking",
            Title: "Tracking",
            Visibility: true,
          });
          LeaveType.map((lt) => {
            headings.push({
              LeaveTypeId: lt.LeaveTypeId,
              Title: lt.Title,
              Visibility: false,
            });
          });
          headings.push({
            LeaveTypeId: "Total",
            Title: "Total",
            Visibility: true,
          });

          let lastYearBalanceRow = ["Last Year Balance"];
          let addedThisYearRow = ["Added This Year"];
          let totalRow = ["Total"];
          let leaveUsedRow = ["Leave Used"];
          let leaveUnderReviewRow = ["Leave under review"];
          let remainingLeaveRow = ["Remaining Leaves"];

          LeaveTracking.map((t, i) => {
            //1
            lastYearBalanceRow.push(t.Opening);

            //2
            let addedThisYear = "-";
            if (t.Accural != "-" && t.Adjusted != "-") {
              addedThisYear = parseFloat(t.Accural) + parseFloat(t.Adjusted);
              addedThisYear = addedThisYear.toFixed(2);
            } else if (t.Accural != "-") {
              addedThisYear = t.Accural;
            } else if (t.Adjusted != "-") {
              addedThisYear = t.Adjusted;
            }

            addedThisYearRow.push(addedThisYear);

            //3
            let total = "-";
            if (t.Opening != "-" && addedThisYear != "-") {
              total = parseFloat(t.Opening) + parseFloat(addedThisYear);
              total = total.toFixed(2);
            } else if (t.Opening != "-") {
              total = t.Opening;
            } else if (addedThisYear != "-") {
              total = addedThisYear;
            }
            totalRow.push(total);

            //4
            let leaveUsed = "-";
            if (t.Taken != "-" && t.LapsedEncashed != "-") {
              leaveUsed = parseFloat(t.Taken) + parseFloat(t.LapsedEncashed);
              leaveUsed = leaveUsed.toFixed(2);
            } else if (t.Taken != "-") {
              leaveUsed = t.Taken;
            } else if (t.LapsedEncashed != "-") {
              leaveUsed = t.LapsedEncashed;
            }
            leaveUsedRow.push(leaveUsed);

            //5
            leaveUnderReviewRow.push(t.UnApprovedLeaveRequest);

            //6
            let remainingLeave =
              parseFloat(total != "-" ? total : 0) -
              (parseFloat(leaveUsed != "-" ? leaveUsed : 0) +
                parseFloat(
                  t.UnApprovedLeaveRequest != "-" ? t.UnApprovedLeaveRequest : 0
                ));
            remainingLeaveRow.push(remainingLeave.toFixed(2));
          });

          rows.push(lastYearBalanceRow);
          rows.push(addedThisYearRow);
          rows.push(totalRow);
          rows.push(leaveUsedRow);
          rows.push(leaveUnderReviewRow);
          rows.push(remainingLeaveRow);
        }

        //#region  Check wether all value are null or "-"
        rows.map((row) => {
          row.map((r, i) => {
            if (headings[i].Visibility == false && r != "-" && r != 0) {
              headings[i].Visibility = true;
            }
          });
        });
        //#endregion

        dispatch({
          type: GET_EMPLOYEE_LEAVE_LIST2,
          response: response.Data,
          formatedResponse: headings.length > 0 ? {headings, rows} : null,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};
