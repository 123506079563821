import {
  UPDATE_COMPANY_INFORMATION,
  ADD_LOADING,
  GET_TRAVEL_SETTINGS,
  UPDATE_TRAVEL_SETTINGS,
  SAVE_LOAN_APPROVERS,
  GET_LOAN_APPROVER_LIST_ADMIN,
  DELETE_ADMIN_LOAN_APPROVER,
} from "actions/types";
import { toastMessage } from "actions/validateAction";
import axios from 'helpers/axiosinstance';
import environment from 'environment';

export const updateCompanySettings = (data) => (dispatch) => {
  addLoading(dispatch, true);
  axios
    .put(environment.api.v1.common.company, data)
    .then((res) => {
      addLoading(dispatch, false);
      if (res.data.Status) {
        dispatch({ type: UPDATE_COMPANY_INFORMATION, payload: data });
        toastMessage(dispatch, res);
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update company information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const updateTravelSettings = (data) => (dispatch) => {
  addLoading(dispatch, true);
  axios
    .put(environment.api.v1.travel.settings, data)
    .then((res) => {
      addLoading(dispatch, false);
      if (res.data.Status) {
        dispatch({ type: UPDATE_TRAVEL_SETTINGS, payload: res.data.Data });
        toastMessage(dispatch, res);
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      addLoading(dispatch, false);
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update company information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getTravelSettings = () => async (dispatch) => {
  addLoading(dispatch, true);
  await axios
    .get(environment.api.v1.travel.settings + "?settingId=1")
    .then((res) => {
      addLoading(dispatch, false);
      if (res.data.Status) {
        dispatch({ type: GET_TRAVEL_SETTINGS, payload: res.data.Data });
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      addLoading(dispatch, false);
      dispatch({ type: GET_TRAVEL_SETTINGS, payload: null });
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update company information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

// export const getLoanApprovers = () => async (dispatch) => {
//   addLoading(dispatch, true);
//   await axios
//     .get("/v1/travel/settings?settingId=1")
//     .then((res) => {
//       addLoading(dispatch, false);
//       if (res.data.Status) {
//         dispatch({ type: GET_TRAVEL_SETTINGS, payload: res.data.Data });
//       } else {
//         toastMessage(dispatch, res);
//       }
//     })
//     .catch((err) => {
//       addLoading(dispatch, false);
//       dispatch({ type: GET_TRAVEL_SETTINGS, payload: null });
//       const response = {
//         data: {
//           MessageType: "Danger",
//           Message: "Failed to update company information.",
//         },
//       };
//       toastMessage(dispatch, response);
//     });
// };

export const saveLoanApprovers = (data) => (dispatch) => {
  axios
    .post(environment.api.v1.loan.primeMultipleLoanApprover, data)
    .then((res) => {
      if (res.data.Status) {
        dispatch({ type: SAVE_LOAN_APPROVERS, payload: res.data.Data });
        toastMessage(dispatch, res);
      } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update company information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const getQualifiedApprovers = () => async (dispatch) => {
  await axios
    .get(environment.api.v1.loan.primeLoanApprover)
    .then((res) => {
      if (res.data) {
        let formattedData = [];
        res.data.Data.forEach((emp) => {
          formattedData.push({
            label: emp.EmployeeName,
            value: emp.EmployeeId,
            isHR: emp.IsHR,
            Role: emp.Role,
          });
      })
      dispatch({ type: GET_LOAN_APPROVER_LIST_ADMIN, payload: formattedData });
    } else {
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update company information.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const deleteLoanApprover = ({value, isHR}) => async (dispatch) => {
  // dispatch({ type: DELETE_ADMIN_LOAN_APPROVER, payload: false });
  await axios
    .delete(`${environment.api.v1.loan.primeLoanApprover}/${value}?employeeRole=${isHR}`)
    .then(async (res) => {
      if (res.data) {
        await dispatch({type: DELETE_ADMIN_LOAN_APPROVER, payload: res.data.Status});
        return res.data.Status;
        // toastMessage(dispatch, res);
      } else {
        dispatch({type: DELETE_ADMIN_LOAN_APPROVER, payload: res.data.Status});
        return false;
        // toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to Delete loan Approver",
        },
      };
      toastMessage(dispatch, response);
    });
};

const addLoading = (dispatch, loading) => {
  dispatch({
    type: ADD_LOADING,
    payload: loading,
  });
};
