import React, {useState} from "react";
import Modal from "components/modal";
import AssignJobModal from "./assignJobModal";
import {connect} from "react-redux";
import {
  assignJobDescription,
  changeAssignedJobDescription,
  getJDViewDetails,
  getJobDescriptionByLevel,
} from "../../action";
import JobDescription from "./jobDescription";

function Current(props) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(false);
  const [openDesc, setOpenDesc] = useState(false);
  const onOpen = async (mode) => {
    let searchData = {
      EmployeeId: props.employeeId,
      JobTitle: "",
      PageIndex: 0,
      PageSize: 10,
    };
    await props.getJobDescriptionByLevel(searchData);
    setMode(mode);
    setOpen(true);
  };
  let {JobTitle, JobCode, JobDescriptionId} = props.employeeAssignedJobDescription || {
    JobCode: null,
    JobTitle: null,
    JobDescriptionId: null,
  };
  const onClose = () => setOpen(false);
  const onDescOpen = async () => {
    await props.getJDViewDetails(JobDescriptionId);
    setOpenDesc(true);
  };
  const onDescClose = () => setOpenDesc(false);

  return (
    <div className="current">
      <div className="current-row">
        <div className="current-label">
          <span>Job Description</span>
        </div>
        {JobTitle && (
          <>
            <div className="current-link">
              <a className="current-link__anchor" onClick={() => onDescOpen()}>
                <span>
                  {JobTitle} [{JobCode}]{" "}
                </span>
              </a>
            </div>
          </>
        )}
        <Modal
          onModalClose={onClose}
          title={"Assign Job Description"}
          className="current-modal"
          open={open}
        >
          <AssignJobModal onClose={onClose} {...props} mode={mode} />
        </Modal>
        <Modal
          onModalClose={onDescClose}
          title={"Job Description"}
          className="job-description-modal"
          open={openDesc}
          width="80%"
        >
          <JobDescription
            jobDesc={props.employeeAssignedJobDescription}
            closeModal={onDescClose}
            {...props}
          />
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  assignJobDescription:
    state.employeeProfile.assignJobDescription &&
    state.employeeProfile.assignJobDescription,
  employeeAssignedJobDescription: state.employeeProfile.employeeAssignedJobDescription,
  employeeProfile: state.employeeProfile.employeeProfile,
});

const mapDispatchToProps = {
  assignJobDescription,
  changeAssignedJobDescription,
  getJDViewDetails,
  getJobDescriptionByLevel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Current);
