import React, {useEffect, useState} from "react";
import Form from "components/form";
import isEmpty from "helpers/isEmpty";

const PageTitleInput = (props) => {
  const {name, value, onChange, error, handleSubmit} = props;
  const placeHolder = isEmpty(value) ? "Click here to add page title" : "";
  const [titleValue, setTitleValue] = useState("");
  useEffect(() => {
    setTitleValue(value);
  }, [value]);

  const onKeyUp = (e) => {
    if (e.key === "Enter" && value !== titleValue) {
      handleSubmit && handleSubmit(titleValue);
    }
  };
  const handleBlur = (e) => {
    if (value !== titleValue) {
      handleSubmit && handleSubmit(titleValue);
    }
  };
  const handleChange = (name, val) => {
    setTitleValue(val);
  };
  return (
    <Form
      name={name}
      label="Page title"
      placeholder={placeHolder}
      onChange={handleChange}
      value={titleValue}
      error={error}
      onKeyUp={onKeyUp}
      onBlur={handleBlur}
    />
  );
};

export default PageTitleInput;
