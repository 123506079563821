import React, { useState, useRef, useEffect } from "react";
import man from "assets/images/man.png";
// import {getBaseAxios} from 'helpers/Constants';
import Avatar from "react-avatar";
import RequestAndAssign from "scenes/common/requestAndAssign";
import {getUploadPath} from 'constants/userDetails'

function HeaderEmployee(props) {
  const {user, baseUrl, isRecommenderOrApprover, showUserRequest} = props;
  return (
    <>
      <div className="header-employee">
        <div className="header-employee__container employee">
          <div className="employee-details">
            <div className="employee-details__image">
              {user.UserImage ? (
                <Avatar
                  className="company-logo"
                  name={user.UserName}
                  size={70}
                  round={true}
                  src={getUploadPath(user.UserImage)}
                />
              ) : (
                <img src={man} alt="Employee Avatar" />
              )}
            </div>
            <div className="employee-details__content">
              <h2>{user.EmployeeName}</h2>
              <p className="position">
                <span>{user.Designation}</span>
                <span>{user.Department}</span>
              </p>
              <p className="location">
              {user.Location ? (
                   <span>{user.Branch},</span>
                ) : (
                  <span>{user.Branch}</span>
               )}
                <span>{user.Location}</span>
                {/* <span onClick={() => props.history.push("/employeeonboard")}>
                  Kathmandu
                </span> */}
              </p>
            </div>
          </div>

          <div className="request-container">
            <RequestAndAssign showUserRequest={showUserRequest} />
            {/* <ul className="request-list">
              {isRecommenderOrApprover &&
                (isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover ||
                  isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover) && (
                  <li className="request-list__item">
                    <RequestButton
                      buttonTitle="Assign Request"
                      isAssign={true}
                      isLeaveTimeReviewerOrApprover={
                        isRecommenderOrApprover.IsLeaveTimeReviewerOrApprover
                      }
                      isAllowanceOvertimeReviewerOrApprover={
                        isRecommenderOrApprover.IsAllowanceOvertimeReviewerOrApprover
                      }
                    />
                  </li>
                )}
              <li className="request-list__item">
                <RequestButton buttonTitle="New Request" isAssign={false} />
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderEmployee;
