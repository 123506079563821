import React, { useEffect } from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem'
import { useForm } from 'react-hook-form';
import { AprAssessmentOptionType } from '../helpers/contants'
import { useDispatch } from 'react-redux';
import { addAppraisalReviewCycle, getAppraisalReviewCycle, updateAppraisalReviewCycle } from '../helpers/action';
import dayjs from 'dayjs';

function ReviewCycleModal(props) {
    const {
        onModalClose,
        open,
        isEdit = false,
    } = props;
    return (
        <Modal
            open={open}
            onModalClose={onModalClose}
            title={`${isEdit ? "Edit" : "Add"} Review Cycle`}
            width='500px'
            className='appraisals-modal'
        >
            <RecycleForm {...props} />
        </Modal>
    )
}

const RecycleForm = (props) => {
    const {
        onModalClose,
        open,
        isEdit = false,
        editData,
        getReviewCycle
    } = props;
    const method = useForm();
    const dispatch = useDispatch()
    const { handleSubmit, setValue,watch } = method;
    const archiveValue = watch("IsArchived")
    useEffect(() => {
        if (isEdit && open) {
            setValue("StartDate", dayjs(editData?.StartDate).format("YYYY-MM-DD"))
            setValue("EndDate", dayjs(editData?.EndDate).format("YYYY-MM-DD"))
            setValue("CycleName", editData?.CycleName)
            if(editData?.AssessmentOptionIds){
                let ids = editData?.AssessmentOptionIds.split(",");
                let isId = ids.length > 0;
                setValue("AssessmentOptions",isId ? ids.map(item=>parseInt(item)):[])
            }
            setValue("IsArchived", editData?.IsArchived === true ? true : false)
        }
    }, [isEdit, open])
    const onSubmit = async (data) => {
        const finalData = {
            "AprReviewCycle": {
                ...data,
                Id: editData?.Id ?? 0
            },
            "ReviewCycleAssessmentOption": data.AssessmentOptions && data.AssessmentOptions.map(item => ({
                "id": 0,
                "reviewCycleRefId": editData?.Id ?? 0,
                "AssessmentOption": item
            }))
        }
        let res;
        if (isEdit) {
            res = await dispatch(updateAppraisalReviewCycle({ ...finalData, }))
        } else {
            res = await dispatch(addAppraisalReviewCycle(finalData))
        }
        if (res === true) {
            onModalClose();
            getReviewCycle && getReviewCycle();
        }
    }
    return (

        <FormGroup method={method} onSubmit={onSubmit} >
            <div className='appraisals-modal__container'>
                <div className="appraisals-modal__body">

                    <FormItem
                        name='CycleName'
                        label='Review Cycle Name'
                        width="200px"
                        rules={{ required: "Cycle Name is required!" }}
                    />
                    <div className="appraisals-modal__row">
                        <FormItem
                            name='StartDate'
                            formName='dateinput'
                            label='Cycle Start Date'
                            width="200px"
                            rules={{ required: "Cycle Start Date is required!" }}
                        />
                        <FormItem
                            name='EndDate'
                            formName='dateinput'
                            label='Cycle End Date'
                            width="200px"
                            rules={{ required: "Cycle End Date is required!" }}
                        />
                    </div>
                    <FormItem
                        name='AssessmentOptions'
                        formName="checkBoxList"
                        label={<div className='appraise-check-label '>Assessment Options <span>Types of assessments allowed in this cycle</span></div>}
                        rules={{ required: "Cycle Name is required!" }}
                        items={[
                            { label: "Performance Appraisals", value: AprAssessmentOptionType.PerformanceAppraisals },
                            { label: "360 Degree Feedback", value: AprAssessmentOptionType.DegreeFeedback },
                            { label: "Check-ins", value: AprAssessmentOptionType.CheckIns },
                        ]
                        }
                    />
                    <div className="appraisal-margin-check-top">
                        <FormItem
                            name='IsArchived'
                            formName="checkgroup"
                            checked={archiveValue}
                            label='Mark as Archived'
                            labelPosition="right"
                        // width="200px"

                        />
                    </div>

                </div>
                <div className="appraisals-modal__footer appraisals-modal__footer-center">
                    <Button
                        onClick={() => onModalClose()}
                        bg="subtle" title="Cancel" />
                    <Button
                        bg='primary'
                        title={isEdit ? "Update" : "Save"}
                        htmlButtonType="submit"
                    />
                </div>
            </div>
        </FormGroup>
    )
}

export default ReviewCycleModal