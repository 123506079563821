import React from "react";
import {FaRegEdit} from "react-icons/fa";
import styled from "styled-components";

const TData = styled.td`
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(195, 195, 195);
  color: rgb(81, 81, 81);
`;

const ReadOnlyRow = ({levelData, handleEditClick}) => {
  return (
    <tr>
      <TData style={{width: "250px"}}>{levelData.Name}</TData>
      <TData style={{width: "60px"}}>{levelData.Amount}</TData>
      <TData>
        <FaRegEdit
          className="cursor-pointer"
          onClick={(event) => handleEditClick(event, levelData)}
        />
      </TData>
    </tr>
  );
};

export default ReadOnlyRow;
