import React, { useState, useEffect } from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
// import ReviewCycleModal from './reviewCycleModal';
import { useDispatch, useSelector } from "react-redux";
import { getWorkFlowAppraisalSteps, getWorkFlowAppraisal,getThreeSixtyWorkFlowAppraisal } from '../helpers/action';
import WorkflowTable from './workflowTable'
import ThreeWorkflowTable from './360workFlow'
import AddWorkflow from './addWorkflow';


function WorkFlow() {
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getWorkFlowAppraisal())
    dispatch(getThreeSixtyWorkFlowAppraisal())
  },[])
  return (
    <div className='appraisals-container'>
    <div className="appraisals-title">
      <span>Workflows</span>
    </div>
    <div className="appraisals-title-secondary">
      <span>Appraisal Workflows</span>
      <Button
        onClick={() => setOpen(true)}
        title='Add' bg="primary" size="small" />
    </div>
    <AddWorkflow 
      open={open}
      onModalClose={onModalClose}
    />
    <WorkflowTable />
    <div className="appraisals-title-secondary">
      <span>360 Appraisal Workflows</span>
      <Button
        onClick={() => setOpen(true)}
        title='Add' bg="primary" size="small" />
    </div>
    <ThreeWorkflowTable />
  </div>
  )
}

export default WorkFlow