import RouteEnum from "constants/routeEnum";
import React from "react";
import {useHistory} from "react-router";
import Button from "components/button";
import {LMSCourseShowCourseTo} from "scenes/lms/helpers/enums";
import isEmpty from "helpers/isEmpty";

const AssignedButton = (props) => {
  const {onAssignClick} = props;
  const history = useHistory();
  const {Id, Title, Status, ShowCourseTo, CourseOptionId} = props.row?.original;
  const hanldeClick = () => {
    let courses = [];
    courses.push({Id, Title});
    onAssignClick && onAssignClick(courses);
  };
  if (isEmpty(CourseOptionId) || CourseOptionId === 0) {
    return (
      <Button
        bg="light"
        onClick={() =>
          history.push(RouteEnum.lmsCourseSettings + "/" + props.row?.original?.Id)
        }
        title="Course Option"
      />
    );
  }
  return (
    <div className="">
      {Status === 1 && LMSCourseShowCourseTo.AssignedUser == ShowCourseTo && (
        <Button bg="light" onClick={hanldeClick} title="Assign Course" />
      )}
    </div>
  );
};

export default AssignedButton;
