import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import RowItem from "./tableRow";
import RowFooter from "./tableRow/tableRowFooter";
import {TMStage} from "../../../../../../helpers/enum";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {useDispatch} from "react-redux";
import {orderTask} from "scenes/tasks/helpers/action";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RowBody = (props) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.tasks) {
      setData(props.tasks);
    }
  }, [props.tasks]);
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const {destination, draggableId} = result;
    const {index, droppableId} = destination;
    if (draggableId) {
      const taskId = draggableId.split("-")[1];
      const datas = {
        taskId: parseInt(taskId),
        boardRefId: props?.boardId,
        stageId: props?.stage,
        order: index,
      };
      
      var res = await dispatch(orderTask(datas));
      if (!res) return;

      const items = reorder(data, result.source.index, result.destination.index);

      const newItems = items.map((item: any, index) => ({...item, PageOrder: index}));

      setData(newItems);
    }
  };
  return (
    <CSSTransition unmountOnExit in={props.expand} timeout={200} classNames="taskpop">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tasks-list" type="collection">
          {(provided, snapshot) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef} nf={provided}>
              {data &&
                data.map((task, index) => (
                  <Draggable
                    key={"Task-" + task.TaskId}
                    draggableId={"Task-" + task.TaskId}
                    index={index}
                    disableInteractiveElementBlocking="true"
                  >
                    {(provided, snapshot) => (
                      <RowItem
                        {...props}
                        provided={provided}
                        snapshot={snapshot}
                        key={index}
                        task={task}
                        rowIndex={index}
                      />
                    )}
                  </Draggable>
                ))}

              {props.enableAddTask ? (
                <RowFooter {...props} columns={props.columns} />
              ) : null}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </CSSTransition>
  );
};

export default RowBody;
