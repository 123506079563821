import Button from "components/button";
import Modal from "components/modal";
import React, {useEffect, useState} from "react";
import Table from "components/customTable";
import TeamForm from "./teamForm";
import {useDispatch, useSelector} from "react-redux";
import Confirm from "components/common/commonConfimation";
import {
  archiveRosterTeam,
  deleteRosterTeam,
  getRosterTeamListBy,
} from "scenes/roster/helpers/action";

import {Tabs, Tab} from "components/tabs/index.d";
import data from "scenes/employee/employeeProfile/placement/reportedBy/data";

const ButtonCell = (props) => {
  const [open, setOpen] = useState(false);
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();

  const onDelete = async () => {
    var res: any = await dispatch(deleteRosterTeam(props.value));
    res && res.Status && props.onSuccessDeleting(props.value);
  };

  const onArchived = async () => {
    var res: any = await dispatch(archiveRosterTeam(props.value));
    res && res.Status && props.onSuccessDeleting(props.value);
  };

  return (
    <div className="shifts-table__col flex">
      <div className="shifts-table__links">
        <span onClick={() => onModalOpen()}>Edit</span>
      </div>
      <div className="shifts-table__links">
        <Confirm action={onDelete}>
          <span>Delete</span>
        </Confirm>
      </div>
      <div className="shifts-table__links">
        <Confirm action={onArchived} title="Archive">
          <span>Archive</span>
        </Confirm>
      </div>
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Edit Team"
        width="450px"
        className="hide-modal-overflow"
      >
        <TeamForm
          editData={props?.row?.original}
          isEdit={true}
          onModalClose={onModalClose}
          onSuccess={props.onSuccessSavingTeam}
        />
      </Modal>
    </div>
  );
};

function Teams() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("ActiveTeam");
  const [dataList, setDataList] = useState([]);
  const onModalClose = () => setOpen(false);
  useEffect(() => {
    loadTeam(activeTab === "ArchivedTeam");
  }, [activeTab]);

  const cols = [
    {
      Header: "Team Name",
      accessor: "Name",
      width: 160,
    },
    {
      Header: "Description",
      accessor: "Description",
      width: 220,
    },
    // {
    //   Header: "Shift Pattern",
    //   accessor: "ShiftPattern",
    //   width: 160,
    // },
  ];
  const colWithAction = [
    ...cols,
    {
      Header: "Actions",
      accessor: "Id",
      Cell: (props) => (
        <ButtonCell
          {...props}
          onSuccessDeleting={handleSuccessDeleting}
          onSuccessSavingTeam={handleOnSuccessSavingTeam}
        />
      ),
      width: 200,
    },
  ];
  const handleSuccessDeleting = (id) =>
    setDataList(dataList.filter((item) => item.Id !== id));

  const handleOnSuccessSavingTeam = (obj) => {
    var nextDataList = [...dataList];
    const index = dataList.findIndex((x) => x.Id == obj.Id);
    if (index == -1) nextDataList.push(obj);
    else nextDataList[index] = obj;
    setDataList(nextDataList);
  };

  const loadTeam = async (isArchived) => {
    var res: any = await dispatch(getRosterTeamListBy(isArchived));
    setDataList(res);
  };
  return (
    <div className="teams">
      <div className="flex mb-sm mt-sm">
        <Button title="Add Team" bg="primary" onClick={() => setOpen(true)} />
        <Modal
          title="Add Team"
          onModalClose={onModalClose}
          open={open}
          width="400px"
          className="hide-modal-overflow"
        >
          <TeamForm onModalClose={onModalClose} onSuccess={handleOnSuccessSavingTeam} />
        </Modal>
      </div>
      <div className="teams-table">
        <Tabs noBackground activeTab={activeTab}>
          <Tab
            name="ActiveTeam"
            id="1"
            label="Active"
            onClick={() => setActiveTab("ActiveTeam")}
          >
            <Table isFixedWidth data={dataList} columns={colWithAction} />
          </Tab>
          <Tab
            name="ArchivedTeam"
            id="2"
            label="Archived"
            onClick={() => setActiveTab("ArchivedTeam")}
          >
            <Table isFixedWidth data={dataList} columns={cols} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Teams;
