import {v1} from "constants/urls";

export default {
  getCourseEnrollment: `${v1}/lms/course-enrollments`,
  getCourseNavigationPolicies: `${v1}/lms/course-navigation-policies`,
  getShowCoursesTo: `${v1}/lms/course-show-courses-to`,
  courses: `${v1}/lms/courses`,
  multipleCourses: `${v1}/lms/multiple-courses`,
  courseDetails: `${v1}/lms/course-detail`,
  assignedCourseDetail: `${v1}/lms/assigned-course-detail`,
  coursePages: `${v1}/lms/course-pages`,
  courseMultiplePages: `${v1}/lms/multiple-course-pages`,
  courseList: `${v1}/lms/course-list`,
  courseListTitle: `${v1}/lms/course-list-title`,
  courseResultsByCourseId: `${v1}/lms/courses/result`,
  courseResultDetailByCourseId: `${v1}/lms/courses/result-detail`,
  resultExport: `${v1}/lms/courses/result-export`,

  updateCourseState: `${v1}/lms/courses/change-status`,
  courseView: `${v1}/lms/course-views`,
  courseCategories: `${v1}/lms/course-categories`,
  courseOptions: `${v1}/lms/course-options`,
  courseOutlines: `${v1}/lms/course-outlines`,
  courseContent: `${v1}/lms/course-contents`,
  updateContentOrder: `${v1}/lms/update-content-order`,
  courseQuestions: `${v1}/lms/course-questions`,
  resultSummaryDetail: `${v1}/lms/courses/result-summary-detail`,

  updateQuestionOrder: `${v1}/lms/update-question-order`,
  getQuestionDetails: `${v1}/lms/course-questions/details`,
  getQuestionAnswers: `${v1}/lms/course-questions-answers`,
  addAnswers: `${v1}/lms/employee-answers`,
  addMultipleAnswers: `${v1}/lms/employee-multiple-answers`,
  courseCompleted: `${v1}/lms/employee-completed-page`,
  leaveCourse: `${v1}/lms/leave-courses`,
  completeCourse: `${v1}/lms/complete-courses`,
  assignCourses: `${v1}/lms/assign-courses`,
  assignCoursesNewEmployees: `${v1}/lms/assign-courses/employees-list`,
  assignCoursesDashboard: `${v1}/lms/assign-courses/dashboard`,
  coursesDashboard: `${v1}/lms/courses/dashboard`,
  dashboardCourseCount: `${v1}/lms/dashboard-course-count`,
  assignedEmployeeList: `${v1}/lms/assign-courses/assigned-employees-list`,
  assignCoursesStatus: `${v1}/lms/assigned-courses/status`,
};
