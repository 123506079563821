import classNames from "classnames";
import React, {Children} from "react";
import "./style.scss";

const LoaderContainer = ({isLoading, children}) => {
  return (
    <div className={classNames({"loader__div-section": isLoading})}>
      {isLoading && (
        <div className="loader__div-loading">
          <div className="loader__div-loading__symbol">
            <div className="loader__div-spinner"></div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default LoaderContainer;
