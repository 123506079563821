import React, {useState, useEffect, useRef} from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import {FaRegCalendarAlt, FaTimes} from "react-icons/fa";

// import { MdClose } from "react-icons/md";
import {onDateBlur, onDate} from "helpers/dateInputHelpers";
import DatePicker from "components/datePicker";
import useEventListener from "hooks/useEventListener";
import {convertNumToNepali, getNepDateArray} from "components/datePicker/helpers";
import CustomPopup from "components/common/customPopup";
import {IoMdClose} from "react-icons/io";

interface Props {
  name: string;
  placeholder: string;
  size: string;
  value: any;
  minDate: any;
  maxDate: any;
  error: any;
  width: string;
  label: string;
  handleBlur: Function;
  onChange: Function;
  onKeyUp?: Function;
  disabled: boolean;
  isFullDetails?: boolean;
  isNepali?: boolean;
  isDateRange?: boolean;
  isTextInNepali?: boolean;
  enableOkButton?: boolean;
  closeOnClick?: boolean;
  hideAction?: boolean;
}

const convertDateMMDDYY = (date, isDateRange) => {
  if (date) {
    if (isDateRange) {
      return date;
    } else {
      // return moment(date).format("YYYY/MM/DD")
      if (typeof date === "object") {
        return "";
      }
      if (/\D\/$/.test(date)) date = date.substr(0, date.length - 3);
      var values = date.split("/").map(function (v) {
        return v.replace(/\D/g, "");
      });
      if (values.length == 3) {
        return `${values[1]}/${values[2]}/${values[0]}`;
      }
    }
  }
  return "";
};

export const convertDateYYMMDD = (date, isDateRange) => {
  if (date) {
    if (isDateRange) {
      return date;
    } else {
      if (/\D\/$/.test(date)) date = date.substr(0, date.length - 3);
      var values = date.split("/").map(function (v) {
        return v.replace(/\D/g, "");
      });
      if (values.length == 3) {
        return `${values[0]}/${values[1]}/${values[2]}`;
      }
    }
  }
  return "";
};
const getDateJoinCharacter = (date) => {
  return date.includes("/") ? "/" : date.includes("-") ? "-" : null;
};
const splitAndJoin = (date, isTextInNepali) => {
  let char = date && getDateJoinCharacter(date);
  if (!isEmpty(char)) {
    return date
      ?.split(char)
      ?.map(function (v) {
        v = v.toString();
        let d = v.length == 1 ? "0" + v : v;
        return isTextInNepali ? convertNumToNepali(d) : d;
      })
      .join("-");
  }
};
const splitAndJoin2 = (date, isTextInNepali) => {
  let char = date && getDateJoinCharacter(date);
  if (!isEmpty(char)) {
    return date
      ?.split(char)
      ?.map(function (v) {
        v = v.toString();
        if (/\s/.test(v)) {
          v.replace(/\s/g, "");
        }
        // return v;
        return isTextInNepali ? convertNumToNepali(v) : v;
      })
      .join("-");
  }
};

const valueWithDateRange = (value, isDateRange = false, isTextInNepali = false) => {
  if (!isDateRange) {
    return splitAndJoin2(value, isTextInNepali);
  }
  if (value && value.startDate && value.endDate) {
    return (
      splitAndJoin(value.startDate, isTextInNepali) +
      " - " +
      splitAndJoin(value.endDate, isTextInNepali)
    );
  }
  if (value && value.startDate && !value.endDate) {
    return splitAndJoin(value.startDate, isTextInNepali) + " - No end date";
  }
  if (value && !value.startDate && value.endDate) {
    return "No start date - " + splitAndJoin(value.endDate, isTextInNepali);
  }
  return "";
};

const DateInput: React.SFC<Props> = (props) => {
  const {
    name,
    placeholder,
    value,
    disabled,
    isNepali,
    closeOnClick = false,
    minDate,
    isDateRange,
    maxDate,
    isTextInNepali,
    enableOkButton,
    hideAction,
  } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleChange = (name, value) => {
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const {name, value} = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };
  const handleSetDate = (date: any, fullDetails: any = "") => {
    props.onChange(name, convertDateYYMMDD(date, isDateRange), fullDetails);
  };
  const dateClasses = classnames("genericForm-group__date custom-date", {
    "custom-date__active": open,
  });
  const calRef: any = useRef(null);
  useEffect(() => {
    props.value && setDate(value);
  }, [props.value]);
  const onInputChange = (e) => {
    const {name, value} = e.target;
    setDate(value);
  };
  useEffect(() => {
    if (date && !isDateRange && typeof date === "string") {
      if (date?.includes("/")) {
        let fin = date?.split("/");
        if (fin.length === 3) {
          if (fin[2].length == 4) {
            handleChange(name, convertDateYYMMDD(date, props.isDateRange));
          }
        }
      }
    }
  }, [date]);
  useEffect(() => {
    if (isDateRange) {
      if (!open) {
        if (value && value.startDate && !value.endDate) {
          const newDate = {
            startDate: value.startDate,
            endDate: value.startDate,
          };
          handleChange(name, newDate);
        }
      }
    }
  }, [open]);
  const newValue = valueWithDateRange(value, isDateRange, isTextInNepali);
  const handleIconClick = () => {
    if (!disabled) {
      if (isEmpty(value)) {
        setOpen(!open);
      } else {
        handleChange(name, "");
      }
    }
  };

  return (
    <>
      <div ref={calRef} className={dateClasses}>
        <input
          className="genericForm-group__date-input"
          type="text"
          id={name}
          name={name}
          onInput={(e) => onDate(e, onInputChange, isNepali, isDateRange)}
          // onBlur={(e) => onDateBlur(e, onInputChange, isNepali, isDateRange)}
          disabled={disabled || open}
          placeholder={placeholder}
          defaultValue={value ? newValue : ""}
          value={value ? newValue : ""}
          onKeyUp={handleKeyUp}
          onClick={() => setOpen(!open)}
          // onClick={()=> isDateRange ?setOpen(!open):null}
        />
        <span className="custom-date__overlay" onClick={(e) => setOpen(!open)}></span>
        {isEmpty(newValue) ? null : (
          <span
            className="genericForm-group__date-icon"
            style={{right: 25, top: 6}}
            onClick={() =>
              handleSetDate(isDateRange ? {startDate: "", endDate: ""} : "", "")
            }
          >
            {/* <MdClose /> */}
          </span>
        )}

        <span className="genericForm-group__date-icon" onClick={handleIconClick}>
          {!isEmpty(value) ? (
            <IoMdClose className="p" />
          ) : (
            <FaRegCalendarAlt className="p" />
          )}
        </span>
        {open ? (
          // <div className="genericForm-group__datepicker">
          <CustomPopup
            parentRef={calRef}
            open={open}
            // handleOutsideClick={() => onClose()}
          >
            <DatePicker
              // date={convertDateYYMMDD(value,isDateRange)}
              date={value ? (typeof value === "object" ? {...value} : value) : ""}
              // date={value}
              isTextInNepali
              onDateChange={(date, fullDetails) => {
                console.clear();
                !enableOkButton && handleSetDate(date, fullDetails);
                closeOnClick && setOpen(false);
              }}
              minDate={minDate}
              maxDate={maxDate}
              isNepali={isNepali ? true : false}
              onClose={onClose}
              isDateRange={isDateRange ? true : false}
              isFullDetails={props.isFullDetails ? true : false}
              enableOkButton={enableOkButton}
              hideAction={hideAction}
              onOkButtonClicked={(date, fullDetails) => {
                handleSetDate(date, fullDetails);
                setOpen(false);
              }}
            />
          </CustomPopup>
        ) : null}
      </div>
    </>
  );
};

DateInput.defaultProps = {
  name: "",
  isNepali: false,
  placeholder: "YYYY/MM/DD",
  minDate: null,
  maxDate: null,
  isDateRange: false,
  isTextInNepali: false,
};

export default DateInput;
