import React from "react";
import {Card} from "components/card";
import {Text} from "components/text";
import {COMPLETED, COURSE_BACKGROUND, IN_PROGRESS} from "constants/colors";

import CourseTitle from "../courseTitle";

const CourseResultSummary = ({summary}) => {
  return (
    <Card
      background={COURSE_BACKGROUND}
      gap="1rem"
      padding="1.5rem"
      flexDirection="column"
      width={"100%"}
    >
      <CourseTitle title={summary.CourseTitle} />
      <Card background={"none"} gap="8px">
        <Card
          alignItems={"center"}
          borderRadius="3px"
          flexDirection="column"
          padding="0.3rem 0.6rem"
          justifyContent={"center"}
        >
          <Text color={COMPLETED} title={summary.Completed} />
          <Text title="Completed" />
        </Card>
        <Card
          alignItems={"center"}
          borderRadius="3px"
          flexDirection="column"
          padding="0.3rem 0.6rem"
          justifyContent={"center"}
        >
          <Text color={IN_PROGRESS} title={summary.InProgress} />
          <Text title="In Progress" />
        </Card>
        <Card
          alignItems={"center"}
          borderRadius="3px"
          flexDirection="column"
          padding="0.3rem 0.6rem"
          justifyContent={"center"}
        >
          <Text title={summary.NotStarted} />
          <Text title="Not Started" />
        </Card>
      </Card>
    </Card>
  );
};

export default CourseResultSummary;
