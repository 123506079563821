import React, { useEffect, useState } from "react";
import isEmpty from "isEmpty";
import { useSelector, useDispatch } from "react-redux";
import Board from "./board";
import { TMStage } from "scenes/tasks/helpers/enum";
import { DragDropContext } from 'react-beautiful-dnd';
import { moveTask } from "../../../helpers/action";

function BoardView(props) {
  const { board, onTaskClicked } = props;
  const { tableView } = useSelector((state: any) => state.taskReducer);
  const dispatch = useDispatch();
  const onBeforeDragStart = tmp => {
    if (document.activeElement.tagName.toUpperCase() === "TEXTAREA") {
    
      // document.activeElement.blur();
      // document.activeElement.blur();
    }
  };
  const onDragStart = tmp => {};

  const onDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;
    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === "COLUMN") {
      // reorderListPosition(source.index, destination.index);
      return;
    }
    const destStageId  = destination?.droppableId.split("-")[1]
    const sourceStageId  = source?.droppableId.split("-")[1];
    if(destStageId !== sourceStageId){
      dispatch(moveTask([result?.draggableId], destStageId, board?.BoardId))
    }
    
    // reorderCardPosition(source, destination, result.draggableId);
  };
  if (!isEmpty(tableView)) {
    return (
      <div className="task-board">
        <div className="task-board__container">
          <DragDropContext
           onBeforeDragStart={onBeforeDragStart}
           onDragStart={onDragStart}
           onDragEnd={onDragEnd}
          >
            <Board
              board={board}
              onTaskClicked={onTaskClicked}
              stage={TMStage.ThingsToDo}
              tasks={tableView.ThingsToDo}
              header="Things to do"
              theme="things-to-do"
              isCollapsed={false}
              enableAddTask={true}
            />

            <Board
              board={board}
              onTaskClicked={onTaskClicked}
              stage={TMStage.InProgress}
              tasks={tableView.InProgress}
              header="In Progress"
              theme="in-progress"
              enableAddTask={true}
            />
            <Board
              board={board}
              onTaskClicked={onTaskClicked}
              stage={TMStage.Completed}
              tasks={tableView.Completed}
              header="Completed"
              theme="completed"
            />
            <Board
              board={board}
              onTaskClicked={onTaskClicked}
              stage={TMStage.OnHold}
              tasks={tableView.OnHold}
              header="On Hold"
              theme="on-hold"
            />
            <Board
              board={board}
              onTaskClicked={onTaskClicked}
              stage={TMStage.Cancelled}
              tasks={tableView.Cancelled}
              header="Cancelled"
              theme="cancelled"
            />
          </DragDropContext>

        </div>
      </div>
    );
  }
  return null;
}

export default BoardView;