import React, {useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

interface radioGroupTypes {
  name: string;
  key?: any;
  value: any;
  error?: string;
  labelPosition?: string;
  id: any;
  disabled?: boolean;
  className?: string;
  checked?: boolean;
  onChange: Function;
  radioLabel: string;
  register?: any;
  required?: any;
  label?: any;
}

function RadioGroup(props: radioGroupTypes) {
  const {
    key,
    name,
    value,
    error,
    radioLabel,
    id,
    checked,
    className,
    labelPosition,
    disabled,
    onChange,
    register,
    required,
  } = props;

  const handleChange = (e) => {
    const {name, value} = e.target;
    onChange && onChange(name, value);
  };
  return (
    <div className="form__radio-group">
      <input
        key={key}
        value={value}
        disabled={disabled}
        checked={checked}
        // defaultChecked={value === radioLabel}
        type="radio"
        className="form__radio-input"
        id={id ? id : radioLabel}
        onChange={handleChange}
        name={name}
      />
      <label htmlFor={id ? id : radioLabel} className="form__radio-label">
        <span className="form__radio-button"></span>
        {radioLabel}
      </label>
    </div>
  );
}

RadioGroup.defaultProps = {
  labelPosition: "left",
  disabled: false,
};

export default RadioGroup;
