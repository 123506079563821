import React, { Component } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Toolbar,
  Page,
  Inject,
} from "@syncfusion/ej2-react-grids";
import Button from "components/button";
import { MdEdit, MdDelete, MdAdd } from "react-icons/md";
import Icons from "components/icons";
import Form from "components/form";
import Modal from "components/modal";
import NewLoanRecord from "./newLoanRecord";
import { connect } from "react-redux";
import { InputValidator } from "helpers/inputValidator";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import isEmpty from "isEmpty";
import { DisplayClientSideMessage } from "actions/commonAction";
import Confirm from "components/common/commonConfimation";
import {
  pullEmployeeOutstandingLoan,
  getEmployeeOutstandingLoan,
  getPayrollLoanHeadings,
  saveOutstandingLoans,
  updateOutstandingLoans,
  changeRecommendation,
  deletePrimeOutstandingLoan,
  changeEmployeeOutstandingLoanManually,
} from "../../../helpers/action";
import { currencyCommaSeperator } from "../../../helpers/currencyFormatter";

const formatNumber = (params) => {
  return (
    Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
  );
};

const formatDate = (params) => {
  if (params.value == "") {
    return "";
  } else return moment(params.value).format("YYYY/MM/DD");
};

const ButtonGroup = (props) => {
  if (props.data.LoanName == "Total") return null;
  return (
    <div className="homeloan-outstandingloan-actionbutton">
      <div className="mr-sm">
        <Button
          bg="subtle"
          icon={<MdEdit />}
          buttonType="icon-button"
          title="Edit"
          isDisabled={
            props.data.CreatedBy != props.api.user?.UserID ||
            props.data.pulled ||
            props.api.loanDetails?.Status != 2
          }
          onClick={() => props.api.editOutstandingLoan(props.data, true)}
        />
      </div>
      <div>
        <Button
          bg="subtle"
          icon={<MdDelete />}
          buttonType="icon-button"
          title="Delete"
          isDisabled={
            props.data.CreatedBy != props.api.user?.UserID ||
            props.api.loanDetails?.Status != 2 ||
            props.data.pulled
          }
          onClick={() => props.api.deleteEmployeeItem(true, props.data)}
        />
      </div>
    </div>
  );
};

export class OutstandingLoanTable extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ShowNewRecordForm: false,
      LoanName: "",
      Outstanding: "",
      AsOnDate: "",
      MonthlyDeduction: "",
      CompletesOn: "",
      employeeOutstandingLoan: [],
      EditView: false,
      errors: {},
      isLoading: true,
      noOutstandingLoans: false,
      columnDefs: [
        {
          field: "LoanName",
          headerName: "Loan",
          width: 200,
        },
        {
          field: "Outstanding",
          headerName: "Outstanding Loan(NPR)",
          type: "rightAligned",
          width: 165,
          valueFormatter: formatNumber,
          // cellStyle: {"display": "table"}
        },
        {
          field: "AsOnDate",
          width: 120,
          headerName: "As on",
          valueFormatter: formatDate,
        },
        {
          field: "MonthlyDeduction",
          headerName: "Monthly Deduction(NPR)",
          width: 170,
          type: "rightAligned",
          valueFormatter: formatNumber,
          // cellStyle: {"display": "table"}
        },
        {
          field: "CompletesOn",
          headerName: "Completes on",
          width: 130,
          valueFormatter: formatDate,
        },
        { field: "", cellRenderer: "ButtonGroup", headerName: "", width: 231 },
      ],
      domLayout: "autoHeight",
      defaultColDef: {
        // width: 145,
        // resizable: true,
      },
      // loadingCellRenderer: 'customLoadingCellRenderer',
      // loadingCellRendererParams: { loadingMessage: 'One moment please...' },
      frameworkComponents: {
        ButtonGroup,
      },

      getRowHeight: function (params) {
        return 44;
      },
    };
    this.template = this.gridTemplate;
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    // const updateData = (data) => {
    //   this.setState({ rowData: data });
    // };

    // if (this.props.data?.Data) {
    //   updateData(this.props.data.Data);
    // }
    const { loanDetails, user } = this.props;
    this.gridApi.editOutstandingLoan = this.editOutstandingLoan;
    this.gridApi.deleteEmployeeItem = this.deleteEmployeeItem;
    this.gridApi.loanDetails = loanDetails;
    this.gridApi.user = user;
    // this.gridApi.submitLoanApproved = this.submitLoanApproved;
    // this.gridApi.setOpen = this.setOpen;
  };

  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };

  async componentDidMount() {
    const { employeeLoanPulledStatus, loanDetails } = this.props;
    // let {
    //   employeeLoanPulledStatus,
    //   loanDetails: { EmployeeId, Status, data, LoanId, NoOfLoanTaken, DisbursementDate },
    //   // employeePrimeLoanSalary
    // } = this.props;
    if (loanDetails) {
      await this.props.getPayrollLoanHeadings();
      await this.props.getEmployeeOutstandingLoan({
        employeeId: loanDetails.EmployeeId,
        loanType: loanDetails.data,
        loanId: loanDetails.LoanId,
      });
      const { employeeOutstandingLoan } = this.props;
      // if (isEmpty(employeeOutstandingLoan)) {

      this.props.onChecknoOutstandingLoans(
        !isEmpty(loanDetails.AssignedBy)
          ? isEmpty(employeeOutstandingLoan)
          : !isEmpty(loanDetails.AssignedBy),
        employeeOutstandingLoan
      );
      // }
      this.setState(
        {
          employeeOutstandingLoan: employeeOutstandingLoan,
          EmployeeId: loanDetails?.EmployeeId,
          LoanType: loanDetails?.data,
          NoOfLoanTaken: loanDetails?.NoOfLoanTaken,
          DisbursementDate: loanDetails?.DisbursementDate,
          DisbursementEmployeeId: loanDetails?.DisbursementDate,
          FourWheelerNoOfLoanTaken: loanDetails?.FourWheelerNoOfLoanTaken,
          FourWheelerDisbursementDate: loanDetails?.FourWheelerDisbursementDate,
          Status: loanDetails?.Status,
          data: loanDetails?.data,
          // noOutstandingLoans: isEmpty(loanDetails.AssignedBy) ? isEmpty(employeeOutstandingLoan) && isEmpty(loanDetails.AssignedBy) : isEmpty(employeeOutstandingLoan),
          noOutstandingLoans: !isEmpty(loanDetails.AssignedBy)
            ? isEmpty(employeeOutstandingLoan)
            : !isEmpty(loanDetails.AssignedBy),
        },
        () => {
          if (employeeLoanPulledStatus) {
            this.pullEmployeeOutstandingLoan();
          } else this.calculateTotalRow();
        }
      );
    }
  }

  // getCheckedStatus = (employeeOutstandingLoan, AssignedBy) => {
  //   if (isEmpty(AssignedBy)) {
  //     return false;
  //   } else true;
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.employeeOutstandingLoan !== prevProps.employeeOutstandingLoan) {
  //     this.calculateTotalRow()
  //   }
  // }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  editOutstandingLoan = (data, EditView) => {
    if (!data)
      data = {
        LoanName: "",
        Outstanding: "",
        AsOnDate: null,
        MonthlyDeduction: "",
        CompletesOn: null,
      };
    else {
      data = {
        ...data,
        LoanName: {
          label: data.LoanName,
          value: data.LoanListId,
        },
      };
    }
    this.setState({ ...data, EditView, ShowNewRecordForm: true });
  };

  deleteOutstandingLoan = async () => {
    const { data } = this.state;
    let employeeOutstandingLoan = [...this.state.employeeOutstandingLoan];
    employeeOutstandingLoan = await employeeOutstandingLoan.filter(
      (loan) => loan.LoanId != data.LoanId
    );
    if (data.pulled) {
      let newPayrollLoan = [];
      employeeOutstandingLoan.forEach((loan) => {
        if (loan.pulled) newPayrollLoan.push(loan);
      });
      this.setState({ employeeOutstandingLoan }, async () => {
        await this.props.changeEmployeeOutstandingLoanManually(newPayrollLoan);
        this.calculateTotalRow();
      });
    } else {
      await this.props.deletePrimeOutstandingLoan(data.Id);
      const { primeOutstandingLoanDeleted, onChecknoOutstandingLoans } =
        this.props;
      if (primeOutstandingLoanDeleted) {
        let isLoanEmpty =
          employeeOutstandingLoan &&
          employeeOutstandingLoan.filter((loan) => loan.LoanName != "Total");
        onChecknoOutstandingLoans(
          isEmpty(isLoanEmpty),
          employeeOutstandingLoan
        );

        this.setState(
          {
            employeeOutstandingLoan,
            isLoading: true,
            noOutstandingLoans: isEmpty(isLoanEmpty),
          },
          () => {
            this.calculateTotalRow();
          }
        );
      } else this.calculateTotalRow();
    }
  };

  pullEmployeeOutstandingLoan = async () => {
    const { EmployeeId } = this.state;
    if (EmployeeId) {
      let data = {
        EmployeeId: EmployeeId,
        // LoanType: "PrimeHomeLoan",
      };
      await this.props.pullEmployeeOutstandingLoan(data);
      const { employeePayrollOutstandingLoan } = this.props;
      let employeeOutstandingLoan = [...this.state.employeeOutstandingLoan];
      employeePayrollOutstandingLoan &&
        employeePayrollOutstandingLoan.forEach((loan) => {
          let data = employeeOutstandingLoan.filter((i) => i.Id == loan.Id);
          if (isEmpty(data))
            employeeOutstandingLoan.push({ pulled: true, ...loan });
          employeeOutstandingLoan = employeeOutstandingLoan.filter(
            (loan) => loan.actionButtons != true
          );
        });
      this.setState({ employeeOutstandingLoan, isLoading: true }, () => {
        this.calculateTotalRow();
      });
    }
  };

  handleSubmit = async () => {
    const {
      EditView,
      LoanName,
      Outstanding,
      AsOnDate,
      LoanId,
      MonthlyDeduction,
      CompletesOn,
      Id,
      EmployeeId,
      LoanType,
    } = this.state;
    const {
      user: { UserID },
      onChecknoOutstandingLoans,
    } = this.props;
    let errors = await InputValidator(document, this.state);

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return;
    }
    // const { homeLoanDetail } = this.props;
    let data = {
      Id: EditView ? Id : 0,
      EmployeeId: EmployeeId,
      LoanId: EditView ? LoanId : this.props.match.params.id,
      LoanType,
      LoanName: !isEmpty(LoanName) ? LoanName.label : "",
      LoanListId: !isEmpty(LoanName) ? LoanName.value : "",
      Outstanding,
      AsOnDate,
      MonthlyDeduction,
      CompletesOn,
      CreatedBy: UserID,
    };
    if (EditView) await this.props.updateOutstandingLoans(data);
    else await this.props.saveOutstandingLoans(data);
    const { employeeOutstandingLoanSaved } = this.props;
    if (employeeOutstandingLoanSaved) {
      let employeeOutstandingLoan = [...this.state.employeeOutstandingLoan];
      if (EditView) {
        employeeOutstandingLoan = await employeeOutstandingLoan.filter(
          (loan) => loan.Id != employeeOutstandingLoanSaved.Id
        );
      }
      data.Id = employeeOutstandingLoanSaved.Id;
      employeeOutstandingLoan.push(data);
      this.setState(
        {
          employeeOutstandingLoan,
          ShowNewRecordForm: false,
          errors: {},
          noOutstandingLoans: false,
        },
        () => {
          onChecknoOutstandingLoans(false, employeeOutstandingLoan);
          this.calculateTotalRow();
        }
      );
    }
  };

  gridTemplate(props) {
    return (
      <tr className="leave-row bordered">
        <td className="">
          <span>{props["LoanName"]}</span>
        </td>
        <td
          className={
            props.actionButtons ? "text-bold align-right" : "align-right"
          }
        >
          <span className="">
            {currencyCommaSeperator(props["Outstanding"])}
          </span>
        </td>
        <td className="align-left">
          <span className="">
            {props["AsOnDate"] != ""
              ? moment(props["AsOnDate"]).format("YYYY.MM.DD")
              : ""}
          </span>
        </td>
        <td
          className={
            props.actionButtons ? "text-bold align-right" : "align-right"
          }
        >
          <span className="">
            {currencyCommaSeperator(props["MonthlyDeduction"])}
          </span>
        </td>
        <td className="align-left">
          <span className="">
            {props["CompletesOn"] != ""
              ? moment(props["CompletesOn"]).format("YYYY.MM.DD")
              : ""}
          </span>
        </td>
        {props.actionButtons ? (
          <td></td>
        ) : (
          <td className="homeloan-outstandingloan-actionbutton">
            <>
              <div className="mr-sm">
                <Button
                  bg="subtle"
                  icon={<MdEdit />}
                  buttonType="icon-button"
                  title="Edit"
                  isDisabled={
                    props.pulled || this.props.loanDetails?.Status != 2
                  }
                  onClick={() => this.editOutstandingLoan(props, true)}
                />
              </div>
              <div>
                <Button
                  bg="subtle"
                  icon={<MdDelete />}
                  buttonType="icon-button"
                  title="Delete"
                  isDisabled={
                    this.props.loanDetails?.Status != 2 || props.pulled
                  }
                  onClick={() => this.deleteEmployeeItem(true, props)}
                />
              </div>
            </>
          </td>
        )}
      </tr>
    );
  }

  calculateTotalRow = () => {
    let { employeeOutstandingLoan } = this.state;
    if (!isEmpty(employeeOutstandingLoan)) {
      let newLoanData = [...employeeOutstandingLoan];
      let totalRow = {
        LoanName: "Total",
        Outstanding: 0,
        MonthlyDeduction: 0,
        AsOnDate: "",
        CompletesOn: "",
        actionButtons: true,
      };
      let Outstanding = 0,
        MonthlyDeduction = 0;
      newLoanData = newLoanData.filter((loan) => loan.LoanName != "Total");
      newLoanData.forEach((loan) => {
        Outstanding += parseInt(loan.Outstanding);
        MonthlyDeduction += parseInt(loan.MonthlyDeduction);
      });
      totalRow.Outstanding = Outstanding;
      totalRow.MonthlyDeduction = MonthlyDeduction;

      newLoanData.push(totalRow);
      this.setState({ employeeOutstandingLoan: newLoanData, isLoading: false });
    } else {
      this.setState({ isLoading: false });
      return;
    }
  };

  deleteEmployeeItem = (flag, data) => {
    this.setState({ modalOpen: flag, data });
  };

  getWidthOfHeader = (headerName) => {
    let mq = window.matchMedia("(min-width: 1800px)");

    if (headerName == "Amount") {
      if (mq.matches) return "58";
      else return "80";
    } else if (headerName == "Date") {
      if (mq.matches) return "54";
      else return "70";
    } else if (headerName == "Action") {
      if (mq.matches) return "60";
      else return "100";
    } else if (headerName == "outstanding") {
      if (mq.matches) return "60";
      else return "70";
    } else if (headerName == "asDate") {
      if (mq.matches) return "40";
      else return "50";
    } else if (headerName == "name") {
      if (mq.matches) return "80";
      else return "100";
    }
  };

  renderRecordButton = () => {
    const { loanDetails, employeeLoanPulledStatus, isHR } = this.props;
    if (loanDetails.Status == 2 && !isHR) {
      return (
        <div className="header_buttons flex mb-sm">
          <Button
            bg="primary"
            icon={<MdAdd />}
            buttonType="icon-button"
            title="Add New Record"
            isDisabled={loanDetails?.Status != 2}
            onClick={() => this.editOutstandingLoan(null, false)}
          />
          {/* <Button
            bg="primary"
            title="Pull From Payroll"
            isDisabled={loanDetails?.Status != 2 || employeeLoanPulledStatus}
            onClick={() => this.pullEmployeeOutstandingLoan()}
          /> */}
        </div>
      );
    } else return null;
  };

  onCheckChange = (e) => {
    const { employeeOutstandingLoan } = this.state;
    let isLoanEmpty =
      employeeOutstandingLoan &&
      employeeOutstandingLoan.filter((loan) => loan.LoanName != "Total");
    if (isEmpty(isLoanEmpty)) {
      this.setState({ noOutstandingLoans: e.target.checked });
      this.props.onChecknoOutstandingLoans(
        e.target.checked,
        this.state.employeeOutstandingLoan
      );
    } else {
      this.props.DisplayClientSideMessage(
        "Outstanding Loan is present for this loan"
      );
    }
  };

  render() {
    const {
      ShowNewRecordForm,
      employeeOutstandingLoan,
      editData,
      modalOpen,
      isLoading,
      Status,
      NoOfLoanTaken,
      DisbursementDate,
      FourWheelerNoOfLoanTaken,
      FourWheelerDisbursementDate,
      data,
      noOutstandingLoans,
      DisbursementEmployeeId,
    } = this.state;
    let distribShown =
      data == "PrimeStaffPersonalLoan" || data == "PrimeVehicleLoan";
    // const { loanDetails, employeeLoanPulledStatus, isHR } = this.props;
    const {
      payrollLoanHeadings,
      loanDetails,
      isHR,
      user: { EmployeeId },
    } = this.props;

    return (
      <div className="homeloan-outstandingloan homeloan-outstandingloan__table">
        <div className="healthCard-heading homeloan-outstandingloan__header">
          <h4>Outstanding Loans</h4>
          {/* {loanDetails.Status == 2 && !isHR && ( */}
          <Form
            formName="checkgroup"
            name="noOutstandingLoans"
            checked={noOutstandingLoans || (isEmpty(employeeOutstandingLoan) && (loanDetails.Status == 4 || loanDetails.Status == 5))}
            value={noOutstandingLoans}
            label="No Outstanding Loans"
            labelPosition="right"
            alignment="horizontal"
            onChange={this.onCheckChange}
            // disabled={isHR || !isEmpty(loanDetails?.AssignedBy)}
            disabled={isHR || loanDetails.Status == 4 || loanDetails.Status == 5}
          />
          {/* )} */}
        </div>
        <Modal
          type="confirm"
          open={modalOpen}
          onModalClose={() => this.deleteEmployeeItem(false, null)}
          func={() => this.deleteOutstandingLoan()}
        />
        {this.renderRecordButton()}
        {/* {loanDetails?.Status == 2 && !isHR ? (
          <div className="header_buttons flex mb-sm">
            <Button
              bg="primary"
              icon={<MdAdd />}
              buttonType="icon-button"
              title="Add New Record"
              isDisabled={loanDetails?.Status != 2}
              onClick={() => this.editOutstandingLoan(null, false)}
            />
            <Button
              bg="primary"
              title="Pull From Payroll"
              isDisabled={loanDetails?.Status != 2 || employeeLoanPulledStatus}
              onClick={() => this.pullEmployeeOutstandingLoan()}
            />
          </div>
        ) : null} */}
        <Modal
          open={ShowNewRecordForm}
          onModalClose={() => this.setState({errors: {}, ShowNewRecordForm: false})}
          title="Add New Record"
          className="homeloan-outstandingloan-addnewrecord"
        >
          <NewLoanRecord
            editData={editData}
            data={this.state}
            onChange={this.handleInputChange}
            handleSubmit={this.handleSubmit}
            title="Add New Record"
            payrollLoanHeadings={payrollLoanHeadings}
            onModalClose={() => this.setState({errors: {}, ShowNewRecordForm: false})}
          />
        </Modal>
        {!isLoading && !isEmpty(employeeOutstandingLoan) && (
          <div
            id="myGrid"
            style={{
              height: "100%",
              // width: "67%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              onGridReady={this.onGridReady}
              getRowHeight={this.state.getRowHeight}
              // loadingCellRenderer={this.state.loadingCellRenderer}
              // loadingCellRendererParams={this.state.loadingCellRendererParams}
              // rowData={data.Data}
              rowData={employeeOutstandingLoan}
              alwaysShowHorizontalScroll={true}
            />
          </div>
        )}
        {Status > 2 && distribShown && (
          <div className="flex mt-md">
            <div>
              {data == "PrimeVehicleLoan" && (
                <div className="vehicle_loantaken_header">Two wheeler</div>
              )}
              <div className="homeloan-view-salaryDetails__block">
                <div className="homeloan-view-salaryDetails__table salary_total outstanding__body">
                  <div className="homeloan-view-salaryDetails__row total-row outstanding__totalheader">
                    <div className="placement-details__col total-row-takehome bold-500 outstanding__totalvalue">
                      Number of time loan Taken:
                    </div>
                    <div className="placement-details__col placement-val salary_total_value outstanding__totalvalue">
                      {NoOfLoanTaken || "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="homeloan-view-salaryDetails__block">
                <div className="homeloan-view-salaryDetails__table salary_total outstanding__body">
                  <div className="homeloan-view-salaryDetails__row total-row outstanding__totalheader">
                    <div className="placement-details__col total-row-takehome bold-500 outstanding__totalvalue">
                      Latest Disbursement Date
                    </div>
                    <div className="placement-details__col placement-val salary_total_value outstanding__totalvalue">
                      {!isEmpty(DisbursementDate)
                        ? moment(DisbursementDate).format("YYYY/MM/DD")
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data == "PrimeVehicleLoan" && (
              <div>
                <div className="vehicle_loantaken_header">Four wheeler</div>
                <div className="homeloan-view-salaryDetails__block">
                  <div className="homeloan-view-salaryDetails__table salary_total outstanding__body">
                    <div className="homeloan-view-salaryDetails__row total-row outstanding__totalheader">
                      <div className="placement-details__col total-row-takehome bold-500 outstanding__totalvalue">
                        Number of time loan Taken:
                      </div>
                      <div className="placement-details__col placement-val salary_total_value outstanding__totalvalue">
                        {FourWheelerNoOfLoanTaken || "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="homeloan-view-salaryDetails__block">
                  <div className="homeloan-view-salaryDetails__table salary_total outstanding__body">
                    <div className="homeloan-view-salaryDetails__row total-row outstanding__totalheader">
                      <div className="placement-details__col total-row-takehome bold-500 outstanding__totalvalue">
                        Latest Disbursement Date
                      </div>
                      <div className="placement-details__col placement-val salary_total_value outstanding__totalvalue">
                        {!isEmpty(FourWheelerDisbursementDate)
                          ? moment(FourWheelerDisbursementDate).format("YYYY/MM/DD")
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/* {!isLoading && !isEmpty(employeeOutstandingLoan) && (
          <div className="arrear-table">
            <GridComponent
              dataSource={employeeOutstandingLoan}
              rowTemplate={this.template.bind(this)}
              editSettings={this.editSettings}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="Loan"
                  width={this.getWidthOfHeader("name")}
                />
                <ColumnDirective
                  field="Outstanding"
                  className="align-right"
                  textAlign="Right"
                  // editType="numericedit"
                  width={this.getWidthOfHeader("outstanding")}
                />
                <ColumnDirective
                  field="As on"
                  textAlign="Left"
                  width={this.getWidthOfHeader("asDate")}
                />
                <ColumnDirective
                  field="Monthly Deduction"
                  textAlign="Right"
                  width={this.getWidthOfHeader("Amount")}
                />
                <ColumnDirective
                  field="Completes on"
                  textAlign="Left"
                  width={this.getWidthOfHeader("Date")}
                />
                <ColumnDirective
                  field=""
                  width={this.getWidthOfHeader("Action")}
                />
              </ColumnsDirective>
            </GridComponent>
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeOutstandingLoan: state.loanReducer.employeeOutstandingLoan, // initial fetch
  employeePayrollOutstandingLoan:
    state.loanReducer.employeePayrollOutstandingLoan, // from payroll
  user: state.auth.user,
  employeeOutstandingLoanSaved: state.loanReducer.employeeOutstandingLoanSaved,
  payrollLoanHeadings: state.loanReducer.payrollLoanHeadings,
  primeOutstandingLoanDeleted: state.loanReducer.primeOutstandingLoanDeleted,
  employeeLoanPulledStatus: state.loanReducer.employeeLoanPulledStatus,
  isHR: state.loanReducer.isHR,
});

const mapDispatchToProps = {
  pullEmployeeOutstandingLoan,
  getEmployeeOutstandingLoan,
  getPayrollLoanHeadings,
  saveOutstandingLoans,
  updateOutstandingLoans,
  changeRecommendation,
  deletePrimeOutstandingLoan,
  changeEmployeeOutstandingLoanManually,
  DisplayClientSideMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutstandingLoanTable);
