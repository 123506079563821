import React, { useRef, useState, useEffect } from "react";
import FormGroup from "components/form";
import { FaCheckCircle, FaPencilAlt, FaPlus } from "react-icons/fa";
import { TMStage, TmViewType } from "scenes/tasks/helpers/enum";
import { MdClose } from "react-icons/md";
import isEmpty from "helpers/isEmpty";
import classNames from "classnames";
import Stages from "./stages";

const LabelPopup = (props) => {
  const { setStatusOpen, setOpenLabelModel, task, board } = props;
  const [searchLabel, setSearchLabel] = useState("");
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    if (props.boardLabels) setLabels(props.boardLabels);
  }, [props.boardLabels]);

  const renderListItem = (item, index) => {
    const isSelected = task.TaskBoardLabels &&
      task.TaskBoardLabels.find((x) => x.BoardLabelId === item.Id);

    let isDisabled = false
    if (task.TaskBoardLabels)
      isDisabled = isSelected ? false : task.TaskBoardLabels.length >= 3;

    return (
      <li className="li-label" key={index}>
        <div
          className={classNames("label-popup__section-item", {
            "label-popup__section-item-disabled": isDisabled,
           // "label-popup__section-item-active": isSelected,
          })}
          style={item.Color ? { backgroundColor: item.Color } : {}}
          onClick={(e) => {
            isSelected ? onDeleteLabel(item) : !isDisabled && onAddLabel(item);
          }}
        >
          {isSelected && <FaCheckCircle />} {item.Name}
        </div>
      </li>
    );
  };

  const renderLabels = () => {
    let list = [];
    if (props.boardLabels && props.boardLabels.length > 0)
      list =
        labels.length > 0
          ? labels.map((item, index) => renderListItem(item, index))
          : [
            <li key={0}>
              <div className="label-popup__section-no-labels">No labels</div>
            </li>,
          ];
    else
      list.push(
        <li>
          <div className="label-popup__section-no-labels">
            No labels defined
          </div>

          <div
            className="label-popup__section-add-labels"
            onClick={() => {
              setStatusOpen(false);
              setOpenLabelModel(true);
            }}
          >
            <FaPlus size="10" /> add labels
          </div>
        </li>
      );
    return list;
  };

  const handleChangeSearchLabel = (name, value) => {
    setSearchLabel(value);
    let list = props.boardLabels || [];
    if (isEmpty(value)) setLabels(list);
    else {
      list = list.filter((x) =>
        x.Name.toLowerCase().trim().includes(value.toLowerCase().trim())
      );
    }
    setLabels(list);
  };

  const onAddLabel = async (item) => {
    let TaskBoardLabels = task.TaskBoardLabels && [...task.TaskBoardLabels] || []
    

    if (isEmpty(TaskBoardLabels.find((x) => x.BoardLabelId === item.Id))) {
      TaskBoardLabels.push({
        BoardLabelId: item.Id,
        LabelName: item.Name,
        Color: item.Color,
      });
    }
    const updateTask = {
      ...task,
      TaskBoardLabels,
    };
    props.onUpdateTask(updateTask);
  };

  const onClickStage = async (stageId) => {
    props.onMoveTask([task.TaskId], stageId, board.BoardId);
  };
  const onDeleteLabel = async (item) => {
    let TaskBoardLabels = [...task.TaskBoardLabels];
    let index = TaskBoardLabels.findIndex((x) => x.BoardLabelId === item.Id);
    if (index > -1) {
      TaskBoardLabels.splice(index, 1);
    }
    const updateTask = {
      ...task,
      TaskBoardLabels,
    };
    props.onUpdateTask(updateTask);
  };

  return (
    <div className="label-popup">
      <div className="label-popup__section">
        <div className="label-popup__section-item-input">
          <FormGroup
            autoComplete="off"
            autoFocus
            value={searchLabel}
            name="search"
            onChange={handleChangeSearchLabel}
          />
        </div>
        <ul>{renderLabels()}</ul>
      </div>
      <div className="label-popup__divider" />
      <Stages onClickStage={(val) => onClickStage(val)} />

      <div className="label-popup__divider" />
      <div className="label-popup__section">
        <div
          className="label-popup__section-footer"
          onClick={() => {
            setStatusOpen(false);
            setOpenLabelModel(true);
          }}
        >
          <FaPencilAlt /> Add or Edit Labels
        </div>
      </div>
    </div>
  );
};

export default LabelPopup;
