import {GET_ALLOWANCES, EMPLOYEE_SINGLE_ALLOWANCE_REQUEST} from "actions/types";
import types from "constants/types";

const initialState = {
  allowanceRequest: null,
  employeeRequest: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALLOWANCES:
      return {
        ...state,
        allowanceRequest: action.payload,
      };
    case EMPLOYEE_SINGLE_ALLOWANCE_REQUEST:
      return {
        ...state,
        employeeRequest: action.payload,
      };
    case types.EXPORT_ALLOWANCES_REQUEST:
      return {
        ...state,
        allowancesExportList: action.payload,
      };
    default:
      return state;
  }
}
