import React, {Component} from "react";
import {connect} from "react-redux";
import RouteEnum from "constants/routeEnum";
import {DisplayClientSideMessage} from "actions/commonAction";
import isEmpty from "isEmpty";
import Form from "components/form";
import "../style.scss";
import {InputValidator} from "helpers/inputValidator";
import SideBar from "./sideTabs";
import Property_Details from "./propertyDetails";
import LoanRequirement from "./loanRequirement";
import Documents from "../../helpers/documentUpload";
import Approvers from "./approvers";
import Area from "./area";
import Button from "components/button";
import Modal from "components/modal";
import ViewDocument from "../../helpers/documentViewer";
import {objectToFormData} from "object-to-formdata";
import {getUploadPath} from "constants/userDetails";
import getFullStringUrl from "helpers/getFullStringUrl";
import {fileLocation, hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";
import {
  getHomeLoanPurposeList,
  getConstructionStages,
  saveHomeLoanRequest,
  getHomeLoanDetails,
  updateHomeLoanRequest,
  getQualifiedApprovers,
  assignForwaredLoan,
} from "../../helpers/action";
import Loaders from "components/loaders";
const propertyType = [
  {
    label: "Building",
    value: "1",
  },
  {
    label: "Apartment",
    value: "2",
  },
];

const BluePrintOptions = [
  {value: "1", label: "Yes"},
  {value: "0", label: "No"},
];

const loanReqCategory = [
  "LoanPurchaseCost",
  "RegistrationFee",
  "TotalConstructionCost",
  "DrawingApprovalCost",
  "ContengencyCost",
  "OtherCost",
];
export class HomeLoanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RequestAmount: "",
      Purpose: "",
      Location: "",
      PropertyType: "",
      ApartmentLocation: "",
      PropertyOwner: "",
      ApartmentName: "",
      BluePrintApproved: null,
      ApartmentNumber: "",
      LandBuilderName: "",
      ApartmentBuilderName: "",

      LandArea: "",
      BuildingArea: "",
      KittaNumber: "",
      NumberofStoreys: "",
      EstimatedValue: "",

      LoanPurchaseCost: "",
      RegistrationFee: "",

      TotalConstructionCost: "",
      DrawingApprovalCost: "",
      ContengencyCost: "",
      OtherCost: "",
      currentStep: 1,
      isAt: 1,
      errors: {},
      ConstructionStage: "",
      Files: [],
      EditView: false,
      AttachmentIds: [],
      ForwardTo: "",
      AssignTo: "",
      open: false,
      approverTitle: "Human Resource Department",
      TermsCond: false,
      PropertyType: null,
    };
  }

  async componentDidMount() {
    const {
      getHomeLoanPurposeList,
      getConstructionStages,
      match,
      getHomeLoanDetails,
      getQualifiedApprovers,
    } = this.props;
    const {
      user: {EmployeeId},
    } = this.props;

    await getQualifiedApprovers(EmployeeId);

    getHomeLoanPurposeList();
    getConstructionStages();
    const {id} = match.params;

    if (id) await getHomeLoanDetails(id);
    let {homeLoanDetail, loanApproverList, user} = this.props;
    loanApproverList =
      loanApproverList && loanApproverList.filter((loan) => loan.Role == 1);
    // loanApproverList && loanApproverList.filter((loan) => loan.IsHR == true);
    if (id && homeLoanDetail) {
      let {
        attachments,
        Purpose,
        BluePrintApproved,
        LoanForwards,
        Location,
        EmployeeId,
        ForwardTo,
        PropertyType,
      } = homeLoanDetail;
      let isSelfApplied = user?.EmployeeId == EmployeeId;
      if (isEmpty(BluePrintApproved)) BluePrintApproved = null;
      if (BluePrintApproved) BluePrintApproved = BluePrintOptions[0];
      else BluePrintApproved = BluePrintOptions[1];
      // if (!isEmpty(BluePrintApproved)){
      //   BluePrintApproved ? BluePrintApproved = BluePrintApproved[0] : BluePrintApproved = BluePrintApproved [1]
      // } else BluePrintApproved = BluePrintApproved[1]
      Purpose = Purpose.toString();

      let Files = [];
      attachments &&
        attachments.forEach((file) => {
          Files.push({
            ...file,
            OriginalName: file.UserFileName,
            DocumentPath: file.FileLocation,
            DocumentName: file.UserFileName,
            name: file.UserFileName,
            path: getUploadPath(file.FileLocation + file.ServerFileName),
            docName: file.UserFileName,
            isFetched: true,
          });
        });
      // let ForwardTo;
      // LoanForwards.forEach((loan) => {
      //   ForwardTo.push(loan.ForwardTo);
      // });
      this.setState({
        ...homeLoanDetail,
        BluePrintApproved,
        EditView: true,
        Id: id,
        Files,
        ForwardTo: ForwardTo ? ForwardTo : "",
        getQualifiedApprovers,
        loanApproverList,
        PropertyType,
        EmployeeId,
        isSelfApplied,
      });
    } else {
      this.setState({loanApproverList, isSelfApplied: true});
    }
  }

  handleInput = async (name, value) => {
    this.setState({[name]: value}, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors, Purpose, PropertyType} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      if (Purpose == "") {
        errors = {
          ...errors,
          Purpose: "Required",
        };
      } else {
        delete errors.Purpose;
        // errors = {
        //   ...errors,
        //   Purpose: false,
        // };
      }
      if (PropertyType == "") {
        errors = {
          ...errors,
          PropertyDetailErrors: "Required",
        };
      } else {
        delete errors.PropertyDetailErrors;
        // errors = {
        //   ...errors,
        //   Purpose: false,
        // };
      }
      this.setState({errors});
      // this.setState({errors});
    }
  };

  onStepChange = async (step, isDraft) => {
    const {
      currentStep,
      isAt,
      Location,
      ApartmentLocation,
      LandBuilderName,
      ApartmentBuilderName,
      PropertyType,
      ConstructionStage,
      Purpose,
      Files,
    } = this.state;
    let errors = await InputValidator(document, this.state);
    let loanError = false;
    let errorMessage = "";
    let loanValue = 0;
    if (step > 1) {
      if (isEmpty(PropertyType)) {
        errors = {
          ...errors,
          PropertyDetailErrors: true,
        };
        // loanError = true;
        // errorMessage = "Please Select a Property type";
      } else {
        delete errors.PropertyDetailErrors;

        // errors = {
        //   ...errors,
        //   PropertyDetailErrors: false,
        // }
      }
      if (Purpose == "") {
        errors = {
          ...errors,
          Purpose: true,
        };
        // loanError = true;
        // errorMessage = "Please Select Loan Purpose";
      } else delete errors.Purpose;
      // else {
      //   errors = {
      //     ...errors,
      //     Purpose: false,
      //   }
      // }
    }
    if (currentStep == 2 && ConstructionStage == "") {
      loanError = true;
      errorMessage = "Please Select a construction stage";
      if (ConstructionStage == "") {
        errors = {
          ...errors,
          constructionStagesError: true,
        };
        // loanError = true;
        // errorMessage = "Please Select Loan Purpose";
      } else delete errors.constructionStagesError;
    }
    if (currentStep == 3) {
      loanReqCategory &&
        loanReqCategory.forEach((loan) => {
          if (isNaN(this.commasOnlyRemover(this.state[loan]))) {
            errorMessage = "Every Cost of Loan Requirement should be in figures";
            loanError = true;
          }
          loanValue += this.state[loan] != "" ? this.commasRemover(this.state[loan]) : 0;
        });
    }
    if (currentStep == 4) {
      if (isEmpty(Files) && !isDraft) {
        this.props.DisplayClientSideMessage(
          "Please upload all of the required documents"
        );
        return;
      }
    }
    if (loanError) {
      let message = {
        MessageType: "danger",
        Message: errorMessage,
      };
      this.props.DisplayClientSideMessage(message);
      // return;
    }
    if (isNaN(loanValue) || (currentStep == 3 && loanValue == 0)) {
      let message = {
        MessageType: "danger",
        Message: "At least one Loan Requirement is Required ",
      };
      this.props.DisplayClientSideMessage(message);
      // return;
    }
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }
    if (step >= 1 && step < 6) {
      this.setState({currentStep: currentStep + 1}, () =>
        this.state.currentStep > isAt
          ? this.setState({isAt: this.state.currentStep})
          : null
      );
      this.setState({currentStep: step});
    }
  };

  renderCurrentStage = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <Property_Details
            data={this.state}
            onChange={this.handleInput}
            errors={this.state.errors}
          />
        );
      case 2:
        return (
          <Area
            data={this.state}
            onChange={this.handleInput}
            constructionStages={this.props.constructionStages}
            errors={this.state.errors}
          />
        );
      case 3:
        return <LoanRequirement data={this.state} onChange={this.handleInput} />;
      case 4:
        return (
          <Documents
            data={this.state}
            onChange={this.handleInput}
            showDocumentModal={this.showDocumentModal}
            loanType={"home"}
            DisplayClientSideMessage={this.props.DisplayClientSideMessage}
          />
        );
      case 5:
        return <Approvers data={this.state} onChange={this.handleInput} />;
    }
  };

  showDocumentModal = (file, flag) => {
    if (flag) window.open(window.URL.createObjectURL(file), "_blank");
    else if (file) {
      const param = {
        serverFileName: file.ServerFileName.replace("Uploads/", ""),
        userFileName: file.UserFileName,
        fileType: file.FileType,
      };
      window.open(
        getFullStringUrl(`${hostedUrl}${Apis.common.downloadFiles}`, param),
        "_blank"
      );

      // let fileURI = "";
      // if (!file?.isFetched) fileURI = URL.createObjectURL(file);
      // else fileURI = getUploadPath(`/${file.ServerFileName}`);
      // window.open(fileURI, "_blank");
    }
    // if (file) {
    //   let fileURI = "";
    //   if (!file?.isFetched) fileURI = URL.createObjectURL(file);
    //   else fileURI = getUploadPath(`/${file.ServerFileName}`);
    //   window.open(fileURI, "_blank");
    // }
  };

  commasRemover = (num) => parseInt(num.toString().replace(/,/g, ""));

  commasOnlyRemover = (num) => num.toString().replace(/,/g, "");

  handleFormSubmit = async (IsDraft) => {
    let errors = await InputValidator(document, this.state);

    if (
      (!isEmpty(errors) && !IsDraft) ||
      (Purpose == "" && !IsDraft) ||
      (PropertyType == "" && !IsDraft)
    ) {
      if (this.state.Purpose == "") {
        errors = {
          ...errors,
          Purpose: true,
        };
      } else {
        errors = {
          ...errors,
          Purpose: false,
        };
      }
      if (isEmpty(PropertyType)) {
        errors = {
          ...errors,
          PropertyDetailErrors: true,
        };
      } else {
        errors = {
          ...errors,
          PropertyDetailErrors: false,
        };
      }
      this.setState({errors});
      return;
    }
    if (Purpose == "" && !IsDraft) {
      DisplayClientSideMessage("Please Select your loan purpose");
      return;
    }
    if (PropertyType == "" && !IsDraft) {
      DisplayClientSideMessage("Please Select your property type");
      return;
    }
    let {
      RequestAmount,
      Purpose,
      Location,
      ApartmentLocation,
      PropertyOwner,
      ApartmentName,
      BluePrintApproved,
      ApartmentNumber,
      LandBuilderName,
      ApartmentBuilderName,

      LandArea,
      BuildingArea,
      KittaNumber,
      NumberofStoreys,
      EstimatedValue,

      LoanPurchaseCost,
      RegistrationFee,
      TotalConstructionCost,
      DrawingApprovalCost,
      ContengencyCost,
      OtherCost,

      ConstructionStage,
      Files,
      EditView,
      Id,
      ForwardTo,
      isSelfApplied,
      PropertyType,
    } = this.state;
    const {
      user: {EmployeeId},
    } = this.props;

    if (isEmpty(Files) && !IsDraft) {
      this.props.DisplayClientSideMessage("Please upload all of the required documents");
      return;
    }

    if (Purpose == "") {
      this.props.DisplayClientSideMessage("Please select the loan purpose");
      return;
    }

    let homeLoanData = {
      Id: EditView ? Id : 0,
      EmployeeId: EditView ? this.state.EmployeeId : EmployeeId,
      RequestAmount,
      Purpose: Purpose ? parseInt(Purpose) : "",
      Location,
      PropertyOwner,
      BluePrintApproved: isEmpty(BluePrintApproved)
        ? null
        : BluePrintApproved.value === "1"
        ? true
        : false,
      LandBuilderName,
      ApartmentLocation,
      ApartmentName,
      ApartmentNumber,
      ApartmentBuilderName,

      LandArea,
      BuildingArea,
      KittaNumber,
      NumberofStoreys,
      EstimatedValue,

      ConstructionStage,
      LoanPurchaseCost: LoanPurchaseCost != "" ? this.commasRemover(LoanPurchaseCost) : 0,
      RegistrationFee: RegistrationFee != "" ? this.commasRemover(RegistrationFee) : 0,
      TotalConstructionCost:
        RegistrationFee != "" ? this.commasRemover(TotalConstructionCost) : 0,
      DrawingApprovalCost:
        DrawingApprovalCost != "" ? this.commasRemover(DrawingApprovalCost) : 0,
      ContengencyCost: ContengencyCost != "" ? this.commasRemover(ContengencyCost) : 0,
      OtherCost: OtherCost != "" ? this.commasRemover(OtherCost) : 0,
      ForwardTo,
      IsDraft: IsDraft,
      PropertyType,
    };
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };

    let AttachmentIds = [];
    Files &&
      Files.forEach((doc, i) => {
        if (doc.AttachmentID) AttachmentIds.push(doc.AttachmentID);
      });
    homeLoanData.AttachmentIds = AttachmentIds;
    const formData = objectToFormData(homeLoanData, options);
    Files &&
      Files.map((doc, i) => {
        if (!doc.AttachmentID) formData.append(`Files`, doc);
      });

    if (EditView) await this.props.updateHomeLoanRequest(formData);
    else await this.props.saveHomeLoanRequest(formData);

    const {saveHomeLoan, history} = this.props;
    if (saveHomeLoan) {
      if (isSelfApplied) history.push(RouteEnum.EmployeeLoanList);
      else history.push(RouteEnum.LoanManagement);
    }
  };

  onClose = () => this.setState({open: false, ForwardTo: "", loanToForward: null});

  handleAssignInput = (name, value) => {
    this.setState({AssignTo: value});
  };
  handleAssignerSubmit = async () => {
    const {LoanId, ForwardTo} = this.state;
    let data = {
      LoanId,
      EmployeeId: ForwardTo,
      LoanType: "PrimeHomeLoan",
    };
    await this.props.assignForwaredLoan(data);
    const {isLoanAssigned} = this.props;
    if (isLoanAssigned) {
      this.props.history.goBack();
    }
  };

  render() {
    let {
      RequestAmount,
      Purpose,
      isAt,
      currentStep,
      errors,
      ForwardTo,
      AssignTo,
      loanApproverList,
      EditView,
      open,
      approverTitle,
      TermsCond,
      isSelfApplied,
    } = this.state;
    const {homeLoanPurposeList, isLoading} = this.props;
    return (
      <div className="personal">
        <div className="personal-container">
          <div className="homeloan">
            <div className="homeloan-body">
              <div className="homeloan-header mb-bg">
                <h3>Staff Home Loan Request</h3>
              </div>
              <Modal
                open={this.state.viewDocument}
                onModalClose={() => this.showDocumentModal(null, false)}
                title="Documents"
                className="travel-request-document homeloan-viewDocs"
              >
                {this.state.viewDocument ? (
                  <ViewDocument viewDocs={this.state.viewDocs} />
                ) : (
                  <span></span>
                )}
              </Modal>
              <div className="homeloan-section-content">
                <Form
                  name="RequestAmount"
                  value={RequestAmount}
                  type="number"
                  onChange={this.handleInput}
                  label="Loan Request Amount"
                  validators={["required", "posNumbers"]}
                  width={"30%"}
                  error={errors.RequestAmount}
                />
              </div>
              <div className={`homeloan-header ${errors.Purpose ? "" : " mb-sm"}`}>
                <span>Purpose of Loan *</span>
              </div>
              {errors && errors.Purpose && (
                <div className={`homeloan__checkbox__required text-red mb-sm`}>
                  Required
                </div>
              )}
              <div className="homeloan-section-content flex">
                <div className="homeloan-section-content homeloan-checkboxes-od wd-50 flex-column">
                  {homeLoanPurposeList &&
                    homeLoanPurposeList.map((radio, index) => {
                      if (index < 2)
                        return (
                          <label class="checkbox-container mb-md">
                            {radio.label}
                            <input
                              type="checkbox"
                              checked={radio.value == Purpose}
                              onChange={() => this.handleInput("Purpose", radio.value)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        );
                    })}
                </div>
                <div className="homeloan-section-content homeloan-checkboxes-od wd-50 flex-column">
                  {homeLoanPurposeList &&
                    homeLoanPurposeList.map((radio, index) => {
                      if (index > 1)
                        return (
                          <label class="checkbox-container mb-md">
                            {radio.label}
                            <input
                              type="checkbox"
                              checked={radio.value == Purpose}
                              onChange={() => this.handleInput("Purpose", radio.value)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        );
                    })}
                </div>
              </div>

              <div className="homeloan-section-content">
                <SideBar
                  isAt={isAt}
                  currentStep={currentStep}
                  onStepChange={this.onStepChange}
                />
              </div>
              <div className="homeloan-section-content">
                {this.renderCurrentStage(currentStep)}
              </div>
            </div>
            <div className="homeloan-section-footer">
              <div className="homeloan-section-footer-item">
                <Button
                  bg="subtle"
                  onClick={() => this.props.history.goBack()}
                  title="Cancel"
                />
              </div>
              <div className="homeloan-section-footer-item">
                <Button
                  bg="primary"
                  onClick={() => this.onStepChange(currentStep - 1, true)}
                  title="Previous"
                  isDisabled={currentStep == 1}
                />
              </div>
              {currentStep == 5 ? (
                isSelfApplied ? (
                  <div className="homeloan-section-footer-item ml-sm wd-100 align-right">
                    <Button
                      bg="primary"
                      title="Save as Draft"
                      onClick={() => this.handleFormSubmit(true)}
                      isDisabled={isLoading}
                    />
                  </div>
                ) : (
                  <div className="homeloan-section-footer-item homeloan-section-footer-next_button ml-sm"></div>
                )
              ) : (
                <div className="homeloan-section-footer-item homeloan-section-footer-next_button ml-sm">
                  <Button
                    bg="primary"
                    onClick={() => this.onStepChange(currentStep + 1, true)}
                    title="Next"
                  />
                </div>
              )}
              {isSelfApplied && currentStep != 5 && (
                <div className="homeloan-section-footer-item ml-sm wd-95 align-right">
                  <Button
                    bg="primary"
                    title="Save as Draft"
                    onClick={() => this.handleFormSubmit(true)}
                    isDisabled={isLoading}
                  />
                </div>
              )}
              {currentStep == 5 ? (
                <div className="homeloan-section-footer-item">
                  <Button
                    bg="primary"
                    title="Send Request"
                    isDisabled={!TermsCond || isLoading}
                    onClick={() => this.handleFormSubmit(false)}
                  />
                </div>
              ) : (
                <div className="homeloan-section-footer-item"></div>
              )}
            </div>
          </div>
          {isLoading ? <Loaders loading={isLoading} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  homeLoanPurposeList: state.loanReducer.homeLoanPurposeList,
  constructionStages: state.loanReducer.constructionStages,
  saveHomeLoan: state.loanReducer.saveHomeLoan,
  homeLoanDetail: state.loanReducer.homeLoanDetail,
  user: state.auth.user,
  auth: state.auth,
  loanApproverList: state.loanReducer.loanApproverList,
  isLoanAssigned: state.loanReducer.isLoanAssigned,
  isLoading: state.loanReducer.isLoading,
});

const mapDispatchToProps = {
  getHomeLoanPurposeList,
  getConstructionStages,
  saveHomeLoanRequest,
  getHomeLoanDetails,
  updateHomeLoanRequest,
  DisplayClientSideMessage,
  getQualifiedApprovers,
  assignForwaredLoan,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoanForm);
