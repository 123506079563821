import React, {Component} from "react";
import {AgGridReact} from "ag-grid-react";
import ProfileImage from "./profileImage";
import CommonPagination from "components/common/pagination";
import Icons from "components/icons";

const State = (props) => {
  const isActive = props.data.State !== "retired";
  return (
    <div className={`working-state ${isActive ? "working" : "retired"}`}>
      {isActive ? (
        <>
          <Icons name="Check" justSVG />
          <span>Working</span>
        </>
      ) : (
        <>
          <Icons name="Close" justSVG />
          <span>Past</span>
        </>
      )}
    </div>
  );
};

const CustomLoadingCellRenderer = (props) => {
  return <div className="">Loading</div>;
};
const toolTipCell = (props) => {
  return <div title={props.value}>{props.value}</div>;
};
class EmployeeTableAg extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: "EmployeeId",
          headerName: "Employee Name",
          pinned: "left",
          width: 300,
          cellRenderer: "profileImage",
        },
        {
          field: "EmployeeId",
          headerName: "ID",
          width: 75,
        },
        {field: "Level"},
        {field: "Position", cellRenderer: "toolTipCell"},
        {field: "Branch", cellRenderer: "toolTipCell"},
        {field: "Department"},
        {field: "Status"},
        {field: "Id", headerName: "", cellRenderer: "State"},
      ],
      domLayout: "autoHeight",
      defaultColDef: {
        width: 145,
        resizable: false,
      },
      // loadingCellRenderer: 'customLoadingCellRenderer',
      // loadingCellRendererParams: { loadingMessage: 'One moment please...' },
      frameworkComponents: {
        profileImage: ProfileImage,
        toolTipCell: toolTipCell,
        // customLoadingCellRenderer: CustomLoadingCellRenderer,
        State,
      },

      getRowHeight: function (params) {
        return 58;
      },
      rowData: [],
    };
  }

  public gridApi;
  public gridColumnApi;

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    const updateData = (data) => {
      this.setState({rowData: data});
    };

    if (this.props.data) {
      updateData(this.props.data);
    }
  };
  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid: any = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };

  render() {
    const {data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage, history} =
      this.props;
    return (
      <div className="table empTable">
        <div className="arrear-table">
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              onGridReady={this.onGridReady}
              getRowHeight={this.state.getRowHeight}
              // loadingCellRenderer={this.state.loadingCellRenderer}
              // loadingCellRendererParams={this.state.loadingCellRendererParams}
              rowData={this.props.data}
            />
          </div>
          {data && data.length > 0 && (
            <CommonPagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
      </div>
    );
  }
}
export default EmployeeTableAg;
