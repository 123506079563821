import React, {memo} from "react";
import Button from "components/button";
import FormGroup from "components/form";
import Form from "components/form";
import {GetEmployeeList} from "actions/commonAction";
import {UpdateOnboardingEmployee, GetOnboardingEmployeeDetail} from "../action";
import {connect} from "react-redux";
import {InputValidator} from "helpers/inputValidator";
import isEmpty from "isEmpty";
import {BS2AD} from "helpers/dateConvert";
import {convertTime12to24} from "helpers/timeConvert";
import {Date_Format_Reverse} from "constants/dateFormat";
import {DisplayClientSideMessage} from "actions/commonAction";
import {getConvertedDate} from "helpers/getConvertedDate";
import Loaders from "components/loaders";
import moment from "moment";
class DateAndPlaceToReport extends React.Component {
  state = {
    instruction: "",
    joinDate: "",
    officeInTime: null,
    place: null,
    contactToId: null,
    managerId: null,
    buddyId: null,
    isLoading: true,
    GoogleMapsURL: null,
  };

  componentDidMount = async () => {
    let {employeeListSelect} = this.props;
    await this.props.GetEmployeeList();
    employeeListSelect = this.props.employeeListSelect;
    await this.props.GetOnboardingEmployeeDetail(this.props.match.params.id);
    let {onboardingEmployeeDetail} = this.props;
    if (onboardingEmployeeDetail && onboardingEmployeeDetail?.EmployeeOnBoardingDto) {
      const {
        EmployeeOnBoardingDto: {
          Instruction,
          BuddyId,
          ManagerId,
          ContactToId,
          Place,
          OfficeInTime,
          JoinDate,
          WelcomeMessage,
          GoogleMapsURL,
        },
      } = onboardingEmployeeDetail;
      let contactId = employeeListSelect.find((e) => e.value == ContactToId);
      let managerId = employeeListSelect.find((e) => e.value == ManagerId);
      let buddyId = employeeListSelect.find((e) => e.value == BuddyId);

      let officeInTime =
        OfficeInTime && OfficeInTime.slice(0, OfficeInTime.lastIndexOf(":"));

      let initialData = {
        joinDate: JoinDate ? moment(JoinDate).format("YYYY/MM/DD") : "",
        officeInTime,
        place: Place,
        contactToId: contactId,
        managerId,
        buddyId,
        instruction: Instruction,
        welcomeMessage: WelcomeMessage,
        isLoading: false,
        GoogleMapsURL,
      };
      this.setState({...initialData});
      this.props.changeActiveTab("0");
    }
  };

  onClose = () => {
    this.props.history.push("/admin/employee_onboarding/");
  };

  handleFieldChange = (name, value) => {
    this.setState({[name]: value});
  };

  handleFormSubmit = async () => {
    const {
      instruction,
      joinDate,
      officeInTime,
      place,
      contactToId,
      managerId,
      buddyId,
      welcomeMessage,
      GoogleMapsURL,
    } = this.state;

    if (isEmpty(officeInTime)) {
      this.props.DisplayClientSideMessage("Reach by time is required");
      return;
    }
    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }
    const {employeeId} = this.props;

    let data = {
      Stage: 2,
      JoinDate: joinDate,
      OfficeInTime:
        officeInTime.includes("am") || officeInTime.includes("pm")
          ? convertTime12to24(officeInTime)
          : officeInTime /* .split(' ')[0], */,
      Place: place,
      ContactToId: contactToId.value,
      ManagerId: managerId.value,
      BuddyId: buddyId.value,
      Instruction: instruction,
      EmployeeId: employeeId,
      WelcomeMessage: welcomeMessage,
      GoogleMapsURL,
    };
    this.props.handleSubmit(data, "0", 2);
  };

  render() {
    const {
      instruction,
      joinDate,
      officeInTime,
      place,
      contactToId,
      managerId,
      buddyId,
      errors,
      isLoading,
      GoogleMapsURL,
    } = this.state;
    const {employeeListSelect, companyInformation, requesting} = this.props;
    if (isLoading) return null;
    return (
      <div className="date-and-place">
        <div className="date-and-place__container">
          <div className="date-and-place__body">
            <div className="date-and-place__header">DATE AND PLACE</div>
            <div className="date-and-place__firstday date-time-place__firstrow">
              <Form
                enableOkButton
                errors={errors?.joinDate}
                formName="customdateinput"
                // isNepali={!companyInformation?.IsEnglishDate}
                // isTextInNepali={false}
                label="First day of office"
                name="joinDate"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                placeholder="Select Date"
                validators={["required"]}
                value={joinDate}
                width="200px"
              />
              <Form
                formName="timepicker"
                name="officeInTime"
                value={officeInTime}
                onChange={(name, value) => this.handleFieldChange(name, value)}
                placeholder="Time.."
                defaultMeridiem={"am"}
                label="Reach office by"
                validators={["required"]}
                errors={errors && errors.officeInTime}
              />
              <Form
                // formName="timepicker"
                name="place"
                value={place}
                onChange={(name, value) => this.handleFieldChange(name, value)}
                label="Place"
                error={errors?.place}
                width="200px"
                validators={["required"]}
              />
              <Form
                // formName="timepicker"
                name="GoogleMapsURL"
                value={GoogleMapsURL}
                onChange={(name, value) => this.handleFieldChange(name, value)}
                label="Geolocation"
                // error={errors?.GoogleMapsURL}
                width="260px"
                // validators={["required"]}
              />
            </div>
            <div className="date-and-place__firstday">
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="contactToId"
                value={contactToId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.contactToId}
                width="200px"
                label="Whom to Contact"
              />
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="managerId"
                value={managerId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.managerId}
                width="200px"
                label="Your Manager"
              />
              <FormGroup
                formName="reactselect"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                name="buddyId"
                value={buddyId}
                options={employeeListSelect}
                // loading={jobProfileList.loading}
                loadingType="skeleton"
                placeholder="Select"
                // disabled={isViewMode}
                validators={["required"]}
                error={errors?.buddyId}
                width="200px"
                label="Your Buddy"
              />
            </div>
            <div className="date-and-place__instruction">
              <Form
                formName="textarea"
                name="instruction"
                onChange={(name, value) => this.handleFieldChange(name, value)}
                label="Instruction"
                value={instruction}
                placeholder="Instruction to be provided to the employee"
                width="745px"
                validators={["required"]}
                error={errors?.instruction}
                // isNepali={!companyInformation?.IsEnglishDate}
                // isTextInNepali={!companyInformation?.IsEnglishDate}
              />
            </div>
            <div className="date-and-place__footer">
              <Button
                bg="primary"
                title="Save and Next"
                onClick={() => this.handleFormSubmit()}
                isDisabled={requesting}
              />
              <Button bg="subtle" onClick={() => this.onClose()} title="Cancel" />
            </div>
          </div>
        </div>
        {requesting ? <Loaders loading={requesting} /> : null}
      </div>
    );
  }
}

DateAndPlaceToReport = connect(
  (state) => ({
    employeeListSelect: state.commonReducer.employeeListSelect,
    companyInformation: state.commonReducer.companyInformation,
    onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
    companyInformation: state.commonReducer.companyInformation,
    requesting: state.employeeOnboarding.requesting,
  }),
  {
    GetEmployeeList,
    UpdateOnboardingEmployee,
    GetOnboardingEmployeeDetail,
    DisplayClientSideMessage,
  }
)(DateAndPlaceToReport);

export default DateAndPlaceToReport;
