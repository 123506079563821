import isEmpty from "helpers/isEmpty";
import React, { useState } from "react";
import { useEffect } from "react";

const TextInput = (props) => {
  const {
    name,
    value,
    handleChange,
    handleSubmit,
    disableSubmitForBlur,
    placeHolder,
    onBlur,
    onKeyPress,
    autoFocus,
  } = props;

  useEffect(() => {
    //setNewValue(value);
  }, [value]);

  const [oldValue, setOldValue] = useState("");
  return (
    <input
      type="text"
      autoFocus={autoFocus}
      value={value}
      placeholder={placeHolder}
      onChange={(e: any) => {
        const { value } = e.target;
        handleChange(name, value);
      }}
      onKeyDown={(e: any) => {
        const { value } = e.target;

        if (e.key == "Enter" && oldValue !== value && !isEmpty(value)) {
          setOldValue(value);
          handleSubmit && handleSubmit(name, value);
        }
      }}
      onFocus={(e: any) => {
        const { value } = e.target;
        setOldValue(value);
      }}
      onBlur={async (e: any) => {
        if (oldValue != value && !isEmpty(value)! && !disableSubmitForBlur) {
          handleSubmit && (await handleSubmit(name, value));
        }
        onBlur && onBlur(e);
        // addLabel(value);
      }}
      onKeyPress={onKeyPress}
    />
  );
};
TextInput.defaultProps = {
  disableSubmitForBlur: false,
};
export default TextInput;
