import {
  ADD_BOARD,
  DELETE_BOARD,
  ARCHIVE_BOARD,
  ADD_TASK,
  GET_TASK_DETAILS,
  GET_BOARDS,
  ADD_TASK_DETAILS,
  ADD_TASK_DETAILS_LOADING,
  GET_CARD_VIEW,
  DELETE_TASKS,
  ADD_TASK_LABEL,
  GET_TABLE_VIEW,
  GET_TABLE_VIEW_LOADING,
  UPDATE_TABLE_VIEW,
  RESET_TABLE_VIEW,
  GET_BOARD_VIEW,
  GET_BOARD_MEMBERS,
  SAVE_BOARD_MEMBERS,
  GET_COMMENTS_BY_TASK_ID,
  GET_COMMENTS_BY_TASK_ID_LOADING,
  COMMENTS_SCROLL,
  GET_BOARD_ACTIVITY,
  SET_BOARD_ACTIVITY_AS_VIEWED,
  GET_BOARD_LABEL,
  GET_BOARD_DETAILS,
  GET_BOARD_DETAILS_EXCEL,
  Save_BOARD_LABEL,
  DELETE_BOARD_LABEL,
  SAVE_SELECTED_CUSTOM_FIELDS,
} from "./types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";
import {api, get, post, put, deletion, get_by_id} from "networkService";
import {TaskUpdateDetailsProps} from "./actionInterface";
import {TMStage, TmViewType} from "./enum";
import async from "react-select/async";

export const addBoard = (data) => async (dispatch) => {
  try {
    let res: any;
    if (data.BoardId === 0) {
      res = await post(api.taskManagement.board, dispatch, data);
    } else {
      res = await put(api.taskManagement.board, dispatch, data);
    }

    if (res && res.Status) {
      dispatch({
        type: ADD_BOARD,
        payload: res.Data,
      });
    }
    return res.Status;
  } catch (ex) {}
};

export const duplicateBoard = (boardId) => async (dispatch) => {
  var res: any = await get_by_id(api.taskManagement.duplicateBoard, dispatch, boardId);
  if (res && res.Status) {
    dispatch({
      type: ADD_BOARD,
      payload: res.Data,
    });
  }
};

export const deleteBoard = (id) => async (dispatch) => {
  var res: any = await deletion(api.taskManagement.board, dispatch, id);
  if (res && res.Status) {
    dispatch({
      type: DELETE_BOARD,
      payload: res.Data,
    });
  }
};
export const archiveBoard = (param) => async (dispatch) => {
  var res: any = await put(api.taskManagement.archive, dispatch, param);
  if (res && res.Status) {
    dispatch({
      type: ARCHIVE_BOARD,
      payload: res.Data,
    });
  }
};

export const getBoardMembers = (boardId) => async (dispatch) => {
  try {
    let res: any = await get_by_id(api.taskManagement.boardMembers, dispatch, boardId);
    dispatch({type: GET_BOARD_MEMBERS, payload: res.Data});
    return res.Data;
  } catch (ex) {}
};
export const saveBoardMembers = (boardId, members) => async (dispatch) => {
  try {
    let param = {
      BoardId: boardId,
      MemberIds: members.map((x) => x.EmployeeId),
    };
    let res = await put(api.taskManagement.boardMembers, dispatch, param);

    dispatch({type: SAVE_BOARD_MEMBERS, payload: members});
  } catch (ex) {}
};

export const getBoards = (boardName) => async (dispatch) => {
  const res: any = await get(api.taskManagement.board, dispatch, {boardName});
  if (res.Status) {
    dispatch({
      type: GET_BOARDS,
      payload: res.Data,
    });
  } else {
    dispatch({
      type: GET_BOARDS,
      payload: null,
    });
  }
};

export const getBoardsDetails =
  (data, isExcel = false) =>
  async (dispatch) => {
    const res: any = await post(
      api.taskManagement.boardDetails,
      dispatch,
      {...data},
      false
    );
    if (res.Status) {
      dispatch({
        type: isExcel ? GET_BOARD_DETAILS_EXCEL : GET_BOARD_DETAILS,
        payload: res.Data,
      });
      return res.Data;
    } else {
      dispatch({
        type: isExcel ? GET_BOARD_DETAILS_EXCEL : GET_BOARD_DETAILS,
        payload: null,
      });
      return [];
    }
  };

export const getTableView =
  (boardId, taskName = "", employeeIds = "") =>
  async (dispatch) => {
    dispatch({type: GET_TABLE_VIEW_LOADING, payload: true});

    const res: any = await get(api.taskManagement.task.tableView, dispatch, {
      boardId,
      taskName,
      employeeIds,
    });
    let data: any = null;
    if (res.Status) {
      data = res.Data;
    }
    dispatch({type: RESET_TABLE_VIEW});
    dispatch({
      type: GET_TABLE_VIEW,
      payload: data,
    });
  };

export const getBoardView = (boardId) => async (dispatch) => {
  const res: any = await get(api.taskManagement.task.boardView, dispatch, {
    boardId,
  });
  let data: any = null;
  if (res.Status) {
    data = res.Data;
  }
  dispatch({
    type: GET_BOARD_VIEW,
    payload: data,
  });
};

export const getCardView = () => async (dispatch) => {
  await axios
    .get(api.taskManagement.boards.cardView)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_CARD_VIEW,
          payload: response.data.Data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_CARD_VIEW,
        payload: null,
      });
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get card view",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addTask = (data, view) => async (dispatch) => {
  await axios
    .post(api.taskManagement.task.tasks, data)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: ADD_TASK,
          payload: response.data.Data,
          view,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      console.log(err, err.response);
      // toastMessage(dispatch, response);
    });
};

export const deleteTasks = (ids: number[], boardId) => async (dispatch) => {
  await axios
    .delete(api.taskManagement.task.tasks, {data: ids})
    .then((response) => {
      if (response.data.Status) {
        // dispatch({
        //   type: DELETE_TASKS,
        //   ids,
        // });
        dispatch(getTableView(boardId));
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      console.log(err, err.response);
      // toastMessage(dispatch, response);
    });
};

export const duplicateTask = (ids, boardId) => async (dispatch) => {
  await axios
    .post(api.taskManagement.task.tasksDuplicate, ids)
    .then((response) => {
      if (response.data.Status) {
        dispatch(getTableView(boardId));
        // dispatch({
        //   type: ADD_TASK,
        //   payload: response.data.Data,
        //   view
        // });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      console.log(err, err.response);
      // toastMessage(dispatch, response);
    });
};

export const moveTask = (ids, stageId, boardId) => async (dispatch) => {
  await axios
    .put(`${api.taskManagement.task.tasksMove}?stageId=${stageId}`, ids)
    .then((response) => {
      if (response.data.Status) {
        dispatch(getTableView(boardId));
        // dispatch({
        //   type: ADD_TASK,
        //   payload: response.data.Data,
        //   view
        // });
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      console.log(err, err.response);
      // toastMessage(dispatch, response);
    });
};

export const orderTask = (data) => async (dispatch) => {
  var res: any = await axios
    .patch(api.taskManagement.task.tasksOrder, data)
    .catch((ex) => {
      return false;
    });
  if (res && res.data.Status) return true;
  else {
    toastMessage(dispatch, res);
    return false;
  }

  // await axios
  //   .patch(api.taskManagement.task.tasksOrder, data)
  //   .then((response) => {
  //     if (response.data.Status) {
  //       // dispatch(getTableView(boardId));
  //       // dispatch({
  //       //   type: ADD_TASK,
  //       //   payload: response.data.Data,
  //       //   view
  //       // });
  //     }
  //     toastMessage(dispatch, response);
  //   })
  //   .catch((err) => {
  //     console.log(err, err.response);
  //     // toastMessage(dispatch, response);
  //   });
};

export const getTaskDetails = (taskId) => async (dispatch) => {
  const res: any = await get_by_id(api.taskManagement.task.taskDetail, dispatch, taskId);
  if (res.Status) {
    dispatch({
      type: GET_TASK_DETAILS,
      payload: res.Data,
    });
  } else {
    dispatch({
      type: GET_TASK_DETAILS,
      payload: null,
    });
  }
  // await axios
  //   .get("v1/tm/tasks-detail/" + taskId)
  //   .then((response) => {
  //     if (response.data.Status) {
  //       dispatch({
  //         type: GET_TASK_DETAILS,
  //         payload: response.data.Data,
  //       });
  //     }
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: GET_TASK_DETAILS,
  //       payload: null,
  //     });
  //     const response = {
  //       data: {
  //         MessageType: "Danger",
  //         Message: err.message
  //           ? err.message
  //           : "Failed to get task details for this task.",
  //       },
  //     };
  //     toastMessage(dispatch, response);
  //   });
};

export const addTaskDetails = (data) => async (dispatch) => {
  var isSuccess = false;
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });
  var res: any = await post(api.taskManagement.task.taskDetail, dispatch, data, false);
  if (res && res.Status) {
    dispatch({
      type: ADD_TASK_DETAILS,
      payload: res.Data,
    });
    isSuccess = true;
  } else {
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: false,
    });
  }
  return isSuccess;
};

export const updateTaskDetails = (data) => async (dispatch) => {
  let isSuccess = false;
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });
  var res: any = await put(
    api.taskManagement.task.taskDetail,
    dispatch,
    data,
    false
  ).catch((ex) => {
    if (ex.Data) {
      dispatch({
        type: ADD_TASK_DETAILS,
        payload: ex.Data,
      });
    }
  });

  if (res && res.Status) {
    dispatch({
      type: ADD_TASK_DETAILS,
      payload: res.Data,
    });
    isSuccess = true;
  } else {
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: false,
    });
  }
  return isSuccess;
};

export const updateTaskRating =
  ({TaskId, Rating}) =>
  async (dispatch) => {
    let success;
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: true,
    });
    var res: any = await post(
      api.taskManagement.task.taskRating,
      dispatch,
      {TaskId, Rating},
      false
    ).catch((ex) => {
      success = null;
    });
    if (res && res.Status) {
      success = res.Data;
    }
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: false,
    });

    return success;
  };

export const updateTaskComments =
  ({TaskId, Comment}) =>
  async (dispatch) => {
    let success;
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: true,
    });
    var res: any = await post(
      api.taskManagement.task.taskComments,
      dispatch,
      {TaskId, Comment},
      false
    ).catch((ex) => {
      success = null;
    });
    if (res && res.Status) {
      success = res.Data;
    }
    dispatch({
      type: ADD_TASK_DETAILS_LOADING,
      payload: false,
    });

    return success;
  };

export const deleteTaskRating = (id) => async (dispatch) => {
  let isSuccess = true;
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });
  var res: any = await deletion(
    api.taskManagement.task.taskRating,
    dispatch,
    id,
    false
  ).catch((ex) => {
    isSuccess = false;
  });

  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: false,
  });

  return isSuccess;
};

export const taskToDo = (startDate, dueDate) => async (dispatch) => {
  const res: any = await get(api.taskManagement.task.toDo, dispatch, {
    startDate,
    dueDate,
  });
  return res.Data;
};

export const GetEmployeeByName = (searchTerm) => async (dispatch: Function) => {
  const res = await get("v1/common/employees", dispatch, {searchTerm});
  return res;
};

//#region  Comment
export const saveComment =
  (param, scroll = false) =>
  async (dispatch: Function) => {
    dispatch({type: COMMENTS_SCROLL, payload: false});
    var res: any = await post(api.taskManagement.Comment.comment, dispatch, param, false);
    dispatch({
      type: GET_COMMENTS_BY_TASK_ID,
      payload: res.Data,
    });
    dispatch({type: COMMENTS_SCROLL, payload: scroll});
    return res;
  };

export const deleteComment = (id) => async (dispatch: Function) => {
  dispatch({type: COMMENTS_SCROLL, payload: false});
  var res: any = await deletion(api.taskManagement.Comment.comment, dispatch, id, false);
  dispatch({
    type: GET_COMMENTS_BY_TASK_ID,
    payload: res.Data,
  });
  return res;
};

export const getCommentsByTaskId = (taskId) => async (dispatch: Function) => {
  dispatch({type: COMMENTS_SCROLL, payload: false});
  dispatch({type: GET_COMMENTS_BY_TASK_ID_LOADING, payload: false});
  dispatch({type: GET_COMMENTS_BY_TASK_ID, payload: []});
  var res: any = await get_by_id(
    api.taskManagement.Comment.getCommentByTask,
    dispatch,
    taskId
  );
  dispatch({type: COMMENTS_SCROLL, payload: true});
  dispatch({
    type: GET_COMMENTS_BY_TASK_ID,
    payload: res.Data,
  });
};

export const addCommentReaction = (param) => async (dispatch: Function) => {
  dispatch({type: COMMENTS_SCROLL, payload: false});
  var res: any = await post(
    api.taskManagement.Comment.addReaction,
    dispatch,
    param,
    false
  );

  dispatch({
    type: GET_COMMENTS_BY_TASK_ID,
    payload: res.Data,
  });
};

export const removeCommentReaction = (param) => async (dispatch: Function) => {
  dispatch({type: COMMENTS_SCROLL, payload: false});
  var res: any = await post(
    api.taskManagement.Comment.removeReaction,
    dispatch,
    param,
    false
  );
  dispatch({
    type: GET_COMMENTS_BY_TASK_ID,
    payload: res.Data,
  });
};

//#endregion

//#region  Activities

export const getBoardActivity =
  (param, saveToReducer = true) =>
  async (dispatch: Function) => {
    var res: any = await get(api.taskManagement.activity, dispatch, param);
    let data = [];
    if (res.Status) {
      data = res.Data;
    }
    if (saveToReducer)
      dispatch({
        type: GET_BOARD_ACTIVITY,
        payload: data,
      });
    return data;
  };

export const setboardActivityAsViewed = (boardId) => async (dispatch: Function) => {
  var res: any = await post(
    `${api.taskManagement.activityViewed}/${boardId}`,
    dispatch,
    {},
    false
  );
  let data = [];
  if (res.Status) {
    data = res.Data;
  }
  dispatch({type: SET_BOARD_ACTIVITY_AS_VIEWED});
  //  dispatch({ type: GET_BOARD_ACTIVITY, payload: data });
};
//#endregion

//#region Board Label
export const saveBoardLabel = (param) => async (dispatch) => {
  var res: any = await post(`${api.taskManagement.boardLabel}`, dispatch, param);
  if (res.Status)
    dispatch({
      type: Save_BOARD_LABEL,
      payload: res.Data,
    });
};

export const getBoardLabel = (boardId) => async (dispatch) => {
  var res: any = await get_by_id(`${api.taskManagement.boardLabel}`, dispatch, boardId);
  dispatch({type: GET_BOARD_LABEL, payload: res.Data});
  return res;
};

export const deleteBoardLabel = (id) => async (dispatch) => {
  var res: any = await deletion(`${api.taskManagement.boardLabel}`, dispatch, id);
  dispatch({type: DELETE_BOARD_LABEL, payload: {id}});
  return res;
};

//#endregion

export const taskUpdateDetails =
  (props: TaskUpdateDetailsProps) => async (dispatch: Function) => {
    const {
      TaskId,
      Priority,
      TeamMembers,
      TaskBoardLabels,
      TaskName,
      DueDate,
      CustomField1,
      CustomField2,
      CustomField3,
    } = props.task;
    let param = {
      TaskId,
      Priority,
      TaskName,
      DueDate,
      CustomField1,
      CustomField2,
      CustomField3,
      EmployeeIds: TeamMembers && TeamMembers.map((x) => x.EmployeeId).toString(),
      TaskLabelIds: TaskBoardLabels && TaskBoardLabels.map((x) => x.BoardLabelId),
    };

    var res: any = await put(
      api.taskManagement.task.updateDetails,
      dispatch,
      param,
      false
    ).catch((res) => {
      let name = (res && res.Data && res.Data.TaskName) || "";
      if (name !== "") props.task.TaskName = name;
    });

    dispatch({
      type: UPDATE_TABLE_VIEW,
      payload: {
        task: props.task,
        rowIndex: props.rowIndex,
        stage: props.stage,
      },
    });
  };

export const setCustomFields =
  ({stageId, colName, isSelected, boardId}) =>
  (dispatch: Function) => {
    dispatch({
      type: SAVE_SELECTED_CUSTOM_FIELDS,
      payload: {stageId, colName, isSelected, boardId},
    });
  };

//#region Task Check List
export const addTaskCheckList = (param) => async (dispatch: Function) => {
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });

  var res: any = await post(
    api.taskManagement.task.checkList,
    dispatch,
    param.checkList,
    false
  ).catch((ex) => {});
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: false,
  });
  if (res && res.Status) return res.Data;

  return null;
};

export const updateTaskCheckList = (param) => async (dispatch: Function) => {
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });

  var res: any = await put(
    api.taskManagement.task.checkList,
    dispatch,
    param.checkList,
    false
  ).catch((ex) => {});
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: false,
  });
  if (res && res.Status) return res.Data;

  return null;
};
export const deleteTaskCheckList = (param) => async (dispatch: Function) => {
  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: true,
  });

  var res: any = await deletion(
    api.taskManagement.task.checkList,
    dispatch,
    param.id,
    false
  ).catch((ex) => {});

  dispatch({
    type: ADD_TASK_DETAILS_LOADING,
    payload: false,
  });

  if (res && res.Status) {
    return true;
  }

  return false;
};

//#endregion
