import React, {Component, useEffect, useState} from "react";
import {trash} from "assets/images/svgs/icons/trash";
import {email} from "assets/images/svgs/icons/email";
import emailOpen from "assets/images/svgs/icons/emailOpen";
import {formatedShortMonthDayYear} from "helpers/dateFormat";
import {CgAttachment} from "react-icons/cg";

const NewsCard = ({item}) => {
  const [height, setHeight] = React.useState(false);

  return (
    <li className="news-list__item">
      <div className="newsItem">
        <div className="newsItem__notice">
          <p className="notice-title">{item.CategoryName}</p>
          <span className="notice-time">
            {formatedShortMonthDayYear(item.PublishDate)}
          </span>
        </div>
        <div className="newsItem__data">
          <div className="newsItem-title">
            <h4>{item.Title}</h4>
          </div>
          <div className="newsItem-content">
            <p
              style={{height: height ? "auto" : 48}}
              dangerouslySetInnerHTML={{__html: item.Body}}
            >
              {/* {props.Body} */}
            </p>
            <div onClick={() => setHeight(!height)} className="newsItem-content-btn">
              {height ? "Hide News" : "View News"}
            </div>
          </div>
        </div>
        <div className="newsItem__icons">
          {item && item.URL && (
            <div className="newsItem__icons-item attachements">
              <a href={item.URL} target="_blank">
                <CgAttachment size={25} />
              </a>
            </div>
          )}
          {/* <div className="newsItem__icons-item">
            <div className="svg-icon email-icon">{email()}</div>
          </div>
          <div className="newsItem__icons-item">
            <div className="svg-icon email-open-icon">{emailOpen()}</div>
          </div>
          <div className="newsItem__icons-item">
            <div className="svg-icon trash-icon">{trash()}</div>
          </div> */}
        </div>
      </div>
    </li>
  );
};

export default NewsCard;
