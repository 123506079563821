import {
  SAVE_ACCESS_AND_ID_TEMPLATE,
  ACCESSANDIDTEMPLATELIST,
  SALARY_TEMPLATE_DETAILS,
  DELETE_SAVE_ACCESS_AND_ID_TEMPLATE,
  CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE,
} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";

export const GetAllAccessAndIdCategoryTemplate = () => async (dispatch) => {
  await axios
    .get(`/v1/onBoarding/access-id-categories`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: ACCESSANDIDTEMPLATELIST,
          payload: data,
        });
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const AddAccessAndIdTemplate = (data, closeForm) => async (dispatch) => {
  dispatch({
    type: SAVE_ACCESS_AND_ID_TEMPLATE,
    payload: false,
  });
  await axios
    .post(`/v1/onBoarding/access-id-categories`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_ACCESS_AND_ID_TEMPLATE,
          payload: true,
        });
        closeForm();
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const GetSalaryTemplateDetails = (templateId) => async (dispatch) => {
  dispatch({
    type: SALARY_TEMPLATE_DETAILS,
    payload: null,
    requesting: true,
  });
  await axios
    .get(`/v1/onBoarding/templates/${templateId}`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SALARY_TEMPLATE_DETAILS,
          payload: data,
          requesting: false,
        });
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      dispatch({
        type: SALARY_TEMPLATE_DETAILS,
        payload: null,
        requesting: false,
      });
      toastMessage(dispatch, res);
    });
};

export const UpdateAccessAndIdTemplate = (data, closeForm) => async (dispatch) => {
  dispatch({
    type: SAVE_ACCESS_AND_ID_TEMPLATE,
    payload: false,
  });
  await axios
    .put(`/v1/onBoarding/access-id-categories`, data)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: SAVE_ACCESS_AND_ID_TEMPLATE,
          payload: true,
        });
        closeForm();
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to save salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const DeleteSalaryTemplate = (templateId) => async (dispatch) => {
  dispatch({
    type: DELETE_SAVE_ACCESS_AND_ID_TEMPLATE,
    payload: false,
  });
  await axios
    .delete(`/v1/onBoarding/access-id-categories/${templateId}`)
    .then((res) => {
      if (res.data.Status) {
        let data = res.data.Data;
        dispatch({
          type: DELETE_SAVE_ACCESS_AND_ID_TEMPLATE,
          payload: true,
        });
        toastMessage(dispatch, res);
      }
    })
    .catch((err) => {
      const res = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to delte salary Template.",
        },
      };
      toastMessage(dispatch, res);
    });
};

export const ClearSaveAccessAndIdTemplate = () => (dispatch) => {
  dispatch({
    type: CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE,
  });
};
