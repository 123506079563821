import React, { useEffect, useState } from "react";
import ShiftTable from "./shiftTable";
import Filter from "./filter";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  GetShiftEmployeeAttendance,
  getWorkingHours,
  UpdateAllShiftAtttendance,
} from "../helpers/action";
import { DateFormat, DateTime_Format } from "helpers/dateFormat";
import Button from "components/button";

function ShiftEmployeeAttendance(props) {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [state, setState] = useState({
    daterange: {
      startDate: DateFormat(new Date(), "YYYY-MM-DD"),
      endDate: DateFormat(new Date(), "YYYY-MM-DD"),
    },
    teamId: null,
  });

  const { user } = useSelector((state: any) => state.auth);

  const onChange = (name, value) => {
    setState((prev) => {
      const newState = { ...prev };
      newState[name] = value;
      return newState;
    });
  };

  useEffect(() => {
    getDataList(state);
  }, []);

  const getDataList = async (data) => {
    var param: any = {
      fromDate: data.daterange?.startDate,
      toDate: data.daterange?.endDate,
      teamId: data?.teamId?.value,
      shiftId: data?.shiftId?.value,
      searchEmployee: data?.searchEmployee,
      showBy: data?.showBy?.value,
    };
    console.clear();
    console.log(param);

    var res: any = await dispatch(GetShiftEmployeeAttendance(param));

    if (res && res.Data) {
      setDataList(res.Data || []);
    }
  };

  const onSearch = () => getDataList(state);

  const updateAll = async () => {
    const { startDate, endDate } = state.daterange;
    await dispatch(UpdateAllShiftAtttendance({ startDate, endDate }));
    onSearch();
  };
  return (
    <div className="workHours">
      <div className="shifts-title">Attendance</div>
      <div className="shifts-filter">
        <Filter onChange={onChange} onSearch={onSearch} state={state} />
        {user?.RoleName === "Administrator" && (
          <Button buttonClass="updateshift" title="Update All" onClick={() => updateAll()} />
        )}
      </div>

      <ShiftTable
        data={dataList}
        filterState={state}
        handleRowChange={() => { }}
        handleIndexChange={() => { }}
        pageIndex={0}
        rowsPerPage={1000}
        onSuccessSavingAttendance={onSearch}
      />
    </div>
  );
}

export default ShiftEmployeeAttendance;
