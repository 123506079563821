import {refreshToken} from "actions/authAction";
import axios from "axios";
import TokenService from "src/utils/tokenService";
import store from "src/store/configureStore";
import {addLoading} from "actions/loadingActions";
import environment from "environment";
import {baseURL} from "constants/urls";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const loading = async (payload, type = null) => {
  await store.dispatch(addLoading(payload, type));
};

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAuthToken();

    if (token) {
      config.headers["Authorization"] = token;
    }
    if (config.headers.loading) {
      loading(true);
    }
    if (config.headers.type) {
      loading(true, config.headers.type);
    }
    return config;
  },
  async (error) => {
    await loading(false);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.headers.loading) {
      loading(false);
    }
    if (response.config.headers.type) {
      loading(false, response.config.headers.type);
    }
    return response;
  },
  async (err) => {
    await loading(false);
    const originalConfig = err.config;

    if (originalConfig.url !== environment.api.v1.auth.signIn && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await store.dispatch(refreshToken());
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
