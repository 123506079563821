import React from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import {numberToNepaliCurrency} from "helpers/regex";
import {getUploadPath} from "constants/userDetails";
import removeTagsFromStrings from "helpers/removeTagsFromStrings";

import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import RobotoMedium from "assets/fonts/Roboto-Medium.ttf";
import RobotoBold from "assets/fonts/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoMedium,
    },
    {
      src: RobotoBold,
    },
  ],
});

export default function settlementPdfTemplate(props) {
  const {
    CompanyDetail,
    TravelDetails,
    TravelDestination,
    TravelExpense,
    TravelRequestApprover,
    SettlementApprover,
    OtherExpenses,
  } = props;

  let advanceAndExpense_SubTotal = 0;
  let advanceAndExpense_Total = 0;
  let otherExpensesTotal = 0;

  return (
    <Document title="Settlement Request.pdf">
      <Page style={styles.body} size="A4">
        {/* <Text style={styles.header} fixed>
          Settlement Request
        </Text> */}

        {CompanyDetail && (
          <>
            {!isEmpty(CompanyDetail.CompanyLogo) && (
              <Image
                style={styles.image}
                src={getUploadPath(CompanyDetail.CompanyLogo)}
              />
            )}
            <Text style={styles.company}>{CompanyDetail.CompanyName}</Text>
            <Text style={styles.address}>{CompanyDetail.Address}</Text>
          </>
        )}

        <View style={{marginTop: 15}}></View>
        <Text style={styles.title}>Travel Report and Settlement Request</Text>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Travel Order</Text>
            <Text style={styles.rightColumn}>
              {!isEmpty(TravelDetails.RequestNumber) ? TravelDetails.RequestNumber : "--"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Date</Text>
            <Text style={styles.rightColumn}>
              {TravelDetails.TravelOrderDate ? TravelDetails.TravelOrderDate : "--"}
            </Text>
          </View>
        </View>

        <View style={{marginTop: 10}}></View>
        {TravelDetails.RequestedBy && (
          <View>
            <Text style={styles.text}>Authorized to</Text>
            <Text style={styles.name}>{TravelDetails && TravelDetails.RequestedBy}</Text>
            <Text style={styles.text}>
              {TravelDetails.Designation && TravelDetails.Designation + ", "}
              {TravelDetails.Department && TravelDetails.Department + ", "}
              {TravelDetails.Branch}
            </Text>
          </View>
        )}

        <Text
          style={[
            styles.subtitle,
            {
              borderBottomWidth: 0.5,
              marginBottom: 3,
            },
          ]}
        >
          Travel Information
        </Text>
        {TravelDestination &&
          TravelDestination.map((dest, i) => {
            return (
              <View style={styles.travelInformation} key={i}>
                <View style={styles.row}>
                  <Text style={styles.leftColumn}>Place of Travel</Text>
                  <Text style={styles.rightColumn}>{dest.PlaceName}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.leftColumn}>Mode</Text>
                  <Text style={styles.rightColumn}>{dest.TransportMode}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.leftColumn}>Dates</Text>
                  <Text style={styles.rightColumn}>
                    {dest.StartDate + " - "}
                    {dest.EndDate}
                    {!isEmpty(dest.TravelDuration) && " (" + dest.TravelDuration + ")"}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.leftColumn}>Purpose</Text>
                  <Text style={styles.rightColumn}>{TravelDetails.Purpose}</Text>
                </View>
              </View>
            );
          })}

        <View style={styles.summary}>
          <Text style={styles.subtitle}>Travel Summary</Text>
          {!isEmpty(TravelDetails.TravelSummary) ? (
            <Text style={styles.summaryText}>
              {removeTagsFromStrings(TravelDetails.TravelSummary)}
            </Text>
          ) : (
            <Text style={styles.message}>No travel summary found.</Text>
          )}
        </View>

        <Text style={[styles.subtitle, {marginTop: 10}]}>Allowances and Advance</Text>
        {TravelExpense && TravelExpense.length > 0 ? (
          <View style={styles.allowanceAndAdvance}>
            <View style={styles.tableTitle}>
              <Text style={styles.c1}>Particulars</Text>
              <Text style={styles.c2}>Units</Text>
              <Text style={styles.c3}>Rate</Text>
              <Text style={styles.c4}>Amount</Text>
            </View>
            {TravelExpense.map((te, i) => {
              advanceAndExpense_SubTotal =
                advanceAndExpense_SubTotal + parseFloat(te.Amount);
              otherExpensesTotal = OtherExpenses.TotalAmount
                ? OtherExpenses.TotalAmount
                : 0;
              advanceAndExpense_Total = advanceAndExpense_SubTotal + otherExpensesTotal;
              return (
                <View style={styles.row} key={i}>
                  <Text style={styles.c1}>{te.AllowanceName}</Text>
                  <Text style={styles.c2}>{te.Units}</Text>
                  <Text style={styles.c3}>{numberToNepaliCurrency(te.Rate)}</Text>
                  <Text style={styles.c4}>{numberToNepaliCurrency(te.Amount)}</Text>
                </View>
              );
            })}
            <View style={styles.row}>
              <Text style={styles.c1}>Sub Total</Text>
              <Text style={styles.c2}></Text>
              <Text style={styles.c3}></Text>
              <Text style={styles.c4}>
                {numberToNepaliCurrency(advanceAndExpense_SubTotal)}
              </Text>
            </View>
            {OtherExpenses && (
              <View style={styles.row}>
                <Text style={styles.c1}>
                  {OtherExpenses.OtherExpenseName && OtherExpenses.OtherExpenseName}
                </Text>
                <Text style={styles.c2}></Text>
                <Text style={styles.c3}></Text>
                <Text style={styles.c4}>
                  {numberToNepaliCurrency(otherExpensesTotal)}
                </Text>
              </View>
            )}

            <View style={styles.total}>
              <Text style={styles.c1}>Total</Text>
              <Text style={styles.c2}></Text>
              <Text style={styles.c3}></Text>
              <Text style={styles.c4}>
                {numberToNepaliCurrency(advanceAndExpense_Total)}
              </Text>
            </View>

            <View style={styles.advance}>
              <Text style={styles.c1}>Advance</Text>
              <Text style={styles.c2}></Text>
              <Text style={styles.c3}></Text>
              <Text style={styles.c4}>
                {numberToNepaliCurrency(TravelDetails.AdvanceTakenAmount)}
              </Text>
            </View>

            <View style={styles.net}>
              <Text style={styles.c1}>Net Payable / (Receivable)</Text>
              <Text style={styles.c2}></Text>
              <Text style={styles.c3}></Text>
              <Text style={styles.c4}>
                {numberToNepaliCurrency(
                  advanceAndExpense_Total - TravelDetails.AdvanceTakenAmount
                )}
              </Text>
            </View>
          </View>
        ) : (
          <Text style={styles.message}>No Allowance and advance found.</Text>
        )}

        <View style={styles.approvedBy}>
          <Text style={styles.approvedByTitle}>Travel Order approved by</Text>
          <View style={styles.authorizers}>
            {TravelRequestApprover && TravelRequestApprover.length > 0
              ? TravelRequestApprover.map((tra, i) => {
                  return (
                    <View style={styles.authorizer}>
                      <Text style={styles.text}>{tra.ApproverName}</Text>
                      <Text style={styles.text}>{tra.Designation}</Text>
                      <Text style={styles.text}>{tra.ApprovedOn}</Text>
                    </View>
                  );
                })
              : // <Text style={styles.message}>No authorization approved yet.</Text>
                null}
          </View>
        </View>

        <View style={styles.approvedBy}>
          <Text style={styles.approvedByTitle}>Travel Report approved by</Text>
          <View style={styles.authorizers}>
            {SettlementApprover && SettlementApprover.length > 0
              ? SettlementApprover.map((tra, i) => {
                  return (
                    <View style={styles.authorizer}>
                      <Text style={styles.text}>{tra.ApproverName}</Text>
                      <Text style={styles.text}>{tra.Designation}</Text>
                      <Text style={styles.text}>{tra.ApprovedOn}</Text>
                    </View>
                  );
                })
              : // <Text style={styles.message}>No travel report approved yet.</Text>
                null}
          </View>
        </View>

        <View style={styles.printedDate}>
          <Text style={styles.text}>{"Printed on: " + TravelDetails.PrintedOn}</Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 70,
  },
  company: {
    fontSize: 16,
    // marginBottom: 2,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Roboto",
  },
  branch: {
    fontSize: 10,
    textAlign: "left",
    marginTop: 5,
  },
  address: {
    fontSize: 12,
    fontFamily: "Roboto",
  },

  title: {
    fontSize: 16,
    paddingVertical: 5,
    fontFamily: "Roboto",
  },

  subtitle: {
    fontSize: 12,
    textAlign: "left",
    paddingVertical: 3,
    marginTop: 5,
    fontFamily: "Roboto",
    fontWeight: "semibold",
  },

  row: {
    flexDirection: "row",
    marginVertical: 2.5,
  },
  leftColumn: {
    width: "20%",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  rightColumn: {
    width: "80%",
    fontSize: 10,
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },

  name: {
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 4,
    textAlign: "left",
    fontFamily: "Roboto",
  },

  travelInformation: {
    marginTop: 3,
    fontFamily: "Roboto",
  },

  summary: {
    marginTop: 10,
    fontFamily: "Roboto",
  },
  summaryTitle: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  summaryText: {
    fontSize: 10,
    fontFamily: "Roboto",
    textAlign: "left",
  },

  allowanceAndAdvanceTitle: {
    marginTop: 8,
    fontFamily: "Roboto",
  },
  allowanceAndAdvanceText: {
    fontSize: 12,
    textAlign: "left",
  },
  allowanceAndAdvance: {
    marginTop: 3,
  },

  tableTitle: {
    flexDirection: "row",
    paddingVertical: 3,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopColor: "#000000",
    borderBottomColor: "#000000",
    marginBottom: 3,
    fontFamily: "Roboto",
  },
  c1: {
    width: "55%",
    fontSize: 10,
    paddingLeft: 2,
    fontFamily: "Roboto",
  },
  c2: {
    width: "10%",
    textAlign: "justify",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  c3: {
    width: "15%",
    textAlign: "justify",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  c4: {
    width: "20%",
    textAlign: "right",
    fontSize: 10,
    fontFamily: "Roboto",
    paddingRight: 4,
  },

  total: {
    flexDirection: "row",
    paddingVertical: 3,
    borderTopWidth: 0.5,
  },

  advance: {
    flexDirection: "row",
    paddingVertical: 3,
    borderBottomWidth: 0.5,
    borderBottomColor: "grey",
  },

  net: {
    flexDirection: "row",
    paddingVertical: 3,
    borderBottomWidth: 0.5,
    borderBottomColor: "grey",
    fontWeight: "extrabold",
    fontSize: 10,
  },

  approvedBy: {
    marginVertical: 10,
  },
  approvedByTitle: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Roboto",
    // textDecoration: "underline",
  },
  authorizers: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  authorizer: {
    maxWidth: "50%",
    flexDirection: "column",
    marginVertical: 5,
    paddingRight: 40,
  },
  printedDate: {
    marginVertical: 5,
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  message: {
    fontSize: 8,
    margin: 4,
    textAlign: "left",
    fontFamily: "Roboto",
    color: "grey",
  },
  image: {
    maxWidth: "50%",
    maxHeight: 30,
    objectFit: "contain",
    marginRight: "auto",
    marginBottom: 4,
  },
  header: {
    fontSize: 16,
    fontWeight: "700",
    marginBottom: 20,
    textAlign: "center",
    // color: "grey",
    fontFamily: "Roboto",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
