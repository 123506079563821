import React, {useEffect, useState} from "react";
import Modal from "components/modal";
import {AiOutlineContainer} from "react-icons/ai";
import {IoMdShareAlt} from "react-icons/io";
import {MdContentCopy} from "react-icons/md";
import {JobCreatePath} from "routes/commonLinks";
import isEmpty from "isEmpty";
import {getApprovedJobRequisition} from "../jobRequisition/action";
import {
  GetDashboardDetail,
  SetCurrentCandidateDetail,
} from "store/actions/recruitment/dashboard";
import {connect} from "react-redux";

export const RenderModal = ({modalOpen, onModalClose, history}) => {
  const [newModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const onNewModalClose = () => setModalOpen(false);
  return (
    <Modal
      open={modalOpen}
      onModalClose={onModalClose}
      className="create-new-job-modal"
      title={"Create New Job Position"}
    >
      <div className="newJob">
        <ul className="newJob__listing">
          <li
            onClick={() => {
              setModalOpen(true);
              setType(1);
            }}
            className="newJob__listing-item"
          >
            <div className="newJob__listing-div">
              <span className="newJob__listing-icon">
                <IoMdShareAlt />
              </span>
              <div className="newJob__listing-details">
                <h4>Select from Approved Job Requisition</h4>
                <p>select an approved job requisition</p>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              setModalOpen(true);
              setType(2);
            }}
            className="newJob__listing-item"
          >
            <div className="newJob__listing-div">
              <span className="newJob__listing-icon">
                <MdContentCopy />
              </span>
              <div className="newJob__listing-details">
                <h4>Copy information from an exiting job</h4>
                <p>copy information and properties from your existing job</p>
              </div>
            </div>
          </li>
          <li
            onClick={() => history.push(JobCreatePath)}
            className="newJob__listing-item"
          >
            <div className="newJob__listing-div">
              <span className="newJob__listing-icon">
                <AiOutlineContainer />
              </span>
              <div className="newJob__listing-details">
                <h4>Start from Scratch</h4>
                <p>Define your new job and do a fresh start</p>
              </div>
            </div>
          </li>
        </ul>

        {newModalOpen && (
          <ConnectedRenderDataModal
            modalOpen={newModalOpen}
            onModalClose={onNewModalClose}
            history={history}
            type={type}
          />
        )}
      </div>
    </Modal>
  );
};

export const RenderDataModal = ({modalOpen, onModalClose, type, history, ...props}) => {
  const {dashboardDetail, approvedReqLoading, approvedRequisition} = props;
  let loadedData = [];
  if (type === 1) {
    loadedData = approvedRequisition;
  }

  useEffect(() => {
    if (type === 1) {
      props.getApprovedJobRequisition();
    }
  }, [type]);

  if (type === 2) {
    loadedData = dashboardDetail;
  }
  return (
    <Modal
      open={modalOpen}
      onModalClose={onModalClose}
      className="create-new-job-modal"
      title={"Choose a title"}
    >
      <div className="jobtitle">
        {approvedReqLoading ? <h2>Loading...</h2> : null}
        {isEmpty(loadedData) ? null : (
          <ul className="jobtitle-listing">
            {loadedData.map((item) => {
              let id;
              if (type === 1) {
                id = item.RequisitionId;
              } else {
                id = item.Jobid;
              }
              return (
                <li
                  key={item.Jobid}
                  onClick={() =>
                    history.push({
                      pathname: JobCreatePath,
                      data: {jobID: id, isJobId: false, isReq: type === 1}, // your data array of objects
                    })
                  }
                  className="jobtitle-listing__item"
                >
                  <div className="jobtitle-listing__container">
                    <h4>{item.InternalJobTitle}</h4>
                    <p>{item.ExternalJobTitle}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  dashboardDetail: state.recruitment_dashboardReducer.dashboardDetail,
  approvedReqLoading: state.jobRequisitionReducer.approvedReqLoading,
  approvedRequisition: state.jobRequisitionReducer.approvedRequisition,
});

const mapDispatchToProps = {
  GetDashboardDetail,
  getApprovedJobRequisition,
  SetCurrentCandidateDetail,
};

const ConnectedRenderDataModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderDataModal);
