import React, {useState, useEffect} from "react";
import Breadcrumb from "../../common/breadcrumb";
import {Tabs, Tab} from "components/tabs/index.d";
import SearchAndFilter from "./searchAndFilter";
import Table from "./table";
import {getSbiLoanStatusHistory, getSbiLoanType} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";
import isEmpty from "isEmpty";
import { UserByStore } from "constants/userDetails";
import dayjs from "dayjs";
import useDebounce from "helpers/customHooks/useDebounce";
import {useHistory} from "react-router-dom";
import "./styles.scss";

let tabs = [
  {tabId: 0, statusId: 0, label: "All Loans", key: "AllLoans"},
  {tabId: 1, statusId: 1, label: "Pending Loan", key: "PendingLoan"},
  {tabId: 2, statusId: 0, label: "Forwared Loan", key: "ForwaredLoan"},
  {tabId: 3, statusId: 10, label: "Approved Loan", key: "ApprovedLoan"},
  {tabId: 4, statusId: -1, label: "Rejected Loan", key: "RejectedLoan"},
];

const Index = () => {
  const [rowData, setRowData] = useState([]);
  const [userId, setUserId] = useState(0);
  const [loanTypes, setLoanTypes] = useState([]);
  const [state, setState] = useState({
    activeTab: tabs[0],
    pageIndex: 0,
    pageSize: 10,
    employeeId: 0,
    status: 0,
    employeeName: "",
    loanType: 0,
    isSelfForward: false,
    startDate: "",
    endDate: "",
  });

  const handleExcelExport = async () => {
    await getProjectDetails(true);
  };

  const onSearch = async () => {
    await getProjectDetails(false);
  };

  const handleDateRangePicker = (name, date) => {
    const {startDate, endDate} = date;
    setState((prev) => ({
      ...prev,
      ["endDate"]: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
      ["startDate"]: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
    }));
  };

  const dispatch = useDispatch();

  const {sbiLoanStatusHistory} = useSelector((state: any) => state.sbiLoanSettingReducer);
  const {exportSbiLoanStatusHistory} = useSelector(
    (state: any) => state.sbiLoanSettingReducer
  );
  const {sbiLoanTypes} = useSelector((state: any) => state.sbiLoanSettingReducer);

  const handleTabChange = (tab) => {
    setState((prev) => ({
      ...prev,
      ["activeTab"]: tab,
      ["isSelfForward"]: tab.tabId === 2 ? true : false,
      ["status"]: tab.statusId,
      ["pageIndex"]: 0,
    }));
  };

  const handleRowChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageSize"]: num,
    }));
  };
  const handleIndexChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageIndex"]: num,
    }));
  };

  const handleAutoText = (name, selected) => {
    setState((prev) => ({...prev, [name]: selected}));
  };

  const getProjectDetails = async (isExport = false) => {
    const UserByStores: any = UserByStore;

    setUserId(UserByStores()?.EmployeeId ?? 0);
    dispatch(
      getSbiLoanStatusHistory(
        {
          ...state,
          employeeId: UserByStores()?.EmployeeId ?? 0,
          pageIndex: isExport ? 0 : state.pageIndex,
          pageSize: isExport ? rowData[0].TotalRows : state.pageSize,
        },
        state.loanType,
        isExport
      )
    );
  };

  useDebounce(() => getProjectDetails(false), 1000, [state.employeeName]);

  const handleAutoSelect = (name, selected) => {
    setState({...state, [name]: selected ? selected : 0});
  };

  useEffect(() => {
    getSbiLoanStatusHistories();
  }, [
    state.activeTab,
    state.status,
    state.loanType,
    state.startDate,
    state.endDate,
    state.pageIndex,
    state.pageSize,
  ]);

  const getSbiLoanStatusHistories = () => {
    const UserByStores: any = UserByStore;
    setUserId(UserByStores()?.EmployeeId ?? 0);
    if (!isEmpty(UserByStores)) {
      setState({ ...state, employeeId: UserByStores()?.EmployeeId });
      dispatch(
        getSbiLoanStatusHistory(
          { ...state, employeeId: UserByStores()?.EmployeeId },
          state.loanType,
          false
        )
      );
    }
  }

  useEffect(() => {
    (async () => {
      const apis = [dispatch(getSbiLoanType())];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    if (sbiLoanTypes) {
      let datas = sbiLoanTypes?.Data;
      let types = datas?.map((x) => {
        return {value: x.Value, label: x.Text};
      });
      setLoanTypes(types ?? []);
    }
  }, [sbiLoanTypes]);

  useEffect(() => {
    sbiLoanStatusHistory && setRowData(sbiLoanStatusHistory);
  }, [sbiLoanStatusHistory]);
  const history = useHistory();
  return (
    <div className="common-container dashboard">
      <Breadcrumb name="Employee Loan Requests" />
      <header className="dashboard__header">
        <div className="space-between panel-section">
          <h3 className="tertiary-title">Employee Loan Requests</h3>
        </div>
      </header>

      <SearchAndFilter
        data={rowData}
        initialData={state}
        exportSbiLoanStatusHistory={exportSbiLoanStatusHistory}
        handleAutoText={handleAutoText}
        handleDateRangePicker={handleDateRangePicker}
        handleAutoSelect={handleAutoSelect}
        onSearch={onSearch}
        handleExcelExport={handleExcelExport}
        sbiLoanTypes={loanTypes}
      />

      <Tabs noBackground>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              label={tab.label}
              name={tab.key}
              id={tab.tabId}
              isActive={state.activeTab.tabId === tab.tabId}
              onClick={() => handleTabChange(tab)}
            >
              <div className="sbi-loan__tabs">
                <Table
                  name={state.activeTab.label}
                  rowData={rowData}
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  state={state}
                  userId={userId}
                  history={history}
                  getSbiLoanStatusHistories={getSbiLoanStatusHistories}
                />
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Index;
