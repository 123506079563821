import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SBILoanRequestButton from "components/common/commonSBILoanButton";
import styled from 'styled-components'
import { AgGridReact } from "ag-grid-react";
import Button from "components/button";
import { connect } from "react-redux";
import { getUploadPath, UserByStore } from "constants/userDetails";
import Avatar from "react-avatar";
import { MdDelete, MdEdit, MdViewComfy } from "react-icons/md";
import { FaRegFilePdf, FaEye } from "react-icons/fa";
// import { IoEye } from "react-icons/io";
import { AiOutlineStop } from 'react-icons/ai'
import RouteEnum from "constants/routeEnum";
import moment from "moment";
import Pagination from "components/common/pagination";
import Modal from "components/common/modal";
import Breadcrumb from "../../../common/breadcrumb";
import './appliedLoanRequests.scss';
import { getAppliedLoanList, deleteRequestedLoan, getEmployeeInfo } from '../helpers/action';
import Table from 'components/customTable'

interface IAppProps {
  getAppliedLoanList: any;
  myAppliedLoanList: any;
  deleteRequestedLoan: any;
  deletedRequestedLoan: any;
  history: any;
  getEmployeeInfo: any;
  companyInformation: any;
}
interface IAppState {
  columnDefs?: any;
  domLayout?: any;
  defaultColDef?: any;
  frameworkComponents?: any,
  getRowHeight?: any,
  PageIndex: number,
  PageSize: number,
  open: boolean,
  openConfirmation: boolean,
  loanData?: any,
  beforecolumns: any,
}


const ButtonGroup = (props) => {
  return (
    <div className="loan-appliedloan__button">
      {props.row.original.Status == 0 ? <Button
        buttonType="icon-button"
        leftIcon={<MdEdit />}
        onClick={() => props.viewConsumerLoanDetails(props.row.original, 1)}
        roundValue={50}
        bg="secondary-dark"
      /> : <Button
        buttonType="icon-button"
        leftIcon={<FaEye />}
        onClick={() => props.viewConsumerLoanDetails(props.row.original, 2)}
        roundValue={50}
        bg="secondary-dark"
      />
      }
      {props.row.original.Status == 0 && <Button
        buttonType="icon-button"
        leftIcon={<MdDelete />}
        onClick={() => props.setOpen(props.row.original)}
        roundValue={50}
        bg="secondary-dark"
      />}
    </div>
  );
};


const formatNumber = (params) => {
  if (!params) return null
  if (params) {
    params = Number.parseFloat(params).toFixed(2);
    return new Intl.NumberFormat("en-IN").format(params);
  }
  // return (
  //   Math.floor(params)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
  // );
};

const formatDate = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : '-';
};
class MyLoanList extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      PageIndex: 0,
      PageSize: 10,
      openConfirmation: false,
      loanData: {},
      beforecolumns: [
        {
          Header: 'Loan Type',
          accessor: 'LoanTypeName',
          width: 120,
        },
        {
          Header: 'Loan Purpose',
          accessor: 'PurposeOfLoanName',
          width: 180,
          isSort: true
        },
        {
          // Header: () => <div style={{ textAlign: "right" }}>Amount (NPR)</div>,
          Header: "Amount (NPR)",
          accessor: row => <div>{formatNumber(row.LoanAmount) || '-'}</div>, // Access Field
          width: 170,
          minWidth: 80,
          maxWidth: 170,
          headerClassName: 'text-right',
          // isSort: true,
        },
        {
          Header: 'Requested on',
          accessor: row => formatDate(row.RequestedDateEng), // Access Field
          width: 160,
          minWidth: 100,
          maxWidth: 200,
          isSort: true
        },
        {
          Header: 'Status',
          accessor: 'StatusName', // Access Field
          width: 120,
          minWidth: 100,
          maxWidth: 500,
          isSort: true,
          // formatNumber
        },
        {
          Header: '',
          accessor: 'StatusNames', // Access Field
          width: 120,
          minWidth: 100,
          maxWidth: 500,
          Cell: props => 
          <ButtonGroup {...props} setOpen={this.setOpen} viewConsumerLoanDetails={this.viewConsumerLoanDetails} />
        },
      ],
    }
  }

  viewConsumerLoanDetails = async (Loan, pageId) => {
    let PageName = pageId === 1 ? (Loan.LoanType == '1' ? RouteEnum.UpdateConsumerLoanRequest : (Loan.LoanType == '2' ? RouteEnum.UpdateSocialLoanRequest : RouteEnum.UpdatePersonalLoanRequest)) : RouteEnum.ViewSBILoanDetail;
    const User: any = UserByStore();

    // if (pageId !== 1 && User) {
    //   await this.props.getEmployeeInfo(User.EmployeeId);
    // }
    this.props.history.push({
      pathname: PageName + '/' + Loan.RequestId,
      // pathname: `${RouteEnum.ViewHomeLoanPath}/${Loan.LoanId}`,
      state: {
        data: Loan.LoanType,
        LoanId: Loan.RequestId,
        LoanType: Loan.LoanType,
        EmployeeId: Loan.EmployeeId,
        FlowType: Loan.FlowType,
        Status: Loan.Status,
        CurrentStep: Loan.Status,
        ProcessFlowStepId: Loan.FlowType,
        IsSelfForward: true,
      },
    })
  };

  setOpen = async (loan) => {
    this.setState({
      openConfirmation: true,
      loanData: loan,
    });
  };

  deleteLoanRequest = async () => {
    const { loanData } = this.state;
    await this.props.deleteRequestedLoan(loanData.RequestId, loanData.LoanType);
    const { deletedRequestedLoan } = this.props;
    if (deletedRequestedLoan) {
      this.getAppliedLoanList();
      this.setState({ openConfirmation: false, loanData: null });
    }
  }

  async componentDidMount() {
    this.getAppliedLoanList();
  }

  getAppliedLoanList = async () => {
    const UserByStores: any = UserByStore;

    let {
      PageIndex,
      PageSize,
    } = this.state;
    let searchDetail = {
      EmployeeId: UserByStores()?.EmployeeId,
      Status1: '1',
      Status2: "2",
      PageIndex,
      PageSize,
      LoanType: "",
      EmployeeName: "",
      IsSelfForward: false,
    };
    const { getAppliedLoanList } = this.props;
    await getAppliedLoanList(searchDetail);
  }

  handleRowChange = (num) => {
    this.setState({ PageSize: num }, async () => {
      await this.getAppliedLoanList();
    });
  };

  handleIndexChange = (num) => {
    this.setState({ PageIndex: num }, async () => {
      await this.getAppliedLoanList();
    });
  };

  handleTabChange = (tab) => {
    this.setState({ PageIndex: 0 }, async () => {
      await this.getAppliedLoanList();
    });
  };


  render() {
    const {
      myAppliedLoanList,
      companyInformation,
    } = this.props;
    const { openConfirmation, beforecolumns } = this.state;
    const { PageSize, PageIndex } = this.state;
    return (
      <div className="loan-appliedloan">
        <div className="tasktracker-title-section flex justify-between">
          <Breadcrumb name="Loan Request" />
          {(companyInformation?.CompanyId == 105 ||
            companyInformation.CompanyId == 0) && <SBILoanRequestButton buttonTitle="New Loan" isAssign={false} />}
        </div>
        <div className="loan-name mb-md mt-md">
          My Loan Requests
        </div>
        <Modal
          type="confirm"
          func={() => this.deleteLoanRequest()}
          open={openConfirmation}
          onModalClose={() => this.setState({ openConfirmation: false, loanData: null })}
        />
        <div
          id="myGrid"
          style={{
            height: "100%",
            width: "100%",
          }}
          className="ag-theme-alpine"
        >
          {myAppliedLoanList && <Table
            // isRowSelect  // Add Checkbox iinside table for selecting multiple rows
            // onRowSelect={onSave} // Return Selected Rows after change in row selection ,
            // onRowClick={onSave}  // on Row Click 
            isFixedWidth
            // isEditable // Enable inline Edit
            // onSaveData={onSave} // After change in inline edited data
            columns={beforecolumns} // Columns Settings
            // updateMyData={updateMyData} // Update Row data during onchange inside inline data
            data={myAppliedLoanList} // Data list
          />}
        </div>
        {myAppliedLoanList &&
          myAppliedLoanList?.RecordsTotal > 0 && (
            <Pagination
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
              pageIndex={PageIndex}
              rowsPerPage={PageSize}
              total={
                myAppliedLoanList &&
                myAppliedLoanList.RecordsTotal
              }
            />
          )}
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  loanApproverList: state.sbiLoanReducer.loanApproverList,
  myAppliedLoanList: state.sbiLoanReducer.myAppliedLoanList,
  deletedRequestedLoan: state.sbiLoanReducer.deletedRequestedLoan,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  getAppliedLoanList,
  deleteRequestedLoan,
  getEmployeeInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLoanList);
