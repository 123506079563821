export const initialHeader = [
  {
    field: "EmployeeName",
    headerName: "Name",
  },
  {
    field: "Allowance",
    headerName: "Allowance",
  },
  {field: "Date", headerName: "Date"},
  {field: "Unit", headerName: "Unit"},
  {
    field: "Reason",
    headerName: "Reason",
  },
  {field: "Status", headerName: "Status"},
];
