import * as React from "react";
import changeMinToHourMinTimeFormat from "helpers/changeMinToHourMinTimeFormat";

interface IOverTimeRequestTableProps {
  overTimeRequest: any[];
}

const OverTimeRequestTable: React.FunctionComponent<IOverTimeRequestTableProps> = (
  props
) => {
  const {overTimeRequest} = props;

  const renderDuration = (ot: any) => {
    if (ot.RequestedMin) {
      let duration = "";
      let diff = parseInt(ot.RequestedMin);
      duration = changeMinToHourMinTimeFormat(diff);
      return duration;
    }
  };

  return (
    <div className="overtime-table flex-column">
      <div className="overtime-table__header">
        <span>Date</span>
        <span>Device in</span>
        <span>Device out</span>
        <span>Start time</span>
        <span>End time</span>
        <span>Duration</span>
      </div>
      <div className="overtime-table__body flex-column">
        {overTimeRequest?.map((x) => {
          return (
            <div className="overtime-table__body-row">
              <span>{x.RequestDate || "-"}</span>
              <span>{x.InTimeDv || "-"}</span>
              <span>{x.OutTimeDv || "-"}</span>
              <span>{x.StartTime || "-"}</span>
              <span>{x.EndTime || "-"}</span>
              <span className="minutes">{renderDuration(x) || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverTimeRequestTable;
