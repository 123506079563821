import React from "react";
import classnames from "classnames";
import Avatar from "react-avatar";
import {FaEdit} from "react-icons/fa";
import {connect} from "react-redux";
import isEmpty from "isEmpty";

function AddressDetail(props) {
  const {
    PECountyName,
    PELocality,
    PEDistrict,
    PEZone,
    PEHouseNo,
    PEStreet,
    PEState,
    PEZipCode,
    PEWardNo,
    PEVDCMuncipality,
    PSLocality,
    PSDistrict,
    PSZone,
    PSCountryName,
    PSVDCMuncipality,
    PSWardNo,
    PSHouseNo,
    PSStreet,
    PSState,
  } = props.employeeAddress || {};
  // console.log(props.employeeAddress);
  return (
    <div className="profile-row">
      <div className="profile-column">
        <div className="profile-address">
          <div className="profile-block__title">
            <h2>ADDRESS</h2>
          </div>
          {!isEmpty(props.employeeAddress) ? (
            <div className="profile-address__body">
              <div className="profile-address__block address-contain">
                <div className="profile-address-label">
                  <h2>Permanent Address</h2>
                </div>
                {/* <div className="profile-address__div">
                                        <div className="value value2">
                                            Jharana Basanti -3
                                        </div>
                                    </div> */}
                <div className="profile-address__div">
                  <span>
                    {PEVDCMuncipality}
                    {PEVDCMuncipality && PEWardNo ? " - " : ""} {PEWardNo}
                  </span>
                </div>
                <div className="profile-address__div">
                  <span>
                    {PEDistrict}
                    {PEDistrict && PEZone ? ", " : ""}
                    {PEZone}
                  </span>
                </div>
                <div className="profile-address__div">
                  <span>{PECountyName && PECountyName}</span>
                </div>
              </div>
              <div className="profile-address__block address-contain">
                <div className="profile-address-label">
                  <h2>Current Address</h2>
                </div>
                {/* <div className="profile-address__div">
                                        <div className="value value2">
                                            Jharana Basanti -3
                                        </div>
                                    </div> */}
                <div className="profile-address__div">
                  <span>
                    {PSVDCMuncipality}
                    {PSVDCMuncipality && PSWardNo ? " - " : ""} {PSWardNo}
                  </span>
                </div>
                <div className="profile-address__div">
                  <span>
                    {PSDistrict}
                    {PSDistrict && PSZone ? ", " : ""}
                    {PSZone}
                  </span>
                </div>
                <div className="profile-address__div">
                  <span>{PSCountryName && PSCountryName}</span>
                </div>
              </div>
              {/* <div className="profile-address__block address-contain  address-map">
                                    <div className="map-view">
                                        <img src="https://picsum.photos/seed/picsum/200/200" alt=""/>
    
                                    </div>
                                    <div className="">
                                        <button className="button button-white">
                                            View Map
                                        </button>
                                    </div>
                                </div> */}
            </div>
          ) : (
            <div className="profile-address__block">
              <div className="profile-block__title nodata">
                <h2>No Addresses Found</h2>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employeeAddress:
    (state.employeeProfile.employeeProfile &&
      state.employeeProfile.employeeProfile.EmployeeAddress) ||
    {},
});

export default connect(mapStateToProps, null)(AddressDetail);
