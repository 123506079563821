import React from "react";
import isEmpty from "isEmpty";
import Todo from "./todo";
import MyUpdateView from "./myUpdateView";

function MyPage({RequestCount, MyUpdate, dashboardLoding}) {
  var isAllRequestCountZero;
  var isAllUpdateFieldEmpty;
  if (RequestCount) {
    isAllRequestCountZero = RequestCount.every(function (key) {
      return key["Total"] === 0;
    });
  }
  if (MyUpdate) {
    isAllUpdateFieldEmpty = Object.values(MyUpdate).every((x) => isEmpty(x));
  }
  const todoProps = {dashboardLoding, isAllRequestCountZero, RequestCount};
  const myUpdateProps = {dashboardLoding, isAllUpdateFieldEmpty, MyUpdate};
  return (
    <div className="mypage">
      <Todo {...todoProps} />
      <MyUpdateView {...myUpdateProps} />
    </div>
  );
}

export default MyPage;
