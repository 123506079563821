import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {GetEmployeeList} from "actions/commonAction";
import Loader from "components/loaders";
import Upload from "components/common/upload";
import FormGroup from "components/form";
import Forms from "components/form";
import {ValidationComponent} from "helpers/getComponents";
import isEmpty from "isEmpty";
import userFormValidation from "validations/useFormValidation";
import validateDisciplinaryCase from "validations/validateDisciplinaryCase";
import EmployeeDetails from "../../../common/employeeDetails";
import {RegisterDisciplinaryCase, EditDisciplinaryCase} from "../action";
import "./disciplinaryCase.scss";
import {getDisciplinaryCaseData} from "./getSaveData";
import Button from "components/button";
import EmployeeSearch from "components/common/employeeSearch";
import {EmployeeNameImageCard} from "components/common/employeeNameImageCard";

class DisciplinaryCaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: {},
      isAnimate: true,
      employeeList: [],
      employeeListWithPhoto: [],
    };
  }
  async componentDidMount() {
    this.setState({isAnimate: false});
    const {GetEmployeeList} = this.props;
    let ajaxApi = [GetEmployeeList];
    Promise.all(
      ajaxApi.map(async (item) => {
        return await item();
      })
    );
  }
  componentDidUpdate(prevProps, prevState) {
    let {employeeList} = this.props;
    if (prevProps.employeeList !== employeeList) {
      this.setState({employeeList});
    }
  }

  onClose() {
    this.props.handleFormDisplay(false);
  }
  render() {
    const {employeeList} = this.state;
    let {spinnerLoading, disciplinaryCaseDetails} = this.props;
    let isEditPage = this.props.isEditPage;
    let isFormReadytoLoad =
      isEditPage &&
      !isEmpty(employeeList) &&
      (!disciplinaryCaseDetails.loading || !isEmpty(disciplinaryCaseDetails.value));
    return (
      <>
        <Loader open={spinnerLoading} />
        {isFormReadytoLoad || !isEditPage ? (
          <Form
            onClose={this.onClose.bind(this)}
            props={this.props}
            setAnimate={() => this.setAnimate()}
            state={this.state}
          />
        ) : null}
      </>
    );
  }
}

const Form = ({props, onClose, state}) => {
  const [reportedBy, setReportedBy] = useState([]);
  let {employeeList} = state;
  let {disciplinaryCaseDetails} = props;
  let dspCaseDetails = disciplinaryCaseDetails?.value;
  // console.log({dspCaseDetails});
  let isEditPage = props.isEditPage;
  let INITIAL_STATE;
  if (isEditPage) {
    INITIAL_STATE = {
      DisciplinaryCaseId: dspCaseDetails?.DisciplinaryCaseId,
      Employee: employeeList.find((x) => x.value === dspCaseDetails?.EmployeeRefId),
      CaseTitle: dspCaseDetails?.CaseTitle,
      CaseStatement: dspCaseDetails?.CaseStatement,
      CaseDate: dspCaseDetails?.ReportedDateEng,
      ReportedBy: isEmpty(dspCaseDetails?.ReportedBy)
        ? []
        : dspCaseDetails?.ReportedBy.split(",").map((x) => {
            return employeeList.find((item) => item.value == x);
          }),
      Notify: isEmpty(dspCaseDetails?.NotifyTo)
        ? []
        : dspCaseDetails?.NotifyTo.split(",").map((x) => {
            return employeeList.find((item) => item.value == x);
          }),
      FileList: dspCaseDetails?.DisciplinaryCaseDocuments.map((x) => {
        return {
          UserFileName: x.UserFileName,
          // FileLocation: x.FileLocation,
          DocumentPath: x.FileLocation,
          // DocumentName: x.DocumentName,
          DocumentName: x.ServerFileName,
          AttachmentId: x.AttachmentId,
          Id: x.AttachmentId,
          Size: x.Size,
        };
      }),
    };
  } else {
    INITIAL_STATE = {
      DisciplinaryCaseId: 0,
      Employee: {},
      CaseTitle: "",
      CaseStatement: "",
      CaseDate: "",
      ReportedBy: [],
      Notify: [],
      FileList: [],
    };
  }
  const {handleSubmit, handleChange, values, errors, isSubmitting, setValues} =
    props.showForm
      ? userFormValidation(INITIAL_STATE, validateDisciplinaryCase)
      : {values: INITIAL_STATE, errors: {}};

  // console.log(errors, isSubmitting, props.parent);
  // console.log({values});

  if (isSubmitting && isEmpty(errors)) {
    const data = getDisciplinaryCaseData(values);
    if (isEditPage) {
      props.EditDisciplinaryCase(data, props.getDisciplinaryActionList, onClose);
    } else props.RegisterDisciplinaryCase(data, props.getDisciplinaryActionList, onClose);
  }
  const onSelectChange = (name, value) => {
    handleChange(name, value);
  };
  const onChange = (name, value) => {
    handleChange(name, value);
  };
  const onDateChange = (name, date) => {
    handleChange("CaseDate", date);
  };

  const setSelectedFiles = (fileList) => {
    handleChange("FileList", fileList);
  };
  const isViewMode = false;
  const empDetails =
    !isEmpty(employeeList) &&
    !isEmpty(values.Employee) &&
    employeeList.find((x) => x.employeeId === values.Employee.value);

  const handleRemove = (name, value) => {
    if (values && values[name] && values[name].length > 0) {
      let newArrItem = values[name].filter((item) => item?.value !== value);
      setValues({
        ...values,
        [name]: newArrItem,
      });
    }
  };

  return (
    <form className="disciplinary-case" onSubmit={handleSubmit}>
      <div className="dsp-form__header">
        <span>New Disciplinary Case</span>
      </div>
      <div className="dsp-form__body">
        <div className="form-row">
          <ValidationComponent
            error={errors.Employee}
            errorClassName="error__message"
            rootClassName="form-group"
          >
            <EmployeeSearch
              disabled={isViewMode}
              label="Select the Employee"
              name="Employee"
              onChange={onSelectChange}
              placeholder="Type and Select"
              value={values.Employee}
            />
          </ValidationComponent>
          {!isEmpty(values.Employee) && !isEmpty(empDetails) ? (
            <EmployeeDetails empDetails={empDetails} />
          ) : null}
        </div>

        <div className="form-row">
          <FormGroup
            disabled={isViewMode}
            error={errors.CaseTitle}
            label="Case title"
            name="CaseTitle"
            onChange={handleChange}
            type="text"
            value={values.CaseTitle}
          />
        </div>
        <div className="form-row">
          <FormGroup
            cols={100}
            disabled={isViewMode}
            error={errors.CaseStatement}
            formName="textarea"
            label="Case Statement"
            name="CaseStatement"
            onChange={handleChange}
            rows={6}
            value={values.CaseStatement}
          />
        </div>
        <div className="form-row">
          <ValidationComponent
            error={errors.CaseDate}
            errorClassName="error__message"
            rootClassName="form-group"
          >
            <label htmlFor="CaseStatement" className="form-group__label">
              When did this happen
            </label>
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="pickers">
                <KeyboardDatePicker
                  disabled={isViewMode}
                  format="MMMM D, YYYY"
                  name="CaseDate"
                  onChange={onDateChange}
                  variant="inline"
                  value={values.CaseDate}
                  width={80}
                />
              </div>
            </MuiPickersUtilsProvider> */}
            <Forms
              enableOkButton
              formName="customdateinput"
              disabled={isViewMode}
              name="CaseDate"
              onChange={onDateChange}
              value={values.CaseDate}
              placeholder="Select Date"
              width="200px"
              isNepali={false}
              isTextInNepali={false}
              validators={["required"]}
              errors={errors?.CaseDate}
            />
          </ValidationComponent>
        </div>
        <div className="form-row">
          <ValidationComponent
            error={errors.ReportedBy}
            errorClassName="error__message"
            rootClassName="form-group"
          >
            <EmployeeSearch
              disabled={isViewMode}
              isMultiple={true}
              label="Reported by"
              name="ReportedBy"
              onChange={onSelectChange}
              value={values.ReportedBy}
              width="300px"
            />
          </ValidationComponent>
          <ValidationComponent
            error={errors.FileList}
            errorClassName="error__message"
            rootClassName="form-group"
          >
            <Upload
              fileList={values.FileList}
              isViewMode={isViewMode}
              noSelectedText="No document selected"
              setSelectedFiles={setSelectedFiles}
              uploadBtnText="Attach Document"
            />
          </ValidationComponent>
        </div>
        <div className="form-row flex-wrap flex-gap">
          {values &&
            values.ReportedBy &&
            values?.ReportedBy.length > 0 &&
            values.ReportedBy.map((report) => {
              return (
                report && (
                  <EmployeeNameImageCard
                    designation={report?.designation}
                    handleRemove={() => handleRemove("ReportedBy", report?.value)}
                    image={report?.img}
                    name={report?.label}
                    showRemoveIcon={true}
                    width="48%"
                  />
                )
              );
            })}
        </div>
        <div className="form-row">
          <ValidationComponent
            error={errors.Notify}
            errorClassName="error__message"
            rootClassName="form-group"
          >
            <EmployeeSearch
              disabled={isViewMode}
              isMultiple={true}
              label="Notify"
              name="Notify"
              onChange={onSelectChange}
              value={values.Notify}
              width="300px"
            />
          </ValidationComponent>
        </div>
        <div className="form-row flex-wrap flex-gap">
          {values &&
            values.Notify &&
            values?.Notify.length > 0 &&
            values.Notify.map((notify) => {
              return (
                notify && (
                  <EmployeeNameImageCard
                    designation={notify?.designation}
                    handleRemove={() => handleRemove("Notify", notify?.value)}
                    image={notify?.img}
                    name={notify?.label}
                    showRemoveIcon={true}
                    width="48%"
                  />
                )
              );
            })}
        </div>
      </div>
      <div className="dsp-form__footer flex-gap-10">
        <Button
          bg="secondary"
          isDisabled={isSubmitting}
          onClick={() => onClose()}
          title="Cancel"
        />
        <Button
          bg="primary"
          isDisabled={isSubmitting}
          title={isSubmitting ? "Please wait" : "Register the case"}
          htmlButtonType="submit"
        />
      </div>
    </form>
  );
};

DisciplinaryCaseForm = connect(
  (state) => ({
    employeeList: state.commonReducer.employeeListSelect,
    spinnerLoading: state.disciplinaryActionReducer.spinnerLoading,
    disciplinaryCaseDetails: state.disciplinaryActionReducer.disciplinaryCaseDetails,
  }),
  {
    GetEmployeeList,
    EditDisciplinaryCase,
    RegisterDisciplinaryCase,
  }
)(DisciplinaryCaseForm);

export default DisciplinaryCaseForm;
