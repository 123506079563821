import {
  GET_PENDING_SETTLEMENT,
  GET_TRAVEL_REQUEST_DETAILS,
  GET_TRAVEL_CATEGORY_LIST,
  GET_OTHER_EXPENSES_LIST,
  GET_BILLS_AND_CLAIMS_LIST,
  SET_SPINNER_LOADING,
  CANCEL_TRAVEL_REQUEST,
  SETTLEMENT_LOADING,
  HANDLE_SETTLEMENT_ACTIVE,
  GET_SETTLEMENT_REQUESTS,
  GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT,
  GET_SETTLEMENT_APPROVAL_AUTHORITIES,
  GET_SETTLEMENT_APPROVAL_HISTORY,
  SETTLEMENT_APPROVAL_AUTHORITIES_LOADING,
  SETTLEMENT_APPROVAL_HISTORY_LOADING,
  HANDLE_SETTLEMENT_REPORT_ACTIVE,
  SETTLEMENT_AUDIT_LOG_LOADING,
  GET_SETTLEMENT_AUDIT_LOG,
  SETTLEMENT_REPORT_LOADING,
  GET_SETTLEMENT_REPORT,
  GET_SETTLEMENT_REQUEST_REPORT_PDF,
  SETTLEMENT_REQUEST_LOADING,
  PENDING_SETTLEMENT_LOADING,
  TRAVEL_REQUEST_DETAILS_LOADING,
  HANDLE_SETTLEMENT_SAVE,
  REMOVE_OTHER_EXPENSE,
} from "actions/types";

const initialState = {
  pendingSettlement: {loading: false, value: []},
  travelRequestDetails: {loading: false, value: []},
  otherExpenses: [],
  billsAndClaims: [],
  loading: true,
  spinnerLoading: false,
  pendingSettlementSearchedPage: {index: 0, total: 0},
  settlementRequestSearchedPage: {index: 0, total: 0},
  cancelledList: [],
  isSettlementActive: false,
  settlementRequest: {loading: false, value: []},
  settlementRequestExportExcel: null,
  approvalAuthorities: {loading: false, value: []},
  approvalHistory: {loading: false, value: []},
  isSettlementReportActive: false,
  settlementAuditLog: {loading: false, value: []},
  settlementReport: {loading: false, value: []},
  settlementReportPdf: null,
  pendingSettlementInitialLoad: false,
  settlementRequestInitialLoad: false,
  isSettlementSaveSuccess: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HANDLE_SETTLEMENT_ACTIVE:
      return {
        ...state,
        isSettlementActive: action.payload,
      };
    case HANDLE_SETTLEMENT_REPORT_ACTIVE:
      return {
        ...state,
        isSettlementReportActive: action.payload,
      };
    case PENDING_SETTLEMENT_LOADING:
      return {
        ...state,
        pendingSettlement: {loading: true, value: []},
      };
    case GET_PENDING_SETTLEMENT:
      return {
        ...state,
        pendingSettlement: {loading: false, value: action.payload},
        pendingSettlementSearchedPage: {
          index: action.page.index,
          total: action.page.total,
        },
        //loading: false,
        pendingSettlementInitialLoad: true,
      };
    case TRAVEL_REQUEST_DETAILS_LOADING:
      return {
        ...state,
        travelRequestDetails: {loading: true, value: []},
      };
    case GET_TRAVEL_REQUEST_DETAILS:
      return {
        ...state,
        travelRequestDetails: {loading: false, value: action.payload},
        spinnerLoading: false,
        loading: false,
        isSettlementActive: true,
      };
    case GET_OTHER_EXPENSES_LIST:
      // debugger
      return {
        ...state,
        otherExpenses: action.payload,
      };
    case GET_BILLS_AND_CLAIMS_LIST:
      return {
        ...state,
        billsAndClaims: action.payload,
      };
    case SET_SPINNER_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload,
      };
    case CANCEL_TRAVEL_REQUEST:
      return {
        ...state,
        cancelledList: [...state.cancelledList, action.payload],
        spinnerLoading: false,
      };
    case SETTLEMENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SETTLEMENT_REQUEST_LOADING:
      return {
        ...state,
        settlementRequest: {loading: true, value: []},
      };
    case GET_SETTLEMENT_REQUESTS:
      return {
        ...state,
        settlementRequest: {loading: false, value: action.payload},
        settlementRequestSearchedPage: {
          index: action.page.index,
          total: action.page.total,
        },
        //loading: false,
        settlementRequestInitialLoad: true,
      };

    case GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT:
      return {
        ...state,
        settlementRequestExportExcel: action.payload,
      };

    case SETTLEMENT_APPROVAL_AUTHORITIES_LOADING: {
      return {
        ...state,
        approvalAuthorities: {loading: true, value: []},
      };
    }
    case SETTLEMENT_APPROVAL_HISTORY_LOADING: {
      return {
        ...state,
        approvalHistory: {loading: true, value: []},
      };
    }
    case GET_SETTLEMENT_APPROVAL_HISTORY: {
      return {
        ...state,
        approvalHistory: {loading: false, value: action.payload},
      };
    }
    case GET_SETTLEMENT_APPROVAL_AUTHORITIES: {
      return {
        ...state,
        approvalAuthorities: {loading: false, value: action.payload},
      };
    }
    case SETTLEMENT_AUDIT_LOG_LOADING: {
      return {
        ...state,
        settlementAuditLog: {loading: true, value: []},
      };
    }
    case GET_SETTLEMENT_AUDIT_LOG: {
      return {
        ...state,
        settlementAuditLog: {loading: false, value: action.payload},
      };
    }
    case SETTLEMENT_REPORT_LOADING: {
      return {
        ...state,
        settlementReport: {loading: true, value: []},
        loading: true,
      };
    }
    case GET_SETTLEMENT_REPORT: {
      return {
        ...state,
        settlementReport: {loading: false, value: action.payload},
        isSettlementReportActive: true,
        spinnerLoading: false,
        loading: false,
      };
    }
    case GET_SETTLEMENT_REQUEST_REPORT_PDF: {
      return {
        ...state,
        settlementReportPdf: action.payload,
      };
    }
    case HANDLE_SETTLEMENT_SAVE: {
      return {
        ...state,
        isSettlementSaveSuccess: action.payload,
      };
    }
    case REMOVE_OTHER_EXPENSE: {
      return {
        ...state,
        otherExpenses: state.otherExpenses.filter((x) => x.Id !== action.payload),
      };
    }
    default:
      return state;
  }
}
