import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CustomPopup from "components/common/customPopup";
import DatePicker from "components/datePicker";
import FormGroup from "components/form";
import { taskUpdateDetails } from "../../../../../../helpers/action";
import TextInput from "../../addLabelModal/textInput";
import { TmViewType } from "scenes/tasks/helpers/enum";

const CustomFieldCell = (props) => {
  const { task, column, isValidUser, stage, rowIndex ,board} = props;
  const { field } = column;
  const [val, setVal] = useState(task[field]);
  useEffect(() => {
    task[field] && setVal(task[field])
  }, [task])


  const [showTextInput, setShowTextInput] = useState(false);
  const handleInputChange = (name, value) => {
    setVal(value);
  };
  const handleSubmit = async (name, value) => {
    const updatedTask = {
      ...task,
    };
    setShowTextInput(false);
    updatedTask[name] = value;
    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updatedTask,
      stage,
      rowIndex,
    });
  };
  return (
    <div className="cell-custom-field" onClick={() => setShowTextInput(true)}>
      {showTextInput && !board.IsArchived  ? (
        <TextInput
          value={val}
          autoFocus
          name={field}
          handleChange={handleInputChange}
          handleSubmit={handleSubmit}
          onBlur={(e) => setShowTextInput(false)}
        />
      ) : (
        <div className="cell-custom-field-text"> {val}</div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { taskUpdateDetails };
export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldCell);
