import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import QuillEditor from "../quillEditor";
import {
  saveComment,
  getCommentsByTaskId,
} from "../../../../../../helpers/action";

const CommentEditor = (props) => {
  const {
    mentions,
    taskId,
    commentId,
    placeHolder,
    onCancelClicked,
    onSucessSubmitting,
    enableCancelButton,
    initialValue,
    attachments
  } = props;
  const [comment, setComment] = useState("");
  const [isUploading, setUploading] = useState(false)
  const [selectedMentions, setSelectedMentions] = useState([]);
  const [files, setFiles] = useState([])

  useEffect(() => {
    props.value && setComment(props.value);
  }, [props.value]);

  useEffect(() => {
    attachments && setFiles(attachments)
  }, attachments)

  const handleSubmit = async (val) => {

    let param = {
      CommentId: commentId || 0,
      taskId: taskId,
      comment,
      mentions: selectedMentions.map((x) => x.id),
      Attachment: files
    };

    (await props.saveComment) && props.saveComment(param, true);
    setComment("");
    setFiles([])
    setSelectedMentions([]);
    onSucessSubmitting && onSucessSubmitting(val);
  };

  const convertToEditorMembers = (items) => {
    return (
      (items &&
        items.map((x) => ({ ...x, id: x.EmployeeId, value: x.FullName }))) ||
      []
    );
  };

  return (
    <QuillEditor
      enableCancelButton={enableCancelButton}
      // alwaysShow={alwaysShow}
      onCancelClicked={() => {
        setComment("");
        onCancelClicked && onCancelClicked();
      }}
      files={files}
      setFiles={setFiles}
      value={comment}
      initialValue={initialValue}
      placeHolder={placeHolder}
      mentions={convertToEditorMembers(mentions)}
      onChange={(name, value) => {
        setComment(value);
      }}
      onSelectedMentions={(mentions) => {
        setSelectedMentions(mentions);
      }}
      handleSubmit={handleSubmit}
      onEnterPressed={handleSubmit}
      isUploading={isUploading}
      setUploading={setUploading}
    />
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { saveComment };
export default connect(mapStateToProps, mapDispatchToProps)(CommentEditor);
