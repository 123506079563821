import isEmpty from "isEmpty";

const getFormattedNumber = (num) => {
  let number = parseInt(num);
  return number.toLocaleString(navigator.language, {
    minimumFractionDigits: 2,
  });
};

export const getCommaFormattedNumber = (num, fractionDigit = 0) => {
  if (isEmpty(num)) {
    return num;
  } else {
    let number = parseFloat(num);
    let option = {
      minimumFractionDigits: fractionDigit,
      maximumFractionDigits: 2,
    };

    return number.toLocaleString(navigator.language, option);
  }
};

export const getRoundedNumber = (num) => {
  if (isEmpty(num)) {
    return num;
  } else {
    return Math.round(num / 100) * 100;
  }
};

export const getCeiledNumber = (num) => {
  if (isEmpty(num)) {
    return num;
  } else {
    return Math.ceil(num / 100) * 100;
  }
};

export const getFlooredNumber = (num) => {
  if (isEmpty(num)) {
    return num;
  } else {
    return Math.floor(num / 100) * 100;
  }
};

export const getTruncatedNumber = (num, index = 2) => {
  return +num.toString().slice(0, num.toString().indexOf(".") + (index + 1));
};

export default getFormattedNumber;
