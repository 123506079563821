import React from "react";
import FormGroup from "components/form";
import Button from "components/button";
import Modal from "components/modal";
import {connect} from "react-redux";
import IncomeHeadOptions from "./incomeHeadOptions";
import isEmpty from "isEmpty";
import {InputValidator} from "helpers/inputValidator";
import AnimatedForm from "components/common/animatedForm";
import {
  DisplayClientSideMessage,
  getLevels,
  GetPayrollIncomeHeads,
} from "actions/commonAction";
import {
  GetDistributedSalary,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate,
} from "../salaryTemplateAction";
import "../style.scss";
import {getRoundedNumber} from "helpers/getFormattedNumber";
import {ParseFloat} from "helpers/parseFloat";

const initialState = {
  date: "",
  open: false,
  templateName: null,
  employeeLevel: null,
  templateOptions: [
    {
      label: "1",
      value: "1001",
    },
    {
      label: "2",
      value: "2001",
    },
    {
      label: "3",
      value: "3001",
    },
    {
      label: "4",
      value: "4001",
    },
  ],
  templateOption: [
    {
      label: "",
      value: "",
    },
  ],
  isApplicableIncome: false,
  salaryDistribution: [],
  grossPay: null,
  totalPay: null,
  gross: true,
  basic: false,
  levelLists: null,
  incomeHeadOpen: false,
  payrollIncomeHeads: [],
  openNewTemplate: false,
  templateLists: null,
  errors: null,
  editView: false,
  templateId: 0,
  totalPercentage: 0,
  distributionChanged: false,
};
interface IAppProps {
  employeeId?: any;
  employeeFamiliesDetail?: any;
  getLevels: any;
  GetPayrollIncomeHeads: any;
  levelList: any;
  payrollIncomeHeads: any;
  GetDistributedSalary: any;
  DisplayClientSideMessage: any;
  distributedIncomeByAmount: any;
  AddSalaryTemplate: any;
  saveSalaryTemplate: any;
  GetAllSalaryTemplate: any;
  salaryTemplateList: any;
  GetSalaryTemplateDetails: any;
  salaryTemplateDetail: any;
  UpdateSalaryTemplate: any;
  DeleteSalaryTemplate: any;
  handleFormDisplay: any;
  showForm: any;
  closeForm: any;
  editViewData?: any;
  editView: any;
  editedPayrollIncomeHeads: any;
  requesting: boolean;
}

interface State {
  date: any;
  open: any;
  templateName: any;
  employeeLevel: any;
  templateOptions: any;
  templateOption: any;
  isApplicableIncome: any;
  salaryDistribution: any;
  grossPay: any;
  totalPay: any;
  gross: any;
  basic: any;
  levelLists?: any;
  incomeHeadOpen?: any;
  payrollIncomeHeads: any;
  openNewTemplate: boolean;
  templateLists: any;
  errors?: any;
  editView?: boolean;
  templateId?: any;
  totalPercentage: any;
  distributionChanged: any;
}

class SalaryTemplateForm extends React.Component<IAppProps, State> {
  state = initialState;

  currencyCommaSeperator = (num) => {
    if (isNaN(num)) return "0";
    return parseFloat(num)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  componentDidMount = async () => {
    const {
      editView,
      editViewData,
      getLevels,
      GetPayrollIncomeHeads,
      GetAllSalaryTemplate,
    } = this.props;
    await getLevels();
    if (!editView) {
      await GetPayrollIncomeHeads();
    }
    // await GetAllSalaryTemplate();
    let {levelList, editedPayrollIncomeHeads, payrollIncomeHeads, salaryTemplateList} =
      this.props;

    let levelLists = levelList.map((x) => {
      return {value: x.Value, label: x.Text};
    });
    if (!editView && payrollIncomeHeads) {
      payrollIncomeHeads = payrollIncomeHeads.map((p) => {
        return {...p, checked: false};
      });
    }
    if (editView) {
      this.setState({
        ...editViewData,
        levelLists,
        payrollIncomeHeads: editedPayrollIncomeHeads,
        editView: true,
      });
    } else this.setState({levelLists, payrollIncomeHeads});
  };
  componentDidUpdate(prevProps, prevState) {
    let {levelList, payrollIncomeHeads, salaryTemplateList} = this.props;
    if (levelList && levelList !== prevProps.levelList) {
      let levelLists = levelList.map((x) => {
        return {value: x.Value, label: x.Text};
      });
      this.setState({levelLists});
    }
    if (payrollIncomeHeads && payrollIncomeHeads !== prevProps.payrollIncomeHeads) {
      payrollIncomeHeads = payrollIncomeHeads.map((p) => {
        return {...p, checked: false};
      });
      this.setState({payrollIncomeHeads});
    }
    // if (prevState.salaryDistribution !== this.state.salaryDistribution) {
    //   this.state.salaryDistribution.length>0 && this.state.salaryDistribution.map((salary) => {
    //     if (salary.isBasicIncome) {

    //     }
    //   });
    // }
  }

  handleFieldChange = (name, value) => {
    if (name == "gross" || name == "basic") {
      let alterField = name == "gross" ? "basic" : "gross";
      let {salaryDistribution} = this.state;
      let selectedSalary = salaryDistribution.find((s) => s.isBasicIncome == true);
      if (name == "basic") {
        if (selectedSalary) {
          selectedSalary["percentages"] = 100;
          salaryDistribution = salaryDistribution.map((sal) => {
            if (sal.isBasicIncome == true)
              return {
                ...sal,
                percentages: 100,
              };
            else
              return {
                ...sal,
                percentages: sal.percentage || sal.percentages,
              };
          });
        } else {
          this.props.DisplayClientSideMessage(
            "Select income heads and adjust their income percentage before distribution"
          );
        }
      }
      this.changeDistributionPercentage("percentages", "100", selectedSalary);
      this.setState(
        {
          [name]: true,
          [alterField]: false,
          salaryDistribution,
          distributionChanged: true,
        } as Pick<State, keyof State>,
        () => {
          this.updateErrors();
        }
      );
    } else {
      this.setState(
        {[name]: value, distributionChanged: true} as Pick<State, keyof State>,
        () => {
          this.updateErrors();
        }
      );
    }
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  changeDistributionPercentage = async (name, value, dist) => {
    let {salaryDistribution} = this.state;

    let selectedSalary;
    let totalPercentage = 0;
    let coDependentId = [];
    let basicPercent;

    if (dist && dist.incomeId) {
      selectedSalary = salaryDistribution.find((s) => s.incomeId == dist.incomeId);
    }
    if (selectedSalary) {
      selectedSalary[name] = value ? parseFloat(value) : 0;
    } else {
      selectedSalary = {
        [name]: value ? parseFloat(value) : 0,
      };
    }

    if (dist && dist.parentIncomeId) {
      salaryDistribution.forEach((sal) => {
        if (sal.incomeId === dist.parentIncomeId && dist.parentIncomeId !== 0)
          coDependentId.push(sal.incomeId);
        if (sal.isBasicIncome === true) basicPercent = sal.percentages;
      });
    }

    salaryDistribution.forEach((sal) => {
      if (sal.isBasicIncome === true) basicPercent = sal.percentages;
    });

    let distributedSal = salaryDistribution.map((s) => {
      if (s.type !== "Fixed" || s.isPFIncome) {
        const percentages = ParseFloat((s.percentage * basicPercent) / 100, 2);

        return {
          ...s,
          percentages,
        };
      } else {
        return s;
      }
    });

    distributedSal.forEach((s) => {
      totalPercentage = totalPercentage + (s.percentages ? parseFloat(s.percentages) : 0);
    });

    this.setState({
      distributionChanged: true,
      salaryDistribution: distributedSal,
      totalPercentage: Math.round(totalPercentage * 100) / 100,
    });
  };

  distributeSelectedSalary = () => {
    const {gross, basic, grossPay, salaryDistribution, totalPercentage} = this.state;
    let totalBal = grossPay;
    if (isEmpty(totalBal) || totalBal <= 0 || isNaN(totalBal)) {
      this.props.DisplayClientSideMessage("Salary to distribute is required");
      return;
    }

    this.setState({/* salaryDistribution, */ totalPay: totalBal}, async () => {
      let data = {
        isGrossPay: gross,
        payAmount: totalBal,
        salaryArray: [],
      };
      let totalIncomePercent = 0;
      salaryDistribution.forEach((sal) => {
        totalIncomePercent =
          totalIncomePercent + (sal.percentages ? parseFloat(sal.percentages) : 0);
      });

      if (totalIncomePercent != 100 && gross == true) {
        this.props.DisplayClientSideMessage(
          "Select income heads and adjust their income percentage before distribution"
        );
        return;
      }
      salaryDistribution.forEach((sal) => {
        data.salaryArray.push({
          abbreviation: sal.abbreviation,
          amount: 0,
          calculation: sal.calculation,
          incomeId: sal.incomeId,
          isBasicIncome: sal.IsBasicIncome,
          isPFIncome: sal.IsPFIncome,
          isSSFType: sal.IsSSFType,
          percentage: sal.percentages,
          parentIncomeId: sal.parentIncomeId,
          templateRefId: sal.templateRefId,
          title: sal.title,
          type: sal.type,
        });
      });

      await this.props.GetDistributedSalary(data);
      const {distributedIncomeByAmount} = this.props;
      let newSalDist = [];
      if (distributedIncomeByAmount) {
        salaryDistribution.forEach((sal) => {
          distributedIncomeByAmount.SalaryTemplateDetailDto.forEach((dis) => {
            if (sal.incomeId == dis.IncomeId) {
              sal.amount = dis.Amount.toFixed(2);
              newSalDist.push(sal);
            }
          });
        });
      }
      this.setState({
        totalPay: distributedIncomeByAmount.TotalAmount,
        distributionChanged: false,
        /* totalPercent: distributedIncomeByAmount.PercentageSum, */ salaryDistribution:
          newSalDist,
      });
    });
    // }
  };

  onIncomeHeadChecked = (e, selected) => {
    const {payrollIncomeHeads} = this.state;
    let selectedSalary = payrollIncomeHeads.find((s) => s.IncomeId == selected.IncomeId);
    selectedSalary["checked"] = e.target.checked;
    // if (selected.Abbreviation == "PF" || selected.Abbreviation == "SSF") {
    if (selected.IsPFIncome == true || selected.IsSSFType == true) {
      if (e.target.checked == true) {
        // let oppSite = selected.Abbreviation == "PF" ? "SSF" : "PF";
        // let selectedSalary = payrollIncomeHeads.find((s) => s.Abbreviation == oppSite);
        let selectedSalary = payrollIncomeHeads.find((s) => {
          if (selected.IsPFIncome === true) {
            return s.IsSSFType === true;
          } else {
            return s.IsPFIncome === true;
          }
        });
        selectedSalary["checked"] = !e.target.checked;
      }
    }
    this.setState({payrollIncomeHeads, distributionChanged: true});
  };

  addIncomeHeads = async () => {
    const {salaryDistribution, payrollIncomeHeads, editView, templateId} = this.state;
    let salaryArray = [];
    let totalPercentage = 0;
    await payrollIncomeHeads.forEach((pay, i) => {
      if (pay.checked == true || i == 0) {
        let ratePercent = pay.RatePercent ? pay.RatePercent : 0;
        let amount = 0;
        if (editView) {
          let selectedSalaryDist = salaryDistribution.find(
            (s) => s.incomeId == pay.IncomeId
          );
          if (selectedSalaryDist) {
            ratePercent = selectedSalaryDist.percentages;
            amount = selectedSalaryDist.amount;
          }
        }

        salaryArray.push({
          abbreviation: pay.Abbreviation,
          amount,
          calculation: pay.Calculation,
          incomeId: pay.IncomeId,
          isBasicIncome: pay.IsBasicIncome,
          isPFIncome: pay.IsPFIncome,
          isSSFType: pay.IsSSFType,
          parentIncomeId: pay.ParentIncomeId,
          percentage: pay.RatePercent,
          percentages: ratePercent,
          templateRefId: templateId,
          title: pay.Title,
          type: pay.Type,
        });
        totalPercentage = totalPercentage + (ratePercent ? parseFloat(ratePercent) : 0);
      }
    });

    this.setState({
      distributionChanged: true,
      salaryDistribution: salaryArray,
      incomeHeadOpen: false,
      // totalPercentage: getRoundedNumber(totalPercentage),
      totalPercentage: Math.round(totalPercentage * 100) / 100,
    });
  };

  handleFormSubmit = async () => {
    let {
      templateOptions,
      templateOption,
      grossPay,
      salaryDistribution,
      gross,
      basic,
      totalPay,
      templateName,
      totalPercentage,
      distributionChanged,
      employeeLevel,
      levelLists,
      incomeHeadOpen,
      payrollIncomeHeads,
      openNewTemplate,
      editView,
      templateId,
    } = this.state;

    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }

    if (isEmpty(grossPay) || grossPay <= 0 || isNaN(grossPay)) {
      this.props.DisplayClientSideMessage("Salary to distribute is required");
      return;
    }
    // let totalIncomePercent = 0;
    // salaryDistribution.forEach(sal => {
    //   totalIncomePercent = totalIncomePercent + (sal.percentages ? sal.percentages : 0)
    // });
    if (distributionChanged == true) {
      this.props.DisplayClientSideMessage("Re-distribute salary income before saving");
      return;
    }
    if (totalPercentage != 100 && gross == true) {
      this.props.DisplayClientSideMessage("Adjust Income percentage before distribution");
      return;
    }
    totalPercentage = 0;
    salaryDistribution = salaryDistribution.map((sal) => {
      totalPercentage += parseFloat(sal.percentages);
      return {
        ...sal,
        percentage: parseFloat(sal.percentages),
      };
    });

    if (Math.round(totalPercentage / 100) * 100 != 100 && gross == true) {
      this.props.DisplayClientSideMessage("Adjust Income percentage before distribution");
      return;
    }

    let templateData = {
      salaryTemplateDto: {
        templateId: editView ? templateId : 0,
        templateName: templateName,
        levelId: employeeLevel.value,
        isOnGrossDistribution: gross,
        grossPay: grossPay,
        basicPay: grossPay,
      },
      salaryTemplateDetailDto: salaryDistribution,
    };

    if (editView) await this.props.UpdateSalaryTemplate(templateData);
    else await this.props.AddSalaryTemplate(templateData);
    const {saveSalaryTemplate} = this.props;
    if (saveSalaryTemplate) {
      // this.setState({ openNewTemplate: false });
      this.props.closeForm();
      this.props.GetAllSalaryTemplate();
      this.setState({
        errors: {},
      });
    }
  };

  render() {
    const {
      grossPay,
      salaryDistribution,
      gross,
      basic,
      totalPay,
      templateName,
      employeeLevel,
      levelLists,
      incomeHeadOpen,
      payrollIncomeHeads,
      errors,
      editView,
      totalPercentage,
    } = this.state;

    const {requesting} = this.props;

    return (
      // <div className="salaryTemplate">
      <div className="salaryTemplate-form">
        <div className="salaryTemplate-form__header">Pay Template</div>
        <div className="salaryTemplate-form__body">
          <div className="form-row">
            <FormGroup
              autoFocus
              name="templateName"
              value={templateName}
              onChange={this.handleFieldChange}
              label="Template Name"
              error={errors?.templateName}
              width="500px"
              validators={["required"]}
            />
          </div>
          <div className="form-row salaryTemplate-placeunder">Place Under</div>
          <div className="form-row">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="employeeLevel"
              value={employeeLevel}
              options={levelLists}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.employeeLevel}
              width="500px"
              label="Level"
            />
          </div>
          <div className="form-row applicable-incomes">
            <div className="salaryTemplate-header">
              <Button
                title="Select Income Heads"
                bg="primary"
                onClick={() => this.setState({incomeHeadOpen: true})}
              />
            </div>
            <Modal
              open={incomeHeadOpen}
              onModalClose={() => this.setState({incomeHeadOpen: false})}
              title="Applicable Income"
            >
              <IncomeHeadOptions
                title="Applicable Incomes"
                onModalClose={() => this.setState({incomeHeadOpen: false})}
                incomeHeads={payrollIncomeHeads}
                onIncomeHeadChecked={this.onIncomeHeadChecked}
                addIncomeHeads={this.addIncomeHeads}
              />
            </Modal>
          </div>
          <div className="form-row flex">
            <FormGroup
              formName="radiogroup"
              onChange={(name, value) => this.handleFieldChange(name, !value)}
              name="gross"
              value={gross}
              checked={gross}
              loadingType="skeleton"
              placeholder="Select"
              width="200px"
              multiple
              label="Gross distribution"
            />
            <FormGroup
              formName="radiogroup"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="basic"
              value={basic}
              checked={basic}
              loadingType="skeleton"
              placeholder="Select"
              width="200px"
              multiple
              label="Calculation on basic"
            />
          </div>
          <div className="form-row flex">
            <FormGroup
              name="grossPay"
              value={grossPay}
              onChange={this.handleFieldChange}
              label={gross ? "Gross Pay" : "Basic Pay"}
              error={errors?.grossPay}
              type="number"
              width="200px"
              validators={["required"]}
              minValue={"0"}
            />
            <Button
              title={gross ? "Distribute" : "Calculate"}
              bg="primary"
              onClick={() => this.distributeSelectedSalary()}
            />
          </div>
          <div className="salary-distribution">
            <div className="salary-distribution-mainheader">
              <span className="salaryTemplate-table-header">Pay Name</span>
              <span className="salaryTemplate-table-header">Type</span>
              <span className="salaryTemplate-table-header">Percentage</span>
              <span className="salaryTemplate-table-header">Amount</span>
            </div>
            <div className="salary-distribution-mainheader">
              {salaryDistribution.map((s) => (
                <>
                  <span className="salaryTemplate-table-body ">{s.title}</span>
                  <span className="salaryTemplate-table-body ">
                    {s.type}
                    {s.percentage > 0 ? `(${s.percentage})%` : ""}
                  </span>
                  <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                    <FormGroup
                      type="number"
                      name="percentages"
                      value={s.percentages}
                      onChange={(name, value) =>
                        this.changeDistributionPercentage(name, value, s)
                      }
                      minValue={"0"}
                      disabled={
                        s.type !== "Fixed" || (s.isBasicIncome == true && gross == false)
                      }
                    />
                  </span>
                  <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                    Rs. {this.currencyCommaSeperator(s.amount)}
                  </span>
                </>
              ))}
            </div>
            <div className="salary-distribution-mainheader">
              <span className="salaryTemplate-table-body ">Total</span>
              <span className="salaryTemplate-table-body "></span>
              <span className="salaryTemplate-table-body salaryTemplate-amount salaryTemplate-percentage flex">
                {gross ? totalPercentage : null}
              </span>
              <span className="salaryTemplate-table-body salaryTemplate-amount flex">
                Rs. {totalPay ? this.currencyCommaSeperator(totalPay) : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="salaryTemplate-form__footer flex justify-end">
          <Button bg="highlight" title="Cancel" onClick={this.props.closeForm} />
          <Button
            bg="primary"
            title={editView ? "Update" : "Save"}
            onClick={() => this.handleFormSubmit()}
            isDisabled={requesting}
          />
        </div>
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeFamiliesDetail: state.employeeProfile.employeeFamiliesDetail,
  levelList: state.commonReducer.levelList,
  payrollIncomeHeads: state.commonReducer.payrollIncomeHeads,
  distributedIncomeByAmount: state.salaryTemplateReducer.distributedIncomeByAmount,
  saveSalaryTemplate: state.salaryTemplateReducer.saveSalaryTemplate,
  salaryTemplateList: state.salaryTemplateReducer.salaryTemplateList,
  salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
  requesting: state.salaryTemplateReducer.requesting,
});

const mapDispatchToProps = {
  getLevels,
  GetPayrollIncomeHeads,
  GetDistributedSalary,
  DisplayClientSideMessage,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryTemplateForm);
