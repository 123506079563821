import RouteEnum from "constants/routeEnum";
import {lazy} from "react";

import Dashboard from "scenes/employee/dashboard";
import OnBoardingDashboard from "scenes/employee/onboarding";
import Personal from "scenes/employee/personal";
import JobInformation from "scenes/employee/jobInformation";
import Payroll from "scenes/employee/payroll";
import Profile from "scenes/employee/employeeProfile";
import EmployeeListScreen from "scenes/employee/employeeList";
import EmployeeLeavesScreen from "scenes/employee/leaves";
import EmployeeTimeScreen from "scenes/employee/time/attendance";
import EmployeeOtherMenus from "scenes/employee/otherMenus";
import RequestScreen from "scenes/employee/requests";
import EmployeeRequestReviewScreen from "scenes/employee/requests/review";
import LeaveRequestDetailsScreen from "scenes/employee/leaves/leaveDetails";
import OverTimeRequestDetailsScreen from "scenes/employee/overtimeRequest/overtimeDetails";
import EmployeeTimeRequestScreen from "scenes/employee/time/timeRequest";
import MyRequestScreen from "scenes/employee/requests/myRequest";
import MyRequestHistoryScreen from "scenes/employee/requests/myHistory";
import TeamRequestHistoryScreen from "scenes/employee/requests/teamHistory";
import Reports from "scenes/employee/reports";
import Notitifications from "scenes/notifications";
import Travel from "scenes/travel";
import AllowanceExpense from "scenes/travel/admin/travelAllowanceExpense/allowanceExpense";
import TravelCategory from "scenes/travel/admin/travelCategory/travelCategory";
import TravelAdvanceReport from "scenes/hr/reports/advanceReports";
// // import TravelInformationScreen from '../Scenes/Travel//TravelInformation/TravelInformationMainScreen'
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";
import MyTravelRequestScreen from "scenes/travel/common/travelInformation/myTravelRequest/myTravelRequestScreen";
import OthersTravelRequestScreen from "scenes/travel/common/travelInformation/otherTravelRequest/otherTravelRequestScreen";
import TravelRequestHistoryScreen from "scenes/travel/common/travelInformation/travelHistory/travelHistoryScreen";
// import MyTravelRequest from "../Scenes/Travel/Common/MyTravelRequest";
import SettlementRequests from "scenes/travel/common/travelSettlement/settlementRequest/settlementRequests";
import PendingSettlement from "scenes/travel/common/travelSettlement/pendingSettlement/pendingSettlement";
import DisciplinaryActionScreen from "scenes/admin/disciplinaryAction";
import DisciplinaryActions from "scenes/admin/disciplinaryAction/form/disciplinaryActions";
import DisciplinaryStages from "scenes/admin/disciplinaryStages";
import HRDashboard from "scenes/hr";
import HRCommonTasks from "scenes/hr/commonTasks";
import PayrollDashboard from "scenes/payroll/dashboard";
import PayrollCommonLinks from "scenes/payroll/commonTasks";
import PayrollReports from "scenes/payroll/reports";
import PayrollSettings from "scenes/payroll/settings";
import PayrollIncomeCalculation from "scenes/payroll/incomeCalculation";
import PayrollLeaveAbsence from "scenes/payroll/leaveAbsence";
import PayrollReviewFinalize from "scenes/payroll/reviewFinalize";
import PayrollRFAdjustmentFinalize from "scenes/payroll/rfAdjustment/rfAdjustment";
import PayrollSalaryAdjustmentFinalize from "scenes/payroll/salaryAdjustment";
import PayrollStopPayment from "scenes/payroll/stopPayment";
import PayrollTaxAdjustment from "scenes/payroll/taxAdjustment";
import PayrollTaxCalculation from "scenes/payroll/taxCalaulation";
import PayrollContinue from "scenes/payroll/payroll";
import EmployeeOnBoarding from "scenes/admin/employeeOnboarding";
import CreateOnboardingEmployeePath from "scenes/admin/employeeOnboarding/form";
import EmployeeOnboardingPackages from "scenes/admin/employeeOnboarding/subscPackages";

import LeaveTimeDashboard from "scenes/leaveTime";
import LeaveCommonLinks from "scenes/leaveTime/commonTasks";
import LeaveReports from "scenes/leaveTime/reports";
import LeaveSettings from "scenes/leaveTime/settings";

//Admin Dashboards Comp
import KSALibrariesScreen from "scenes/admin/jobProfile/ksaLibraries";
import JobProfile from "scenes/admin/jobProfile";
import JobProfileFormScreen from "scenes/admin/jobProfile/form/jobProfile/jobProfileForm";
import JobDescriptionFormScreen from "scenes/admin/jobProfile/form/jobDescription/jobDescriptionForm";
import HRSettings from "scenes/hr/settings";
import HRReports from "scenes/hr/reports";
import Settings from "scenes/settings";
import TravelSettings from "scenes/settings/travel";

//Task tracker components
import ActivityDashboardScreen from "scenes/taskTracker/activityDashboard";
import TaskTrackerCommonTaskScreen from "scenes/taskTracker/commonTasks";
import ActivityAndBillingRolesScreen from "scenes/taskTracker/commonTasks/activityAndBillingRoles";
import LegalClientScreen from "scenes/taskTracker/commonTasks/legalClient";
import TaskTrackerReportScreen from "scenes/taskTracker/reports";
import ActivityReportScreen from "scenes/taskTracker/reports/activityReport";
import ActivityNotSubmittedReportScreen from "scenes/taskTracker/reports/activityNotSubmittedReport";
import HoursWorkedByClientScreen from "scenes/taskTracker/reports/hoursWorkedByClient";
import HoursWorkedByPeopleScreen from "scenes/taskTracker/reports/hoursWorkedByPeople";
import HoursCommittedScreen from "scenes/taskTracker/reports/hoursCommitted";
import PeopleInProjectScreen from "scenes/taskTracker/reports/peopleInProject";
import RawReportScreen from "scenes/taskTracker/reports/rawReport";
import ProjectInformation from "scenes/taskTracker/activityDashboard/projectInformation";

// Task Bords Components
// const Recruit = lazy(() => import('scenes/recruit'));
import Boards from "scenes/tasks/boards";
import Board from "scenes/tasks/board";
import BoardList from "scenes/tasks/boardsList";
import TaskTodo from "scenes/tasks/taskTodo";
import TaskSummary from "scenes/tasks/taskSummary";
import BoardActivityReport from "scenes/tasks/boardActivityReport";

// sbi loan Components
import SbiLoan from "scenes/sbiLoan/loanSetting";
import SbiPersonalLoan from "scenes/sbiLoan/personalLoan";
import SbiLoanStatusHistroy from "scenes/sbiLoan/loanStatusHistories";
import SbiLoanPurpose from "scenes/sbiLoan/loanPurpose";

// import Recruit from "scenes/recruit";
import JobCreate from "scenes/recruit/jobCreate";
import PositionDetails from "scenes/recruit/jobCreate/positionDetails";
import Applications from "scenes/recruit/jobCreate/applications";
import HiringStage from "scenes/recruit/jobCreate/hiringStage";
import Scorecard from "scenes/recruit/jobCreate/scorecard";
import HiringTeam from "scenes/recruit/jobCreate/hiringTeam";
import RecruitDashboard from "scenes/recruit/dashboard";
import RecruitDashboardOpening from "scenes/recruit/dashboard/opening";
import DashboardInformation from "scenes/recruit/dashboard/information";
import ApproverRequistionRequestScreen from "scenes/recruit/jobRequisition/approverRequisitionRequestScreen";
import JobRequisitionScreen from "scenes/recruit/jobRequisition/jobRequisition";
import MyRequistionRequestScreen from "scenes/recruit/jobRequisition/myRequistionRequestScreen";
import JobDetails from "scenes/recruit/jobListing/jobDetails";
import JobListing from "scenes/recruit/jobListing/jobListing";
import JobApplyScreen from "scenes/recruit/jobApply/jobApplyScreen";
import ApplicantsReportScreen from "scenes/recruit/report/application";
import EmailTemplateScreen from "scenes/recruit/settings/emailTemplate";

import EmployeeOnboardingAddEmployee from "scenes/admin/employeeOnboarding/form/createNew";

// Overtime & Allowance
import OvertimeRequest from "../scenes/hr/overtime";
import AllowanceRequest from "../scenes/hr/allowance";

//Roles and Authorization
import Authorized from "scenes/roles/authorized";
import Roled from "scenes/roles/roles";
import Managers from "scenes/roles/managers";
import Clusters from "scenes/roles/clusters";

import ReportsPage from "scenes/employee/reports/pages/reports";

// loan routes
import LoanManagement from "scenes/loan";
import AdminLoanManagement from "scenes/loan/adminListing";
import EmployeeLoanList from "scenes/loan/employeeListing";
import HomeLoanCreateScreen from "scenes/loan/loanTypes/homeLoan";
import HomeLoanViewScreen from "scenes/loan/loanTypes/homeLoan/homeLoanView";
import VehicleLoanViewScreen from "scenes/loan/loanTypes/vehicleLoan/vehicleLoanView";
import VehicleCreateScreen from "scenes/loan/loanTypes/vehicleLoan";
import StaffODCreateScreen from "scenes/loan/loanTypes/staffODLoan";
import StaffPersonalCreateScreen from "scenes/loan/loanTypes/staffPersonalLoan";

// new loan routes
import MyAppliedLoanRequests from "scenes/employee/sbiloan/myAppliedLoanRequests";
import ConsumerLoanRequest from "scenes/employee/sbiloan/loanRequest/consumerLoan";
import SocialLoanRequest from "scenes/employee/sbiloan/loanRequest/socialLoan";
import PersonalLoanRequest from "scenes/employee/sbiloan/loanRequest/personalLoan";
import SBILoanDetailView from "scenes/employee/sbiloan/loanDetails/consumerLoan/index";
// import SocialLoanRequest from 'scenes/employee/sbiloan/loanRequest/socialLoan';

// import LMS from 'scenes/lms'
import lmsLinks from "./lmsLinks";

//Roster
import Rosters from "scenes/roster";
import RosterShifts from "scenes/roster/shifts";
import RosterSchedule from "scenes/roster/schedule";
import ShiftPattern from "scenes/roster/shiftPattern";
import SchedulePdf from "scenes/roster/schedule/schedules/schedulePdf";
import ShiftEmployeeAttendance from "scenes/roster/shiftEmployeeAttendance";
import WorkHours from "scenes/roster/workHours";

// Access & ID Category
import AccessAndIdCategoryTemplateScreen from "scenes/onboarding/accessAndIdCategoryTemplate";
// Checklist
import ChecklistTemplateScreen from "scenes/onboarding/checklistTemplate";
// Message
import MessageTemplateScreen from "scenes/onboarding/messageTemplate";
//import Appraisals
// import Appraisals from 'scenes/appraisals';
import PerformanceAppraisals from "scenes/appraisals/performanceAppraisals";
import EmailConfig from "scenes/appraisals/emailConfig";
import Questionaires from "scenes/appraisals/questionaires";
import RatingScale from "scenes/appraisals/ratingScale";
import ReviewsCycle from "scenes/appraisals/reviewsCycle";
import WorkFlow from "scenes/appraisals/workFlow";
import ThreeSixtyDegreeView from "scenes/appraisals/threeSixtyDegreeReview";
import GoalDatabase from "scenes/appraisals/goalDatabase";
import FinalScore from "scenes/appraisals/finalScore";
import ViewAllMessages from "scenes/employee/dashboard/messages/viewAll";

//Recruit Componenets
const Recruit = lazy(() => import("scenes/recruit"));
//LMS
const LMS = lazy(() => import("scenes/lms"));
const Appraisals = lazy(() => import("scenes/appraisals"));
const SBILoan = lazy(() => import("scenes/employee/sbiloan"));

export default (features) => [
  {
    path: RouteEnum.AuthorizedUser,
    exact: true,
    isAdmin: true,
    component: Authorized,
    title: "Authorized Users",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Roles,
    exact: true,
    isAdmin: true,
    component: Roled,
    title: "Roles",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ManagersLinks,
    exact: true,
    isAdmin: true,
    component: Managers,
    title: "Managers",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ClustersLink,
    exact: true,
    isAdmin: true,
    component: Clusters,
    title: "Regions & Cluster",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.OvertimeRequestPath,
    exact: true,
    isAdmin: true,
    component: OvertimeRequest,
    title: "Overtimes Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.AllowanceRequestPath,
    exact: true,
    isAdmin: true,
    component: AllowanceRequest,
    title: "Allowance Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeDashBoard,
    exact: true,
    isEmployee: true,
    component: Dashboard,
    title: "Employee Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeViewAllMessage,
    exact: true,
    isEmployee: true,
    component: ViewAllMessages,
    title: "Employee Messages",
    feature: features.CoreHR,
  },

  {
    path: RouteEnum.EmployeeOnboarded,
    exact: true,
    isEmployee: true,
    component: OnBoardingDashboard,
    title: "Employee Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PersonalInfo,
    exact: true,
    isEmployee: true,
    component: Personal,
    title: "Personal Info",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.JobInfo,
    exact: true,
    isEmployee: true,
    component: JobInformation,
    title: "Job Info",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeListPath,
    exact: true,
    isAdmin: true,
    component: EmployeeListScreen,
    title: "Employee List",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeProfile + "/:id",
    exact: true,
    component: Profile,
    isAdmin: true,
    title: "Employee Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeReportsPath,
    exact: true,
    component: Reports,
    title: "Employee Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.YearlyReportsPath + "/:reports",
    exact: true,
    component: ReportsPage,
    title: "Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeePayroll,
    exact: true,
    component: Payroll,
    title: "Employee Payroll",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeLeavesPath,
    exact: true,
    component: EmployeeLeavesScreen,
    title: "Leaves",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeTimePath,
    exact: true,
    component: EmployeeTimeScreen,
    title: "Time",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeTimeRequestPath,
    exact: true,
    component: EmployeeTimeRequestScreen,
    title: "Time Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOtherMenu,
    exact: true,
    component: EmployeeOtherMenus,
    title: "Menus",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeRequest,
    // exact:true,
    component: RequestScreen,
    // title: "Request",
    feature: features.CoreHR,
    children: requestLinks,
  },

  {
    path: RouteEnum.lmsRoutes,
    // exact:true,
    component: LMS,
    // title: "Request",
    feature: features.LMS,
    children: lmsLinks,
  },

  {
    path: RouteEnum.roosterRoutes,
    // exact:true,
    component: Rosters,
    // title: "Rosters",
    feature: features.CoreHR,
    children: roosterLinks,
  },

  {
    path: RouteEnum.appraisalsRoutes,
    // exact:true,
    component: Appraisals,
    // title: "Rosters",
    feature: features.CoreHR,
    children: appraisalsLinks,
  },
  {
    path: RouteEnum.LeaveRequestDetailsPath,
    exact: true,
    component: LeaveRequestDetailsScreen,
    title: "Leave Details",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.OverTimeRequestDetailsPath,
    exact: true,
    component: OverTimeRequestDetailsScreen,
    title: "Overtime Details",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.NotificationsPath,
    exact: true,
    component: Notitifications,
    title: "Notitifactions",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TravelPath,
    // exact:true,
    component: Travel,
    // title: "Travel",
    feature: features.TravelManagement,
    children: travelLinks,
  },
  {
    path: RouteEnum.PayrollDashboardPath,
    exact: true,
    component: PayrollDashboard,
    title: "Payroll Dashboard",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardCommonTaskPath,
    exact: true,
    component: PayrollCommonLinks,
    title: "Payroll Common Task",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardReportsPath,
    exact: true,
    component: PayrollReports,
    title: "Payroll Reports",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardSettingsPath,
    exact: true,
    component: PayrollSettings,
    title: "Payroll Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollContinuePath,
    exact: true,
    component: PayrollContinue,
    // title: "Payroll",
    isAdmin: true,
    children: [
      {
        path: RouteEnum.PayrollIncomeCalculationPath,
        exact: true,
        component: PayrollIncomeCalculation,
        title: "Payroll Income Calculation",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollLeaveAbsencePath,
        exact: true,
        component: PayrollLeaveAbsence,
        title: "Payroll Leave Absence",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollReviewFinalizePath,
        exact: true,
        component: PayrollReviewFinalize,
        title: "Payroll Review Finalize Absence",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollRFAdjustmentPath,
        exact: true,
        component: PayrollRFAdjustmentFinalize,
        title: "Payroll RF Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollSalaryAdjustmentPath,
        exact: true,
        component: PayrollSalaryAdjustmentFinalize,
        title: "Payroll Salary Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollStopPaymentPath,
        exact: true,
        component: PayrollStopPayment,
        title: "Payroll Stop Payment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollTaxAdjustmentPath,
        exact: true,
        component: PayrollTaxAdjustment,
        title: "Payroll Tax Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollTaxCalculationPath,
        exact: true,
        component: PayrollTaxCalculation,
        title: "Payroll Tax Calculation",
        isAdmin: true,
        feature: features.CoreHR,
      },
    ],
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Settings,
    exact: true,
    component: Settings,
    title: "HR Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TravelSettings,
    exact: true,
    component: TravelSettings,
    title: "HR Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardPath,
    exact: true,
    component: LeaveTimeDashboard,
    isAdmin: true,
    title: "Leave & Time Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardCommonTaskPath,
    exact: true,
    component: LeaveCommonLinks,
    isAdmin: true,
    title: "Leave & Time Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardReportsPath,
    exact: true,
    component: LeaveReports,
    isAdmin: true,
    title: "Leave & Time Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardSettingsPath,
    exact: true,
    component: LeaveSettings,
    isAdmin: true,
    title: "Leave & Time Settings",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.DisciplinaryActionPath,
    exact: true,
    component: DisciplinaryActionScreen,
    isAdmin: true,
    title: "Disciplinary Action",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.DisciplinaryActionStagesPath,
    exact: true,
    component: DisciplinaryStages,
    isAdmin: true,
    title: "Disciplinary Action Stages",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewDisciplinaryActionPath + ":disciplinaryCaseId",
    exact: true,
    component: DisciplinaryActions,
    isAdmin: true,
    title: "Disciplinary Action",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardPath,
    exact: true,
    component: HRDashboard,
    isAdmin: true,
    title: "HR Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TravelAdvanceSettlementReport,
    exact: true,
    component: TravelAdvanceReport,
    isAdmin: true,
    title: "Travel Advance Report Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardCommonTaskPath,
    exact: true,
    component: HRCommonTasks,
    isAdmin: true,
    title: "HR Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardReportsPath,
    exact: true,
    component: HRReports,
    isAdmin: true,
    title: "HR Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardSettingsPath,
    exact: true,
    component: HRSettings,
    isAdmin: true,
    title: "HR Settings",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.KSALibrariesPath,
    exact: true,
    component: KSALibrariesScreen,
    isAdmin: true,
    title: "KSALibraries",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.JobProfilePath,
    exact: true,
    component: JobProfile,
    isAdmin: true,
    title: "Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateJobProfilePath,
    exact: true,
    component: JobProfileFormScreen,
    isAdmin: true,
    title: "Create Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateJobProfilePath + ":profileId",
    exact: true,
    component: JobProfileFormScreen,
    isAdmin: true,
    title: "Update Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateJobDescriptionPath,
    exact: true,
    component: JobDescriptionFormScreen,
    isAdmin: true,
    title: "Create Job Description",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateJobDescriptionPath + ":descriptionId",
    exact: true,
    component: JobDescriptionFormScreen,
    isAdmin: true,
    title: "Update Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ActivityDashboardPath,
    exact: true,
    component: ActivityDashboardScreen,
    title: "Dashboard",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.ActivityProjectReview,
    exact: true,
    component: ProjectInformation,
    title: "Project Review",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.TaskTrackerReportPath,
    exact: true,
    component: TaskTrackerReportScreen,
    title: "Reports",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.TaskTrackerCommonTaskPath,
    exact: true,
    component: TaskTrackerCommonTaskScreen,
    title: "Common Tasks",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.LegalClientPath,
    exact: true,
    component: LegalClientScreen,
    title: "Clients",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.ActivityAndBillingRolesPath,
    exact: true,
    component: ActivityAndBillingRolesScreen,
    title: "Activity and Billing Roles",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.ActivityReportPath,
    exact: true,
    component: ActivityReportScreen,
    title: "Activity Report",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.ActivityNotSubmittedReportPath,
    exact: true,
    component: ActivityNotSubmittedReportScreen,
    title: "Activity Not Submitted",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.HoursWorkedByClientPath,
    exact: true,
    component: HoursWorkedByClientScreen,
    title: "Hours Worked by Client",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.HoursWorkedByPeoplePath,
    exact: true,
    component: HoursWorkedByPeopleScreen,
    title: "Hours Worked by People",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.HoursCommittedPath,
    exact: true,
    component: HoursCommittedScreen,
    title: "Hours Committed",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.PeopleInProjectPath,
    exact: true,
    component: PeopleInProjectScreen,
    title: "People in Project",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.RawReportPath,
    exact: true,
    component: RawReportScreen,
    title: "Raw Report",
    feature: features.TaskTracker,
  },
  {
    path: RouteEnum.EmployeeOnboarding,
    exact: true,
    component: EmployeeOnBoarding,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOnboardingAddEmployee,
    exact: true,
    isAdmin: true,
    component: EmployeeOnboardingAddEmployee,
    title: "Employee Onboarding Add Employee",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateOnboardingEmployee,
    exact: true,
    component: CreateOnboardingEmployeePath,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateOnboardingEmployee + "/:id",
    exact: true,
    component: CreateOnboardingEmployeePath,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOnboardingPackages + "/:id",
    exact: true,
    isAdmin: true,
    component: EmployeeOnboardingPackages,
    title: "Employee Onboarding Package",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TaskBoards,
    component: Boards,
    // title: "Tasks Boards",
    feature: features.TaskManagement,
    children: taskboardLinks,
  },
  {
    path: RouteEnum.TaskSummary,
    exact: true,
    component: TaskSummary,
    feature: features.TaskManagement,
    title: "Tasks Summary",
  },
  {
    path: RouteEnum.TaskBoardActitityReport,
    exact: true,
    component: BoardActivityReport,
    feature: features.TaskManagement,
    title: "Tasks Summary",
  },
  {
    path: RouteEnum.RecruitPath,
    component: Recruit,
    // title: "Recruit",
    feature: features.Recruitment,
    children: recruitLinks,
  },
  // loan module
  {
    path: RouteEnum.LoanManagement,
    exact: true,
    component: LoanManagement,
    title: "Loan Management",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateHomeLoanPath,
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateHomeLoanPath + "/:id",
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewHomeLoanPath + "/:id",
    exact: true,
    title: "View Home Loan",
    component: HomeLoanViewScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateVehicleLoanPath,
    exact: true,
    title: "Request Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateVehicleLoanPath + "/:id",
    exact: true,
    title: "Update Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewVehicleLoanPath + "/:id",
    exact: true,
    title: "View Home Loan",
    component: VehicleLoanViewScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffODLoanPath,
    exact: true,
    title: "Request Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffODLoanPath + "/:id",
    exact: true,
    title: "Update Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffPersonalLoanPath,
    exact: true,
    title: "Request Staff Pesonal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffAdvanceLoanPath,
    exact: true,
    title: "Request Staff Advance Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffPersonalLoanPath + "/:id",
    exact: true,
    title: "Update Staff Personal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  // {
  //   path: RouteEnum.AddRecordPath,
  //   exact: true,
  //   component: LoanManagement,
  //   title: "Loan Management",
  //   feature: features.CoreHR,
  // },
  {
    path: RouteEnum.EmployeeLoanList,
    exact: true,
    component: EmployeeLoanList,
    title: "Loan List",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.AccessAndIDCategory,
    exact: true,
    component: AccessAndIdCategoryTemplateScreen,
    title: "Access & ID Templates",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Checklist,
    exact: true,
    component: ChecklistTemplateScreen,
    title: "Checklist Templates",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Message,
    exact: true,
    component: MessageTemplateScreen,
    title: "Message Templates",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.loanRoutes,
    // exact:true,
    component: SBILoan,
    // title: "Loan Requests",
    feature: features.Loan,
    children: SBILoanModule,
  },
  // ...newLoanModule(features)
];

const appraisalsLinks = [
  {
    path: RouteEnum.appraisalsPerformanceRoutes,
    exact: true,
    component: PerformanceAppraisals,
    title: "Performance Appraisals",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsWorkFlowRoutes,
    exact: true,
    component: WorkFlow,
    title: "Appraisal Work Flow",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsReviewsRoutes,
    exact: true,
    component: ReviewsCycle,
    title: "Reviews Cycle",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsRatingScaleRoutes,
    exact: true,
    component: RatingScale,
    title: "Rating Scale",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsQuestionairesRoutes,
    exact: true,
    component: Questionaires,
    title: "Questionaires",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsEmailConfigRoutes,
    exact: true,
    component: EmailConfig,
    title: "Email Configuration",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsthreeSixtyDegreeReviewRoutes,
    exact: true,
    component: ThreeSixtyDegreeView,
    title: "Three Sixty Degree Review",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsGoalDatabaseRoutes,
    exact: true,
    component: GoalDatabase,
    title: "Goal Database",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.appraisalsFinalScoreRoutes,
    exact: true,
    component: FinalScore,
    title: "Final Score",
    // feature:features.CoreHR
  },
];

const roosterLinks = [
  {
    path: RouteEnum.roosterScheduleRoutes,
    exact: true,
    isAdmin: true,
    component: RosterSchedule,
    title: "Roster Schedules",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.roosterSchedulePatternRoutes,
    exact: true,
    isAdmin: true,
    component: ShiftPattern,
    title: "Roster Shift Pattern",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.roosterShiftsRoutes,
    exact: true,
    isAdmin: true,
    component: RosterShifts,
    title: "Roster Shifts",
    // feature:features.CoreHR
  },
  {
    // path: RouteEnum.roosterSchedulePdfRoutes + '/:teamid/:startDate:/:endDate',
    path: RouteEnum.roosterSchedulePdfRoutes,
    exact: true,
    isAdmin: true,
    component: SchedulePdf,
    title: "Roster Shifts PDF",
    // feature:features.CoreHR
  },
  {
    // path: RouteEnum.roosterSchedulePdfRoutes + '/:teamid/:startDate:/:endDate',
    path: RouteEnum.roosterWorkHousRoutes,
    exact: true,
    isAdmin: true,
    component: WorkHours,
    title: "Roster Working Hours",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.roosterEmployeeAttendance,
    exact: true,
    // isAdmin: true,
    component: ShiftEmployeeAttendance,
    title: "Attendance",
  },
];

const requestLinks = [
  {
    path: RouteEnum.EmployeeRequestReviewPath,
    exact: true,
    component: EmployeeRequestReviewScreen,
    title: "Employee Request",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.MyRequestPath,
    exact: true,
    component: MyRequestScreen,
    title: "My Request ",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.MyRequestHistoryPath,
    exact: true,
    component: MyRequestHistoryScreen,
    title: "My Request History",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.EmployeeRequestHistoryPath,
    exact: true,
    component: TeamRequestHistoryScreen,
    title: "Team Request History",
    // feature:features.CoreHR
  },
];

const recruitLinks = [
  {
    path: RouteEnum.RecruitDashboardPath,
    exact: true,
    isAdmin: true,
    component: RecruitDashboard,
    title: "Recruit Dashboard",
  },
  {
    path: RouteEnum.RecruitDashboardOpeningPath,
    exact: true,
    isAdmin: true,
    component: RecruitDashboardOpening,
    title: "Opening Dashboard",
  },
  {
    path: RouteEnum.RecruitDashboardInformationPath,
    exact: true,
    component: DashboardInformation,
    title: "Recruit Dashboard Info",
  },
  {
    path: RouteEnum.RequistionRequestPath,
    exact: true,
    isAdmin: true,
    component: ApproverRequistionRequestScreen,
    title: "Requisition Request",
  },
  {
    path: RouteEnum.MyRequistionRequestPath,
    exact: true,
    isAdmin: true,
    component: MyRequistionRequestScreen,
    title: "My Requisition",
  },
  {
    path: RouteEnum.CreateNewJobRequisitionPath,
    exact: true,
    component: JobRequisitionScreen,
    isAdmin: true,
    title: "Create Job Requisition",
  },
  {
    path: RouteEnum.UpdateJobRequisitionPath,
    exact: true,
    isAdmin: true,
    component: JobRequisitionScreen,
    title: "Update Job Requisition",
  },
  {
    path: RouteEnum.JobListingPath,
    exact: true,
    isAdmin: true,
    component: JobListing,
    isNotPrivate: true,
    title: "Job Listing Path",
  },
  {
    path: RouteEnum.JobDetailsPath + ":jobId",
    exact: true,
    component: JobDetails,
    title: "Job Details",
  },
  {
    path: RouteEnum.JobApplyPath,
    exact: true,
    component: JobApplyScreen,
    title: "Job Apply Path",
  },
  {
    path: RouteEnum.JobCreatePath,
    exact: true,
    component: JobCreate,
    // title: "Job Create",
    isAdmin: true,
    children: [
      {
        path: RouteEnum.PositionDetailsPath,
        exact: true,
        component: PositionDetails,
        title: "Position Details",
        isAdmin: true,
      },
      {
        path: RouteEnum.ApplicationsPath,
        exact: true,
        component: Applications,
        title: "Applications",
        isAdmin: true,
      },
      {
        path: RouteEnum.HiringStagePath,
        exact: true,
        component: HiringStage,
        title: "Hiring Stage",
        isAdmin: true,
      },
      {
        path: RouteEnum.ScorecardPath,
        exact: true,
        component: Scorecard,
        title: "Scored Card",
        isAdmin: true,
      },
      {
        path: RouteEnum.HiringTeamPath,
        exact: true,
        component: HiringTeam,
        title: "Hiring Team",
        isAdmin: true,
      },
    ],
  },
  {
    path: RouteEnum.Applicants,
    exact: true,
    component: ApplicantsReportScreen,
    title: "Applicants",
  },
  {
    path: RouteEnum.RecruitEmailTemplate,
    exact: true,
    component: EmailTemplateScreen,
    title: "Email Template",
  },
];
const taskboardLinks = [
  {
    path: RouteEnum.TaskBoardSingleLink + ":board",
    exact: true,
    component: Board,
    title: "Tasks Boards",
  },

  {
    path: RouteEnum.TaskBoardListLinks,
    exact: true,
    component: BoardList,
    title: "Tasks Boards",
  },
  {
    path: RouteEnum.TaskBoardTodo,
    exact: true,
    component: TaskTodo,
    title: "Tasks To Do",
  },
];
const payRollLinks = [
  {
    path: RouteEnum.TaskBoardSingleLink + ":board",
    exact: true,
    component: Board,
    title: "Tasks Boards",
  },
  {
    path: RouteEnum.TaskBoardListLinks,
    exact: true,
    component: BoardList,
    title: "Tasks Boards",
  },
  {
    path: RouteEnum.TaskBoardTodo,
    exact: true,
    component: TaskTodo,
    title: "Tasks To Do",
  },
];

const travelLinks = [
  {
    path: RouteEnum.TravelAllowanceExpensePath,
    exact: true,
    component: AllowanceExpense,
    title: "Travel Allowance",
  },
  {
    path: RouteEnum.TravelCategoryPath,
    exact: true,
    component: TravelCategory,
    isAdmin: true,
    title: "Travel Category",
  },
  {
    path: RouteEnum.CreateTravelRequestPath,
    // exact:true,
    component: CreateNewTravelRequest,
    title: "Create New Travel Request",
    isTravelAnimate: true,
  },
  {
    path: RouteEnum.UpdateTravelRequestPath,
    // exact:true,
    component: CreateNewTravelRequest,
    title: "Update Travel Request",
    isTravelAnimate: true,
  },
  {
    path: RouteEnum.TeamTravelRequestPath,
    exact: true,
    component: OthersTravelRequestScreen,
    title: "Travel Request",
  },
  {
    path: RouteEnum.MyTravelRequestPath,
    exact: true,
    component: MyTravelRequestScreen,
    title: "My Travel Request",
  },
  {
    path: RouteEnum.SettlementRequestPath,
    exact: true,
    component: SettlementRequests,
    title: "Settlement Requests",
  },
  {
    path: RouteEnum.PendingSettlementPath,
    exact: true,
    component: PendingSettlement,
    title: "Pending Settlement",
  },
  {
    path: RouteEnum.TravelHistoryPath,
    exact: true,
    component: TravelRequestHistoryScreen,
    title: "Travel History",
  },
];

export const justLoanModule = (features) => [
  {
    path: RouteEnum.Settings,
    exact: true,
    component: Settings,
    title: "HR Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardPath,
    exact: true,
    component: LeaveTimeDashboard,
    isAdmin: true,
    title: "Leave & Time Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardCommonTaskPath,
    exact: true,
    component: LeaveCommonLinks,
    isAdmin: true,
    title: "Leave & Time Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardReportsPath,
    exact: true,
    component: LeaveReports,
    isAdmin: true,
    title: "Leave & Time Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardSettingsPath,
    exact: true,
    component: LeaveSettings,
    isAdmin: true,
    title: "Leave & Time Settings",
    feature: features.CoreHR,
  },

  {
    path: RouteEnum.HRDashboardPath,
    exact: true,
    component: HRDashboard,
    isAdmin: true,
    title: "HR Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardCommonTaskPath,
    exact: true,
    component: HRCommonTasks,
    isAdmin: true,
    title: "HR Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardReportsPath,
    exact: true,
    component: HRReports,
    isAdmin: true,
    title: "HR Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardSettingsPath,
    exact: true,
    component: HRSettings,
    isAdmin: true,
    title: "HR Settings",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardPath,
    exact: true,
    component: PayrollDashboard,
    title: "Payroll Dashboard",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardCommonTaskPath,
    exact: true,
    component: PayrollCommonLinks,
    title: "Payroll Common Task",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardReportsPath,
    exact: true,
    component: PayrollReports,
    title: "Payroll Reports",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardSettingsPath,
    exact: true,
    component: PayrollSettings,
    title: "Payroll Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },

  //Loan Module
  {
    path: RouteEnum.LoanManagement,
    exact: true,
    component: LoanManagement,
    title: "Loan Management",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.AdminLoanManagement,
    exact: true,
    component: AdminLoanManagement,
    title: "Loan List",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateHomeLoanPath,
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateHomeLoanPath + "/:id",
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewHomeLoanPath + "/:id",
    exact: true,
    title: "View Home Loan",
    component: HomeLoanViewScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateVehicleLoanPath,
    exact: true,
    title: "Request Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateVehicleLoanPath + "/:id",
    exact: true,
    title: "Update Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffODLoanPath,
    exact: true,
    title: "Request Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffODLoanPath + "/:id",
    exact: true,
    title: "Update Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffPersonalLoanPath,
    exact: true,
    title: "Request Staff Personal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffAdvanceLoanPath,
    exact: true,
    title: "Request Staff Advance Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffPersonalLoanPath + "/:id",
    exact: true,
    title: "Update Staff Personal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  // {
  //   path: RouteEnum.AddRecordPath,
  //   exact: true,
  //   component: LoanManagement,
  //   title: "Loan Management",
  //   feature: features.CoreHR,
  // },
  {
    path: RouteEnum.EmployeeLoanList,
    exact: true,
    component: EmployeeLoanList,
    title: "Loan List",
    feature: features.CoreHR,
  },
];

// new loan routes
export const SBILoanModule = [
  // {
  //   path: RouteEnum.MyLoanRequests,
  //   exact: true,
  //   component: Settings,
  //   title: "My Loan Request",
  //   isAdmin: true,
  //   feature: features.CoreHR,
  //   // feature: features.CoreHR,
  // },
  {
    path: RouteEnum.CreateLoanSbiPath,
    exact: true,
    component: SbiLoan,
    title: "SBI Loan",
  },
  {
    path: RouteEnum.SbiLoanPurpose,
    exact: true,
    component: SbiLoanPurpose,
    title: "SBI Loan",
  },
  {
    path: RouteEnum.StaffPersonalLoan,
    exact: true,
    component: SbiPersonalLoan,
    title: "SBI Loan",
  },
  {
    path: RouteEnum.ConsumerLoanRequest,
    exact: true,
    component: ConsumerLoanRequest,
    title: "Consumer Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateConsumerLoanRequest + "/:id",
    exact: true,
    component: ConsumerLoanRequest,
    title: "Update Consumer Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateSocialLoanRequest + "/:id",
    exact: true,
    component: SocialLoanRequest,
    title: "Update Consumer Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.SocialLoanRequest,
    exact: true,
    component: SocialLoanRequest,
    title: "Social Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.PersonalLoanRequest,
    exact: true,
    component: PersonalLoanRequest,
    title: "Personal Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdatePersonalLoanRequest + "/:id",
    exact: true,
    component: PersonalLoanRequest,
    title: "Update Personal Loan Request",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.MyAppliedLoanRequests,
    exact: true,
    component: MyAppliedLoanRequests,
    title: "My Loan Requests",
    // isAdmin: true,
    // feature: features.CoreHR,
  },
  {
    path: RouteEnum.LoanStatusHistory,
    exact: true,
    component: SbiLoanStatusHistroy,
    title: "SBI Loan status histories",
  },
  {
    path: RouteEnum.ViewSBILoanDetail + "/:id",
    exact: true,
    component: SBILoanDetailView,
    title: "SBI Loan Detail",
  },
];
