import React, {Component, Suspense, useEffect, useState} from "react";
import classnames from "classnames";
import man from "assets/images/man.png";
import isEmpty from "isEmpty";
import {ConfigProvider} from "react-avatar";
import {Tabs, Tab} from "components/tabs/index.d";
import EmployeeAvatar from "components/common/employeeAvatar";
import Loaders from "components/loaders";
import {
  getEmployeeDashboardTeamMembers,
  getEmployeeDashboardOnLeave,
  getEmployeeDashboardAbsent,
  getEmployeeDashboardBirthDays,
} from "scenes/employee/action";
import {useDispatch} from "react-redux";

enum MyTeamTab {
  TeamMembers = "Team Members",
  OnLeave = "On Leave",
  Absent = "Absent",
  BirthDays = "Birth Days",
}

const MyTeam = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    activeTab: MyTeamTab.TeamMembers,
    activeCard: "",
    currentCard: {},
  });
  useEffect(() => {
    state.activeTab && handleTabChange(state.activeTab);
  }, [state.activeTab]);

  const [tabData, setTabData] = useState({
    TeamMembers: null,
    OnLeave: null,
    Birthdays: null,
    Absent: null,
    items: [],
  });
  const onTabClick = async (item) => {
    setState({...state, activeTab: item});
  };

  const fetchData = async (name, cb) => {
    const newData = tabData[name];
    if (newData === null) {
      setIsLoading(true);
      const data = await dispatch(cb?.());
      setTabData({...tabData, [name]: data, items: data});
      setIsLoading(false);
    } else {
      setTabData({...tabData, items: newData});
    }
  };
  const handleTabChange = async (tab) => {
    const {TeamMembers, OnLeave} = tabData;

    switch (tab) {
      case MyTeamTab.TeamMembers:
        fetchData("TeamMembers", getEmployeeDashboardTeamMembers);
        break;
      case MyTeamTab.OnLeave:
        fetchData("OnLeave", getEmployeeDashboardOnLeave);
        break;
      case MyTeamTab.Absent:
        fetchData("Absent", getEmployeeDashboardAbsent);
        break;
      case MyTeamTab.BirthDays:
        fetchData("Birthdays", getEmployeeDashboardBirthDays);
        break;

      default:
        break;
    }
  };
  const _renderTabList = () => {
    const {TeamMembers, OnLeave, Birthdays, Absent} = tabData;

    const TeamTab = (name, length) => (
      <span className="team-block__tablist-context">
        <span>{name}</span>
        <span className={`badge`}>{length}</span>
      </span>
    );

    const countItems = (items) => {
      if (items === null) return "-";
      return items?.length;
    };

    const tabList = [{name: MyTeamTab.TeamMembers}];
    const isActive = (tabName) => state.activeTab === tabName;
    return (
      <Tabs>
        {/* {tabList.map((t, index) => (
          <Tab
            key={index}
            onClick={() => onTabClick(t.name)}
            label={TeamTab("My Managers", countItems(TeamMembers))}
            id="My Managers"
            name="Team Members"
            isActive={isActive(t.name)}
          >
            {_renderTabImage()}
          </Tab>
        ))} */}
        <Tab
          onClick={() => onTabClick(MyTeamTab.TeamMembers)}
          label={TeamTab("My Managers", countItems(TeamMembers))}
          id="My Managers"
          name="Team Members"
          isActive={isActive(MyTeamTab.TeamMembers)}
        >
          {_renderTabImage()}
        </Tab>
        <Tab
          onClick={() => onTabClick(MyTeamTab.OnLeave)}
          label={TeamTab("On Leave", countItems(OnLeave))}
          id="On Leave"
          name="On Leave"
          isActive={isActive(MyTeamTab.OnLeave)}
        >
          {_renderTabImage()}
        </Tab>
        <Tab
          onClick={() => onTabClick(MyTeamTab.Absent)}
          label={TeamTab("Absent", countItems(Absent))}
          id="Absent"
          name="Absent"
          isActive={isActive(MyTeamTab.Absent)}
        >
          {_renderTabImage()}
        </Tab>
        <Tab
          onClick={() => onTabClick(MyTeamTab.BirthDays)}
          label={TeamTab("Birthdays", countItems(Birthdays))}
          id="Birthdays"
          name="Birthdays"
          isActive={isActive(MyTeamTab.BirthDays)}
        >
          {_renderTabImage()}
        </Tab>
      </Tabs>
    );
  };

  const _renderTabImage = () => {
    const {items} = tabData;
    if (isLoading)
      return (
        <Loaders name="card" type="skeleton" noRows={2} cardType="block" loading={true} />
      );

    if (isEmpty(items) || items?.length == 0) {
      return <span className="no-found">No Data</span>;
    }

    return (
      <>
        <ul className="team-images-list">
          {items?.map((person, index) => {
            return (
              <li key={index + Math.random()} className="team-images-list__item">
                <div className="team-image">
                  <EmployeeAvatar employee={person} />
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <div className="team">
      <h3 className="bloch__title">My Team</h3>
      <div className="team-block">
        <ConfigProvider colors={["#ffefee", "#e6f1ff"]}>
          <div className="team-block__tab">{_renderTabList()}</div>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default MyTeam;
