export const initialHeader = [
  {
    field: "EmployeeName",
    headerName: "Name",
  },
  {
    field: "OverTime",
    headerName: "Overtime",
  },
  {cellRenderer: "DateCommon", field: "RequestedOn", headerName: "Date"},
  {field: "StartTime", headerName: "Requested Time"},
  {
    field: "EndTime",
    headerName: "Recorded Time",
  },
  {
    field: "RequestedTimeInMin",
    headerName: "Requested (mins)",
  },
  {field: "ApprovedTimeInMIn", headerName: "Approved (mins)"},
  {field: "Reason", headerName: "Reason"},
  {
    cellRenderer: "Status",
    field: "Status",
    headerName: "Status",
  },
];
