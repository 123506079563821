import React, {useState, useEffect} from "react";
import thumbsDown from "assets/images/icons/thumbsDownColor.svg";
import thumbsUp from "assets/images/icons/thumbsUpColor.svg";
import comment from "assets/images/icons/commentBlue.svg";
import man from "assets/images/man.png";
import Avatar from "react-avatar";
import {FaTrash, FaEdit, FaFileInvoice} from "react-icons/fa";
import Icons from "components/icons";
import FormGroup from "components/form";
import Button from "components/button";
import Modal from "components/modal";
import {connect} from "react-redux";
import Form from "components/form";
import classnames from "classnames";
import IncomeHeadOptions from "./form/incomeHeadOptions";
import isEmpty from "isEmpty";
import {InputValidator} from "helpers/inputValidator";
import {MdAdd} from "react-icons/md";
import SalaryTemplateForm from "./form";
import {
  DisplayClientSideMessage,
  getLevels,
  GetPayrollIncomeHeads,
} from "actions/commonAction";
import {
  GetDistributedSalary,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate,
} from "./salaryTemplateAction";
import "./style.scss";

const initialState = {
  date: "",
  open: false,
  templateName: null,
  employeeLevel: null,
  templateOptions: [
    {
      label: "1",
      value: "1001",
    },
    {
      label: "2",
      value: "2001",
    },
    {
      label: "3",
      value: "3001",
    },
    {
      label: "4",
      value: "4001",
    },
  ],
  templateOption: [
    {
      label: "",
      value: "",
    },
  ],
  isApplicableIncome: false,
  salaryDistribution: [],
  grossPay: null,
  totalPay: null,
  gross: true,
  basic: false,
  levelLists: null,
  incomeHeadOpen: false,
  payrollIncomeHeads: [],
  openNewTemplate: false,
  templateLists: null,
  errors: null,
  editView: false,
  templateId: 0,
  totalPercentage: 0,
  editViewData: {
    templateName: null,
    gross: null,
    grossPay: null,
    basic: null,
    employeeLevel: null,
    templateId: null,
    salaryDistribution: null,
  },
};
interface IAppProps {
  employeeId?: any;
  employeeFamiliesDetail?: any;
  getLevels: any;
  GetPayrollIncomeHeads: any;
  levelList: any;
  payrollIncomeHeads: any;
  GetDistributedSalary: any;
  DisplayClientSideMessage: any;
  distributedIncomeByAmount: any;
  AddSalaryTemplate: any;
  saveSalaryTemplate: any;
  GetAllSalaryTemplate: any;
  salaryTemplateList: any;
  GetSalaryTemplateDetails: any;
  salaryTemplateDetail: any;
  UpdateSalaryTemplate: any;
  DeleteSalaryTemplate: any;
}

interface State {
  date: any;
  open: any;
  templateName: any;
  employeeLevel: any;
  templateOptions: any;
  templateOption: any;
  isApplicableIncome: any;
  salaryDistribution: any;
  grossPay: any;
  totalPay: any;
  gross: any;
  basic: any;
  levelLists?: any;
  incomeHeadOpen?: any;
  payrollIncomeHeads: any;
  openNewTemplate: boolean;
  templateLists: any;
  errors?: any;
  editView?: boolean;
  templateId?: any;
  totalPercentage: any;
  editViewData?: any;
}

class SalaryTemplate extends React.Component<IAppProps, State> {
  state = initialState;

  componentDidMount = async () => {
    await this.props.getLevels();
    await this.props.GetPayrollIncomeHeads();
    await this.props.GetAllSalaryTemplate();
    const {levelList, payrollIncomeHeads, salaryTemplateList} = this.props;
    let levelLists = [];
    let payrollIncomeHead = [];
    if (!isEmpty(levelList)) {
      levelList &&
        levelList.forEach((x) => {
          levelLists.push({value: x.Value, label: x.Text});
        });
    }
    if (!isEmpty(payrollIncomeHeads)) {
      payrollIncomeHead = payrollIncomeHeads.map((p) => {
        return {...p, checked: true};
      });
    }
    this.setState({levelLists, payrollIncomeHeads: payrollIncomeHead});
  };
  componentDidUpdate(prevProps, prevState) {
    const {levelList, payrollIncomeHeads, salaryTemplateList} = this.props;
    if (levelList && levelList !== prevProps.levelList) {
      let levelLists = levelList.map((x) => {
        return {value: x.Value, label: x.Text};
      });
      this.setState({levelLists});
    }
    if (payrollIncomeHeads && payrollIncomeHeads !== prevProps.payrollIncomeHeads) {
      let payrollIncomeHead = [];
      payrollIncomeHead = payrollIncomeHeads.map((p) => {
        return {...p, checked: false};
      });
      this.setState({payrollIncomeHeads: payrollIncomeHead}, () =>
        console.log(this.state)
      );
    }
    // if(salaryTemplateList && salaryTemplateList !== prevProps.salaryTemplateList){
    //   this.setState({ templateLists : salaryTemplateList },()=>console.log(this.state))
    // }
  }

  handleFieldChange = (name, value) => {
    if (name == "gross" || name == "basic") {
      let alterField = name == "gross" ? "basic" : "gross";
      this.setState({[name]: true, [alterField]: false} as Pick<State, keyof State>);
    } else {
      this.setState({[name]: value} as Pick<State, keyof State>);
    }
  };

  // addApplicableIcomes = (name, selected) => {
  //   let { templateOption, salaryDistribution } = this.state;

  //   if (templateOption.find((x) => x.value === selected.value)) {
  //     templateOption = templateOption.filter((x) => x.value !== selected.value);
  //     if (salaryDistribution.find(s => s.id == selected.id)) {
  //       salaryDistribution = salaryDistribution.filter(s => s.id !== selected.id)
  //     }
  //   } else {
  //     // if (selected.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE)
  //     //   templateOption = templateOption.filter(
  //     //     (x) => x.value === EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE
  //     //   );
  //     // else if (selected.value !== EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE)
  //     //   templateOption = templateOption.filter(
  //     //     (x) => x.value !== EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE
  //     //   );
  //     salaryDistribution.pop();
  //     salaryDistribution.push({
  //       title: selected.label, type: selected.label, percentage: 0, amount: 0, id: selected.value
  //     });
  //     salaryDistribution.push(salaryDistributions[3])
  //     templateOption.push(selected);
  //   }
  //   this.setState({ templateOption, salaryDistribution});
  //   // this.setState({ [name]: value } as Pick<State, keyof State>);
  // }

  onIncomeHeadChecked = (e, selected) => {
    const {payrollIncomeHeads} = this.state;
    let selectedSalary = payrollIncomeHeads.find((s) => s.IncomeId == selected.IncomeId);
    selectedSalary["checked"] = e.target.checked;
    this.setState({payrollIncomeHeads});
  };

  addIncomeHeads = async () => {
    const {salaryDistribution, payrollIncomeHeads, editView, templateId} = this.state;
    let salaryArray = [];
    let totalPercentage = 0;
    await payrollIncomeHeads.forEach((pay) => {
      if (pay.checked == true) {
        let ratePercent = pay.RatePercent ? pay.RatePercent : 0;
        let amount = 0;
        if (editView) {
          let selectedSalaryDist = salaryDistribution.find(
            (s) => s.incomeId == pay.IncomeId
          );
          if (selectedSalaryDist) {
            ratePercent = selectedSalaryDist.percentage;
            amount = selectedSalaryDist.amount;
          }
        }

        salaryArray.push({
          abbreviation: pay.Abbreviation,
          amount,
          calculation: pay.Calculation,
          incomeId: pay.IncomeId,
          isBasicIncome: pay.IsBasicIncome,
          isPFIncome: pay.IsPFIncome,
          isSSFType: pay.IsSSFType,
          percentage: ratePercent,
          templateRefId: templateId,
          title: pay.Title,
          type: pay.Type,
        });
        totalPercentage = totalPercentage + (ratePercent ? parseFloat(ratePercent) : 0);
      }
    });
    this.setState({
      salaryDistribution: salaryArray,
      incomeHeadOpen: false,
      totalPercentage,
    });
  };

  handleFormSubmit = async () => {
    let {
      templateOptions,
      templateOption,
      grossPay,
      salaryDistribution,
      gross,
      basic,
      totalPay,
      templateName,
      employeeLevel,
      levelLists,
      incomeHeadOpen,
      payrollIncomeHeads,
      openNewTemplate,
      editView,
      templateId,
    } = this.state;

    let errors = await InputValidator(document, this.state);
    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }
    salaryDistribution = salaryDistribution.map((sal) => {
      return {
        ...sal,
        percentage: sal.percentages,
      };
    });

    let templateData = {
      salaryTemplateDto: {
        templateId: editView ? templateId : 0,
        templateName: templateName,
        levelId: employeeLevel.value,
        isOnGrossDistribution: gross,
        grossPay: grossPay,
        basicPay: grossPay,
      },
      salaryTemplateDetailDto: salaryDistribution,
    };
    if (editView) await this.props.UpdateSalaryTemplate(templateData);
    else await this.props.AddSalaryTemplate(templateData);
    const {saveSalaryTemplate} = this.props;
    if (saveSalaryTemplate) {
      this.setState({openNewTemplate: false});
      this.props.GetAllSalaryTemplate();
      this.setState({
        errors: {},
      });
    }
  };

  editSelectedTemplate = async (templateId) => {
    await this.props.GetSalaryTemplateDetails(templateId);
    let {salaryTemplateDetail} = this.props;
    let {levelLists, payrollIncomeHeads} = this.state;
    if (salaryTemplateDetail) {
      const {SalaryTemplateDto, SalaryTemplateDetailDto} = salaryTemplateDetail;
      let employeeLevel =
        levelLists && levelLists.find((lvl) => lvl.value == SalaryTemplateDto.LevelId);
      let templateDetail = {
        templateName: SalaryTemplateDto.TemplateName,
        gross: SalaryTemplateDto.IsOnGrossDistribution,
        grossPay: SalaryTemplateDto.GrossPay,
        basic: !SalaryTemplateDto.IsOnGrossDistribution,
        employeeLevel,
        templateId: SalaryTemplateDto.TemplateId,
        salaryDistribution: null,
        totalPercentage: 100,
        totalPay: 0,
      };
      let salaryDistribution = [];
      let totalPay = 0;

      if (SalaryTemplateDetailDto) {
        SalaryTemplateDetailDto.forEach((salDetl, i) => {
          let salectedIncomeHead = payrollIncomeHeads.find(
            (s) => s.IncomeId == salDetl.IncomeId
          );
          if (salectedIncomeHead) salectedIncomeHead["checked"] = true;
          // calculate percent based on the basic
          let calcPercent = salDetl.Percentage;
          if (SalaryTemplateDto.IsOnGrossDistribution && i != 0) {
            calcPercent =
              (salDetl.Percentage * SalaryTemplateDetailDto[0].Percentage) / 100;
          }
          salaryDistribution.push({
            abbreviation: salDetl?.Abbreviation || "",
            amount: parseFloat(salDetl?.Amount).toFixed(2) || "0",
            calculation: salDetl?.Calculation || "",
            incomeId: salDetl.IncomeId,
            isBasicIncome: salDetl?.IsBasicIncome || false,
            isPFIncome: salDetl?.IsPFIncome || false,
            isSSFType: salDetl?.IsSSFType || false,
            parentIncomeId: salectedIncomeHead?.ParentIncomeId || 0,
            percentage: parseFloat(salectedIncomeHead?.RatePercent) || 0,
            percentages: parseFloat(salDetl?.Percentage) || 0,
            templateRefId: SalaryTemplateDto.TemplateId,
            title: salDetl?.Title || "",
            type: salDetl?.Type || "",
          });
          totalPay = totalPay + (salDetl?.Amount || 0);
        });
        if (SalaryTemplateDto?.IsOnGrossDistribution) {
          totalPay = SalaryTemplateDto?.GrossPay || 0;
        }
        templateDetail.salaryDistribution = salaryDistribution;
        templateDetail.totalPay = totalPay;
        // this.setState({ editViewData:  })
        this.setState({
          ...templateDetail,
          salaryDistribution,
          openNewTemplate: true,
          editView: true,
          totalPercentage: 100,
          totalPay,
          editViewData: templateDetail,
          payrollIncomeHeads,
        });
      }
    }
  };

  deleteSelectedTemplate = async (templateId) => {
    await this.props.DeleteSalaryTemplate(templateId);
    this.props.GetAllSalaryTemplate();
  };

  currencyCommaSeperator = (num) => {
    if (isNaN(num)) return "0";
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  render() {
    const {
      templateOptions,
      templateOption,
      grossPay,
      salaryDistribution,
      gross,
      basic,
      totalPay,
      templateName,
      employeeLevel,
      levelLists,
      incomeHeadOpen,
      payrollIncomeHeads,
      openNewTemplate,
      templateLists,
      errors,
      editView,
      totalPercentage,
      editViewData,
    } = this.state;

    const {salaryTemplateList} = this.props;

    return (
      <div className="salaryTemplate">
        <div className="tertiary-title">
          <span>Salary Template</span>
        </div>
        <div className="salaryTemplate-container">
          <div className="salaryTemplate-header mb-10">
            <Button
              title="Add New"
              icon={<Icons name="Add" />}
              leftIcon={<MdAdd />}
              buttonType="icon-button"
              bg="white"
              onClick={() =>
                this.setState(
                  {
                    ...initialState,
                    levelLists: this.state.levelLists,
                    payrollIncomeHeads: this.state.payrollIncomeHeads,
                  },
                  () => this.setState({openNewTemplate: true})
                )
              }
            />
          </div>
          <div className="salaryTemplate-table">
            <div className="salaryTemplate-table-mainheader">
              <span className="salaryTemplate-table-header">Level</span>
              <span className="salaryTemplate-table-header">Template Name</span>
              <span className="salaryTemplate-table-header"></span>
            </div>
            <div className="salaryTemplate-table-mainbody">
              {salaryTemplateList &&
                salaryTemplateList.map((tem) => (
                  <>
                    <span className="salaryTemplate-table-body">{tem.level}</span>
                    <span className="salaryTemplate-table-body">{tem.label}</span>
                    <span className="salaryTemplate-table-body flex">
                      <span
                        className="salaryTemplate-handover"
                        onClick={() => this.editSelectedTemplate(tem.value)}
                      >
                        <FaEdit />
                      </span>
                      <span
                        className="salaryTemplate-handover"
                        onClick={() => this.deleteSelectedTemplate(tem.value)}
                      >
                        <FaTrash />
                      </span>
                    </span>
                  </>
                ))}
            </div>
          </div>
        </div>
        {openNewTemplate ? (
          <Modal
            open={openNewTemplate}
            onModalClose={() => this.setState({openNewTemplate: false})}
            width="65%"
          >
            <SalaryTemplateForm
              // history={history}
              handleFormDisplay={this.handleFormSubmit}
              editView={editView}
              editViewData={editViewData}
              showForm={openNewTemplate}
              // formExists={true}
              closeForm={() => this.setState({openNewTemplate: false})}
              editedPayrollIncomeHeads={payrollIncomeHeads}
              // getDisciplinaryActionList={this.getDisciplinaryActionList}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeFamiliesDetail: state.employeeProfile.employeeFamiliesDetail,
  levelList: state.commonReducer.levelList,
  payrollIncomeHeads: state.commonReducer.payrollIncomeHeads,
  distributedIncomeByAmount: state.salaryTemplateReducer.distributedIncomeByAmount,
  saveSalaryTemplate: state.salaryTemplateReducer.saveSalaryTemplate,
  salaryTemplateList: state.salaryTemplateReducer.salaryTemplateList,
  salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
});

const mapDispatchToProps = {
  getLevels,
  GetPayrollIncomeHeads,
  GetDistributedSalary,
  DisplayClientSideMessage,
  AddSalaryTemplate,
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  UpdateSalaryTemplate,
  DeleteSalaryTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryTemplate);
