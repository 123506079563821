import {loginUser, logoutUser} from "actions/authAction";
import {GetCompanyInformation} from "actions/commonAction";
import React, {Component} from "react";
import {connect} from "react-redux";
import HeaderTop from "./headerTop";
import Navigation from "./navigation";
import "./styles.scss";

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: null,
      totalRequestCount: 0,
    };
    this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
  }
  async componentDidMount() {
    // await this.props.GetCompanyInformation();
    this.updateCompanyInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    const {companyInformation, employeeDashboard} = this.props;
    if (prevProps?.employeeDashboard !== employeeDashboard) {
      const {RequestCount} = employeeDashboard;
      const totalRequestCount = RequestCount?.reduce(
        (a, b) => a + (parseInt(b["Total"]) || 0),
        0
      );
      this.setState({totalRequestCount});
    }
    if (prevProps?.companyInformation !== companyInformation) {
      if (companyInformation) {
        this.updateCompanyInfo();
      }
    }
  }
  updateCompanyInfo() {
    const {companyInformation} = this.props;
    let companyInfo = null;
    if (companyInformation) {
      const logoExtension =
        companyInformation.CompanyLogo && companyInformation.CompanyLogo.split(".")[1];
      let companyLogo = null;
      if (logoExtension) {
        companyLogo = companyInformation.CompanyLogo;
      } else {
        companyLogo = companyInformation.CompanyLogo + ".png";
      }

      companyInfo = {
        companyGuIdId: companyInformation.ID,
        companyName: companyInformation.Name,
        address: companyInformation.Address,
        countryId: companyInformation.CountryId,
        currencyCode: companyInformation.CurrencyCode,
        email: companyInformation.Email,
        logo: companyLogo,
      };
      this.setState({companyInfo: companyInfo});
    }
  }

  render() {
    const {companyInfo, totalRequestCount} = this.state;
    const {informationalVersion} = this.props;

    return (
      <>
        <header className="headers">
          <HeaderTop
            companyInfo={companyInfo}
            auth={this.props.auth}
            adminEmployee={this.props.adminEmployee}
            loginUser={this.props.loginUser}
            logoutUser={this.props.logoutUser}
            totalRequestCount={totalRequestCount}
            informationalVersion={informationalVersion}
          />
          <Navigation />
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyInformation: state.commonReducer.companyInformation,
  user: state.auth.user,
  auth: state.auth,
  baseUrl: state.commonReducer.baseUrl,
  activeFeatureFlags: state.permissionReducer.activeFeatureFlags,
  employeeDashboard: state.employeeReducer.employeeDashboard,
  informationalVersion: state.commonReducer.informationalVersion,
});

const mapDispatchToProps = {
  GetCompanyInformation,
  logoutUser,
  loginUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
