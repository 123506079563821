import React, {useState} from "react";
import Button from "components/button";
import "./styles.scss";
import {
  getCourseList,
  getCourseCategories,
  updateCourseStatus,
  getCourseEnrollment,
  getCourseNavigationPolicies,
  getShowCoursesTo,
} from "../helpers/action";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import RouteEnum from "constants/routeEnum";
import {Link, useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import AssignCourse from "../courseAssignment/assign";
import {CourseStatus} from "../helpers/enums";
import CourseFilter from "./courseFilter";
import CourseManagementTags from "./courseManagementTags";
import CourseTable from "./courseTable";

const initialFilters = {
  pageIndex: 0,
  pageSize: 10,
  courseName: "",
  categoryId: null,
  createdOn: null,
  openAssign: false,
  selectedCourses: [],
  status: null,
  isStatusDropDisabled: false,
  activeTab: -1,
};

let preActiveTab = -1;
let prePage = 0;

function CourseManagement(props) {
  const [state, setState] = useState(initialFilters);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const method = useForm();
  const {courseCategories} = useSelector((state: any) => state.lmsReducer);

  useEffect(() => {
    (async () => {
      const apis = [
        dispatch(getCourseCategories()),
        dispatch(getCourseEnrollment()),
        dispatch(getCourseNavigationPolicies()),
        dispatch(getShowCoursesTo()),
      ];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    fetchCourses(state);
  }, []);

  const handleRowChange = (num) => {
    setState((prev) => {
      let nextState = {
        ...prev,
        ["pageSize"]: num,
      };
      fetchCourses(nextState);
      return nextState;
    });
  };
  const handleIndexChange = (num) => {
    prePage = num;
    setState((prev) => {
      let nextState = {
        ...prev,
        ["pageIndex"]: num,
      };
      fetchCourses(nextState);
      return nextState;
    });
  };
  const fetchCourses = (data) => {
    const {pageIndex, pageSize, courseName, categoryId, createdOn, status} = data;
    let filter = {
      pageIndex,
      pageSize,
      courseName,
      categoryId: categoryId?.value,
      status: status?.value,
      createdOn: createdOn?.startDate,
      createdToDate: createdOn?.endDate,
    };

    dispatch(getCourseList({...state, ...filter}));
  };

  const onSearch = (data) => {
    fetchCourses(data);
  };

  const onCourseStatus = (id, pageIndex = 0) => {
    const isStatusDropDisabled =
      id === CourseStatus.Unpublish ||
      id === CourseStatus.Publish ||
      id === CourseStatus.Discontinue;
    preActiveTab = id;
    prePage = 0;
    setState((prev) => {
      let nextState = {
        ...prev,
        categoryId: null,
        courseName: "",
        pageIndex: pageIndex,
        createdOn: null,
        status: {value: id},
        isStatusDropDisabled,
        activeTab: id,
      };

      fetchCourses(nextState);
      return nextState;
    });
  };
  const handleStatusChange = () => onCourseStatus(preActiveTab, prePage);

  const handleAssignCourseButtonClick = (courses) =>
    setState({...state, openAssign: true, selectedCourses: courses});

  return (
    <div className="container-wrap">
      {/* <div className="course-management"> */}
      <div className="course-management__header">
        <div className="course-management__title">Course Management</div>
        <Link to={RouteEnum.lmsCourseAdd} className="btn btn-primary ">
          Add Course
        </Link>
      </div>
      <CourseManagementTags activeTab={state.activeTab} onCourseStatus={onCourseStatus} />

      <CourseFilter
        onSearch={onSearch}
        filter={state}
        setFilter={setState}
        courseCategories={courseCategories}
      />
      <CourseTable
        handleAssignCourseButtonClick={handleAssignCourseButtonClick}
        handleStatusChange={handleStatusChange}
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        pageIndex={state.pageIndex}
        pageSize={state.pageSize}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />

      <AssignCourse
        courses={state.selectedCourses}
        open={state.openAssign}
        onModalClose={() => setState({...state, openAssign: false})}
        onSuccessSubmitting={() => fetchCourses(state)}
      />
    </div>
  );
}

export default CourseManagement;
