import Http from "src/utils/http/http";
import axios from "helpers/axiosinstance";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import {
  ALLOWANCE_EXPENSE_LOADING,
  GET_ALLOWANCE_EXPENSE,
  ADD_ALLOWANCE_EXPENSE,
  ADD_MESSAGE,
  LOAD_ALLOWANCE_EXPENSE,
  SET_EDIT_MODE,
  EDIT_ALLOWANCE_EXPENSE,
  DELETE_ALLOWANCE_EXPENSE,
  CLEAR_ERRORS,
} from "actions/types";

// Add Allowance-Expense
export const addAllowanceExpense = (data, handleModalShow) => async (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  const res = Http.post(environment.api.v1.travel.getAllowanceExpense, data);
  const actionConfig = {
    dispatch,
    actionType: ADD_ALLOWANCE_EXPENSE,
    effect: res,
    isLoading: true,
  };
  handleCreateThunkEffect(actionConfig, handleModalShow);
};

// Edit Allowance-Expense
export const editAllowanceExpense = (data, handleModalShow) => (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  const res = Http.put(environment.api.v1.travel.getAllowanceExpense, data);
  const actionConfig = {
    dispatch,
    actionType: EDIT_ALLOWANCE_EXPENSE,
    effect: res,
    isLoading: true,
  };
  handleCreateThunkEffect(actionConfig, handleModalShow);
};

// Get Allowance-Expense
export const getAllowanceExpense = () => (dispatch) => {
  axios
    .get(environment.api.v1.travel.getAllowanceExpense)
    .then((res) => {
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ALLOWANCE_EXPENSE,
        payload: null,
      })
    );
};

// Delete Allowance-Expense
export const deleteAllowanceExpense = (id, handleModalShow) => async (dispatch) => {
  dispatch(setAllowanceExpenseLoading(true));
  const res = Http.delete(`${environment.api.v1.travel.getAllowanceExpense}?id=${id}`);
  const actionConfig = {
    dispatch,
    actionType: DELETE_ALLOWANCE_EXPENSE,
    effect: res,
    type: "delete",
    id,
    isLoading: true,
  };
  const resp = await Action.createThunkEffect(actionConfig);
  if (resp && resp?.Status) {
    handleModalShow(false, "normal");
  }
};

export const setEditMode = () => (dispatch) => {
  dispatch({
    type: SET_EDIT_MODE,
  });
};
export const loadAllowanceExpense = (data) => (dispatch) => {
  dispatch({
    type: LOAD_ALLOWANCE_EXPENSE,
    payload: data,
  });
};
export const toastMessage = (res) => {
  return {
    type: ADD_MESSAGE,
    payload: {
      type: res.data.MessageType,
      message: res.data.Message,
      position: "bottom-right",
    },
  };
};

// Set loading state
export const setAllowanceExpenseLoading = (data) => {
  return {
    type: ALLOWANCE_EXPENSE_LOADING,
    payload: data,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// Handle Create Thunk Effect
export const handleCreateThunkEffect = async (actionConfig, handleModalShow) => {
  const resp = await Action.createThunkEffect(actionConfig);
  if (resp && resp?.Status) {
    handleModalShow(false, "normal");
  }
};
