import React from 'react';
import isEmpty from 'isEmpty';

const initialData = [
    [
        { head:'Job Titles', value: '-' },
        { head:'Job Family', value: '-' },
        { head:'Departments', value: '-' },
        { head:'Job Type', value: '-' },
    ],
    [
        { head:'Job Code', value: '-' },
        { head:'Job Role', value: '-' },
        { head:'Branch', value: '-' },
        { head:'Reports to', value: '-' },
    ]
]
const JobTables = (props) =>{
    let EmployeeDetails = props.jobDesc;
    let {employeeProfile}=props;
    let data = !isEmpty(EmployeeDetails) ? filterData(EmployeeDetails,employeeProfile) : initialData;
 
    return(
        <div className="jobdesc-tables">
            <div className="jobdesc-tables-container">
                {
                    data.map(JobTable)
                }
            </div>
        </div>
    )
}

const filterData = (jobData,employeeProfile) => {
    if(!isEmpty(jobData)) {
        const { JobTitle,JobType,JobCode, LevelName,LevelGroupName,ReportsToJobTitle } = jobData;
        const { Department,Branch,EmployeeStatus }=employeeProfile?.EmployeeProfileHeader || { Department: "",Branch: "",EmployeeStatus: ""};
        return [[
                { head:'Job Titles', value: JobTitle || '-' },
                { head:'Job Family', value: LevelName  || '-' },
                { head:'Departments', value: Department  || '-' },
                { head:'Job Type', value: EmployeeStatus || '-' },
            ],
            [
                { head:'Job Code', value: JobCode  || '-' },
                { head:'Job Role', value: LevelGroupName  || '-' },
                { head:'Branch', value: Branch  || '-' },
                { head:'Reports to', value: ReportsToJobTitle  || '-' },
            ]];
    }
}

const JobTable = (data,index) =>{
    return(
        <div key={index + Math.random()} className="jobdesc-table">
            {
                data.map(JobRow)
            }
        </div>
    )
}

const JobRow = ({head,value},index) =>{
    return(
        <div key={index + head} className="jobdesc-table__row">
            <div className="jobdesc-table__col">
                <span>{head}</span>
            </div>
            <div className="jobdesc-table__col">
                <span className="val">
                    {value}
                </span>
            </div>
        </div>
    )
}

export default JobTables;
