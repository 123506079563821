import React, {useState, useEffect} from "react";
import Button from "components/button";
import Table from "components/customTable";
import {MdAdd} from "react-icons/md";
import Icons from "components/icons";
import { MdDelete, MdEdit, MdViewComfy } from "react-icons/md";
import moment from "moment";
import {FaRegFilePdf, FaEye} from "react-icons/fa";
import LoanDataTable from "../../common/loanDataTable";
import Modal from "components/modal";
import {useDispatch, useSelector} from "react-redux";
import {getUploadPath, UserByStore} from "constants/userDetails";
import NewLoanRecord from "./newOutstandingLoan";
import isEmpty from 'isEmpty';
import {
  GenerateIncomeDeductionList,
  getOutstandingLoans,
  deleteOutstandingLoan,
} from "../../helpers/action";
function PayVerification(props) {
  // const { data } = loanDetails;
  const dispatch = useDispatch();
  const [deductions, setDeductions] = useState([]);
  const [incomes, setIncomes] = useState([]); 
  const [ProposedLoanInstallment, setProposedLoanInstallment] = useState(0);
  const [GrossSalary, setGrossSalary] = useState(0);
  const [ShowNewRecordForm, setShowNewRecordForm] = useState(false);
  const [EditLoanRecordData, setEditLoanRecordData] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [LoanData, setLoanData] = useState({
    LoanName: "",
    Outstanding: "",
    AsOnDate: "",
    MonthlyDeduction: "",
    CompletesOn: "",

  });
  const [outstandingLoan, setOutstandingLoan] = useState([]);
  
  const [
    PercentageOfTotalDeductionOnGrossSalary,
    setPercentageOfTotalDeductionOnGrossSalary,
  ] = useState(0);
  const {
    consumerLoanPurposeList,
    loanSettingByType,
    loanRequesterInformation,
    loanPaymentSchedules,
    incomeDeductionList,
    loanApproverList,
    savedConsumerLoanRequest,
    consumerLoanDetails,
    outstandingLoans,
  } = useSelector((state) => state.sbiLoanReducer);
  useEffect(() => {
    const User = UserByStore();
    const {EmployeeId, LoanType, LoanId} = props.location.state;
    (async () => {
      let incomeDeductionData = {
        EmployeeId,
        LoanId,
        LoanType,
      };
      let apis = [
        dispatch(GenerateIncomeDeductionList(incomeDeductionData)),
        dispatch(getOutstandingLoans({EmployeeId, LoanType, LoanId})),
      ];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(incomeDeductionList)) {
      const {Incomes, Deductions} = incomeDeductionList;

      // CALCULATE INCOME DISTRIBUTION
      let incomes =
        Incomes &&
        Incomes.map((income) => {
          if (income.Alias == "-1") setGrossSalary(income.Amount);
          return {
            colorCode:
              income.Alias == "-1"
                ? "gross"
                : income.Alias == "-2"
                ? "secondary"
                : "primary",
            title: income.Title,
            amount: formatNumber(income.Amount) || "-",
            ticker: income.Alias == "-2",
          };
        });
      setIncomes(incomes);
      calculateDeductions(Deductions, 0);
    }
    if (!isEmpty(outstandingLoans)) {
      setOutstandingLoan(outstandingLoans);
      calculateTotalRow(null, null, outstandingLoans);
      // outstandingLoans
    }
  }, [incomeDeductionList, outstandingLoans]);
  const calculateDeductions = (Deductions, loanInstall) => {
    // CALCULATE DEDUCTION DISTRIBUTION
    let deductions =
      Deductions &&
      Deductions.map((deduction, i) => {
        let Amount = deduction.Amount;
        // let subTotal = Deductions.find((d) => d.Alias == "-3");
        // let totalDeduction = !isEmpty(loanInstall)
        //   ? loanInstall + subTotal.Amount
        //   : ProposedLoanInstallment + subTotal.Amount;
        // if (deduction.Alias == "-4") {
        //   Amount = !isEmpty(loanInstall) ? loanInstall : ProposedLoanInstallment;
        // } else if (deduction.Alias == "-5") {
        //   Amount = totalDeduction;
        // } else if (deduction.Alias == "-6") {
        //   Amount =
        //     GrossSalary > 0 && totalDeduction > 0
        //       ? `${parseFloat(((totalDeduction / GrossSalary) * 100).toString()).toFixed(
        //           2
        //         )}`
        //       : 0;
        //   setPercentageOfTotalDeductionOnGrossSalary(Amount);
        // }
        let computableRow =
          deduction.Alias == "-4" || deduction.Alias == "-5" || deduction.Alias == "-6";
        return {
          colorCode: computableRow ? "secondary" : "primary",
          title: deduction.Title,
          amount:
            deduction.Alias == "-6"
              ? `${formatNumber(Amount) || "-"} %`
              : formatNumber(Amount) || "-",
          ticker: computableRow,
        };
      });
    setDeductions(deductions);
  };

  const ButtonGroup = (prop) => {
    if (
      prop.row.original.LoanName == "Total" ||
      props.location.state.Status == -1 ||
      props.location.state.Status == 10
    )
      return null;
    return (
      <div className="loan-appliedloan__button">
        <Button
          buttonType="icon-button"
          leftIcon={<Icons name="Edit" />}
          onClick={() => prop.viewOutstandingDetails(prop.row.original, "view")}
          roundValue={50}
          bg="secondary-dark"
        />
        <Button
          buttonType="icon-button"
          leftIcon={<MdDelete />}
          onClick={() => prop.viewOutstandingDetails(prop.row.original, "delete")}
          roundValue={50}
          bg="secondary-dark"
        />
      </div>
    );
  };
  
  const formatNumber = (params) => {
    if (!params) return null
    if (params) {
      params = Number.parseFloat(params).toFixed(2);
      return new Intl.NumberFormat("en-IN").format(params);
    }
    
    // return (
    //   Math.floor(params)
    //     .toString()
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ".00"
    // );
  };

  const formatDate = (date) => {
    return date ? moment(date).format("YYYY/MM/DD") : "";
  };
  const beforecolumns = [
    {
      Header: "Loan",
      accessor: "LoanName",
      width: 100,
    },
    {
      // Header: () => <div style={{ textAlign: "right" }}>Amount (NPR)</div>,
      Header: "Amount (NPR)",
      accessor: (row) => (
        <div style={{textAlign: "right"}}>
          {formatNumber(row.OutstandingAmount)}
        </div>
      ), // Access Field
      width: 170,
      minWidth: 80,
      maxWidth: 170,
      headerClassName: "text-right",
    },
    {
      Header: "As on",
      accessor: (row) => formatDate(row.AsOnDateEng), // Access Field
      width: 100,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      // Header: () => <div style={{ textAlign: "right" }}>Amount (NPR)</div>,
      Header: "Monthly Deduction",
      accessor: (row) => (
        <div style={{textAlign: "right"}}>
          {formatNumber(row.MonthlyDeductionAmount)}
        </div>
      ), // Access Field
      width: 160,
      minWidth: 80,
      maxWidth: 200,
      headerClassName: "text-right",
    },
    {
      Header: "Completes on",
      accessor: (row) => formatDate(row.CompletesOnDateEng), // Access Field
      width: 100,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      Header: "",
      accessor: "StatusNames", // Access Field
      width: 80,
      minWidth: 80,
      maxWidth: 500,
      Cell: (props) => (
        <ButtonGroup
          {...props}
          setOpen={setOpen}
          viewOutstandingDetails={viewOutstandingDetails}
        />
      ),
    },
  ];
  const setOpen = () => {
    
  }
  const viewOutstandingDetails = async(loan, option) => {
    if (option == 'view') {
      await setEditLoanRecordData(loan);
      setShowNewRecordForm(true);
    } else if (option == 'delete') {
      await setEditLoanRecordData(loan);
      setOpenConfirmation(true);
    }
  };
  const data = [
    {
      title: "Sharawan 20-10",
      amount: 10000,
      colorCode: "primary",
    },
    {
      title: "Bhadra 20-10",
      amount: 18000,
      colorCode: "secondary",
    },
    {
      title: "Bhadra 20-10",
      amount: 18000,
      colorCode: "gross",
      ticker: true,
    },
  ];
  const editOutstandingLoan = (flag) => {
    if (flag)
      setEditLoanRecordData(null)
    setShowNewRecordForm(flag);
  }
  // const addNewRecordToTable = async (data, addOrRemove) => {
  //   let newOutstandingLoan = [...outstandingLoan];
  //   if (addOrRemove == 'add') {
  //     newOutstandingLoan.push(data);
  //   } else {
  //     newOutstandingLoan = newOutstandingLoan.filter(loan => loan.Id != data.Id);
  //   }
  //   await setOutstandingLoan(newOutstandingLoan)
  //   calculateTotalRow();
  //   // setShowNewRecordForm(false);
  // };
  const calculateTotalRow = async (data, addOrRemove, outstandingLoans) => {
    let newOutstandingLoan = !isEmpty(outstandingLoans) ? outstandingLoans : [...outstandingLoan];
    // if (addOrRemove == "add") {
    //   newOutstandingLoan.push(data);
    // } else if (addOrRemove == "delete") {
    //   newOutstandingLoan = newOutstandingLoan.filter((loan) => loan.Id != data.Id);
    // }
    // let newOutstandingLoan = [...outstandingLoan];
    newOutstandingLoan = newOutstandingLoan.filter((loan) => loan.LoanName != "Total");
    let OutstandingAmount = 0;
    let MonthlyDeductionAmount = 0;
    if (!isEmpty(newOutstandingLoan)) {
      OutstandingAmount = newOutstandingLoan.reduce(
        (previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.OutstandingAmount),
        OutstandingAmount
      );
      MonthlyDeductionAmount = newOutstandingLoan.reduce(
        (previousValue, currentValue) =>
          parseInt(previousValue) + parseInt(currentValue.MonthlyDeductionAmount),
        MonthlyDeductionAmount
      );
      let totalRow = {
        LoanName: "Total",
        OutstandingAmount,
        AsOnDateEng: "",
        MonthlyDeductionAmount,
        CompletesOnDateEng: "",
      };
      newOutstandingLoan.push(totalRow);
    }
    setOutstandingLoan(newOutstandingLoan);
    setShowNewRecordForm(false);
    setOpenConfirmation(false);
    const { EmployeeId, LoanType, LoanId } = props.location.state;
    let incomeDeductionData = {
      EmployeeId,
      LoanId,
      LoanType,
    };
    if (addOrRemove == "add" || addOrRemove == "delete") {
      await dispatch(getOutstandingLoans({EmployeeId, LoanType, LoanId}));
      await dispatch(GenerateIncomeDeductionList(incomeDeductionData));
      // if (addOrRemove == "add")
    }
    // return newOutstandingLoan;
  };
  const deleteOutstandingLoanRecord = async () => {
    if (EditLoanRecordData) {
      let loanDeleted = await dispatch(deleteOutstandingLoan(EditLoanRecordData.Id));
      if (loanDeleted) {
        let newDeleted = [...outstandingLoan];
        newDeleted = newDeleted.filter(loan => loan.Id != EditLoanRecordData.Id);
        calculateTotalRow(EditLoanRecordData, "delete", newDeleted);
        setOpenConfirmation(false);
      }
    }
  }
  const {Status} = props.location.state;
  return (
    <div className="consumer-loan__presentdeduction consumer-loan__commonborder">
      {/* <GrossSalary /> */}
      <div className="mb-bg homeloan-view-employeedetails">
        {!isEmpty(incomes) && <LoanDataTable data={incomes} title="Gross Salary" />}
      </div>
      <div className="homeloan-view-employeedetails mb-sm">
        {!isEmpty(deductions) && (
          <LoanDataTable data={deductions} title="Present Deduction" />
        )}
      </div>
      {Status === -1 || Status === 10 ? null : (
        <div className="consumer-loan__addnewrecord mb-sm mt-md">
          <Button
            bg="primary"
            // icon={<MdAdd />}
            buttonType="icon-button"
            title="Add New Record"
            // isDisabled={loanDetails?.Status != 2}
            onClick={() => editOutstandingLoan(true)}
          />
        </div>
      )}
      <Modal
        type="confirm"
        func={() => deleteOutstandingLoanRecord()}
        open={openConfirmation}
        onModalClose={() => setOpenConfirmation(false)}
        title="Delete Outstanding Loan"
        okButtonTitle="Delete"
        modalMessage="Are you sure you want to delete?<br/> You will not be able to restore the deleted item."
      />
      <Modal
        open={ShowNewRecordForm}
        onModalClose={() => editOutstandingLoan(false)}
        // {errors: {}, ShowNewRecordForm: false})}
        title="Add New Record"
        className="homeloan-outstandingloan-addnewrecord"
      >
        <NewLoanRecord
          editData={EditLoanRecordData}
          // data={LoanData}
          // onChange={this.handleInputChange}
          // handleSubmit={this.handleSubmit}
          addNewRecordToTable={(data, addOrRemove) =>
            calculateTotalRow(data, addOrRemove)
          }
          loanDetail={props.location.state}
          title="Add New Record"
          EditMode={!isEmpty(EditLoanRecordData)}
          // payrollLoanHeadings={payrollLoanHeadings}
          onModalClose={() => editOutstandingLoan(false)}
        />
      </Modal>
      <div
        id="myGrid"
        style={{
          height: "100%",
          width: "100%",
        }}
        className="ag-theme-alpine"
      >
        {outstandingLoan && (
          <Table
            // isRowSelect  // Add Checkbox iinside table for selecting multiple rows
            // onRowSelect={onSave} // Return Selected Rows after change in row selection ,
            // onRowClick={onSave}  // on Row Click
            isFixedWidth
            // isEditable // Enable inline Edit
            // onSaveData={onSave} // After change in inline edited data
            columns={beforecolumns} // Columns Settings
            // updateMyData={updateMyData} // Update Row data during onchange inside inline data
            data={outstandingLoan} // Data list
          />
        )}
      </div>
    </div>
  );
}

export default PayVerification;
