import { api, post, get, get_by_id } from "networkService";
import {
  GET_ACTIVE_JOBS,
  GET_JOB_DETAIL_BY_ID,
  GET_HIRING_TEAMS,
  GET_HIRING_TEAMS_LOADING,
  GET_JOB_HIRING_SUBSTAGES,
  GET_JOB_HIRING_SUBSTAGES_LOADING,
} from "store/types";
// import routes from "../../navigation/routes";
import environment from 'environment'

export const GetActiveJobs = () => async (dispatch: Function) => {
  const res = await get(environment.api.v1.recruitment.job.activeJobs, dispatch);
  dispatch({ type: GET_ACTIVE_JOBS, payload: res });
};

export const GetJobDetail = (jobId: any) => async (dispatch: Function) => {
  dispatch({ type: GET_JOB_DETAIL_BY_ID, payload: {} });
  const res = await get_by_id(environment.api.v1.recruitment.job.detail, dispatch, jobId);
  dispatch({ type: GET_JOB_DETAIL_BY_ID, payload: res });
};

export const SaveJobApply = (param: any, props: any) => async (
  dispatch: Function
) => {
  const res: any = await post(
    environment.api.v1.recruitment.job.apply,
    dispatch,
    param,
    true,
    false
  );
  if (res.Status && props) {
    // props.history.push({
    //   pathname: routes.jobListing,
    // });
  }
};

export const GetJobHiringTeams = (jobId: any) => async (dispatch: Function) => {
  dispatch({ type: GET_HIRING_TEAMS_LOADING, payload: true });
  const res: any = await get_by_id(
    environment.api.v1.recruitment.hiring.teams,
    dispatch,
    jobId
  );
  dispatch({ type: GET_HIRING_TEAMS, payload: res.Data });
};

export const GetJobHiringSubStages = (
  jobId: any,
  stageId: any = null
) => async (dispatch: Function) => {
  dispatch({ type: GET_JOB_HIRING_SUBSTAGES_LOADING, payload: true });
  
  let url = `${environment.api.v1.recruitment.hiring.subStagesByJobAndStage}/${jobId}/hiring-sub-stages`;
  if (stageId) {
    url = `${url}/${stageId}`;
  }
  const res: any = await get(url, dispatch);
  dispatch({ type: GET_JOB_HIRING_SUBSTAGES, payload: res.Data });
};
