import React, {Component} from "react";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import HeaderEmployee from "scenes/common/header/headerEmployee";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import {getActiveFeatureFlag} from "actions/permissionsAction";

import "./dashboard.scss";
import MyPage from "./myPage";
import WorkSchedule from "./myWorkSchedule";
import MyTeamView from "./myTeam";
import Messages from "./messages";
import News from "./news";
import {
  getEmployeeDashboard,
  getEmployeeDashboardInfos,
  getEmployeeTravelDashboard,
} from "../action";

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeDashboard: null,
    };
  }

  async componentDidMount() {
    this.fetchData();

    let {employeeDashboard, employeeTravelDashboard, activeFeatureFlags} = this.props;
    if (
      !isEmpty(employeeTravelDashboard) &&
      !isEmpty(activeFeatureFlags) &&
      isFeatureEnabledValue("TravelManagement")
    ) {
      employeeDashboard = await this.mergeTravelSettlementDataToEmployeeDashboard(
        employeeDashboard,
        employeeTravelDashboard
      );
    }
    if (!isEmpty(employeeDashboard)) {
      this.setState({employeeDashboard});
    }
  }
  mergeTravelSettlementDataToEmployeeDashboard = (
    employeeDashboard,
    employeeTravelDashboard
  ) => {
    return {
      ...employeeDashboard,
      RequestCount: [
        ...employeeDashboard?.RequestCount,
        employeeTravelDashboard?.RequestCount,
        employeeTravelDashboard?.SettlementRequestCount,
      ],
      MyUpdate: {
        ...employeeDashboard?.MyUpdate,
        ...employeeTravelDashboard?.MyUpdate,
      },
    };
  };
  async componentDidUpdate(prevProps, prevState) {
    let {
      employeeDashboard,
      dashboardLoding,
      employeeTravelDashboard,
      activeFeatureFlags,
    } = this.props;
    if (
      !isEmpty(employeeDashboard) &&
      // !isEmpty(employeeTravelDashboard) &&
      employeeDashboard !== prevProps.employeeDashboard
    ) {
      if (
        !isEmpty(employeeTravelDashboard) &&
        !isEmpty(activeFeatureFlags) &&
        isFeatureEnabledValue("TravelManagement")
      )
        employeeDashboard = await this.mergeTravelSettlementDataToEmployeeDashboard(
          employeeDashboard,
          employeeTravelDashboard
        );
      this.setState({employeeDashboard});
    }
  }

  async fetchData() {
    this.props.getEmployeeDashboardInfos();
    this.props.getEmployeeTravelDashboard();
    this.props.getActiveFeatureFlag();
  }

  render() {
    const {employeeDashboard} = this.state;
    // if (isEmpty(employeeDashboard)) {
    //   // || this.props.dashboardLoding)
    //   return (
    //     <div className="dashboard">
    //       <div>No Data Found</div>
    //     </div>
    //   );
    // }
    const {
      RequestCount,
      MyUpdate,
      TeamMembers,
      OnLeave,
      Absent,
      Birthday,
      Message,
      News: NewsData,
    } = employeeDashboard || {};
    const {user} = this.props;

    return (
      <>
        {user && (
          <HeaderEmployee
            user={user}
            isRecommenderOrApprover={this.props.isRecommenderOrApprover}
            showUserRequest={true}
            {...this.props}
          />
        )}
        <div className="dashboard">
          <MyPage
            RequestCount={RequestCount}
            MyUpdate={MyUpdate}
            dashboardLoding={this.props.dashboardLoding}
          />
          {user?.IsShiftEmployee && <WorkSchedule />}

          <MyTeamView
            TeamMembers={TeamMembers}
            OnLeave={OnLeave}
            Absent={Absent}
            Birthday={Birthday}
          />

          {/* <MyTeam
            TeamMembers={TeamMembers}
            OnLeave={OnLeave}
            Absent={Absent}
            Birthday={Birthday}
          /> */}
          <Messages />

          <News />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  employeeDashboard: state.employeeReducer.employeeDashboard,
  employeeTravelDashboard: state.employeeReducer.employeeTravelDashboard,
  dashboardLoding: state.employeeReducer.dashboardLoding,
  isRecommenderOrApprover: state.auth.isRecommenderOrApprover,
  activeFeatureFlags: state.permissionReducer.activeFeatureFlags,
});

const mapDispacthToProps = {
  getEmployeeDashboard,
  getEmployeeDashboardInfos,
  getEmployeeTravelDashboard,
  getActiveFeatureFlag,
};

export default connect(mapStateToProps, mapDispacthToProps)(Dashboard);
