import React from "react";
import "./style.scss";
import Loading from "components/loaders";

const links = {
  yearly: "Employee/yearleavereport.aspx",
  leave: "Employee/leavesummary.aspx",
  attendance: "Employee/attendancedetails.aspx",
  team: "Employee/otheremployeeattendance.aspx",
  tax_calculation: "Employee/TaxDetails.aspx",
};

function Reports(props) {
  const {baseUrl} = props;
  const {reports} = props.match.params;
  const [load, setLoad] = React.useState(false);
  React.useEffect(() => {
    const foot: any = document.getElementsByClassName("footer");
    const layoutBody: any = document.getElementsByClassName("layout-body");
    foot[0].style.display = "none";
    layoutBody[0].style.paddingBottom = 0;

    setLoad(true);
  }, []);

  return (
    <div className="employee-reports">
      <Loading type="spinner" backgroundColor={"rgba(30,30,30,.3)"} loading={load} />
      <iframe
        onLoad={() => setLoad(false)}
        id="reports-frame"
        src={baseUrl + links[reports]}
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default Reports;
