import React from "react";
import {GiTick} from "react-icons/gi";
import {MdErrorOutline} from "react-icons/md";
import {FaCheck} from "react-icons/fa";
import isEmpty from "helpers/isEmpty";
function AttendanceCell(props) {
  const {
    row: {original},
  } = props;
  const {ErrorInfo} = original;
  if (isEmpty(ErrorInfo)) return null;
  return (
    <div className="shifts-table__cell-attendance">
      {ErrorInfo === "match" ? (
        <FaCheck color="#006DF9" fontSize={18} />
      ) : (
        <MdErrorOutline color="#D81C4E" fontSize={20} />
      )}
      {ErrorInfo}
    </div>
  );
}

export default AttendanceCell;
