import React, {Component, useState, useEffect} from "react";
import classnames from "classnames";
import DatepickerAsPerSetting from "components/customdaterange/datepickerAsPerSetting";
import FormGroup from "components/form";
import isEmpty from "isEmpty";

import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {change} from "redux-form";
import {MyRequistionRequestPath} from "routes/commonLinks";
import userFormValidation from "validations/useFormValidation";
import validateJobRequisition from "validations/validateJobRequisition";
import {saveJobRequisition, updateJobRequisition, getRequisitionNumber} from "../action";
import {getEditObject, getSaveObject} from "../getFormattedObject";
import Icons from "components/icons";
import "../jobRequisitionRequest.scss";

const required = (value, allValues, props, name) => {
  return value ? undefined : "Required";
};
const editorConfiguration = {
  //plugins: [ Essentials, Bold, Italic, Paragraph ],
  toolbar: ["bold", "italic", "bulletedList", "numberedList", "blockQuote"],
};

const INITIAL_STATE = {
  RequisitionId: 0,
  RequisitionNumber: "",
  InternalJobTitle: "",
  ExternalJobTitle: "",
  JobStartDate: null,
  JobEndDate: null,
  IsExperienceRequired: true,
  EmploymentType: "",
  Division: "",
  Branch: "",
  Location: "",
  EducationLevel: "",
  ExperienceDurationType: 0,
  Openings: "",
  AdditionalQualification: "",
  NoofExperience: "",
  JobSummary: "",
  Memo: "",
  IsInternalJob: false,
};

const JobRequisitionForm = (props) => {
  const [values, setValues] = useState(INITIAL_STATE);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  let {
    branches,
    departments,
    locations,
    employeeStatus,
    educationLevels,
    approvers,
    experienceDuration,
    loading,
  } = props.recruitment_commonReducer;

  useEffect(() => {
    if (!props.isEditPage && !props.isViewPage) {
      handleJobTypeChange("IsInternalJob", false);
    }
  }, []);

  useEffect(() => {
    const {requisitionDetails} = props.jobRequisitionReducer;
    if (props.isEditPage && requisitionDetails) {
      let obj = getEditObject(props.recruitment_commonReducer, requisitionDetails);

      obj && setValues(obj);
    }
  }, [props.jobRequisitionReducer]);

  let isViewMode = props.isViewMode;

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });

    if (isSubmitted) {
      const validationErrors = validateJobRequisition({
        ...values,
        [name]: value,
      });
      setErrors(validationErrors);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateJobRequisition(values);
    setErrors(validationErrors);
    
    if (isEmpty(validationErrors)) {
      const {companyInformation} = props;
      let isNepaliDate = !companyInformation.IsEnglishDate;
      const data = getSaveObject(values, isNepaliDate);

      data.RequisitionId === 0
        ? props.saveJobRequisition(data, props.history)
        : props.updateJobRequisition(data, props.history);
    }
  };

  const onSelectChange = (name, value) => {
    handleChange(name, value);
  };
  const onChange = (name, value) => {
    //let { name, value } = e.target;
    //value = name === "IsExperienceRequired" ? !e.target.checked : value;
    handleChange(name, value);
  };
  const experienceChange = (e) => {
    let {name, value} = e.target;
    value = name === "IsExperienceRequired" ? !e.target.checked : value;
    handleChange(name, value);
  };
  const setDate = (name, date) => {
    handleChange(name, date);
  };
  const getFromDate = (date) => {
    handleChange("JobEndDate", date);
  };

  const onCancelClick = () => {
    props.history.push(MyRequistionRequestPath);
  };
  const handleJobTypeChange = async (name, value) => {
    let newValues = {...values};
    let number = await props.getRequisitionNumber(value);

    newValues[name] = value;
    newValues["RequisitionNumber"] = number;

    setValues(newValues);
  };

  const experienceNotRequired = !values.IsExperienceRequired;
  if (!values.IsExperienceRequired) {
    values.NoofExperience = "";
    values.ExperienceDurationType = "";
  }

  return (
    <div className="job-requisition-form">
      <form className="" onSubmit={handleSubmit}>
        <div className="job-requisition form">
          <div className="form__header">
            <span className="form__header__text">{props.title}</span>
          </div>
          <div className="form__body">
            <div className="first-section">
              <div className="section__btnGrp">
                <div
                  className={classnames("job-type", {
                    "job-type-active": values.IsInternalJob === true,
                  })}
                  onClick={() => handleJobTypeChange("IsInternalJob", true)}
                >
                  {values.IsInternalJob && <Icons name="Check" />}
                  Internal Job
                </div>
                <div
                  className={classnames("job-type", {
                    "job-type-active": values.IsInternalJob === false,
                  })}
                  onClick={() => handleJobTypeChange("IsInternalJob", false)}
                >
                  {!values.IsInternalJob && <Icons name="Check" />}
                  External Job
                </div>
              </div>
              <div className="section__header">
                <span>Job Title</span>
              </div>
              <div className="section__body">
                <div className="form-row">
                  <div className="form-label">
                    <span>Req ID</span>
                  </div>
                  <div className="input-grp">
                    {/* <InputGroup
                    name="RequisitionNumber"
                    size="sm"
                    label=""
                    validate={[required]}
                    type="text"
                    value={values.RequisitionNumber}
                    width="100px"
                    disabled
                  /> */}

                    <FormGroup
                      name="RequisitionNumber"
                      size="sm"
                      label=""
                      validate={[required]}
                      type="text"
                      value={values.RequisitionNumber}
                      disabled
                    />
                  </div>
                </div>
                {values.IsInternalJob ? (
                  <div
                    className={classnames({
                      "form-row": true,
                      error: errors.InternalJobTitle,
                    })}
                  >
                    <div className="form-label">
                      <span>Internal Job Title</span>
                    </div>
                    <div className="input-grp">
                      {/* <InputGroup
                    name="InternalJobTitle"
                    size="lg"
                    label=""
                    type="text"
                    value={values.InternalJobTitle}
                    width="300px"
                    onChange={onChange}
                    disabled={isViewMode}
                  /> */}
                      <FormGroup
                        name="InternalJobTitle"
                        size="lg"
                        label=""
                        type="text"
                        value={values.InternalJobTitle}
                        width="300px"
                        onChange={onChange}
                        disabled={isViewMode}
                        error={errors?.InternalJobTitle}
                      />
                    </div>
                    <div>
                      {errors.InternalJobTitle ? (
                        <span className="error__message">{errors.InternalJobTitle}</span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div
                    className={classnames({
                      "form-row": true,
                      error: errors.ExternalJobTitle,
                    })}
                  >
                    <div className="form-label">
                      <span>External Job Title</span>
                    </div>
                    <div className="input-grp">
                      {/* <InputGroup
                    name="ExternalJobTitle"
                    size="md"
                    label=""
                    type="text"
                    value={values.ExternalJobTitle}
                    width="300px"
                    onChange={onChange}
                    disabled={isViewMode}
                  /> */}
                      <FormGroup
                        name="ExternalJobTitle"
                        size="md"
                        label=""
                        type="text"
                        value={values.ExternalJobTitle}
                        width="300px"
                        onChange={onChange}
                        disabled={isViewMode}
                        error={errors?.ExternalJobTitle}
                      />
                    </div>
                    <div>
                      {errors.ExternalJobTitle ? (
                        <span className="error__message">{errors.ExternalJobTitle}</span>
                      ) : null}
                    </div>
                    {/* <div className="btn-grp">
                    <button
                      onClick={sameAsInternalClick}
                      type="button"
                      disabled={isViewMode}
                    >
                      Same as Internal
                    </button>
                  </div> */}
                  </div>
                )}
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.EmploymentType,
                  })}
                >
                  <div className="form-label">
                    <span>Employment Type</span>
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="EmploymentType"
                    placeholder="Select Employment Type"
                    options={employeeStatus}
                    width={300}
                    value={values.EmploymentType}
                    onChange={onSelectChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="EmploymentType"
                      placeholder="Select Employment Type"
                      options={employeeStatus}
                      width={300}
                      value={values.EmploymentType}
                      onChange={onSelectChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.EmploymentType ? (
                      <span className="error__message">{errors.EmploymentType}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="second-section">
              <div className="section__header">
                <span>Placement Information</span>
              </div>
              <div className="section__body">
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.Division,
                  })}
                >
                  <div className="form-label">
                    <span>Division</span>
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="Division"
                    placeholder="Select Division"
                    options={departments}
                    width={300}
                    value={values.Division}
                    onChange={onSelectChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="Division"
                      placeholder="Select Division"
                      options={departments}
                      width={300}
                      value={values.Division}
                      onChange={onSelectChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.Division ? (
                      <span className="error__message">{errors.Division}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.Branch,
                  })}
                >
                  <div className="form-label">
                    <span>Branch</span>
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="Branch"
                    placeholder="Select Branch"
                    options={branches}
                    width={300}
                    value={values.Branch}
                    onChange={onSelectChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="Branch"
                      placeholder="Select Branch"
                      options={branches}
                      width={300}
                      value={values.Branch}
                      onChange={onSelectChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.Branch ? (
                      <span className="error__message">{errors.Branch}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.Location,
                  })}
                >
                  <div className="form-label">
                    <span>Location</span>
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="Location"
                    placeholder="Select Location"
                    options={locations}
                    width={300}
                    value={values.Location}
                    onChange={onSelectChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="Location"
                      placeholder="Select Location"
                      options={locations}
                      width={300}
                      value={values.Location}
                      onChange={onSelectChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.Location ? (
                      <span className="error__message">{errors.Location}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.Openings,
                  })}
                >
                  <div className="form-label">
                    <span>Openings</span>
                  </div>
                  <div className="input-grp">
                    {/* <InputGroup
                    name="Openings"
                    size="md"
                    label=""
                    value={values.Openings}
                    type="number"
                    minValue="1"
                    width="60px"
                    onChange={onChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      name="Openings"
                      size="md"
                      label=""
                      value={values.Openings}
                      type="number"
                      minValue="1"
                      width="60px"
                      onChange={onChange}
                      disabled={isViewMode}
                      error={errors.Openings}
                    />
                  </div>
                  <div>
                    {errors.Openings ? (
                      <span className="error__message">{errors.Openings}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="third-section">
              <div className="section__header">
                <span>Job Dates</span>
              </div>
              <div className="section__body">
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.JobStartDate,
                  })}
                >
                  <div className="form-label">
                    <span>Start Date</span>
                  </div>
                  <div className="input-grp">
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="pickers">
                      <KeyboardDatePicker
                        name="JobStartDate"
                        value={values.JobStartDate}
                        width={60}
                        onChange={getToDate}
                        disabled={isViewMode}
                      />
                    </div>
                  </MuiPickersUtilsProvider> */}
                    <DatepickerAsPerSetting
                      formName="customdateinput"
                      onChange={setDate}
                      value={values.JobStartDate}
                      placeholder="Select date"
                      label=""
                      // isNepali
                      width="300px"
                      validators={["required"]}
                      error={errors?.JobStartDate}
                      name="JobStartDate"
                    />
                  </div>
                  <div>
                    {errors.JobStartDate ? (
                      <span className="error__message">{errors.JobStartDate}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <span>End Date</span>
                  </div>
                  <div className="input-grp">
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="pickers">
                      <KeyboardDatePicker
                        name="JobEndDate"
                        value={values.JobEndDate}
                        width={120}
                        onChange={getFromDate}
                        disabled={isViewMode}
                      />
                    </div>
                  </MuiPickersUtilsProvider> */}
                    <DatepickerAsPerSetting
                      formName="customdateinput"
                      onChange={setDate}
                      value={values.JobEndDate}
                      placeholder="Select date"
                      label=""
                      // isNepali
                      width="300px"
                      validators={["required"]}
                      error={errors?.JobEndDate}
                      name="JobEndDate"
                    />
                  </div>
                  <div>
                    {errors.JobEndDate ? (
                      <span className="error__message">{errors.JobEndDate}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="fourth-section">
              <div className="section__header">
                <span>Job Summary</span>
              </div>
              <div className="section__body">
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.EducationLevel,
                  })}
                >
                  <div className="form-label">
                    <span>Education Level</span>
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="EducationLevel"
                    placeholder="Select EducationLevel"
                    options={educationLevels}
                    width={300}
                    value={values.EducationLevel}
                    onChange={onSelectChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="EducationLevel"
                      placeholder="Select EducationLevel"
                      options={educationLevels}
                      width={300}
                      value={values.EducationLevel}
                      onChange={onSelectChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.EducationLevel ? (
                      <span className="error__message">{errors.EducationLevel}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.AdditionalQualification,
                  })}
                >
                  <div className="form-label">
                    <span>Additional Qualification</span>
                  </div>
                  <div className="input-grp">
                    {/* <InputGroup
                    name="AdditionalQualification"
                    size="md"
                    label=""
                    value={values.AdditionalQualification}
                    type="text"
                    //value={this.state.billAmount}
                    width="300px"
                    onChange={onChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      name="AdditionalQualification"
                      size="md"
                      label=""
                      value={values.AdditionalQualification}
                      type="text"
                      //value={this.state.billAmount}
                      width="300px"
                      onChange={onChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.AdditionalQualification ? (
                      <span className="error__message">
                        {errors.AdditionalQualification}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div
                  className={classnames({
                    "form-row": true,
                    error: errors.IsExperienceRequired,
                  })}
                >
                  <div className="form-label">
                    <span>Experience</span>
                  </div>
                  <div className="input-grp">
                    {/* <InputGroup
                    name="NoofExperience"
                    size="md"
                    label=""
                    value={values.NoofExperience}
                    type="number"
                    //value={this.state.billAmount}
                    minValue="1"
                    width="60px"
                    onChange={onChange}
                    disabled={isViewMode || experienceNotRequired}
                  /> */}
                    <FormGroup
                      name="NoofExperience"
                      size="md"
                      label=""
                      value={values.NoofExperience}
                      type="number"
                      //value={this.state.billAmount}
                      minValue="1"
                      width="60px"
                      onChange={onChange}
                      disabled={isViewMode || experienceNotRequired}
                    />
                  </div>
                  <div className="input-grp">
                    {/* <AutoSelect
                    name="ExperienceDurationType"
                    placeholder=""
                    options={experienceDuration}
                    width={120}
                    onChange={onSelectChange}
                    value={values.ExperienceDurationType}
                    disabled={isViewMode || experienceNotRequired}
                  /> */}
                    <FormGroup
                      formName="reactselect"
                      name="ExperienceDurationType"
                      placeholder=""
                      options={experienceDuration}
                      width={120}
                      onChange={onSelectChange}
                      value={values.ExperienceDurationType}
                      disabled={isViewMode || experienceNotRequired}
                    />
                  </div>
                  <div className="input-grp">
                    {/* <CheckGroup
                    label="Not required"
                    labelPosition="right"
                    name="IsExperienceRequired"
                    checked={!values.IsExperienceRequired}
                    onChange={onChange}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="checkgroup"
                      label="Not required"
                      labelPosition="right"
                      name="IsExperienceRequired"
                      checked={!values.IsExperienceRequired}
                      onChange={experienceChange}
                      disabled={isViewMode}
                    />
                  </div>
                  <div>
                    {errors.IsExperienceRequired ? (
                      <span className="error__message">
                        {errors.IsExperienceRequired}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <span>Job Summary</span>
                  </div>
                  <div className="input-grp">
                    {/* <CKEditor
                    className="job-summary"
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={values.JobSummary}
                    onChange={(name, editor) => {
                      const data = editor.getData();
                      handleChange("JobSummary", data);
                      //
                    
                      // this.handleTravelSummaryChange(data);
                    }}
                    disabled={isViewMode}
                  /> */}
                    <FormGroup
                      formName="texteditor"
                      value={values.JobSummary}
                      toolbarId={"jobSummary"}
                      placeholder={"Write something"}
                      name="JobSummary"
                      onChange={(name, value) => {
                        handleChange("JobSummary", value);
                      }}
                      width="500px"
                      disabled={isViewMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="approver form">
          <div className="form__header">
            <span>Approvals</span>
          </div>
          <div className="form__body">
            <div className="form-row">
              <div className="form-label mb-sm">
                <span>Memo</span>
              </div>
              <div className="input-grp">
                {/* <TextareaAutosize
                name="Memo"
                cols={100}
                rows={6}
                value={values.Memo}
                onChange={onChange}
                disabled={isViewMode}
              /> */}
                <FormGroup
                  formName="textarea"
                  value={values.Memo}
                  placeholder="type memo.."
                  name="Memo"
                  label=""
                  onChange={onChange}
                  disabled={isViewMode}
                />
              </div>
            </div>
            {/* <div className="form-row">
            <Select
              name="Approver"
              isMulti={true}
              closeMenuOnSelect={false}
              options={approvers}
              className="multiselect"
              //validate={[required]}
              placeholder="Select approver..."
              width={250}
              //value={this.state.billAmount}
            />
          </div> */}
          </div>
        </div>
        <div className="footer">
          <div className="form-row">
            <button
              className="btn btn-del"
              type="button"
              onClick={onCancelClick}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
              Send for Approval
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  recruitment_commonReducer: state.recruitment_commonReducer,
  jobRequisitionReducer: state.jobRequisitionReducer,
  companyInformation: state.commonReducer.companyInformation,
});
const mapDispatchToProps = {
  saveJobRequisition,
  updateJobRequisition,
  getRequisitionNumber,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobRequisitionForm)
);
