// import axios from 'helpers/axiosinstance';
import axios from "helpers/axiosinstance";
import isEmpty from "isEmpty";
import uuid from "uuid";
import HttpErrorResponseModel from "./httpErrorResponseModel";

const RequestMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Delete: "DELETE",
  Options: "OPTIONS",
  Head: "HEAD",
  Patch: "PATCH",
};
// const token = isEmpty(localStorage.getItem('jwtToken'))? null : localStorage.getItem('jwtToken');

export default class HttpUtility {
  static async get(endpoint?: string, params?: any, requestConfig?: any) {
    const paramsConfig = params ? {params} : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Get,
      },
      {
        ...paramsConfig,
        ...requestConfig,
      }
    );
  }

  static async post(endpoint?: string, data?: any, extraConfig: any = {}) {
    const config = data ? {data} : {};
    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      {
        ...config,
        ...extraConfig,
      }
    );
  }

  static async put(endpoint?: string, data?: any, extraConfig: any = {}) {
    const config = data ? {data} : {};

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Put,
      },
      {
        ...config,
        ...extraConfig,
      }
    );
  }

  static async patch(endpoint?: string, data?: any, extraConfig: any = {}) {
    const config = data ? {data} : {};

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Patch,
      },
      {
        ...config,
        ...extraConfig,
      }
    );
  }

  static async delete(endpoint?: string) {
    return HttpUtility._request({
      url: endpoint,
      method: RequestMethod.Delete,
    });
  }

  static async _request(restRequest, config = null) {
    if (!Boolean(restRequest.url)) {
      console.error(`Received ${restRequest.url} which is invalid for a endpoint url`);
    }

    try {
      const axiosRequestConfig = {
        ...config,
        method: restRequest.method,
        url: restRequest.url,
        headers: {
          "Content-Type": "application/json",
          loading: false,
          // 'Authorization':token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
          ...config?.headers,
        },
      };

      const [axiosResponse] = await Promise.all([
        axios(axiosRequestConfig),
        HttpUtility._delay(),
      ]);

      const {status, data, request} = axiosResponse;

      if (data.success === false) {
        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: data.errors.join(" - "),
            errors: data.errors,
            url: request ? request.responseURL : restRequest.url,
            raw: axiosResponse,
          },
          restRequest
        );
      }

      return {
        ...axiosResponse,
        isSuccess: true,
      };
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const {status, statusText, data} = error.response;
        const errors = data.hasOwnProperty("errors")
          ? {statusText, ...data.errors}
          : [statusText];
        const message = data.hasOwnProperty("message") ? data.message : "";
        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: message,
            errors,
            url: error.request.responseURL,
            raw: error.response,
          },
          restRequest
        );
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js

        const {status, statusText, responseURL} = error.request;

        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: statusText,
            errors: {message: statusText},
            url: responseURL,
            raw: error.request,
          },
          restRequest
        );
      }

      // Something happened in setting up the request that triggered an Error
      return HttpUtility._fillInErrorWithDefaults(
        {
          status: 0,
          message: error.message,
          errors: {message: error.message},
          url: restRequest.url,
          raw: error,
        },
        restRequest
      );
    }
  }

  static _fillInErrorWithDefaults(error, request) {
    const model = new HttpErrorResponseModel();
    model.status = error.status || 0;
    model.message = error.message || "Error requesting data";
    model.errors = !isEmpty(error.errors) ? [error.errors] : ["Error requesting data"];
    model.url = error.url || request.url;
    model.raw = error.raw;

    // Remove anything with undefined or empty strings.
    model.errors = model.errors.filter(Boolean);
    model.isSuccess = isEmpty(error.errors);
    return model;
  }

  /**
   * We want to show the loading indicator to the user but sometimes the api
   * request finished too quickly. This makes sure there the loading indicator is
   * visual for at least a given time.
   *
   * @param duration
   * @returns {Promise<unknown>}
   * @private
   */
  static _delay(duration = 250) {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }
}
