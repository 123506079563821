import React, {useState, useEffect} from "react";
import Form from "components/form";
import Button from "components/button";
import moment from "moment";
import {useForm} from "react-hook-form";
import isEmpty from "isEmpty";
import FormGroup from 'components/formGroup';
import {useDispatch, useSelector} from "react-redux";
import FormItem from "components/formGroup/formItem";
import {saveOutstandingLoan, getLoanTypes} from "../../helpers/action";
export default function NewLoanRecord({
  // data,
  // onChange,
  // onModalClose,
  // handleSubmit,
  // payrollLoanHeadings,
  editData,
  EditMode,
  loanDetail,
  addNewRecordToTable,
  onModalClose,
  ...props
}) {
  const methods = useForm();
  const [defaultValues, setDefaultValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {setValue, getValues, watch, control} = methods;
  useEffect(() => {
    // dispatch(getLoanTypes());
  }, []);
  useEffect(() => {
    dispatch(getLoanTypes());
    if (!isEmpty(editData)) {
      const {
        LoanName,
        LoanType,
        OutstandingAmount,
        AsOnDateEng,
        MonthlyDeductionAmount,
        CompletesOnDateEng,
      } = editData;
      let loanDrop = {
        label: LoanName,
        value: LoanType,
      };
      setValue("LoanName", loanDrop);
      setValue("Outstanding", OutstandingAmount);
      setValue("AsOnDate", moment(AsOnDateEng).format("YYYY/MM/DD"));
      setValue("MonthlyDeduction", MonthlyDeductionAmount);
      setValue("CompletesOn", moment(CompletesOnDateEng).format("YYYY/MM/DD"));
    }
  }, [editData]);
  const {sbiLoanTypes} = useSelector(
    (state) => state.sbiLoanReducer
  );
  const onSubmit = async (data) => {
    setIsLoading(true)
    const { Outstanding, AsOnDate, MonthlyDeduction, CompletesOn, LoanName } = data;
    // const {
    //   Id
    // } = editData;
    let loanData = {
      Id: EditMode ? editData?.Id : 0,
      EmployeeId: loanDetail.EmployeeId,
      LoanType: loanDetail.LoanType,
      AppliedLoanType: LoanName.value, // dropdown id of loan
      LoanId: loanDetail.LoanId,
      LoanName: LoanName.label,
      OutstandingAmount: Outstanding,
      // asOnDate: AsOnDate,
      AsOnDateEng: new Date(AsOnDate),
      MonthlyDeductionAmount: MonthlyDeduction,
      // completesOnDate: CompletesOn,
      CompletesOnDateEng: new Date(CompletesOn),
      // completesOnDateEng: "2021-09-28T10:29:47.267Z",
    };
    let isOutstandingSaved = await dispatch(saveOutstandingLoan(loanData, EditMode ? 'put' : 'post'));
    if (isOutstandingSaved) {
      loanData.LoanName = LoanName.label;
      loanData.Id = isOutstandingSaved.Id;
      addNewRecordToTable(loanData, "add");
      setIsLoading(false);
    }
  };
  // const onModalClose = (data) => {};
  const handleFormChange = (name, value) => {
    setValue(name, value);
  };
  const PayHoll = [
    {
      label: "typing",
      value: "1",
    },
  ];
  return (
    <div className="homeloan-newloan">
      <FormGroup method={methods} defaultValues={defaultValues} onSubmit={onSubmit}>
        <div className="homeloan-newloan-body">
          <div className="homeloan-newloan-row">
            <FormItem
              name="LoanName"
              // value={salaryTemplate}
              options={sbiLoanTypes}
              onChange={(name, value) => handleFormChange(name, value)}
              // type="number"
              rules={{required: "Required!"}}
              formName="reactselect"
              label={"Loan Name"}
              // width={"80%"}
            />
          </div>
          <div className="homeloan-newloan-row flex">
            <div className="homeloan-newloan-row-content">
              <FormItem
                name="Outstanding"
                onChange={(name, value) => handleFormChange(name, value)}
                rules={{required: "Required!"}}
                type="number"
                label={"Outstanding Amount"}
                // width={"80%"}
              />
            </div>
            <div className="homeloan-newloan-row-content-date">
              <FormItem
                formName="customdateinput"
                label="As on Date"
                // value={
                //   !isEmpty(data.AsOnDate)
                //     ? moment(data.AsOnDate).format("YYYY/MM/DD")
                //     : null
                // }
                rules={{required: "Required!"}}
                validators={["required"]}
                name="AsOnDate"
                enableOkButton
                onChange={(name, value) => handleFormChange(name, value)}
                placeholder={"YYYY/MM/DD"}
                // isNepali={!companyInformation.IsEnglishDate}
                // isTextInNepali={!companyInformation.IsEnglishDate}
              />
            </div>
          </div>
          <div className="homeloan-newloan-row flex">
            <div className="homeloan-newloan-row-content">
              <FormItem
                name="MonthlyDeduction"
                onChange={(name, value) => handleFormChange(name, value)}
                type="number"
                label={"Monthly Deduction"}
                rules={{required: "Required!"}}
                // width={"80%"}
              />
            </div>
            <div className="homeloan-newloan-row-content-date">
              <FormItem
                formName="customdateinput"
                label="Completes on"
                enableOkButton
                // value={
                //   companyInformation.IsEnglishDate ? completesOn : date_expired_nep
                // }
                // value={
                //   !isEmpty(data.CompletesOn)
                //     ? moment(data.CompletesOn).format("YYYY/MM/DD")
                //     : null
                // }
                name="CompletesOn"
                onChange={(name, value) => handleFormChange(name, value)}
                placeholder={"YYYY/MM/DD"}
                rules={{required: "Required!"}}
                // isNepali={!companyInformation.IsEnglishDate}
                // isTextInNepali={!companyInformation.IsEnglishDate}
              />
            </div>
          </div>
        </div>
        <div className="homeloan-newloan-footer">
          <Button title="Cancel" bg="subtle" onClick={() => onModalClose()} />
          <Button
            title="Save"
            bg="primary"
            htmlButtonType="submit"
            isDisabled={isLoading}
            // onClick={() => handleSubmit()}
          />
        </div>
      </FormGroup>
    </div>
  );
}
