import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import useOutsideClick from "helpers/outsideClickHook";
import FormGroup from "components/form";
import Button from "components/button";
import Icons from "components/icons";
import { DateTime_Format } from "constants/dateFormat";
import { Date_Format } from "helpers/dateFormat";

const filters = [
  { label: "This Week", id: 1 },
  { label: "Last Week", id: 2 },
  { label: "This Month", id: 3 },
  { label: "Last Month", id: 4 },
  { label: "Date Range", id: 5 },
];

function RecordTable(props: any) {
  const ref = useRef(null);
  const [selectedFilter, setFilter] = useState(filters[0]);
  const [showDateRangeCalendar, setDateRangeCalendar] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    //#region Attendance report
    async function getAttendanceReportAsync() {
      getAttendanceReport(selectedFilter);
    }
    getAttendanceReportAsync();
    //#endregion
  }, []);

  const getAttendanceReport = async (filter: any) => {
    const dateRange = getDateRange(filter);
    await props.GetAttendanceReport(dateRange.fromDate, dateRange.toDate, dateRange.attPeriod);
  };

  const getDateRange = (filter: any) => {
    let fromDate = null;
    let toDate = null;
    let attPeriod = null;
    let dateRange = { fromDate, toDate, attPeriod };
    if (filter) {
      const id = filter.id;
      if (id == 1) {
        fromDate = moment().startOf("week").toDate();
        toDate = moment().endOf("week").toDate();
      } else if (id == 2) {
        let date = new Date();
        date.setDate(date.getDate() - 7);
        fromDate = moment(date).startOf("week").toDate();
        toDate = moment(date).endOf("week").toDate();
      } else if (id == 3) {
        fromDate = moment().startOf("month").toDate();
        toDate = moment().endOf("month").toDate();
      } else if (id == 4) {
        let date = new Date();
        date.setDate(date.getDate() - date.getDate());
        fromDate = moment(date).startOf("month").toDate();
        toDate = moment(date).endOf("month").toDate();
      }

      dateRange.fromDate = DateTime_Format(fromDate);
      dateRange.toDate = DateTime_Format(toDate);
      dateRange.attPeriod = id;
    }

    setDateRange(dateRange);
    return dateRange;
  };

  const onChangeFilter = async (filter) => {
    if (filter.id == 5) {
      setDateRangeCalendar(true);
    } else {
      await getAttendanceReport(filter);
    }
    setFilter(filter);
  };

  const onChangeDateRange = async (range) => {
    const dateRange = {
      fromDate: range.startDate,
      toDate: range.endDate,
    };

    setDateRange(dateRange);
    // setFilter(null);
    if (dateRange.fromDate && dateRange.toDate) {
      await props.GetAttendanceReport(dateRange.fromDate, dateRange.toDate, 5);
    }
  };

  // const handleButtonOutsideClick = () => {
  //   setDateRangeCalendar(false);
  //   alert("hi");
  // };

  useOutsideClick(ref, () => {
    setDateRangeCalendar(false);
  });

  return (
    <div className="web-attendance-table">
      <div className="web-attendance-table-top">
        <div className="web-attendance-table-title">Attendance Record</div>
        <div className="web-attendance-table-date_filter" ref={ref}>
          {showDateRangeCalendar ? (
            <FormGroup
              formName="reactdaterange"
              startDate={dateRange && dateRange.fromDate}
              endDate={dateRange && dateRange.toDate}
              onChange={(name, range) => onChangeDateRange(range)}
              placeholder="Select date range"
              width="220px"
            />
          ) : (
            <Button
              title={
                selectedFilter.id == 5
                  ? Date_Format(dateRange.fromDate) +
                    " - " +
                    Date_Format(dateRange.toDate)
                  : selectedFilter.label
              }
              buttonType="drop-button"
              justDrop
              dropComponent={
                <ul className="button-drop-list">
                  {filters.map((filter, i) => (
                    <li
                      className={`button-drop-list__item ${
                        selectedFilter && selectedFilter.id == filter.id
                          ? " selected"
                          : ""
                      }`}
                      key={i}
                      onClick={() => onChangeFilter(filter)}
                    >
                      {filter.label}
                    </li>
                  ))}
                </ul>
              }
              bg="white"
            />
          )}
        </div>
      </div>
      <div className="web-attendance-table-container">
        <div className="web-attendance-table-container-grid header">
          <span>Type</span>
          <span>Date</span>
          <span>Day</span>
          <span>Check-in Time</span>
          <span>Check-out Time</span>
          <span>Office Hour</span>
        </div>

        {props.attendanceReport.map((report, index) => {
          return (
            <div
              className="web-attendance-table-container-grid data"
              key={index}
            >
              <span>{report.DayType || "-"}</span>
              <span>{report.ActualDate || "-"}</span>
              <span>{report.Day || "-"}</span>
              <span>{report.InTime || "-"}</span>
              <span>{report.OutTime || "-"}</span>
              <span>{report.OfficeHour || "-"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RecordTable;
