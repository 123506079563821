import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getTaskDetails,
  addTaskDetails,
  updateTaskDetails,
  getCommentsByTaskId,
} from "../../../../helpers/action";
import isEmpty from "isEmpty";
import {taskDetailsInitialState, taskCheckListState} from "../../../taskFormStates";
import Popup from "components/popup";
import {getDateISOMDY, getDateISO} from "components/calendar/helpers/engCalendar";

import HeaderSection from "./headerSection";
import TeamMemberSection from "./teamMemberSection";
import AttachmentSection from "./attachmentSection";
import CommentSection from "./commentSection";
import CheckListSection from "./checkListSection";
import DateSection from "./dateSection";
import CustomFieldSection from "./customFieldSection";
import RecurringSection from "./recurringSection";
import TaskRatingSection from "./taskRatingSection";
import {Scrollbars} from "react-custom-scrollbars";
import CommentEditor from "./commentSection/commentEditor";
import {TMStage, TmViewType} from "scenes/tasks/helpers/enum";
import {UPDATE_TABLE_VIEW} from "scenes/tasks/helpers/types";
import {DateFormat} from "helpers/dateFormat";
import moment from "moment";
import Loader from "components/loaders";
import {IoIosCheckmarkCircle} from "react-icons/io";
import TaskLoader from "./taskLoader";
import {toastMessage} from "actions/validateAction";

const TaskModalContent = ({task, rowIndex, stage, board}) => {
  const [data, setData] = useState(taskDetailsInitialState);

  const [tag, setTag] = useState("");
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  let {currentTask, taskLoading, user} = useSelector((state: any) => ({
    currentTask: state.taskReducer.task,
    taskLoading: state.taskReducer.taskLoading,
    user: state.auth.user,
  }));
  const isValidUser = () => {
    return (
      user.UserID === data.TmTaskDetailsDto.CreatedBy || user.RoleName === "Administrator"
    );
  };

  useEffect(() => {
    dispatch(getTaskDetails(task.TaskId));
    dispatch(getCommentsByTaskId(task.TaskId));
  }, []);

  useEffect(() => {
    if (!isEmpty(currentTask)) {
      let formData = {
        ...currentTask,
        TmTaskDetailsDto: {...currentTask.TmTaskDetailsDto},
      };
      if (!formData.TmTaskDetailsDto.TaskRefId) {
        formData.TmTaskDetailsDto.TaskRefId = task.TaskId;
      }
      //   setEstimatedHour(currentTask.TmTaskDetailsDto.EstimatedHour);
      formData.TmTaskDetailsDto.StartDate = currentTask.TmTaskDetailsDto.StartDate
        ? getDateISOMDY(new Date(currentTask.TmTaskDetailsDto.StartDate))
        : "";
      formData.TmTaskDetailsDto.DueDate = currentTask.TmTaskDetailsDto.DueDate
        ? getDateISOMDY(new Date(currentTask.TmTaskDetailsDto.DueDate))
        : "";

      //setStartDate(start);
      // setDueDate(due);
      setData(formData);
    }
  }, [currentTask]);

  const convertDateISOMDY = (date) => {
    return date ? getDateISOMDY(new Date(date)) : "";
  };

  const handleCheckListUpdates = (data: any) => {
    setData(data);
    updateBoardInfo(data);
  };

  const onTagAdd = (close) => {
    let nextData = {...data};

    if (nextData.TmTaskDetailsDto.Tags) {
      nextData.TmTaskDetailsDto.Tags = nextData.TmTaskDetailsDto.Tags + "," + tag;
    } else {
      nextData.TmTaskDetailsDto.Tags = tag;
    }

    setPopup(false);
    close();
    addOrUpdateTask(nextData);
  };
  const onTagRemove = (tagName) => {
    let nextData = {...data};
    if (nextData.TmTaskDetailsDto.Tags) {
      const a = nextData.TmTaskDetailsDto.Tags.split(",")
        .filter((tg) => tg !== tagName)
        .join(",");
      nextData.TmTaskDetailsDto.Tags = a;
    }
    addOrUpdateTask(nextData);
  };
  const onEnter = (e: any, name: string, value) => {
    if (e.key === "Enter") {
      if (!isValidUser()) return;
      let nextData = {...data};
      //update state only if valid user
      nextData.TmTaskDetailsDto[name] = value;
      addOrUpdateTask(nextData);
    }
  };
  const onInputChange = (name, value) => {
    if (!isValidUser()) return;
    let nextData = {...data};
    nextData.TmTaskDetailsDto[name] = value;
    setData(nextData);
  };

  const calculateDaysRemaining = (date) => {
    let res: any;
    let day = "days";
    let dayDiff = 0;
    let type = "overdue";
    if (date) {
      var dueDate = moment(date);
      var start = moment(Date()).format("MM/DD/YYYY");
      dayDiff = dueDate.diff(start, "days");

      if (dayDiff >= -1 && dayDiff <= 1) day = "day";
      if (dayDiff >= 0) type = "remaining";
      res = `${Math.abs(dayDiff)} ${day} ${type}`;
    }
    return res;
  };
  const updateBoardInfo = (data) => {
    let dueDays = calculateDaysRemaining(data.TmTaskDetailsDto.DueDate);
    let newTask = {
      ...task,
      TaskName: data.TmTaskDetailsDto.TaskName,
      CustomField1: data.TmTaskDetailsDto.CustomField1,
      CustomField2: data.TmTaskDetailsDto.CustomField2,
      CustomField3: data.TmTaskDetailsDto.CustomField3,
      DueDate: data.TmTaskDetailsDto.DueDate,
      DueDays: dueDays,
      OpenCheckList: data.TmTaskCheckListDto.filter((x) => x.Status).length,
      TotalCheckList: data.TmTaskCheckListDto.length,
      TotalAttachments: data.Attachment && data.Attachment.length,
      TeamMembers: data.TmTaskMemberListDto,
      Rating: (data.TmTaskDetailsDto.Rating && data.TmTaskDetailsDto.Rating / 20) || 0,
    };

    dispatch({
      type: UPDATE_TABLE_VIEW,
      payload: {
        task: newTask,
        rowIndex: rowIndex,
        stage: stage,
      },
    });
  };

  const addOrUpdateTask = async (data) => {
    const taskDetailId =
      (data && data.TmTaskDetailsDto && data.TmTaskDetailsDto.TaskDetailsId) || 0;
    let isSuccess: any = false;
    if (taskDetailId === 0) {
      isSuccess = await dispatch(addTaskDetails(data));
    } else {
      isSuccess = await dispatch(updateTaskDetails(data));
    }

    if (isSuccess) updateBoardInfo(data);
  };

  const onAttachmentChange = (name, value) => {
    if (!isValidUser()) return;
    let nextData = {...data};
    nextData[name] = value;
    setData(nextData);
    addOrUpdateTask(nextData);
  };

  const onSetComments = () => {
    var nextData = {...data};
    //nextData.TmTaskDetailsDto.Comments = [];
    addOrUpdateTask(nextData);
  };
  const onAddMembers = (members) => {
    if (!isValidUser()) return;
    let newData = {...data, TmTaskMemberListDto: data.TmTaskMemberListDto};
    newData.TmTaskMemberListDto = members;
    setData(newData);
    addOrUpdateTask(newData);
  };
  const onMemberRemoved = (member, index) => {
    if (!isValidUser()) return;
    let newData = JSON.parse(JSON.stringify(data));
    if (isValidUser()) newData.TmTaskMemberListDto.splice(index, 1);
    setData(newData);
    addOrUpdateTask(newData);
  };
  const onDateChange = (name, value) => {
    if (!isValidUser()) return;

    if (name === "RepeatDueDate") {
      let cdate = new Date();
      cdate.setHours(0, 0, 0, 0);
      if (value && new Date(value) < cdate) {
        toastMessage(dispatch, {
          MessageType: "warning",
          Message: "Back date can not be selected.",
        });
        return;
      }
    }

    let newData = JSON.parse(JSON.stringify(data)); //{ ...data };

    newData.TmTaskDetailsDto[name] = value && convertDateISOMDY(value);
    if (convertDateISOMDY(value) !== data.TmTaskDetailsDto[name]) {
      addOrUpdateTask(newData);
    }
    setData(newData);
  };
  const onPeriodChange = (values, update = true) => {
    let newData = JSON.parse(JSON.stringify(data));
    for (let i = 0; i < values.length; i++) {
      let obj = values[i];
      newData.TmTaskDetailsDto[obj.name] = obj.value;
    }
    if (update) addOrUpdateTask(newData);
    setData(newData);
  };
  const handleRatingsUpdated = (rating) => {
    var newData = {...data};
    if (rating)
      newData.TmTaskDetailsDto = {
        ...newData.TmTaskDetailsDto,
        CommentedBy: rating.CommentedBy,
        CommentedByUser: rating.CommentedByUser,
        CommentedOn: rating.CommentedOn,
        Comments: rating.Comment,
        Rating: rating.Rating,
        RatedByUserId: rating.RatedByUserId,
        RatedBy: rating.RatedBy,
        RatedOn: rating.RatedOn,
        RatedByImagePath: rating.RatedByImagePath,
        TaskRatingId: rating.TaskRatingId,
      } as any;
    else
      newData.TmTaskDetailsDto = {
        ...newData.TmTaskDetailsDto,
        CommentedBy: "",
        CommentedByUser: "",
        CommentedOn: null,
        Comments: "",
        Rating: 0,
        RatedByUserId: "",
        RatedBy: "",
        RatedOn: null,
        RatedByImagePath: "",
        TaskRatingId: 0,
      } as any;
    setData(newData);
    updateBoardInfo(newData);
  };

  return (
    <div className="taskmodal">
      {/* {taskLoading && <div className="taskmodal__mask">
        <Loader
          type="normal"
          className="loader-icon abs-position-center"
          loading={taskLoading}
          name="card"
          cardType="dashboard"
        />
      </div>} */}
      <div className="taskmodal__container">
        <div className="taskmodal__left">
          <TaskLoader taskLoading={taskLoading} />
          <div className="taskmodal__header">
            <HeaderSection data={data} onEnter={onEnter} isValidUser={isValidUser} />
          </div>
          <Scrollbars autoHide style={{width: "100%"}}>
            <div className="scroll-content">
              <TeamMemberSection
                data={data}
                onMemberRemoved={onMemberRemoved}
                onAddMembers={onAddMembers}
                isValidUser={isValidUser}
              />

              <CheckListSection
                stage={stage}
                board={board}
                data={data}
                onCheckListUpdates={handleCheckListUpdates}
              />
              <DateSection
                board={board}
                data={data}
                onInputChange={onInputChange}
                onEnter={onEnter}
                onDateChange={onDateChange}
                isValidUser={isValidUser}
              />
              {/* <div className="taskmodal__assigned">
            <span>Followed</span>
            <div className="taskmodal__assigned-images">
              <FaRegUserCircle />
            </div>
          </div> */}

              <CustomFieldSection
                data={data}
                onInputChange={onInputChange}
                onEnter={onEnter}
                isValidUser={isValidUser}
              />

              {data.TmTaskDetailsDto && data.TmTaskDetailsDto.IsRecurring && (
                <RecurringSection
                  board={board}
                  taskLoading={taskLoading}
                  data={data}
                  onPeriodChange={onPeriodChange}
                  onInputChange={onInputChange}
                  onEnter={onEnter}
                  onDateChange={onDateChange}
                  isValidUser={isValidUser}
                />
              )}
              {board &&
                task &&
                board.EnableRatingAndApproval &&
                task.StageId === TMStage.Completed && (
                  <TaskRatingSection
                    data={data}
                    onRatingsUpdated={handleRatingsUpdated}
                  />
                )}
            </div>
          </Scrollbars>
        </div>
        <div className="taskmodal__right">
          <div className="taskmodal__right-flex">
            <div className="taskmodal__attachment">
              <AttachmentSection
                data={data}
                name="Attachment"
                onAttachmentChange={onAttachmentChange}
                isValidUser={isValidUser}
              />
            </div>
            <div className="taskmodal__activities">
              <CommentSection
                members={data.TmTaskMemberListDto}
                taskId={data.TmTaskDetailsDto.TaskRefId}
                task={task}
                rowIndex={rowIndex}
                stage={stage}
              />
            </div>

            <div>
              <CommentEditor
                placeHolder="Write a comments....."
                taskId={data.TmTaskDetailsDto.TaskRefId}
                mentions={data.TmTaskMemberListDto}
                onSucessSubmitting={(val) => {
                  // alert(val);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskModalContent;
