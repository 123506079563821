import React, {useRef} from "react";
import {useSpring, useChain, animated} from "react-spring";
import classnames from "classnames";
import isEmpty from "isEmpty";

export const AnimateShowHide = ({
  show,
  children,
  config,
  fromStyle,
  className,
  toStyle,
}) => {
  const open = show;
  const springRef = useRef();
  const {size, opacity, ...rest} = useSpring({
    ref: springRef,
    config: config, //{ duration: 1000 },
    from: fromStyle,
    to: toStyle,
  });
  useChain([springRef], [0, open ? 0.5 : 0.6]);
  let animClasses = classnames({
    "animation-overlay": true,
    [className]: !isEmpty(className),
  });
  return (
    <>
      <animated.div style={{...rest}} className={animClasses}>
        {children}
      </animated.div>
    </>
  );
};
