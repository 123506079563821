import {
  GET_TIME_REQUEST_DETAIL,
  GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
  CREATE_TIME_REQUEST_REQUEST,
  CREATE_TIME_REQUEST_SUCCESS,
  CREATE_TIME_REQUEST_FAILURE,
  GET_SHIFT_NAMES_LIST,
} from "actions/types";
import axios from "helpers/axiosinstance";
import {toastMessage} from "actions/validateAction";
import {catchError} from "helpers";
import {loadingHeaders} from "helpers/getHeaders";
import {Date_Format} from "constants/dateFormat";
import {get} from "src/networkService";
import environment from "environment";

const urlPrefix = "/v1/leave-time/time/";

export const GetTimeRequestDetailById = (timeRequestId) => async (dispatch) => {
  await axios
    .get(`${urlPrefix}request/${timeRequestId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_TIME_REQUEST_DETAIL,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const GetTimeApprovalRecommendedForEmployee = (employeeId) => async (dispatch) => {
  dispatch({
    type: GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
    payload: null,
  });
  await axios
    .get(`${urlPrefix}approvals/${employeeId}`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const CreateTimeRequest = (data, props) => async (dispatch) => {
  dispatch({
    type: CREATE_TIME_REQUEST_REQUEST,
  });
  await axios
    .post(`${urlPrefix}requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({type: CREATE_TIME_REQUEST_SUCCESS});
        props.refreshRequestData && props.refreshRequestData();
        props.onModalClose(false);
      } else {
        dispatch({type: CREATE_TIME_REQUEST_FAILURE});
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch({type: CREATE_TIME_REQUEST_FAILURE});
      catchError(dispatch, err);
    });
};

export const UpdateTimeRequest = (data, props) => async (dispatch) => {
  dispatch({
    type: CREATE_TIME_REQUEST_REQUEST,
  });
  await axios
    .put(`${urlPrefix}requests`, data)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: CREATE_TIME_REQUEST_SUCCESS,
        });
        props.refreshRequestData && props.refreshRequestData();
        props.onModalClose(false);
      } else {
        dispatch({type: CREATE_TIME_REQUEST_FAILURE});
      }
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch({type: CREATE_TIME_REQUEST_FAILURE});
      catchError(dispatch, err);
    });
};

export const GetShiftNamesList = (queries) => async (dispatch) => {
  const {employeeId, scheduleDate} = queries;
  var res = await get(
    `${environment.api.v1.employee.time.timeRequest}?employeeId=${employeeId}&scheduleDate=${scheduleDate}`,
    dispatch,
    null,
    false
  ).catch((ex) => {});

  if (res?.Status) {
    const payload = res?.Data.map((data) => ({
      label: data.ShiftName,
      value: data.ScheduleId,
    }));
    dispatch({
      type: GET_SHIFT_NAMES_LIST,
      payload,
    });
    return payload;
  }
};
