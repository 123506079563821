import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {otherInputInterface} from "ts/interfaces/formInterface";
import Icons from "components/icons";
import {relative} from "path";
import isEmpty from "helpers/isEmpty";

const TextInput = (props: otherInputInterface): JSX.Element => {
  const {
    name,
    type,
    value,
    autoFocus,
    disabled,
    placeholder,
    minValue,
    maxValue,
    enableClearText,
    onClearText,
    autoComplete,
    rightSideText,
    error,
  } = props;

  const ref = useRef(null);
  useEffect(() => {
    if (!isEmpty(error)) {
      ref?.current?.focus?.();
    }
  }, [error]);

  const handleChange = (event) => {
    const {name, value} = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const {name, value} = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };

  const handleKeyPress = (event) => {
    props.onKeyPress && props.onKeyPress(event);
  };

  const handleBlur = (event) => {
    const {name, value} = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleFocus = (event) => {
    if (type === "number") {
      event.target.select();
    }
  };
  return (
    <div style={{position: "relative", display: "flex"}}>
      <input
        ref={ref}
        className="genericForm-group__input"
        placeholder={placeholder}
        value={value || ""}
        type={type}
        name={name}
        min={minValue}
        max={maxValue}
        autoFocus={autoFocus}
        disabled={disabled}
        onKeyUp={handleKeyUp}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete={autoComplete}
        onWheel={(event) => event.currentTarget.blur()}
      />
      {enableClearText && value && value.length > 0 && (
        <div
          className="genericForm-group__close"
          onClick={() => {
            props.onChange && props.onChange(name, "");
            props.onClearText && onClearText();
          }}
        >
          <Icons name="Close" />
        </div>
      )}
      {rightSideText && (
        <span className="genericForm-group__label right-side-label">
          {" "}
          {rightSideText}
        </span>
      )}
    </div>
  );
};

TextInput.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
  placeholder: "",
  autoComplete: "on",
};

export default TextInput;
