import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import TextareaAutosize from "react-textarea-autosize";
import Avatar from "react-avatar";
import { formatedFullDayMMYearTime } from "helpers/dateFormat";
import {
    updateTaskComments
} from "../../../../../helpers/action";
import isEmpty from "helpers/isEmpty";


const TaskComment = (props) => {
    const { data, onRatingsUpdated, showAddBtn } = props;
    const [edited, setEdited] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [comment, setComment] = useState("")
    const dispatch = useDispatch();


    function linkify(str) {
        const RE_URL = /\w+:\/\/\S+/g;
        let match;
        const results = [];
        let lastIndex = 0;

        var links = []
        while (match = RE_URL.exec(str)) {
            const link = match[0];
            if (lastIndex !== match.index) {
                const text = str.substring(lastIndex, match.index);
                results.push(
                    <span key={results.length}>{text}</span>,
                );
            }
            const text = str.substring(lastIndex, match.index);
            links.push(link.replace("</p>", ""));

            results.push(
                <a key={results.length} href={link} target="_blank">{link}</a>
            );

            lastIndex = match.index + link.length;
        }

        links.forEach(link => {
            str = str.replace(link, `<a  href="${link}" target="_blank">${link}</a>`);
        });
        return str;
    }

    useEffect(() => { data && data.Comments && setComment(data.Comments) }, [data])

    const onDeleteRating = async () => {
        props.deleteRating && props.deleteRating(data.TaskRefId);
    };
    const onChange = (e) => {
        let value = (e.target && e.target.value) || ""
        setComment(value)
        setEdited(true);
    }
    const onKeyUp = (e) => {
        if (e.key === "Enter") {
            let val = e.target && e.target.value
            submitTaskComment(val)
        }
    };

    const onBlur = (e) => {
        if (edited) {

            let val = e.target && e.target.value
            submitTaskComment(val)
        }
        setEdited(false);
        setEditMode(false)
    };

    const submitTaskComment = async (comment) => {
        var res = await dispatch(updateTaskComments({ TaskId: data.TaskRefId, Comment: comment }));
        setEditMode(false)
        setEdited(false)
        setComment("")
        if (isEmpty(res)) {
            setComment(data.Comments)
        }
        else
            onRatingsUpdated(res)

    }

    const { auth } = useSelector((state: any) => state)
    const base = (auth && auth.baseUrl) ? auth.baseUrl.NetFrameworkAppBaseUrl : "";
    if (showAddBtn)
        return null

    return (
        !isEmpty(data) && (

            <div className="taskmodal__ratings-comment">

                <div className="comment">
                    {!isEmpty(data.Comments) &&
                        <div className="comment-header">
                            <div className="comment-header-item">
                                <div className="comment-avatar">
                                    <Avatar
                                        className="avatar"
                                        src={`${base}${data.CommentedByImagePath}`}
                                        name={data.CommentedBy}
                                        size={"30"}
                                        round={true}
                                    />
                                </div>
                                <div className="comment-title">
                                    <p>
                                        {data.CommentedBy}
                                    </p>
                                    <span>
                                        {formatedFullDayMMYearTime(data.CommentedOn)}
                                    </span>
                                </div>
                            </div>

                        </div>
                    }
                    {editMode || isEmpty(data.Comments) ? <div className="comment-block">
                        <TextareaAutosize
                            className="genericForm-group__input"
                            minRows={1}
                            autoFocus
                            name="Comment"
                            placeholder="comment"
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onBlur={onBlur}
                            value={comment}
                        />
                    </div> :
                        <div className="comment-block">
                            <div className="comment-block-message" dangerouslySetInnerHTML={{ __html: linkify(data.Comments) }} ></div>
                            <div className="comment-block-action action-btn-light">
                                <span onClick={() => setEditMode(true)}>Edit</span>
                                <span onClick={() => submitTaskComment('')}>Delete</span>
                            </div>
                        </div>}


                </div>

            </div>


        )
    );
};

export default TaskComment;
