import React, {useState} from "react";
import UserCard from "./components/userCard";
import "./Tabs.scss";

const Tabs = ({activeTab, setActiveTab, scheduledEmployee, notScheduledEmployee}) => {
  // const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <div class="tab">
        <button
          className={!activeTab ? "tablinks active" : "tablinks"}
          onClick={() => setActiveTab(0)}
        >
          Scheduled
        </button>
        <button
          className={activeTab ? "tablinks active" : "tablinks"}
          onClick={() => setActiveTab(1)}
        >
          Not Scheduled
        </button>
      </div>
      {activeTab === 0 &&
        scheduledEmployee &&
        scheduledEmployee.map((item) => (
          <UserCard
            key={item?.EmployeeId}
            isLarge
            noOfDays={item?.NoOfDays}
            noOfShifts={item?.NoOfShifts}
            name={item?.EmployeeName}
            image={item?.EmployeeImgpath}
            designation={item?.TeamRoleName}
            ot_hr={item.OT > 0 ? `${item.OT}h` : null}
            working_hr={item.TotalHours > 0 ? `${item.TotalHours}h` : null}
          />
        ))}
      {activeTab === 1 &&
        notScheduledEmployee &&
        notScheduledEmployee.map((item) => (
          <UserCard
            key={item?.EmployeeId}
            isLarge
            name={item?.EmployeeName}
            image={item?.EmployeeImgpath}
            designation={item?.TeamRoleName}
          />
        ))}
    </>
  );
};

export default Tabs;
