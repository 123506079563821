import React, {useState} from "react";
import {IoCheckmarkCircleSharp, IoCloseCircle} from "react-icons/io5";
import {FaUserFriends} from "react-icons/fa";
import RouteEnum from "constants/routeEnum";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";

import AssignedButton from "./assignButton";
import AssignedDrop from "./assignDrop";
import MyTooltip from "components/common/tooltip";

const PublishedFrame = (props) => {
  return (
    <div className="course-publish">
      {props.value === 1 ? (
        <span className="course-publish__item course-publish__yes">
          <IoCheckmarkCircleSharp />
          <span>Yes</span>
        </span>
      ) : (
        <span className="course-publish__item course-publish__no">
          <span>No</span>
        </span>
      )}
    </div>
  );
};

const AssignedFrame = (props) => {
  const value = props.value || 0;
  const history = useHistory();
  return (
    <div
      className="course-assigned"
      onClick={() => {
        const {Id, Title} = props.row?.original;
        if (value === 0) return;
        let obj = {value: Id, label: Title};
        history.push({
          pathname: RouteEnum.lmsCourseAssign,
          state: {course: obj},
        });
      }}
    >
      {value === 0 ? (
        <span>--</span>
      ) : (
        <>
          <span>{props.value}</span>
          <FaUserFriends />
        </>
      )}
    </div>
  );
};

const CourseLink = (props) => {
  return (
    <MyTooltip title={props.value} placement="top-start">
      <div className="course-assigned">
        <Link
          className="text-primary"
          to={RouteEnum.lmsCourseAdd + "/" + props.row?.original?.Id}
        >
          {props.value}
        </Link>
      </div>
    </MyTooltip>
  );
};

const CourseDate = (props) => {
  return <div className="course-assigned">{dayjs(props.value).format("YYYY-MM-DD")}</div>;
};
export {
  PublishedFrame,
  AssignedFrame,
  CourseLink,
  CourseDate,
  AssignedButton,
  AssignedDrop,
};
