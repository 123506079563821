import React, {useEffect, useState} from "react";

import Button from "components/button";
import Modal from "components/modal";
import moment from "moment";
import "./style.scss";
import TimeRequest from "../timeRequest";
import classNames from "classnames";
import {GetEmployeeShiftAttendances, SaveEmployeeAttendance} from "../../helpers/action";
import {useDispatch} from "react-redux";
import isEmpty from "helpers/isEmpty";
import ReviewModal from "./reviewModal";
/*

...InTimes.map((x) => ({
        time: x.Time,
        requestId: x.RequestId,
        isDisabled: x.Status === "TIMEREQUEST",
      })),
      ...OutTimes.map((x) => ({
        time: x.Time,
        requestId: x.RequestId,
        isDisabled: x.Status === "TIMEREQUEST",
      })),
*/

function AttendanceReview(props) {
  const {
    row: {
      original: {
        CheckOut,
        CheckedIn,
        EmployeeName,
        EmployeeId,
        InTimes,
        OutTimes,
        InTime,
        OutTime,
        ScheduleDate,
        ScheduleId,
        ShiftName,
        ShiftShortName,
        ShiftStart,
        ShiftEnd,
        IsReviewed,
      },
    },
    onSuccessSavingAttendance,
    filterState,
  } = props;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    open: false,
    shifts: [],
    showTimeRequest: false,
    timeRequestData: {},
    isLoading: false,
    checkedIn: "",
    checkedOut: "",
    inRequestId: "",
    outRequestId: "",
    shiftScheduleEmployeeId: "",
  });

  const handleReviewClick = () => {
    fetchEmployeeShiftAttendances();
  };

  const fetchEmployeeShiftAttendances = async () => {
    var shifts: any = await dispatch(
      GetEmployeeShiftAttendances({
        EmployeeId,
        startDate: ScheduleDate,
        endDate: ScheduleDate,
      })
    );
    setState({...state, open: true, shifts});
  };

  const handleModalClose = () => {
    setState({...state, open: false});
  };

  const handleSuccesTimeRequest = (obj, shiftIndex, mode) => {
    const newState = {...state, shifts: [...state.shifts], showTimeRequest: false};

    if (mode === "checkin") {
      newState.shifts[shiftIndex].InTimes.push(obj);
    } else {
      newState.shifts[shiftIndex].OutTimes.push(obj);
    }
    setState(newState);
  };

  const revieModalProps = {
    state,
    setState,
    handleModalClose,
    ScheduleDate,
    EmployeeId,
    EmployeeName,
    onSuccessSavingAttendance,
  };
  return (
    <div>
      <Button title={IsReviewed ? "Reviewed" : "Review"} onClick={handleReviewClick} />

      {state.open && <ReviewModal {...revieModalProps} />}

      {state.showTimeRequest && (
        <Modal
          open={state.showTimeRequest}
          onModalClose={() =>
            setState({...state, timeRequestData: {}, showTimeRequest: false})
          }
          title="Time Attendance Request"
          className="attreview-modal"
        >
          <TimeRequest
            {...state.timeRequestData}
            onCancel={() =>
              setState({...state, timeRequestData: {}, showTimeRequest: false})
            }
            onSuccesTimeRequest={handleSuccesTimeRequest}
          />
        </Modal>
      )}
    </div>
  );
}

export default AttendanceReview;
