import React, {Component} from "react";
import {connect} from "react-redux";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Filter,
  Inject,
  Sort,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
// import TableRow from "./tableRow";
import classNames from "classnames";
import Icons from "components/icons";
export class Table extends Component {
  constructor(props) {
    super(props);
    // this.gridTemplate = this.gridTemplate;
    this.row = [];
    this.state = {
      ksaType: props.ksaType,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    // const {ksaType} = this.state;
    if (this.props.ksaType !== prevState.ksaType) {
      this.setState({ksaType: this.props.ksaType});
      this.grid.refreshHeader();
    }
  };

  componentDidMount = () => {};

  // gridTemplate(props, history) {
  //   return (
  //     <TableRow
  //       {...props}
  //       {...history}
  //       handleDelete={this.props.handleDelete}
  //       handleEdit={this.props.handleEdit}
  //       ref={(row) => {
  //         this.row = [...this.row, row];
  //       }}
  //     />
  //   );
  // }

  actionButtons = (props) => {
    console.log("each button s==>>", props);
    return (
      <td className="e-custom-row" role="gridcell">
        <div className="flex library">
          <button
            className="button with-icon button-white"
            onClick={(e) => this.props.handleEdit(props.Id)}
          >
            <Icons name="Edit" className="flex" />
            <span>Edit</span>
          </button>
          <button
            className="button with-icon button-white"
            disabled={this.props.deleteKSALibrariesLoading}
            onClick={(e) => this.props.handleDelete(props.Id)}
          >
            <Icons name="Delete" className="flex" />
            <span>Delete</span>
          </button>
        </div>
      </td>
    );
  };

  render() {
    const {tableStructure, tableData, ksaView} = this.props;
    const {ksaType} = this.state;
    console.log("table rows==>", tableData, tableStructure, ksaType);
    return (
      <div
        className={classNames({
          table: true,
          "wd-60": ksaView,
        })}
      >
        <div className="arrear-table">
          <div className="">
            <GridComponent
              dataSource={tableData}
              className="custom-grid"
              // toolbar={this.toolbar}
              // filterSettings={{ type: "Menu" }}
              // allowFiltering={true}
              //  height={height}
              id="grid"
              // allowExcelExport={true}
              ref={(g) => (this.grid = g)}
              // rowTemplate={this.gridTemplate.bind(this)}
              allowSorting={true}
              sortSettings={this.sortingOptions}
            >
              <ColumnsDirective>
                {tableStructure &&
                  tableStructure.map((ts, i) => {
                    if (ts.field == "Action")
                      return (
                        <ColumnDirective
                          field={ts.field}
                          width={ts.width}
                          template={this.actionButtons}
                          headerText={""}
                        />
                      );
                    else
                      return (
                        <ColumnDirective
                          field={ts.field}
                          width={ts.width}
                          headerText={
                            ksaType
                              ? ksaType == 1
                                ? "Knowledge Statement"
                                : ksaType == 2
                                ? "Skills Statement"
                                : "Ability Statement"
                              : ts.headerText
                          }
                        />
                      );
                  })}
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteKSALibrariesLoading: state.jobProfileKSAReducer.deleteKSALibrariesLoading,
});

export default connect(mapStateToProps, null)(Table);
