import React from "react";
import Avatar from "react-avatar";
import {getUploadPath} from "constants/userDetails";
import {Text} from "components/text";
import {WHITE} from "constants/colors";
import {Card} from "components/card";

const EmployeeDetail = ({detail}) => {
  return (
    <div className="flex employee-detail">
      <div className="employee-detail__image">
        <Avatar
          name={detail.EmployeeName}
          size={"40"}
          round={true}
          src={getUploadPath(detail.ImagePath)}
        />
      </div>

      <Card background="none" flexDirection="column" margin="0px 0px 0px 8px">
        <Text color={WHITE} letterSpacing="0.4px" title={detail.EmployeeName} />
        <Text
          color={WHITE}
          title={detail.Position}
          fontSize="12px"
          fontWeight="200"
          letterSpacing="0.2px"
        />
      </Card>
    </div>
  );
};

export default EmployeeDetail;
