import {v1} from "constants/urls";

export default {
  //Roster Schedule
  roosterShift: `${v1}/leave-time/shift`,
  roosterShiftList: `${v1}/leave-time/shift/list`,
  roosterShiftArchive: `${v1}/leave-time/shift/archive`,
  roosterShiftListDown: `${v1}/leave-time/shift/dropdown-list/team`,

  roosterShiftPattern: `${v1}/leave-time/shift-pattern`,
  roosterShiftPatternList: `${v1}/leave-time/shift-pattern/list`,
  roosterShiftPatternDetail: `${v1}/leave-time/shift-pattern/detail`,
  roosterShiftPatternDetailMaster: `v1/leave-time/shift-pattern/details-by-master`,

  roosterLocation: `${v1}/leave-time/location`,
  roosterLocationList: `${v1}/leave-time/location/list`,

  roosterSchedule: `${v1}/leave-time/schedule`,
  roosterScheduleCopy: `${v1}/leave-time/schedule/copy`,
  roosterSchedulePublish: `${v1}/leave-time/schedule/publish`,
  roosterScheduleList: `${v1}/leave-time/schedule/list`,
  roosterScheduleAllEmployeeList: `${v1}/leave-time/schedule-all-employee/list`,
  roosterShiftScheduleEmployeeInfo: `${v1}/leave-time/shift-schedule-employee-info`,
  roosterScheduleEmployeeList: `${v1}/leave-time/schedule-employee/list`,
  roosterScheduleEmployeeListWithWorkingHourDetail: `${v1}/leave-time/schedule-employee/list-with-working-hours-detail`,
  roosterScheduleEmployee: `${v1}/leave-time/schedule-employee`,
  roosterScheduleRemoveEmployee: `${v1}/leave-time/schedule-employee/remove-employee/`,
  roosterScheduleEmployeeUnavailable: `${v1}/leave-time/schedule-employee/unavailability/`,
  roosterWorkingHours: `${v1}/leave-time/shift/employee-work-hours`,
  rosterPublishHistory: `${v1}/leave-time/schedule/publish-history`,
  roosterScheduleDownload: `${v1}/leave-time/schedule/export-published-shift`,
  roosterShiftEmployeeAttendaces: `${v1}/leave-time/shift-employee-attendances`,
  updateAllShiftEmployeeAttendances: `${v1}/leave-time/shift-employee-attendances/update-all`,

  roosterEmployeeShiftAttendaces: `${v1}/leave-time/employee-shift-attendances`,

  roosterTeam: `${v1}/leave-time/team`,
  roosterTeamArchive: `${v1}/leave-time/team/archive`,
  roosterTeamList: `${v1}/leave-time/team/list`,
  roosterTeamEmployee: `${v1}/leave-time/team-employee`,
  roosterTeamRole: `${v1}/leave-time/team-role`,
  roosterTeamRoleList: `${v1}/leave-time/team-role/list`,
  leaveTimeRequest: `${v1}/leave-time/time/roster-attendance`,
};
