import React, {useState} from "react";
import shortid from "shortid";
import {AnswerType, LMSQuestionTypeEnum} from "../helpers/enums";
import Form from "components/form";
import {FiPlus, FiMinus} from "react-icons/fi";
import classnames from "classnames";
import FormItem from "components/formGroup/formItem";

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

const RadioOption = (props) => {
  const {
    parentId,
    point,
    answer,
    answerOption,
    questionIndex,
    sectionIndex,
    index,
    id,
    isChecked,
    // changeState,
    type,
    answerType,
    questionType,
    onRadioChange,
    onChange,
    methods,
  } = props;
  const handleChange = (name, value) => {
    let nameArray = name.split("-");
    let controlName = name;
    if (nameArray.length > 0) controlName = nameArray[0];
    props.onChange && props.onChange(index, controlName, value);
  };

  const addRadioButton = () => {
    props.onAddOption &&
      props.onAddOption(index, {
        id: shortid.generate(),
        isChecked: false,
        answerOption: "",
        point: 0,
      });
  };
  const removeRadioButton = () => {
    props.onRemoveOption && props.onRemoveOption(index);
  };
  const getName = (name) => {
    return `${name}-s${sectionIndex}q${questionIndex}i${index}`;
  };

  return (
    <div className="courseForm-question__body-form">
      <div
        onClick={() => handleChange("isChecked", !isChecked)}
        className={classnames("courseForm-question__body-circle", {
          "circle-active": isChecked,
          "check-box": questionType === LMSQuestionTypeEnum.CheckBox,
        })}
      ></div>
      <div className="courseForm-question__body-input">
        <FormItem
          control={methods.control}
          setValue={methods.setValue}
          name={getName("answerOption")}
          error={methods?.formState?.errors[getName("answerOption")]?.message}
          rules={{
            required: "This field is required.",
          }}
          value={answerOption}
          onChange={handleChange}
        />
      </div>
      <div className="courseForm-question__body-stepper">
        <FormItem
          control={methods.control}
          setValue={methods.setValue}
          name={getName("point")}
          error={methods?.formState?.errors[getName("point")]?.message}
          rules={{
            required: "This field is required.",
          }}
          formName="stepper"
          value={point}
          minValue={"-5"}
          maxValue={"5"}
          onChange={handleChange}
        />
      </div>
      {answerType === AnswerType.normal && (
        <div className="courseForm-question__body-action">
          <button type="button" onClick={() => addRadioButton()} className="btn-round">
            <FiPlus />
          </button>
          <button type="button" onClick={() => removeRadioButton()} className="btn-round">
            <FiMinus />
          </button>
        </div>
      )}
    </div>
  );
};
export default RadioOption;
