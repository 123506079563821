import React, { useState } from "react";
import isEmpty from "isEmpty";
import Icons from "components/icons";
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

interface LoanProps {
  data: Array<any>,
  title?: string,
  ProposedLoanInstallment?: any,
  type?: any
}

export default function LoanDataTable({ data, title, type }: LoanProps) {

  const dropClass = (row, header, colorCode) => classnames({
    "loan-datatable__row": true,
    "loan-datatable__borderunset": row !== 0,
    [`loan-datatable__header__${colorCode} flex`]: header,
    "loan-datatable__headerText": !header,
    [`loan-datatable__headerText__${colorCode}`]: !header
  });
  const {
    loanPaymentSchedules,
  } = useSelector((state: any) => state.sbiLoanReducer);
      
  return (
    <div>
      {title && <div className="consumer-loan__payment__schedule__title mb-sm text-bg">
        {title}
      </div>}
      <div className="loan-datatable__body">
        {!isEmpty(data) && data.map((d, i) => {
          return < div className="loan-datatable" >
            <div className={dropClass(i, true, d.colorCode)}>
              {d.title}              
              {(type == 'grosssalary' && d.alias == '-2') && !isEmpty(loanPaymentSchedules) ?
                (parseInt(loanPaymentSchedules[0].Amount) < parseInt((d.amount).replace(/,/g, '')) ? 
               <Icons name="CircleCheck" className="checkmark-align ml-sm" color="green" /> 
               : <Icons name="FaCross" className="checkmark-align ml-sm" color="red" /> )
               : null}
              {/* {d.ticker ? (!isEmpty(ProposedLoanInstallment) ? 
              <Icons name="CircleCheck" className="checkmark-align ml-sm" color="green" /> : d.tickerType == 'red'
                  ? <Icons name="FaCross" className="checkmark-align ml-sm" color="red" /> : null)
              : null} */}
            </div>
            <div className={dropClass(i, false, d.colorCode)}>
              {d.amount}
            </div>
          </div>
        })}
      </div>

    </div>
  )
}