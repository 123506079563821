import dayjs from 'dayjs';

export const checkDateError = (date:any,dateType:"single"|"weekly"|"monthly"|"quaterly") => {
    if(dateType !== 'single'){
        if(typeof date === 'string' || 'start_date' in date === false || 'end_date' in date === false){
            return "Incorrect value format, missing start date or end date";
        }
    }
    if(dateType==='weekly'){
        const {start_date,end_date} = date;
        let a =dayjs(start_date).day();
        let b =dayjs(end_date).day();
        if(a !== 0 || b !== 6){
            return "Starting date or end date are outside week."
        }
    }

    return "";
}