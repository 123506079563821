import React, { Component } from "react";
import { connect } from "react-redux";
import complogo from "assets/images/Login-Banner.png";
import rigo from "assets/images/rigo-logo-vector.svg";
// import Modal from "Components/Common/Modal";
import { getComapnyLogo } from "actions/commonAction";
import isEmpty from "helpers/isEmpty";
import classnames from "classnames";
import {
  loginUser,
  forgotPassword,
  otherMessage,
  sendUsernameToEmail,
  loginViaVianet,
} from "actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { Redirect } from "react-router-dom";
import { clearOtherMessage } from "actions/validateAction";
import Form from "components/form";
import Modal from "components/modal";
import { isFeatureEnabled } from "helpers/checkPermissions";
import { roles } from "constants/constants";
import environment from "environment";
import Loader from "components/loaders";

interface LoginProps {
  loginUser: Function;
  errors: object;
  auth: any;
  // loadingState:boolean,
  baseUrl: string;
  history: any;
  sendUsernameToEmail?: any;
  forgotPassword?: Function;
  getComapnyLogo?: Function;
  companyLogo: any;
  otherMessage?: Function;
  activeFeatureFlags: any;
  loginViaVianet: Function;
  oauthEnabled: boolean;
}

interface LoginState {
  email: string;
  rigoId: string;
  password: string;
  rememberClient: boolean;
  errors: errorsState;
  modalOpen: boolean;
  isFeatured: boolean;
  loadingState: boolean;
  forgot: string;
  companyLogo: any;
}

interface errorsState {
  rigoId?: string;
  password?: string;
  email?: string;
}

const LOGIN_FEATURE = "CoreHR";

export class Login extends Component<LoginProps, LoginState> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      rigoId: "",
      password: "",
      rememberClient: true,
      errors: {},
      isFeatured: false,
      modalOpen: false,
      loadingState: false,
      forgot: "",
      companyLogo: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this._renderForgot = this._renderForgot.bind(this);
    // this._renderLoading= this._renderLoading.bind(this);
  }
  async componentDidMount() {
    const { getComapnyLogo, companyLogo } = this.props;
    const { auth } = this.props;

    await getComapnyLogo();
    if (!isEmpty(companyLogo)) {
      this.setState({ companyLogo });
    }
    this.onRedirect();
  }
  componentDidUpdate(prevProps, prevState) {
    // const {auth,errors,companyLogo} = this.props;
    const { auth, errors, activeFeatureFlags, companyLogo, baseUrl } = this.props;
    this.onRedirect();
    if (errors !== prevProps.errors) {
      this.setState({ errors });
    }
    if (!isEmpty(companyLogo) && companyLogo !== prevProps.companyLogo) {
      this.setState({ companyLogo });
    }
  }
  onRedirect = () => {
    const { auth, baseUrl } = this.props;
    if (auth.isAuthenticated) {
      // this.props.history.push('/');
      // window.location.href =getBaseAxios();
      // this.props.history.push('/');
      if (!isEmpty(auth.user)) {
        // if(!this.state.loadingState){
        //     this.setState({loadingState:true})
        // }
        // this.props.history.push('/');
        let a: any = roles();
        if (a.includes(auth.user.RoleId)) {
          if (environment.isProduction) {
            if (!this.state.loadingState) {
              this.setState({ loadingState: true });
            }
            // goToOldAdminDashabord(this.props.baseUrl)
            this.props.history.push("/");
            return;
          } else {
            this.props.history.push("/");
          }
        } else {
          if (environment.isProduction) {
            if (!this.state.loadingState) {
              this.setState({ loadingState: true });
            }
            // goToOldEmployeeDashabord(this.props.baseUrl)
            this.props.history.push("/");
            return;
          }
          this.props.history.push("/");
        }
      }
    }
  };
  onModalClose = () => {
    this.setState({ modalOpen: false });
  };
  // onChange(e:React.ChangeEvent<HTMLInputElement>) {
  onChange(name, value) {
    if (name === "email") this.setState({ email: value });
    if (name === "rigoId") this.setState({ rigoId: value });
    if (name === "password") this.setState({ password: value });
  }
  handleLoginViaVianet = async () => {
    this.setState({ errors: {} });
    this.props.loginViaVianet();
  };
  onForgot(e) {
    e.preventDefault();
    const { email, forgot } = this.state;
    const data = { email };
    const { isAuth, errors } = this.onEmailValidate(data);
    this.setState({ errors });
    console.log(isAuth);
    if (!isAuth) {
      return false;
    }
    if (forgot === "password") {
      this.props.forgotPassword(email);
    }
    if (forgot === "id") {
      this.props.sendUsernameToEmail(email);
    }
    this.setState({ modalOpen: false, email: "" });
  }
  onSubmit(e) {
    e.preventDefault();
    const { isFeatured } = this.state;
    if (isFeatureEnabled(LOGIN_FEATURE)) {
      const { rigoId, password } = this.state;
      const data = {
        rigoId,
        password,
      };
      const { isAuth, errors } = this.onValidate(data);
      this.setState({ errors });
      if (!isAuth) {
        return false;
      }
      this.props.loginUser(data);
    } else {
      this.props.otherMessage("Please enable this feature to access login");
    }
  }
  onValidate(data) {
    let errors: errorsState = {};
    if (isEmpty(data.rigoId)) {
      errors.rigoId = "RigoID is required";
    }
    if (isEmpty(data.password)) {
      errors.password = "Password is required";
    }
    return { isAuth: isEmpty(errors), errors };
  }
  onEmailValidate(data) {
    let errors: any = {};
    if (isEmpty(data.email)) {
      errors.email = "Email Field is required";
    }
    return { isAuth: isEmpty(errors), errors };
  }
  _renderForgot() {
    const { modalOpen, forgot, errors } = this.state;
    const { sendUsernameToEmail } = this.props;
    return (
      <Modal
        open={modalOpen}
        onModalClose={this.onModalClose}
        className="forgot-div"
        title={forgot === "password" ? "Forgot Password" : "Forgot Rigo Id"}
      >
        <div className="forgot-container">
          <p className="forgot-container__text">
            Forgot your Rigo ID/Password? Please enter the registered email. You will
            recieve a link to create a new password via email
          </p>

          <form className="forgot-form" onSubmit={this.onForgot.bind(this)}>
            {/* {errors.email && <div className="forgot-error">{errors.email}</div>} */}
            <div
              title={errors.email}
              className={classnames({
                "forgot-input": true,
                "forgot-error": !isEmpty(errors.email),
              })}
            >
              <input
                type="email"
                name="email"
                onChange={(e) => this.onChange("email", e.target.value)}
                value={this.state.email}
                placeholder="Enter your registered Email"
              />
              <button
                // onClick={()=>this.onSendEmail()}
                type="submit"
                className="btn btn-primary"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
  public render() {
    const { loginLoading, loginViaVianetLoading } = this.props.auth;
    const { oauthEnabled } = this.props;
    const { companyLogo } = this.state;
    const loginButtonClass = classnames("login-submit flex", {
      "login-loading": loginLoading,
    });
    const loginViaVianetButtonClass = classnames("login__via-vianet flex", {
      "login-loading": loginViaVianetLoading,
    });
    // return (
    //   <section className="section-login login">
    //     <div className="login-left">
    //       <div className="login-company">
    //         <div className="login-company__image">
    //           <img
    //             src={
    //               companyLogo && companyLogo.LoginPageImage
    //                 ? // getUploadPath(companyLogo.LoginPageImage) :
    //                   companyLogo.LoginPageImage
    //                 : complogo
    //             }
    //             alt="CompanyBanner"
    //           />
    //         </div>
    //         <div className="login-company__logo">
    //           <img
    //             src={
    //               companyLogo && companyLogo.CompanyLogo
    //                 ? // getUploadPath(companyLogo.CompanyLogo) :
    //                   companyLogo.CompanyLogo
    //                 : rigo
    //             }
    //             alt="CompanyLogo"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="login-right">
    //       <div className="login-container">
    //         <LogoutMessage />
    //         <div className="login-header">
    //           <h4 className="login-heading">Log in to Rigo HRMS</h4>
    //         </div>
    //         <form className="login-form" onSubmit={this.onSubmit}>
    //           <Form
    //             formName="textinput"
    //             label="Rigo ID"
    //             name="rigoId"
    //             type="text"
    //             placeholder="Rigo ID"
    //             onChange={this.onChange}
    //             value={this.state.rigoId}
    //             error={this.state.errors.rigoId}
    //             autoFocus
    //           />
    //           <Form
    //             formName="textinput"
    //             label="Password"
    //             name="password"
    //             type="password"
    //             placeholder="Password"
    //             onChange={this.onChange}
    //             value={this.state.password}
    //             error={this.state.errors.password}
    //           />
    //           {!isEmpty(this.state.errors) ? (
    //             <div className="error__message">
    //               {typeof this.state.errors === "string" && this.state.errors}
    //             </div>
    //           ) : null}
    //           <button disabled={loginLoading} className={loginButtonClass} type="submit">
    //             {loginLoading ? (
    //               <>
    //                 <span className="button-spin"></span>
    //                 <span>Logging In...</span>
    //               </>
    //             ) : (
    //               "Log In"
    //             )}
    //           </button>
    //           {oauthEnabled && (
    //             <button
    //               className={loginViaVianetButtonClass}
    //               disabled={loginViaVianetLoading}
    //               onClick={this.handleLoginViaVianet}
    //             >
    //               {loginViaVianetLoading ? (
    //                 <>
    //                   <span className="button-spin"></span>
    //                   <span>Logging In...</span>
    //                 </>
    //               ) : (
    //                 "Log In with Vianet"
    //               )}
    //             </button>
    //           )}
    //           <div className="login-form__forgot">
    //             <span
    //               className="forgot-link"
    //               onClick={() =>
    //                 this.setState({
    //                   modalOpen: true,
    //                   forgot: "password",
    //                 })
    //               }
    //             >
    //               Forgot password ?
    //             </span>
    //             <span
    //               onClick={() =>
    //                 this.setState({
    //                   modalOpen: true,
    //                   forgot: "id",
    //                 })
    //               }
    //               className="forgot-link"
    //             >
    //               Forgot Rigo ID ?
    //             </span>
    //           </div>
    //         </form>
    //       </div>
    //       <div className="login-footer">
    //         <div className="login-footer__logo">
    //           <img src={rigo} alt="RIGO HRMS" />
    //           <h5>Rigo HRMS</h5>
    //         </div>
    //       </div>
    //     </div>
    //     {this._renderForgot()}
    //     {/* {this._renderLoading()} */}
    //     {environment.isProduction && (
    //       <Loader
    //         backgroundColor="rgba(255,255,255,1)"
    //         loading={this.state.loadingState}
    //         type="spinner"
    //       />
    //     )}
    //   </section>
    // );
    return (
      <section className="wrapper-login">
        <div className="login-centered">
          <div className="container-login">
            <LogoutMessage />

            <div className="login-logo">
              <img
                src={
                  companyLogo && companyLogo.CompanyLogo
                    ? // getUploadPath(companyLogo.CompanyLogo) :
                    companyLogo.CompanyLogo
                    : rigo
                }
                alt="CompanyLogo"
              />
            </div>
            <h4 className="login-title">
              Rigo HRMS
            </h4>

            <form className="login-form" onSubmit={this.onSubmit}>
              {oauthEnabled ? (
                <button
                  className={loginViaVianetButtonClass}
                  disabled={loginViaVianetLoading}
                  onClick={this.handleLoginViaVianet}
                >
                  {loginViaVianetLoading ? (
                    <>
                      <span className="button-spin"></span>
                      <span>Logging In...</span>
                    </>
                  ) : (
                    "Log In with Vianet"
                  )}
                </button>
              ) : (
                <>
                  <Form
                    formName="textinput"
                    label="Rigo ID"
                    name="rigoId"
                    type="text"
                    placeholder="Rigo ID"
                    onChange={this.onChange}
                    value={this.state.rigoId}
                    error={this.state.errors.rigoId}
                    autoFocus
                  />
                  <Form
                    formName="textinput"
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={this.onChange}
                    value={this.state.password}
                    error={this.state.errors.password}
                  />
                  {!isEmpty(this.state.errors) ? (
                    <div className="error__message">
                      {typeof this.state.errors === "string" && this.state.errors}
                    </div>
                  ) : null}
                  <div className="small-links">
                    <div className="login-form__forgot">
                      <span
                        className="forgot-link"
                        onClick={() =>
                          this.setState({
                            modalOpen: true,
                            forgot: "password",
                          })
                        }
                      >
                        Forgot password
                      </span>
                      <span
                        onClick={() =>
                          this.setState({
                            modalOpen: true,
                            forgot: "id",
                          })
                        }
                        className="forgot-id"

                      >
                        Forgot Rigo ID
                      </span>
                    </div>
                  </div>
                  <button
                    disabled={loginLoading}
                    className={loginButtonClass}
                    type="submit"
                  >
                    {loginLoading ? (
                      <>
                        <span className="button-spin"></span>
                        <span>Logging In...</span>
                      </>
                    ) : (
                      "Log In"
                    )}
                  </button>
                </>
              )}
            </form>
            <div className="login-footer">
              <div className="login-footer__logo">
                <a href="https://rigonepal.com/" target="_blank">
                  <img src={rigo} alt="RIGO HRMS" />
                  <h5>Rigo </h5>
                </a>

              </div>
            </div>
          </div>
        </div>
        {this._renderForgot()}
        {/* {this._renderLoading()} */}
        {environment.isProduction && (
          <Loader
            backgroundColor="rgba(255,255,255,1)"
            loading={this.state.loadingState}
            type="spinner"
          />
        )}
      </section>
    );
  }
}

const LogoutMessage = () => {
  const dispatch = useDispatch();
  const otherMessage = useSelector((state: any) => state.validateReducer.otherMessage);
  if (!isEmpty(otherMessage)) {
    return (
      <div className="login-message">
        <div className="login-message__text">{otherMessage.message}</div>
        <div
          onClick={() => dispatch(clearOtherMessage())}
          className="login-message__close"
        >
          <MdClose />
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  // loadingState:state.loadingReducer.loading,
  activeFeatureFlags: state.permissionReducer.activeFeatureFlags,
  companyLogo: state.commonReducer.companyLogo,
  oauthEnabled: state.commonReducer.oauthEnabled,
});

const mapDispatchToProps = {
  loginUser,
  getComapnyLogo,
  sendUsernameToEmail,
  otherMessage,
  forgotPassword,
  loginViaVianet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
