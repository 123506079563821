import React, { useState } from "react";
import FormGroup from "components/form";
import { getEmployeeListToAssignRequest } from "services//employeeRequests";
import isEmpty from "helpers/isEmpty";

function SelectEmployee(props: any) {
  const [employeeList, setEmployeeList] = useState(null);
  useState(async () => {
    let response = await getEmployeeListToAssignRequest();
    setEmployeeList(response);
  });

  return (
    <FormGroup
      formName="reactselect"
      onChange={props.onChange}
      name="employeeToAssign"
      value={props.employeeToAssign}
      options={employeeList}
      placeholder="Select"
      validators={["required"]}
      error={props.error}
      width="300px"
      label="Select Employee"
      loading={isEmpty(employeeList)}
      loadingType="skeleton"
    />
  );
}

export default SelectEmployee;
