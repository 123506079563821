import React, {useState, useEffect, useMemo} from "react";
import Modal from "components/modal";
import {useDispatch, useSelector} from "react-redux";
import {IoMdArrowBack} from "react-icons/io";
import RouteEnum from "constants/routeEnum";

import {handleChangeFilterValue, getResultSummaryDetail} from "../helpers/action";
import CourseResultDetail from "./courseResultDetail";
import CourseResultsFooter from "./courseResultsFooter";
import CourseResultsDetailFilter from "./courseResultsDetailFilter";
import CourseTable from "./courseTable";
import CourseTabs from "./courseTabs";
import Summary from "./summary";
import ResultSummaryDetail from "./resultSummaryDetail";
import "./styles.scss";

const CourseResults = ({history}) => {
  const [showCourseResultsDetailForm, setShowCourseResultsDetailForm] = useState(false);
  const [showCourseSummaryDetailForm, setShowCourseSummaryDetailForm] = useState(false);
  const [selectedCourseResult, setSelectedCourseResult] = useState(null);
  const [courseResultDetailData, setCourseResultDetailData] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const {courseResultDetail} = useSelector((state: any) => state.lmsReducer);

  const dispatch = useDispatch();

  useMemo(() => {
    courseResultDetail && setCourseResultDetailData(courseResultDetail);
  }, [courseResultDetail]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const result =
        courseResultDetail.length > 0 &&
        courseResultDetail.filter((course) => {
          return course.EmployeeName.toLowerCase().includes(
            employeeName.toLocaleLowerCase()
          );
        });

      setCourseResultDetailData(result);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [employeeName]);

  const handleReportsClick = (selectedRow) => {
    setShowCourseResultsDetailForm(true);
    setSelectedCourseResult(selectedRow);
  };

  const handleResultsSummaryClick = (courseId, dateType) => {
    setShowCourseSummaryDetailForm(true);
    dispatch(getResultSummaryDetail(courseId, dateType));
  };

  const handleSearchEmployee = (name, value) => {
    setEmployeeName(value);
    dispatch(handleChangeFilterValue(name, value));
  };

  return (
    <div className="course-results">
      <div className="course-results__header">
        <IoMdArrowBack
          className="course-results__header__back-icon"
          onClick={() => history.push(RouteEnum.lmsCourseManagement)}
        />
        <div className="course-results__header__title">Course Results</div>
      </div>

      <div className="flex items-start course-results__main">
        <div className="course-results__list">
          <CourseTabs />
        </div>

        {courseResultDetailData.length > 0 && (
          <div className="course-results__detail">
            <CourseResultsDetailFilter
              handleSearchEmployee={handleSearchEmployee}
              employeeName={employeeName}
            />
            <Summary handleResultsSummaryClick={handleResultsSummaryClick} />
            <CourseTable
              handleReportsClick={handleReportsClick}
              courseResultDetailData={courseResultDetailData}
            />
          </div>
        )}
      </div>

      {showCourseResultsDetailForm ? (
        <Modal
          open={showCourseResultsDetailForm}
          onModalClose={() => setShowCourseResultsDetailForm(false)}
          title="Results"
        >
          <>
            <CourseResultDetail selectedCourseResult={selectedCourseResult} />
            <CourseResultsFooter
              onModalClose={() => setShowCourseResultsDetailForm(false)}
            />
          </>
        </Modal>
      ) : null}

      {showCourseSummaryDetailForm ? (
        <Modal
          open={showCourseSummaryDetailForm}
          onModalClose={() => setShowCourseSummaryDetailForm(false)}
          title="Result Summary"
        >
          <>
            <ResultSummaryDetail />
            <CourseResultsFooter
              onModalClose={() => setShowCourseSummaryDetailForm(false)}
            />
          </>
        </Modal>
      ) : null}
    </div>
  );
};

export default CourseResults;
