import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from "scenes/common/breadcrumb";
import ReportTable from "./reportTable";
import "./styles.scss";
import {
  GetAdvanceTravelRequest,
  GetTravelCategoryLists,
  ExportAdvanceTravelRequest,
} from "../helpers/action";
import CommonPagination from "components/common/pagination";
import {AgGridReact} from "ag-grid-react";
import dayjs from "dayjs";
import isEmpty from "isEmpty";
import {ChangeDetectionStrategyType} from "ag-grid-react/lib/changeDetectionService";
import {agSettings, initialFilters, initialHeader, finalHeaders} from "./constant";
import {Link} from "react-router-dom";
import RouteEnum from "constants/routeEnum";

export const OrderDate = (props) => {
  return <div className="">{dayjs(props.value).format("YYYY.MM.DD")}</div>;
};

export const PaymentInRupees = (props) => {
  return props.value?.toLocaleString("en-IN");
};

const getRowHeight = (params) => {
  return params.data.rowHeight;
};

const RowLink = ({value}) => {
  return <Link to={RouteEnum.SettlementRequestPath}> {value} </Link>;
};

const AdvanceReports = () => {
  const [state, setState] = useState(initialFilters);
  const [rowData, setRowData]: any = useState([]);
  const [dynamicHeaders, setDynamicHeaders] = useState(initialHeader);
  const [gridApi, setGridApi] = useState(null);
  const [dynamicHeaderState, setdynamicHeaderState] = useState(null);
  const [advanceCategory, setAdvanceCategory] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [exportColumn, setexportColumn] = useState([]);

  const dispatch = useDispatch();
  const {advancetravelRequest} = useSelector((state: any) => state.hrReducer);
  const {advanceTravelCategoryList} = useSelector((state: any) => state.hrReducer);
  const {advanceExportList} = useSelector((state: any) => state.hrReducer);

  const handleRowChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageSize"]: num,
    }));
  };

  const handleIndexChange = (num) => {
    setState((prev) => ({
      ...prev,
      ["pageIndex"]: num,
    }));
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleExcelExport = async () => {
    let searchData = {
      pageIndex: 0,
      pageSize: 999999999,
      name: "",
      destination: "",
      type: -1,
      category: -1,
    };
    await dispatch(ExportAdvanceTravelRequest(searchData));
  };

  const onSearch = async (data) => {
    const datas = {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      name: data.name,
      destination: data.destination,
      type: data.type?.value ?? -1,
      category: parseInt(data.category?.value ?? -1),
    };

    setState(datas);
  };

  useEffect(() => {
    (async () => {
      const apis = [dispatch(GetAdvanceTravelRequest(state))];
      await Promise.all(apis);
    })();
  }, [state]);

  useEffect(() => {
    (async () => {
      await dispatch(GetTravelCategoryLists(1));
    })();
  }, []);

  useEffect(() => {
    if (advanceTravelCategoryList) {
      let tempData = advanceTravelCategoryList.map((value) => {
        return {Name: value.Text, label: value.Text, value: value.Value};
      });
      setAdvanceCategory(tempData);
    }
  }, [advanceTravelCategoryList]);

  useEffect(() => {
    if (!isEmpty(advancetravelRequest) && !dynamicHeaderState) {
      const allowanceData = advancetravelRequest?.AllowanceAndExpense;
      if (!isEmpty(allowanceData)) {
        const data = allowanceData?.map((value) => {
          return {
            headerName: value?.Name,
            field: value?.AllowanceId.toString(),
            type: "rightAligned",
          };
        });
        const dynamicValues = data.concat(finalHeaders);
        setDynamicHeaders(dynamicHeaders.concat(dynamicValues));
        setdynamicHeaderState(dynamicHeaders.concat(dynamicValues));
      }
    }
  }, [advancetravelRequest]);

  useEffect(() => {
    if (dynamicHeaders) {
      const newArray = dynamicHeaders.map((item) => {
        return {label: item.headerName, value: item.field};
      });
      console.log({dynamicHeaders, newArray});
      setexportColumn(newArray);
    }
  }, [dynamicHeaders]);

  useEffect(() => {
    const rowData = (settlementData, expenseData, allowanceData) => {
      let test = settlementData.map((settlementDataValue) => {
        let dynamicObjValue = {};
        let Total = 0;
        let headerFilter = allowanceData.map((val) => val.AllowanceId);

        let filteredNew = expenseData.filter((finalFil) => {
          return finalFil?.SettlementId === settlementDataValue.SettlementId;
        });

        let temp = settlementDataValue.TravelDate.map((test) => {
          let dynamicObjValue = "";
          return dynamicObjValue.concat(
            dayjs(test.StartDate)?.format("YYYY.MM.DD") +
              ` - ` +
              dayjs(test.EndDate)?.format("YYYY.MM.DD")
          );
        });

        filteredNew = filteredNew?.filter((val) =>
          headerFilter.includes(val.AllowanceId)
        );

        filteredNew?.map((val) => {
          dynamicObjValue[val.AllowanceId] = val.Amount?.toLocaleString("en-IN");
          Total = Total + val.Amount;
        });

        let NetPayable = Total - settlementDataValue.Advance;

        return {
          ...settlementDataValue,
          ...dynamicObjValue,
          // TravelDate: temp.toString(),
          TravelDate: temp.join(", "),
          Total,
          NetPayable,
        };
      });

      return test;
    };

    if (!isEmpty(advancetravelRequest)) {
      let settlementData = advancetravelRequest?.SettlementData;
      let expenseData = advancetravelRequest?.SettlementAllowanceAndExpense;
      let allowanceData = advancetravelRequest?.AllowanceAndExpense;

      let test = rowData(settlementData, expenseData, allowanceData);
      setRowData(test);
    }

    if (!isEmpty(advanceExportList)) {
      let settlementData = advanceExportList.SettlementData;
      let expenseData = advanceExportList.SettlementAllowanceAndExpense;
      let allowanceData = advanceExportList.AllowanceAndExpense;

      let test = rowData(settlementData, expenseData, allowanceData);
      console.log({test});
      setExportData(test);
    }
  }, [advancetravelRequest, advanceTravelCategoryList, advanceExportList]);

  return (
    <div className="common-container dashboard">
      <Breadcrumb name="Advance Travel Request" />
      <div className="advance-travel-management">
        <ReportTable
          advanceCategory={advanceCategory}
          onSearch={onSearch}
          exportColumn={exportColumn}
          exportData={exportData}
          handleExcelExport={handleExcelExport}
        />

        <div className="advance-travel-management__table">
          <div
            id="myGrid"
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              frameworkComponents={{OrderDate, PaymentInRupees, RowLink}}
              columnDefs={dynamicHeaders}
              {...agSettings}
              onGridReady={onGridReady}
              groupSelectsChildren={true}
              rowData={rowData}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
              rowDragManaged={true}
              getRowHeight={getRowHeight}
              suppressExcelExport={true}
            />
          </div>
        </div>

        {advancetravelRequest && advancetravelRequest?.SettlementData.length > 0 && (
          <CommonPagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={state.pageIndex}
            rowsPerPage={state.pageSize}
            total={advancetravelRequest.SettlementData[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
};

export default AdvanceReports;
