import React, {useEffect} from "react";
import {useDispatch, useSelector, connect} from "react-redux";
import "./style.scss";
import EmployeeDetails from "./employeeDetail";
import DateTimeContact from "./dateTimeContact";
import IntroduceYourself from "./introduceYourself";
import EmpHeader from "scenes/common/header";
import moment from "moment";
import {GetOnboardingEmployeeDetail} from "../../admin/employeeOnboarding/action";

function OnBoardingDashboard(props) {
  let employeeDetail = {
    EmployeeId: 12068,
    Photo: "/Uploads/5b6511e9-6b15-40e8-83d7-191055880b37.png",
    BranchId: 1326,
    DepartmentId: 294,
    DesignationId: 5426,
    EmployeeId: 12068,
    FirstName: "kul",
    LastName: "singh",
    MiddleName: "man",
    Name: null,
    Salutation: "Mr",
  };
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const EmpID = user.EmployeeId;
  useEffect(() => {
    if (EmpID) {
      dispatch(GetOnboardingEmployeeDetail(EmpID));
    }
  }, [EmpID]);
  const {onboardingEmployeeDetail} = useSelector((state) => state.employeeOnboarding);
  if (onboardingEmployeeDetail) {
    const {
      EmployeePhotoDto,
      EmployeeOnBoardingDto,
      EmployeeDto,
      Buddy,
      ContactPerson,
      Manager,
    } = onboardingEmployeeDetail || {
      EmployeePhotoDto: {},
    };
    let empDetail = {
      fullName: EmployeeDto
        ? EmployeeDto?.Salutation +
          " " +
          EmployeeDto?.FirstName +
          " " +
          EmployeeDto?.LastName
        : null,
      photoURI: EmployeePhotoDto?.Photo,
      AppointmentDate: moment
        .parseZone(EmployeeOnBoardingDto?.AppointmentDate)
        .local()
        .format("MMMM DD, YYYY"),
      EmpID,
    };
    let dateTimeDetail = {
      JoinDate: EmployeeOnBoardingDto?.JoinDate,
      OfficeInTime: EmployeeOnBoardingDto?.OfficeInTime,
      Place: EmployeeOnBoardingDto?.Place,
      Contacts: [ContactPerson, Buddy, Manager],
      Instruction: EmployeeOnBoardingDto?.Instruction,
      GoogleMapsURL: EmployeeOnBoardingDto?.GoogleMapsURL,
    };
    return (
      <>
        {/* <EmpHeader /> */}
        <div className="employee-onboarding__container">
          <div className="employee-onboarding__header"> Get ready to Join Rigo</div>
          <EmployeeDetails empDetail={empDetail} />
          <DateTimeContact data={dateTimeDetail} />
          <IntroduceYourself
            EmpID={EmpID}
            EmployeeOnBoardingDto={EmployeeOnBoardingDto}
          />
        </div>
      </>
    );
  } else return null;
}
const mapStateToProps = (state) => ({
  employeeRolesHistory: state.employeeProfile.employeeRolesHistory || [],
  assignJobDescriptionHistoryData:
    state.employeeProfile.assignJobDescriptionHistoryData || [],
  employeeAssignedJobDescription: state.employeeProfile.employeeAssignedJobDescription,
});
const mapDispatchToProps = {
  GetOnboardingEmployeeDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingDashboard);
