import {getCommaFormattedNumber} from "helpers/getFormattedNumber";
import React from "react";
const SampleTotal = ({total}) => {
  if (total === 0) return null;
  return (
    <div className="emp-payroll__table">
      <div className="emp-payroll__body">
        <div className="emp-payroll__row">
          <div className="emp-payroll__col"></div>
          <div className="emp-payroll__col">
            <span className={"bold-500"}>{getCommaFormattedNumber(total, 2)}</span>
          </div>
          <div className="emp-payroll__col"></div>
          <div className="emp-payroll__col"></div>
          <div className="emp-payroll__col"></div>
        </div>
      </div>
    </div>
  );
};

export default SampleTotal;
