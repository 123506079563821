import classnames from "classnames";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import isEmpty from "helpers/isEmpty";
import React from "react";
import {AiFillMinusCircle} from "react-icons/ai";
import {IoIosAddCircle} from "react-icons/io";
import {Link} from "react-router-dom";

const UpdateItem = ({myUpdateList}) => {
  if (isEmpty(myUpdateList)) {
    return null;
  }
  const statusClasses = (text) =>
    classnames({
      "mypage-section-block-name": true,
      "text-pink": text === "Pending" || text === "Approval Pending",
      "text-green": text === "Approved",
      "text-blue": text === "Reviewed",
      "text-orange": text === "Recommended",
    });

  return (
    <div className="mypage-section__container">
      {/* <h3 className="tertiary-title">{title}</h3> */}
      <div className="mypage-section-grid">
        {myUpdateList &&
          myUpdateList?.map((item, index) => (
            <Link
              to={{
                pathname: item.link,
                state: {RequestType: item.requestType},
              }}
              key={index}
            >
              <div className="mypage-section-block">
                <div className={statusClasses(item.data.Status)}>
                  {item.data.Status}
                  <span className="mypage-section-block-name-icon">
                    {item.data.Status === "Approved" ? (
                      <SvgIcons name={"Doublecheck"} className="status-icon text-green" />
                    ) : item.data.Status === "Recommended" ? (
                      <SvgIcons name={"Check"} className="status-icon text-orange" />
                    ) : (
                      <Icons name="Time" />
                    )}
                  </span>
                </div>
                <div className="mypage-section-block-desc">
                  <div className="mypage-section-block-desc-detail items-start wd-100">
                    <div className="mypage-section-block-desc-detail-text text-title flex wd-100 justify-between">
                      {item.data.Title}
                      {item.data.Title === "Compensatory Leave" &&
                        item.data.CompensatorType &&
                        (item.data.CompensatorType === "Add" ? (
                          <IoIosAddCircle className="comp-leave-plus-icon" />
                        ) : (
                          <AiFillMinusCircle className="comp-leave-minus-icon" />
                        ))}
                    </div>

                    <div className="flex justify-between wd-100">
                      <div className="flex-column">
                        <div className="mypage-section-block-desc-detail-text text-time">
                          <span>{item.data.FromDateEng}</span>
                          <span>{" - "}</span>
                          <span> {item.data.ToDateEng} </span>
                        </div>
                        {item.data.Place && (
                          <div className="mypage-section-block-desc-detail-text text-place">
                            <span>{item.data.Place}</span>
                          </div>
                        )}
                      </div>
                      <div className="mypage-section-block-desc-icon">
                        <SvgIcons name={item.requestType} className="request-icon" />
                        {/* <Icon className="request-icon" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default UpdateItem;
