import React, {useEffect, useState} from "react";
import "./style.scss";
import SearchFilter from "./SearchFilter";
import TableView from "./tableView";
import {getBoardActivity} from "../helpers/action";
import {useDispatch} from "react-redux";
import {Date_Format_ALT} from "helpers/dateFormat";

const endDate = new Date();

const initial = {
  pageSize: 20,
  pageIndex: 0,
  startDate: Date_Format_ALT(new Date().setDate(endDate.getDate() - 1)),
  endDate: Date_Format_ALT(endDate),
  board: "",
  employee: "",
};

const BoardActivityReport = (props) => {
  const [filter, setFilter] = useState(initial);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (filter) fetchData();
  }, [filter]);

  const fetchData = async (isExcelExport = false) => {
    const param = {
      page: isExcelExport ? 0 : filter.pageIndex,
      size: isExcelExport ? 9999999 : filter.pageSize,
      boardId: 0,
      startDate: filter.startDate,
      endDate: filter.endDate,
      board: filter.board,
      employee: filter.employee,
    };
    let data: any = await dispatch(getBoardActivity(param, false));
    if (!isExcelExport) setTableData(data);
    return data;
  };

  const onChangeFilter = (name, val) => {
    const newFilter = {...filter, [name]: val};
    setFilter(newFilter);
  };

  const handleDateRangePicker = (name, date) => {
    const {startDate, endDate} = date;
    const newFilter = {...filter, startDate, endDate};
    setFilter(newFilter);
  };

  const handleRowChange = (num) => {
    setFilter((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0,
      };
    });
  };

  const handleIndexChange = (num) => {
    setFilter((filter: any) => {
      return {
        ...filter,
        pageIndex: num,
      };
    });
  };
  return (
    <div className="activityReport">
      <div className="activityReport-title">
        <h1>Board Activities</h1>
        <SearchFilter
          filter={filter}
          onChange={onChangeFilter}
          handleDateRangePicker={handleDateRangePicker}
          handleExcelExport={fetchData}
        />
        <TableView
          tableData={tableData}
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
        />
      </div>
    </div>
  );
};

export default BoardActivityReport;
