import React, { Component } from "react";
import Table from "./table";

export class KsaView extends Component {
  constructor(props) {
    super(props);
    this.tableStructure_knowledge = [
      {
        field: "Statement",
        headerText: "Knowledge Statement",
        width: 500,
      },
      { field: "Action", headerText: "", width: 220 },
    ];

    this.tableStructure_skills = [
      {
        field: "Statement",
        headerText: "Skills Statement",
        width: 500,
      },
      {field: "Action", headerText: "", width: 220},
    ];

    this.tableStructure_abilities = [
      {
        field: "Statement",
        headerText: "Ability Statement",
        width: 500,
      },
      {field: "Action", headerText: "", width: 220},
    ];
  }

  render() {
    const { ksaListByType } = this.props;
    let ksaType =
      ksaListByType && ksaListByType.length > 0 ? this.props.ksaType : null;
    if (!ksaType) {
      return null;
    }

    let tableData = [];
    ksaListByType &&
      ksaListByType.map((d) => {
        tableData.push({
          Id: d.KSAId,
          Statement: d.Name,
          Actions: "",
        });
      });
    console.log("Right KSALIST", ksaType);
    return (
      <Table
        tableStructure={
          ksaType == 1
            ? this.tableStructure_knowledge
            : ksaType == 2
            ? this.tableStructure_skills
            : this.tableStructure_abilities
        }
        ksaType={ksaType}
        tableData={tableData}
        handleDelete={this.props.handleDelete}
        handleEdit={this.props.handleEdit}
        ksaView={true}
      />
    );
  }
}

export default KsaView;
