import axios from 'helpers/axiosinstance';

import {
  ADD_LOADING,
  REMOVE_LOADING,
} from "./types";
import shortid from "shortid";

export const addLoading = (data:any,type:string="normal"):any => (dispatch:any):any => {
  dispatch({
    type: ADD_LOADING,
    payload:data,
    loadingType:type
  });
};
