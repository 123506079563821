import React, {useEffect, useState} from "react";

import Table from "components/customTable";
import {FaAngleDown, FaAngleRight} from "react-icons/fa";
import {HiOutlineChevronLeft} from "react-icons/hi";
import RowActionCell from "./rowActionCell";
import SubrowActionCell from "./subrowActionCell";
import {formatedShortMonthDayYear} from "helpers/dateFormat";
import Button from "components/button";
import EstimatedHourCell from "./estimatedHourCell";
import ActiveProjectOrDetail from "./activeProjectOrDetail";

export default function LegalClientReactTable(props) {
  const {dataSource} = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataSource) {
      var newData = dataSource.map((x) => ({
        ...x,
        EstimatedHours: 0,
        IsParent: true,
        subRows: x.ClientProjectList.map((y) => ({
          ...y,
          IsParent: false,
          ClientName: y.ProjectName,
          TypeName: y.TypeName,
          EstimatedHours: y.EstimatedHours ? y.EstimatedHours / 60 : "-",
        })),
      }));
      setData(newData);
      console.clear();
      console.log(newData);
    }
  }, [dataSource]);
  const expander: any = [
    {
      // Build our expander column
      id: "expander", // Make sure it has an ID
      width: "55",
      // sticky: 'left',
      // Footer: “”,
      //   Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
      //     <span {...getToggleAllRowsExpandedProps()}>
      //       {isAllRowsExpanded ? “:point_down:” : “:point_right:”}
      //     </span>
      //   ),
      Cell: ({row}: any) =>
        row.canExpand ? (
          <div
            key={row.index}
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 20}px`,
              },
            })}
          >
            {row.isExpanded ? <FaAngleDown /> : <FaAngleRight />}
          </div>
        ) : null,
    },
  ];
  const cols = [
    {
      Header: "Client Name",
      accessor: "ClientName",
      width: 180,
      Cell: (cellProps) => {
        const data = cellProps.row?.original;
        return (
          <span className="detail">
            <span className="project">
              {!data.IsParent && <span className="project-icon">P</span>}
              <span>{data.ClientName}</span>
            </span>
          </span>
        );
      },
    },
    {
      Header: "Type",
      accessor: "TypeName",
      width: 110,
    },
    {
      Header: "Projects",
      accessor: "ActiveProjectCount",
      Cell: ActiveProjectOrDetail,
    },
    {
      Header: "",
      accessor: "EstimatedHours",
      width: 200,
      Cell: (cellProps) => <EstimatedHourCell {...cellProps} {...props} />,
    },

    {
      Header: "",
      accessor: "Id",

      Cell: (cellProps) => {
        const data = cellProps.row?.original;
        if (!data) return null;
        if (data.IsParent) return <RowActionCell {...cellProps} {...props} />;
        else if (data.IsParent == false)
          return <SubrowActionCell {...cellProps} {...props} />;
        return null;
      },
    },
  ];
  return (
    <div className="table-tasktracker">
      <Table
        isFixedWidth
        data={data ?? []}
        columns={[...expander, ...cols]}
        isExpanded={false}
      />
    </div>
  );
}
