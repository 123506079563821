import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesDashboad } from "scenes/lms/helpers/action";
import { CourseDashboardStatus } from "scenes/lms/helpers/enums";
import CourseStatusTabBar from "../courseStatusTabBar";
import CourseCard from "./courseCard";
const initialState = { pageIndex: 0, pageSize: 8 };

const OtherAvailableCourses = ({ status }) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const { courseDashboard } = useSelector((state: any) => state.lmsReducer);

  useEffect(() => {
    checkIfActive() && loadAssignedCourses({ ...state, status });
  }, [status]);

  const loadAssignedCourses = (param) => {
    //dispatch(getAssignedCoursesDashboad(param));
    dispatch(getCoursesDashboad(param));
  };
  const checkIfActive = () =>
    status != CourseDashboardStatus.Assign && status != CourseDashboardStatus.Deadline;

  const handleViewMore = () => {
    setState((prev) => {
      let newState = { ...prev };
      newState.pageIndex++;
      loadAssignedCourses({ ...newState, status });
      return newState;
    });
  };
  const showViewMore = () => {
    let totalData = 0;
    if (courseDashboard && courseDashboard.length > 0)
      totalData = courseDashboard[0].TotalRows;
    const pageWiseData = (state.pageIndex + 1) * state.pageSize;
    return totalData > pageWiseData;
  };

  return (
    checkIfActive() &&
    courseDashboard?.length > 0 && (
      <div className="courses-public">
        <div className="courselist-header  header-course">
          Explore other available courses
        </div>
        <div className="courses-public__container">
          <ul className="courses-assigned__list">
            {courseDashboard?.map((item, index) => (
              <li className="courses-assigned__item" key={index}>
                <CourseCard item={item} />
              </li>
            ))}
          </ul>
          {showViewMore() && (
            <div className="courses-viewmore">
              <span onClick={handleViewMore}>View More</span>
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default OtherAvailableCourses;
