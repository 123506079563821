import React, {useEffect} from "react";
import FormItem from "components/formGroup/formItem";
import FormGroup from "components/formGroup";
import Button from "components/button";
import {useDispatch, useSelector} from "react-redux";
import {
  addRosterScheduleEmployeeByShift,
  getRosterScheduleAllEmployeeList,
  getRosterScheduleList,
  getRosterScheduleEmployeeList,
  getRosterScheduleEmployeeListWithWorkingHour,
} from "scenes/roster/helpers/action";
import {useForm} from "react-hook-form";

function ShiftAssignment(props) {
  const {onModalClose, timeData, teamId, startDate, endDate} = props;
  const {scheduleAllEmployeeList} = useSelector((state: any) => state.roosterReducer);

  const assignableEmployees =
    scheduleAllEmployeeList &&
    scheduleAllEmployeeList
      .filter((x) => x.IsOnLeave === false && x.IsWorkDay === true)
      .map((item) => ({
        FullName: item?.EmployeeName,
        Id: item.EmployeeId,
        ...item,
      }));

  const unAssignableEmployees =
    scheduleAllEmployeeList &&
    scheduleAllEmployeeList
      .filter((x) => x.IsOnLeave === true || x.IsWorkDay === false)
      .map((item) => ({
        FullName: item?.EmployeeName,
        Id: item.EmployeeId,
        ...item,
      }));

  const dispatch = useDispatch();
  const method = useForm();
  const {setValue, watch, control} = method;
  const onSubmit = async (data) => {
    const newData =
      data.assignable &&
      scheduleAllEmployeeList &&
      scheduleAllEmployeeList
        .filter((x) => data.assignable.includes(x.EmployeeId))
        .map((item) => ({
          shiftScheduleId: timeData?.ShiftScheduleId,
          employeeId: item.EmployeeId,
          teamRoleId: item.TeamRoleId, //teamId?.value,
          shiftRotationStartDay: item.ShiftRotationStartDay,
        }));
    if (newData) {
      const res: any = await dispatch(
        addRosterScheduleEmployeeByShift(
          newData,
          startDate,
          endDate,
          timeData?.ShiftScheduleId
        )
      );
      if (res && res?.Status) {
        getRosterSchedule();
        onModalClose();
      }
    }
  };
  useEffect(() => {
    const teamParam = {
      teamId: teamId.value,
      ShiftScheduleId: timeData.ShiftScheduleId,
    };

    dispatch(getRosterScheduleAllEmployeeList(teamParam));
  }, []);

  var assignable = watch(assignable, control);

  useEffect(() => {
    if (timeData && timeData?.allEmployees) {
      const emps = timeData?.allEmployees.map((item) => item.EmployeeId);
      setValue("assignable", emps);
      console.log("assignable", emps);
      // allEmployees
    }
  }, [timeData]);

  const getRosterSchedule = async () => {
    const param = {
      startDate: startDate,
      endDate: endDate,
      teamId: teamId.value,
    };
    const promiseArray = [
      dispatch(getRosterScheduleList(param)),
      dispatch(getRosterScheduleEmployeeList(param)),
      dispatch(getRosterScheduleEmployeeListWithWorkingHour(param)),
    ];
    await Promise.all(promiseArray);
  };

  return (
    <FormGroup method={method} onSubmit={onSubmit}>
      <div className="shift-basic">
        <div className="shift-basic__row shift-basic__time">
          <span>{timeData?.dateWithDay}</span>
        </div>
        <div className="shift-basic__row shift-basic__row-2">
          <div className="shift-basic__col">
            <FormItem
              formName="checkBoxGroup"
              name={"assignable"}
              label="Assignable"
              checkGroupArray={assignableEmployees}
              checkedGroupValue={[]}
              multiple={true}
            />
          </div>
          <div className="shift-basic__col">
            <FormItem
              formName="checkBoxGroup"
              disabled
              hide
              name={"unassignable"}
              label="Unassignable"
              checkGroupArray={unAssignableEmployees}
              checkedGroupValue={[]}
              multiple={false}
            />
          </div>
        </div>

        <div className="shift-basic__row flex">
          <Button
            onClick={() => onModalClose()}
            size="large"
            bg="subtle"
            title="Cancel"
          />
          <Button bg="primary" size="large" htmlButtonType="submit" title="Save" />
        </div>
      </div>
    </FormGroup>
  );
}

export default ShiftAssignment;
