import React from 'react'
import Button from 'components/button'

function EmailConfiguration() {
  const onView = () =>{

  }
  const onEdit = () =>{
    
  }
  return (
    <div className='appraisals-container'>
      <div className="appraisals-title">
        <span>E-Mail Configuration</span>
      </div>
      <AppraisalTemplate title={"E-Mail Templates"}>
          <AppraisalTemplateContain title={"Performance Appraisal"}>
              <AppraisalTemplateRow 
                title={"Invitation to complete Self Review"}
                sub="Some description here"
                badge={"workflow: self then manager or simultaneous"}
                onView={onView}
                onEdit={onEdit}
              />
              <AppraisalTemplateRow 
                title={"Mail to manager when employee completes Self Review"}
                sub="Some description here"
                badge={"workflow: self then manager"}
                onView={onView}
                onEdit={onEdit}
              />
              <AppraisalTemplateRow 
                title={"Invitation to manager to complete appraisal"}
                sub="Some description here"
                badge={"workflow: manager only or simultaneous"}
                onView={onView}
                onEdit={onEdit}
              />
          </AppraisalTemplateContain>
          <AppraisalTemplateContain title={"360 Surveys"}>
            <AppraisalTemplateRow 
                title={"Invitation to provide feedback (single)"}
                sub="Some description here"
                onView={onView}
                onEdit={onEdit}
              />
            <AppraisalTemplateRow 
                title={"Invitation to provide feedback (group)"}
                sub="Some description here"
                onView={onView}
                onEdit={onEdit}
              />
          </AppraisalTemplateContain>
          <AppraisalTemplateContain title={"Check-ins"}>
            <AppraisalTemplateRow 
                title={"Invitation to complete check-in"}
                sub="Some description here"
                onView={onView}
                onEdit={onEdit}
              />
            <AppraisalTemplateRow 
                title={"Mail to Manager when employee completes a check-in"}
                sub="Some description here"
                onView={onView}
                onEdit={onEdit}
              />
          </AppraisalTemplateContain>
          <AppraisalTemplateContain title={"Assessment Result"}>
            <AppraisalTemplateRow 
                title={"Result shared with employee"}
                sub="Some description here"
                onView={onView}
                onEdit={onEdit}
              />
          </AppraisalTemplateContain>
      </AppraisalTemplate>
    </div>
  )
}

const AppraisalTemplate = ({children,title}) =>{
  return(
    <div className="appraisals-panel">
        <div className="appraisals-panel__header">
            <div className="appraisals-panel__title">{title}</div>
        </div>
        <div className="appraisals-panel__body">
            {children}
        </div>
      </div>
  )
}


const AppraisalTemplateContain = ({children,title}) => {
  return(
        <div className="appraisals-panel__contain">
          <div className="appraisals-panel__contain-title">{title}</div>
          <div className="appraisals-panel__contain-body">
              {children}
          </div>
        </div>
  )
}

const AppraisalTemplateRow = ({sub,title,onView,onEdit,badge=null}) => {
  return(
      <div className="appraisals-panel__contain-row">
          <div className="appraisals-panel__contain-row-details">
              <div className="appraisals-panel__contain-row-description">
                <div className="appraisals-panel__contain-row-title">
                  {title}
                </div>
                <div className="appraisals-panel__contain-row-sub">
                  {sub}
                </div>
              </div>
              {badge && <div className="appraisals-panel__contain-row-badge">
                {badge}
              </div>}
          </div>
          <div className="appraisals-panel__contain-row-buttons">
              <Button 
                  onClick={onView}
                  bg="white"
                  title='View'
              />
              <Button 
                  onClick={onEdit}
                  bg="white"
                  title='Edit'
              />
          </div>
      </div>  
  )
}

export default EmailConfiguration