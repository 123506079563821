import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import Button from "components/button";
import {FcCheckmark} from 'react-icons/fc';
import dayjs from 'dayjs';

const ButtonCell = (props) => {
    const {original} = props.row;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true);
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
      console.log("Edit");
    };
    return (
      <div className="appraisals-table__col">
        <div className="appraisals-table__links">
          <Button onClick={() => onModalOpen()} size="small" title="Edit" bg="secondary" />
        </div>
        <Modal
          open={open}
          onModalClose={onModalClose}
          className="hide-modal-overflow"
          title={
            original?.EmployeeId + " - " + original?.EmployeeName ?? "Edit Employee Team"
          }
          width="450px"
        >
          <span>Emmp</span>
        </Modal>
      </div>
    );
};

const EmpCell = (props) => {
    const {original} = props.row;
    return(
        <div className="appraisals-table__col">
            <div className="appraisals-table__emp">
                <div className="appraisals-table__emp-image">
                    <img src={original.EmployeeImage} alt="" />
                </div>
                <div className="appraisals-table__emp-link">
                    {original.EmployeeName}
                </div>
            </div>
        </div>
    )
}

const ReviewCell = (props) => {
    const {value} = props;
    return(
        <div className="appraisals-table__col">
            {
                value ? <div className="appraisals-table__icon"><FcCheckmark /></div> : <span>Not Started</span>
            }
        </div>
    )
}

const DateCell = (props) => {
    const {value} = props;
    return(
        <div className="appraisals-table__col">
            {
                value && dayjs(value).format("YYYY-MM-DD")
            }
        </div>
    )
}

const StatusCell = (props) => {
    const {value,original} = props;
    return(
        <div className="appraisals-table__col">
            {
                value ? 
                    <div className="appraisals-table__badge appraisals-table__badge-green">Done</div> : 
                    <div className="appraisals-table__badge">Done on {original?.DueDays} days</div>
            }
        </div>
    )
}

function PerformanceTable(props) {
    const {pageIndex, pageSize, handleRowChange, handleIndexChange} = props;
    const {rollouts} = useSelector((state: any) => state.appraisalReducer);
    const cols = [
        {
          Header: "Employee Name",
          accessor: "Id",
          Cell: EmpCell,
          width: 200,
        },
        // {
        //   Header: "Employee Name",
        //   accessor: "EmployeeName",
        //   width: 240,
        // },
        {
          Header: "Position",
          accessor: "Position",
          width: 150,
        },
        {
          Header: "Self Review",
          accessor: "SelfReview",
          width: 120,
          Cell:ReviewCell
        },
        {
            Header: "Deadline",
            accessor: "SelfReviewDeadline",
            width: 120,
            Cell:DateCell,
        },
        {
            Header: "Manager Review",
            accessor: "ManagerReview",
            width: 130,
            Cell:ReviewCell
        },
        {
            Header: "Manager Deadline",
            accessor: "ManagerDeadline",
            width: 130,
            Cell:DateCell,
        },
        {
            Header: "Status",
            accessor: "Status",
            width: 140,
            Cell:StatusCell
        },
        {
          Header: "Actions",
          accessor: "EmployeeId",
          Cell: ButtonCell,
          width: 120,
        },
      ];
    return (
        <div className="appraisals-table">
            <Table isFixedWidth data={ rollouts ?? []} columns={cols} />
        </div>
    )
}

export default PerformanceTable