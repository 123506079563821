import CommonAPIs from "./endpoints/v1/common";
import LMSAPIs from "./endpoints/v1/lmsAPIs";
import RoosterAPIs from "./endpoints/v1/rosterAPIs";
import AppraisalsAPIs from "./endpoints/v1/appraisal";
import SbiLoanAPIs from "./endpoints/v1/SbiLoanAPIs";
import AuthApis from "./endpoints/v1/authAPIs";
import Recruitment from "./endpoints/v1/recruitment";
import TaskManagements from "./endpoints/v1/taskManagement";

export default function baseEnv(baseApi) {
  const baseUrl = document.getElementsByTagName(`base`)[0].getAttribute(`href`) as string;
  let v1 = `/v1`;
  return {
    route: {
      baseRoute: baseUrl, // Fixes issue with Github Pages
    },

    api: {
      v1: {
        auth: AuthApis,
        common: CommonAPIs,
        permissions: {},
        lms: LMSAPIs,
        roster: RoosterAPIs,
        loan: {
          primeMultipleLoanApprover: `${v1}/loan/prime-multiple-loan-approvers`,
          primeLoanApprover: `${v1}/loan/prime-loan-approvers`,
        },
        appraisals: AppraisalsAPIs,
        sbi: SbiLoanAPIs,
        recruitment: Recruitment,
        taskManagements: TaskManagements,
        travel: {
          settings: `${v1}/travel/settings`,
          // travel request
          advanceTravelRequest: `${v1}/travel/settlements/advancereport`,
          advanceTravelCategoryList: `${v1}/travel/travel-categories/list`,

          // travel allowance expenses
          getAllowanceExpense: `${v1}/travel/allowance-expenses`,
        },
        taskTracker: {
          // task tracer project listing
          taskTrackerPorjectDetailsList: `${v1}/tt/projects/details-list`,
          taskTrackerProjectTypes: `${v1}/tt/client-types`,
        },
        payroll: {
          overtime: `${v1}/payroll/overtime-requests/admin-view`,
          allowance: `${v1}/payroll/allowances/admin-view`,
        },
        employee: {
          officeContact: `${v1}/employee-record/change-request/office-contact`,
          time: {
            timeRequest: `${v1}/leave-time/shift/shift-names`,
          },
        },
      },
    },
    isProduction: true,
    isDevelopment: false,
    isStaging: false,
    isTesting: false,
  };
}
