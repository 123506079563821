import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {FaRegUserCircle, FaRegComment, FaRegSmile, FaCheck} from "react-icons/fa";
import FormGroup from "components/form";
import Icons from "components/icons";
import Avatar from "react-avatar";
import {GetEmployeeByName} from "../../../../helpers/action";
import classNames from "classnames";
import {convetToDataSource, convetFilterToDataSource} from "./helper";

const BoardMember = (props) => {
  const {selectedMembers, disableRemoteSearch} = props;
  const [search, setSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    let selected = convetToDataSource(selectedMembers, true, base);
    setSelectedEmployee(selected);
  }, [selectedMembers]);

  useEffect(() => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    if (disableRemoteSearch && props.boardMembers) {
      let members = convetFilterToDataSource(props.boardMembers, selectedMembers, base);
      setFilteredEmployees(members);
    }
  }, [props.boardMembers, selectedMembers]);

  const onChange = async (name, value) => {
    setSearch(value);
    let filteredData = await getEmployeeList(value);
    setFilteredEmployees(filteredData);
  };

  const getEmployeeList = async (name) => {
    let res = [];

    let boardMembers = [...props.boardMembers];
    if (!disableRemoteSearch) {
      if (name.length > 2) res = await props.GetEmployeeByName(name);
      else res = [];
    } else if (disableRemoteSearch && name.length == 0) {
      res = boardMembers || [];
    } else
      res =
        boardMembers.filter(
          (x) =>
            x.FullName && x.FullName.toLowerCase().includes(name && name.toLowerCase())
        ) || [];

    res = res.map((x) => ({
      ...x,
      isChecked:
        selectedEmployee &&
        selectedEmployee.find((member) => member.EmployeeId === x.EmployeeId)
          ? true
          : false,
    }));

    /*if (searchText !== "") {
      filteredData = filteredData.filter(
        (x) => x.FullName && x.FullName.toLowerCase().includes(searchText)
      );
    } */
    return res;
  };

  const onItemClicked = (items, index) => {
    let newFilteredEmployees = JSON.parse(JSON.stringify(items));
    let selectedList = [...selectedEmployee];

    newFilteredEmployees[index].isChecked = !newFilteredEmployees[index].isChecked;

    if (!newFilteredEmployees[index].isChecked) {
      let inx = selectedList.findIndex(
        (x) => x.EmployeeId === newFilteredEmployees[index].EmployeeId
      );
      if (inx > -1) {
        selectedList.splice(inx, 1);
      }
    }

    let selected = [
      ...selectedList,
      ...newFilteredEmployees.filter(
        (x) =>
          x.isChecked && !selectedEmployee.map((y) => y.EmployeeId).includes(x.EmployeeId)
      ),
    ];
    setFilteredEmployees(newFilteredEmployees);
    setSelectedEmployee(selected);
    props.onSelectionChange && props.onSelectionChange(selected);
  };

  const onRemoveItemClicked = (index) => {
    setSearch("");
    const newItems = [...selectedEmployee];
    var removedMembers = newItems.splice(index, 1);
    let newFilteredEmployees = [...filteredEmployees];

    newFilteredEmployees = newFilteredEmployees.map((x) => ({
      ...x,
      isChecked: newItems.map((y) => y.EmployeeId).includes(x.EmployeeId),
    }));

    setFilteredEmployees(newFilteredEmployees);
    // setSelectedEmployee(newItems);
    setSelectedEmployee(newItems);
    props.onMemberRemoved && props.onMemberRemoved(newItems, removedMembers);
  };

  const renderListItem = (items) => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    return (
      <div className="search-team__section">
        {search.length === 0 && <span className="search-team__text">Team Members</span>}
        <ul className="search-team__emplist">
          {items.map((item, index) => (
            <li
              key={index}
              className="search-team__empitem"
              onClick={(e) => {
                onItemClicked(items, index);
              }}
            >
              <div className="search-team__empitem-image">
                <Avatar
                  className="team-member"
                  name={item.FullName}
                  size={"25"}
                  round={true}
                  src={`${base}${item.Imagepath}`}
                />
              </div>
              <div className="search-team__empitem-name">
                <span>{item.FullName}</span>
              </div>
              {item.isChecked && (
                <div style={{padding: 5}}>
                  <FaCheck size="14" color="#172B4D" />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSelectedListItem = (items) => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    return (
      <div className="search-team__section">
        {items.length === 0 ? (
          <span className="search-team__text">No Member Selected</span>
        ) : (
          <span className="search-team__text">
            {disableRemoteSearch ? "TEAM MEMBERS" : "BOARD MEMBERS"}
          </span>
        )}

        <ul className="search-team__emplist">
          {items.map((item, index) => (
            <li className="search-team__empitem" key={index}>
              <div className="search-team__empitem-image">
                <Avatar
                  className="team-member"
                  name={item.FullName}
                  size={"25"}
                  round={true}
                  src={`${base}${item.Imagepath}`}
                />
              </div>
              <div className="search-team__empitem-name">
                <span>{item.FullName}</span>
              </div>
              {canModify() && (
                <div
                  className="search-team__empitem-close"
                  onClick={(e) => {
                    onRemoveItemClicked(index);
                  }}
                >
                  <Icons name="Close" size={10} color="#fff" />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const canModify = () => {
    const {auth, board} = props;
    const {user} = auth;
    if (board.IsArchived) return false;
    if (user.RoleName === "Administrator") {
      return true;
    }
    return user.UserID === board.BoardOwner || board.IsRecurring;
  };

  return (
    <>
      <div className="search-team">
        {canModify() && (
          <FormGroup
            name="Search"
            autoComplete="off"
            value={search}
            placeholder="Search Team Member"
            enableClearText={true}
            autoFocus
            onChange={(name, value) => onChange(name, value)}
          />
        )}
        <div className="search-team-members">
          {/* {search.length === 0 && renderSelectedListItem(selectedEmployee)} */}
          {search.length === 0
            ? renderSelectedListItem(selectedEmployee)
            : renderListItem(filteredEmployees)}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  employeeListSelect: state.commonReducer.employeeListSelect,
  boardMembers: state.taskReducer.boardMembers,
  board: state.taskReducer.board,
  auth: state.auth,
});
const mapDispatchToProps = {
  GetEmployeeByName,
};
export default connect(mapStateToProps, mapDispatchToProps)(BoardMember);
