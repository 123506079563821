import React, {useEffect, useState} from "react";
import Button from "components/button";
import FormGroup from "components/form";
import {GetEmployeeList} from "actions/commonAction";
import {GetonBoardingAccessID, GetOnboardingEmployeeDetail} from "../action";
import {useSelector} from "react-redux";
import isEmpty from "isEmpty";
import {useDispatch} from "react-redux";
import TableData from "./assessIDTable";
import Loaders from "components/loaders";
import {getEngDate} from "components/datePicker/helpers";

export const AuthorityList = [
  {label: "Branch Initiator", value: 1},
  {label: "Branch Head", value: 2},
  {label: "Province Head", value: 3},
  {label: "Corporate Office", value: 4},
  {label: "Department Head", value: 5},
  {label: "Multiple Specific Person", value: 6},
];

function AssessIds(props) {
  const [messageTemplate, setMessageTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [rowDatas, setRowDatas] = useState([]);
  const [editData, setEditData] = useState([]);
  const dispatch = useDispatch();
  const skipPageResetRef = React.useRef(false);

  const {accessIdTemplates, onboardingEmployeeDetail, requesting} = useSelector(
    (state) => state.employeeOnboarding
  );
  useEffect(() => {
    (async () => {
      await dispatch(GetonBoardingAccessID());
      await dispatch(GetEmployeeList());
      await dispatch(GetOnboardingEmployeeDetail(props.match.params.id));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    let data = [...onboardingEmployeeDetail.EmployeeOnboardingAccessAndId];
    if (!isEmpty(onboardingEmployeeDetail?.EmployeeOnboardingAccessAndId)) {
      setRowDatas(data);
      setMessageTemplate([]);
    } else {
      if (!isEmpty(accessIdTemplates)) {
        setMessageTemplate([]);
        setRowDatas([]);
        setEditData([]);
      }
    }
  }, [accessIdTemplates]);

  const removeAccessIdRow = (rowData) => {
    let newData = [...rowDatas];
    let sIndex = newData.findIndex((f) => f.Id == rowData.Id);
    newData.splice(sIndex, 1);
    setRowDatas(newData);
  };

  const onClose = () => {
    props.history.push("/admin/employee_onboarding/");
  };

  const handleFieldChange = (name, value, rowData) => {
    if (rowData) {
      let newData = [...rowDatas];
      let sIndex = newData.findIndex((f) => f.Id == rowData.Id);
      let getData = {...newData[sIndex]};
      getData[name] = value;
      newData[sIndex] = getData;
      setRowDatas(newData);
    } else if (value) {
      setMessageTemplate(value);
      let sIndex = accessIdTemplates.findIndex((s) => s.value == value.value);
      setRowDatas(accessIdTemplates[sIndex].subCategory);
    } else {
      setMessageTemplate(value);
      setRowDatas([]);
    }
  };

  const addRow = () => {
    let rowData = [...rowDatas];
    let allValues = rowData.concat({
      TypeName: "",
      ResponsiblePerson: null,
      DueDate: "",
      Note: 0,
      Id: !isEmpty(rowData) ? rowData[rowData.length - 1].Id + 1 : 0,
    });
    setRowDatas(allValues);
  };

  const onSave = (data) => {
    skipPageResetRef.current = true;
    setRowDatas((prev) => prev.map((item) => (data.Id === item.Id ? data : item)));
  };

  const handleFormSubmit = async () => {
    let formData = [...rowDatas];
    let isNameBlank = false;
    formData =
      formData &&
      formData.map((f) => {
        if (isEmpty(f.TypeName)) isNameBlank = true;
        return {
          ...f,
          // DueDateEng: getEngDate(f.DueDate),
          DueDateEng: f.DueDate,
          ResponsiblePersonId: f.ResponsiblePerson ? f.ResponsiblePerson.value : null,
        };
      });
    if (isNameBlank) {
      props.DisplayClientSideMessage("Please fill all the Type Name");
      return;
    }
    props.handleSubmit(formData, "2", 3);
  };

  return (
    <div className="date-and-place">
      <div className="date-and-place__container">
        <div className="date-and-place__body">
          <div className="date-and-place__header">Access and IDs</div>
          <div className="date-and-place__header">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => handleFieldChange(name, value)}
              name="messageTemplate"
              value={messageTemplate}
              options={accessIdTemplates}
              loadingType="skeleton"
              placeholder="Access & ID Category"
              width="250px"
            />
          </div>
          {rowDatas?.length > 0 && (
            <div className="mb-sm">
              <TableData
                rowData={rowDatas}
                onClose={(data) => removeAccessIdRow(data)}
                handleFieldChange={(name, value, rowData) =>
                  handleFieldChange(name, value, rowData)
                }
              />
            </div>
          )}
          <Button bg="success" title="Add Row" onClick={() => addRow()} />
          <div className="date-and-place__footer">
            <Button
              bg="primary"
              title="Save and Next"
              onClick={() => handleFormSubmit()}
              isDisabled={requesting}
            />
            <Button bg="subtle" onClick={() => onClose()} title="Cancel" />
          </div>
        </div>
      </div>
      {requesting ? <Loaders loading={requesting} /> : null}
    </div>
  );
}

export default AssessIds;
