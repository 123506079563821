import React, { Component } from "react";
import Table from "./table";

export class Certifications extends Component {
  constructor(props) {
    super(props);
    this.tableStructure = [
      {
        field: "Name",
        headerText: "Certification Name",
        width: 350,
      },
      { field: "Type", headerText: "Type", width: 180 },
      {
        field: "RenewalFrequency",
        headerText: "Renewal Frequency",
        width: 180,
      },
      { field: "Action", headerText: "", width: 180 },
    ];
  }

  render() {
    const { certificationList } = this.props;
    let tableData = [];
    certificationList &&
      certificationList.map((certificate) => {
        tableData.push({
          Id: certificate.CertificationId,
          Name: certificate.Name,
          Type: certificate.TypeName,
          RenewalFrequency: certificate.RenewalFrequency,
          Actions: "",
        });
      });
    return (
      <Table
        tableStructure={this.tableStructure}
        tableData={tableData}
        handleDelete={this.props.handleDelete}
        handleEdit={this.props.handleEdit}
      />
    );
  }
}

export default Certifications;
