import {
  SEARCHED_ATTENDANCE_REGISTER,
  CLEAR_ERRORS,
  GET_ERRORS,
  GET_COST_CODES,
  COST_CODES_LOADING,
  GENERATE_ATTENDANCE_REGISTER,
  UNIT_LIST_LOADING,
  GET_UNIT_LIST,
  COLORS_LOADING,
  GET_COLORS,
  ATTENDANCE_LOADING,
} from "../../../actions/types";
import isEmpty from "isEmpty";
import {toastMessage} from "../../../actions/validateAction";
import axios from "helpers/axiosinstance";

// Get Filtered Leave
export const searchAttendance = (data) => (dispatch) => {
  dispatch(setAttendanceLoading());
  dispatch({
    type: SEARCHED_ATTENDANCE_REGISTER,
    payload: null,
    page: {},
  });
  axios
    .post(`/v1/leave-time/attendance-register`, data)
    .then((res) => {
      dispatch({
        type: SEARCHED_ATTENDANCE_REGISTER,
        payload: res.data.Data,
        page: {index: res.data.PageIndex, total: res.data.RecordsTotal},
      });
      toastMessage(res);
    })
    .catch((err) =>
      dispatch({
        type: SEARCHED_ATTENDANCE_REGISTER,
        payload: null,
        page: {},
      })
    );
};

export const generateAttendance = (data) => (dispatch) => {
  dispatch(setAttendanceLoading());
  axios
    .put(
      `/v1/attendance/attendance-register?payrollPeriodId=${data.payrollPeriodId}&employeeId=${data.employeeId}`
    )
    .then((res) => {
      // console.log(res)
      dispatch({
        type: GENERATE_ATTENDANCE_REGISTER,
        payload: res.data.Data,
        page: {index: res.data.PageIndex, total: res.data.RecordsTotal},
      });
      toastMessage(res);
    })
    .catch((err) =>
      dispatch({
        type: GENERATE_ATTENDANCE_REGISTER,
        payload: null,
      })
    );
};

// Get Cost Code
export const getCostCodes = () => (dispatch) => {
  dispatch({type: COST_CODES_LOADING});
  axios
    .get(`/v1/common/shared/costcodes`)
    .then((res) => {
      dispatch({
        type: GET_COST_CODES,
        payload: res.data,
      });
      toastMessage(res);
    })
    .catch((err) =>
      dispatch({
        type: GET_COST_CODES,
        payload: null,
      })
    );
};

// Get Cost Code
export const getUnitList = () => (dispatch) => {
  dispatch({type: UNIT_LIST_LOADING});
  axios
    // .get(`/v1/common/shared/unitlist`)
    .get(`/v1/payroll/units`)
    .then((res) => {
      let formattedData = [];
      res.data.forEach((unit) => {
        formattedData.push({
          label: unit.Text,
          value: unit.Value,
          selected: unit.Selected,
        });
      });

      dispatch({
        type: GET_UNIT_LIST,
        payload: formattedData,
      });
      toastMessage(res);
    })
    .catch((err) =>
      dispatch({
        type: GET_UNIT_LIST,
        payload: null,
      })
    );
};
// Get Cost Code
export const getColors = () => (dispatch) => {
  dispatch({type: COLORS_LOADING});
  axios
    // .get(`/v1/attendance/attendance-register/legend`)
    .get(`/v1/leave-time/attendance-register/legend`)
    .then((res) => {
      dispatch({
        type: GET_COLORS,
        payload: res.data,
      });
      toastMessage(res);
    })
    .catch((err) =>
      dispatch({
        type: GET_COLORS,
        payload: null,
      })
    );
};

// Set loading state
export const setAttendanceLoading = () => {
  return {
    type: ATTENDANCE_LOADING,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
