import React, {useState} from "react";
import dayjs from "dayjs";
import Avatar from "react-avatar";
import Button from "components/button";
import Dropdown from "components/dropdown";
import Modal from "components/modal";

export const DateCell = ({value}) => {
  return <span>{dayjs(value).format("YYYY-MM-DD")}</span>;
};

export const EmpCell = (props) => {
  const {baseURL} = props;
  const {original} = props.row;
  return (
    <div className="course-assign__emp">
      <div className="course-assign__emp-image">
        <Avatar
          size="32px"
          round
          src={`${baseURL}${original.UrlPhoto}`}
          name={original.EmployeeName}
        />
      </div>
      <div className="course-assign__emp-link">{original.EmployeeName}</div>
    </div>
  );
};

export const StatusCell = (val) => {
  const {
    row: {original},
  } = val;

  let statusClass = "completed";
  statusClass =
    original.Status === 0
      ? "not-started"
      : original.Status === 1
      ? "in-progress"
      : "completed";

  return (
    <div className="course-assign__status">
      <span className={`status-block ${statusClass}`}>
        {original && original.StatusName}
      </span>
    </div>
  );
};

const Confirm = ({confirm, setConfirm, onConfirmed}) => {
  return (
    <Modal
      type="confirm"
      title={confirm.title}
      func={() => onConfirmed && onConfirmed()}
      open={confirm.open}
      modalMessage={confirm.message}
      onModalClose={() => setConfirm({open: false, title: "", message: ""})}
    />
  );
};

export const ActionCell = (props) => {
  const {
    row: {original},
  } = props;
  const [confirm, setConfirm] = useState({open: false, title: "", message: ""});
  let confirmMessage =
    original.Status === 0
      ? "Are you sure you want to unassign?"
      : "This employee is currently studying this coures. Are you sure you want to unassign?";

  //returns null when completed status=2
  if (original?.Status === 2) return null;

  return (
    <div className="course-drop">
      <Dropdown isFixed>
        <div className="course-drop__list">
          <div
            onClick={() => {
              props.onEditClicked(original);
            }}
            className="course-drop__item"
          >
            <span>Edit</span>
          </div>

          <div
            className="course-drop__item"
            onClick={() =>
              setConfirm({
                open: true,
                title: "Unassign Employee!!",
                message: confirmMessage,
              })
            }
          >
            <span>Unassign</span>
          </div>
        </div>
      </Dropdown>
      <Confirm
        confirm={confirm}
        setConfirm={setConfirm}
        onConfirmed={() =>
          props.onUnassignEmployee && props.onUnassignEmployee(original.AssignedId)
        }
      />
    </div>
  );
};
