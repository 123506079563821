import React, {Component} from "react";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import Loader from "components/loaders";
import {TRAVEL_REQUEST_APPROVAL_FLOW_TYPE} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {ADMIN_ROLE_ID} from "constants/constants";
import {GetEmployeeList} from "actions/commonAction";

import {
  GetOthersTravelRequests,
  ApproveOthersTravelRequests,
  GetApprovalAuthorities,
  GetApprovalHistory,
  GetApprovalFlowSteps,
  clearEditTravelRequest,
} from "../actions";
import CommonHeaderSection from "../../../admin/commonHeaderSection";
import CommonFilters from "../../../employee/commonFilters";
import TravelDetailsBlock from "../common/travelDetailsBlock";

class OthersTravelRequestScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      searchEmployeeText: "",
      branchId: -1,
      searchFlowSteps: "",
      fromDate: null,
      toDate: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.props.GetApprovalFlowSteps(TRAVEL_REQUEST_APPROVAL_FLOW_TYPE);
    await this.props.GetEmployeeList();
    this.initialize();
  }

  componentDidUpdate() {
    if (this.props.reloadData && this.state.needReload) {
      this.setState({needReload: false});
      this.initialize();
    }
  }

  initialize = async () => {
    const {
      pageNumber,
      pageSize,
      searchEmployeeText,
      branchId,
      searchFlowSteps,
      fromDate,
      toDate,
    } = this.state;

    await this.props.GetOthersTravelRequests({
      pageNumber,
      pageSize,
      searchEmployeeText,
      branchId,
      searchFlowSteps,
      fromDate,
      toDate,
    });
  };

  handleSearchTextChange = (text) => {
    this.setState({searchEmployeeText: text}, () => {
      this.initialize();
    });
  };

  handleBranchChange = (branchId) => {
    this.setState({branchId: branchId}, () => {
      this.initialize();
    });
  };

  handleRowChange = (num) => {
    this.setState({pageSize: num}, () => {
      this.initialize();
    });
  };
  handleIndexChange = (num) => {
    this.setState({pageNumber: num + 1}, () => {
      this.initialize();
    });
  };

  handleApprove = (data) => {
    const postData = {
      HistoryID: 0,
      RequestId: data.requestId,
      Status: data.statusId,
      ActionBy: "",
      ActionOn: "",
      ActionRemarks: data.remarks,
      ActionDisplayTitle: "",
      ApprovalEmployeeId: !isEmpty(data.employee) ? parseInt(data.employee.value) : 0,
    };
    const reqData = {requestId: data.requestId};
    this.setState({needReload: true}, async () => {
      await this.props.ApproveOthersTravelRequests(reqData, postData);
      await this.initialize();
    });
  };

  handleFilterChange = (name, selected) => {
    this.setState({[name]: selected}, () => {
      this.initialize();
    });
  };
  handleDateRangeChange = (range) => {
    this.setState({fromDate: range.fromDate, toDate: range.toDate}, () => {
      this.initialize();
    });
  };
  renderBody = () => {
    const {
      othersTravelRequestList,
      isLoading,
      GetApprovalAuthorities,
      GetApprovalHistory,
      approvalAuthorities,
      approvalHistory,
    } = this.props;
    return (
      <div className="travel-create">
        <div className="travel-create__body">
          {othersTravelRequestList && !isLoading ? (
            <TravelDetailsBlock
              rows={othersTravelRequestList}
              parentState={this.state}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
              handleApprove={(requestId) => this.handleApprove(requestId)}
              GetApprovalAuthorities={GetApprovalAuthorities}
              GetApprovalHistory={GetApprovalHistory}
              approvalAuthorities={approvalAuthorities}
              approvalHistory={approvalHistory}
              history={this.props.history}
              clearEditTravelRequest={this.props.clearEditTravelRequest}
              page="otherTravelRequest"
              getTravelRequest={this.initialize}
              pageIndex={this.state.pageNumber}
              rowsPerPage={this.state.pageSize}
              isLoading={isLoading}
            />
          ) : (
            isLoading && (
              <Loader
                type="skeleton"
                name="card"
                cardType="travel"
                noRows={10}
                loading={isLoading}
              />
            )
          )}
        </div>
      </div>
    );
  };

  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    const roleId = RoleId();
    return (
      <>
        {roleId == ADMIN_ROLE_ID ? (
          <CommonHeaderSection
            renderBody={this.renderBody()}
            selectedTabId={selectedTabId}
            showFilterSection={true}
            handleSearchTextChange={(text) => this.handleSearchTextChange(text)}
            handleBranchChange={(branchId) => this.handleBranchChange(branchId)}
            handleFilterChange={this.handleFilterChange}
            handleDateRangeChange={this.handleDateRangeChange}
          />
        ) : (
          <div className="travel__filter-body">
            <CommonFilters
              handleSearchTextChange={(text) => this.handleSearchTextChange(text)}
              handleBranchChange={(branchId) => this.handleBranchChange(branchId)}
              handleFilterChange={this.handleFilterChange}
              handleDateRangeChange={this.handleDateRangeChange}
              selectedTabId={selectedTabId}
            />
            <div className="travel-requestMainBody">{this.renderBody()}</div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  othersTravelRequestList: state.travelInformationReducer.othersTravelRequestList,
  reloadData: state.travelInformationReducer.reloadData,
  isLoading: state.travelInformationReducer.isLoading,
  approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
  approvalHistory: state.travelInformationReducer.approvalHistory,
  intitalLoad: state.travelInformationReducer.otherTravelRequestInitialLoad,
});

const mapDispatchToProps = {
  GetOthersTravelRequests,
  ApproveOthersTravelRequests,
  GetApprovalAuthorities,
  GetApprovalHistory,
  GetApprovalFlowSteps,
  clearEditTravelRequest,
  GetEmployeeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(OthersTravelRequestScreen);
