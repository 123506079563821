import React, {useEffect, useState} from "react";
import Button from "components/button";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import Avatar from "react-avatar";
import {useDispatch, useSelector} from "react-redux";
import {
  addRosterScheduleEmployee,
  removeRosterScheduleEmployee,
  updateRosterScheduleEmployee,
  getRosterScheduleEmployeeInfo,
} from "scenes/roster/helpers/action";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import "./assignModal.scss";

const weekList = [
  {label: "Sun", value: 1},
  {label: "Mon", value: 2},
  {label: "Tue", value: 3},
  {label: "Wed", value: 4},
  {label: "Thu", value: 5},
  {label: "Fri", value: 6},
  {label: "Sat", value: 7},
];

function AssignModal(props) {
  const {
    onShiftModalClose,
    startDate,
    onRemoveAssign,
    teamId,
    endDate,
    getRosterSchedule,
    duration,
    timeData,
    editData,
    isEditOrAssign,
    setIsEditOrAssign,
  } = props;
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState({
    WeeklyHours: 0,
    OTHours: 0,
    OTCost: 0,
    ShiftPatternName: "",
    ShiftPatternInfo: "",
  });
  const shiftId = timeData?.ShiftScheduleId;
  const method = useForm();
  const {setValue, control, watch, reset} = method;
  const {scheduleAllEmployeeList, scheduleList, teamRoleList} = useSelector(
    (state: any) => state.roosterReducer
  );
  const emps =
    scheduleAllEmployeeList &&
    scheduleAllEmployeeList.map((item) => ({
      label: item?.EmployeeName,
      value: item.EmployeeId,
      ...item,
    }));
  const schedules =
    scheduleList &&
    scheduleList.map((item) => ({
      label: item.ShiftName,
      value: item.ShiftScheduleId,
      ...item,
    }));

  const roles =
    teamRoleList &&
    teamRoleList.map((item) => ({label: item.RoleName, value: item.Id, ...item}));

  let employee = watch("EmployeeId");

  useEffect(() => {
    return () => {
      setIsEditOrAssign(true);
      reset();
    };
  }, []);

  useEffect(() => {
    getEmployeeInfo();
  }, [employee]);

  useEffect(() => {
    if (isEditOrAssign && editData && roles && schedules && emps && !isEdit) {
      setIsEdit(true);
      const empss = emps && emps.find((item) => item.value === editData?.EmployeeId);
      const role = roles && roles.find((item) => item.value === editData?.TeamRoleId);
      setValue("EmployeeId", empss);
      setValue("TeamRoleId", role);
      setValue("ShiftRotationStartDay", editData?.ShiftRotationStartDay + "");
      employee = watch("EmployeeId");
    }
  }, [editData, emps, schedules, roles, employee]);

  const getEmployeeInfo = async () => {
    if (!employee) return;

    let param = {
      startDate,
      endDate,
      teamId: teamId && teamId.value,
      employeeId: employee.EmployeeId,
      ShiftId: timeData.ShiftId,
    };

    var res: any = await dispatch(getRosterScheduleEmployeeInfo(param));

    if (!editData) {
      const role = roles && roles.find((item) => item.value === res.TeamRoleId);
      setValue(
        "ShiftRotationStartDay",
        (res.ShiftRotationStartDay && res.ShiftRotationStartDay + "") || ""
      );
      setValue("TeamRoleId", role);
    }

    setEmployeeInfo({
      WeeklyHours: res.TotalHours,
      OTHours: res.OT,
      OTCost: res.OTCost,
      ShiftPatternName: res.ShiftPatternName,
      ShiftPatternInfo: res.ShiftPatternInfo,
    });
  };
  // },[editData,roles,schedules,emps])
  // useEffect(()=>{
  //     if(shiftId && schedules){
  //         const shift = schedules && schedules.find(item=>item.ShiftScheduleId === shiftId)
  //         if(shift){
  //             setValue('shiftScheduleId',shift)
  //         }
  //     }
  // },[shiftId,schedules])
  // useEffect(()=>setValue('shiftRotationStartDay',"0"),[])
  const onSubmit = async (data) => {
    // const shift = schedules && schedules.find(item=>item.ShiftScheduleId === shiftId)
    const finalData = {
      ...data,
      EmployeeId: data?.EmployeeId?.value,
      TeamRoleId: data?.TeamRoleId?.value,
      ShiftScheduleId: shiftId,
      // shiftScheduleId:data?.shiftScheduleId?.value,
    };

    let res: any;
    const stDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
    const edDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";
    if (isEdit) {
      res = await dispatch(
        updateRosterScheduleEmployee(
          {...finalData, id: editData?.ShiftScheduleEmployeeId},
          stDate,
          edDate
        )
      );
    } else {
      res = await dispatch(addRosterScheduleEmployee(finalData, stDate, edDate));
    }

    if (res?.Status) {
      onShiftModalClose();
      getRosterSchedule && getRosterSchedule();
    }
  };
  const onPersonRemove = async () => {
    const res: any = await dispatch(
      removeRosterScheduleEmployee(editData.ShiftScheduleId, [editData?.EmployeeId])
    );
    if (res?.Status) {
      onShiftModalClose();
      getRosterSchedule && getRosterSchedule();
    }
  };

  let shiftPattern =
    employeeInfo &&
    employeeInfo.ShiftPatternInfo &&
    employeeInfo.ShiftPatternInfo.includes(",") &&
    employeeInfo.ShiftPatternInfo.split(",").map(
      (item) => item.includes(":") && item.split(":")
    );

  return (
    <div className="assign-modal__container">
      <div className="assign-modal__col assign-modal__col-left">
        <div className="assign-modal__emp">
          <Avatar
            src={employee?.EmployeeImgPath}
            name={employee?.EmployeeName}
            size={"40px"}
            round="100%"
          />
          <div className="assign-modal__emp-name">{employee?.EmployeeName}</div>
          <div className="assign-modal__emp-designation">{employee?.TeamName}</div>
        </div>
        <div className="assign-modal__work">
          <div className="assign-modal__work-col">
            <div className="time">{employeeInfo.WeeklyHours}</div>
            <div className="time-desc">Weekly Hours</div>
          </div>
          <div className="assign-modal__work-col">
            <div className="time">{employeeInfo.OTHours}</div>
            <div className="time-desc">OT Hours</div>
          </div>
          <div className="assign-modal__work-col">
            <div className="time">{employeeInfo.OTCost}</div>
            <div className="time-desc">OT Cost</div>
          </div>
        </div>
      </div>
      <div className="assign-modal__col assign-modal__col-right">
        <div className="assign-modal__head">{timeData?.dateWithDay}</div>
        <div className="assign-modal__sub">
          <div className="assign-modal__sub-head">{timeData?.ShiftName}</div>
          <div className="assign-modal__sub-time">{duration}</div>
        </div>
        <FormGroup method={method} onSubmit={onSubmit}>
          <div className="assign-modal__form">
            <div className="assign-modal__form-item">
              <FormItem
                name="EmployeeId"
                formName="reactselect"
                options={emps}
                disabled={isEdit}
                control={control}
                width="200px"
                label="Who is working ?"
                rules={{required: "Employee is required!"}}
              />
            </div>
            <div className="assign-modal__form-item">
              {/* <FormItem
                                name="ShiftScheduleId"
                                formName="reactselect"
                                options={schedules}
                                width="200px"
                                label="Shift Pattern"
                            /> */}
              {/* {timeData?.ShiftPatternName && (
                <div className="assign-modal__form-pattern">
                  <div className="assign-modal__form-label">Shift Pattern</div>
                  <div className="assign-modal__form-value">
                    {timeData?.ShiftPatternName}
                  </div>
                </div>
              )} */}

              {shiftPattern && (
                <div className="assign-modal__form-pattern">
                  <div className="assign-modal__form-label flex">
                    <span>Shift Pattern</span>
                    <span className="text-blue ml-xsm">
                      {employeeInfo?.ShiftPatternName}
                    </span>
                  </div>
                  <div className="assign-modal__form-value">
                    <div className="shiftPattern">
                      <div className="shiftPattern-row">
                        {shiftPattern.map((item) => {
                          return (
                            <div className="shiftPattern-col">
                              <div className="shiftPattern-label">{item[0]}</div>
                              <div
                                style={{
                                  color:
                                    item[1].toLocaleLowerCase() === "w"
                                      ? "#0075f6"
                                      : "#363b40",
                                }}
                                className={`shiftPattern-val`}
                              >
                                {item[1]}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="assign-modal__form-item">
              <FormItem
                name="TeamRoleId"
                formName="reactselect"
                options={roles}
                control={control}
                width="200px"
                label="Role"
                rules={{required: "Role is required!"}}
              />
            </div>
            <div className="assign-modal__form-item">
              <FormItem
                formName="radioList"
                name={"ShiftRotationStartDay"}
                control={control}
                label={"Sift Pattern Rotation Start Day"}
                options={weekList}
                rules={{required: "Shift Rotation Start Day is required!"}}
              />
            </div>
            <div className="assign-modal__form-btns space-between">
              <div className="">
                <Button title="Cancel" bg="subtle" onClick={() => onShiftModalClose()} />
                <Button title="Save" bg="primary" htmlButtonType="submit" />
              </div>
              {editData ? (
                <Button
                  bg="white"
                  title="Remove Person"
                  onClick={() => onPersonRemove()}
                />
              ) : (
                <Button
                  title="Remove Position"
                  bg="white"
                  onClick={() => onRemoveAssign()}
                />
              )}
            </div>
          </div>
        </FormGroup>
      </div>
    </div>
  );
}

export default AssignModal;
