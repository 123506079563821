import React from "react";
import { Text } from "../../../addCourse/contents/showItem";

function Contents({ courseContent }) {
  return (
    <div className="course-page__content ">
      {courseContent?.map((item, index) => (
        <div className="course-page__section fr-box fr-basic">
          <Text key={index} {...item} />
        </div>
      ))}
    </div>
  );
}

export default Contents;
