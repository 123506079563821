import React, { useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Modal from "components/modal";
import Button from "components/button";
import { deleteAppraisalRatingScale } from '../helpers/action';


const data = [
    {
        EmployeeImage:'/images/male.png',
        CycleName:'2022',
        AssessmentOptions:'Performance Appraisals 360 Feedback',
        State:true,
    }
]


const ButtonCell = (props) => {
    const {original,value} = props.row;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true);
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onEdit = () => {
      console.log("Edit");
    };
    const onDelete = () => dispatch(deleteAppraisalRatingScale(props.value))
    return (
      <div className="appraisals-table__col">
        <div className="appraisals-table__links">
          <Button onClick={() => onModalOpen()} size="small" title="Edit" bg="secondary" />
          <Button onClick={() => onDelete()} size="small" title="Delete" bg="secondary" />
        </div>
        <Modal
          open={open}
          onModalClose={onModalClose}
          className="hide-modal-overflow"
          title={
            original?.EmployeeId + " - " + original?.EmployeeName ?? "Edit Employee Team"
          }
          width="450px"
        >
          <span>Emmp</span>
        </Modal>
      </div>
    );
};

const StatusCell = (props) => {
    const {value,original} = props;
    return(
        <div className="appraisals-table__col">
            {
                value ? 
                    <div className="">Yes</div> : 
                    <div className="">No</div>
            }
        </div>
    )
}


function RatingScaleTable() {
    const {ratingScales} = useSelector((state:any)=>state.appraisalReducer);
    const cols = [
        {
          Header: "Scale Name",
          accessor: "ScaleName",
          width: 280,
        },
        {
            Header: "Includes NA",
            accessor: "IsIncludeNA",
            width: 140,
            Cell:StatusCell
        },
        {
          Header: "",
          accessor: "Id",
          Cell: ButtonCell,
          width: 120,
        },
    ];
  return (
    <div className="appraisals-table">
        <Table isFixedWidth data={ ratingScales ?? []} columns={cols} />
    </div>
  )
}

export default RatingScaleTable