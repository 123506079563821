import React from 'react'
import FormItem from 'components/form';
import FormGroup from 'components/formGroup';
import Button from 'components/button';
import { useSelector } from 'react-redux';

function GoalFilter(props) {
    const { filters, onChange,onFilter } = props;
    const {branchList,departmentList,jobStatusList,levelList} = useSelector((state:any)=>state.commonReducer);
    const {reviewCyclesDropDown} = useSelector((state:any)=>state.appraisalReducer);
    const onSubmit = (data) => {
        console.log({data})
    }
    return (
        // <FormGroup onSubmit={onSubmit}>
            <div className='appraisals-filter'>
                <FormItem 
                    name='ReviewCycle'
                    label="Review Cycle"
                    options={reviewCyclesDropDown ?? []}
                    formName="reactselect"
                    value={filters.reviewCycle}
                    onChange={onChange}
                />
                <FormItem 
                    name='SearchEmployee'
                    label="Search Employee"
                    value={filters.reviewCycle}
                    onChange={onChange}
                />
                <FormItem 
                    name='Branch'
                    label="Branch"
                    options={branchList ?? []}
                    formName="reactselect"
                    value={filters.reviewCycle}
                    onChange={onChange}
                />
                <FormItem 
                    name='Department'
                    label="Department"
                    options={departmentList ?? []}
                    formName="reactselect"
                    value={filters.reviewCycle}
                    onChange={onChange}
                />
                <FormItem 
                    name='Level'
                    label="Level"
                    options={levelList??[]}
                    formName="reactselect"
                    value={filters.reviewCycle}
                    onChange={onChange}
                />
                <Button 
                    title="Apply Filters"
                    bg="primary"
                    size='small'
                    onClick={()=>onFilter()}
                    // htmlButtonType='submit'                
                />
            </div>
        // </FormGroup>
        
    )
}

export default GoalFilter