import React, {Component, useEffect, useState} from "react";
import isEmpty from "isEmpty";
import {getEmployeeNotices} from "../../action";
import {useDispatch, useSelector} from "react-redux";
import NewsCard from "./newsCard";

const Index = (props) => {
  const [state, setState] = useState({
    height: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeNotices());
  }, []);

  const {employeeNotices} = useSelector((state: any) => state.employeeReducer);
  return (
    <div className="news">
      <div className="messages">
        <h3 className="mypage-section-title">Notices</h3>
        {isEmpty(employeeNotices) ? (
          <div className="no-found-container">
            <span className="no-found">No Notices Found</span>
          </div>
        ) : (
          <div className="news-container">
            <ul className="news-list">
              {employeeNotices?.map((item, index) => (
                <NewsCard item={item} key={index} />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default Index;
