import React from "react";
import {Tabs, Tab} from "components/tabs/index.d";
import JobDescription from "./jobDescription";
import JobProfile from "./jobProfile";
import JobFamily from "./jobFamily";
import JobRole from "./jobRole";

function JobTabs(props) {
  const {handleJobProfileFormShow, activeTabId, setActiveTabId} = props;
  const tabs = [
    {
      id: 1,
      label: "JOB DESCRIPTIONS",
      component: JobDescription,
    },
    {
      id: 2,
      label: "JOB PROFILES",
      component: JobProfile,
    },
  ];

  return (
    <div>
      {tabs && (
        <Tabs
          // type="block"
          noBackground
          activeTab={activeTabId}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                isActive={activeTabId === tab.id}
                setActiveTabId={setActiveTabId}
              >
                <tab.component
                  activeTabId={activeTabId}
                  handleJobProfileFormShow={handleJobProfileFormShow}
                />
              </Tab>
            );
          })}
        </Tabs>
      )}
    </div>
  );
}

export default JobTabs;
