import React, { Component } from "react";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/lib/ReactCrop.scss";
import { connect } from "react-redux";
import { UploadFiles } from 'actions/commonAction'
import isEmpty from 'isEmpty'
import { GoCloudUpload } from 'react-icons/go'
import Modal from 'components/modal'
// import LoginPreview from '../../Login/LoginPreview'
// import { AnimateShowHide } from 'helpers/animation';
import { FiUpload } from 'react-icons/fi'
import { FaRegFileImage, FaImage } from 'react-icons/fa';
import { IoIosMove } from 'react-icons/io'
import Cropper from 'react-easy-crop'
import rigo from "assets/images/rigo-logo-vector.svg";
import getCroppedImg from 'helpers/cropImage';
import banner from 'assets/images/Login-Banner.png'
import Button from 'components/button';

class CropImage extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            src: this.props.img,
            show: false,
            openModal: false,
            resetImages: false,
            blob: '',
            // crop: {
            //     unit: "%",
            //     width: 30,
            //     aspect: 9 / 16
            // }
            // bannerImage:'http://localhost:3000/images/2newFile.jpeg',
            bannerImage: '',
            logoImage: '',
            croppedImage: '',
            bannerImage_url: '',
            logoImage_url: '',
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 9 / 16,
            croppedAreaPixels: ''
        };

        this.onImageSave = this.onImageSave.bind(this);
        this.onBannerSave = this.onBannerSave.bind(this);
        this.onLogoSave = this.onLogoSave.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.showCroppedImage = this.showCroppedImage.bind(this);
    }
    public imageRef;
    public fileUrl;
    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    // onCropComplete = crop => {
    //     this.makeClientCrop(crop);
    // };
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        this.setState({ croppedAreaPixels })
    }
    componentDidMount() {
        this.setState({ resetImages: false })
    }
    onCropChange = (crop: any) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    showCroppedImage = async () => {
        let filename = this.state.bannerImage.name;
        let finalImage;
        const showImage = (img) => finalImage = img
        const croppedImage = await getCroppedImg(
            this.state.bannerImage_url,
            this.state.croppedAreaPixels,
            filename,
            showImage
        )
        console.log(finalImage)
        this.setState({ croppedImage })
        return finalImage

    }
    showImage = (img) => console.log(img)
  
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }
  
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob: any) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
                this.setState({ blob: blob })
            }, "image/jpeg");
        });
    }
    // onImageSave() {
    //     const { UploadFiles, isLogo } = this.props;
    //     const { croppedImageUrl, src, blob } = this.state;
    //     const newImage = isLogo ? src : new File([blob], blob.name, { type: blob.type });
    //     const data = {
    //         file: newImage,
    //     }
    //     UploadFiles(data, true);
    // }
    async onImageSave() {
        const { logoImage_url, bannerImage_url } = this.state;

        // const data = [{file:logoImage},{file:newBanner}]
        // data.map(async item=>await UploadFiles(item, true))
        // const data = {
        //     fileList: [logoImage,newBanner],
        // }

        if (!isEmpty(bannerImage_url) && !isEmpty(logoImage_url)) {
            await this.onBannerSave();
            await this.onLogoSave()
        }
        else if (isEmpty(bannerImage_url) && !isEmpty(logoImage_url)) {
            this.onLogoSave()
        }
        else if (!isEmpty(bannerImage_url) && isEmpty(logoImage_url)) {
            this.onBannerSave();
        }
    }
    onLogoSave() {
        const { UploadFiles } = this.props;
        const { logoImage } = this.state;
        const data = { file: logoImage };
        const fd = new FormData();
        fd.append('files', logoImage)

        // UploadFiles(data, true,"logo");
        UploadFiles(fd, true, "logo");
        // UploadFiles(fd);
    }
    async onBannerSave() {
        const { UploadFiles } = this.props;
        const { bannerImage, croppedImage, bannerImage_url } = this.state;
        const cropped = await this.showCroppedImage();
        const newBanner = new File([cropped], bannerImage.name, { type: bannerImage.type });

        // const data = [{file:logoImage},{file:newBanner}]
        // data.map(async item=>await UploadFiles(item, true))
        // const data = {
        //     fileList: [logoImage,newBanner],
        // }
        const data = { file: newBanner };
        const fd = new FormData();
        fd.append('files', newBanner)

        // UploadFiles(data, true,"banner");
        UploadFiles(fd, true, "banner");
        // UploadFiles(fd);
    }
    onModalClose = () => {
        this.setState({ show: false })
        setTimeout(() => {
            this.setState({ modalOpen: false })
        }, 1000);

    }
    onImageChange(e) {
        const accept = ["image/png", "image/jpeg"]
        const { name, files } = e.target;
        if (accept.includes(files[0].type)) {
            // this.setState({[name]:files[0],errors:''})
            this.setState(prevState => ({
                ...prevState,
                [name + '_url']: URL.createObjectURL(files[0]),
                [name]: files[0],
                errors: {
                    ...prevState.errors,
                    [name]: ''
                }
            }))
        } else {
            // this.setState({errors:'Please choose JPG/PNG/SVG file type'});
            this.setState(
                prevState => ({
                    ...prevState,
                    errors: {
                        ...prevState.errors,
                        [name]: 'Please choose JPG/PNG/SVG file type'
                    }
                }),
                () => console.log(this.state.errors)
            )
        }
    }
    render() {
        const { crop, croppedImageUrl, src } = this.state;
        const { logoImage, logoImage_url, bannerImage, croppedImage, bannerImage_url, resetImages } = this.state;
        return (
            <>
                <div className="preview-container">
                    <div className={'preview-image'}>
                        <div className="preview-image-left">
                            <div className="image-container">
                                <div className="image-preview-container">
                                    <div className="image-preview">
                                        <div className="image-preview-img">
                                            {
                                                this.state.bannerImage_url ?
                                                    <Cropper
                                                        image={this.state.bannerImage_url}
                                                        crop={this.state.crop}
                                                        zoom={this.state.zoom}
                                                        aspect={this.state.aspect}
                                                        cropSize={{ width: 400, height: 800 }}
                                                        onCropChange={this.onCropChange}
                                                        onCropComplete={this.onCropComplete}
                                                    // onZoomChange={this.onZoomChange}
                                                    />
                                                    :
                                                    !isEmpty(this.props.state.finalBanner) ?
                                                        <img className="bannerImage" src={this.props.state.finalBanner} alt="Banner" />
                                                        :
                                                        <img className="bannerImage" src={banner} alt="Banner" />
                                            }

                                        </div>
                                        <div className="image-preview-logo">
                                            {this.state.logoImage_url ?
                                                <img src={this.state.logoImage_url} alt=" Company Logo" />
                                                :
                                                !isEmpty(this.props.state.finalLogo) ?
                                                    <img src={this.props.state.finalLogo} alt=" Company Logo" />
                                                    : <img src={rigo} alt=" Company Logo" />
                                            }
                                        </div>
                                        <div className="drag-div">
                                            <div className="drag-div-btn">
                                                <IoIosMove />
                                                Drag to Reposition
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {src && !this.props.isLogo && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    )}
                    {src && this.props.isLogo && (
                        <img
                            alt="Crop"
                            style={{ maxWidth: "100%" }}
                            src={URL.createObjectURL(src)}
                        />
                    )} */}
                        </div>
                        <div className="preview-image-right">
                            <div className="right-buttons">
                                <div className="right-btn">
                                    <div className="upload-btn-wrapper">
                                        <input
                                            type="file"
                                            name="logoImage"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => this.onImageChange(e)}
                                        />
                                        <button className="upload-btn upload-btn-white with-icon">
                                            <FiUpload />
                                            Upload New Logo
                                        </button>
                                    </div>
                                    <div className="instruction">
                                        <span>Please upload JPG or PNG image. The image should not be larger than 500 x 200 px (width x height )</span>
                                    </div>
                                </div>
                                <div className="right-btn">
                                    <div className="upload-btn-wrapper">
                                        <input
                                            type="file"
                                            name="bannerImage"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => this.onImageChange(e)}
                                        />
                                        <button className="upload-btn upload-btn-white with-icon">
                                            <FaImage />
                                            Upload New Banner
                                        </button>
                                    </div>
                                    <div className="instruction">
                                        <span>Please upload JPG or PNG image. The image should not be larger than 500 x 200 px (width x height )</span>
                                    </div>
                                </div>
                                <div className="right-btn">
                                    <button
                                        onClick={() => { this.setState({ resetImages: true }); this.props.resetImages() }}
                                        className="btn btn-white with-icon">
                                        <GoCloudUpload />
                                        <span>Reset To Default</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="preview-footer flex">
                    <div>
                        <Button
                            onClick={() => this.setState({ bannerImage: '', bannerImage_url: '', logoImage: '', logoImage_url: '', resetImages: false })}
                            title="Cancel"
                            bg="secondary-dark"
                        />
                    </div>
                    <div className="btn-group">
                        <Button
                            isDisabled={isEmpty(bannerImage_url) && isEmpty(logoImage_url) && !resetImages}
                            title="Save Changes"
                            onClick={() => this.state.resetImages ? this.props.onSave() : this.onImageSave()}
                            bg="primary"
                        />
                        <Button
                            title="Close"
                            bg="secondary"
                            onClick={() => this.props.onModalClose()}
                        />
                    </div>
                </div>
            </>

        );
    }
}
const mapDispatchToProps = {
    UploadFiles
}
export default connect(null, mapDispatchToProps)(CropImage);
