import React, { useState } from "react";
import Icons from "components/icons";
import CommonCommentSection from "components/common/commonCommentSection";
import EmployeeDetails from "scenes/common/employeeDetails";
import { FaMinusCircle } from "react-icons/fa";
import AllowanceRequestForm from "scenes/employee/allowance/allowanceRequest";
import Modal from "components/modal";
import isEmpty from "helpers/isEmpty";
import moment from "moment";

interface IAppProps {
  empRequest?: any;
  comments: any[];
  errors: any;
  saveComment: Function;
  handleApproveRequest: Function;
  allowanceRequestId: number,
  allowanceRequestDetails?: any,
  refreshRequestData: any,
  isViewMode: boolean,
  handleRejectRequest: Function,
  isDateNepali: boolean,
}

const AllowanceRequestDetails: React.FunctionComponent<IAppProps> = (props) => {
  const {
    comments,
    errors,
    saveComment,
    handleApproveRequest,
    allowanceRequestDetails,
    empRequest,
    refreshRequestData,
    handleRejectRequest,
    isViewMode,
    isDateNepali
  } = props;
  const [isAllowanceRequestFormShown, showAllowanceTimeRequestFrom] = useState(false);
  const [commentData, setCommentData] = useState('')

  if (isEmpty(allowanceRequestDetails)) return null;
  return (
    <div className="my-allowance-request">
      <div className="my-allowance-request__header">
        <div className="request-type mb-md">{empRequest.AllowanceType}</div>
        <div className="flex detailSection">
          <span className="white-space-nowrap mb-10 mr-bg">{isDateNepali ? empRequest.FromDate : moment.parseZone(empRequest.FromDateEng).local().format("MMMM DD, YYYY")}
           - {isDateNepali ? empRequest.ToDate : moment.parseZone(empRequest.ToDateEng).local().format("MMMM DD, YYYY")}</span>
          <span className="wd-50 mb-10">{empRequest.Reason}</span>
        </div>
        <div className="flex">
          <span className="wd-50">{empRequest.Units} {empRequest.Units <= 1 ? 'Unit' : 'Units'}</span>
        </div>
        <div className="flex mt-md">
          <span className="mr-md">Requested On</span>
          <span className="wd-30">{isDateNepali ? empRequest.RequestedOn : moment.parseZone(empRequest.RequestedOnEng).local().format("MMMM DD, YYYY h:mm A")}</span>
          {/* {empRequest.RecommendedStatus === "Approved" ? (
            <span className="emp-name text-green flex mr-md">
            <Icons name="Check" className="checkmark-align" />
            <span>{empRequest.Reccommender}</span>
            </span>) :
            <span className="mr-md">{empRequest.Reccommender}</span>
          }
            {empRequest.ApprovalStatus === "Approved" ? (
          <span className="emp-name ml-md text-green flex">
            <Icons name="Check" className="checkmark-align" />
            <span>{empRequest.Approval}</span>
          </span>): 
            <span>{empRequest.Approval}</span>
          } */}
        </div>
      </div>
      <div className="my-allowance-request__body flex-column">
        <div className="approvers flex mt-sm">
          <span className="mr-md">Approvers</span>
          <div className="approver__name flex">
            {empRequest?.approvers?.map((item) => {
              return <EmployeeDetails empDetails={item} size={30} showApprovalStatus/>;
            })}
          </div>
        </div>
        <div className="mt-md flex">
          <CommonCommentSection
            submitComment={saveComment}
            comments={comments}
            submitErrors={errors}
            isViewMode={isViewMode}
            setCommentData={(data) => setCommentData(data)}
          />
        </div>
        {!isViewMode && <div className="flex mt-md justify-between">
          <div className="flex library">
          <button
            onClick={() => {
                handleRejectRequest(commentData);
            }}
            className="button with-icon button-white">
              <FaMinusCircle />
              <span>Deny</span>
            </button>

            <button
              className="button with-icon button-white"
              onClick={() => {
                showAllowanceTimeRequestFrom(true);
              }}
            >
              <Icons name="Edit" className="flex" />
              <span>Edit</span>
            </button>
          </div>
          <button
            className="button with-icon button-approve"
            onClick={() => handleApproveRequest(commentData)}
          >
            <Icons name="Check" className="flex" />
            <span>Approve</span>
          </button>
        </div>}
      </div>
      {isAllowanceRequestFormShown && (
        <Modal
          title="Allowance Request"
          open={isAllowanceRequestFormShown}
          onModalClose={() => showAllowanceTimeRequestFrom(false)}
          type=""
          className="modal-allowance-request allowance-request-form"
        >
          <AllowanceRequestForm
            refreshRequestData={refreshRequestData}
            allowanceRequestId={empRequest}
            closeForm={() => showAllowanceTimeRequestFrom(false)}
            isApprover={true}
            editMode={true}
          />
        </Modal>

      )}
    </div>
  );
};

export default AllowanceRequestDetails;
