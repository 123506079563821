import React, { useEffect, useState } from 'react'
import CalendarHeader from './calendarHeader';
import CalendarDays from './calendarDays';
import CalendarDates from './calendarDates';
import {MONTH,YEAR} from '../../constants'
import CalendarFooter from './calendarFooter';
import MonthTab from './monthTab';
import YearTab from './yearTab'
import {
    getNextYear,
    getPreviousMonth,
    getPreviousYear,
    getNextMonth
} from '../../helpers/date'
import dayjs from 'dayjs';

interface PopupInterface{
    open:boolean,
    today:any,
    currentDate:any,
    onDateClick:Function,
    setCurrentDate:Function,
    value:string
    format:string,
    onOk:Function,
    isSaveOnButton:boolean,
    valueType:'single' | 'weekly' | 'monthly' | 'quaterly'
}

function CalendarPopup(props:PopupInterface) {
    const {
        open,
        today,
        currentDate,
        onDateClick,
        setCurrentDate,
        value,
        format,
        onOk,
        isSaveOnButton,
        valueType,
    } = props;
    const [tab,setTab] = useState<string>("");
    useEffect(()=>{
        if(!open){
            setTab("")
        }
    },[open]);
    if(!open){
        return null;
    }


    const nextYear = () => {
        const dates = getNextYear(currentDate);
        setTab('');
        setCurrentDate(dayjs(dates.date).format(format));
    };
    const prevYear = () => {
        const dates = getPreviousYear(currentDate);
        setTab('');
        setCurrentDate(dayjs(dates.date).format(format));
    };
    const nextMonth = () => {
        const dates = getNextMonth(currentDate);
        setTab('');
        
        setCurrentDate(dayjs(dates.date).format(format));
    };
    const prevMonth = () => {
        const dates = getPreviousMonth(currentDate);
        setTab('');
        setCurrentDate(dayjs(dates.date).format(format));
    };
    
    let actions = {
        nextYear,
        prevYear,
        nextMonth,
        prevMonth,

    }
    let isSingle = valueType === 'single'
    return (
        <div className="cal-popup">
            <div className="cal-popup__container">
                <CalendarHeader 
                    setCurrentDate={setCurrentDate}
                    currentDate={currentDate}
                    tab={tab}
                    setTab={setTab}
                    actions={actions}
                />
                {
                    tab === MONTH ? <MonthTab 
                                        format={format} 
                                        currentDate={currentDate} 
                                        setCurrentDate={setCurrentDate}
                                        setTab={setTab}
                                    /> : tab === YEAR ? <YearTab 
                                    format={format} 
                                        currentDate={currentDate} 
                                        setCurrentDate={setCurrentDate}
                                        setTab={setTab}
                                    
                                    /> : (
                        <>
                         <div className="cal-popup__body">
                            <CalendarDays 
                                
                            />
                            <CalendarDates 
                                currentDate={currentDate}
                                today={today}
                                onDateClick={onDateClick}
                                setCurrentDate={setCurrentDate}
                                value={value}
                                isSingle ={isSingle}
                                valueType={valueType}
                                format={format}
                            />
                        </div>
                        <CalendarFooter 
                            setCurrentDate={setCurrentDate}
                            onOk={onOk}
                            isSaveOnButton={isSaveOnButton}
                        />
                        </>
                    )
                }
               
            </div>
        </div>
    )
}

export default CalendarPopup