import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routeEnum";
import {api, get, post, put, deletion, get_by_id} from "networkService";

export const getConsumerLoanPurposeList = () => async (dispatch) => {
  const res = await Http.get(environment.api.v1.sbi.getConsumerLoanPurposeList);
  const actionConfig = {
    dispatch,
    actionType: types.GET_CONSUMER_LOAN_PURPOSE_LIST,
    effect: res,
    isSelect: true,
    isValueKey: false,
    isJustData: false,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getLoanSettingsByType = (LoanType) => async (dispatch) => {
  const res = await Http.get(
    `${environment.api.v1.sbi.getLoanSettingsByType}/${LoanType}`
  );
  const actionConfig = {
    dispatch,
    actionType: types.LOAN_SETTING_BY_TYPE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getEmployeeInfo = (EmployeeId) => async (dispatch) => {
  const res = await Http.get(environment.api.v1.sbi.getEmployeeInfo + EmployeeId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_REQUESTER_INFORMATION,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const calculatePaymentSchedule = (data) => async (dispatch) => {
  const res: any = await Http.post(environment.api.v1.sbi.calculatePaymentSchedule, data);
  const actionConfig = {
    dispatch,
    actionType: types.CALCULATE_PAYMENT_SCHEDULE,
    effect: res,
    message: "",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Data || [];
};

export const LoanApproverList =
  ({EmployeeId, LoanType, FlowType, CurrentStep}) =>
  async (dispatch) => {
    const res: any = await Http.get(
      environment.api.v1.sbi.loanApproverList +
        `?employeeId=${EmployeeId}&loanType=${LoanType}&flowType=${FlowType}&currentStep=${CurrentStep}`
    );
    let formattedData = [];
    res.data.forEach((emp) => {
      formattedData.push({
        ...emp,
        FullName: emp.EmployeeName,
        Imagepath: emp.UrlPhoto,
        // Designation: emp.DesignationName,
        Department: emp.Department,
        Id: emp.EmployeeId,
      });
    });
    let resData = {
      ...res,
      data: formattedData,
    };
    const actionConfig = {
      dispatch,
      actionType: types.LOAN_APPROVER_LIST,
      effect: resData,
      message: "",
      isLoading: true,
      isJustData: true,
    };
    await Action.createThunkEffect(actionConfig);
  };
export const GenerateIncomeDeductionList =
  ({EmployeeId, LoanId, LoanType}) =>
  async (dispatch) => {
    const res = await Http.get(
      environment.api.v1.sbi.generateIncomeDeductionList +
        EmployeeId +
        `?LoanId=${LoanId}&LoanType=${LoanType}`
    );
    const actionConfig = {
      dispatch,
      actionType: types.GENERATE_INCOME_DEDUCTION_LIST,
      effect: res,
      message: "",
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

export const saveConsumerLoanRequest =
  (data, status, isEditMode) => async (dispatch: Function) => {
    dispatch({
      type: types.SAVE_CONSUMER_LOAN_REQUEST,
      payload: true,
    });

    var url =
      status === 0
        ? environment.api.v1.sbi.draftConsumerLoanRequest
        : environment.api.v1.sbi.saveConsumerLoanRequest;

    var res: any = isEditMode
      ? await put(url, dispatch, data, false).catch((ex) => {})
      : await post(url, dispatch, data, false).catch((ex) => {});

    dispatch({
      type: types.SAVE_CONSUMER_LOAN_REQUEST,
      payload: false,
    });

    if (res && res.Status) return true;

    return null;
  };

export const getConsumerLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.v1.sbi.getConsumerLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.CONSUMER_LOAN_DETAILS,
    effect: res,
    message: "Loan Data fetched successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
  // if (res.data.MessageType == "Success") {
  //   return true;
  // }
};

export const resetLoanDetails = () => async (dispatch) => {
  dispatch({type: types.CONSUMER_LOAN_DETAILS, payload: null});
};

export const deleteRequestedLoan = (LoanId, LoanType) => async (dispatch) => {
  // dispatch({type: types.DELETE_REQUESTED_LOAN, payload: false});
  await dispatch(Action.createAction(types.DELETE_REQUESTED_LOAN, false));
  let apiURI =
    LoanType == 1
      ? environment.api.v1.sbi.deleteConsumerLoan
      : LoanType == 2
      ? environment.api.v1.sbi.deleteSocialLoan
      : environment.api.v1.sbi.deletePersonalLoan;
  const res: any = await Http.delete(apiURI + LoanId);
  // const res: any = await Http.delete(environment.api.v1.sbi.deleteConsumerLoan + LoanId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_REQUESTED_LOAN,
    effect: res,
    message: "Loan Deleted successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status || [];
};

// social loan
// export const saveSocialLoanRequest = (data) => async (dispatch) => {
//   const res = Http.post(environment.api.v1.sbi.saveSocialLoanRequest, data);
//   const actionConfig = {
//     dispatch,
//     actionType: types.SAVE_CONSUMER_LOAN_REQUEST,
//     effect: res,
//     message: "Loan Applied successfully",
//     isLoading: true,
//   };
//   await Action.createThunkEffect(actionConfig);
// };

export const getAppliedLoanList = (data) => async (dispatch) => {
  const res: any = await Http.get(
    environment.api.v1.sbi.getAppliedLoanList + data.EmployeeId
  );
  const actionConfig = {
    dispatch,
    actionType: types.MY_APPLIED_LOAN_LIST_REQUEST,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// Get loan details
export const getLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.v1.sbi.getConsumerLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.GET_LOAN_DETAILS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// Get outstanding loans
export const getOutstandingLoans =
  ({EmployeeId, LoanType, LoanId}) =>
  async (dispatch) => {
    const res: any = await Http.get(
      environment.api.v1.sbi.getOutstandingLoans +
        "/" +
        EmployeeId +
        `?loanType=${LoanType}&loanId=${LoanId}`
    );
    const actionConfig = {
      dispatch,
      actionType: types.GET_OUTSTANDING_LOANS,
      effect: res,
      // isSelect: false,
      // isValueKey: true,
      // isJustData: true,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
  };
// save outstandingloans
export const saveOutstandingLoan = (data, action) => async (dispatch) => {
  await dispatch(Action.createAction(types.SAVE_OUTSTANDING_LOANS, false));
  const res: any = await Http[action](environment.api.v1.sbi.getOutstandingLoans, data);
  const actionConfig = {
    dispatch,
    actionType: types.SAVE_OUTSTANDING_LOANS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    message: res.data.Message,
    // action == "post"
    //   ? "Outstanding Loan Saved Successfully"
    //   : "Outstanding Loan Updated Successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status ? res.data.Data : res.data.Status;
};

// delete saved outstandingloans
export const deleteOutstandingLoan = (LoanId) => async (dispatch) => {
  await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
  const res: any = await Http.delete(
    environment.api.v1.sbi.getOutstandingLoans + "/" + LoanId
  );
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_OUTSTANDING_LOANS,
    effect: res,
    // isSelect: false,
    // isValueKey: true,
    // isJustData: true,
    message: res.data.Message,
    // "Outstanding Loan Deleted Successfully",
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status;
};

// delete saved outstandingloans
export const getLoanTypes = () => async (dispatch) => {
  // await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
  const res: any = await Http.get(environment.api.v1.sbi.getSbiLoanTypes);
  const actionConfig = {
    dispatch,
    actionType: types.SBI_LOAN_TYPES,
    effect: res,
    isSelect: true,
    // isValueKey: true,
    // isJustData: true,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

// delete saved outstandingloans
export const getLoanCommentsHistory =
  ({loanType, loanId}) =>
  async (dispatch) => {
    // await dispatch(Action.createAction(types.DELETE_OUTSTANDING_LOANS, false));
    const res: any = await Http.get(
      environment.api.v1.sbi.getLoanCommentsHistory +
        "" +
        `?loanType=${loanType}&loanId=${loanId}`
    );
    const actionConfig = {
      dispatch,
      actionType: types.LOAN_COMMENT_HISTORY,
      effect: res,
      // isSelect: true,
      // isValueKey: true,
      // isJustData: true,
      isLoading: true,
      // isJustData: true,
    };
    await Action.createThunkEffect(actionConfig);
  };

// SOCIAL LOAN

export const saveSocialLoanRequest =
  (data, status, isEditMode) => async (dispatch: Function) => {
    dispatch({
      type: types.SAVE_SOCIAL_LOAN_REQUEST,
      payload: true,
    });

    var url =
      status === 0
        ? environment.api.v1.sbi.draftSocialLoanRequest
        : environment.api.v1.sbi.saveSocialLoanRequest;

    var res: any = isEditMode
      ? await put(url, dispatch, data, false).catch((ex) => {})
      : await post(url, dispatch, data, false).catch((ex) => {});

    dispatch({
      type: types.SAVE_SOCIAL_LOAN_REQUEST,
      payload: false,
    });

    if (res && res.Status) return true;

    return null;
  };

export const deleteSocialLoan = (LoanId, LoanType) => async (dispatch) => {
  // dispatch({type: types.DELETE_REQUESTED_LOAN, payload: false});
  await dispatch(Action.createAction(types.DELETE_REQUESTED_LOAN, false));
  let apiURI =
    LoanType == 1
      ? environment.api.v1.sbi.deleteConsumerLoan
      : environment.api.v1.sbi.deleteSocialLoan;
  const res: any = await Http.delete(apiURI + LoanId);
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_SOCIAL_LOAN,
    effect: res,
    message: "Loan Deleted successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);
  return res.data.Status || [];
};

export const getSocialLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.v1.sbi.getSocialLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.CONSUMER_LOAN_DETAILS,
    effect: res,
    message: "Loan Data fetched successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);

  // if (res.data.MessageType == "Success") {
  //   return true;
  // }
};

// PERSONAL LOAN

export const savePersonalLoanRequest = (data, status, isEditMode) => async (dispatch) => {
  dispatch({
    type: types.SAVE_PERSONAL_LOAN_REQUEST,
    payload: true,
  });

  var url =
    status === 0
      ? environment.api.v1.sbi.draftPersonalLoanRequest
      : environment.api.v1.sbi.savePersonalLoanRequest;

  var res: any = isEditMode
    ? await put(url, dispatch, data, false).catch((ex) => {})
    : await post(url, dispatch, data, false).catch((ex) => {});

  dispatch({
    type: types.SAVE_PERSONAL_LOAN_REQUEST,
    payload: false,
  });

  if (res && res.Status) return true;

  return null;
};

export const getPersonalLoanDetails = (loanId) => async (dispatch) => {
  const res: any = await Http.get(environment.api.v1.sbi.getPersonalLoanDetails + loanId);
  const actionConfig = {
    dispatch,
    actionType: types.CONSUMER_LOAN_DETAILS,
    effect: res,
    message: "Loan Data fetched successfully",
    isLoading: true,
    isJustData: true,
  };
  await Action.createThunkEffect(actionConfig);

  // if (res.data.MessageType == "Success") {
  //   return true;
  // }
};
