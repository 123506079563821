import React, {useRef, useState, useEffect} from "react";
import AddLabelModal from "../../../addLabelModal";
import CustomPopup from "components/common/customPopup";
import FormGroup from "components/form";
import {FaCheckCircle, FaPencilAlt, FaPlus, FaTimes} from "react-icons/fa";
import {TMStage, TmViewType} from "scenes/tasks/helpers/enum";
import {IoMdAdd} from "react-icons/io";
import {MdClose} from "react-icons/md";
import {connect} from "react-redux";
import isEmpty from "helpers/isEmpty";
import LabelPopup from "./labelPopup";
import {taskUpdateDetails, moveTask} from "../../../../../../../helpers/action";

const LabelCell = (props) => {
  const {task, boardLabels, stage, rowIndex, board} = props;
  const statusRef = useRef(null);
  const [statusOpen, setStatusOpen] = useState(false);
  const [hiddenNum, setHiidenNum] = useState(0);
  const [openLebelModal, setOpenLabelModel] = useState(false);
  const TaskBoardLabels = (task && task.TaskBoardLabels) || [];

  const handleMoveTask = async (ids, stage, boardId) => {
    props.moveTask(ids, stage, boardId);
  };
  const onLabelUpdate = (param) => {
  };
  const handleUpdateTask = async (updatedTask) => {
    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updatedTask,
      stage,
      rowIndex,
    });
    setStatusOpen(false);
  };
  const removeBoardLabel = (index) => {
    let TaskBoardLabels = [...task.TaskBoardLabels];
    TaskBoardLabels.splice(index, 1);
    const updatedTask = {
      ...task,
      TaskBoardLabels,
    };
    handleUpdateTask(updatedTask);
  };
  useEffect(() => {
    if (statusRef.current && TaskBoardLabels) {
      HideChildren(false);
    }
  }, [statusRef.current]);
  useEffect(() => {
    if (statusRef.current && TaskBoardLabels) {
      HideChildren(true);
    }
  }, [TaskBoardLabels]);
  const HideChildren = async (isNotFirst) => {
    // if (statusRef && statusRef.current && parent) {
    //   const child = navLinkRef.current;
    //   if (((child.offsetLeft + child.offsetWidth) - parent.offsetLeft > parent.offsetWidth) ||
    //     (child.offsetTop - parent.offsetTop > parent.offsetHeight)
    //   ) {
    //     setHidden(true)
    //     onHiddenChild(content)
    //   } else {
    //     setHidden(false)
    //     removeFromHidden(content)
    //   }
    // }
    if (statusRef && statusRef.current) {
      const child = statusRef.current.firstElementChild;
      const parent = statusRef.current.parentElement;

      if (parent && child) {
        const children = child.children;
        if (hiddenNum !== 0 && isNotFirst) {
          await setHiidenNum((prev) => 0);
        }
        children &&
          children.forEach(async (element) => {
            if (element.offsetLeft + element.offsetWidth > parent.offsetWidth) {
              if (element.className === "taskBlock-avatar-group") {
                return;
              }
              element.style.display = "none";
              await setHiidenNum((prev) => prev + 1);
            }
          });
      }
    }
  };

  return (
    <div ref={statusRef}>
      <div
        onClick={() => !board.IsArchived && setStatusOpen(!statusOpen)}
        className="taskBlock-section taskBlock-section-label"
      >
        {TaskBoardLabels.map((label, i) => (
          <div
            className="taskBlock-section-label-title"
            title={label.LabelName}
            key={i}
            style={label.Color ? {backgroundColor: label.Color} : {}}
          >
            {label.LabelName}
          </div>
        ))}
        {parseInt(hiddenNum + "") > 0 && (
          <div className="taskBlock-avatar-group">
            <div className="avatar-more">
              <div className="avatar-more-div">
                <span>+{hiddenNum}</span>
              </div>
            </div>
          </div>
        )}
        {TaskBoardLabels.length < 3 && !board.IsArchived && (
          <div className="btn-add">
            <span>
              <IoMdAdd size={15} />
            </span>
          </div>
        )}
      </div>
      {statusOpen && statusRef && statusRef.current && (
        <CustomPopup
          parentRef={statusRef}
          open={statusOpen}
          handleOutsideClick={() => setStatusOpen(false)}
        >
          <LabelPopup
            {...props}
            task={task}
            onUpdateTask={handleUpdateTask}
            onMoveTask={handleMoveTask}
            boardLabels={boardLabels}
            setStatusOpen={setStatusOpen}
            setOpenLabelModel={setOpenLabelModel}
          />
        </CustomPopup>
      )}

      <AddLabelModal
        {...props}
        open={openLebelModal}
        setOpen={setOpenLabelModel}
        onLabelUpdate={onLabelUpdate}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  boardLabels: state.taskReducer.boardLabels,
});
const mapDispatchToProps = {taskUpdateDetails, moveTask};
export default connect(mapStateToProps, mapDispatchToProps)(LabelCell);
