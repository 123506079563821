import React, {useState, useEffect} from "react";
import {FaRegComment, FaPencilAlt, FaRegUserCircle, FaRegCopy} from "react-icons/fa";
import SvgIcons from "components/icons/svgIcons";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import FormGroup from "components/form";
import {TmTaskPriorityEnum, TmViewType} from "../../../../../../helpers/enum";
import isEmpty from "helpers/isEmpty";
import classNames from "classnames";
import TextInput from "../../addLabelModal/textInput";
import {taskUpdateDetails} from "../../../../../../helpers/action";
import {connect} from "react-redux";
import moment from "moment";

const TitleCell = (props) => {
  const {
    onTaskClicked,
    stage,
    provided,
    rowIndex,
    task,
    selectTask,
    setSelectTask,
    canSelectTask,
    board,
  } = props;
  const [showTextInput, setShowTextInput] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [dueDate, setDueDate] = useState(null);
  useEffect(() => {
    if (task) {
      setTaskName(task.TaskName);
      task.DueDate && setDueDate(`${moment(new Date(task.DueDate)).format("MMM DD")}`);
    }
  }, [task]);

  const completed = task.OpenCheckList;
  const percentage: number =
    task.TotalCheckList > 0 && task.OpenCheckList > 0
      ? (task.OpenCheckList / task.TotalCheckList) * 100
      : 0.0;

  const getStyleForCircularProgressBar = () => {
    let per = percentage;
    if (per < 0) {
      per = 1;
    }

    return buildStyles({
      pathColor: `rgba(8, 202, 121, ${per / 100})`,
      textSize: "28px",
      textColor: "#08ca79",
    });
  };

  const getClassName = (priority: TmTaskPriorityEnum) => {
    switch (priority) {
      case TmTaskPriorityEnum.High:
        return "taskBlock-border task-priority-high";
      case TmTaskPriorityEnum.Medium:
        return " taskBlock-border task-priority-medium";
      case TmTaskPriorityEnum.Low:
        return " taskBlock-border task-priority-low";
      default:
        return "taskBlock-border task-priority-none";
    }
  };

  const handleInputChange = (name, value) => {
    setTaskName(value);
  };
  const handleSubmit = async (name, value) => {
    const updatedTask = {
      ...task,
    };

    setShowTextInput(false);
    updatedTask[name] = value;

    await props.taskUpdateDetails({
      viewType: TmViewType.TableView,
      task: updatedTask,
      stage,
      rowIndex,
    });
  };
  const maxLengthValidation = (e: any) => {
    const {value, name} = e.target;
    let len = (value && value.trim().length) || 0;
    let maxlength = 99;

    if (len > maxlength) {
      e.preventDefault();
    }
  };
  return (
    <>
      <div {...provided.dragHandleProps} className={getClassName(task.Priority)}>
        <div className="taskBlock-drag-icons flex" style={{height: "100%"}}>
          <SvgIcons name="ellipsis-v" size={16} color="#fff" />
          <SvgIcons name="ellipsis-v" size={16} color="#fff" />
        </div>
      </div>
      <div className="taskBlock-task">
        <div className="taskBlock-task__item">
          <div className={classNames({"taskBlock-task__not-allowed": !canSelectTask})}>
            <FormGroup
              formName="checkgroup"
              name="task"
              onChange={(e) => canSelectTask && setSelectTask(e.target.checked)}
              value={selectTask}
              checked={selectTask}
            />
          </div>

          {!showTextInput ? (
            <div
              onClick={() => {
                onTaskClicked && onTaskClicked(task, stage, rowIndex);
              }}
              className="taskBlock-task__text"
            >
              <div className="taskBlock-task__taskName">
                <span className="taskBlock-task__taskName-title"> {taskName}</span>
                {dueDate && (
                  <span className="taskBlock-task__taskName-duedate">{dueDate}</span>
                )}
              </div>
              {!board.IsArchived && (
                <div
                  className="taskBlock-task__edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTextInput(true);
                  }}
                >
                  <FaPencilAlt />
                </div>
              )}
            </div>
          ) : (
            <div className="taskBlock-task__text-input">
              <TextInput
                value={taskName}
                autoFocus
                name={"TaskName"}
                handleChange={handleInputChange}
                handleSubmit={handleSubmit}
                onBlur={(e) => {
                  setShowTextInput(false);
                }}
                onKeyPress={maxLengthValidation}
              />
            </div>
          )}
        </div>
        <div className="taskBlock-item__status">
          {task.Rating > 0 && (
            <div className="taskBlock-item__rating">
              <SvgIcons name="award" size="14px" color="#f87403" />
              <span>{task.Rating}</span>
            </div>
          )}
          <div className="taskBlock-item__completed">
            {task.TotalCheckList > 0 ? (
              <>
                <span className="text-success">{completed > 0 ? completed : "-"}</span>/
                <span>{task.TotalCheckList}</span>
              </>
            ) : null}
          </div>

          <div className="taskBlock-item__progress">
            {!isEmpty(percentage) && task.TotalCheckList > 0 ? (
              <CircularProgressbar
                strokeWidth={15}
                styles={getStyleForCircularProgressBar()}
                value={percentage}
                text={`${percentage.toFixed(0)}%`}
              />
            ) : null}
          </div>
          {task.TotalComments > 0 && (
            <div className={classNames("taskBlock-item__comments active")}>
              <FaRegComment />
              {task.TotalComments > 0 && (
                <span className="taskBlock-item__comments-count">
                  {task.TotalComments}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {taskUpdateDetails};
export default connect(mapStateToProps, mapDispatchToProps)(TitleCell);
