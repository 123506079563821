import React from "react";
import Button from "components/button";

const CourseResultsFooter = ({onModalClose}) => {
  return (
    <div className="course-results__footer">
      <Button onClick={onModalClose} title="Close" />
    </div>
  );
};

export default CourseResultsFooter;
