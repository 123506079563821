import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import React, {useEffect, useRef} from "react";

const CourseRadio = ({question, answer, onChange, answerOptions}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!isEmpty(answer?.errors)) {
      ref?.current?.scrollIntoView?.({block: "start", behavior: "smooth"});
    }
  }, [answer?.errors]);
  const onClick = (id) => onChange(id, true);
  return (
    <>
      <ul className="course-page__quiz-list" ref={ref}>
        {answerOptions &&
          answerOptions.map((item) => (
            <li
              key={item.Id}
              onClick={() => onClick(item.Id)}
              className={classnames("course-page__quiz-item", {
                "course-page__quiz-item-active": answer && item.Id === answer.AnswerRefId,
              })}
            >
              <div className="course-page__quiz-radio"></div>
              <div className="course-page__quiz-name">{item.AnswerOption}</div>
            </li>
          ))}
      </ul>
      {answer && answer.errors && <span className="error__message">{answer.errors}</span>}
    </>
  );
};
export default CourseRadio;
