import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {FaCheck} from "react-icons/fa";
import FormGroup from "components/form";
import Avatar from "react-avatar";
import classNames from "classnames";
import {convetToDataSource, convetFilterToDataSource} from "./helper";
import isEmpty from "helpers/isEmpty";

const TeamMember = (props) => {
  const {selectedMembers, boardMembers, isTeam, task, isSearchMode} = props;
  const [search, setSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    let selected = convetToDataSource(selectedMembers, true, base);
    setSelectedEmployee(selected);
  }, [selectedMembers]);

  useEffect(() => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    if (props.boardMembers) {
      let members = convetFilterToDataSource(
        props.boardMembers,
        selectedMembers || [],
        base
      );
      if (!isEmpty(search)) {
        members =
          members.filter(
            (x) => x.FullName && x.FullName.toLowerCase().includes(search.toLowerCase())
          ) || [];
      }
      setFilteredEmployees(members);
    }
  }, [props.boardMembers, selectedMembers]);

  const onChange = async (name, value) => {
    setSearch(value);
    let filteredData = await getEmployeeList(value);
    setFilteredEmployees(filteredData);
  };

  const getEmployeeList = async (name) => {
    let boardMembers = [...props.boardMembers];

    let res =
      boardMembers.filter(
        (x) => x.FullName && x.FullName.toLowerCase().includes(name && name.toLowerCase())
      ) || [];

    res = res.map((x) => ({
      ...x,
      isChecked:
        selectedEmployee &&
        selectedEmployee.find((member) => member.EmployeeId === x.EmployeeId)
          ? true
          : false,
    }));

    return res;
  };

  const onItemClicked = (items, index) => {
    let newFilteredEmployees = [...filteredEmployees];
    let selectedList = [...selectedEmployee];

    newFilteredEmployees[index].isChecked = !newFilteredEmployees[index].isChecked;

    if (!newFilteredEmployees[index].isChecked) {
      let inx = selectedList.findIndex(
        (x) => x.EmployeeId === newFilteredEmployees[index].EmployeeId
      );
      if (inx > -1) {
        selectedList.splice(inx, 1);
      }
    }

    let selected = [
      ...selectedList,
      ...newFilteredEmployees.filter(
        (x) =>
          x.isChecked && !selectedEmployee.map((y) => y.EmployeeId).includes(x.EmployeeId)
      ),
    ];
    setFilteredEmployees(newFilteredEmployees);
    setSelectedEmployee(selected);
    props.onSelectionChange && props.onSelectionChange(selected);
  };

  const renderListItem = (items) => {
    const base = props.auth.baseUrl ? props.auth.baseUrl.NetFrameworkAppBaseUrl : "";
    return (
      <div className="search-team__section">
        {search.length === 0 && <span className="search-team__text">Team Members</span>}
        <ul className="search-team__emplist">
          {items.map((item, index) => (
            <li
              key={index}
              className={classNames({
                "search-team__empitem": true,
                "search-team__not-allowed": !canModifyTask(),
              })}
              onClick={(e) => {
                canModifyTask() && onItemClicked(items, index);
              }}
            >
              <div className="search-team__empitem-image">
                <Avatar
                  className="team-member"
                  name={item.FullName}
                  size={"25"}
                  round={true}
                  src={`${base}${item.Imagepath}`}
                />
              </div>
              <div className="search-team__empitem-name">
                <span>{item.FullName}</span>
              </div>
              {item.isChecked && (
                <div style={{padding: 5}}>
                  <FaCheck size="14" color="#172B4D" />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const canModify = () => {
    const {auth, board} = props;
    const {user} = auth;
    if (board.IsArchived) return false;
    return (
      user.UserID === board.CreatedBy ||
      user.RoleName === "Administrator" ||
      board.IsRecurring
    );
    //return (user.UserID === board.CreatedBy || board.IsRecurring);
  };

  const canModifyTask = () => {
    const {auth, board} = props;
    const {user} = auth;
    if (board.IsArchived) return false;

    if (isSearchMode) return true;

    if (user.RoleName === "Administrator") {
      return true;
    }

    if (board.CreatedBy == user.UserID) return true;

    if (task && task.CreatedBy == user.UserID) return true;

    return false;
  };

  return (
    <>
      <div className="search-team">
        {canModify() && (
          <FormGroup
            name="Search"
            autoComplete="off"
            value={search}
            placeholder="Search Team Member"
            enableClearText={true}
            autoFocus
            onChange={(name, value) => onChange(name, value)}
          />
        )}
        <div className="search-team-members">{renderListItem(filteredEmployees)}</div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeList: state.commonReducer.employeeList,
  employeeListSelect: state.commonReducer.employeeListSelect,
  boardMembers: state.taskReducer.boardMembers,
  board: state.taskReducer.board,
  auth: state.auth,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
