import React,{useState}  from "react";
import Modal from "components/modal";
import Button from "components/button";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {
  copyRosterSchedule,
  getRosterScheduleEmployeeList,
  getRosterScheduleList,
  getRosterScheduleEmployeeListWithWorkingHour,
} from "scenes/roster/helpers/action";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const getDatesInBetween = (startDate, endDate, cDate) => {
  var dateArray = [];
  var currentDate = dayjs(startDate);
  var lastDate = dayjs(endDate);
  while (currentDate <= lastDate) {
    if (cDate !== dayjs(currentDate).format("YYYY-MM-DD")) {
      dateArray.push({
        value: dayjs(currentDate).format("YYYY-MM-DD"),
        label: dayjs(currentDate).format("ddd D"),
      });
    }

    currentDate = dayjs(currentDate).add(1, "day");
  }
  return dateArray;
};

export const getDateRange = (date, dateIndex = 0) => {
  var curr = new Date(date);

  var firstDayOfWeek = dayjs(dayjs(curr).startOf("week").toDate()).format("YYYY-MM-DD");
  var lastDayOfWeek = dayjs(dayjs(curr).endOf("week").toDate()).format("YYYY-MM-DD");
  return {
    startDate: firstDayOfWeek,
    endDate: lastDayOfWeek,
  };
};

function ScheduleCopyModal(props) {
  const {
    open,
    onModalClose,
    isSingle,
    currentDate,
    teamId,
    startDate,
    endDate,
    isThisWeek,
  } = props;
  const weekDates = getDateRange(currentDate);
  const ranges = getDatesInBetween(
    isSingle ? weekDates.startDate : dayjs(endDate).add(1, "day"),
    isSingle ? weekDates.endDate : dayjs(endDate).add(7, "day"),
    isSingle ? currentDate : null
  );
  const beforeRanges = isSingle ? null : getDatesInBetween(startDate, endDate, null);
  const method = useForm({
    defaultValues: {
      copyWithEmployees: 1,
      replaceExisting: 1,
      dates: [],
      destination: isSingle ? null : "3",
    },
  });
  const {watch} = method;
  const dispatch = useDispatch();
  const d = watch("destination");
  const onSubmit = async (formData) => {
    const dates = formData.destination === "2" ? formData.dates : ranges;
    const data = {
      TeamId: teamId?.value,
      CopyWithEmployees: formData?.copyWithEmployees === 1,
      ReplaceExisting: formData?.replaceExisting === 1,
      Dates: dates.map((item, index) => ({
        source: isSingle ? new Date(currentDate) : new Date(beforeRanges[index]?.value),
        destination: new Date(item.value),
      })),
    };
    const res: any = await dispatch(copyRosterSchedule(data));
    if (res && res?.Status) {
      const param = {
        startDate: startDate,
        endDate: endDate,
        teamId: teamId ? teamId.value : null,
      };
      const promiseArray = [
        dispatch(getRosterScheduleList(param)),
        dispatch(getRosterScheduleEmployeeList(param)),
        dispatch(getRosterScheduleEmployeeListWithWorkingHour(param)),
      ];
      await Promise.all(promiseArray);
      onModalClose();
    }
  };
  return (
    <Modal
      onModalClose={onModalClose}
      open={open}
      className="copy-schedule-modal hide-modal-overflow"
    >
      <FormGroup
        method={method}
        defaultValues={{copyWithEmployees: 1, replaceExisting: 1, dates: []}}
        onSubmit={onSubmit}
      >
        <div className="copy-schedule__container">
          <div className="copy-schedule__title">Copy and Paste</div>

          <div className="copy-schedule__body">
            <div className="copy-schedule__col">
              <div className="copy-schedule__label">Source</div>
              <div className="copy-schedule__val">
                {isSingle
                  ? dayjs(currentDate).format("ddd D")
                  : `This Week (${
                      dayjs(startDate).format("ddd D") +
                      " - " +
                      dayjs(endDate).format("ddd D")
                    })`}
              </div>
            </div>
            <div className="copy-schedule__col">
              <div className="copy-schedule__label">Copy</div>
              <div className="copy-schedule__radio">
                <FormItem
                  formName="radioList"
                  options={[
                    {label: "Shifts with Employees", value: 1},
                    {label: "Shifts only", value: 2},
                  ]}
                  name="copyWithEmployees"
                />
              </div>
            </div>
            <div className="copy-schedule__col">
              <div className="copy-schedule__label">Destination (Paste) *</div>
              <div className="copy-schedule__radio">
                <FormItem
                  formName="radioList"
                  options={
                    isSingle
                      ? [
                          {label: "All remaining days in this week", value: 1},
                          {label: "Selected days this week", value: 2},
                        ]
                      : [{label: "Next week", value: 3}]
                  }
                  name="destination"
                  rules={{required: "Destination is required"}}
                />
              </div>
              {d === "2" && (
                <div className="copy-schedule__chips">
                  <FormItem
                    formName="chipsinput"
                    chipsArray={ranges}
                    name="dates"
                    multiple
                  />
                </div>
              )}
            </div>
            <div className="copy-schedule__divider"></div>
            <div className="copy-schedule__col">
              <div className="copy-schedule__caption">
                If data exists on the destination dates
              </div>
              <div className="copy-schedule__radio">
                <FormItem
                  formName="radioList"
                  options={[
                    {label: "Replace (remove existing and paste new)", value: 1},
                    {label: "Add (keep existing)", value: 2},
                  ]}
                  name="replaceExisting"
                />
              </div>
            </div>
            <div className="copy-schedule__btns">
              <Button
                title="Cancel"
                bg="secondary"
                size="large"
                onClick={() => onModalClose()}
              />
              <Button title="Save" bg="primary" size="large" htmlButtonType="submit" />
            </div>
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
}

export default ScheduleCopyModal;
