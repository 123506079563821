import React from "react";
import Form from "components/form";
import Button from "components/button";
import ExportExcel from "components/excel/exportExcel";

const exportColumns = [
  {
    label: "Date",
    value: "ScheduleDate",
  },
  {
    label: "Name",
    value: "EmployeeName",
  },

  {
    label: "Assigned Shift",
    value: "AssignedShift",
  },
  {
    label: "Shift Start",
    value: "ShiftStart",
  },
  {
    label: "Shift End",
    value: "ShiftEnd",
  },
  {
    label: "Shift Hours",
    value: "ShiftHours",
  },
  {
    label: "CheckIn",
    value: "CheckedInTime",
  },
  {
    label: "CheckOut",
    value: "CheckedOutTime",
  },
  {
    label: "Workhours",
    value: "WorkingHours",
  },
  {
    label: "OTHours",
    value: "OT",
  },
];

function WorkHoursFilter(props) {
  const {state, onChange, onUpdate, exportData, handleExcelExport} = props;
  return (
    <div>
      <div className="flex workHours-filters">
        <Form
          name="daterange"
          width="200px"
          formName="customdateinput"
          isDateRange
          isNepali={false}
          label="Date Range"
          onChange={onChange}
          value={state.daterange}
        />
        <Form
          name="search"
          formName="searchInput"
          width="200px"
          onChange={onChange}
          value={state.search}
          placeholder="Search Employee"
          label="Search Employee"
        />
        <Form
          name="showAll"
          formName="reactselect"
          options={[
            {label: "Show all", value: 1},
            {label: "Overtime", value: 2},
          ]}
          width="200px"
          onChange={onChange}
          value={state.showAll}
          label="Filter"
        />
        <Button title="Update" bg="primary" onClick={() => onUpdate()} />

        <ExportExcel
          onClick={handleExcelExport}
          fileName={"EmployeeWorkHours"}
          columns={exportColumns}
          sheets={[{sheetName: "EmployeeWorkHours", data: exportData}]}
          className="button button-secondary"
        />
      </div>
    </div>
  );
}

export default WorkHoursFilter;
