import {TMStage} from "./enum";
import copyObject from "helpers/copyObject";

export const updateBoards = (state, board) => {
  let PersonalBoards: any = [...state.boards.PersonalBoards];
  let TeamBoards = [...state.boards.TeamBoards];
  if (board.BoardType === 1) {
    var index = PersonalBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index === -1) PersonalBoards = [board, ...PersonalBoards];
    else PersonalBoards[index] = board;
  } else {
    var index: any = TeamBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index === -1) TeamBoards = [board, ...TeamBoards];
    else TeamBoards[index] = board;
  }
  return {PersonalBoards, TeamBoards};
};
export const deleteBoard = (state, board) => {
  let PersonalBoards: any = [...state.boards.PersonalBoards];
  let TeamBoards = [...state.boards.TeamBoards];
  if (board.BoardType === 1) {
    var index = PersonalBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index > -1) PersonalBoards.splice(index, 1);
  } else {
    var index: any = TeamBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index > -1) TeamBoards.splice(index, 1);
  }
  return {PersonalBoards, TeamBoards};
};
export const archiveBoard = (state, board) => {
  let PersonalBoards: any = copyObject(state.boards.PersonalBoards); // [...state.boards.PersonalBoards];
  let TeamBoards = copyObject(state.boards.TeamBoards); // [...state.boards.TeamBoards];

  if (board.BoardType === 1) {
    if (board.IsArchived) {
      var index = PersonalBoards.findIndex((x) => x.BoardId === board.BoardId);
      if (index > -1) PersonalBoards.splice(index, 1);
    } else {
      PersonalBoards.push(board);
    }
  } else {
    if (board.IsArchived) {
      var index: any = TeamBoards.findIndex((x) => x.BoardId === board.BoardId);
      if (index > -1) {
        TeamBoards.splice(index, 1);
      } else TeamBoards.push(board);
    }
  }
  return {PersonalBoards, TeamBoards};
};
export const swithcBoard = (state, board) => {
  let PersonalBoards: any = [...state.boards.PersonalBoards];
  let TeamBoards = [...state.boards.TeamBoards];

  if (board.BoardType === 1) {
    var index: any = TeamBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index > -1) {
      TeamBoards.splice(index, 1);
      PersonalBoards.push(board);
    }
  } else {
    var index = PersonalBoards.findIndex((x) => x.BoardId === board.BoardId);
    if (index > -1) {
      PersonalBoards.splice(index, 1);
      TeamBoards.push(board);
    }
  }
  return {PersonalBoards, TeamBoards};
};
export const removeBoardLabel = (tasks, boardLabelId) => {
  var newTasks = JSON.parse(JSON.stringify(tasks));

  for (var i = 0; i < tasks.length; i++) {
    let index = newTasks[i].TaskBoardLabels.findIndex(
      (x) => x.BoardLabelId === boardLabelId
    );
    if (index > -1) {
      newTasks[i].TaskBoardLabels.splice(index, 1);
    }
  }
  return newTasks;
};
export const updateBoardLabel = (tasks, obj) => {
  var newTasks = JSON.parse(JSON.stringify(tasks));
  for (var i = 0; i < tasks.length; i++) {
    if (newTasks[i].TaskBoardLabels) {
      let index = newTasks[i].TaskBoardLabels.findIndex((x) => x.BoardLabelId === obj.Id);
      if (index > -1) {
        newTasks[i].TaskBoardLabels[index].LabelName = obj.Name;
        newTasks[i].TaskBoardLabels[index].Color = obj.Color;
      }
    }
  }
  return newTasks;
};

export const updateCheckList = (state, payload) => {
  const {stage, data} = payload;
  let tableView = JSON.parse(JSON.stringify(state.tableView));
  let {ThingsToDo, OnHold, InProgress, Completed, Cancelled} = tableView;

  if (stage === TMStage.ThingsToDo) {
  } else if (stage === TMStage.OnHold) {
  } else if (stage === TMStage.InProgress) {
    //inProgress.push(action.payload);
  } else if (stage === TMStage.Completed) {
  } else if (stage === TMStage.Cancelled) {
    // thingsToDo.push(action.payload);
  }
  return tableView;
};
