import axios from 'helpers/axiosinstance';
import { Apis } from "src/config/api";
import { getErrorResponse } from "helpers/getApiResponse";
import { loadingHeaders } from "helpers/getHeaders";
//#region jobprofile
const getJobProfile = async (searchData) => {
  try {
    let result = await axios.post(Apis.JobProfileSearchApi, searchData);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const getDefaultJobProfile = async (data) => {
  try {
    let result = await axios.get(Apis.JobProfileApi, loadingHeaders);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const getJobProfileById = async (profileId) => {
  try {
    let result = await axios.get(
      Apis.JobProfileApi + `/${profileId}`,
      loadingHeaders
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const createJobProfile = async (data) => {
  try {
    let result = await axios.post(Apis.JobProfileApi, data);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const updateJobProfile = async (data) => {
  try {
    let result = await axios.put(Apis.JobProfileApi, data);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
const deleteJobProfile = async (profileId) => {
  try {
    let result = await axios.delete(Apis.JobProfileApi + `/${profileId}`);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
//#endregion

//#region job description
const getJobDescription = async (searchData) => {
  try {
    let result = await axios.post(Apis.JobDescriptionSearchApi, searchData);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const getJobDescriptionById = async (descriptionId) => {
  try {
    let result = await axios.get(Apis.JobDescriptionApi + `/${descriptionId}`);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const createJobDescription = async (data) => {
  try {
    let result = await axios.post(Apis.JobDescriptionApi, data);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const updateJobDescription = async (data) => {
  try {
    let result = await axios.put(Apis.JobDescriptionApi, data);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

const deleteJobDescription = async (descriptionId) => {
  try {
    let result = await axios.delete(
      Apis.JobDescriptionApi + `/${descriptionId}`
    );
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};
//#endregion

export {
  getJobProfile,
  getDefaultJobProfile,
  getJobProfileById,
  createJobProfile,
  updateJobProfile,
  deleteJobProfile,
  getJobDescription,
  getJobDescriptionById,
  deleteJobDescription,
  createJobDescription,
  updateJobDescription,
};
