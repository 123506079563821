import axios from 'helpers/axiosinstance';
import isEmpty from 'isEmpty'

import {
    GET_LEAVE_REQUEST_STATUS,
    SEARCHED_LEAVE_ABSENCE,
    CLEAR_ERRORS,
    LEAVE_REQUEST_STATUS_LOADING,
    GET_ERRORS,
    LEAVE_ABSENCE_LOADING,
    LEAVE_SOURCE_SELECT_LOADING,
    GET_LEAVE_SOURCE,
    ADD_MESSAGE
  } from '../../../actions/types';
import {toastMessage} from '../../../actions/validateAction'
  

  // Get Filtered Leave
  export const searchLeave= (data) => dispatch => {
    dispatch(setLeaveLoading());
    axios
      .post(`/v1/payroll/leave-and-absenteeism/search`,data)
      .then(res =>{
        dispatch({
          type: SEARCHED_LEAVE_ABSENCE,
          payload: res.data.Data,
          page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
        });
        toastMessage(res);
      }
        
      )
      .catch(err =>{
        dispatch({
          type: SEARCHED_LEAVE_ABSENCE,
          payload: null
        })
      }
        
      );
  };

  // Get Branch
export const getLeaveRequestStatus = id => dispatch => {
    dispatch(setLeaveRequestStatusLoading());
    axios
      // .get(`/v1/payroll/leave-request-status`)
      .get(`/v1/payroll/leave-request/status`)
      .then(res =>{
        let formattedData = [];
        res.data.forEach(data => {
          formattedData.push({
            label: data.Text,
            value: data.Value
          })
        });
        dispatch({
          type: GET_LEAVE_REQUEST_STATUS,
          payload: formattedData
        });
        toastMessage(res);
      }
        
      )
      .catch(err =>
        dispatch({
          type: GET_LEAVE_REQUEST_STATUS,
          payload: null
        })
      );
  };


  export const getLeaveSource = () => dispatch => {
    dispatch({ type: LEAVE_SOURCE_SELECT_LOADING });
    axios
        .get(`/v1/payroll/leavesources`)
        .then(res => {
            dispatch({
                type: GET_LEAVE_SOURCE,
                payload: res.data
            });
            toastMessage(dispatch, res);
        })
        .catch(err => {
            dispatch({
                type: GET_LEAVE_SOURCE,
                payload: null
            });
        });
  };


  // Set loading state
export const setLeaveLoading = () => {
    return {
      type: LEAVE_ABSENCE_LOADING
    };
  };
 
export const setLeaveRequestStatusLoading=()=>{
  return {
    type: LEAVE_REQUEST_STATUS_LOADING
  };
}

  // Clear errors
  export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };
  