import React, {useState} from "react";
import Modal from "components/modal";
import FormGroup from "components/form";
import MomentUtils from "@date-io/moment";
import {TextareaAutosize} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ValidationComponent} from "helpers/getComponents";
import userFormValidation from "validations/useFormValidation";
import validateDisciplinaryActionForm from "validations/validateDisciplinaryActionForm";
import isEmpty from "helpers/isEmpty";
import {Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import Upload from "components/common/upload";
import {
  closeDisciplinaryCase,
  addCounsellingNote,
  addVerbalWarningNote,
  addWrittenWarningNote,
  addActionTakeNote,
} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {Date_Format} from "constants/dateFormat";
import Button from "components/button";
import {EmployeeNameImageCard} from "components/common/employeeNameImageCard";

function ActionForm({
  formData,
  isModalShow,
  closeModal,
  isEditPage,
  employeeList,
  disciplinaryCaseDetails,
  actionsStages,
}) {
  const {
    ActionId,
    SubStageId,
    ActionTitle,
    ActionDateTitle,
    EffectiveFromTitle,
    ActionByTitle,
    ActionNoteTitle,
    SubmitButtonText,
  } = formData;

  const {CaseTitle, CaseStatement} = disciplinaryCaseDetails;
  let INITIAL_STATE;
  if (isEditPage) {
    // INITIAL_STATE = getEditObject(props);
  } else {
    INITIAL_STATE = {
      ActionBy: "",
      ActionDate: null,
      ActionId,
      ActionNote: "",
      ActionTaken: "",
      ActionType: "1",
      DisciplinaryCaseId: disciplinaryCaseDetails.DisciplinaryCaseId,
      EffectiveFromDate: null,
      FileList: [],
      SubStageId,
      SuspensionFrom: null,
      SuspensionTo: null,
    };
  }
  const {errors, handleSubmit, handleChange, isSubmitting, setValues, values} =
    userFormValidation(INITIAL_STATE, validateDisciplinaryActionForm);
  let [isActionResponseShow, setActionResponseShow] = useState(false);
  const dispatch = useDispatch();
  // console.log(errors);
  if (isSubmitting && isEmpty(errors)) {
    // console.log(values);
    SaveActionForm(dispatch, values, closeModal);
  }
  const onSelectChange = (name, value) => {
    handleChange(name, value);
    if (name === "ActionTaken") {
      setActionResponseShow(isEmpty(value) ? false : true);
    }
  };
  const onChange = (name, value) => {
    //let { name, value } = e.target;
    handleChange(name, value);
  };
  const onDateChange = (date) => {
    handleChange("ActionDate", date);
  };
  const onEffectiveDateChange = (date) => {
    handleChange("EffectiveFromDate", date);
  };

  const handleRemove = (name, value) => {
    if (values && values[name] && values[name].length > 0) {
      let newArrItem = values[name].filter((item) => item?.value !== value);
      setValues({
        ...values,
        [name]: newArrItem,
      });
    }
  };

  const isViewMode = false;
  const setSelectedFiles = (fileList) => {
    handleChange("FileList", fileList);
  };
  const ActionTaken =
    ActionId === 4
      ? actionsStages
          .find((x) => x.ActionStageId === 4)
          .SubStages.map((x) => {
            return {value: x.ActionSubStageId, label: x.SubStageName};
          })
      : [];
  return (
    <Modal
      open={isModalShow}
      onModalClose={closeModal}
      title={ActionTitle}
      className="dsp-action-modal"
    >
      <form>
        <div className="action-body">
          <div className="form-row">
            <span className="case-title">{CaseTitle}</span>
            <span className="case-statement">{CaseStatement}</span>
          </div>
          {ActionId === 4 ? (
            <div className="form-row">
              <ValidationComponent
                rootClassName="form-group select"
                error={errors.ActionTaken}
                errorClassName="error__message"
              >
                <label htmlFor="ActionTaken" className="form-group__label">
                  Action Taken
                </label>
                <FormGroup
                  formName="reactselect"
                  onChange={onSelectChange}
                  name="ActionTaken"
                  value={values.ActionTaken}
                  options={ActionTaken}
                  width="300px"
                  placeholder="select an action"
                  disabled={isViewMode}
                />
              </ValidationComponent>
            </div>
          ) : null}
          <div className="form-row flex flex-gap-20">
            <ValidationComponent
              error={errors.ActionDate}
              errorClassName="error__message"
            >
              <label
                className="form-group__label display-block mb-sm"
                htmlFor="ActionDate"
              >
                {ActionDateTitle}
              </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="pickers">
                  <KeyboardDatePicker
                    disabled={isViewMode}
                    format="MMMM D, YYYY"
                    name="ActionDate"
                    onChange={onDateChange}
                    style={{width: "300px"}}
                    value={values.ActionDate}
                    variant="inline"
                  />
                </div>
              </MuiPickersUtilsProvider>
            </ValidationComponent>
            {ActionId === 4 ? (
              <ValidationComponent
                error={errors.ActionDate}
                errorClassName="error__message"
              >
                <label
                  className="form-group__label display-block mb-sm"
                  htmlFor="EffectiveFromDate"
                >
                  {EffectiveFromTitle}
                </label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div className="pickers">
                    <KeyboardDatePicker
                      disabled={isViewMode}
                      format="MMMM D, YYYY"
                      name="EffectiveFromDate"
                      onChange={onEffectiveDateChange}
                      style={{width: "300px"}}
                      variant="inline"
                      value={values.EffectiveFromDate}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </ValidationComponent>
            ) : null}
          </div>
          {isActionResponseShow ? (
            <ActionResponse
              errors={errors}
              isViewMode={isViewMode}
              onDateChange={onDateChange}
              values={values}
            />
          ) : null}
          <div className="form-row">
            <ValidationComponent
              error={errors.ActionBy}
              errorClassName="error__message"
              rootClassName="form-group select autoselect"
            >
              <label htmlFor="ActionBy" className="form-group__label">
                {ActionByTitle}
              </label>
              <FormGroup
                disabled={isViewMode}
                formName="reactselect"
                multiple
                name="ActionBy"
                onChange={onSelectChange}
                options={employeeList}
                placeholder="select an employee"
                value={values.ActionBy}
                width={ActionId === 4 ? "620px" : "300px"}
              />
            </ValidationComponent>
          </div>
          <div className="form-row flex flex-wrap flex-gap">
            {values &&
              values.ActionBy &&
              values?.ActionBy.length > 0 &&
              values.ActionBy.map((actionBy) => {
                return (
                  actionBy && (
                    <EmployeeNameImageCard
                      designation={actionBy?.designation}
                      handleRemove={() => handleRemove("ActionBy", actionBy?.value)}
                      image={actionBy?.img}
                      name={actionBy?.label}
                      showRemoveIcon={true}
                      width="48%"
                    />
                  )
                );
              })}
          </div>
          {ActionId === 3 ? (
            <div className="form-row">
              <div className="actiontype-row">
                <RadioGroup
                  className="action-radio"
                  name="ActionType"
                  onChange={(event, value) => onChange(event.target.name, value)}
                  value={values.ActionType}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Email" />
                  <FormControlLabel
                    control={<Radio />}
                    label="Physical Document"
                    value="2"
                  />
                </RadioGroup>
                {/* {values.ActionType === "2" ? ( */}
                <ValidationComponent
                  error={errors.FileList}
                  errorClassName="error__message"
                  rootClassName="form-group select"
                >
                  <Upload
                    fileList={values.FileList}
                    isViewMode={isViewMode}
                    noSelectedText="No document selected"
                    setSelectedFiles={setSelectedFiles}
                    uploadBtnText="Attach document"
                  />
                </ValidationComponent>
                {/* ) : null} */}
              </div>
            </div>
          ) : null}
          <div className="form-row">
            <ValidationComponent
              error={errors.ActionNote}
              errorClassName="error__message"
              rootClassName="form-group"
            >
              <label htmlFor="ActionNote" className="form-group__label">
                {ActionNoteTitle}
              </label>
              <FormGroup
                cols={50}
                disabled={isViewMode}
                formName="textarea"
                name="ActionNote"
                onChange={onChange}
                rows={6}
                value={values.ActionNote}
              />
            </ValidationComponent>
          </div>
          {ActionId === 4 ? (
            <div className="form-row">
              <ValidationComponent
                error={errors.FileList}
                errorClassName="error__message"
                rootClassName="form-group select"
              >
                <Upload
                  fileList={values.FileList}
                  isViewMode={isViewMode}
                  noSelectedText="No document selected"
                  setSelectedFiles={setSelectedFiles}
                  uploadBtnText="Attach document"
                />
              </ValidationComponent>
            </div>
          ) : null}
        </div>
        <div className="action-footer">
          <Button
            bg="secondary-dark"
            isDisabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            title="Cancel"
          />
          <Button
            bg="primary"
            isDisabled={isSubmitting}
            onClick={handleSubmit}
            title={SubmitButtonText}
          />
        </div>
      </form>
    </Modal>
  );
}

const ActionResponse = ({errors, values, isViewMode, onDateChange}) => {
  let ActionTaken = null;
  switch (values.ActionTaken.value) {
    case 1: {
      ActionTaken = (
        <div className="form-row">
          <div className="flex">
            <ValidationComponent
              error={errors.SuspensionFrom}
              errorClassName="error__message"
              rootClassName="form-group select"
            >
              <label htmlFor="SuspensionFrom" className="form-group__label">
                Suspension from
              </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="pickers">
                  <KeyboardDatePicker
                    disabled={isViewMode}
                    name="SuspensionFrom"
                    onChange={onDateChange}
                    value={values.SuspensionFrom}
                    width="220px"
                  />
                </div>
              </MuiPickersUtilsProvider>
            </ValidationComponent>
            <ValidationComponent
              error={errors.SuspensionTo}
              errorClassName="error__message"
              rootClassName="form-group select"
            >
              <label htmlFor="SuspensionTo" className="form-group__label">
                Suspension to
              </label>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="pickers">
                  <KeyboardDatePicker
                    disabled={isViewMode}
                    name="SuspensionTo"
                    onChange={onDateChange}
                    value={values.SuspensionTo}
                    width="220px"
                  />
                </div>
              </MuiPickersUtilsProvider>
            </ValidationComponent>
          </div>
        </div>
      );
    }
  }
  return ActionTaken;
};

const SaveActionForm = async (dispatch, data, closeModal) => {
  const {
    ActionBy,
    ActionNote,
    ActionType,
    ActionId,
    ActionTaken,
    ActionDate,
    DisciplinaryCaseId,
    EffectiveFromDate,
    FileList,
    SubStageId,
    SuspensionFrom,
    SuspensionTo,
  } = data;

  switch (ActionId) {
    case 0:
      {
        let saveData = {
          AuthorizedBy: ActionBy.map((x) => x.value).join(","),
          CaseRefId: DisciplinaryCaseId,
          ClosureDate: ActionDate,
          ClosureDateEng: ActionDate,
          ClosureNote: ActionNote,
        };
        await dispatch(closeDisciplinaryCase(saveData, closeModal));
      }
      break;
    case 1:
      {
        let saveData = {
          CaseRefId: DisciplinaryCaseId,
          CounselledBy: ActionBy.map((x) => x.value).join(","),
          CounselledDate: ActionDate,
          CounselledDateEng: ActionDate,
          CounsellingNote: ActionNote,
        };
        await dispatch(addCounsellingNote(saveData, closeModal));
      }
      break;
    case 2:
      let saveData = {
        CaseRefId: DisciplinaryCaseId,
        InPresenceOf: ActionBy.map((x) => x.value).join(","),
        WarningDate: ActionDate,
        WarningDateEng: ActionDate,
        WarningNote: ActionNote,
      };
      await dispatch(addVerbalWarningNote(saveData, closeModal));
      break;
    case 3:
      {
        // let saveData = {
        //   caseRefId: disciplinaryCaseId,
        //   subStageRef_Id: SubStageId,
        //   warningDate: ActionDate,
        //   warningDateEng: ActionDate,
        //   authorizedBy: ActionBy.map((x) => x.value).join(","),
        //   responseMedium: actionType,
        //   warningNote: ActionNote,
        //   fileList,
        // };
        const fd = new FormData();
        fd.append("CaseRefId", DisciplinaryCaseId);
        fd.append("SubStageRefId", SubStageId ? SubStageId : 0);
        fd.append("WarningDate", Date_Format(ActionDate));
        fd.append("WarningDateEng", Date_Format(ActionDate));
        fd.append("AuthorizedBy", ActionBy.map((x) => x.value).join(","));
        fd.append("ResponseMedium", ActionType);
        fd.append("WarningNote", ActionNote);
        FileList.map((x, i) => {
          fd.append(`Files`, x);
        });

        //fd.append("Files", FileList);
        await dispatch(addWrittenWarningNote(fd, DisciplinaryCaseId, closeModal));
      }
      break;
    case 4:
      {
        // let saveData = {
        //   caseRefId: disciplinaryCaseId,
        //   actionTakenId: ActionTaken.value,
        //   actionDate,
        //   actionDateEng: actionDate,
        //   authorizedBy: ActionBy.map((x) => x.value).join(","),
        //   ActionNote,
        //   fileList,
        // };
        const fd = new FormData();
        fd.append("CaseRefId", DisciplinaryCaseId);
        fd.append("ActionTakenId", ActionTaken.value);
        fd.append("ActionDate", Date_Format(ActionDate));
        fd.append("ActionDateEng", Date_Format(ActionDate));
        fd.append("EffectiveFromDate", Date_Format(EffectiveFromDate));
        fd.append("EffectiveFromDateEng", Date_Format(EffectiveFromDate));
        fd.append("AuthorizedBy", ActionBy.map((x) => x.value).join(","));
        fd.append("ActionNote", ActionNote);
        FileList.map((x, i) => {
          fd.append(`Files`, x);
        });
        //fd.append("Files", FileList);
        await dispatch(addActionTakeNote(fd, DisciplinaryCaseId, closeModal));
      }
      break;
    default:
      break;
  }
};

export default ActionForm;
