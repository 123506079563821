import React, {useRef, useEffect} from "react";
import {
  getUser,
  getBaseURL,
  getPublicRecruitmentBaseURL,
  loginUser,
  refreshToken,
  getIsHRorFinance,
  getHrCommonSettings,
} from "actions/authAction";
import {
  GetCompanyHolidaysWhole,
  GetCompanyInformation,
  GetEmpMenus,
} from "actions/commonAction";
import {useDispatch, useSelector} from "react-redux";
import {
  getFeatureFlag,
  getActiveFeatures,
  getActiveFeatureFlag,
  activeFlags,
} from "actions/permissionsAction";
import {roles} from "constants/constants";
import isEmpty from "helpers/isEmpty";

function useCommonAPIs() {
  const dispatch = useDispatch();
  const {isAuthenticated, user, baseUrl} = useSelector((state: any) => state.auth);
  const {activeFeatureFlags} = useSelector((state: any) => state.permissionReducer);
  const {isRequestedForCompanyInformation} = useSelector(
    (state: any) => state.commonReducer
  );
  const {refresh_loading} = useSelector((state: any) => state.loadingReducer);
  useEffect(() => {
    (async () => {
      await dispatch(getBaseURL());
      await dispatch(getPublicRecruitmentBaseURL());
      (!activeFeatureFlags || activeFeatureFlags?.length === 0) &&
        (await dispatch(getActiveFeatureFlag()));
      await dispatch(getFeatureFlag());
      // await dispatch(getActiveFeatures());
      // await dispatch(GetEmpMenus());

      // await dispatch(GetCompanyHolidaysWhole());
    })();
  }, []);
  let a: any = roles();

  useEffect(() => {
    if (
      isAuthenticated &&
      baseUrl &&
      !isEmpty(user) &&
      !isRequestedForCompanyInformation
    ) {
      dispatch(
        GetCompanyInformation(a.includes(user.RoleId), baseUrl?.NetFrameworkAppBaseUrl)
      );
    }
  }, [isAuthenticated, baseUrl, user]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const promiseArray = [
          dispatch(getUser()),
          // dispatch(GetCompanyHolidaysWhole()),
          dispatch(getIsHRorFinance()),
          dispatch(getHrCommonSettings()),
        ];
        await Promise.all(promiseArray);
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user.RoleName === "Administrator") dispatch(getActiveFeatures());
    else dispatch(GetEmpMenus());
  }, [isAuthenticated, user]);
}

export default useCommonAPIs;
