import React, { useState, useEffect } from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import ReviewCycleTable from './reviewCycleTable'
import ReviewCycleModal from './reviewCycleModal';
import {useDispatch, useSelector} from "react-redux";
import { getAppraisalReviewCycle, getAppraisalReviewCycleDropdown } from '../helpers/action';

function ReviewsCycle() {
  const [open,setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();
  useEffect(()=>{
    getReviewCycle()
    dispatch(getAppraisalReviewCycleDropdown())
  },[])
  const getReviewCycle = () =>{
    dispatch(getAppraisalReviewCycle({
      "pageIndex":0,
      "pageSize":20
    }))
  }
  return (
    <div className='appraisals-container'>
    <div className="appraisals-title">
      <span>Review Cycles</span>
      <Button 
        onClick={()=>setOpen(true)}
        title='Add Review Cycle' bg="primary" size="small" />
      <ReviewCycleModal 
        open={open}
        getReviewCycle={getReviewCycle}
        onModalClose={onModalClose}
      />
    </div>
    <ReviewCycleTable getReviewCycle={getReviewCycle} />
  </div>
  )
}

export default ReviewsCycle