import React, {useState} from "react";
import shortid from "shortid";
import Modal from "components/modal";
import Form from "components/form";
import Button from "components/button";
import {
  BsFillQuestionSquareFill,
  BsQuestionCircleFill,
  BsTextareaT,
} from "react-icons/bs";
import {MdCheckBox, MdClose} from "react-icons/md";
import QuestionLabel from "./questionLabel";
import {FaFont} from "react-icons/fa";
import {IoMdRadioButtonOn} from "react-icons/io";
import RadioOption from "../../common/radioOption";
import {LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";

enum radioType {
  normal = "normal",
  yes = "yes",
  true = "true",
}

const questions = [
  {label: <QuestionLabel label="Short Text" icon={<FaFont />} />, value: 1},
  {label: <QuestionLabel label="Long Text" icon={<BsTextareaT />} />, value: 2},
  {label: <QuestionLabel label="Radio Buttons" icon={<IoMdRadioButtonOn />} />, value: 3},
  {label: <QuestionLabel label="Check Boxes" icon={<MdCheckBox />} />, value: 4},
];

const Question = (props) => {
  const {onChange, item, changeState, onQuestionAdd, onQuestionRemove} = props;
  const {
    questionType,
    question,
    id,
    radioOptions,
    isRandomised,
    selectionFrom,
    selectionTo,
  } = item;
  const [randomize, setRandomize] = useState(false);
  const [bulkAnswers, setBulkAnswers] = useState("");
  const [open, setOpen] = useState(false);
  const onModalClose = () => setOpen(false);
  const handleChange = async (name, value) => {
    await onChange(id, name, value);
    if (name === "questionType" && (value.value === 3 || value.value === 4)) {
      const options = [
        {id: shortid.generate(), isChecked: false, answer: "", marks: 0, type: "normal"},
        {id: shortid.generate(), isChecked: false, answer: "", marks: 0, type: "normal"},
      ];
      await onChange(id, "radioOptions", options);
    }
  };
  const addRadioButton = () => {
    changeState((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const radioItem = {
            id: shortid.generate(),
            isChecked: false,
            answer: "",
            marks: 0,
            type: "normal",
          };
          return {
            ...item,
            radioOptions: item.radioOptions
              ? [...item.radioOptions, radioItem]
              : [radioItem],
          };
        }
        return item;
      })
    );
  };
  const coupleQuestion = (type) => {
    changeState((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const radioItems = [
            {
              id: shortid.generate(),
              isChecked: false,
              answer: type === radioType.yes ? "Yes" : "True",
              marks: 0,
              type,
            },
            {
              id: shortid.generate(),
              isChecked: false,
              answer: type === radioType.yes ? "No" : "False",
              marks: 0,
              type,
            },
          ];
          return {
            ...item,
            radioOptions: radioItems,
          };
        }
        return item;
      })
    );
  };
  const onDuplicate = () => {
    changeState((prev) => [
      ...prev,
      {
        ...item,
        id: shortid.generate(),
      },
    ]);
  };
  const onBulkAnswer = () => {
    if (bulkAnswers) {
      const answerSplit = bulkAnswers.split(/\r?\n/);
      if (answerSplit && Array.isArray(answerSplit)) {
        const allAnswers = answerSplit
          .map((item) => {
            return {
              id: shortid.generate(),
              isChecked: false,
              answer: item,
              marks: 0,
              type: radioType.normal,
            };
          })
          .filter((item) => item.answer !== "");
        changeState((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                radioOptions: allAnswers,
              };
            }
            return item;
          })
        );
      }
    }
    setBulkAnswers(null);
    setOpen(false);
    debugger;
  };
  return (
    <div className="courseForm-question">
      <Modal
        onModalClose={onModalClose}
        open={open}
        title="Add Bulk Answers"
        className="courseForm-bulk"
        width="600px"
      >
        <div className="courseForm-bulk__modal">
          <div className="courseForm-bulk__form">
            <Form
              width="100%"
              value={bulkAnswers}
              name="bulkAnswers"
              formName="textarea"
              onChange={(name, value) => setBulkAnswers(value)}
            />
          </div>
          <div className="courseForm-bulk__footer">
            <Button bg="secondary" title="Cancel" onClick={() => onModalClose()} />
            <Button bg="primary" title="Add" onClick={() => onBulkAnswer()} />
          </div>
        </div>
      </Modal>
      <div className="courseForm-question__header">
        <div className="courseForm-question__header-action question-header-row">
          <BsQuestionCircleFill />
          <span>Question</span>
        </div>
        <div className="questionheader-action">
          <div onClick={() => onDuplicate()} className="questionheader-info">
            {/* <IoCopyOutline /> */}
            <BsFillQuestionSquareFill />
            <span>Duplicate</span>
          </div>
          <div onClick={() => onQuestionRemove(id)} className="questionheader-secondary">
            <MdClose />
            <span>Delete</span>
          </div>
        </div>
      </div>
      {/* #4F6066 */}
      <div className="courseForm-question__body">
        <div className="courseForm-question__body-form">
          <Form
            label={
              questionType.value === 1
                ? "Short Text"
                : questionType.value === 2
                ? "Long Text"
                : questionType.value === 3
                ? "Radio buttons"
                : questionType.value === 4
                ? "Check boxes"
                : null
            }
            value={question}
            width="100%"
            name="question"
            onChange={handleChange}
          />
          <Form
            width="300px"
            value={questionType}
            options={questions}
            name="questionType"
            formName="reactselect"
            onChange={handleChange}
          />
        </div>
        {(questionType.value === LMSQuestionTypeEnum.RadioButton ||
          questionType.value === LMSQuestionTypeEnum.CheckBox) && (
          <div className="courseForm-question__body-radio">
            <div className="courseForm-question__body-options">
              <div className="courseForm-question__body-col">
                <span>Options</span>
                <a onClick={() => setOpen(true)}>
                  <span>+ Bulk answers</span>
                </a>
              </div>
              {questionType.value === 3 && (
                <div className="courseForm-question__body-col">
                  <a onClick={() => coupleQuestion(radioType.yes)}>
                    <span>Yes | No</span>
                  </a>
                  <a onClick={() => coupleQuestion(radioType.true)}>
                    <span>True | False</span>
                  </a>
                </div>
              )}
            </div>
            <div className="courseForm-question__body-formgroup">
              <label htmlFor="" className="courseForm-question__body-label">
                Select the correct answer below
              </label>
              <div className="courseForm-question__body-radiolist">
                {radioOptions &&
                  radioOptions.map((item, key) => (
                    <RadioOption
                      index={key}
                      changeState={changeState}
                      onChange={onChange}
                      parentId={id}
                      {...item}
                    />
                  ))}
                {/* <RadioOption /> */}
              </div>
            </div>
            {questionType.value === LMSQuestionTypeEnum.CheckBox && (
              <div className="courseForm-question__body-options-alt">
                <div className="courseForm-question__body-col">
                  <div className="courseForm-question__body-label flex">
                    <span>Selection limits: From</span>
                    <Form
                      name="selectionFrom"
                      formName="stepper"
                      stepperType="alternative"
                      value={selectionFrom}
                      minValue={"0"}
                      maxValue={"5"}
                      onChange={handleChange}
                    />
                    <span>to</span>
                    <Form
                      name="selectionTo"
                      formName="stepper"
                      stepperType="alternative"
                      value={selectionTo}
                      minValue={"0"}
                      maxValue={"5"}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{padding: 0, marginTop: 24}}
              className="courseForm-question__body-options"
            >
              <div className="courseForm-question__body-col">
                <a onClick={() => addRadioButton()}>
                  <span>Add another choice</span>
                </a>
              </div>
              <div className="courseForm-question__body-col">
                <Form
                  width="230px"
                  value={isRandomised}
                  labelPosition="right"
                  label="Randomize order of choices"
                  name="isRandomised"
                  formName="checkgroup"
                  onChange={(e) => handleChange("isRandomised", e.target.checked)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="courseForm-question__footer">
        <a onClick={() => onQuestionAdd()}>
          <span>Add another question</span>
        </a>
      </div>
    </div>
  );
};

export default Question;
