import React from "react";
import FormGroup from "components/form";
import styled from "styled-components";

const AllowanceExpenseList = ({
  filteredAllowanceList,
  handleAddSelectedAllowance,
  handleCheckboxChange,
  handleExpenseModalShow,
  selectedAllowanceIdList,
}) => {
  return (
    <ExpenseListContainer>
      <p>Select Applicable Expenses to this Travel Category</p>

      <form onSubmit={(values) => handleAddSelectedAllowance(values)}>
        {filteredAllowanceList &&
          filteredAllowanceList?.length > 0 &&
          filteredAllowanceList.map((filteredAllowance, index) => (
            <ExpenseList key={`${index}${filteredAllowance?.label}`}>
              <FormGroup
                formName="checkgroup"
                label={filteredAllowance.label}
                // name="IsBillRequired"
                labelPosition="right"
                checked={
                  selectedAllowanceIdList?.length > 0 &&
                  selectedAllowanceIdList.includes(filteredAllowance.value)
                }
                onChange={(e) => handleCheckboxChange(e, filteredAllowance.value)}
                // value={formData.IsBillRequired}
              />
            </ExpenseList>
          ))}
        <div className="form-row footer-grp button-group justify-between">
          <button
            type="button"
            className="footer__cancel button button-white"
            // disabled={submitting}
            onClick={() => handleExpenseModalShow(false, "normal")}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="footer__save button button-primary"
            // disabled={submitting}
          >
            Save
          </button>
        </div>
      </form>
    </ExpenseListContainer>
  );
};

const ExpenseListContainer = styled.div`
  margin: 1rem 1.5rem;
`;

const ExpenseList = styled.div`
  margin: 1rem 0.5rem;
`;

export default AllowanceExpenseList;
