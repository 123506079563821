import React from 'react'
import {Tabs, Tab} from "components/tabs/index.d";
import Button from 'components/button';
import SelectEmployee from './selectEmployee';


function BatchRollOutModal(props) {
    const {onModalClose} = props;
  return (
    <div className='appraisals-modal__container'>
        <Tabs noBackground>
            <Tab label="1. Select Employees" name="1. Select Employees">
                <SelectEmployee />
            </Tab>
            <Tab label="2. Assessment Details" name="2. Assessment Details">
                <span>2. Assessment Details</span>
            </Tab>
            <Tab label="3. Confirmation" name="3. Confirmation">
                <span>3. Confirmation</span>
            </Tab>
        </Tabs>
        <div className="appraisals-modal__body"></div>
        <div className="appraisals-modal__footer">
            <Button 
                onClick={()=>onModalClose()}
                bg="subtle" title="Cancel" />
            <Button bg='primary' title="Next" />
        </div>
    </div>
  )
}

export default BatchRollOutModal