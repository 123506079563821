import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";

export const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Attendance",
    feature: features?.LeaveAndTime,
    links: [
      {
        title: "Attendance Chart(Monthly)",
        link: "Attendance/HRAttendanceEmpMontlySummary.aspx",
      },
      {
        title: "Employee Attendance Details",
        link: "Attendance/HRAttendanceEmpMonthDetails.aspx",
      },
      {
        title: "Branch Employee Attendance",
        link: "Attendance/EmployeeBranchAttendance.aspx",
      },
      {
        title: "Weekly Attendance Summary",
        link: "Attendance/WeeklyAttendanceDetails.aspx",
      },
      {title: "Time Attendance Report", link: "Attendance/TimeAttendanceReport.aspx"},
    ],
  },
  {
    groupTitle: "Work Days(hours)",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Work Days", link: "Attendance/HREmployeeAttSummary.aspx"},
      {title: "Work Days Summary", link: "Attendance/MonthlyAttendanceSummary.aspx"},
      {title: "Generate Work Hours", link: "newhr/GenerateAttendanceReportData.aspx"},
      {
        title: "Work Hours/Attendance(Summary)",
        link: "Attendance/AttendanceSummary.aspx",
      },
      {
        title: "Work Hours/Attendance(Details)",
        link: "Attendance/AttendanceDetails.aspx",
      },
    ],
  },
  {
    groupTitle: "Absentism",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Absent Deduction Summary", link: "newhr/AttendanceDeductionReport.aspx"},
      {title: "Absent Deduction Details", link: "newhr/AttendanceDeductionDetails.aspx"},
    ],
  },
  {
    groupTitle: "Leave Balances",
    feature: features?.LeaveAndTime,
    links: [
      {title: "Yearly Leave Report", link: "NewHR/YearLeaveReport.aspx"},
      {title: "Monthly Leave Report", link: "CP/Report/HRLeaveBalance.aspx"},
      {title: "Consolidated Leave Bal", link: "CP/Report/ConsolidatedLeaveReport.aspx"},
      {
        title: "Leave Balances for a Period",
        link: "CP/Report/HRLeaveBalanceSummary.aspx",
      },
    ],
  },
  {
    groupTitle: "Leave Taken",
    feature: features?.LeaveAndTime,
    links: [{title: "Leave Taken Report", link: "CP/Report/LeaveInADayReport.aspx"}],
  },
];

function Reports(props) {
  return (
    <div>
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Leave & Time Reports"
        localStorageTitle="LTR"
        modules={modules}
      />
    </div>
  );
}

export default Reports;
