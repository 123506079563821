import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routeEnum";
import queryUrl from "helpers/getQueryUrl";
import HttpErrorResponseModel from 'src/utils/http/httpErrorResponseModel'

// Review Cycles
export const getAppraisalReviewCycle = (page) => async (dispatch) => {
    const res = Http.post(environment.api.v1.appraisals.reviewCycle.list,page);
    const actionConfig = {
      dispatch,
      actionType: types.GET_APPRAISALS_REVIEW_CYCLES,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
};

export const getAppraisalReviewCycleDropdown = () => async (dispatch) => {
    const res = Http.get(environment.api.v1.appraisals.reviewCycle.dropdown);
    const actionConfig = {
      dispatch,
      actionType: types.GET_APPRAISALS_REVIEW_CYCLES_DROPDOWN,
      effect: res,
      isLoading: true,
    };
    await Action.createThunkEffect(actionConfig);
};


export const addAppraisalReviewCycle = (data) => async (dispatch) => {
    const res = Http.post(environment.api.v1.appraisals.reviewCycle.initial, data);
    const isError = await res instanceof HttpErrorResponseModel;
    const actionConfig = {
      dispatch,
      actionType: types.ADD_APPRAISALS_REVIEW_CYCLE,
      effect: res,
      isLoading: true,
      message:'Review Cycle Added Successfully'
    };
     await Action.createThunkEffect(actionConfig);
    return !isError;
};

export const updateAppraisalReviewCycle = (data) => async (dispatch) => {
    const res = Http.put(environment.api.v1.appraisals.reviewCycle.initial, data);
    const isError = await res instanceof HttpErrorResponseModel;
    const actionConfig = {
      dispatch,
      actionType: types.UPDATE_APPRAISALS_REVIEW_CYCLE,
      effect: res,
      isLoading: true,
      message:'Review Cycle Updated Successfully'
    };
    await Action.createThunkEffect(actionConfig);
    return !isError;
};

export const deleteAppraisalReviewCycle = (id) => async (dispatch) => {
    const res = Http.delete(environment.api.v1.appraisals.reviewCycle.initial + "/?id=" + id);
    const isError = await res instanceof HttpErrorResponseModel;
    const actionConfig = {
      dispatch,
      actionType: types.DELETE_APPRAISALS_REVIEW_CYCLE,
      effect: res,
      type: "delete",
      id,
      isLoading: true,
      message:'Review Cycle Deleted Successfully'
    };
    await Action.createThunkEffect(actionConfig);
    return !isError;
};
  

// Rating Scale
export const getAppraisalRatingScale = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.appraisals.ratingScale.initial);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_RATING_SCALE,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const deleteAppraisalRatingScale = (id) => async (dispatch) => {
  const res = Http.delete(environment.api.v1.appraisals.ratingScale.initial+'/'+id);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_APPRAISALS_RATING_SCALE,
    effect: res,
    type: "delete",
    id,
    isLoading: true,
    message:'Rating Scale Deleted Successfully'
  };
  await Action.createThunkEffect(actionConfig);
  return !isError;
};


//WorkFlow
export const getWorkFlowAppraisal = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.appraisals.workflow.initial);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_WORKFLOW,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addWorkFlowAppraisal = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.appraisals.workflow.initial, data);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.ADD_APPRAISALS_WORKFLOW,
    effect: res,
    isLoading: true,
    message:'Workflow Added Successfully'
  };
   await Action.createThunkEffect(actionConfig);
  return !isError;
};

export const updateWorkFlowAppraisal = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.appraisals.workflow.initial, data);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_APPRAISALS_WORKFLOW,
    effect: res,
    isLoading: true,
    message:'Workflow Updated Successfully'
  };
  await Action.createThunkEffect(actionConfig);
  return !isError;
};

export const deleteWorkFlowAppraisal = (id) => async (dispatch) => {
  const res = Http.delete(environment.api.v1.appraisals.workflow.initial + "/?id=" + id);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.DELETE_APPRAISALS_WORKFLOW,
    effect: res,
    type: "delete",
    id,
    isLoading: true,
    message:'Workflow Deleted Successfully'
  };
  await Action.createThunkEffect(actionConfig);
  return !isError;
};

export const getWorkFlowAppraisalSteps = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.appraisals.workflow.wrokDlowSteps);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_WORKFLOW_STEPS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getThreeSixtyWorkFlowAppraisal = () => async (dispatch) => {
  const res = Http.get(environment.api.v1.appraisals.workflow.threeSixtyWorkflow);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_THREESIXTY_WORKFLOW,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};


//Goal Databases
export const getAppraisalGoalDatabases = (filter) => async (dispatch) => {
  const final = queryUrl(environment.api.v1.appraisals.goalDatabse.list, {});
  const res = Http.post(final,filter);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_GOAL_DATABASES,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};



//Rollout
export const getAppraisalRollout = (filter) => async (dispatch) => {
  const final = queryUrl(environment.api.v1.appraisals.rollout.list, {});
  const res = Http.post(final,filter);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_ROLLOUTS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const getAppraisalEmployeeBatchRollout = (filter) => async (dispatch) => {
  const final = queryUrl(environment.api.v1.appraisals.rollout.batchRollout, {});
  const res = Http.post(final,filter);
  const actionConfig = {
    dispatch,
    actionType: types.GET_APPRAISALS_EMPLOYEE_BATCH_ROLLOUTS,
    effect: res,
    isLoading: true,
  };
  await Action.createThunkEffect(actionConfig);
};

export const addRolloutAppraisal = (data) => async (dispatch) => {
  const res = Http.post(environment.api.v1.appraisals.rollout.initial, data);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.ADD_APPRAISALS_ROLLOUT,
    effect: res,
    isLoading: true,
    message:'Rollout Added Successfully'
  };
   await Action.createThunkEffect(actionConfig);
  return !isError;
};

export const updateRolloutAppraisal = (data) => async (dispatch) => {
  const res = Http.put(environment.api.v1.appraisals.rollout.initial, data);
  const isError = await res instanceof HttpErrorResponseModel;
  const actionConfig = {
    dispatch,
    actionType: types.UPDATE_APPRAISALS_ROLLOUT,
    effect: res,
    isLoading: true,
    message:'Rollout Updated Successfully'
  };
  await Action.createThunkEffect(actionConfig);
  return !isError;
};
