import environment from "environment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/button";
import {getFullURL} from "helpers/fullURL";
// import Froala from "react-froala-wysiwyg";

declare let FroalaEditor: any;

const FrEditor = (props) => {
  const {id, value, name, onChange} = props;
  const [editor, setEditor] = useState(null);
  const baseUrl = getFullURL(useSelector, "");
  const {token} = useSelector((state: any) => state.auth);
  const config = {
    requestHeaders: {
      authorization: token,
    },

    videoUploadParam: "Files",
    videoUploadURL: environment.api.v1.common.commonUpload,
    videoUploadMethod: "POST",
    // Set max video size to 50MB.
    videoMaxSize: 50 * 1024 * 1024,
    // Allow to upload MP4, WEBM and OGG
    videoAllowedTypes: ["webm", "jpg", "ogg", "mp4"],

    imageUploadParam: "Files",
    // imageUploadParams: imageFormData,
    imageUploadURL: environment.api.v1.common.commonUpload,
    imageUploadMethod: "POST",

    // Set the file upload parameter.
    fileUploadParam: "Files",
    fileUploadURL: environment.api.v1.common.commonUpload,
    fileUploadMethod: "POST",
    // Set max file size to 20MB.
    fileMaxSize: 20 * 1024 * 1024,
    fileAllowedTypes: ["*"],

    // editor.html.get()
    // to get values.
    events: {
      contentChanged: function () {
        const val = this?.html.get?.();
        val && props.onChange && props.onChange(name, this.html.get());
      },
      blur: function () {
        props.onChange && props.onChange(name, this.html.get());
      },
      "image.beforeUpload": function (images) {
        // Return false if you want to stop the image upload.
      },
      "image.uploaded": function (response) {
        // Image was uploaded to the server.
        const res = JSON.parse(response);
        const imageData = res.Data ? res.Data[0] : res;
        //   if(res.Data){
        //       dispatch({type:types.ADD_COURSE_CONTENT,payload:res.Data})
        //   }
        const image = imageData
          ? imageData?.FileLocation + imageData?.ServerFileName
          : "";
        var img_url = baseUrl + image;
        this.image.insert(img_url, false, null, this.image.get(), response);

        return false;
      },
      "image.inserted": function ($img, response) {
        // Image was inserted in the editor.
      },
      "image.replaced": function ($img, response) {
        // Image was replaced in the editor.
      },
      "image.error": function (error, response) {
        // Bad link.
      },
      "video.error": function (error, response) {
        // Bad link.
      },
      "video.inserted": function ($img, response) {
        // Image was inserted in the editor.
      },
      "video.uploaded": function (response) {
        const res = JSON.parse(response);
        const imageData = res.Data ? res.Data[0] : res;
        // if(res.Data){
        //     dispatch({type:isContent ? types.ADD_COURSE_CONTENT : types.UPDATE_COURSE_CONTENT,payload:res.Data})
        // }
        const image = imageData
          ? imageData?.FileLocation + imageData?.ServerFileName
          : "";
        var img_url = image;

        this.video.setSize("300px", "300px");
        const a = this.video.insert(
          `<iframe width="560" height="315" src="${img_url}" frameborder="0" />`
        );
        // const a = this.video.insertByURL(img_url);

        debugger;
        return false;
      },
      "file.error": function (error, response) {
        // Bad link.
      },
      "file.inserted": function ($img, response) {
        // Image was inserted in the editor.
      },
      "file.uploaded": function (response) {
        debugger;
        const res = JSON.parse(response);
        const imageData = res.Data ? res.Data[0] : res;
        //   if(res.Data){
        //       dispatch({type:types.ADD_COURSE_CONTENT,payload:res.Data})
        //   }
        const image = imageData
          ? imageData?.FileLocation + imageData?.ServerFileName
          : "";
        const file = imageData ? imageData?.UserFileName : "File";
        var img_url = baseUrl + image;
        this.file.insert(img_url, file, response);

        return false;
      },
    },
  };

  useEffect(() => {
    (function () {
      let a = new FroalaEditor(`#${name}`, config, function () {
        setEditor(this);
      });

      //  setEditor(a);
    })();
  }, []);

  useEffect(() => {
    let newVal = value || "";
    editor && editor.html.set(newVal);
  }, [editor]);
  return <textarea name="editor_content" id={name}></textarea>;
};

export default FrEditor;
