import React, {useState} from "react";
import {Card} from "components/card";
import {useDispatch, useSelector} from "react-redux";
import Loaders from "components/loaders";
import {getResultSummaryDetail} from "scenes/lms/helpers/action";

import CourseResultSummary from "./courseResultSummary";
import DateTypeList from "./dateTypeList";
import CoursePage from "./coursePage";

const ResultSummaryDetail = () => {
  const [activeDateType, setActiveDateType] = useState(0);

  const {courseResultSummary, resultSummaryDetail, requesting} = useSelector(
    (state: any) => state.lmsReducer
  );

  const dispatch = useDispatch();

  const handleDateTypeClick = (dateType) => {
    const courseId = courseResultSummary[0].CourseId;
    dispatch(getResultSummaryDetail(courseId, dateType));
    setActiveDateType(dateType);
  };

  if (requesting) {
    return (
      <Loaders cardType="course-result" loading={true} name="card" type="skeleton" />
    );
  }

  return (
    <Card background={"none"} flexDirection="column" width="100%">
      {/* course result summary */}
      <CourseResultSummary summary={courseResultSummary[0]} />

      {/* result summary question answer */}
      <DateTypeList
        activeDateType={activeDateType}
        handleDateTypeClick={handleDateTypeClick}
      />
      <CoursePage coursePage={resultSummaryDetail} />
    </Card>
  );
};

export default ResultSummaryDetail;
