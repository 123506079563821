import isEmpty from "isEmpty";
import React, {Component} from "react";
import {connect} from "react-redux";
import {approveJobRequisition, getApproverJobRequisition} from "./action";
import CommonHeaderSection from "./commonHeaderSection";
import Filter from "./filter";
//import {GetMyTravelRequests,DeleteTravelRequests} from './action';
import ApproverRequisitionRequest from "./table/requisitionRequest";
class TravelRequestScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      pageIndex: 1,
      branch: -1,
      department: -1,
      status: -1,
      requisitionNumber: "",
      jobTitle: "",
    };
    this.handleFilterApply = this.handleFilterApply.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }
  componentDidMount() {
    this.getApproverJobRequisition();
  }
  componentDidUpdate = (prevProps, prevState) => {
    const {rowsPerPage, pageIndex} = this.state;
    if (rowsPerPage !== prevState.rowsPerPage || pageIndex !== prevState.pageIndex) {
      this.getApproverJobRequisition();
    }
  };

  getApproverJobRequisition = (requisitionId) => {
    let {
      pageIndex,
      rowsPerPage,
      branch,
      department,
      status,
      requisitionNumber,
      jobTitle,
    } = this.state;

    let params = {
      PageIndex: !isEmpty(pageIndex) ? pageIndex : 0,
      PageSize: !isEmpty(rowsPerPage) ? rowsPerPage : -1,
      BranchId: !isEmpty(branch) ? branch.value : -1,
      DepartmentId: !isEmpty(department) ? department.value : -1,
      StatusId: !isEmpty(status) ? status.value : -1,
      RequisitionNumber: !isEmpty(requisitionNumber) ? requisitionNumber : "-1",
      JobTitle: !isEmpty(jobTitle) ? jobTitle : "-1",
    };
    if (requisitionId) {
      params["RequisitionId"] = requisitionId;
    }
    this.props.getApproverJobRequisition(params);
  };

  // getSearchData = () => {
  //   let {
  //     pageIndex,
  //     rowsPerPage,
  //     branchId,
  //     departmentId,
  //     statusId,
  //     requisitionNumber,
  //     jobTitle,
  //   } = this.state;
  //   return {
  //     PageIndex: !isEmpty(pageIndex) ? pageIndex : 0,
  //     PageSize: !isEmpty(rowsPerPage) ? rowsPerPage : -1,
  //     BranchId: !isEmpty(branchId) ? branchId : -1,
  //     DepartmentId: !isEmpty(departmentId) ? departmentId : -1,
  //     StatusId: !isEmpty(statusId) ? statusId : -1,
  //     RequisitionNumber: !isEmpty(requisitionNumber) ? requisitionNumber : "-1",
  //     JobTitle: !isEmpty(jobTitle) ? jobTitle : "-1",
  //   };
  // };
  handleRowChange(num) {
    this.setState({rowsPerPage: num});
  }
  handleIndexChange(num) {
    this.setState({pageIndex: num + 1});
  }

  handleFilterApply(event) {
    event.preventDefault();
    this.getApproverJobRequisition();
  }

  onChange = (name, value) => {
    this.setState({[name]: value});
  };

  approveJobRequisition = (requisitionId) => {
    this.getApproverJobRequisition(requisitionId);
  };
  renderBody = () => {
    const {loading} = this.props.jobRequisitionReducer;
    return (
      <div className="">
        <div>
          <Filter
            {...this.state}
            onSubmit={this.handleFilterApply}
            onChange={this.onChange}
          />
        </div>
        {!loading ? (
          <div>
            <ApproverRequisitionRequest
              pageIndex={this.state.pageIndex}
              rowsPerPage={this.state.rowsPerPage}
              handleRowChange={this.handleRowChange}
              handleIndexChange={this.handleIndexChange}
              type={1}
              approveJobRequisition={this.approveJobRequisition}
            />
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const selectedTabId = !isEmpty(this.props.location.state)
      ? this.props.location.state.selectedTabId
      : null;
    return (
      <CommonHeaderSection renderBody={this.renderBody()} selectedTabId={selectedTabId} />
    );
  }
}

const mapStateToProps = (state) => ({
  jobRequisitionReducer: state.jobRequisitionReducer,
});

const mapDispatchToProps = {getApproverJobRequisition, approveJobRequisition};

export default connect(mapStateToProps, mapDispatchToProps)(TravelRequestScreen);
