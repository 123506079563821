import React, {useState, useEffect} from "react";
import Table from "./rolesTable/index";
import {useDispatch, useSelector} from "react-redux";
import {deleteRosterTeamRole} from "scenes/roster/helpers/action";
import AddRole from "./addRole";
import Confirm from "components/common/commonConfimation";

function RolesTable() {
  const {teamRoleList, teamList} = useSelector((state: any) => state.roosterReducer);

  const TeamNameCell = (props) => {
    const [open, setOpen] = useState(null);
    const onModalOpen = (Id) => setOpen(Id);
    const onModalClose = () => setOpen(null);

    const [openAddRoleModal, setOpenAddRoleModal] = useState(false);
    const onAddRoleModalOpen = () => setOpenAddRoleModal(true);
    const onAddRoleModalClose = () => setOpenAddRoleModal(false);

    const dispatch = useDispatch();

    const onDelete = (Id) => {
      dispatch(deleteRosterTeamRole(Id));
    };

    return (
      <div className="shifts-table__col">
        <div className="flex justify-between">
          <span className="bold text-capitalize">{props.value}</span>
          <span
            className="color-active bold cursor-pointer position-relative right-md"
            onClick={() => onAddRoleModalOpen()}
          >
            Add Role
          </span>
        </div>
        {openAddRoleModal && (
          <AddRole
            open={openAddRoleModal}
            onModalClose={onAddRoleModalClose}
            isEdit={false}
            selectedTeamName={props?.value ?? null}
          />
        )}
        {teamRoleList?.length > 0 &&
          teamRoleList.map((teamRoleItem, index) => {
            if (teamRoleItem?.TeamName === props?.value) {
              return (
                <div
                  style={{
                    borderTop: "1px solid #e0e0e0",
                  }}
                  className="flex justify-between role-row"
                >
                  <span
                    key={`${teamRoleItem?.Id}${index}`}
                    className="pl-onerm text-capitalize"
                  >
                    {teamRoleItem?.RoleName}
                  </span>
                  <div className="shifts-table__col flex">
                    <div className="shifts-table__links">
                      <span
                        className="color-active"
                        onClick={() => onModalOpen(teamRoleItem?.Id)}
                      >
                        Edit
                      </span>
                    </div>
                    <div className="shifts-table__links">
                      <Confirm
                        okButtonTitle="Delete"
                        action={() => onDelete(teamRoleItem?.Id)}
                      >
                        <span className="color-active">Delete</span>
                      </Confirm>
                    </div>
                    {open === teamRoleItem?.Id && (
                      <AddRole
                        open={true}
                        onModalClose={onModalClose}
                        title="Edit Employee Team"
                        isEdit={true}
                        editData={teamRoleItem}
                      />
                    )}
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  };

  const cols = [
    {
      Header: "Team and Role",
      accessor: "Name",
      Cell: TeamNameCell,
      width: 500,
    },
  ];
  return (
    <div>
      <Table data={teamList ?? []} columns={cols} />
    </div>
  );
}

export default RolesTable;
