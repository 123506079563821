import React, { useState } from "react";
import Icon from "components/icons";
import BoardAdd from "./boardAdd";
const AddBoardButton = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div
                onClick={() => setOpen(true)}
                className="tasks-component__primary-icon"
            >
                <Icon justSVG name="Add" />
            </div>
            {open && (
                <BoardAdd
                    boardType={props.boardType}
                    onModalClose={() => setOpen(false)}
                    open={open}
                />
            )}
        </>
    );
};

export default AddBoardButton;