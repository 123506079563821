import React, {useState, useEffect} from "react";
import "./style.scss";
import Avatar from "react-avatar";
import Form from "components/form";
import Modal from "components/modal";
// import Modal from "../modal";
import EmployeeDetails from "./employeeDetail";
import Button from "components/button";
import {useDispatch, useSelector} from "react-redux";
import {saveEmployeeIntroduction} from "../../admin/employeeOnboarding/action";

export default function IntroduceYourSelf({EmpID, EmployeeOnBoardingDto}) {
  const [introduction, setIntroduction] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    EmployeeOnBoardingDto?.PersonalBio &&
      setIntroduction(EmployeeOnBoardingDto?.PersonalBio);
  }, [EmployeeOnBoardingDto?.PersonalBio]);

  const saveEmployeeIntro = () => {
    let data = {
      // EmpID,
      personalBio: introduction,
      onboardId: EmployeeOnBoardingDto?.EmpOnBoardId,
    };
    dispatch(saveEmployeeIntroduction(data));
  };

  return (
    <div className="employee-onboarding__datetime">
      <div className="employee-onboarding__datetime__header mb-sm text-bold">
        Introduce yourself to the team
      </div>
      <div className="employee-onboarding__datetime__body">
        <div className="employee-onboarding__datetime__date">
          <div className="employee-onboarding__datetime__date_first flex mb-sm">
            <div className="softtext">
              Your team would like to know about you. If you are comfortable sharing these
              information, it will be great. If you are not comfortable to answer, that’s
              ok, just skip this section and proceed.
            </div>
          </div>
        </div>
        <div className="employee-onboarding__datetime__place">
          <Form
            name="introduction"
            formName="textarea"
            value={introduction}
            type="number"
            onChange={(name, value) => setIntroduction(value)}
            label="Write about yourself"
            // width={"30%"}
          />
        </div>
        <Button title="Skip this section" bg="secondary" />
      </div>
      <div className="employee-onboarding__submit">
        <Button title="Save and Send" bg="primary" onClick={() => saveEmployeeIntro()} />
      </div>
    </div>
  );
}
