import React, {useState, useEffect} from "react";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import {isFeatureEnabledValue} from "helpers/checkPermissions";
import ModuleMain from "./moduleMain";
import {GetFavLink} from "src/store/actions/favLink";
import {connect} from "react-redux";

const LinkCategories = (props) => {
  const {baseUrl, title, categories, featureId, category, favLinks} = props;

  useEffect(() => {
    getFavLinks();
  }, [category, featureId]);

  const getFavLinks = async () => {
    if (featureId && category) {
      props.GetFavLink({feature: featureId, category, dashboardType: false});
    }
  };
  const [search, setSearch] = useState("");
  const setFav = (fav) => {};

  return (
    <div className="admin admin-common">
      <div className="admin-container">
        <div className="admin-title">
          <h1>{title}</h1>
        </div>
        <div className="admin-modules">
          <div className="admin-modules__container">
            <div className="admin-modules__block">
              <div className="admin-modules__search">
                <FormGroup
                  formName="searchInput"
                  name="search"
                  value={search}
                  onChange={(name, value) => setSearch(value)}
                  placeholder="Search.."
                />
              </div>

              <div className="admin-modules__contain">
                {favLinks && favLinks.length > 0 && (
                  <div className="admin-modules__list admin-modules__list-fav">
                    <ModuleMain
                      searchValue={search}
                      groupTitle={"Favorite"}
                      links={favLinks}
                      baseUrl={baseUrl}
                      isBaseUrl={!isEmpty(baseUrl)}
                    />
                  </div>
                )}

                <ul className="admin-modules__list">
                  {categories &&
                    categories.map((item, index) => {
                      // isFeatureEnabledValue
                      const feat = item.feature ? item.feature : "CoreHR";
                      if (!isFeatureEnabledValue(feat)) {
                        return false;
                      }
                      return (
                        <ModuleMain
                          key={index}
                          searchValue={search}
                          groupTitle={item.LinkCategory}
                          links={item.Links}
                          baseUrl={baseUrl}
                          isBaseUrl={!isEmpty(baseUrl)}
                        />
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  favLinks: state.favLinkReducer.favLinks,
});
const mapDispatchToProps = {
  GetFavLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkCategories);
