import {BS2AD, AD2BS} from "helpers/dateConvert";
import {getMonthsDays, getMonthsFirstDay} from "helpers/getDateMonthDates";
import moment from "moment";

export const MONTH = "MONTH";
export const YEAR = "YEAR";

export const WEEK_DAYS = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
};

export const CALENDAR_MONTHS = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};
export const NEPALI_WEEK_DAYS_LONG = {
  आइतबार: "आइत",
  सोमबार: "सोम",
  मंगलबार: "मंगल",
  बुधबार: "बुध",
  बिहिबार: "बिहि",
  शुक्रबार: "शुक्र",
  शनिबार: "शनि",
};
export const NEPALI_WEEK_DAYS = {
  आइतबार: "आ",
  सोमबार: "सो",
  मंगलबार: "मं",
  बुधबार: "बु",
  बिहिबार: "बि",
  शुक्रबार: "शु",
  शनिबार: "श",
};

export const CALENDAR_MONTHS_NEPALI_ENG = {
  Baisakh: "Baisakh",
  Jestha: "Jestha",
  Ashadh: "Ashadh",
  Shrawan: "Shrawan",
  Bhadra: "Bhadra",
  Ashwin: "Ashwin",
  Kartik: "Kartik",
  Mansir: "Mansir",
  Poush: "Poush",
  Magh: "Magh",
  Falgun: "Falgun",
  Chaitra: "Chaitra",
};

export const CALENDAR_MONTHS_NEPALI_NEP_ENG = {
  "Apr / May": "Apr/May",
  "May / Jun": "May/Jun",
  "Jun / Jul": "Jun/Jul",
  "Jul / Aug": "Jul/Aug",
  "Aug / Sep": "Aug/Sep",
  "Sep / Oct": "Sep/Oct",
  "Oct / Nov": "Oct/Nov",
  "Nov / Dec": "Nov/Dec",
  "Dec / Jan": "Dec/Jan",
  "Jan / Feb": "Jan/Feb",
  "Feb / Mar": "Feb/Mar",
  "Mar / Apr": "Mar/Apr",
};
const maxNepYear = 2105;
const minNepYear = 1989;

export const CALENDAR_MONTHS_NEPALI = {
  बैसाख: "बै",
  ज्येष्ठ: "जे",
  आषाढ: "आषा",
  श्रावण: "श्रा",
  भाद्र: "भा",
  आश्विन: "आश",
  कार्तिक: "का",
  मंसिर: "मं",
  पौष: "पौ",
  माघ: "मा",
  फाल्गुण: "फा",
  चैत्र: "चै",
};
export const NEPALI_NUM = {
  0: "०",
  1: "१",
  2: "२",
  3: "३",
  4: "४",
  5: "५",
  6: "६",
  7: "७",
  8: "८",
  9: "९",
};

export const CALENDAR_WEEKS = 6;

export const CALENDAR_MONTHS_30 = [4, 6, 9, 11];

export const isDate = (date: any) => {
  const isDate = Object.prototype.toString.call(date) === "[object Date]";
  const isValidDate = date && !Number.isNaN(+date);
  return isDate && isValidDate;
};

function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

function isValidDate2(date: any) {
  return date && Object.prototype.toString.call(date);
}
export const getDateSlash = (date: any = new Date()) => {
  let instance = isValidDate2(date);
  if (instance === "[object String]") {
    if (date.includes("/")) {
      return date;
    }
  } else {
    return isDate(date)
      ? [date.getFullYear(), date.getMonth() + 1, date.getDate()]
          .map((v) => String(v).padStart(2, "0"))
          .join("/")
      : null;
  }
};

export const getSlashedNepaliDate = (date: any = new Date()) => {
  let instance = isValidDate2(date);
  if (instance === "[object Date]") {
    const slashDate = getDateSlash(date);
    return slashDate;
  } else if (instance === "[object Number]") {
    date = new Date(date);
    const slashDate = getDateSlash(date);
    return slashDate;
  } else if (instance === "[object String]") {
    if (date.includes("/")) {
      return date;
    } else {
      throw new Error("Invalid Date");
    }
  } else {
    throw new Error("Invalid Date");
  }
};

export const getNepDate = (date: any = new Date()) => {
  let instance = isValidDate2(date);
  if (instance === "[object Date]") {
    const slashDate = getDateSlash(date);

    const fullDetails = AD2BS(slashDate);
    const newDate =
      fullDetails["en"].year +
      "/" +
      fullDetails["en"].month +
      "/" +
      fullDetails["en"].day;
    return newDate;
  } else if (instance === "[object Number]") {
    date = new Date(date);
    const slashDate = getDateSlash(date);
    const fullDetails = AD2BS(slashDate);
    const newDate =
      fullDetails["en"].year +
      "/" +
      fullDetails["en"].month +
      "/" +
      fullDetails["en"].day;
    return newDate;
  } else if (instance === "[object String]") {
    if (date.includes("/")) {
      const fullDetails = AD2BS(date);
      const newDate =
        fullDetails["en"].year +
        "/" +
        fullDetails["en"].month +
        "/" +
        fullDetails["en"].day;
      return newDate;
    } else {
      throw new Error("Invalid Date");
    }
  } else {
    throw new Error("Invalid Date");
  }
};

export const getNepaliDate = (date: any = new Date()) => {
  let instance = isValidDate2(date);
  if (instance === "[object Date]") {
    const slashDate = getDateSlash(date);

    const fullDetails = AD2BS(slashDate);
    const newDate =
      fullDetails["en"].year +
      "/" +
      fullDetails["en"].month +
      "/" +
      fullDetails["en"].day;
    return newDate;
  } else if (instance === "[object Number]") {
    date = new Date(date);
    const slashDate = getDateSlash(date);
    const fullDetails = AD2BS(slashDate);
    const newDate =
      fullDetails["en"].year +
      "/" +
      fullDetails["en"].month +
      "/" +
      fullDetails["en"].day;
    return newDate;
  } else if (instance === "[object String]") {
    if (date.includes("/")) {
      return date;
    } else {
      throw new Error("Wrong Date");
    }
  } else {
    throw new Error("Wrong Date");
  }
};

export const getEnglishDate = (date) => {
  try {
    const slashDate = getDateSlash(date);

    const fullDetails = BS2AD(slashDate);
    const newDate = fullDetails.year + "/" + fullDetails.month + "/" + fullDetails.day;
    return newDate;
  } catch (ex) {
    return date;
  }
};

export const getEngDate = (date: any = new Date()) => {
  if (date) {
    let instance = isValidDate2(date);
    if (instance === "[object Date]") {
      const slashDate = getDateSlash(date);

      const fullDetails = BS2AD(slashDate);
      const newDate = fullDetails.year + "/" + fullDetails.month + "/" + fullDetails.day;
      return newDate;
    } else if (instance === "[object Number]") {
      date = new Date(date);
      const slashDate = getDateSlash(date);
      const fullDetails = BS2AD(slashDate);
      const newDate = fullDetails.year + "/" + fullDetails.month + "/" + fullDetails.day;
      return newDate;
    } else if (instance === "[object String]") {
      if (date.includes("/")) {
        let fullDetails = BS2AD(date);
        const newDate =
          fullDetails.year + "/" + fullDetails.month + "/" + fullDetails.day;
        return newDate;
      } else {
        throw new Error("Invalid Date");
      }
    } else {
      throw new Error("Invalid Date");
    }
  }
};

export const getNepaliFormatedDate = (isIt, date: any = new Date()) => {
  const [year, month, day] = getNepDateArray(date);
  let monthname;
  let newDay;
  let newYear;
  if (isIt) {
    monthname = Object.keys(CALENDAR_MONTHS_NEPALI)[Math.max(0, Math.min(month - 1, 11))];
    newDay = convertNumToNepali(day);
    newYear = convertNumToNepali(year);
  } else {
    monthname = Object.keys(CALENDAR_MONTHS_NEPALI_ENG)[
      Math.max(0, Math.min(month - 1, 11))
    ];
    newDay = day;
    newYear = year;
  }

  let formatedDate = `${newDay} ${monthname}, ${newYear}`;
  return formatedDate;
};

export const getFullNepaliDate = (date = new Date()) => {
  const slashDate = getDateSlash(date);
  return AD2BS(slashDate);
};

export const getFullNepaliDateFromNep = (date = new Date()) => {
  date = getNepaliDate(date);
  let ad = BS2AD(date);
  return AD2BS(`${ad.year}/${ad.month}/${ad.day}`);
};

export const getFullNepali2EngDate = (date = new Date()) => {
  date = getNepaliDate(date);
  return BS2AD(date);
};

export const getNepDateArray = (date = getNepaliDate()) => {
  const dat = getSlashedNepaliDate(date);
  const [year = null, month = null, day = null] = (dat || "").split("/").map((v) => +v);
  return [year, month, day];
};

export const getNepDateSeperated = (dat) => {
  const [year = null, month = null, day = null] = (dat || "").split("/").map((v) => +v);
  return {year, month, day};
};

export const getNepMonthDays = (date = getNepaliDate()) => {
  const [year, month] = getNepDateArray(date);
  let newYear = year < minNepYear ? minNepYear : year > maxNepYear ? maxNepYear : year;
  let a = getMonthsDays(newYear)[month];
  return a;
};

export const getNepMonthFirstDay = (date = getNepaliDate()) => {
  const [year, month] = getNepDateArray(date);
  let newYear = year < minNepYear ? minNepYear : year > maxNepYear ? maxNepYear : year;
  let days = getMonthsFirstDay(newYear)[month];
  return days;
};

export const getNepPreviousMonth = (date = getNepaliDate()) => {
  const [year, month] = getNepDateArray(date);
  return {
    month: month > 1 ? month - 1 : 12,
    year: month > 1 ? year : year - 1,
  };
};

export const getNepPreviousMonthWithDay = (date = getNepaliDate()) => {
  const [year, month, day] = getNepDateArray(date);
  return {
    month: month > 1 ? month - 1 : 12,
    year: month > 1 ? year : year - 1,
    day: day,
  };
};

export const getNepNextMonth = (date = getNepaliDate()) => {
  const [year, month] = getNepDateArray(date);
  return {
    month: month < 12 ? month + 1 : 1,
    year: month < 12 ? year : year + 1,
  };
};

export const getNepNextMonthWithDay = (date = getNepaliDate()) => {
  const [year, month, day] = getNepDateArray(date);
  return {
    month: month < 12 ? month + 1 : 1,
    year: month < 12 ? year : year + 1,
    day: day,
  };
};

export const getNepNextYearWithDay = (date = getNepaliDate()) => {
  const [year, month, day] = getNepDateArray(date);
  return {
    month: month,
    year: year < maxNepYear ? year + 1 : year,
    day: day,
  };
};

export const getNepPrevYearWithDay = (date = getNepaliDate()) => {
  const [year, month, day] = getNepDateArray(date);
  return {
    month: month,
    year: year > minNepYear ? year - 1 : year,
    day: day,
  };
};

export const dateDiff = (date1, date2 = new Date()) => {
  return isDate(date1) && isDate(date2)
    ? new Date(+date1).setHours(0, 0, 0, 0) - new Date(+date2).setHours(0, 0, 0, 0)
    : null;
};

export const nepDateDiff = (date1, date2 = getNepaliDate()) => {
  let a;
  let b;
  let instance1 = isValidDate2(date1);
  let instance2 = isValidDate2(date2);
  if (instance1 === "[object Date]" && instance1 === "[object Date]") {
    a = new Date(date1);
    b = new Date(date2);
  } else if (instance1 === "[object Number]" && instance2 === "[object Number]") {
    a = new Date(date1);
    b = new Date(date2);
  } else if (instance1 === "[object String]" && instance2 === "[object String]") {
    if (date1.includes("/") && date1.includes("/")) {
      a = new Date(getEngDate(date1));
      b = new Date(getEngDate(date2));
    } else {
      throw new Error("Invalid Date");
    }
  } else {
    a = new Date(date1);
    b = new Date(date2);
  }

  return isDate(a) && isDate(b)
    ? new Date(+a).setHours(0, 0, 0, 0) - new Date(+b).setHours(0, 0, 0, 0)
    : null;
};

export const inBetween = (from: any, to: any, date: any) => {
  const da1 = new Date(getEngDate(from));
  const da2 = new Date(getEngDate(to));
  const da3 = new Date(getEngDate(date));
  const [y1, m1, d1] = getNepDateArray(da1);
  const [y2, m2, d2] = getNepDateArray(da2);
  const [y3, m3, d3] = getNepDateArray(da3);

  var fin1 = new Date(y1, m1 - 1, d1);
  var fin2 = new Date(y2, m2 - 1, d2);
  var check = new Date(y3, m3 - 1, d3);

  return check > fin1 && check < fin2;
};

// export const isBeforeDay = (date1, date2) => +dateDiff(date1, date2) < 0

// export const isAfterDay = (date1, date2) => +dateDiff(date1, date2) > 0

// export const isSameDay = (date1, date2) => dateDiff(date1, date2) === 0

export const isBeforeDay = (date1, date2) => +nepDateDiff(date1, date2) < 0;

export const isAfterDay = (date1, date2) => +nepDateDiff(date1, date2) > 0;

export const isSameDay = (date1, date2) => nepDateDiff(date1, date2) === 0;

export const isSameMonth = (date1, date2) => {
  return isDate(date1) && isDate(date2)
    ? new Date(+date1).setDate(1) - new Date(+date2).setDate(1) === 0
    : false;
};

export const isNepSameMonth = (date1, date2) => {
  const firstDate = getNepDateArray(date1);
  const secondDate = getNepDateArray(date2);
  if (parseInt(firstDate[2]) === 32 && parseInt(secondDate[2]) === 32) {
    return firstDate[0] === secondDate[0] ? firstDate[1] - secondDate[1] === 0 : false;
  } else if (parseInt(firstDate[2]) === 32) {
    return isDate(new Date(date2))
      ? firstDate[0] === secondDate[0]
        ? firstDate[1] - secondDate[1] === 0
        : false
      : false;
  } else if (parseInt(secondDate[2]) === 32) {
    return isDate(new Date(date1))
      ? firstDate[0] === secondDate[0]
        ? firstDate[1] - secondDate[1] === 0
        : false
      : false;
  } else {
    return isDate(new Date(date1)) && isDate(new Date(date2))
      ? firstDate[0] === secondDate[0]
        ? firstDate[1] - secondDate[1] === 0
        : false
      : false;
  }
};

export const convertNumToNepali = (num) => {
  let a = "";
  let strNum = num.toString();
  for (var i = 0; i < strNum.length; i++) {
    a += NEPALI_NUM[strNum[i]];
  }
  return a;
};

export const calendarDates = (date: any = getNepaliDate()) => {
  date = getNepaliDate(date);
  const monthDays = getNepMonthDays(date);
  const monthFirstDay = getNepMonthFirstDay(date) + 1;
  const [year, month] = getNepDateArray(date);
  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth = CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);
  const {month: prevMonth, year: prevMonthYear} = getNepPreviousMonth(date);
  const {month: nextMonth, year: nextMonthYear} = getNepNextMonth(date);

  const prevMonthDays = getNepMonthDays(`${prevMonthYear}/${prevMonth}/1`);
  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => [
    prevMonthYear,
    prevMonth,
    index + 1 + (prevMonthDays - daysFromPrevMonth),
  ]);

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => [
    year,
    month,
    index + 1,
  ]);

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => [
    nextMonthYear,
    nextMonth,
    index + 1,
  ]);
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};

export const validateFormat = (date, isEng = false) => {
  console.log({date});

  if (date) {
    if (isEng) {
      let isValid = false;
      if (/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(date)) {
        isValid = true;
      }
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(date)) {
        isValid = true;
      }
      if (!isValid) {
        throw new Error("Invalid Date / Date Format");
      }
    } else {
      if (!/^\d{4}\/\d{1,2}\/\d{1,2}$/.test(date)) {
        throw new Error("Invalid Date / Date Format");
      }
    }
  }
  return true;
};

export const getPagedYears = (year, yearSetting, isEng = false) => {
  // const { maxdate, mindate, year, month, today } = this.state;
  // const [maxYear] = getNepDateArray(maxdate);
  // const [minYear] = getNepDateArray(mindate);
  const maxYear = maxNepYear;
  const minYear = isEng ? 1900 : minNepYear;

  let rightArrow = false;
  let leftArrow = false;
  const allYears = Array(maxYear - minYear)
    .fill(minYear)
    .map((val, index) => {
      return {year: val + index};
    });
  const yearIndex = allYears.findIndex((item) => item.year === year);
  // const yearIndex = allYears.findIndex((item)=>item.year===thisYear);
  const minIndex = allYears.findIndex((item) => item.year === minYear);
  const maxIndex = allYears.findIndex((item) => item.year === maxYear - 1);
  var SplittedYears = [];
  const {indexPoint, indexLeap} = yearSetting;
  if (maxIndex - yearIndex < 11) {
    if (maxIndex - yearIndex - indexPoint * indexLeap < 11) {
      SplittedYears = allYears.slice(
        yearIndex - 6 + indexPoint * indexLeap,
        maxIndex + 1 + indexPoint * indexLeap
      );
      rightArrow = true;
    } else if (yearIndex - minIndex + indexLeap * indexPoint < 11) {
      SplittedYears = allYears.slice(0, 18);
      leftArrow = true;
    } else {
      SplittedYears = allYears.slice(
        yearIndex - 6 + indexPoint * indexLeap,
        yearIndex + 10 + indexPoint * indexLeap
      );
      rightArrow = false;
    }
  } else if (maxIndex - yearIndex - indexPoint * indexLeap < 11) {
    SplittedYears = allYears.slice(
      yearIndex - 6 + indexPoint * indexLeap,
      maxIndex + indexPoint * indexLeap
    );
    rightArrow = true;
  } else if (yearIndex - minIndex + indexLeap * indexPoint < 11) {
    SplittedYears = allYears.slice(0, 18);
    leftArrow = true;
  } else {
    SplittedYears = allYears.slice(
      yearIndex - 6 + indexPoint * indexLeap,
      yearIndex + 12 + indexPoint * indexLeap
    );
  }
  return {leftArrow, rightArrow, SplittedYears};
};
