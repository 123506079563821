import React, {Component} from "react";
import {MenuItem, Tooltip} from "@material-ui/core";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Grid,
} from "@syncfusion/ej2-react-grids";
import Pagination from "components/common/pagination";
import Avatar from "react-avatar";
import RouteEnum from "constants/routeEnum";
import {Date_Format_Reverse} from "constants/dateFormat";
import {getUploadPath} from "constants/userDetails";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Modal from "components/modal";
import MenuItemComponent from "components/common/MenuItem";

export class DisciplinaryTable extends Component {
  constructor(props) {
    super(props);
    this.grid = Grid || null;
    this.widths = {
      image: 60,
      name: 220,
      idCard: 70,
      jobRolePosition: 240,
      date: 150,
      actionStatement: 250,
      stage: 200,
    };
    this.state = {
      drop: false,
      dropMoreOption: false,
      selectedId: null,
      modalState: {show: false, type: "normal"},
    };
    this.dropdown = "";
    this.dropdownMoreOption = "";
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleConfirmDelRecord = this.handleConfirmDelRecord.bind(this);
    this.caseTitleTemplate = this.caseTitleTemplate.bind(this);

    this.gridTemplate = this.gridTemplate;
    this.row = [];
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.dropMoreOption && event.target.className != "drop-list__item") {
      this.setState({dropMoreOption: false}, () => {
        // this.grid.refresh();
      });
    }
  }

  onDrop = () => {
    this.setState({drop: !this.state.drop});
  };

  onDropMoreOption = (props) => {
    console.log("dropmoreoption");
    this.setState(
      {
        dropMoreOption: props?.DisciplinaryCaseId || null,
      }
      // () => this.grid.refresh()
    );
  };

  dropdownTemplate = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <td className="e-custom-row" style={{overflow: "visible"}} role="gridcell">
        <div
          className={`more drop ${
            this.state.dropMoreOption == props.DisciplinaryCaseId ? "active" : ""
          }`}
        >
          <MenuItemComponent
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            open={open}
          >
            <MenuItem
              onClick={() => {
                this.props.handleEdit(props.DisciplinaryCaseId);
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.handleConfirmDelRecord(props.DisciplinaryCaseId);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </MenuItemComponent>
        </div>
      </td>
    );
  };

  caseTitleTemplate = (props) => {
    return (
      <Tooltip title={props?.CaseTitle} placement="top-start">
        <div style={{overflowX: "hidden"}}>{props?.CaseTitle}</div>
      </Tooltip>
    );
  };

  levelPosition = (props) => {
    return (
      <div className="flex-column">
        <span>{props.Level}</span>
        <span className="color-light">{props.Position}</span>
      </div>
    );
  };
  ReportedDate = (props) => {
    return (
      <div className="">
        <span>{props.ReportedDate && Date_Format_Reverse(props.ReportedDate)}</span>
      </div>
    );
  };

  avatarPhoto = (props) => {
    return (
      <Avatar
        name={props.Name}
        size={45}
        round={true}
        src={props.UrlPhoto && getUploadPath(props.UrlPhoto)}
      />
    );
  };

  clickableName = (props) => {
    return (
      <span
        className="clickable"
        onClick={() =>
          this.props.history.push(
            RouteEnum.ViewDisciplinaryActionPath + props.DisciplinaryCaseId
          )
        }
      >
        {props.Name}
      </span>
    );
  };

  closeModal = () => {
    this.props.handleModalShow({show: false, type: "normal"});
  };

  handleConfirmDelRecord = (id) => {
    this.setState({
      selectedId: id,
    });
    this.handleModalShow(true, "confirm");
  };

  handleDelRecord = () => {
    this.props.handleDelete(this.state.selectedId);
  };

  handleModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };

  render() {
    const {modalState} = this.state;
    const {data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage, stageId} =
      this.props;
    return (
      <div className="table">
        <div className="arrear-table">
          <div className="pending-settlement-grid">
            <GridComponent
              dataSource={data}
              className="custom-grid"
              toolbar={this.toolbar}
              // filterSettings={{type: "Menu"}}
              // allowFiltering={true}
              id="grid"
              allowExcelExport={true}
              ref={(g) => (this.grid = g)}
              allowSorting={true}
            >
              <ColumnsDirective>
                <ColumnDirective width={this.widths.image} template={this.avatarPhoto} />
                <ColumnDirective
                  headerText="Name"
                  width={this.widths.name}
                  template={this.clickableName}
                />
                <ColumnDirective
                  headerText="Job Role and Position"
                  width={this.widths.jobRolePosition}
                  template={this.levelPosition}
                />
                <ColumnDirective
                  headerText="Date"
                  width={this.widths.date}
                  template={this.ReportedDate}
                />
                <ColumnDirective
                  field=""
                  headerText="Case Title"
                  template={this.caseTitleTemplate}
                  width={this.widths.actionStatement}
                />
                <ColumnDirective
                  field="Stage"
                  headerText="Stage"
                  width={this.widths.stage}
                />
                {(this.props.label === "New Cases" ||
                  this.props.label === "Open Cases") && (
                  <ColumnDirective width={240} template={this.dropdownTemplate} />
                )}
              </ColumnsDirective>
            </GridComponent>

            {data && data.length > 0 && (
              <Pagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRows}
              />
            )}
            <Modal
              title={"Employee"}
              open={modalState.show}
              onModalClose={() => this.handleModalShow(false, "normal")}
              type={modalState.type}
              func={() => this.handleDelRecord()}
              className="modal-travel-category"
              content={<div></div>}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(DisciplinaryTable));
