import React, { useRef, useState } from "react";
import FormGroup from "components/form";
import {
  getDateISOMDY,
  getDateISO,
} from "components/calendar/helpers/engCalendar";

import useOutsideClick from "helpers/customHooks/outsideClickHook";
import { toastMessage } from "actions/validateAction";
import { useDispatch } from "react-redux";
import isEmpty from "helpers/isEmpty";

const DateSection = (props) => {
  const { data, onInputChange, onEnter, onDateChange, isValidUser, board } =
    props;
  const [edited, setEdited] = useState(false);
  const convertDateISO = (date) => {
    return date ? getDateISO(new Date(date)) : "";
  };
  const getFomattedDateISO = (date) => {
    return date
      ? getDateISO(new Date(date)).replace(new RegExp("/", "g"), "-")
      : "";
  };
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  useOutsideClick(ref, () => {
    edited &&
      onEnter &&
      onEnter(
        { key: "Enter" },
        "EstimatedHour",
        data.TmTaskDetailsDto.EstimatedHour
      );

    edited && setEdited(false);
  });

  const handleDateChange = (name, value) => {
    let startDate =
      data.TmTaskDetailsDto.StartDate &&
      new Date(data.TmTaskDetailsDto.StartDate);
    let dueDate =
      data.TmTaskDetailsDto.DueDate && new Date(data.TmTaskDetailsDto.DueDate);

    let boardStartDate = board.StartDate && new Date(board.StartDate);
    let boardDueDate = board.DueDate && new Date(board.DueDate);

    let error = {};
    if (name === "DueDate") {
      dueDate = new Date(value);

      if (startDate && dueDate < startDate) {
        error = {
          data: {
            MessageType: "Danger",
            Message: "Due date must be greater than start date",
          },
        };
      }

      if (boardDueDate && boardDueDate < dueDate) {
        error = {
          data: {
            MessageType: "Danger",
            Message: `This project is scheduled to complete on ${getFomattedDateISO(
              board.DueDate
            )}.Please set the task due date within the project completion date.`,
          },
        };
      }
    } else if (name === "StartDate") {
      startDate = new Date(value);

      if (boardStartDate && startDate < boardStartDate) {
        error = {
          data: {
            MessageType: "Danger",
            Message: `This project starts from ${getFomattedDateISO(board.StartDate)}. 
                      Please set the task start date between the project start and end date`,
          },
        };
      }
    }
    if (!isEmpty(error)) {
      toastMessage(dispatch, error);
      return;
    }
    onDateChange(name, value);
  };
  return (
    <div className="taskmodal__date">
      <FormGroup
        disabled={!isValidUser()}
        formName="customdateinput"
        name="StartDate"
        placeholder="YYYY-MM-DD"
        // onChange={(name, value) => onDateChange(name, value)}
        onChange={handleDateChange}
        value={convertDateISO(data.TmTaskDetailsDto.StartDate)}
        label="Start date"
        enableOkButton
        hideAction
      />

      <FormGroup
        disabled={!isValidUser()}
        formName="customdateinput"
        placeholder="YYYY-MM-DD"
        name="DueDate"
        onChange={handleDateChange}
        value={convertDateISO(data.TmTaskDetailsDto.DueDate)}
        label="Due date"
        enableOkButton
        hideAction
      />
      <div ref={ref}>
        <FormGroup
          disabled={!isValidUser()}
          name="EstimatedHour"
          width="120px"
          label="Estimated (hour)"
          onChange={(name, value) => {
            setEdited(true);
            onInputChange(name, value);
          }}
          value={data.TmTaskDetailsDto.EstimatedHour || ""}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setEdited(false);
              onEnter(e, "EstimatedHour", data.TmTaskDetailsDto.EstimatedHour);
            }
          }}
        />
      </div>
    </div>
  );
};
export default DateSection;
