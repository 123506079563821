import React, { useState, useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

const TaskLoader = ({ taskLoading }) => {
    const [showCheck, setCheck] = useState(false)
    const [enableAutoShow, setEnableAutoShow] = useState(false)

    useEffect(() => {
        if (taskLoading) {
            setEnableAutoShow(true);
            setCheck(false)
        }

        if (!taskLoading && enableAutoShow) {
            setEnableAutoShow(false);
            setCheck(true)
            setTimeout(() => setCheck(false), 1500);
        }

    }, [taskLoading])

    let el = taskLoading || setCheck ?

        <div className="task-status">
            {taskLoading && <div className="task-loading">
                <div className="loader loader-1"></div>
                <div className="loader loader-2"></div>
                <div className="loader loader-3"></div>
                <div className="loader loader-4"></div>

            </div>
            }
            {showCheck &&
                <div className="task-complete">

                    <IoIosCheckmarkCircle />
                </div>
            }
        </div>
        :
        null
    return el
};
export default TaskLoader;
