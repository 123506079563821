import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import Icons from "components/icons";
import {useSelector} from "react-redux";
import FormGroup from "components/form";
import {GetEmployeeList} from "actions/commonAction";

function AssessIDTable(props) {
  const {employeeListSelect} = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(GetEmployeeList());
    })();
  }, []);

  const {handleFieldChange, onClose, rowData, errors} = props;

  return (
    <div>
      <div className="flex flex-gap-6 mb-6 mt-6 genericForm-group__label">
        <span style={{width: "250px"}}>Item Name</span>
        <span style={{width: "250px"}}>Responsible person</span>
      </div>
      {rowData &&
        rowData.map((row) => {
          return (
            <div index={row.Id} className="flex mb-12 items-center">
              <FormGroup
                error={errors?.TypeName}
                onChange={(name, value) => handleFieldChange(name, value, row)}
                name="TypeName"
                value={row.TypeName}
                loadingType="skeleton"
                width="250px"
              />
              <FormGroup
                formName="reactselect"
                loadingType="skeleton"
                name="ResponsiblePerson"
                onChange={(name, value) => handleFieldChange(name, value, row)}
                options={employeeListSelect}
                value={row.ResponsiblePerson}
                width="250px"
              />
              <span className="new-close-icon" onClick={() => onClose(row)}>
                <Icons name="Close" color="#465060" />
              </span>
            </div>
          );
        })}
    </div>
  );
}

export default AssessIDTable;
