import React, {useEffect, useState} from "react";
import shortid from "shortid";
import Modal from "components/modal";
import Form from "components/form";
import FormItem from "components/formGroup/formItem";
import Button from "components/button";
import {
  BsFillQuestionSquareFill,
  BsQuestionCircleFill,
  BsTextareaT,
} from "react-icons/bs";
import {MdCheckBox, MdClose} from "react-icons/md";
import QuestionLabel from "../addCourse/contents/questionLabel";
import {FaFont} from "react-icons/fa";
import {IoMdRadioButtonOn} from "react-icons/io";
import RadioOption from "./radioOption";
import questionTypeOptions from "../helpers/questionTypeOptions";
import {AnswerType, LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";
import {getNewRadioOption} from "scenes/lms/helpers/constants";
import classNames from "classnames";
import isEmpty from "helpers/isEmpty";

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

const QuestionControl = (props) => {
  const {
    onChange,
    item,
    setItem,
    changeState,
    onDelete,
    onDuplicate,
    sectionIndex,
    questionIndex,
    methods,
  } = props;

  // const [data, setData] = useState(newQuestion);
  const [randomize, setRandomize] = useState(false);
  const [bulkAnswers, setBulkAnswers] = useState("");
  const [open, setOpen] = useState(false);
  const {
    questionType,
    question,
    id,
    answerOptions,
    isRandomised,
    answerLimitFrom,
    answerLimitTo,
  } = item;

  const onModalClose = () => setOpen(false);

  const handleChange = async (name, value) => {
    let nameArray = name.split("-");
    let controlName = name;
    if (nameArray.length > 0) controlName = nameArray[0];

    if (controlName === "questionType") {
      console.log(name, value);
    }

    if (!isEmpty(value)) {
      if (controlName === "answerLimitTo") {
        if (answerLimitFrom > value) return;
      }
      if (controlName === "answerLimitFrom") {
        if (answerLimitTo < value) return;
      }
    }
    onChange && onChange(controlName, value);
  };

  const addRadioButton = () => {
    const radioItem = getNewRadioOption();
    setItem((prev) => {
      let newData = {...prev};
      newData["answerOptions"] = item.answerOptions
        ? [...item.answerOptions, radioItem]
        : [radioItem];
      newData["answerType"] = AnswerType.normal;

      return newData;
    }, questionIndex);
  };
  const coupleQuestion = (type) => {
    const radioItems = [
      {...getNewRadioOption(), answerOption: type === AnswerType.yes ? "Yes" : "True"},
      {...getNewRadioOption(), answerOption: type === AnswerType.yes ? "No" : "False"},
    ];

    setItem((prev) => {
      let newData = {...prev};
      newData["answerOptions"] = radioItems;
      newData["answerType"] = type === "true" ? AnswerType.true : AnswerType.yes;
      return newData;
    }, questionIndex);
  };

  const onBulkAnswer = () => {
    if (bulkAnswers) {
      const answerSplit = bulkAnswers.split(/\r?\n/);
      if (answerSplit && Array.isArray(answerSplit)) {
        const allAnswers = answerSplit
          .map((item) => {
            return {
              ...getNewRadioOption(),
              answerOption: item,
            };
          })
          .filter((item) => item.answerOption !== "");
        setItem((prev) => {
          let newData = {...prev};
          newData["answerOptions"] = allAnswers;
          newData["answerType"] = AnswerType.normal;
          return newData;
        }, questionIndex);
      }
    }
    setBulkAnswers(null);
    setOpen(false);
  };
  const handleAddOption = (index, value) => {
    setItem((prev) => {
      let newData = {...prev};
      newData["answerOptions"] = item.answerOptions
        ? insert([...item.answerOptions], index + 1, value)
        : [value];
      newData["questionType"] = {
        ...item.questionType,
        answerType: AnswerType.normal,
      };
      return newData;
    }, questionIndex);
  };
  const handleRemoveOption = (index) => {
    if (item.answerOptions) {
      item.answerOptions.splice(index, 1);
      setItem((prev) => {
        let newData = {...prev};
        newData["answerOptions"] = [...item.answerOptions];
        newData["answerType"] = AnswerType.normal;
        return newData;
      }, questionIndex);
      // onChange && onChange("answerOptions", [...item.answerOptions]);
    }
  };
  const handleChangeOption = (index, name, value) => {
    if (item.answerOptions) {
      let newOptions = JSON.parse(JSON.stringify(item.answerOptions));
      newOptions[index][name] = value;
      onChange && onChange("answerOptions", newOptions);
    }
  };
  const getName = (name) => {
    // return name;
    return `${name}-s${sectionIndex}q${questionIndex}`;
  };

  return (
    <div className="courseForm-question">
      <Modal
        onModalClose={onModalClose}
        open={open}
        title="Add Bulk Answers"
        className="courseForm-bulk"
        width="600px"
      >
        <div className="courseForm-bulk__modal">
          <div className="courseForm-bulk__form">
            <Form
              width="100%"
              value={bulkAnswers}
              name="bulkAnswers"
              formName="textarea"
              rows={15}
              onChange={(name, value) => setBulkAnswers(value)}
            />
          </div>
          <div className="courseForm-bulk__footer">
            <Button bg="secondary" title="Cancel" onClick={() => onModalClose()} />
            <Button bg="primary" title="Add" onClick={() => onBulkAnswer()} />
          </div>
        </div>
      </Modal>
      <div className="courseForm-question__header">
        <div className="courseForm-question__header-action question-header-row">
          <BsQuestionCircleFill />
        </div>
        <div className="questionheader-action">
          {onDuplicate && (
            <div
              onClick={() => onDuplicate(questionIndex)}
              className="questionheader-info"
            >
              {/* <IoCopyOutline /> */}
              <BsFillQuestionSquareFill />
              <span>Duplicate</span>
            </div>
          )}
          {onDelete && (
            <div
              onClick={() => onDelete && onDelete(questionIndex)}
              className="questionheader-secondary"
            >
              <MdClose />
              <span>Delete</span>
            </div>
          )}
        </div>
      </div>

      <div className="courseForm-question__body">
        <div
          className={classNames("courseForm-question__body-form courseform-align", {
            nolabels: isEmpty(questionType?.value),
          })}
        >
          <div className="type-label">
            <label>
              {questionType?.value === LMSQuestionTypeEnum.ShortText
                ? "Short Text"
                : questionType?.value === LMSQuestionTypeEnum.LongText
                ? "Long Text"
                : questionType?.value === LMSQuestionTypeEnum.RadioButton
                ? "Radio buttons"
                : questionType?.value === LMSQuestionTypeEnum.CheckBox
                ? "Check boxes"
                : null}
            </label>
          </div>
          <FormItem
            className="type-inputs"
            control={methods.control}
            setValue={methods.setValue}
            label=""
            value={question}
            width="auto"
            name={getName("question")}
            error={methods?.formState?.errors[getName("question")]?.message}
            rules={{
              required: "Question is required.",
            }}
            onChange={handleChange}
          />
          <FormItem
            className="field-type type-inputs"
            control={methods.control}
            setValue={methods.setValue}
            width="auto"
            value={questionType}
            options={[
              {
                label: (
                  <QuestionLabel
                    label="Radio Buttons"
                    icon={<IoMdRadioButtonOn />}
                    selectedValue={questionType}
                    value={3}
                  />
                ),
                value: 3,
              },
              {
                label: (
                  <QuestionLabel
                    label="Short Text"
                    icon={<FaFont />}
                    selectedValue={questionType}
                    value={1}
                  />
                ),
                value: 1,
              },
              {
                label: (
                  <QuestionLabel
                    label="Check Boxes"
                    icon={<MdCheckBox />}
                    selectedValue={questionType}
                    value={4}
                  />
                ),
                value: 4,
              },
              {
                label: (
                  <QuestionLabel
                    label="Long Text"
                    icon={<BsTextareaT />}
                    selectedValue={questionType}
                    value={2}
                  />
                ),
                value: 2,
              },
            ]}
            name={getName("questionType")}
            error={methods?.formState?.errors[getName("questionType")]?.message}
            rules={{
              required: "Question type is required.",
            }}
            formName="reactselect"
            onChange={handleChange}
          />
        </div>

        {(questionType?.value === LMSQuestionTypeEnum.RadioButton ||
          questionType?.value === LMSQuestionTypeEnum.CheckBox) && (
          <div className="courseForm-question__body-radio">
            <div className="courseForm-question__body-options">
              <div className="courseForm-question__body-col">
                <span>Options</span>
                <a onClick={() => setOpen(true)}>
                  <span>+ Bulk answers</span>
                </a>
              </div>

              {questionType?.value === LMSQuestionTypeEnum.RadioButton && (
                <div className="courseForm-question__body-col">
                  <a onClick={() => coupleQuestion(AnswerType.yes)}>
                    <span>Yes | No</span>
                  </a>
                  <a onClick={() => coupleQuestion(AnswerType.true)}>
                    <span>True | False</span>
                  </a>
                </div>
              )}
            </div>
            <div className="courseForm-question__body-formgroup">
              <label htmlFor="" className="courseForm-question__body-label">
                Select the correct answer below answerOption is
              </label>
              <div className="courseForm-question__body-radiolist">
                {answerOptions &&
                  answerOptions.map((option, key) => (
                    <RadioOption
                      questionType={questionType?.value}
                      methods={methods}
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                      index={key}
                      key={key}
                      onAddOption={handleAddOption}
                      onRemoveOption={handleRemoveOption}
                      onChange={handleChangeOption}
                      {...option}
                      answerType={item.answerType}
                    />
                  ))}

                <div>
                  <input
                    type="hidden"
                    name={getName("correctAnswers")}
                    ref={methods?.register?.({
                      validate: (value) =>
                        answerOptions.findIndex((x) => x.isChecked) > -1,
                    })}
                  ></input>
                  {methods?.formState?.errors[getName("correctAnswers")] && (
                    <span className="courseForm-question__body-error">
                      Please select at least one correct answer.
                    </span>
                  )}
                </div>

                {/* <RadioOption /> */}
              </div>
            </div>
            {questionType?.value === LMSQuestionTypeEnum.CheckBox && (
              <div className="courseForm-question__body-options-alt">
                <div className="courseForm-question__body-col">
                  <div className="courseForm-question__body-label flex">
                    <span>Selection limits: From</span>
                    <FormItem
                      control={methods.control}
                      setValue={methods.setValue}
                      name={getName("answerLimitFrom")}
                      formName="stepper"
                      stepperType="alternative"
                      value={answerLimitFrom}
                      minValue={"1"}
                      maxValue={answerOptions?.length}
                      onChange={handleChange}
                      rules={{
                        required: "This field is required.",
                      }}
                      error={
                        methods?.formState?.errors[getName("answerLimitFrom")]?.message
                      }
                    />
                    <span>to</span>
                    <FormItem
                      control={methods.control}
                      setValue={methods.setValue}
                      name={getName("answerLimitTo")}
                      formName="stepper"
                      stepperType="alternative"
                      value={answerLimitTo}
                      minValue={"1"}
                      maxValue={answerOptions?.length}
                      onChange={handleChange}
                      rules={{
                        required: "This field is required.",
                      }}
                      error={
                        methods?.formState?.errors[getName("answerLimitTo")]?.message
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{padding: 0, marginTop: 24}}
              className="courseForm-question__body-options"
            >
              <div className="courseForm-question__body-col">
                <a onClick={() => addRadioButton()}>
                  <span>Add another choice</span>
                </a>
              </div>
              <div className="courseForm-question__body-col">
                <FormItem
                  control={methods.control}
                  width="230px"
                  value={isRandomised}
                  checked={isRandomised}
                  labelPosition="right"
                  label="Randomize order of choices"
                  name="isRandomised"
                  formName="checkgroup"
                  onChange={(e) => handleChange("isRandomised", e.target.checked)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionControl;
