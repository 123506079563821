import React, {Component} from "react";
import "./hr.styles.scss";
import {
  getHrDashboard,
  getDashboardMenus,
  removeDashboardMenu,
  addDashboardBlocks,
  addDashboardMenu,
  getDashboardBlocks,
} from "./helpers/action";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import DragBlock from "./component/dragBlock";
import Button from "components/button";
import RouteEnum from "constants/routeEnum";
import {Link} from "react-router-dom";
import SvgIcons from "components/icons/svgIcons";
import FavIconModal from "components/common/favIconModal";
import {modules as common} from "./commonTasks/index";
import {modules as reports} from "./reports/index";
import {Features} from "helpers/getPermissions";

export class HRDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this._renderCount = this._renderCount.bind(this);
    this._renderFavButton = this._renderFavButton.bind(this);
  }
  async componentDidMount() {
    setTimeout(async () => {
      const {isAuthenticated} = this.props;
      const userDetails = localStorage.getItem("UserDetail");
      if (isAuthenticated && userDetails) await this.props.getHrDashboard();
    }, 200);
  }
  async componentDidUpdate(prevProps, prevState) {
    const {hrDashboard} = this.props;
    if (isEmpty(hrDashboard) && hrDashboard !== prevProps.hrDashboard) {
      console.log("hrDashboard", hrDashboard);
      await this.props.getHrDashboard();
    }
  }

  _renderCount() {
    const {baseUrl} = this.props;
    const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
    let list = [
      {
        name: "Employees",
        key: "TotalEmployees",
        isReact: true,
        link: RouteEnum.EmployeeListPath,
      },
      {
        name: "New Joins",
        key: "NewJoinEmployees",
        link: base + "Cp/Report/HRNewJoinList.aspx",
      },
      {name: "Retiring", key: "RetiringEmployees", link: base + "newhr/retirement.aspx"},
      {
        name: "Contract-Expiring",
        key: "ContracExpiring",
        link: base + "newhr/EmployeeContractExpiring.aspx",
      },
      {name: "Branches", key: "Branches", link: base + "CP/ManageBranch.aspx"},
      {name: "Departments", key: "Departments", link: base + "CP/ManageDep.aspx"},
    ];

    const {EmployeeDetailsCount} = this.props.hrDashboard;

    const checkNull = (item) => {
      if (item === 0 || isEmpty(item)) {
        return "-";
      }
      return item;
    };
    return (
      <div className="count-block">
        {isEmpty(EmployeeDetailsCount) ? (
          <span className="no-found">No Counts Found</span>
        ) : (
          <ul className="count-block__list">
            {list.map((lis) => {
              return (
                <li className="count-block__item" key={lis.key}>
                  {lis.link ? (
                    lis.isReact ? (
                      <Link to={lis.link}>
                        <div className="count-number">
                          <span>{checkNull(EmployeeDetailsCount[lis.key])}</span>
                        </div>
                        <div className="count-name">
                          <span>{lis.name}</span>
                        </div>
                        <div className="link-icon">
                          <span></span>
                        </div>
                      </Link>
                    ) : (
                      <a href={lis.link} target="_blank">
                        <div className="count-number">
                          <span>{checkNull(EmployeeDetailsCount[lis.key])}</span>
                        </div>
                        <div className="count-name">
                          <span>{lis.name}</span>
                        </div>
                        <div className="link-icon">
                          <span></span>
                        </div>
                      </a>
                    )
                  ) : (
                    <a>
                      <div className="count-number">
                        <span>{checkNull(EmployeeDetailsCount[lis.key])}</span>
                      </div>
                      <div className="count-name">
                        <span>{lis.name}</span>
                      </div>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
  _renderFavButton() {
    // const history = useHistory();
    const {removeDashboardMenu} = this.props;
    const features = Features();
    return (
      <div className="fav-button-block">
        <div className="fav-button-group">
          <Button
            buttonType="icon-button"
            icon={<SvgIcons name="tasks" color="#3a3d43" size="17px" />}
            title="Activities"
            bg="white"
            onClick={() => this.props.history.push(`${RouteEnum.Links}/CoreHR/2`)}
          />
          <Button
            buttonType="icon-button"
            icon={<SvgIcons name="fileInvoice" color="#3a3d43" size="14px" />}
            title="Reports"
            bg="white"
            onClick={() => this.props.history.push(`${RouteEnum.Links}/CoreHR/3`)}
          />
          <FavIconModal
            dashboardName="HR"
            removeMenu={removeDashboardMenu}
            common={common(features)}
            reports={reports(features)}
          />
        </div>
      </div>
    );
  }
  render() {
    const {hrDashboard} = this.props;
    const {baseUrl} = this.props;
    const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
    //
    return (
      <div className="admin">
        <div className="admin-container">
          <div className="admin-title">
            <h1>Human Resources Dashboard</h1>
          </div>
          {isEmpty(hrDashboard) ? (
            <span className="no-found">No Data Loaded</span>
          ) : (
            <div className="admin-body">
              {this._renderCount()}
              {this._renderFavButton()}
              <DragBlock
                base={base}
                addDashboardBlocks={this.props.addDashboardBlocks}
                blocks={this.props.dashboardBlock}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getHrDashboard,
  getDashboardMenus,
  removeDashboardMenu,
  getDashboardBlocks,
  addDashboardBlocks,
  addDashboardMenu,
};

const mapStateToProps = (state) => ({
  hrDashboard: state.hrReducer.hrDashboard,
  dashboardMenu: state.hrReducer.dashboardMenu,
  baseUrl: state.commonReducer.baseUrl,
  dashboardBlock: state.hrReducer.dashboardBlock,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(HRDashboard);
