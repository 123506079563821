import React, {Component} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import isEmpty from "isEmpty";
import {validUploadDocExtension} from "constants/validExtensions";
import {checkIsFileImageType} from "helpers/checkIsFileImageType";
import {getUploadPath} from "constants/userDetails";
import Icons from "components/icons";
import {getSelectedUploadFile} from "helpers/getSelectedUploadFile";
import GetFileTypeIcon from "components/icons/getFileTypeIcon";
import {FiPlus} from "react-icons/fi";
export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      notValidFile: false,
      fileName: "",
    };
    this.removeFile = this.removeFile.bind(this);
    this.node = "";
  }

  fileSelectedHandler = (event) => {
    if (this.validateImage(event.target.files)) {
      this.props.DisplayClientSideMessage(
        `Max valid file size is ${this.props.maxSize / 1000000} MB`
      );
      return;
    }
    const fileList = event?.dataTransfer
      ? event?.dataTransfer?.files
      : event?.target?.files;
    let {inValidFileExists, mergedFiles} = getSelectedUploadFile(
      fileList,
      this.state.fileList,
      this.props.allowedFileTypes
    );
    this.setState({
      fileList: mergedFiles,
      notValidFile: inValidFileExists,
      fileName: "abc",
    });
    this.props.setSelectedFiles(mergedFiles);
  };

  validateImage = (elem) => {
    const {maxSize} = this.props;
    // debugger;
    if (isEmpty(elem)) return true;
    const file = elem[0];
    console.log("file.size > maxSize", file.size > maxSize);
    if (file.size > maxSize) return true;
    return false;
  };

  removeFile(name) {
    const {fileList} = this.state;
    let newFiles = fileList.filter((item) => item.name !== name);
    this.setState({fileList: newFiles, fileName: null});
    this.props.setSelectedFiles(newFiles);
  }

  componentDidMount() {
    const {fileList} = this.props;
    this.setState({fileList});
  }
  onInputClick = (event) => {
    event.target.value = "";
  };
  render() {
    let {fileList, notValidFile, fileName} = this.state;
    let {
      uploadBtnText,
      noSelectedText,
      isViewMode,
      showModal,
      showDocumentModal,
      allowedFileTypes,
      isDifferentSource,
    } = this.props;
    // console.log("fileList", fileList);
    return (
      <div className="Upload">
        {fileList && fileList.length > 0
          ? fileList.map((file, index) => {
              if (!isEmpty(file?.Id)) {
                file = {...file};
                // file.name = file.OriginalName;
                file.name = file.UserFileName;
                file.path = file.DocumentPath + file.DocumentName;
                file.docName = file.DocumentName;
                file.size = file.Size;
              }
              console.log({file});
              let isImageFile = file?.name && checkIsFileImageType(file?.name);

              return (
                <div className="flex  mb-xsm">
                  {showModal ? (
                    <a
                      onClick={() => showDocumentModal(file, isEmpty(file?.path))}
                      className="flex cursor-pointer link-color"
                      key={index}
                    >
                      <span className="document-icon link-color">
                        <GetFileTypeIcon fileName={file.name} />
                      </span>
                      <label className="document-name link-color white-space-nowrap">
                        {file.name.split(".")[0] + "." + file.name.split(".")[1]}
                      </label>
                    </a>
                  ) : (
                    <a
                      href={
                        isEmpty(file?.path)
                          ? window.URL.createObjectURL(file)
                          : isDifferentSource
                          ? getUploadPath(file?.path)
                          : file?.path
                      }
                      target={isImageFile ? "_blank" : ""}
                      download={isImageFile ? false : true}
                      className="flex cursor-pointer link-color"
                      key={index}
                    >
                      <span className="document-icon link-color">
                        <GetFileTypeIcon fileName={file.name} />
                      </span>
                      <label className="document-name link-color white-space-nowrap">
                        {file &&
                          file.name &&
                          file.name.split(".")[0] + "." + file.name.split(".")[1]}
                      </label>
                    </a>
                  )}
                  {!isViewMode && (
                    <span
                      className="document-icon cancel-icon flex"
                      onClick={() =>
                        this.removeFile(!isEmpty(file.name) ? file.name : "")
                      }
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </div>
              );
            })
          : fileList?.length === 0 && (
              <span className="document-no mb-xsm display-block">{noSelectedText} </span>
            )}
        {notValidFile && (
          <div className="flex error-color text-md items-start mb-xsm">
            <Icons name="Info" />
            <span className="error-message">
              Invalid file type(valid file types are{" "}
              {!isEmpty(allowedFileTypes)
                ? allowedFileTypes.join(", ")
                : validUploadDocExtension.join(", ")}
              )!
            </span>
          </div>
        )}
        {isViewMode ? null : (
          <div className="badges-group">
            <div className="upload-btn-wrapper">
              <button className="upload-btn flex">
                <FiPlus /> {uploadBtnText}
              </button>
              <input
                type="file"
                name="file"
                fileName={fileName}
                onClick={this.onInputClick}
                onChange={this.fileSelectedHandler}
                multiple
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Upload;
