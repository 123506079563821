import axios from "helpers/axiosinstance";
import reject from "store/reducers/recruitment/reject";
import {baseURL, baseURLV1} from "../constants/urls";
import {dispatchMessage} from "../helpers/dispatchMessage";

const setHeaders = (isMultipart = false, isFileResponse = false) => {
  const header = {
    ["content-type"]: isMultipart
      ? "multipart/form-data"
      : "application/json; charset=utf-8",
  };
  if (isFileResponse) header["responseType"] = "blob";

  return {
    headers: header,
  };
};
// const setHeaders = () => {
//   // Token
//   const token = localStorage.getItem("AuthToken");
//   let headers = {
//     headers: {
//       Authorization: token,
//     },
//   };
//   return headers;
// };

// actionBase.defaults.baseURL = baseURL;
// const actionBase = axios.create({ baseURL: baseURL });

export const get = (url, dispatch, param = null, showResponseMessage = false) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .get(fullUrl, setHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};
export const exportFile_get = (url, dispatch, param = null) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .get(fullUrl, setHeaders(false, true))
      .then((res) => downloadFile(res, resolve, dispatch))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const get_by_id = (url, dispatch, id, showResponseMessage = false) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    // actionBase.defaults.baseURL=baseURLV1
    const fullUrl = `${url}/${id}`;
    instance
      .get(fullUrl, setHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const post = (
  url,
  dispatch,
  param,
  showResponseMessage = true,
  isMultipart = false
) => {
  let headers = setHeaders(isMultipart);
  // if (isMultipart) {
  //   headers["content-type"] = "multipart/form-data";
  // }
  const instance = axios;
  return new Promise((resolve, reject) => {
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .post(url, param, headers)
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const exportFile_post = (url, dispatch, param) => {
  const instance = axios;
  let headers = setHeaders(false, true);
  return new Promise((resolve, reject) => {
    instance
      .post(url, param, headers)
      .then((res) => downloadFile(res, resolve, dispatch))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put = (
  url,
  dispatch,
  param,
  showResponseMessage = true,
  isMultipart = false
) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    let headers = setHeaders(isMultipart);
    // if (isMultipart) {
    //   headers["content-type"] = "multipart/form-data";
    // } else {
    //   headers["content-type"] = "application/json";
    // }
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .put(url, param, headers)
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const exportFile_put = (url, dispatch, param) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    let headers = setHeaders(false, true);

    instance
      .put(url, param, headers)
      .then((res) => downloadFile(res, resolve, dispatch))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

export const put_inline_param = (url, dispatch, param, showResponseMessage = true) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    const fullUrl = getFullStringUrl(url, param);
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .put(fullUrl, null, setHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, resolve));
  });
};

export const deletion = (url, dispatch, id, showResponseMessage = true) => {
  const instance = axios;
  return new Promise((resolve, reject) => {
    const fullUrl = `${url}/${id}`;
    // actionBase.defaults.baseURL=baseURLV1
    instance
      .delete(fullUrl, setHeaders())
      .then((res) => onSuccess(res, dispatch, resolve, reject, showResponseMessage))
      .catch((err) => onFailure(err, dispatch, reject));
  });
};

// export const deletion_with_param = (
//   url,
//   dispatch,
//   param,
//   showResponseMessage = true
// ) => {
//   return new Promise((resolve, reject) => {
//     actionBase
//       .delete(url, param)
//       .then((res) => onSuccess(res, dispatch, resolve, showResponseMessage))
//       .catch((err) => onFailure(err, dispatch, reject));
//   });
// };

// export const deletion_inline_param = (url, dispatch, param) => {
//   return new Promise((resolve, reject) => {
//     const fullUrl = getFullStringUrl(url, param);
//     actionBase
//       .delete()
//       .then((res) => onSuccess(res, dispatch, resolve))
//       .catch((err) => onFailure(err, dispatch, reject));
//   });
// };

const getFullStringUrl = (url, param) => {
  const entries = param ? Object.entries(param) : null;
  let fullUrl = url;
  entries &&
    entries.map((entry, ind) => {
      if (ind == 0) {
        fullUrl = `${fullUrl}?${`${entry[0]}=${entry[1]}`}`;
      } else {
        fullUrl = `${fullUrl}&${`${entry[0]}=${entry[1]}`}`;
      }
    });
  return fullUrl;
};

const onSuccess = (res, dispatch, resolve, reject, showResponseMessage) => {
  const response = res.data;
  if (response.Status == true) {
    if (showResponseMessage) {
      dispatchMessage(dispatch, response);
    }
    resolve(response);
  } else if (response.Status == undefined) {
    if (res.status == 200) {
      resolve(response);
    } else {
      dispatchMessage(dispatch, "Response status is not 200");
      reject(response);
    }
  } else {
    dispatchMessage(dispatch, response);
    reject(response);
  }
};

const onFailure = (err, dispatch, reject) => {
  // debugger;
  dispatchMessage(dispatch, err.message);
  reject(err);
};

const downloadFile = (res: any, resolve: any, dispatch: Function) => {
  var data = res.data;
  if (data && data["status"] === false) {
    const message = data["message"];
    if (message) dispatchMessage(dispatch, message);
    resolve(null);
    return;
  }

  const url = window.URL.createObjectURL(new Blob([data]));
  var contentDisposition = res.headers["content-disposition"];
  const regex = /filename=(?<filename>[^,;]+);/g;
  const match = regex.exec(contentDisposition);
  const filename = match.groups.filename;

  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  resolve(null);
};
