import React, {useEffect, useState} from "react";

import Button from "components/button";
import Modal from "components/modal";
import moment from "moment";
import "./style.scss";
import TimeRequest from "../timeRequest";
import classNames from "classnames";
import {GetEmployeeShiftAttendances, SaveEmployeeAttendance} from "../../helpers/action";
import {useDispatch} from "react-redux";
import isEmpty from "helpers/isEmpty";
import LoaderContainer from "components/loaderContainer";
function ReviewModel(props) {
  const {
    state,
    setState,
    handleModalClose,
    ScheduleDate,
    EmployeeId,
    EmployeeName,
    onSuccessSavingAttendance,
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const onTimeRequestClick = (item, shiftIndex, mode) => {
    console.clear();
    console.log("shifts", state.shifts);
    console.log(item);
    const {ScheduleDate, ShiftName, ShiftScheduleEmployeeId} = item;
    setState({
      ...state,
      showTimeRequest: true,
      timeRequestData: {
        TimeMode: mode,
        EmployeeName,
        EmployeeId,
        ScheduleDate,
        ShiftName,
        ShiftScheduleEmployeeId,
        shiftIndex,
      },
    });
  };
  const handleInTimeBlockClick = (shiftIndex, item) => {
    const newState = {...state, shifts: [...state.shifts]};
    newState.shifts[shiftIndex].CheckedIn = item.Time;
    newState.shifts[shiftIndex].InRequestId = item.RequestId;
    setState(newState);
  };
  const handleOutTimeBlockClick = (shiftIndex, item) => {
    const newState = {...state, shifts: [...state.shifts]};
    newState.shifts[shiftIndex].CheckOut = item.Time;
    newState.shifts[shiftIndex].OutRequestId = item.RequestId;
    setState(newState);
  };
  const formatDate = () => moment(ScheduleDate).format("MMMM DD, YYYY dddd");

  const onSaveAttendance = async () => {
    // if (!isDisabled()) {

    let requestParams = [];
    state.shifts.forEach((el) => {
      if (!isEmpty(el.CheckedIn) || !isEmpty(el.CheckOut))
        requestParams.push({
          ScheduleId: el.ScheduleId,
          ShiftScheduleEmployeeId: el.ShiftScheduleEmployeeId,
          EmployeeId: el.EmployeeId,
          ScheduleDate: el.ScheduleDate,
          InTime: el.CheckedIn,
          OutTime: el.CheckOut,
        });
    });

    setIsLoading(true);

    const res: any = await dispatch(SaveEmployeeAttendance(requestParams));
    setIsLoading(false);
    if (res) {
      setState({...state, open: false});
      onSuccessSavingAttendance?.();
    }

    // }
    //
  };
  const getDateDifference = (date, fromTime, toTime) => {
    var f = moment(date).format("MM/d/YYYY") + " " + fromTime;
    var t = moment(date).format("MM/d/YYYY") + " " + toTime;
    // var t = moment(date).add(1, "day").format("MM/d/YYYY") + " " + toTime;
    var ms = moment(t).diff(f);
    if (ms < 0) {
      ms = moment(f).diff(t);
    }
    var d: any = moment.duration(ms);
    var s = d.format("hh:mm");
    if (s === "00") s = "-";
    return s;
  };
  const getShortTime = (shift) => {
    var shiftFrom =
      moment(shift.ScheduleDate).format("MM/d/YYYY") + " " + shift.ShiftStart;
    var shiftTo = moment(shift.ScheduleDate).format("MM/d/YYYY") + " " + shift.ShiftEnd;
    var shiftHours = moment(shiftTo).diff(shiftFrom);

    var workFrom = moment(shift.ScheduleDate).format("MM/d/YYYY") + " " + shift.CheckedIn;
    var workTo = moment(shift.ScheduleDate).format("MM/d/YYYY") + " " + shift.CheckOut;
    var workHours = moment(workTo).diff(workFrom);

    const diff = workHours - shiftHours;
    let title = "Short time";
    let time = "-";

    if (diff < 0) {
      const shortTimeSpan: any = moment.duration(shiftHours - workHours);
      title = "Short time";
      time = shortTimeSpan ? shortTimeSpan.format("hh:mm") : "-";
    } else {
      const shortTimeSpan: any = moment.duration(workHours - shiftHours);
      title = "Extra time";
      time = shortTimeSpan ? shortTimeSpan.format("hh:mm") : "-";
    }
    return (
      <>
        <span className="time-info-title bolder">{title}</span>
        <span className="time-info-short bolder">{time != "00" ? time : "-"}</span>
      </>
    );
  };
  return (
    <Modal
      open={state.open}
      onModalClose={handleModalClose}
      title="Attendance Review"
      className="attreview-modal"
    >
      <LoaderContainer isLoading={isLoading}>
        <div className="attreview-modal">
          <div className="attreview-modal__body">
            <div className="attreview-modal__body-header">
              <span className="attreview-modal__emp">{EmployeeName}</span>
              <span className="attreview-modal__schedule-date">{formatDate()}</span>
            </div>

            <div className="attreview-modal__shift">
              {state?.shifts.map((shift, shiftIndex) => (
                <div className="attreview-modal__shift-body" key={shiftIndex}>
                  <div className="attreview-modal__shift-title">
                    <div>
                      {shift.ShiftName}({shift.ShiftShortName})
                    </div>
                    <div>
                      {shift.ShiftStart} - {shift.ShiftEnd}
                    </div>
                  </div>

                  <div className="attreview-modal__recorded-time">
                    <div className="time-title">Recorded times</div>
                    <div className="time-row">
                      <span className="time-row__label">In</span>

                      <div className="time-row__section">
                        {shift.InTimes.map((item, inIndex) => (
                          <span
                            key={inIndex}
                            onClick={() =>
                              !item.IsPending && handleInTimeBlockClick(shiftIndex, item)
                            }
                            className={classNames("time-block", {
                              "time-block-matched": item.Time === shift.CheckedIn,
                              "time-block-disable": item.IsPending,
                            })}
                          >
                            {item.Time}
                          </span>
                        ))}

                        <span
                          className="btn-request"
                          onClick={() => onTimeRequestClick(shift, shiftIndex, "checkin")}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="time-row">
                      <span className="time-row__label">Out</span>
                      <div className="time-row__section">
                        {shift.OutTimes.map((item, outIndex) => (
                          <span
                            key={outIndex}
                            onClick={() =>
                              !item.IsPending && handleOutTimeBlockClick(shiftIndex, item)
                            }
                            className={classNames("time-block", {
                              "time-block-matched": item.Time === shift.CheckOut,
                              "time-block-disable": item.IsPending,
                            })}
                          >
                            {item.Time}
                          </span>
                        ))}
                        <span
                          className="btn-request"
                          onClick={() =>
                            onTimeRequestClick(shift, shiftIndex, "checkout")
                          }
                        >
                          +
                        </span>
                      </div>
                    </div>

                    <div className="time-info">
                      <div className="time-info-flex">
                        <span className="time-info-title">Work Hours</span>
                        <span className="time-info-work bolder">
                          {getDateDifference(
                            ScheduleDate,
                            shift.CheckedIn,
                            shift.CheckOut
                          )}
                        </span>
                      </div>
                      <div className="time-info-flex">
                        <span className="time-info-title">Shift Hours</span>
                        <span className="time-info-shift bolder">
                          {getDateDifference(
                            ScheduleDate,
                            shift.ShiftStart,
                            shift.ShiftEnd
                          )}
                        </span>
                      </div>
                      <div className="time-info-flex">{getShortTime(shift)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="attreview-modal__footer">
            <Button title="Cancel" onClick={handleModalClose} />
            <Button
              title="Save"
              bg="primary"
              onClick={onSaveAttendance}
              // isDisabled={isDisabled()}
            />
          </div>
        </div>
      </LoaderContainer>
    </Modal>
  );
}

export default ReviewModel;
