import React from "react";
import Table from "components/customTable";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import Pagination from "components/common/pagination";
import {TaskBoards} from "common-links";
import {Link} from "react-router-dom";
import Icons from "components/icons";
import value from "environment";
import isEmpty from "helpers/isEmpty";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function TableView(props) {
  const {handleRowChange, handleIndexChange, pageIndex, pageSize, tableData} = props;

  const BoardViewCell = (props) => {
    const {value, cell} = props;
    const {original} = cell.row;
    const {BoardId} = original;
    return (
      <div>
        <Link
          to={{
            pathname: `${TaskBoards}board/${BoardId}`,
            search: ``,
          }}
        >
          {value}
        </Link>
      </div>
    );
  };

  let columns = [
    {
      Header: "Board Name",
      accessor: "BoardName",
      width: 150,
      Cell: BoardViewCell,
    },
    {
      Header: "Task",
      accessor: "TaskName",
      width: 150,
    },
    {
      Header: "Activity",
      accessor: "Text",
      width: 150,
    },
    {
      Header: "User",
      accessor: "CreatedBy",
      width: 100,
    },
    {
      Header: "Date",
      accessor: "CreatedDate",
      width: 60,
    },
    {
      Header: "Time",
      accessor: "Time",
      width: 50,
    },
  ];

  return (
    <div className="activityReport-container">
      <div className="activityReport-table">
        <Table
          isFixedWidth
          columns={columns} // Columns Settings
          data={tableData} // Data list
        />
      </div>
      <div className="">
        {tableData && tableData.length > 0 && (
          <Pagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={pageIndex}
            rowsPerPage={pageSize}
            total={tableData[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
}

export default TableView;
