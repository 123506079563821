import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import React, {useEffect, useRef, useState} from "react";
interface iprops {
  question: any;
  answer: any;
  onChange: Function;
  answerOptions: any;
  error?: any;
}

const CourseCheck = ({question, answer, onChange, answerOptions}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!isEmpty(answer?.errors)) {
      ref?.current?.scrollIntoView?.();
    }
  }, [answer?.errors]);

  const onClick = (id) => {
    let nextChose = [...(answer?.MultipleAnswerRefId || [])];
    if (nextChose.includes(id)) {
      nextChose = nextChose.filter((item) => item !== id);
    } else {
      nextChose = [...nextChose, id];
    }
    onChange(nextChose, true, true);
  };

  return (
    <>
      <ul className="course-page__quiz-list" ref={ref}>
        {answerOptions &&
          answerOptions.map((item) => {
            const isChecked = answer?.MultipleAnswerRefId?.includes(item.Id);

            const isQuestionLimit = false;
            const isDisabled = !isChecked && isQuestionLimit;
            return (
              <li
                key={item.Id}
                onClick={() => !isDisabled && onClick(item.Id)}
                className={classnames("course-page__quiz-item", {
                  "course-page__quiz-item-active": isChecked,
                  "course-page__quiz-item-disabled": isDisabled,
                })}
              >
                <div className="course-page__quiz-check"></div>
                <div className="course-page__quiz-name">{item.AnswerOption}</div>
              </li>
            );
          })}
      </ul>
      {answer && answer.errors && <span className="error__message">{answer.errors}</span>}
    </>
  );
};

export default CourseCheck;
