import {logoutUser} from 'actions/authAction'

const checkTokenExpirationMiddleware = store => next => action => {
  
    const exp = localStorage.getItem("Expires");
    let newexp;
      // console.log(store)
    if (exp && exp !== 'NaN') {
      newexp = JSON.parse(exp);
      if(newexp < Date.now() / 1000){
        // next(action);
        localStorage.clear();
        // store.dispatch(logoutUser(""));
      }
    }
    next(action);
  };


export default checkTokenExpirationMiddleware;