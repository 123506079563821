import React from "react";
import {Card} from "components/card";
import {Text} from "components/text";
import {
  CORRECT_ANSWER_BACKGROUND,
  WHITE,
  WRONG_ANSWER_BACKGROUND,
} from "constants/colors";
import {getTruncatedNumber} from "helpers/getFormattedNumber";

const AnswerListItem = ({questionOption, index}) => {
  return (
    <Card alignItems={"center"} gap={"0.5rem"} padding={"0.5rem 0 0 1.5rem"}>
      <Text title={`${index + 1}. ${questionOption.AnswerOption}`} />
      {!isNaN(questionOption.AnswerPercent) && (
        <Card
          alignItems={"center"}
          borderRadius={"3px"}
          background={
            questionOption.IsCorrectAnswer
              ? CORRECT_ANSWER_BACKGROUND
              : WRONG_ANSWER_BACKGROUND
          }
          justifyContent="center"
          padding="0.25rem 0.5rem"
        >
          <Text
            color={questionOption.IsCorrectAnswer && WHITE}
            title={`${getTruncatedNumber(questionOption.AnswerPercent)}%`}
          />
        </Card>
      )}
    </Card>
  );
};

AnswerListItem.defaultProps = {
  questionOption: {},
};

export default AnswerListItem;
