import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteCourseQuestion} from "../../../helpers/action";
import QuestionSection from "./questionSection";
import questionTypeOptions from "scenes/lms/helpers/questionTypeOptions";
import {AnswerType} from "../../../helpers/enums";
import {v4 as uuidv4} from "uuid";

function Question(props) {
  const {courseId, pageId, enableUpdateQuestion, setEnableUpdateQuestion} = props;
  const {questionDetails} = useSelector((state: any) => state.lmsReducer);
  const dispatch = useDispatch();
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (questionDetails) {
      let questions = [];
      let newSectionId = uuidv4();
      var sectionIds =
        questionDetails
          ?.map((x) => x.Section)
          .filter((val, index, self) => self.indexOf(val) === index) || [];

      for (var i = 0; i < sectionIds.length; i++) {
        let res = questionDetails
          .filter((x) => x.Section === sectionIds[i])
          .map((x) => ({
            answerLimitFrom: x.AnswerLimitFrom,
            answerLimitTo: x.AnswerLimitTo,
            answerOptions: x.AnswerOptions?.map((ans) => ({
              answerOption: ans.AnswerOption,
              id: ans.Id,
              isChecked: ans.IsCorrect,
              questionId: ans.QuestionId,
              point: ans.Point ?? 0,
            })),
            courseOutlineId: x.CourseOutlineId,
            id: x.Id,
            isOtherPresent: x.IsOtherPresent,
            isRandomised: x.IsRandomised,
            question: x.Question,
            questionOrder: x.QuestionOrder,
            questionType: questionTypeOptions.find((y) => y.value === x.QuestionType),
            answerType: x.AnswerType ?? AnswerType.normal,
            section: x.Section || newSectionId,
            sectionOrder: x.SectionOrder,
          }));
        questions.push(res);
      }

      setSections(questions);
    }
  }, [questionDetails]);

  const onQuestionDelete = (id) => {
    dispatch(deleteCourseQuestion(id));
  };
  const onChange = (name, value, sectionIndex, questionIndex) => {
    setSections((prev) => {
      let newSections = [...prev];
      newSections[sectionIndex][questionIndex][name] = value;
      return newSections;
    });
  };
  return (
    <div className="lms-questions course-fields">
      {sections &&
        sections.map((section, ind) => (
          <QuestionSection
            enableUpdateQuestion={enableUpdateQuestion}
            setEnableUpdateQuestion={setEnableUpdateQuestion}
            courseId={courseId}
            pageId={pageId}
            section={section}
            key={ind}
            sectionIndex={ind}
            onChange={onChange}
            setSections={setSections}
          />
        ))}
    </div>
  );
}

export default Question;
