import {common} from "./common";
import {recruitment} from "./recruitment";
import {taskManagement} from "./taskManagement";
import {favLink} from "./favLink";
import {dashboard} from "./dashboard";
import {employees} from "./employee";
export const api = {
  common,
  recruitment,
  taskManagement,
  favLink,
  dashboard,
  employees,
};
