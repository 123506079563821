import React, { Component } from "react";
import { Tabs, Tab } from "components/tabs/index.d";
import FormGroup from "components/form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUnitList } from "./action";
export class ListingTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, label: "Common Parameters" },
        { id: 2, label: "Advanced Parameters" },
      ],
      activeTabId: 1,
    };
    this.filters = "";
  }


  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  render() {
    const { tabs, activeTabId } = this.state;
    return (
      <div className="employee-tabs">
        <Tabs
          activeTab={activeTabId}
          type="block"
          isCollapse={true}
          collapseText="FILTRATION"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                label={tab.label}
                name={tab.id}
                id={tab.id}
                key={index}
                onTabClick={() => this.handleTabChange(tab.id)}
              >
                {tab.id === 1 ? (
                  <CommonFilter
                    activeTabId={activeTabId}
                    {...this.props}
                  />
                ) : (
                    <AdvanceFilter
                      activeTabId={activeTabId}
                      {...this.props}
                    />
                  )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

const AdvanceFilter = (props) => {
  const { region, approvalState, leaveType } = props.parentState;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item">
        <FormGroup
          // formName="reactselect"
          onChange={props.handleInput}
          name="region"
          label={"Region"}
          value={region}
          // options={genders}
          placeholder="Select Region"
          width="200px"
        />
      </div>
      {/* <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="approvalState"
          label={"Approval State"}
          value={approvalState}
          options={approval_state}
          placeholder="Select Approval State"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="leaveType"
          label={"Leave type"}
          value={leaveType}
          options={new_joins_opt}
          placeholder="Select Leave type"
          width="200px"
        />
      </div> */}
      <div className="filters-input__item">
        <button
          onClick={() => props.onFilter()}
          className="btn btn-primary"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

const CommonFilter = (props) => {
  const {
    employee,
    activeBranch,
    activeDepartment,
    activePosition,
    jobStatus,
  } = props.parentState;
  const { branches, departments, designations, statusList } = props;
  return (
    <div className="filters-input filters">
      <div className="filters-input__item search">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="employee"
          label={"Employee"}
          value={employee}
          options={props.employeeListSelect}
          loadingType="skeleton"
          placeholder="Select Employee"
          // validators={["required"]}
          width="300px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeBranch"
          label={"Branch"}
          value={activeBranch}
          options={branches}
          placeholder="Select Branch"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activeDepartment"
          label="Department"
          value={activeDepartment}
          options={departments}
          placeholder="Select Department"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="activePosition"
          label="Position"
          value={activePosition}
          options={designations}
          placeholder="Select Position"
          width="200px"
        />
      </div>

      <div className="filters-input__item">
        <FormGroup
          formName="reactselect"
          onChange={props.handleAutoSelect}
          name="jobStatus"
          label="Status"
          value={jobStatus}
          options={statusList}
          placeholder="Select Status"
          width="200px"
        />
      </div>
      <div className="filters-input__item">
        <button
          onClick={() => props.onFilter()}
          className="btn btn-primary"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

ListingTabs.propTypes = {
  searchLeave: PropTypes.func.isRequired,
  leaveReducer: PropTypes.object.isRequired,
  selectReducer: PropTypes.object.isRequired,
  searchAttendance: PropTypes.object.isRequired,
  generateAttendance: PropTypes.func.isRequired,
  payrollAttendanceReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveReducer: state.leaveReducer,
  selectReducer: state.selectReducer,
  payrollAttendanceReducer: state.payrollAttendanceReducer,
  employeeListSelect: state.commonReducer.employeeListSelect,
  branches: state.commonReducer.branchList,
  departments: state.commonReducer.departmentList,
  designations: state.commonReducer.designationList,
  unitlist: state.payrollAttendanceReducer.unitlist,
  statusList: state.commonReducer.jobStatusList
});

export default connect(mapStateToProps, {
  getUnitList
})(ListingTabs);
