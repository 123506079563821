import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import FormGroup from "components/form";
import Modal from "components/modal";
import isEmpty from "isEmpty";
import {FaCheck, FaRegEdit, FaTimes} from "react-icons/fa";
import {FiPlus} from "react-icons/fi";
import {IoMdMore} from "react-icons/io";
import {connect} from "react-redux";

import AllowanceExpenseList from "./allowanceExpenseList";
import {GridListTable} from "./gridListTable";
import "./travelCategoryForm.scss";

export class TravelCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowanceExpense: null,
      allowanceExpenses: [],
      positionRates: [],
      allowanceExpenseRate: [],
      activeTab: 0,
      currentTabRateType: "1",
      isModalShow: false,
      warningMessage: "",
      isLevelSelected: false,
      selectedLevels: [],
      selectedLevelAmount: "",
      levelList: [],
      levelGridList: [],
      isLevelEdit: false,
      selectedLevelGroupId: 0,
      levels: [],
      allowanceExpenseSelectList: [],
      fieldError: "",
      activeTabLabel: "Add New",
      formData: {
        CategoryId: 0,
        CategoryName: "",
        Description: "",
        TravelCategoryType: 1,
        TenantId: "",
      },
      errors: {},
      modalState: {show: false, type: "normal"},
      filteredAllowanceList: [],
      selectedAllowanceIdList: [],
      selectedAllowance: {},
      expenseMenu: null,
      skipPageReset: false,
      clickedButtonType: "",
      editing: [],
      selectedRow: {},
      selectedRowGroupId: 0,
      editLevelId: null,
      editLevelData: {
        Amount: "",
        GroupId: "",
        Id: "",
        LevelId: "",
        Name: "",
      },
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleRateTypeChange = this.handleRateTypeChange.bind(this);
    this.onFlatRateChange = this.onFlatRateChange.bind(this);
    this.getInputPositionRate = this.getInputPositionRate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.setEditLevelId = this.setEditLevelId.bind(this);
    this.setEditLevelData = this.setEditLevelData.bind(this);
    this.handleEditLevelDataSubmit = this.handleEditLevelDataSubmit.bind(this);
  }

  componentDidMount = () => {
    if (this.state.positionRates.length <= 0) {
      let arrayItem = [];
      if (this.props.levels && this.props.levels.length > 0) {
        this.props.levels.map((item, key) => {
          arrayItem.push({Id: item.Value, Name: item.Text});
          return null;
        });

        this.setState({positionRates: arrayItem});
      }
    }
    this.initialState();
    if (!isEmpty(this.props.levels) && this.props.levels.length > 0) {
      this.setState({levels: this.Arrays(this.props.levels)});
    }
    if (!isEmpty(this.props.allowanceExpense) && this.props.allowanceExpense.length > 0) {
      this.setState({
        allowanceExpenseSelectList: this.Arrays(this.props.allowanceExpense),
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.allowanceExpenseRate !== this.state.allowanceExpenseRate ||
      prevState.allowanceExpenseSelectList !== this.state.allowanceExpenseSelectList
    ) {
      let filterDrpdwn = [];
      let allowanceExpensesObj =
        this.state.allowanceExpenses?.length > 0 &&
        this.state.allowanceExpenses.reduce(
          (obj, item) => Object.assign(obj, {[item.Text]: item.Value}),
          {}
        );
      let filteredAllowanceExpenseSelectList;
      if (Object.keys(allowanceExpensesObj).length > 0) {
        filteredAllowanceExpenseSelectList = this.state.allowanceExpenseSelectList.filter(
          (allowanceExpenseSelect) =>
            allowanceExpenseSelect.value !=
            allowanceExpensesObj[allowanceExpenseSelect.label]
        );
      } else {
        filteredAllowanceExpenseSelectList = this.state.allowanceExpenseSelectList;
      }
      this.setState({filteredAllowanceList: filteredAllowanceExpenseSelectList});
    }
    if (prevState.levelGridList !== this.state.levelGridList) {
      const {activeTab, allowanceExpenseRate, selectedRow, editLevelData} = this.state;

      let selectedLevelGridList = this.state.levelGridList.filter(
        (levelGrid) => levelGrid?.LevelId === editLevelData?.LevelId
      );

      let allowanceExpenseRateCopy = Object.assign([], allowanceExpenseRate);

      if (allowanceExpenseRateCopy.length > 0) {
        allowanceExpenseRateCopy.filter(
          (x) => x.AllowanceId === activeTab
        )[0].LevelRates = allowanceExpenseRateCopy
          .filter((x) => x.AllowanceId === activeTab)[0]
          .LevelRates.filter((x) => x.LevelId !== editLevelData?.LevelId)
          .concat(selectedLevelGridList);
      }
      this.setState(() => ({
        allowanceExpenseRate: allowanceExpenseRateCopy,
      }));
    }
  };

  setEditLevelId = (levelId) => {
    this.setState({editLevelId: levelId});
  };

  setEditLevelData = (editLevelData) => {
    this.setState({editLevelData});
  };

  handleEditLevelDataSubmit = (event) => {
    event.preventDefault();
    const {editLevelData, editLevelId, levelGridList} = this.state;

    const newLevelGridLists = [...levelGridList];

    const index = levelGridList.findIndex(
      (levelGrid) => levelGrid.LevelId === editLevelId
    );

    newLevelGridLists[index] = editLevelData;

    this.setState({levelGridList: newLevelGridLists});
    this.setEditLevelId(null);
  };

  onFlatRateChange = (name, value) => {
    var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    stateCopy = stateCopy.map((x) => {
      const o = {
        ...x,
      };
      if (x.AllowanceId === this.state.activeTab) {
        o["FlatRate"] = value;
      }
      return o;
    });
    this.setState({allowanceExpenseRate: stateCopy});
  };

  handleTabChange = (id) => {
    if (this.state.allowanceExpenses.length > 0) {
      var currentRates = this.state.allowanceExpenseRate.filter(
        (x) => x.AllowanceId === id
      );
      var isFlatRate = currentRates.length > 0 ? currentRates[0].IsFlatRate : true;
      this.setState(
        {
          activeTab: id,
          currentTabRateType: isFlatRate ? "1" : "2",
          activeTabLabel: this.state.allowanceExpenses.find((x) => x.Value === id).Text,
          selectedAllowance: this.state.allowanceExpenses.find((x) => x.Value === id),
        },
        () => (id !== 0 ? this.getLevelGridList() : {})
      );
    } else {
      this.setState({selectedAllowance: {}});
    }
  };

  handleRateTypeChange = (event) => {
    var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
    stateCopy
      .filter((x) => x.AllowanceId === this.state.activeTab)
      .filter((x) => {
        x.IsFlatRate = event.target.value === "1" ? true : false;
      });
    this.setState(
      {
        currentTabRateType: event.target.value,
        allowanceExpenseRate: stateCopy,
      },
      () => this.getLevelGridList()
    );
  };

  getInputPositionRate = (AllowanceId, PositionId) => {
    var currentRates = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === AllowanceId
    );
    return currentRates[0].LevelRates.length > 0
      ? "" + currentRates[0].LevelRates.find((x) => x.LevelId === PositionId).Amount
      : "";
  };

  getInputFlatRate = (id) => {
    var rate = this.state.allowanceExpenseRate.find(
      (y) => y.AllowanceId === id
    )?.FlatRate;
    return isEmpty(rate) || rate === 0 ? "" : "" + rate;
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   this.props.onSubmit(this.state);
  // };

  handleReset = () => {
    this.props.reset();
    this.initialState();
  };
  initialState = () => {
    if (
      this.props.initialValues &&
      this.props.initialValues.allowanceExpenseRate.length > 0 &&
      this.props.allowanceExpense &&
      this.props.allowanceExpense.length > 0
    ) {
      const {CategoryId, CategoryName, Description, TravelCategoryType, TenantId} =
        this.props.initialValues;
      var stateCopy = [];
      this.props.initialValues.allowanceExpenseRate.map((x) => {
        stateCopy.push({
          Value: x?.AllowanceId,
          Text: this.props.allowanceExpense.find(
            (y) => `${y.Value}` === `${x.AllowanceId}`
          )?.Text,
        });
        return null;
      });
      // stateCopy.push({Value: 0, Text: "Add New"});
      const isEdit = CategoryId !== 0 ? true : false;
      let activeTab = isEdit ? stateCopy[0].Value : 0;
      let isFlatRate = this.props.initialValues.allowanceExpenseRate.find(
        (x) => x.AllowanceId === stateCopy[0].Value
      ).IsFlatRate;
      let selectedAllowance = isEdit ? stateCopy[0] : {};
      // console.log({isEdit, stateCopy, selectedAllowance});
      this.setState(
        {
          formData: {
            CategoryId,
            CategoryName,
            Description,
            TravelCategoryType: parseInt(TravelCategoryType),
            TenantId: TenantId,
          },
          allowanceExpenses: stateCopy,
          allowanceExpenseRate: this.props.initialValues.allowanceExpenseRate.map((x) => {
            const o = {...x};
            return o;
          }),
          activeTab,
          currentTabRateType: isFlatRate ? "1" : "2",
          selectedAllowance,
        },
        () => this.getLevelGridList()
      );
    }
  };

  closeModal = () => {
    this.setState({isModalShow: false});
  };

  isValidForm = () => {
    let errors = {};
    this.state.allowanceExpenseRate.map((x, i) => {
      if (x.IsFlatRate && isEmpty(x.FlatRate)) {
        errors[`FlatRate-${x.AllowanceId}`] = "Required";

        if (
          this.state.allowanceExpenseRate.length > 1 &&
          x.AllowanceId !== this.state.activeTab
        )
          errors.PositionRates = "Required";
        return errors;
      } else if (!x.IsFlatRate && x.LevelRates.length <= 0) {
        errors.PositionRates = "Required";
        return errors;
      }
    });
    return errors;
  };

  Arrays = (data) => {
    let arrayItem = [];
    if (data) {
      data.map((item, key) => {
        arrayItem.push({label: item.Text, value: item.Value});
        return null;
      });
    }
    return arrayItem;
  };

  getLevelGridList = () => {
    let list = [];
    let activeAllowanceExpenseRate = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    )[0];
    if (!isEmpty(activeAllowanceExpenseRate)) {
      activeAllowanceExpenseRate.LevelRates.map((x) => {
        const o = {
          ...x,
        };
        list.push(o);
        return null;
      });
      let levelDropdown = this.getLevelSelectList();
      let levelIds = list?.map((l) => `${l.LevelId}`) || [];

      // adds new level to list
      levelDropdown
        .filter((x) => !levelIds.includes(x.value))
        .map((x, i) => {
          let obj = {
            Amount: 0,
            LevelId: x.value,
            Name: x.label,
            GroupId: list.length + i + 1,
          };
          list.push(obj);
        });
    }
    this.setState({levelGridList: list});
  };

  getLevelSelectList = () => {
    const levelDrpdwn = [];
    let levels =
      this.state.selectedLevels.length === 0
        ? [{value: 0, label: "All Other"}].concat(this.state.levels)
        : this.state.selectedLevels[0].value === 0
        ? []
        : this.state.levels.concat();
    let currentRate = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === this.state.activeTab
    );
    // debugger;
    levels.map((x) => {
      if (this.state.allowanceExpenseRate.length === 0 || currentRate.length === 0) {
        levelDrpdwn.push(x);
      } else {
        if (currentRate[0].LevelRates.length === 0) {
          levelDrpdwn.push(x);
        } else {
          if (this.state.isLevelEdit) {
            let selectedLevels = this.state.selectedLevels.map((x) => {
              return {
                LevelId: x.value,
                Name: x.label,
                Amount: this.state.selectedLevelAmount,
                GroupId: x.Id,
              };
            });
            var stateCopy = Object.assign([], this.state.allowanceExpenseRate);
            let mergedList = stateCopy
              .filter((x) => x.AllowanceId === this.state.activeTab)[0]
              .LevelRates.filter((x) => x.GroupId !== this.state.selectedLevelGroupId)
              .concat(selectedLevels);

            if (isEmpty(mergedList.find((y) => y.LevelId === x.value)))
              levelDrpdwn.push(x);
          } else {
            if (isEmpty(currentRate[0].LevelRates.find((y) => y.LevelId === x.value)))
              levelDrpdwn.push(x);
          }
        }
      }
    });
    return levelDrpdwn;
  };

  removeAllowancExpense = (id) => {
    let {allowanceExpenses, activeTab, allowanceExpenseRate} = this.state;
    let newAllowanceExpense = allowanceExpenses.filter((x) => x.Value !== id);
    let newAllowanceExpenseRate = allowanceExpenseRate.filter(
      (x) => x.AllowanceId !== id
    );
    let activeTabId = activeTab;
    if (activeTab === id) {
      let indexOfEl = allowanceExpenses.findIndex((x) => x.Value == id);
      activeTabId = allowanceExpenses[indexOfEl + 1].Value;
    }
    this.setState(
      {
        allowanceExpenses: newAllowanceExpense,
        allowanceExpenseRate: newAllowanceExpenseRate,
      },
      () => this.handleTabChange(activeTabId)
    );
  };

  handleInputChange = (name, value) => {
    this.setFormState(name, value);
    let errors = {};
    if (isEmpty(value)) {
      errors.CategoryName = "Required";
    }
    this.setState({errors});
  };

  handleRadioChange = (event) => {
    let {name, value} = event.target;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: parseInt(value),
      },
    }));
  };

  setFormState = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleAddAllowanceOrExpense = (filterDrpdwn) => {
    this.handleModalOpen(filterDrpdwn);
  };

  handleModalOpen = () => {
    this.handleExpenseModalShow({show: true, type: "normal"});
  };

  handleExpenseModalShow = (show, type) => {
    this.setState({modalState: {show, type}});
  };

  handleCheckboxChange = (event, value) => {
    let {name, checked} = event.target;
    let selectedAllowanceId = this.state.selectedAllowanceIdList;
    if (checked) {
      selectedAllowanceId = [...selectedAllowanceId, value];
    } else {
      selectedAllowanceId = selectedAllowanceId.filter((id) => id !== value);
    }
    this.setState(() => ({selectedAllowanceIdList: selectedAllowanceId}));
  };

  handleAddSelectedAllowance = (e) => {
    e.preventDefault();
    let tempAllowanceExpenses = this.state.filteredAllowanceList.filter((allowance) =>
      this.state.selectedAllowanceIdList.includes(allowance.value)
    );

    let modifiedTempAllowanceExpenses = tempAllowanceExpenses.map((allowance) => {
      return {
        Value: allowance.value,
        Text: allowance.label,
      };
    });

    let tempAllowanceExpensesRate = modifiedTempAllowanceExpenses.map((allowance) => {
      return {
        AllowanceId: allowance.Value,
        IsFlatRate: true,
        FlatRateId: 0,
        FlatRate: null,
        LevelRates: [],
      };
    });

    this.setState((prevState) => ({
      allowanceExpenses: [
        ...modifiedTempAllowanceExpenses,
        ...prevState.allowanceExpenses,
      ],
      allowanceExpenseRate: [
        ...prevState.allowanceExpenseRate,
        ...tempAllowanceExpensesRate,
      ],
      selectedAllowance: modifiedTempAllowanceExpenses[0],
      activeTab: modifiedTempAllowanceExpenses[0].Value,
      activeTabLabel: modifiedTempAllowanceExpenses[0].Text,
      levelGridList: [],
      currentTabRateType: "1",
    }));
    this.setState({selectedAllowanceIdList: []});
    this.handleExpenseModalShow(false, "normal");
  };

  handleSelectAllowance = (allowance) => {
    this.setState({selectedAllowance: allowance});
    var currentRates = this.state.allowanceExpenseRate.filter(
      (x) => x.AllowanceId === allowance.Value
    );
    var isFlatRate = currentRates.length > 0 ? currentRates[0].IsFlatRate : true;
    this.setState(
      {
        activeTab: allowance.Value,
        currentTabRateType: isFlatRate ? "1" : "2",
        activeTabLabel: this.state.allowanceExpenses.find(
          (x) => x.Value === allowance.Value
        ).Text,
      },
      () => (allowance.Value !== 0 ? this.getLevelGridList() : {})
    );
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({expenseMenu: event.currentTarget});
  };

  handleClose = (event) => {
    event.preventDefault();
    this.setState({expenseMenu: null});
  };

  handleRemoveExpense = (event) => {
    event.preventDefault();
    let {allowanceExpenses, activeTab, allowanceExpenseRate, selectedAllowance} =
      this.state;

    let newAllowanceExpense = allowanceExpenses.filter(
      (x) => x.Value !== selectedAllowance.Value
    );
    let newAllowanceExpenseRate = allowanceExpenseRate.filter(
      (x) => x.AllowanceId !== selectedAllowance.Value
    );
    let activeTabId = activeTab;
    let indexOfEl;
    if (activeTab === selectedAllowance.Value) {
      indexOfEl = allowanceExpenses.findIndex((x) => x.Value == selectedAllowance.Value);
      if (allowanceExpenses.length === indexOfEl + 1) {
        activeTabId = allowanceExpenses[0]?.Value ?? null;
      } else {
        activeTabId = allowanceExpenses[indexOfEl + 1]?.Value ?? null;
      }
    }
    this.setState(
      {
        allowanceExpenses: newAllowanceExpense,
        allowanceExpenseRate: newAllowanceExpenseRate,
      },
      () => this.handleTabChange(activeTabId)
    );
    this.handleClose(event);
  };

  handleSubmit = (event) => {
    const {travelCategory} = this.props;
    event.preventDefault();
    const {formData} = this.state;
    let errors = {};
    if (isEmpty(formData.CategoryName)) {
      errors.CategoryName = "Required";
    } else {
      if (
        travelCategory &&
        travelCategory.find(
          (x) =>
            x.CategoryId !== formData.CategoryId &&
            x.CategoryName === formData.CategoryName
        )
      ) {
        errors.CategoryName = "Category Name already taken. Please choose another name!";
      } else if (this.state.allowanceExpenseRate.length <= 0) {
        errors.AllowanceExpense = "Required";
        this.setState({
          warningMessage: "Please add at least one Allowance/Expense",
          isModalShow: true,
        });
      } else {
        let rateErrors = this.isValidForm();

        if (!isEmpty(rateErrors)) {
          errors = {...errors, ...rateErrors};
          if (!isEmpty(rateErrors.PositionRates))
            this.setState({
              warningMessage: "Please add at least one rate",
              isModalShow: true,
            });
        }
      }
    }
    // debugger;
    if (isEmpty(errors)) {
      this.props.onSubmit(formData, this.state.allowanceExpenseRate);
    }
    this.setState({errors});
  };

  render() {
    const {submitting, spinnerLoading} = this.props;
    const {
      formData,
      errors,
      activeTab,
      allowanceExpenses,
      currentTabRateType,
      modalState,
      filteredAllowanceList,
      selectedAllowanceIdList,
      selectedAllowance,
    } = this.state;
    const plus = (
      <div className="flex">
        <FiPlus />
        <span>Add new</span>
      </div>
    );

    return (
      <div>
        <Modal
          title={"Add Allowance or Expense"}
          open={modalState.show}
          onModalClose={() => this.handleExpenseModalShow(false, "normal")}
          type={modalState.type}
          className="modal-travel-category"
        >
          <AllowanceExpenseList
            handleCheckboxChange={this.handleCheckboxChange}
            handleExpenseModalShow={this.handleExpenseModalShow}
            filteredAllowanceList={filteredAllowanceList}
            selectedAllowanceIdList={selectedAllowanceIdList}
            handleAddSelectedAllowance={this.handleAddSelectedAllowance}
          />
        </Modal>
        <form
          onSubmit={(values) => this.handleSubmit(values)}
          className="travel-category-form"
        >
          <input type="hidden" name="CategoryId"></input>
          <div className="form-body">
            <div className="form-row">
              <FormGroup
                name="CategoryName"
                type="text"
                error={errors.CategoryName}
                label="Category name"
                value={formData.CategoryName}
                onChange={this.handleInputChange}
                width="250px"
              />
            </div>

            <div className="form-row">
              <div className="filters-input__item">
                <RadioGroup
                  name="TravelCategoryType"
                  onChange={this.handleRadioChange}
                  value={formData.TravelCategoryType}
                  className="action-radio"
                >
                  <FormControlLabel value={1} control={<Radio />} label="Domestic" />
                  <FormControlLabel value={2} control={<Radio />} label="International" />
                </RadioGroup>
              </div>
            </div>

            <div className="form-row">
              <div className="form-label">
                <span>Description</span>
              </div>
              <FormGroup
                formName="textarea"
                name="Description"
                error={errors.Description}
                value={formData.Description}
                onChange={this.handleInputChange}
                rows={2}
                cols={60}
              />
            </div>
            <div className="form-row">
              <div className="form-label">
                <span>Allowances and expenses applicable to this category</span>
              </div>
              <button
                type="button"
                className="button button-primary select-applicable-button"
                onClick={() => this.handleExpenseModalShow({show: true, type: "normal"})}
              >
                Select Applicable Items
              </button>
              {allowanceExpenses?.length > 0 && <p>Define Rates for Selected Items</p>}
              <div className="rate-section">
                {allowanceExpenses?.length > 0 && (
                  <div className="rate-section__left">
                    {allowanceExpenses.map((x, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            activeTab === x.Value
                              ? "rate-section__left-item active"
                              : "rate-section__left-item"
                          }
                          onClick={() => this.handleSelectAllowance(x)}
                        >
                          <p>{x.Text}</p>
                          <IconButton
                            id="expense-button"
                            aria-controls={
                              Boolean(this.state.expenseMenu) ? "expense-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(this.state.expenseMenu) ? "expense-menu" : undefined
                            }
                            color="inherit"
                            onClick={this.handleClick}
                            style={{padding: 0}}
                          >
                            <IoMdMore />
                          </IconButton>
                          <Menu
                            id="expense-menu"
                            anchorEl={this.state.expenseMenu}
                            open={Boolean(this.state.expenseMenu)}
                            onClose={this.handleClose}
                            MenuListProps={{
                              "aria-labelledby": "expense-button",
                            }}
                          >
                            <MenuItem onClick={this.handleRemoveExpense}>Remove</MenuItem>
                          </Menu>
                        </div>
                      );
                    })}
                  </div>
                )}
                {Object.keys(selectedAllowance).length > 0 && (
                  <div className="rate-section__right">
                    <div className="rate-section__right-title">
                      {selectedAllowance.Text}
                    </div>
                    <div className="rate-section__right-content">
                      <RadioGroup
                        name={`RateType${selectedAllowance.Value}`}
                        onChange={(event) => this.handleRateTypeChange(event)}
                        value={
                          this.state.allowanceExpenseRate.find(
                            (y) => y.AllowanceId === selectedAllowance.Value
                          )?.IsFlatRate
                            ? "1"
                            : "2"
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Flat rate for all"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Rate based on position"
                        />
                      </RadioGroup>
                      {currentTabRateType === "1" ? (
                        <div className="form-row">
                          <FormGroup
                            name={`FlatRate-${selectedAllowance.Value}`}
                            label="Rate"
                            value={this.getInputFlatRate(selectedAllowance.Value)}
                            onChange={this.onFlatRateChange}
                            width="250px"
                            type="number"
                            // width="100px"
                            error={errors[`FlatRate-${selectedAllowance.Value}`]}
                          />
                        </div>
                      ) : (
                        <div>
                          {this.state.levelGridList?.length > 0 && (
                            <GridListTable
                              data={this.state.levelGridList}
                              editLevelId={this.state.editLevelId}
                              setEditLevelId={this.setEditLevelId}
                              editLevelData={this.state.editLevelData}
                              setEditLevelData={this.setEditLevelData}
                              handleEditLevelDataSubmit={this.handleEditLevelDataSubmit}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-row footer-grp button-group justify-between">
              <button
                type="button"
                className="footer__cancel button button-white"
                disabled={submitting}
                onClick={() => this.props.handleModalShow(false, "normal")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="footer__save button button-primary"
                disabled={submitting || spinnerLoading}
              >
                {spinnerLoading ? "Saving" : "Save"}
              </button>
            </div>
          </div>
        </form>
        <Modal
          title={""}
          open={this.state.isModalShow}
          onModalClose={this.closeModal}
          type="dangerContent"
          className="modal-warn"
          content={this.state.warningMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allowanceExpense: state.travelCategoryReducer.allowanceExpense,
  levels: state.travelCategoryReducer.levels,
  initialValues: state.travelCategoryReducer.travelCategoryFormData,
  travelCategory: state.travelCategoryReducer.travelCategory,
  spinnerLoading: state.travelCategoryReducer.spinnerLoading,
});

export default connect(mapStateToProps, {})(TravelCategoryForm);
