import Button from "components/button";
import Icons from "components/icons";
import React from "react";

function SubrowActionCell(props) {
  const data = props.row?.original || {};
  const onEditProject = (e, data) => {
    e.stopPropagation();
    props.handleEditProject(data);
  };
  const onArchiveProject = (e, projectId) => {
    e.stopPropagation();
    props.handleArchiveProject(projectId);
  };
  const onDeleteProject = (e, data) => {
    e.stopPropagation();
    props.handleDeleteProject(data);
  };
  const onRestoreProject = (e, projectId) => {
    e.stopPropagation();
    props.handleRestoreProject(projectId);
  };

  return (
    <>
      {" "}
      <span className="detail flex">
        {/* <Button title={list.DocumentCount + " Documents"} bg="white" /> */}
        <div className="client-document-count">
          <Icons name="Pdf" />
          <span className="client-document-count-number">{data.DocumentCount}</span>
        </div>
        {!data.IsArchived ? (
          <Button
            buttonType="drop-button"
            bg="secondary"
            title="Edit"
            onClick={(e) => onEditProject(e, data)}
            dropClass={"common-drop"}
            dropComponent={
              <ul id={"Project" + data.ProjectId} className="common-drop-list">
                <li
                  className={"common-drop-list__item"}
                  onClick={(e) => onArchiveProject(e, data.ProjectId)}
                >
                  <span className="common-drop-name">Archive</span>
                </li>

                <li
                  className={"common-drop-list__item"}
                  onClick={(e) => onDeleteProject(e, data)}
                >
                  <span className="common-drop-name">Delete </span>
                </li>
              </ul>
            }
          />
        ) : (
          <Button
            buttonType="drop-button"
            bg="secondary"
            title="Restore"
            onClick={(e) => onRestoreProject(e, data.ProjectId)}
            dropClass={"common-drop"}
            dropComponent={
              <ul className="common-drop-list">
                <li
                  className={"common-drop-list__item"}
                  onClick={(e) => onDeleteProject(e, data)}
                >
                  <span className="common-drop-name">Delete</span>
                </li>
              </ul>
            }
          />
        )}
      </span>
    </>
  );
}

export default SubrowActionCell;
