import React, {Component} from "react";
import FormGroup from "components/form";
import isEmpty from "helpers/isEmpty";
import EmployeeSearch from "components/common/employeeSearch";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchEmployee,
      searchActionStatement,
      fromDate,
      toDate,
      activeJobRole,
      activePosition,
    } = this.props.parentState;
    const {
      designationList,
      levelList,
      handleAutoSelect,
      setDate,
      date,
      handleDateSelectForMultiple,
    } = this.props;
    return (
      <div className="filters">
        <EmployeeSearch
          onChange={handleAutoSelect}
          value={searchEmployee}
          label="Employee"
          name="searchEmployee"
        />
        <div className="filters-grid">
          <FormGroup
            className="search"
            onChange={handleAutoSelect}
            name="searchActionStatement"
            value={searchActionStatement}
            placeholder="Search Case Title"
          />
        </div>

        <div className="filters-grid">
          <FormGroup
            formName="reactdaterange"
            startDate={!isEmpty(fromDate) ? fromDate : new Date()}
            endDate={!isEmpty(toDate) ? toDate : new Date()}
            onChange={(name, range) => handleDateSelectForMultiple(range)}
            placeholder="Select date"
          />
        </div>

        <div className="filters-grid">
          <FormGroup
            onChange={this.props.handleAutoSelect}
            name="activeJobRole"
            value={activeJobRole}
            options={levelList}
            formName="reactselect"
            placeholder="Filter Job Role"
          />
        </div>

        <div className="filters-grid">
          <FormGroup
            onChange={this.props.handleAutoSelect}
            name="activePosition"
            value={activePosition}
            options={designationList}
            formName="reactselect"
            placeholder="Filter Position"
          />
        </div>
      </div>
    );
  }
}

export default SearchAndFilter;
