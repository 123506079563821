import React from 'react'
import FormInput from 'components/form'
import { useSelector,useDispatch } from 'react-redux';
import Button from 'components/button';

function PerformanceFilters(props) {
    const { filters, onChange, getRollout } = props;
    const dispatch = useDispatch();
    const {branchList,departmentList,jobStatusList} = useSelector((state:any)=>state.commonReducer);
    const {reviewCyclesDropDown} = useSelector((state:any)=>state.appraisalReducer);
    return (
        <div className='appraisals-filters'>
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="reviewCycleId"
                label="Review Cycle"
                value={filters.reviewCycleId}
                width="200px"
                options={reviewCyclesDropDown ?? []}
            />
            <FormInput
                formName="searchInput"
                name="employee"
                label="Search Employee"
                value={filters.employee}
                onChange={onChange}
                width="200px"
            />
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="managerId"
                label="Filter Manager"
                value={filters.managerId}
                width="200px"
                options={[]}
            />
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="branchId"
                label="Filter Branch"
                value={filters.branchId}
                width="200px"
                options={branchList??[]}
            />
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="departmentId"
                label="Filter Department"
                value={filters.departmentId}
                width="200px"
                options={departmentList??[]}
            />
            <FormInput
                formName="reactselect"
                onChange={onChange}
                name="status"
                label="Filter Status"
                value={filters.status}
                width="200px"
                options={jobStatusList??[]}
            />
            <Button bg='primary' title='Apply Filters' onClick={()=>getRollout()} />
        </div>
    )
}

export default PerformanceFilters