import React from "react";
import "./styles.scss";
import JobHeader from "./jobHeader";
import JobTables from "./jobTables";
import JobDetails from "./jobDetails";
import JobScore from "./jobScore";
import Button from "components/button";
import {useSelector} from "react-redux";

function JobDescription(props) {
  let {EmployeeDetails} = props.jobDesc || {EmployeeDetails: {}};
  let {
    employeeProfile: {EmployeeProfileHeader},
  } = props;
  const {user} = useSelector((state) => state.auth);
  const a =
    user.RoleId === 2
      ? {...props, profileInfo: user, employeeProfile: {EmployeeProfileHeader: user}}
      : props;
  return (
    <div className="jobdesc">
      <div className="jobdesc-container">
        <JobHeader {...a} />
        <JobTables {...a} />
        <JobDetails {...a} />
        <JobScore {...a} />
      </div>
      <div className="jobdesc-acknowledge">
        <div className="jobdesc-block">
          <div className="jobdesc-title">
            <h3>Acknowledgement</h3>
          </div>
          <div className="jobdesc-block__body">
            <div>
              Acknowledged by{" "}
              {/* {EmployeePersonalAndJobDetail?.Gender === "Male" ? "Mr." : "Mrs."}{" "} */}
              {EmployeeProfileHeader?.EmployeeName}
            </div>
            {/* <div>on January 1st 2010, 3:30 pm</div> */}
            <div>on .......................................</div>
          </div>
        </div>
      </div>
      <div className="jobdesc-btn">
        <div className="button-group button-group__right">
          <Button bg="primary" onClick={() => props.closeModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default JobDescription;
