import React from "react";

const RowSelectionCheckbox = React.forwardRef<any>(
  ({indeterminate, ...rest}: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef && resolvedRef.current)
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {!rest.disabled && (
          <input className="table-check" type="checkbox" ref={resolvedRef} {...rest} />
        )}
      </>
    );
  }
);

export default RowSelectionCheckbox;
