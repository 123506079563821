import React, { Component } from "react";
import Modal from "components/modal";
import { connect } from "react-redux";
import { AiOutlineLeftSquare, AiOutlineDoubleRight } from "react-icons/ai";
import { IoMdArrowRoundBack, IoIosArrowBack } from "react-icons/io";
import RouteEnum from "constants/routeEnum";
import { useHistory } from "react-router-dom";
import RequestAndAssign from "scenes/common/requestAndAssign";

function Title(props) {
  const {name, isCustomBackLink, customLink, hideAssignAndApprove, showUserRequest} =
    props;
  const history = useHistory();
  return (
    <div className="common-route">
      {/* <button
        className="btn btn-back"
        onClick={() => parentProps.history && parentProps.history.goBack()}
      >
        <IoIosArrowBack />
      </button> */}
      <div className="common-route-title">
        {isCustomBackLink ? (
          <span
            className="module"
            onClick={() => history.push(customLink.link)}
            style={{paddingLeft: 0}}
          >
            {customLink.title}
          </span>
        ) : (
          <span
            className="module"
            onClick={() => history.push(RouteEnum.EmployeeDashBoard)}
            style={{paddingLeft: 0}}
          >
            Dashboard
          </span>
        )}

        <AiOutlineDoubleRight className="right-arrow" />
        <span className="path-name">{name}</span>
      </div>
      {!hideAssignAndApprove && <RequestAndAssign showUserRequest={showUserRequest} />}
    </div>
  );
}

Title.defaultProps = {
  isCustomBackLink: false,
};

export default Title;
