import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import Table from "components/customTable";
import Pagination from "components/common/pagination";

const pageState = {
  pageIndex: 0,
  pageSize: 10,
};

const ShiftTable = (props) => {
  const [state, setState] = useState(pageState);
  const {isArchived, cols, getShiftList} = props;

  useEffect(() => {
    const param = {
      page: state.pageIndex,
      size: state.pageSize,
      isArchived: isArchived || false,
    };
    getShiftList(param);
  }, [state.pageIndex, state.pageSize]);

  const handleRowChange = (num) => {
    setState((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0,
      };
    });
  };

  const handleIndexChange = (num) => {
    setState((filter: any) => {
      return {
        ...filter,
        pageIndex: num,
      };
    });
  };
  const {shiftList} = useSelector((state: any) => state.roosterReducer);
  const totalRows = shiftList && shiftList.length > 0 ? shiftList[0].TotalRows : 0;
  return (
    <>
      <Table isFixedWidth data={shiftList} columns={cols} />
      {totalRows > 0 && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={state.pageIndex}
          rowsPerPage={state.pageSize}
          total={totalRows}
          //total={shiftList.length > 1 ? shiftList[1]?.TotalRows : shiftList.length}
        />
      )}
    </>
  );
};

export default ShiftTable;
