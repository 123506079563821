import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {CgAttachment} from "react-icons/cg";
import {UploadFiles} from "store/actions/common";
import {objectToFormData} from "object-to-formdata";
import classnames from "classnames";

const AttachmentButton = (props) => {
  const {
    name,
    disabled,
    isMultiple,
    maxFiles,
    maxSizeInMb,
    uploadSize,
    uploadTitle,
    isValidUser,
    value,
    setUploading,
  } = props;
  const ref: any = useRef();
  const dispatch = useDispatch();

  const uploadToServer = async (newFiles) => {
    const formData = objectToFormData({});
    setUploading(true);
    for (var i = 0; i < newFiles.length; i++) {
      formData.append("files", newFiles[i]);
    }
    props.onChange && props.onChange(name, [...value, ...newFiles]);

    let response: any = await dispatch(UploadFiles(formData));

    if (response) props.onChange && props.onChange(name, [...value, ...response]);
    else props.onChange && props.onChange(name, [...value]);

    setUploading(false);
  };
  const handleChange = async (event) => {
    // if (!showUploadSection()) return;
    const existingFile = value.map((file) => file.UserFileName);

    const {files} = event.target;
    let newFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!existingFile.includes(file.name))
        newFiles.push(
          Object.assign(file, {
            UserFileName: file.name,
            ServerFileName: file.name,
          })
        );
    }
    if (newFiles.length > 0) {
      let totalFiles = newFiles.length + existingFile.length;
      if (totalFiles > maxFiles) {
        // setError(`Max file number is ${maxFiles}`);
        return;
      }
      let isValid = true;
      for (let i = 0; i < newFiles.length; i++) {
        // isValid = validateFile(files[i], props, setError);
        // if (!isValid) break;
      }
      if (isValid) {
        // setError("");
        await uploadToServer(newFiles);
        ref.current.value = "";
      }
    }
  };

  return (
    <span style={{position: "relative"}}>
      <input
        ref={ref}
        style={{
          position: "absolute",
          height: "100%", // uploadSize ? "100%" : height + "px",
        }}
        className={classnames({
          "genericForm-group__upload-input": true,
          "disable-input": disabled,
        })}
        type="file"
        multiple={isMultiple}
        name={name}
        disabled={disabled}
        onChange={(event) => handleChange && handleChange(event)}
      />
      <CgAttachment />
    </span>
  );
};

export default AttachmentButton;
