import React, {useEffect} from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import Button from "components/button";
import {useDispatch, useSelector} from "react-redux";
import {
  addRosterTeam,
  getRosterScheduleEmployeeList,
  getRosterScheduleEmployeeListWithWorkingHour,
  getRosterScheduleList,
  updateRosterTeam,
} from "scenes/roster/helpers/action";

function TeamForm(props) {
  const {editData, isEdit, onSuccess} = props;
  const method = useForm();
  const {setValue} = method;
  const dispatch = useDispatch();
  const {patternList} = useSelector((state: any) => state.roosterReducer);
  const patterns =
    patternList && patternList.length > 0
      ? patternList.map((item) => ({value: item?.Id, label: item?.Name}))
      : [];
  const onSubmit = async (data) => {
    let res: any;
    if (isEdit) {
      res = await dispatch(
        updateRosterTeam({
          ...data,
          Id: editData?.Id,
        })
      );
    } else {
      res = await dispatch(
        addRosterTeam({
          ...data,
        })
      );
    }
    if (res && res?.Status) {
      onSuccess && onSuccess(res.Data);
      props.onModalClose();
    }
  };
  const refresh = async () => {
    const {stDate, edDate, tId} = props;
    const param = {
      startDate: stDate,
      endDate: edDate,
      teamId: tId ? tId.value : null,
    };
    const promiseArray = [
      dispatch(getRosterScheduleList(param)),
      dispatch(getRosterScheduleEmployeeList(param)),
      dispatch(getRosterScheduleEmployeeListWithWorkingHour(param)),
    ];
    await Promise.all(promiseArray);
  };
  useEffect(() => {
    if (isEdit) {
      setValue("Name", editData?.Name);
      setValue("Description", editData?.Description);
    }
  }, [isEdit]);
  return (
    <div className="p-sm">
      <FormGroup onSubmit={onSubmit} method={method}>
        <div className="team-body team-body-form">
          <FormItem
            name="Name"
            label="Team Name"
            rules={{required: "Team Name is required"}}
            width="250px"
          />
          <FormItem
            name="Description"
            label="Team Description"
            // rules={{required:"Team Description is required"}}
            width="350px"
            formName="textarea"
          />
        </div>

        <div className="flex mt-sm">
          <Button
            title={isEdit ? "Update Team" : "Save Team"}
            bg="primary"
            htmlButtonType="submit"
          />
          <Button title="Cancel" bg="secondary" onClick={() => props.onModalClose()} />
        </div>
      </FormGroup>
    </div>
  );
}

export default TeamForm;
