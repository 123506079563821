import RouteEnum from "constants/routeEnum";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {addCourseCompleted, completeCourse, leaveCourse} from "scenes/lms/helpers/action";
import {EmployeeCourseAssignStatus} from "scenes/lms/helpers/enums";
import Modal from "components/modal";

function ContentFooter({state, assignedId}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {course} = useSelector((state: any) => state.lmsReducer);
  const [confirm, setConfirm] = useState({
    open: false,
    title: "",
    message: "",
    type: "",
    okButtonTitle: "",
  });

  //{IsHRAssigned}
  const handleNextClick = async () => {
    const {currentPage, nextPage} = state;

    if (currentPage) {
      const {CourseId, CoursePageId, AssignedId, IsRead} = currentPage;
      if (!IsRead) {
        //set as completed.
        dispatch(
          addCourseCompleted({
            CourseId,
            CoursePageId,
            AssignedId,
          })
        );
      }
      let url = "";
      if (assignedId) {
        url = `${RouteEnum.lmsCourse}/${CourseId}/${assignedId}/pages/${nextPage.CoursePageId}`;
      } else url = `${RouteEnum.lmsCourse}/${CourseId}/pages/${nextPage.CoursePageId}`;
      history.push(url);
    }
  };
  const handleLeavePage = async () => {
    const {currentPage, nextPage} = state;
    const {AssignedId} = currentPage;
    var res = await dispatch(leaveCourse(AssignedId));
    if (res) {
      history.push(RouteEnum.lmsRoutes);
    }
  };
  const handleMarkAsCompleteClick = async () => {
    const {AssignedId, CoursePageId} = state?.currentPage;
    if (AssignedId && CoursePageId) {
      const res = await dispatch(completeCourse(AssignedId, CoursePageId));
      if (res) {
        history.push(RouteEnum.lmsRoutes);
      }
    }
  };
  const onConfirmed = () => {
    if (confirm.type === "leave") {
      handleLeavePage();
    } else if (confirm.type === "complete") {
      handleMarkAsCompleteClick();
    }
  };
  const showConfirm = (title, type) => {
    setConfirm({
      title: `${title}`,
      message: `Are you sure you want to ${type} this course?`,
      type,
      open: true,
      okButtonTitle: type === "leave" ? "Leave" : "Complete",
    });
  };
  return assignedId && course.AssignedStatus > 0 ? (
    <div className="course-page__footer">
      {state?.nextPage && (
        <div className="course-footerlink nextlink">
          <button className="button-next" onClick={handleNextClick}>
            Next
          </button>
          <span>{state?.nextPage?.PageName}</span>
        </div>
      )}
      {course?.AssignedStatus !== EmployeeCourseAssignStatus.Completed && (
        <>
          {!state?.nextPage && (
            <div className="course-footerlink ">
              <button
                className="button-complete"
                onClick={() => showConfirm("Complete Course", "complete")}
              >
                Mark as Complete
              </button>
            </div>
          )}

          {!course?.IsHRAssigned &&
            course.AssignedStatus === EmployeeCourseAssignStatus.InProgress && (
              <div className="course-footerlink">
                <button
                  className="button-leave"
                  onClick={() => showConfirm("Leave Course", "leave")}
                >
                  Leave this course
                </button>
              </div>
            )}
        </>
      )}

      <Modal
        type="confirm"
        title={confirm.title}
        func={() => onConfirmed && onConfirmed()}
        open={confirm.open}
        modalMessage={confirm.message}
        okButtonTitle={confirm.okButtonTitle}
        isOkButtonPrimary={confirm.type === "complete"}
        onModalClose={() =>
          setConfirm({open: false, title: "", message: "", type: "", okButtonTitle: "Ok"})
        }
      />
    </div>
  ) : null;
}

export default ContentFooter;
