import classnames from "classnames";
import Icons from "components/icons";
import SvgIcons from "components/svgIcons";
import React, {useEffect, useRef, useState} from "react";
import Avatar from "react-avatar";
import {AiOutlineArrowDown, AiOutlineArrowRight} from "react-icons/ai";
import {BsThreeDots} from "react-icons/bs";
import {getUploadPath} from "constants/userDetails";
import isEmpty from "isEmpty";
import MultipleApprovers from "assets/images/icons/multiple-approver.png";
function ApprovalFlow({approvalStatus, rowIndex}) {
  const approverBlock = useRef(null);
  const [approvalBlockData, setApprovalBlockData] = useState(null);
  useEffect(() => {
    let approvalData = [...approvalStatus];
    if (!isEmpty(approvalStatus[0]) && approvalStatus[0]?.IsMultipleApproval) {
      // approvalStatus.push({
      //   ...approvalStatus,
      //   ActionBy: "Multiple",
      //   ApprovalStatus: "",
      //   IsMultipleApproval: true,
      //   Multiple: true,

      // });
      approvalData.push({
        ...approvalStatus,
        ActionBy: "Multiple",
        ApprovalStatus: "",
        IsMultipleApproval: true,
        Multiple: true,
      });
    }
    setApprovalBlockData(approvalData);
    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  const handleMoreIconClick = (event, index) => {
    let targetParentEl = document.getElementById("rowApprover" + index);
    if (targetParentEl.contains(event.target)) targetParentEl.classList.toggle("active");
  };

  const handleClickOutside = (event) => {
    let targetParentEl = document.getElementsByClassName("approver__more");
    targetParentEl.forEach((element) => {
      if (!element.contains(event.target)) {
        element.classList.remove("active");
      }
    });
  };
  // let IsMultipleApproval = false;
  // if (!isEmpty(approvalStatus) && !approvalStatus[0]?.IsMultipleApproval) {
  //   approvalStatus.push({
  //     ...approvalStatus,
  //     ActionBy: "Multiple",
  //     ApprovalStatus: "",
  //     IsMultipleApproval: true,
  //     Multiple: true,

  //   });
  // }
  return (
    <div className="approver flex " ref={approverBlock}>
      {approvalBlockData?.map((x, i) => {
        // IsMultipleApproval = x.IsMultipleApproval;
        // let approvalLength = approvalBlockData.length;
        const classname = classnames({
          approved: x.ApprovalStatus === "Approved",
          "current-approver": !x.ApprovalStatus === "Approved",
          rejected: x.ApprovalStatus === "Rejected" || x.ApprovalStatus === "Denied",
          cancelled: x.ApprovalStatus === "Cancelled",
        });
        if (i < 4)
          return (
            <>
              {x.ActionBy ? (
                <div className="approver__details flex" key={i}>
                  <Avatar
                    alt={x.ActionBy}
                    name={x.ActionBy}
                    src={
                      x.Multiple
                        ? MultipleApprovers
                        : x.ApproverImage && getUploadPath(x.ApproverImage)
                    }
                    className="emp-img"
                    round
                    size={40}
                  />
                  {x.ApprovalStatus === "Approved" ? (
                    // <Icons name="Approve" className="approved-icon" />
                    <SvgIcons name="ApprovedCircle" className="approved-icon" />
                  ) : null}
                  {(x.ApprovalStatus === "Rejected" || x.ApprovalStatus === "Denied") && (
                    // <Icons name="Reject" className="rejected-icon" />
                    <SvgIcons name="RejectedCircle" className="rejected-icon" />
                  )}
                  {x.ApprovalStatus === "Cancelled" && (
                    <Icons name="Cancel" className="rejected-icon" />
                  )}
                  <div className={classname}>{x.Multiple ? "" : x.ActionBy}</div>
                  {i < approvalBlockData?.length - 1 ? (
                    <AiOutlineArrowRight className="arrow-right" />
                  ) : null}
                </div>
              ) : null}
            </>
          );
      })}
      {approvalBlockData?.length > 4 ? (
        <div
          className="approver__more"
          onClick={(event) => handleMoreIconClick(event, rowIndex)}
          id={"rowApprover" + rowIndex}
        >
          <BsThreeDots />
          <div className="approver__more__list">
            {approvalBlockData?.map((x, i) => {
              const classname = classnames({
                approved: x.ApprovalStatus === "Approved",
                "current-approver": !x.ApprovalStatus === "Approved",
                rejected: x.ApprovalStatus === "Rejected",
              });
              if (i > 3)
                return (
                  <>
                    {x.ActionBy ? (
                      <>
                        <div className="approver__details flex" key={i}>
                          <Avatar
                            alt={x.ActionBy}
                            name={x.ActionBy}
                            src={
                              x.Multiple
                                ? MultipleApprovers
                                : x.ApproverImage && getUploadPath(x.ApproverImage)
                            }
                            className="emp-img"
                            round
                            size={40}
                          />
                          {x.ApprovalStatus === "Approved" ? (
                            <SvgIcons name="ApprovedCircle" className="approved-icon" />
                          ) : null}
                          {(x.ApprovalStatus === "Rejected" ||
                            x.ApprovalStatus === "Denied") && (
                            // <Icons name="Reject" className="rejected-icon" />
                            <SvgIcons name="RejectedCircle" className="rejected-icon" />
                          )}
                          {x.ApprovalStatus === "Cancelled" && (
                            <Icons name="Cancel" className="rejected-icon" />
                          )}
                          <div className={classname}>{x.ActionBy} </div>
                        </div>
                        {i < approvalBlockData.length - 1 ? (
                          <div className="approver-forward-icon">
                            <AiOutlineArrowDown className="arrow-right" />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </>
                );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ApprovalFlow;
