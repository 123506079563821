import Button from "components/button";
import React, {useEffect, useState} from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
  getRosterTeamRoleList,
  updateRosterTeamEmployee,
  addRosterTeamEmployee,
} from "scenes/roster/helpers/action";
import isEmpty from "helpers/isEmpty";

function EmployeeEditForm(props) {
  const {editData, onModalClose} = props;

  const {teamList, teamRoleList} = useSelector((state: any) => state.roosterReducer);
  const [teamDataList, setTeamDataList] = useState([]);

  const method = useForm();
  const {watch, setValue} = method;
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const final = {
      Id: editData?.Id ?? 0,
      TeamRoleId: data?.TeamRoleId?.value,
      RoleName: data?.TeamRoleId?.label,
      TeamId: data?.TeamId?.value,
      TeamName: data?.TeamId?.label,
      EmployeeId: editData?.EmployeeId ?? 0,
      EmployeeName: editData?.EmployeeName ?? "",
      ShiftPatternId: data?.ShiftPatternId?.value,
      ShiftPatternName: data?.ShiftPatternId?.label,
    };
    let res;
    if (editData?.Id === 0) {
      res = await dispatch(addRosterTeamEmployee(final));
    } else {
      res = await dispatch(updateRosterTeamEmployee(final));
    }

    if (res && res.Status) {
      onModalClose && onModalClose();
    }
  };
  useEffect(() => {
    if (editData) {
      editData &&
        Object.keys(editData).map((item) => {
          if (item === "TeamRoleId") {
            const red = teamRoleList?.find(
              (item) => item.value === editData["TeamRoleId"]
            );
            setValue("TeamRoleId", red);
            return;
          } else if (item === "TeamId") {
            const ted = teamList?.find((item) => item.value === editData["TeamId"]);
            setValue("TeamId", ted);
            return;
          } else if (item === "ShiftPatternId") {
            const shift =
              patterns &&
              patterns.find((item) => item?.value === editData.ShiftPatternId);
            setValue("ShiftPatternId", shift);
            return;
          }
          setValue(item, editData[item]);
        });
    }
  }, [editData]);

  useEffect(() => {
    if (teamList) {
      let list = [...teamList];

      if (editData.IsArchivedTeam) {
        const ted = {
          Id: editData.TeamId,
          Name: editData.TeamName,
          label: editData.TeamName,
          value: editData.TeamId,
        };
        list.push(ted);
        setValue("TeamId", ted);
      }
      setTeamDataList(list);
    }
  }, [teamList]);

  useEffect(() => {
    if (!isEmpty(teamRoleList)) {
      const red = teamRoleList?.find((item) => item.value === editData["TeamRoleId"]);
      setValue("TeamRoleId", red);
    }
  }, [teamRoleList]);

  const teamId = watch("TeamId");
  useEffect(() => {
    fetchTeam();
  }, [teamId, editData]);

  const fetchTeam = async () => {
    if (teamId) {
      await dispatch(getRosterTeamRoleList(teamId?.value));
    } else if (editData) {
      await dispatch(getRosterTeamRoleList(editData?.TeamId));
    }

    // setValue("TeamId", ted);
  };

  const {patternList} = useSelector((state: any) => state.roosterReducer);
  const patterns =
    patternList && patternList.length > 0
      ? patternList.map((item) => ({value: item?.Id, label: item?.Name}))
      : [];

  return (
    <FormGroup onSubmit={onSubmit} method={method}>
      <div className="sched-modal__container">
        <div className="sched-modal__body">
          <FormItem
            formName="reactselect"
            name="TeamId"
            placeholder="Select Team"
            width="300px"
            options={teamDataList}
            className="pb-sm"
            rules={{required: "Team is required"}}
            label="Team"
          />
          <FormItem
            formName="reactselect"
            name="TeamRoleId"
            placeholder="Select Role"
            width="300px"
            options={teamRoleList}
            rules={{required: "Team Role is required"}}
            label="Role"
          />
          <FormItem
            name="ShiftPatternId"
            label="Shift Pattern"
            formName="reactselect"
            options={patterns}
            rules={{required: "Shift Pattern is required"}}
            width="300px"
          />
        </div>
        <div className="sched-modal__btns mt-md">
          <Button title="Save" htmlButtonType="submit" bg="primary" />
          <Button title="Cancel" bg="subtle" onClick={() => onModalClose()} />
        </div>
      </div>
    </FormGroup>
  );
}

export default EmployeeEditForm;
