import RouteEnum from "constants/routeEnum";
import techo from "assets/images/icons/tachometer-alt-fast.svg";
import scroll from "assets/images/icons/scroll.svg";

const {REACT_APP_IS_LEAVE_EMPLOYEE_MENU} = process.env;

const checkTempUserOrNot = (user) => {
  if (user && user.RoleName === "OnboardingEmployee") {
    return false;
  } else {
    return true;
  }
};

export const checkShowPayslip = (EmpDashboardShowPayslip) => {
  if (EmpDashboardShowPayslip) {
    return true;
  } else {
    return false;
  }
};

export default (user, EmpDashboardShowPayslip) => {
  const navigationContent = [
    {
      navId: 1,
      title: "Dashboard",
      link:
        user && user?.RoleName === "OnboardingEmployee"
          ? RouteEnum.EmployeeOnboarded
          : RouteEnum.EmployeeDashBoard,
      feature: "CoreHR",
      env: true,
      tempUser: true,
      showPayslip: true,
    },
    {
      navId: 2,
      title: "Personal Information",
      link: RouteEnum.PersonalInfo,
      feature: "CoreHR",
      env: true,
      tempUser: true,
      showPayslip: true,
    },
    {
      navId: 3,
      title: "Travel",
      link: RouteEnum.TeamTravelRequestPath,
      feature: "TravelManagement",
      env: true,
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 5,
      title: "Job Information",
      link: RouteEnum.JobInfo,
      feature: "JobProfile",
      env: true,
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 6,
      title: "Payroll",
      link: RouteEnum.EmployeePayroll,
      feature: "CoreHR",
      env: true,
      tempUser: checkTempUserOrNot(user),
      showPayslip: checkShowPayslip(EmpDashboardShowPayslip),
    },
    {
      navId: 7,
      title: "Leaves",
      link: RouteEnum.EmployeeLeavesPath,
      feature: "LeaveAndTime",
      env:
        REACT_APP_IS_LEAVE_EMPLOYEE_MENU === "true" ||
        REACT_APP_IS_LEAVE_EMPLOYEE_MENU !== "false",
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 8,
      title: "Time",
      link: RouteEnum.EmployeeTimePath,
      feature: "LeaveAndTime",
      env:
        REACT_APP_IS_LEAVE_EMPLOYEE_MENU === "true" ||
        REACT_APP_IS_LEAVE_EMPLOYEE_MENU !== "false",
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 9,
      title: "Reports",
      feature: "CoreHR",
      env: true,
      link: RouteEnum.EmployeeReportsPath,
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 12,
      title: "LMS",
      feature: "LMS",
      // isDrop:true,
      env: true,
      link: RouteEnum.lmsRoutes,
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 10,
      title: "Task Tracker",
      feature: "TaskTracker",
      isDrop: true,
      env: true,
      links: [
        {
          title: "Activity Dashboard",
          link: RouteEnum.ActivityDashboardPath,
          icon: techo,
        },
        {
          title: "Activity Report",
          link: RouteEnum.ActivityReportPath,
          icon: scroll,
        },
      ],
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 11,
      title: "Task Management",
      feature: "TaskManagement",
      isDrop: true,
      env: true,
      links: [
        {
          title: "Task Board",
          link: RouteEnum.TaskBoardListLinks,
          icon: scroll,
        },
        {
          title: "Things to Do",
          link: RouteEnum.TaskBoardTodo,
          icon: scroll,
        },
      ],
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
    {
      navId: 12,
      title: "Other",
      feature: "CoreHR",
      isDrop: false,
      env: true,
      link: RouteEnum.EmployeeOtherMenu,
      tempUser: checkTempUserOrNot(user),
      showPayslip: true,
    },
  ];
  return {navigationContent};
};
