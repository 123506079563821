import React, {useEffect, useState} from "react";
import WorkHoursTable from "./workHoursTable";
import WorkHoursFilter from "./workHoursFilter";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {getWorkingHours} from "../helpers/action";

function WorkHours(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    daterange: {startDate: null, endDate: null},
    location: null,
    search: null,
    filter: null,
    showAll: 1,
    pageIndex: 0,
    rowsPerPage: 20,
  });
  const {employeeWorkHours, exportEmployeeWorkHours} = useSelector(
    (state: any) => state.roosterReducer
  );
  const onChange = (name, value) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    getWorkingHoursS(state);
  }, []);

  const onUpdate = () => {
    let nextState = {...state};
    nextState["pageIndex"] = 0;
    setState(nextState);
    getWorkingHoursS(nextState);
  };

  const handleExcelExport = async () => {
    await getWorkingHoursS(state, true);
  };
  const handleRowChange = async (num) => {
    let nextState = {...state};
    nextState["pageIndex"] = 0;
    nextState["rowsPerPage"] = num;
    setState(nextState);
    getWorkingHoursS(nextState);
  };
  const handleIndexChange = (num) => {
    let nextState = {...state};
    nextState["pageIndex"] = num;
    setState(nextState);
    getWorkingHoursS(nextState);
  };

  const getWorkingHoursS = async (data, isExcelExport = false) => {
    var param: any = {
      fromDate: data.daterange.startDate,
      toDate: data.daterange.endDate,
      employee: data.search,
      PageIndex: isExcelExport ? 0 : data.pageIndex,
      PageSize: isExcelExport ? 999999999 : data.rowsPerPage,
    };
    const showAll: any = data.showAll;
    if (showAll && showAll.value === 2) {
      param.filterByOT = true;
    }

    await dispatch(getWorkingHours(param, isExcelExport));
  };

  return (
    <div className="workHours">
      <div className="shifts-title">Work Hours</div>
      <WorkHoursFilter
        onChange={onChange}
        onUpdate={onUpdate}
        state={state}
        exportData={exportEmployeeWorkHours}
        handleExcelExport={handleExcelExport}
      />
      <WorkHoursTable
        data={employeeWorkHours}
        handleRowChange={handleRowChange}
        handleIndexChange={handleIndexChange}
        pageIndex={state.pageIndex}
        rowsPerPage={state.rowsPerPage}
      />
    </div>
  );
}

export default WorkHours;
