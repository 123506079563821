import React, {useEffect, useState} from "react";
import {Tabs, Tab} from "components/tabs/index.d";
import CommonView from "./commonView";

import {getBoardsDetails} from "../helpers/action";
import "./styles.scss";
import {useDispatch} from "react-redux";
import {getBranches, getDepartments} from "components/dataselect/action";
import useDebounce from "helpers/customHooks/useDebounce";
import isEmpty from "helpers/isEmpty";

const initialFilter = {
  search: "",
  branchId: null,
  departmentId: null,
  owner: "",
  dl: null,
  pageIndex: 0,
  pageSize: 10,
  startDate: "",
  endDate: "",
  sortDir: "",
  sortExpr: "",
};

const tabs = [
  {id: 1, name: "active_tasks", label: "Active Tasks"},
  {id: 2, name: "completed_tasks", label: "Completed Tasks"},
  {id: 3, name: "archive", label: "Archive"},
];

function Reports() {
  const [filter, setFilter] = React.useState({
    active_tasks: {...initialFilter},
    completed_tasks: {...initialFilter},
    archive: {...initialFilter},
  });
  const [state, setState] = React.useState({isLoading: false, activeTab: tabs[0]});
  //const [activeTab, setActivetab] = React.useState(tabs[0]);
  const {activeTab, isLoading} = state;

  const [tableData, setTableData] = useState({
    active_tasks: [],
    completed_tasks: [],
    archive: [],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranches());
    dispatch(getDepartments());
    getBords(getActiveTabFilter(), activeTab);
  }, []);

  const handleTabChange = (tab) => setState({...state, activeTab: tab});
  const getActiveTabData = () => tableData[activeTab.name];
  const getActiveTabFilter = () => filter[activeTab.name];
  const setActiveTabFilter = (data: any) =>
    setFilter({...filter, [activeTab.name]: data});

  const setActiveTabTableData = (data: any) =>
    setTableData({...tableData, [activeTab.name]: data});

  const handleRowChange = (num) => {
    const param = {
      ...getActiveTabFilter(),
      pageSize: num,
      pageIndex: 0,
    };
    setFilter((prev) => ({...prev, [activeTab.name]: param}));
    getBords(param, activeTab);
  };

  const handleIndexChange = (num) => {
    const param = {
      ...getActiveTabFilter(),
      pageIndex: num,
    };
    setFilter((prev) => ({...prev, [activeTab.name]: param}));
    getBords(param, activeTab);
  };
  const handleExcelExport = async () =>
    await getBords(getActiveTabFilter(), activeTab, true);

  const handleSort = async (props) => {
    const param = {
      ...getActiveTabFilter(),
      pageIndex: 0,
      sortDir: props.sortOrder,
      sortExpr: props.sortBy === "Name" ? "BoardName" : props.sortBy,
    };
    setFilter((prev) => ({...prev, [activeTab.name]: param}));
    await getBords(param, activeTab);
  };

  const handleSearchClicked = () => getBords(getActiveTabFilter(), activeTab);

  const getBords = async (filter, tab, isExcel = false) => {
    const param = {
      completed: tab.id === 2 || null,
      pageIndex: isExcel ? 0 : filter.pageIndex,
      pageSize: isExcel ? 9999999 : filter.pageSize,
      archived: tab.id === 3 || null,
      boardName: filter.search || "",
      owner: filter.owner,
      branchId: filter?.branchId ? filter?.branchId?.value : null,
      departmentId: filter?.departmentId ? filter?.departmentId?.value : null,
      overDue: filter.dl
        ? filter.dl.value === "2"
          ? true
          : filter.dl.value === "1"
          ? null
          : false
        : null,
      startDate: filter.startDate,
      endDate: filter.endDate,
      sortDir: filter.sortDir,
      sortExpr: filter.sortExpr,
    };
    setState({...state, isLoading: true});
    let list = await dispatch(getBoardsDetails(param, isExcel));
    setState({...state, isLoading: false});
    setActiveTabTableData(list);
  };

  return (
    <div className="admin">
      <div className="admin-container">
        <div className="admin-title">
          <h1>Task Summary</h1>
        </div>
        <div className="tasksReport-tabs">
          <Tabs noBackground>
            {tabs.map((tab) => (
              <Tab
                label={tab.label}
                name={tab.name}
                id={tab.id}
                key={tab.id}
                isActive={activeTab.id === tab.id}
                onClick={() => handleTabChange(tab)}
              >
                <CommonView
                  activeTab={activeTab}
                  isLoading={isLoading}
                  tableData={getActiveTabData() || []}
                  setActiveTabTableData={setActiveTabTableData}
                  filter={getActiveTabFilter() || {}}
                  setActiveTabFilter={setActiveTabFilter}
                  isComplete={activeTab.id === 2}
                  isArchive={activeTab.id === 3}
                  exportName="export"
                  exportData={[]}
                  handleRowChange={handleRowChange}
                  handleIndexChange={handleIndexChange}
                  handleExcelExport={handleExcelExport}
                  handleSearchClicked={handleSearchClicked}
                  handleSort={handleSort}
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Reports;
