import React, {useEffect} from 'react'
import { useDispatch, useSelector  } from 'react-redux';
import {assignJobDescriptionHistory} from '../../action';
import {
    Date_Format
} from 'constants/dateFormat';

function History({data}) {
    const dispatch = useDispatch();
    const {assignJobDescriptionHistoryData} = useSelector((state:any)=>state.employeeProfile)
    useEffect(()=>{
        if(data){
            console.log("hob discription history", data)
            if(data.JobDescriptionId){
                dispatch(assignJobDescriptionHistory(data.JobDescriptionId))
            }
        }
        
    },[]);
    return (
        <div className="reporting-history">
            <HistoryBlock title="Assigned" data={assignJobDescriptionHistoryData.AssignedJobDescription} />
            <HistoryBlock title="Changed" data={assignJobDescriptionHistoryData.JobDescriptionChanged} />
            <HistoryBlock title="KSA Added" data={assignJobDescriptionHistoryData.JobDescriptionKsaAdded} />
            <HistoryBlock title="KSA Removed" data={assignJobDescriptionHistoryData.JobDescriptionKsaRemoved} />
        </div>
    )
}


const HistoryBlock = ({ title, data }) =>{
    return(
        <div className="reporting-history__block">
            <div className="reporting-history__title">
                <h4>{title}</h4>
            </div>
            {
                data && data.length > 0 ? data.map(item=>{
                    return <HistoryRow data={item} key={item.position} />
                }) 
                :
                <span className="no-data">No data</span>
            }
        </div>
    )
}

const HistoryRow = ({ data }) =>{
    return(
        <div className="reporting-history__row">
            <div className="reporting-history__col">
                {Date_Format(data.AssignedDate)}
            </div>
            <div className="reporting-history__col">
                {data.JdName}
            </div>
            <div className="reporting-history__col">
                {data.AssignedBy}
            </div>
            <div className="reporting-history__col">
                {data.EmployeeName}
            </div>
        </div>
    )
}


export default History
