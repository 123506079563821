import React from "react";
import FormGroup from "components/form";
import Button from "components/button";
import Modal from "components/modal";
import {connect} from "react-redux";
import Form from "components/form";
import SalaryReview from "./salaryReview";
import {objectToFormData} from "object-to-formdata";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  GetPayrollIncomeHeads,
  DisplayClientSideMessage,
  GetHiringMethods,
  GetJobStatus,
} from "actions/commonAction";
import moment from "moment";
import isEmpty from "isEmpty";
import {InputValidator} from "helpers/inputValidator";
import {getConvertedDate} from "helpers/getConvertedDate";
import {
  GetAllSalaryTemplate,
  GetSalaryTemplateDetails,
  GetDistributedSalary,
} from "../../../settings/salaryTemplate/salaryTemplateAction";
import {
  SaveOnboardingEmployee,
  UpdateOnboardingEmployee,
  SaveEmployeeSalaryTemplate,
  GetOnboardingEmployeeDetail,
  GetEmployeeSalaryTemplateDetails,
} from "../action";
import {UploadProfile} from "./uploadProfilePic";
import {BS2AD, AD2BS} from "helpers/dateConvert";
import {Date_Format_Reverse} from "constants/dateFormat";
import "./style.scss";
import {changeProfileImage} from "actions/authAction";
import Loaders from "components/loaders";

const titleOptions = [
  {label: "Mr", value: "Mr"},
  {label: "Miss", value: "Miss"},
  {label: "Mrs", value: "Mrs"},
];

class OnboardingForm extends React.Component {
  state = {
    employeeTitle: null,
    salaryReview: false,
    FirstName: null,
    MiddleName: null,
    LastName: null,
    Mobile: null,
    salaryTemplate: null,
    Designation: null,
    Branch: null,
    Department: null,
    PersonalEmail: null,
    OfficeEmail: null,
    HiringMethod: null,
    JobStatus: null,
    AppointmentDate: "",
    includePackage: false,
    grossPay: 0,
    files: [],
    IsFileEdit: true,
    isEditPage: false,
    EmployeeId: 0,
    totalGrossPay: 0,
    EmpOnBoardId: 0,
    Stage: 1,
    recalculateSalary: false,
    salaryTemplateAvail: false,
    salaryReviewed: false,
  };

  async componentDidMount() {
    await this.props.GetAllSalaryTemplate();

    if (!this.props.payrollIncomeHeads) {
      await this.props.GetPayrollIncomeHeads();
    }
    await this.props.GetHiringMethods();
    await this.props.GetJobStatus();

    let {
      departmentList: departmentList,
      branchList: branchList,
      designationList: designationList,
      companyInformation,
      salaryTemplateList: salaryTemplateList,
      isEditPage,
      onboardingEmployeeDetail: onboardingEmployeeDetail,
      match,
      hiringMethodLists: hiringMethodLists,
      jobStatusList: jobStatusList,
    } = this.props;
    const {id} = match.params;
    if (isEmpty(branchList)) {
      await this.props.GetBranches();
      branchList = this.props.branchList;
    }
    if (isEmpty(designationList)) {
      await this.props.GetDesignations();
      designationList = this.props.designationList;
    }
    if (isEmpty(departmentList)) {
      await this.props.GetDepartments();
      departmentList = this.props.departmentList;
    }

    if (id) await this.props.GetOnboardingEmployeeDetail(id);
    onboardingEmployeeDetail = this.props.onboardingEmployeeDetail;

    if (salaryTemplateList) {
      this.setState({salaryTemplates: salaryTemplateList});
    }
    if (isEditPage || id) {
      await this.props.GetEmployeeSalaryTemplateDetails(id);
      if (Object.keys(onboardingEmployeeDetail).length > 0) {
        const {
          EmployeeDto: {
            Salutation,
            FirstName,
            MiddleName,
            LastName,
            BranchId,
            DepartmentId,
            DesignationId,
          },
          EmployeePhotoDto: {Photo},
          AddressDto: {Mobile, PersonalEmail, OfficeEmail},
          EmployeeOnBoardingDto: {
            SalaryTemplateId,
            AppointmentDate,
            PayAmount,
            HiringSourceId,
            StatusId,
            EmpOnBoardId,
            Stage,
          },
          EmployeeOnBoardingDto,
          Attachment,
        } = onboardingEmployeeDetail;

        let Department = departmentList?.find((d) => d.value == DepartmentId);
        let HiringMethod = hiringMethodLists.find((d) => d.value == HiringSourceId);
        let JobStatus = jobStatusList.find((d) => d.value == StatusId);
        let Branch = branchList.find((d) => d.value == BranchId);
        let Designation = designationList.find((d) => d.value == DesignationId);
        let salaryTemplate = salaryTemplateList.find((d) => d.value == SalaryTemplateId);
        let previewImage = `${Photo}`;

        await this.props.GetSalaryTemplateDetails(SalaryTemplateId);

        const {salaryTemplateDetail, employeeSalaryTemplateDetails} = this.props;
        let isPayGross =
          salaryTemplateDetail?.SalaryTemplateDto &&
          salaryTemplateDetail?.SalaryTemplateDto.IsOnGrossDistribution;
        let grossPay =
          salaryTemplateDetail?.SalaryTemplateDetailDto &&
          salaryTemplateDetail?.SalaryTemplateDetailDto.find(
            (sal) => sal.Abbreviation == "BS"
          );
        let totalPercentage = 0;
        let salaryArray = [];
        let salaryTemplateAvail = false;

        if (employeeSalaryTemplateDetails && employeeSalaryTemplateDetails.length > 0) {
          salaryTemplateAvail = true;
          employeeSalaryTemplateDetails.forEach((sal) => {
            totalPercentage = parseFloat(totalPercentage) + parseFloat(sal.Percentage);
            salaryArray.push({
              ...sal,
              Percentages: sal.Percentage,
            });
          });
        } else {
          salaryArray =
            salaryTemplateDetail.SalaryTemplateDetailDto &&
            salaryTemplateDetail.SalaryTemplateDetailDto.map((sal) => {
              totalPercentage = parseFloat(totalPercentage) + parseFloat(sal.Percentage);
              return {
                ...sal,
                Percentages: sal.Percentage,
              };
            });
        }

        grossPay = grossPay?.Amount;

        if (isPayGross)
          grossPay =
            salaryTemplateDetail.SalaryTemplateDto &&
            salaryTemplateDetail.SalaryTemplateDto.GrossPay;

        if (PayAmount > 0) {
          grossPay = PayAmount;
        }

        let distributedIncomeByAmount = {
          SalaryTemplateDetailDto: salaryArray,
          TotalAmount: grossPay,
        };

        let employeeInfo = {
          employeeTitle: Salutation,
          FirstName,
          Branch,
          Designation,
          Department,
          Mobile,
          LastName,
          MiddleName,
          HiringMethod,
          JobStatus,
          AppointmentDate: moment(AppointmentDate).format("YYYY/MM/DD"),
          includePackage: false,
          salaryTemplate,
          PersonalEmail,
          OfficeEmail,
          grossPay,
          totalGrossPay: this.currencyCommaSeperator(Math.floor(PayAmount).toFixed(2)),
          files: [],
          IsFileEdit: false,
          isEditPage: true,
          EmployeeId: id,
          EmpOnBoardId,
          Attachment,
          previewImage,
          Stage,
          distributedIncomeByAmount,
          totalPercentage,
          EmployeeOnBoardingDto,
          salaryTemplateAvail,
        };

        this.setState({...employeeInfo});
      }
    }
  }

  handleFieldChange = async (name, value, onClose) => {
    if (name === "salaryTemplate" && !isEmpty(value)) {
      if (onClose) await this.props.GetSalaryTemplateDetails(value.value);
      this.setState(
        {[name]: value, grossPay: value.GrossPay, salaryTemplateAvail: false},
        async () => {
          if (onClose) {
            let distributedIncomeByAmount = {
              SalaryTemplateDetailDto:
                this.props.salaryTemplateDetail.SalaryTemplateDetailDto,
            };
            this.setState({distributedIncomeByAmount}, () => {
              this.getSalaryDistribution(onClose);
            });
          }
          this.updateErrors();
        }
      );
    } else
      this.setState({[name]: value}, () => {
        this.updateErrors();
      });
  };

  currencyCommaSeperator = (num) => {
    if (isNaN(num)) return "0";
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  getSalaryDistribution = async (onClose) => {
    const {grossPay} = this.state;
    const {
      salaryTemplateDetail: {SalaryTemplateDto},
      GetDistributedSalary,
    } = this.props;

    const {
      distributedIncomeByAmount: {SalaryTemplateDetailDto},
    } = this.state;

    let data = {
      payAmount: grossPay,
      isGrossPay: SalaryTemplateDto?.IsOnGrossDistribution,
    };
    let isAnyZeroPercentage = false;
    if (SalaryTemplateDetailDto) {
      data.salaryArray = SalaryTemplateDetailDto.map((sal) => {
        // if (sal.Percentages == 0) isAnyZeroPercentage = true;
        return {
          ...sal,
          Percentage: sal.Percentages ? sal.Percentages : sal.Percentage,
          Percentages: sal.Percentages ? sal.Percentages : sal.Percentage,
        };
      });
    }
    if (isAnyZeroPercentage) {
      this.props.DisplayClientSideMessage("Adjust income percentage with non-zero value");
      return;
    }
    // if (isEditPage)
    await this.props.GetPayrollIncomeHeads();
    await GetDistributedSalary(data);
    const {distributedIncomeByAmount, payrollIncomeHeads} = this.props;
    let totalPercentage = 0;
    let newSalDist = [];
    if (!onClose) {
      newSalDist = SalaryTemplateDetailDto;
    } else if (distributedIncomeByAmount) {
      SalaryTemplateDetailDto.forEach((sal) => {
        payrollIncomeHeads.forEach((pay) => {
          if (pay.IncomeId == sal.IncomeId) {
            distributedIncomeByAmount.SalaryTemplateDetailDto.forEach((dis) => {
              if (sal.IncomeId == dis.IncomeId) {
                let salDist = {
                  ...sal,
                  Amount: dis.Amount?.toFixed(2),
                  ParentIncomeId: pay.ParentIncomeId,
                  Percentages: dis.Percentage?.toFixed(2),
                  Type: pay.Type,
                };
                totalPercentage =
                  parseFloat(totalPercentage) + parseFloat(dis.Percentage);
                newSalDist.push(salDist);
              }
            });
          }
        });
      });
    }
    let salaryData = {
      TotalAmount: !onClose
        ? this.state.distributedIncomeByAmount.TotalAmount
        : distributedIncomeByAmount.TotalAmount,
      SalaryTemplateDetailDto: newSalDist,
    };
    let totalGrossPay = this.currencyCommaSeperator(
      Math.floor(distributedIncomeByAmount.TotalAmount).toFixed(2)
    );
    this.setState({
      totalGrossPay,
      distributedIncomeByAmount: salaryData,
      totalPercentage,
    });
  };

  onCheckChange = (e) => {
    this.setState({includePackage: e.target.checked});
  };

  onClose = async () => {
    const {
      salaryReviewed,
      salaryTemplateAvail,
      distributedIncomeByAmount,
      salaryTemplates,
    } = this.state;

    let totalGrossPay = this.currencyCommaSeperator(
      Math.floor(distributedIncomeByAmount.TotalAmount).toFixed(2)
    );

    let userSalaryTemplate = salaryTemplates.find(
      (sal) =>
        sal.value ==
        this.props.onboardingEmployeeDetail?.EmployeeOnBoardingDto?.SalaryTemplateId
    );

    if (salaryReviewed) {
      this.setState({totalGrossPay, salaryReview: false});
    } else {
      if (salaryTemplateAvail)
        this.setState({
          totalGrossPay,
          salaryReview: false,
          salaryTemplate: userSalaryTemplate,
          TotalAmount: distributedIncomeByAmount.TotalAmount,
        });
      this.setState({totalGrossPay, salaryReview: false});
    }
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  handleSave = async () => {
    const {
      employeeTitle,
      FirstName,
      Branch,
      Designation,
      Department,
      Mobile,
      EmployeeId,
      LastName,
      MiddleName,
      HiringMethod,
      JobStatus,
      AppointmentDate,
      includePackage,
      salaryTemplate,
      PersonalEmail,
      OfficeEmail,
      files,
      IsFileEdit,
      isEditPage,
      totalGrossPay,
      EmpOnBoardId,
      Attachment,
      Stage,
      EmployeeOnBoardingDto,
      cropFile,
    } = this.state;

    let errors = await InputValidator(document, this.state);

    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }

    let data = {
      employeeDto: {
        EmployeeId,
        salutation: employeeTitle,
        FirstName: FirstName.replace(/\s/g, ""),
        MiddleName: MiddleName ? MiddleName.replace(/\s/g, "") : MiddleName,
        LastName: LastName.replace(/\s/g, ""),
        BranchId: parseInt(Branch.value),
        DepartmentId: parseInt(Department.value),
        DesignationId: parseInt(Designation.value),
        Name:
          FirstName.replace(/\s/g, "") +
          " " +
          (MiddleName ? MiddleName.replace(/\s/g, "") + " " : "") +
          LastName.replace(/\s/g, ""),
      },
      employeePhotoDto: {
        EmployeeId,
      },
      addressDto: {
        EmployeeId,
        Mobile,
        PersonalEmail,
        OfficeEmail,
      },
      employeeOnBoardingDto: {
        IsIncludePackage: includePackage ? false : true,
      },
      IsFileEdit,
    };

    if (isEditPage) {
      data.employeeOnBoardingDto = {
        ...EmployeeOnBoardingDto,
      };
      data.Attachment = Attachment;
    }
    data.employeeOnBoardingDto = {
      ...data.employeeOnBoardingDto,
      EmpOnBoardId,
      EmployeeId,
      Stage,
      HiringSourceId: HiringMethod.value,
      StatusId: JobStatus.value,
      AppointmentDate,
      SalaryTemplateId: parseInt(salaryTemplate?.value),
      PayAmount: parseInt(totalGrossPay.toString().replace(/,/g, "")),
    };
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };
    let formData = objectToFormData(data, options);

    // console.log({formData, data, convertedSelectedDate});

    if (files) formData.append(`Files`, files[0] ? files[0] : []);

    if (isEditPage) await this.props.UpdateOnboardingEmployee(formData);
    else await this.props.SaveOnboardingEmployee(formData);
    const {saveOnboardingEmployee, GetEmployeeOnboardingList} = this.props;
    if (!includePackage && saveOnboardingEmployee && !isEditPage) {
      delete data.files;
      delete data.attachment;
      delete data.employeePhotoDto;
      if (Object.keys(saveOnboardingEmployee).length > 0) {
        const {
          saveOnboardingEmployee: {
            EmployeeDto: {EmployeeId},
          },
        } = this.props;
        await this.changeProfileImage(EmployeeId, files, cropFile);
        this.props.history.push(`/admin/employee_onboarding/packages/${EmployeeId}`);
      }
    } else {
      if (Object.keys(saveOnboardingEmployee).length > 0) {
        const {
          saveOnboardingEmployee: {
            EmployeeDto: {EmployeeId},
          },
        } = this.props;
        await this.changeProfileImage(EmployeeId, files, cropFile);
        this.props.history.push("/admin/employee_onboarding");
      }
    }
  };

  changeProfileImage = (EmployeeId, files, cropFile) => {
    const picformData = new FormData();
    picformData.append("EmployeeId", `${EmployeeId}`);
    picformData.append("OriginalFile", files[0]);
    // const cropFile = await getFileImage();
    picformData.append("CroppedFile", cropFile);

    this.props.changeProfileImage(picformData, true);
  };

  uploadProfileImageToParent = (data) => this.setState({files: data});
  uploadCroppedImageImageToParent = (data) => this.setState({cropFile: data});

  openSalaryReviewModal = async () => {
    if (!this.state.salaryTemplateAvail)
      await this.props.GetSalaryTemplateDetails(this.state.salaryTemplate?.value);
    await this.getSalaryDistribution(true);
    this.setState({salaryReview: true});
  };

  changeDistributionPercentage = async (name, value, dist) => {
    let {
      distributedIncomeByAmount: {SalaryTemplateDetailDto, TotalAmount},
    } = this.state;
    let selectedSalary = SalaryTemplateDetailDto.find((s) => s.IncomeId == dist.IncomeId);
    selectedSalary["Percentages"] = value ? parseFloat(value) : "";

    let totalPercentage = 0;
    let coDependentId = [];
    let basicPercent;
    await SalaryTemplateDetailDto.forEach((sal) => {
      if (sal.IncomeId == dist.ParentIncomeId && dist.ParentIncomeId !== 0)
        coDependentId.push(sal.IncomeId);
      if (sal.Abbreviation == "BS" || sal.Abbreviation == "NB")
        basicPercent = sal.Percentages;
    });
    if (dist.Abbreviation == "BS" || dist.Abbreviation == "NB") {
      let calcPercent;
      SalaryTemplateDetailDto = SalaryTemplateDetailDto.map((sal) => {
        if (sal.Type !== "Fixed") {
          let calcPercent = sal.Percentage;

          SalaryTemplateDetailDto.forEach((s) => {
            if (s.ParentIncomeId == sal.IncomeId) {
              calcPercent += parseFloat(s.Percentages);
            }
          });
          return {
            ...sal,
            Percentages: parseFloat(
              ((calcPercent * parseFloat(value)) / 100).toString()
            ).toFixed(2),
          };
        } else {
          return sal;
        }
      });
    } else {
      let calcDependentPercent = 0;
      SalaryTemplateDetailDto.find((s) => {
        if (coDependentId.includes(s.IncomeId)) {
          calcDependentPercent += s.Percentage;
        }
      });
      SalaryTemplateDetailDto = SalaryTemplateDetailDto.map((sal) => {
        if (coDependentId.includes(sal.IncomeId)) {
          return {
            ...sal,
            Percentages:
              parseFloat(
                (
                  ((calcDependentPercent + parseFloat(value)) * basicPercent) /
                  100
                ).toString()
              ).toFixed(2) || 0,
          };
        } else {
          return sal;
        }
      });
    }
    SalaryTemplateDetailDto.forEach((s) => {
      totalPercentage = totalPercentage + (s.Percentages ? parseFloat(s.Percentages) : 0);
    });
    let salaryData = {
      SalaryTemplateDetailDto,
      TotalAmount,
    };
    // console.log("sss", salaryData, SalaryTemplateDetailDto);
    this.setState({
      distributedIncomeByAmount: salaryData,
      distributionChanged: true,
      SalaryTemplateDetailDto,
      totalPercentage: Math.round(totalPercentage * 100) / 100,
    });
  };

  submitEmployeeSalaryStructure = async (isGrossPay) => {
    const {distributedIncomeByAmount, EmployeeId, EmpOnBoardId} = this.state;
    const {match} = this.props;
    let totalPercentage = 0;
    let employeeSalStructure = distributedIncomeByAmount.SalaryTemplateDetailDto.map(
      (sal) => {
        totalPercentage = parseFloat(totalPercentage) + parseFloat(sal.Percentages);
        return {
          Amount: parseInt(sal.Amount),
          EmployeeId,
          IncomeId: sal.IncomeId,
          OnBoardingRefId: EmpOnBoardId,
          Percentage: sal.Percentages,
        };
      }
    );

    if (parseInt(totalPercentage) != 100 && isGrossPay) {
      this.props.DisplayClientSideMessage("Adjust income percentage before saving");
    } else {
      const {id} = match.params;
      if (id) {
        await this.props.SaveEmployeeSalaryTemplate(employeeSalStructure);
      }
      this.setState({
        salaryReview: false,
        salaryReviewed: true,
        salaryTemplateAvail: true,
      });
    }
  };

  render() {
    const {
      AppointmentDate,
      Branch,
      Department,
      Designation,
      distributedIncomeByAmount,
      employeeTitle,
      errors,
      files,
      FirstName,
      grossPay,
      HiringMethod,
      JobStatus,
      includePackage,
      isEditPage,
      LastName,
      MiddleName,
      Mobile,
      OfficeEmail,
      PersonalEmail,
      previewImage,
      salaryTemplate,
      salaryTemplates,
      salaryReview,
      recalculateSalary,
      totalGrossPay,
      totalPercentage,
    } = this.state;

    const {
      branchList,
      companyInformation,
      departmentList,
      designationList,
      DisplayClientSideMessage,
      GetSalaryTemplateDetails,
      hiringMethodLists,
      jobStatusList,
      requesting,
      salaryTemplateDetail,
      salaryTemplateDetailRequesting,
    } = this.props;
    return (
      <div className="employee-onboarding">
        <div className="employee-onboarding-header borderbtm commonPd">
          Employee Onboarding
        </div>
        <div className="employee-onboarding-introduction onboardTitle borderbtm commonPd">
          INTRODUCTION
        </div>
        <div className="employee-onboarding-info borderbtm">
          <div className="employee-onboarding-info employee-onboarding-info-title commonPd">
            <FormGroup
              formName="chipsinput"
              name={"employeeTitle"}
              alignment="horizontal"
              chipsArray={titleOptions}
              value={employeeTitle}
              onChange={(name, value) => this.handleFieldChange(name, value.value)}
              badgeColor="green"
              validators={["required"]}
              error={errors && errors.employeeTitle}
            />
          </div>
          <div className="employee-onboarding-info employee-onboarding-info-name flex commonPd">
            <FormGroup
              name="FirstName"
              value={FirstName}
              onChange={this.handleFieldChange}
              label="First Name"
              error={errors?.FirstName}
              width="205px"
              validators={["required"]}
            />
            <FormGroup
              name="MiddleName"
              value={MiddleName}
              onChange={this.handleFieldChange}
              label="Middle Name"
              width="205px"
            />
            <FormGroup
              name="LastName"
              value={LastName}
              onChange={this.handleFieldChange}
              label="Last Name"
              error={errors?.LastName}
              width="205px"
              validators={["required"]}
            />
          </div>
          <div className="employee-onboarding-info employee-onboarding-photo">
            <UploadProfile
              previewImage={previewImage}
              isNotModal
              EmployeeId={1}
              uploadProfileImageToParent={(data) => this.uploadProfileImageToParent(data)}
              uploadCroppedImageImageToParent={(data) =>
                this.uploadCroppedImageImageToParent(data)
              }
              uploadOnly={true}
              image={files}
              isCropped={true}
            />
          </div>
          <div className="employee-onboarding-info onboardTitle commonPd">
            JOB INFORMATION
          </div>
        </div>
        <div className="employee-onboarding-job borderbtm">
          <div className="employee-onboarding-job-hiring flex commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="HiringMethod"
              value={HiringMethod}
              options={hiringMethodLists}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.HiringMethod}
              width="425px"
              label="How the employee was hired"
            />
            <Form
              enableOkButton
              error={errors?.AppointmentDate}
              formName="customdateinput"
              // isNepali={!companyInformation?.IsEnglishDate}
              // isTextInNepali={false}
              label="Appointment date"
              name="AppointmentDate"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              placeholder="Date.."
              validators={["required"]}
              value={AppointmentDate}
              width="205px"
            />
          </div>
          <div className="employee-onboarding-job-hiring commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="JobStatus"
              value={JobStatus}
              options={jobStatusList}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.JobStatus}
              width="425px"
              label="Job Status"
            />
          </div>
          <div className="employee-onboarding-job-placement onboardTitle commonPd">
            PLACEMENT
          </div>
        </div>
        <div className="employee-onboarding-placement borderbtm">
          <div className="employee-onboarding-placement-branch flex">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="Branch"
              value={Branch}
              options={branchList}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.Branch}
              width="205px"
              label="Branch"
            />
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="Department"
              value={Department}
              options={departmentList}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.Department}
              width="205px"
              label="Department"
            />
          </div>
          <div className="employee-onboarding-placement-position">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value)}
              name="Designation"
              value={Designation}
              options={designationList}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.Designation}
              width="425px"
              label="Job Position"
            />
          </div>

          <div className="employee-onboarding-info onboardTitle mt-40">
            SALARY AND COMPENSATION
          </div>
        </div>
        <div className="employee-onboarding-info borderbtm">
          <div className="employee-onboarding-info flex commonPd">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => this.handleFieldChange(name, value, true)}
              name="salaryTemplate"
              value={salaryTemplate}
              options={salaryTemplates}
              loadingType="skeleton"
              placeholder="Select"
              validators={["required"]}
              error={errors?.salaryTemplate}
              width="425px"
              label="Salary template"
            />
            <div className="employee-onboarding-pay">
              <span>Total Pay</span>
              <span>Rs. {totalGrossPay}</span>
              <span>
                <Button
                  bg="white"
                  buttonClass="primary-color"
                  isDisabled={!salaryTemplate}
                  onClick={this.openSalaryReviewModal}
                  title="Review"
                />
              </span>
            </div>
          </div>
          <div className="employee-onboarding-info onboardTitle commonPd">CONTACT</div>
          <Modal
            open={salaryReview}
            onModalClose={this.onClose}
            title="Salary Review"
            className="salary-review__modal"
          >
            <SalaryReview
              onClose={this.onClose}
              grossPay={grossPay}
              templateId={salaryTemplate}
              GetSalaryTemplateDetails={GetSalaryTemplateDetails}
              salaryTemplateDetail={salaryTemplateDetail}
              distributedIncomeByAmount={distributedIncomeByAmount}
              changeGrossPay={(grossPay) => this.setState({grossPay})}
              getSalaryDistribution={this.getSalaryDistribution}
              changeDistributionPercentage={this.changeDistributionPercentage}
              SaveEmployeeSalaryTemplate={SaveEmployeeSalaryTemplate}
              DisplayClientSideMessage={DisplayClientSideMessage}
              recalculateSalary={recalculateSalary}
              totalPercentage={totalPercentage}
              changeRecalculated={(value) => this.setState({recalculateSalary: value})}
              submitEmployeeSalaryStructure={this.submitEmployeeSalaryStructure}
            />
          </Modal>
        </div>
        <div className="employee-onboarding-info borderbtm">
          <div
            className={`employee-onboarding-info employee-onboarding-info-name flex commonPd ${
              isEditPage ? "mb-md" : ""
            }`}
          >
            <FormGroup
              error={errors?.Mobile}
              label="Mobile Number"
              name="Mobile"
              onChange={this.handleFieldChange}
              type="number"
              validators={["required", "phone"]}
              value={Mobile}
              width="205px"
            />
            <FormGroup
              disabled={isEditPage}
              error={errors?.PersonalEmail}
              label="Personal email"
              name="PersonalEmail"
              onChange={this.handleFieldChange}
              validators={["required", "email"]}
              value={PersonalEmail}
              width="205px"
            />
            <FormGroup
              name="OfficeEmail"
              value={OfficeEmail}
              onChange={this.handleFieldChange}
              label="Office email"
              error={errors?.OfficeEmail}
              width="205px"
              validators={["email"]}
            />
          </div>
          {!isEditPage && (
            <div className="employee-onboarding-compensation flex commonPd">
              <FormGroup
                formName="checkgroup"
                name="includePackage"
                label="Do not Create Onboarding Package"
                value={includePackage}
                checked={includePackage}
                labelPosition="right"
                onChange={this.onCheckChange}
                className="orange-color"
              />
            </div>
          )}
        </div>
        <div className="employee-onboarding__footer flex justify-end commonPd flex-gap-10">
          <Button
            bg="highlight"
            title="Cancel"
            onClick={() => this.props.history.push("/admin/employee_onboarding")}
          />
          <Button
            bg="primary"
            title={isEditPage ? "Update" : "Save"}
            onClick={() => this.handleSave()}
            isDisabled={requesting}
          />
        </div>
        {/* {requesting || salaryTemplateDetailRequesting ? (
          <Loaders loading={requesting || salaryTemplateDetailRequesting} />
        ) : null} */}
      </div>
    );
  }
}

OnboardingForm = connect(
  (state) => ({
    departmentList: state.commonReducer.departmentList,
    branchList: state.commonReducer.branchList,
    designationList: state.commonReducer.designationList,
    salaryTemplateList: state.salaryTemplateReducer.salaryTemplateList,
    salaryTemplateDetail: state.salaryTemplateReducer.salaryTemplateDetail,
    distributedIncomeByAmount: state.salaryTemplateReducer.distributedIncomeByAmount,
    saveOnboardingEmployee: state.employeeOnboarding.saveOnboardingEmployee,
    onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
    employeeSalaryTemplateDetails: state.employeeOnboarding.employeeSalaryTemplateDetails,
    companyInformation: state.commonReducer.companyInformation,
    payrollIncomeHeads: state.commonReducer.payrollIncomeHeads,
    hiringMethodLists: state.commonReducer.hiringMethodLists,
    jobStatusList: state.commonReducer.jobStatusList,
    requesting: state.employeeOnboarding.requesting,
    salaryTemplateDetailRequesting:
      state.salaryTemplateReducer.salaryTemplateDetailRequesting,
  }),
  {
    GetBranches,
    GetDesignations,
    GetDepartments,
    GetAllSalaryTemplate,
    SaveOnboardingEmployee,
    GetSalaryTemplateDetails,
    GetDistributedSalary,
    UpdateOnboardingEmployee,
    GetPayrollIncomeHeads,
    SaveEmployeeSalaryTemplate,
    GetOnboardingEmployeeDetail,
    GetHiringMethods,
    GetJobStatus,
    DisplayClientSideMessage,
    GetEmployeeSalaryTemplateDetails,
    changeProfileImage,
  }
)(OnboardingForm);

export default OnboardingForm;
