import React from "react";
import {Link} from "react-router-dom";
import SvgIcons from "components/svgIcons";
import RouteEnum from "constants/routeEnum";
import {FaRegSmile} from "react-icons/fa";
import Loaders from "components/loaders";

const Todo = ({dashboardLoding, isAllRequestCountZero, RequestCount}) => {
  return (
    <div className="mypage-section">
      <div className="bloch__title">To Do</div>
      <div className="mypage-section-grid">
        {dashboardLoding ? (
          <Loaders
            name="card"
            type="skeleton"
            noRows={4}
            cardType="dashboard"
            loading={true}
          />
        ) : !isAllRequestCountZero ? (
          RequestCount?.map((req, index) => {
            if (req.Total === 0) return null;
            return (
              <Link
                to={{
                  pathname:
                    req.RequestType === "Travel"
                      ? RouteEnum.TeamTravelRequestPath
                      : req.RequestType === "Settlement"
                      ? RouteEnum.SettlementRequestPath
                      : RouteEnum.EmployeeRequestReviewPath,
                  state: {RequestType: req.RequestType},
                }}
                key={index}
              >
                <div className="mypage-section-block">
                  <div className="mypage-section-block-name">{req.Title}</div>
                  <div className="mypage-section-block-desc">
                    <div className="mypage-section-block-desc-icon">
                      {/* <Icons name={req.RequestType} size={40} /> */}
                      <SvgIcons
                        name={req.RequestType}
                        size={30}
                        className="request-icon" /*  styles={{"height": "30px"}}  */
                      />
                    </div>
                    <div className="mypage-section-block-desc-detail">
                      <div className="mypage-section-block-desc-detail-int">
                        {req.Total}
                      </div>
                      <div className="mypage-section-block-desc-detail-text">
                        requests
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <div className="no-found-container" style={{width: "190px"}}>
            <div className="flex-column content">
              <span className="">All clear</span>
              <span className="mt-xsm flex">
                No pending work <FaRegSmile className="ml-sm text-xbg " />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Todo;
