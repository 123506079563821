import Table from "components/customTable";
import dayjs from "dayjs";
import React from "react";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const DateCell = ({value}) => {
  return (
    <span>
      {`${dayjs(value).format("YYYY-MM-DD")}    ${dayjs(value).format("dddd")}`}
    </span>
  );
};

const PositionCell = ({value}) => {
  return (
    <span style={{cursor: "pointer"}} title={value}>
      {value}
    </span>
  );
};

function OffDayTable(props) {
  const {data} = props;
  const cols = [
    {
      Header: "Name",
      accessor: "Name",
      width: 240,
    },
    {
      Header: "Location",
      accessor: "Location",
      width: 140,
    },
    {
      Header: "Position",
      accessor: "Position",
      Cell: PositionCell,
      width: 140,
    },
    {
      Header: "Type",
      accessor: "Type",
      width: 100,
    },
    {
      Header: "Status",
      accessor: "Status",
      width: 140,
    },

    {
      Header: "Date",
      accessor: "FromDateEng",
      width: 200,
      Cell: DateCell,
    },
  ];
  return (
    <div>
      <Table isFixedWidth data={data} columns={cols} />
    </div>
  );
}

export default OffDayTable;
