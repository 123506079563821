import React from "react";
import isEmpty from "helpers/isEmpty";

import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import LinkCategories from "./linkCategories";
const Links = (props: any) => {
  const {activeFeaturesCategories} = props;
  const {feature, category} = props.match.params;

  if (isEmpty(feature) || isEmpty(category)) return null;

  const selectedFeature = activeFeaturesCategories.find(
    (x) => x.Feature.toLowerCase() === feature.toLowerCase()
  );
  if (isEmpty(selectedFeature)) return null;

  const categories =
    (selectedFeature &&
      selectedFeature.FeatureCategories.find((x) => x.Category == category)) ||
    [];

  return (
    <div>
      <LinkCategories
        baseUrl={props.baseUrl}
        title={selectedFeature.Title}
        categories={categories.LinkCategories}
        featureId={selectedFeature.FeatureId}
        category={category}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeFeaturesCategories: state.permissionReducer.activeFeaturesCategories,
});
const mapDispathcToProps = {};
export default withRouter(connect(mapStateToProps, mapDispathcToProps)(Links));
