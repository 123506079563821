import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import {unregister} from "./registerServiceWorker";
import environment from "environment";
import {createBrowserHistory} from "history";
import rootStore from "./store/configureStore";
import {Provider, useDispatch} from "react-redux";
import {refreshToken, logoutUser} from "actions/authAction";
import {clearLogoutLocalStorage} from "helpers/constants";
import {check_current_window_status} from "helpers/winTabStatus";
import {
  getFeatureFlag,
  getActiveFeatureFlag,
  activeFlags,
} from "actions/permissionsAction";
import {GetAppVersion} from "actions/commonAction";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

(async (window) => {
  const initialState = {};
  const history = createBrowserHistory<any>({basename: "/"});
  // const history = createBrowserHistory<any>({ basename: environment.route.baseRoute });
  // const store = rootStore(initialState, history);
  const store = rootStore;
  const rootEl = document.getElementById("root");

  // console.log(store,refresh_loading)
  if (localStorage.RefreshToken) {
    await store.dispatch(refreshToken());
    console.log("Refresh Token");
  }
  const promiseArray = [
    store?.dispatch(getActiveFeatureFlag()),
    store?.dispatch(GetAppVersion()),
  ];
  await Promise.all(promiseArray);

  // await store.dispatch(getModules())
  // React.useEffect(() => {
  //     (async () => {
  //         if(localStorage.RefreshToken){
  //             await store.dispatch(refreshToken());
  //         }
  //     })();
  // }, []);
  // if(localStorage.RefreshToken){
  //     await store.dispatch(refreshToken());
  // }
  // window.onbeforeunload = function (e) {

  //     window.sessionStorage.unloadTime = JSON.stringify(new Date());

  // };

  //     window.onload = async function () {

  //     let loadTime = new Date();
  //     let unloadTime = new Date(JSON.parse(window.sessionStorage.unloadTime));
  //     let refreshTime = loadTime.getTime() - unloadTime.getTime();
  //     console.log(refreshTime)
  //     if(refreshTime>5000)//3000 milliseconds
  //     {
  //         console.log(refreshTime)
  //         clearLogoutLocalStorage()
  //         //@ts-ignore
  //         // await store.dispatch(logoutUser());
  //     }else{
  // if(localStorage.RefreshToken){
  //     await store.dispatch(refreshToken());
  // }
  //     }

  // };
  // window.onunload = () => {
  //     if (window.localStorage) {
  //         // flag the page as being unloading
  //         window.localStorage['myUnloadEventFlag']=new Date().getTime();

  //     }
  //     const a = check_current_window_status('');
  //     console.log(a)
  //
  //     if(a.tab_len < 1){
  //
  //         // clearLogoutLocalStorage()
  //     }
  //  }

  //  window.onload = () =>{
  //     const a = check_current_window_status('');
  //
  //     if (window.localStorage) {
  //         var t0 = Number(window.localStorage['myUnloadEventFlag']);
  //         if (isNaN(t0)) t0=0;
  //         var t1=new Date().getTime();
  //         var duration=t1-t0;
  //         if (duration<10*1000) {
  //             // less than 10 seconds since the previous Unload event => it's a browser reload (so cancel the disconnection request)
  //             return;
  //         } else {
  //             // last unload event was for a tab/window close => do whatever you want (I do nothing here)
  //             if(a.tab_len < 1){
  //                 clearLogoutLocalStorage()
  //             }
  //         }
  //     }
  //  }
  const render = (Component, el) => {
    ReactDOM.render(
      <Provider store={store}>
        <Component history={history} dispatch={store?.dispatch} />
      </Provider>,
      el
    );
  };
  render(App, rootEl);
})(window);

// ReactDOM.render(<App />,document.getElementById('root'));

// registerServiceWorker();
unregister();
