import React from "react";
import CommonModuleLinks from "components/common/commonModuleLinks";
import Title from "scenes/common/title";
import RouteEnum from "constants/routeEnum";

export const modules = (features, EmpDashboardShowPayslip) => [
  {
    groupTitle: "Message",
    feature: features?.CoreHR,
    links: [
      {title: "Send Message", link: "CP/ComposeMessage.aspx"},
      {title: "Notice List", link: "cp/NoticeList.aspx"},
    ],
  },
  {
    groupTitle: "Travel Request",
    feature: features?.TravelManagement,
    links: [
      {
        title: "Travel Requests",
        link: "travelrequest/TravelRequestListingAdminView.aspx",
      },
      {title: "Allowance location", link: "CP/AllowanceLocation.aspx"},
    ],
  },
  {
    groupTitle: "New Travel Request",
    feature: features?.TravelManagement,
    links: [
      {
        title: "Allowance and Expenses",
        link: RouteEnum.TravelAllowanceExpensePath,
        isReact: true,
      },
      {title: "Travel Category", link: RouteEnum.TravelCategoryPath, isReact: true},
      {title: "Travel Requests", link: RouteEnum.TeamTravelRequestPath, isReact: true},
    ],
  },
  {
    groupTitle: "Recruitment",
    feature: features?.Recruitment,
    links: [
      // { title:'Requesition Request', link:'react/recruit/requistion_request'},
      {
        title: "Requisition Requests",
        link: RouteEnum.RequistionRequestPath,
        isReact: true,
      },
      // { title:'Dashboard', link:'react/recruit/dashboard'},
      {title: "Dashboard", link: RouteEnum.RecruitDashboardPath, isReact: true},
      // { title:'Job Listing', link:'react/recruit/job_listing'},
      {title: "Job Listing", link: RouteEnum.JobListing, isReact: true},
    ],
  },
  {
    groupTitle: "Overtime Allowance",
    feature: features?.CoreHR,
    links: [
      {title: "Overtime and Allowance Unit", link: "newhr/OvertimeAllowanceUnit.aspx"},
      {title: "Generate Overtime", link: "CP/overtimelist.aspx"},
      {title: "Overtime Requests", link: RouteEnum.OvertimeRequestPath, isReact: true},
      // { title:'Overtime Requests', link:'CP/AAForwardOvertime.aspx'},
      {title: "Allowance Request", link: RouteEnum.AllowanceRequestPath, isReact: true},
      // { title:'Allowance Request', link:'CP/AllowanceForward.aspx'},
      {title: "Children Allowances", link: "newhr/ChildrenAllowances.aspx"},
    ],
  },
  {
    groupTitle: "Training",
    feature: features?.CoreHR,
    links: [
      {title: "Create Training", link: "newhr/TrainingMod/TrainingPlanning.aspx"},
      {title: "Training Request Scale", link: "newhr/TrainingMod/TrRatingScale.aspx"},
      {title: "Training Template", link: "newhr/TrainingMod/TrTemplate.aspx"},
      {title: "Training Requests", link: "newhr/TrainingMod/TrainingAdministration.aspx"},
      {title: "Training Feedback", link: "newhr/TrainingMod/Trainingfeedback.aspx"},
      {title: "Training Employee", link: "newhr/TrainingMod/TrainingEmployee.aspx"},
      {
        title: "Training Attendance",
        link: "newhr/TrainingMod/TrainingEmpAttendance.aspx",
      },
    ],
  },
  {
    groupTitle: "Employee Approval",
    feature: features?.CoreHR,
    links: [
      {title: "Profile Change", link: "newhr/EmployeeProfileChange.aspx"},
      {title: "Photo Approval", link: "newhr/EmpIdentificationApproval.aspx"},
    ],
  },
  {
    groupTitle: "Seniority",
    feature: features?.CoreHR,
    links: [{title: "Set Seniority", link: "newhr/EmployeeSeniority.aspx"}],
  },
];

function CommonTasks(props) {
  return (
    <div className="admin-contain">
      {/* <Title name="HR Common Task" parentProps={props} /> */}
      <CommonModuleLinks
        baseUrl={props.baseUrl}
        title="Human Resource Common Task"
        localStorageTitle="HRCT"
        modules={modules}
      />
    </div>
  );
}

export default CommonTasks;
