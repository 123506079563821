import React, { useState, useEffect } from 'react'
import UserCard from './components/userCard';
import { useSelector } from 'react-redux';
import { Droppable, Draggable } from "react-beautiful-dnd";
import FormItem from 'components/form';
import Tabs from './Tabs';

function ScheduleSidebar({ teamId, isPrev }) {
    // const [employees, setEmployees] = useState([]);
    const [scheduledEmployeeList, setScheduledEmployeeList] = useState([]);
    const [notScheduledEmployeeList, setNotScheduledEmployeeList] = useState([]);
    const [search, setSearch] = useState("")
    const { scheduledEmployee, notScheduledEmployee, scheduleAllEmployeeListwithWorkingHours } = useSelector((state: any) => state.roosterReducer);
    const [activeTab, setActiveTab] = useState(0);
    
    useEffect(() => {
        if (scheduledEmployee) {
            setScheduledEmployeeList(scheduledEmployee);
        }
    }, [scheduledEmployee]);

    useEffect(() => {
        if (notScheduledEmployee) {
            setNotScheduledEmployeeList(notScheduledEmployee);
        }
    }, [notScheduledEmployee]);

    useEffect(()=>{
        if (activeTab === 0) {
            setScheduledEmployeeList(prev=>{
                return scheduledEmployee && scheduledEmployee.filter(item=>item.EmployeeName.toLocaleLowerCase().includes(search));
            });
        }

        if (activeTab === 1) {
            setNotScheduledEmployeeList(prev=>{
                return notScheduledEmployee && notScheduledEmployee.filter(item=>item.EmployeeName.toLocaleLowerCase().includes(search));
            });
        }
    },[search]);

    return (
        <Droppable droppableId={-1 + ""} direction="vertical" type="sections">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="schedule-sidebar">
                    <div className="schedule-sidebar__search">

                    </div>
                    <div className="schedule-sidebar__users">
                        {/* <Draggable
                    key={"isOpen"} 
                    draggableId={"isOpen"} 
                    index={0}
                    disableInteractiveElementBlocking="true"
                >
                {(provided, snapshot) => (
                    <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // style={getItemStyle(
                    //     snapshot.isDragging,
                    //     provided.draggableProps.style
                    // )}
                    >
                        <UserCard isOpen isLarge />
                    </div>
                )}
                    
                </Draggable> */}
                        {/* {
                            activeTab === 0 && scheduledEmployee && scheduledEmployee.length > 0 && (
                                <FormItem 
                                    name="search"
                                    formName="searchInput"
                                    placeholder='Search Employee'
                                    onChange={(name,value)=>{
                                        console.log(value, 'value')
                                        setSearch(value);
                                    }}
                                />
                            )
                        }
                        {
                            activeTab === 1 && notScheduledEmployee && notScheduledEmployee.length > 0 && (
                                <FormItem 
                                    name="search"
                                    formName="searchInput"
                                    placeholder='Search Employee'
                                    onChange={(name,value)=>{
                                        console.log(value, 'value')
                                        setSearch(value);
                                    }}
                                />
                            )
                        } */}
                        <FormItem 
                            name="search"
                            formName="searchInput"
                            placeholder='Search Employee'
                            onChange={(name,value)=>{
                                console.log(value, 'value')
                                setSearch(value);
                            }}
                        />
                        <Tabs
                            scheduledEmployee={scheduledEmployeeList}
                            notScheduledEmployee={notScheduledEmployeeList}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}
        </Droppable>

    )
}

export default ScheduleSidebar
