import React, {useState, useEffect} from "react";
import Icons from "components/icons";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/modal";
import {
  deleteLoanPurpose,
  getSbiLoanPurposeList,
  loadLoanPurpose,
  setEditMode,
} from "../helpers/action";
import Table from "components/customTable";
import Button from "components/button";
import AddLoanPurpose from "./loanPurposeForm";

const LoanPurposetable = () => {
  const dispatch = useDispatch();
  const [rowDatas, setRowDatas] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [modalState, setModalState] = useState({
    show: false,
    type: "normal",
  });

  const {sbiLoanPurpose} = useSelector((state: any) => state.sbiLoanSettingReducer);

  const actionCell = (rowProps) => {
    return (
      <div className="flex flex-gap-10">
        <Button
          title="Edit"
          bg="primary"
          type="button-outline"
          onClick={() => handleEditRecord(rowProps.row.values)}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Edit" />}
        />
        <Button
          title="Delete"
          bg="primary"
          type="button-outline"
          onClick={() => {
            setSelectedId(rowProps.value);
            setModalState({show: true, type: "confirm"});
          }}
          buttonType={"icon-button"}
          leftIcon={<Icons name="Delete" />}
        />
      </div>
    );
  };

  const beforecolumns = [
    {accessor: "Name", Header: "Loan Purpose", width: 180},
    {
      accessor: "Id",
      Header: "Action",
      Cell: actionCell,
      width: 145,
    },
  ];

  useEffect(() => {
    (async () => {
      const apis = [dispatch(getSbiLoanPurposeList())];
      await Promise.all(apis);
    })();
  }, []);

  useEffect(() => {
    if (sbiLoanPurpose) {
      setRowDatas(sbiLoanPurpose);
    }
  }, [sbiLoanPurpose]);

  // const handleDelPurpose = async () => {
  //   const res: any = await dispatch(deleteLoanPurpose(selectedId));
  //   if (res && res ?.Status) {
  //     dispatch(getSbiLoanPurposeList());
  //   }
  // };

  const handleModalShow = (show: boolean, type: string) => {
    setModalState({show, type});
  };

  const handleAddNew = () => {
    const data = {
      Id: 0,
      Name: "",
    };
    handleModalOpen(data);
  };

  const handleEditRecord = ({Id, Name}) => {
    const data = {Id, Name};
    handleModalOpen(data);
    dispatch(setEditMode());
  };

  const handleModalOpen = (data) => {
    dispatch(loadLoanPurpose(data));
    handleModalShow(true, "normal");
  };

  return (
    <div>
      <div className="panel white space-between">
        <div className="flex">
          <h4 style={{fontSize: "14px", marginRight: "1rem", fontWeight: 400}}>
            Loan Purpose
          </h4>
          <Button
            title=" Add New"
            bg="primary"
            type="button-outline"
            onClick={handleAddNew}
            buttonType={"icon-button"}
            leftIcon={<Icons name="Add" />}
          />
        </div>
      </div>
      <Table isFixedWidth columns={beforecolumns} data={rowDatas} />
      {modalState.show && (
        <Modal
          title={"Loan Purpose"}
          open={modalState.show}
          onModalClose={() => setModalState({show: false, type: "normal"})}
          type={modalState.type}
          func={() => dispatch(deleteLoanPurpose(selectedId, handleModalShow))}
        >
          <AddLoanPurpose handleModalShow={handleModalShow} />
        </Modal>
      )}
    </div>
  );
};

export default LoanPurposetable;
