import React, { useEffect, useState } from "react";
import SvgIcons from 'components/icons/svgIcons';
import isEmpty from "helpers/isEmpty";
import TaskRating from "./taskRating";
import TaskComment from "./taskComment";

const TaskRatingSection = (props) => {
    const { data, onRatingsUpdated } = props
    const [showAddBtn, setShowAddBtn] = useState(false)

    const taskDetailsDto = data && data.TmTaskDetailsDto

    if (isEmpty(taskDetailsDto))
        return null

    return (<div className="taskmodal__ratings">
        <div className="taskmodal__ratings-title">
            <SvgIcons name="award" color="#405471" size="18px" />
            <h4>Rating</h4>
        </div>

        <TaskRating
            data={taskDetailsDto}
            showAddBtn={showAddBtn}
            setShowAddBtn={setShowAddBtn}
            onRatingsUpdated={onRatingsUpdated} />

        <TaskComment
            data={taskDetailsDto}
            showAddBtn={showAddBtn}
            onRatingsUpdated={onRatingsUpdated} />
    </div>
    );
};
export default TaskRatingSection;
