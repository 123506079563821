import React, {Component} from "react";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import Form from "components/form";
import "../style.scss";
import {InputValidator} from "helpers/inputValidator";
import Button from "components/button";
import Title from "../../../common/title";
import {
  getStaffODLoanPurposeList,
  saveStaffODLoanRequest,
  updateStaffODLoanRequest,
  getStaffODLoanDetails,
  getQualifiedApprovers,
} from "../../helpers/action";
import RouteEnum from "constants/routeEnum";
import {DisplayClientSideMessage} from "actions/commonAction";
export class StaffODLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RequestAmount: "",
      Purpose: "",
      OtherPurpose: "",
      EditView: false,
      errors: {},
      ForwardTo: "",
      isSelfApplied: true,
    };
  }

  async componentDidMount() {
    let {
      getStaffODLoanPurposeList,
      getStaffODLoanDetails,
      match,
      getQualifiedApprovers,
      loanApproverList,
    } = this.props;
    getStaffODLoanPurposeList();
    const {id} = match.params;

    if (id) await getStaffODLoanDetails(id);
    // if (isEmpty(loanApproverList)) {
    await getQualifiedApprovers();
    // }
    loanApproverList = this.props.loanApproverList;

    loanApproverList =
      // loanApproverList && loanApproverList.filter((loan) => loan.IsHR == true);
      loanApproverList && loanApproverList.filter((loan) => loan.Role == 1);
    let {staffODLoanDetail, user} = this.props;
    if (id && staffODLoanDetail) {
      // let ForwardTo = [];
      // staffODLoanDetail.LoanForwards.forEach((loan) => {
      //   ForwardTo.push(loan.ForwardTo);
      // });
      let isSelfApplied = user?.EmployeeId == staffODLoanDetail.EmployeeId;
      this.setState({
        ...staffODLoanDetail,
        EditView: true,
        Id: id,
        loanApproverList,
        ForwardTo: staffODLoanDetail.ForwardTo ? staffODLoanDetail.ForwardTo : "",
        // ForwardTo,
        EmployeeId: staffODLoanDetail.EmployeeId,
        isSelfApplied,
      });
    } else this.setState({loanApproverList});
  }

  onChange = (name, value) => {
    this.setState({[name]: value}, () => {
      this.updateErrors();
    });
  };

  updateErrors = async (name) => {
    let {errors, Purpose} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      if (Purpose == "") {
        errors = {
          ...errors,
          Purpose: "Required",
        };
      } else {
        errors = {
          ...errors,
          Purpose: false,
        };
      }
      this.setState({errors});
    }
  };

  handleFormSubmit = async (IsDraft) => {
    const {
      Id,
      RequestAmount,
      Purpose,
      OtherPurpose,
      Note,
      EditView,
      ForwardTo,
      isSelfApplied,
    } = this.state;
    let errors = await InputValidator(document, this.state);
    if ((!isEmpty(errors) && !IsDraft) || Purpose == "") {
      if (this.state.Purpose == "") {
        errors = {
          ...errors,
          Purpose: true,
        };
      }
      this.setState({errors});
      return;
    }
    const {
      updateStaffODLoanRequest,
      saveStaffODLoanRequest,
      DisplayClientSideMessage,
      user: {EmployeeId},
      history,
    } = this.props;

    if (Purpose == "" && !IsDraft) {
      // DisplayClientSideMessage("Please Select your loan purpose");
      return;
    }
    let staffODLoanData = {
      Id: EditView ? Id : 0,
      EmployeeId: EditView ? this.state.EmployeeId : EmployeeId,
      RequestAmount,
      Purpose: parseInt(Purpose),
      OtherPurpose,
      Note,
      ForwardTo,
      IsDraft,
    };
    if (EditView) await updateStaffODLoanRequest(staffODLoanData);
    else await saveStaffODLoanRequest(staffODLoanData);
    const {staffODLoanSaved} = this.props;
    if (staffODLoanSaved) {
      if (isSelfApplied) history.push(RouteEnum.EmployeeLoanList);
      else history.push(RouteEnum.LoanManagement);
      // if (EditView) history.push(RouteEnum.LoanManagement);
      //   else history.push(RouteEnum.EmployeeLoanList);
    }
  };

  render() {
    let {
      RequestAmount,
      Purpose,
      Note,
      OtherPurpose,
      errors,
      EditView,
      loanApproverList,
      ForwardTo,
      isSelfApplied,
      IsDraft,
    } = this.state;
    const {
      staffODLoanPurposeList,
      user: {EmployeeId},
      isLoading,
    } = this.props;
    loanApproverList = loanApproverList?.filter((appr) => appr.Id != EmployeeId);

    return (
      <div className="personal">
        <div className="personal-container">
          {/* <Title name="Staff OD Request" /> */}

          <div className="homeloan wd-60">
            <div className="homeloan-body">
              <div className="homeloan-header mb-bg">
                <h3>Staff OD Loan Request</h3>
              </div>
              <div className="homeloan-section-content mb-md">
                <div className="text-md mb-sm">
                  <span>OD Loan Request Amount *</span>
                </div>
                <Form
                  name="RequestAmount"
                  value={RequestAmount}
                  onChange={this.onChange}
                  type="number"
                  validators={["required", "posNumbers"]}
                  error={errors && errors.RequestAmount}
                  width={"30%"}
                  minValue={1}
                />
              </div>
              <div className="homeloan-header mb-sm">
                <div className="text-md bold-500 mb-sm">
                  <span>Purpose of the Loan *</span>
                </div>
              </div>
              <div className="homeloan-section-content homeloan-checkboxes-od flex-column mb-md">
                {staffODLoanPurposeList &&
                  staffODLoanPurposeList.map((radio, index) => {
                    return (
                      <label class="checkbox-container">
                        {radio.label}
                        <input
                          type="checkbox"
                          checked={radio.value == Purpose}
                          onChange={() => this.onChange("Purpose", radio.value)}
                        />
                        <span class="checkmark"></span>
                      </label>
                    );
                  })}
              </div>
              {errors.Purpose && (
                <div className="homeloan__checkbox__required text-red mb-sm">
                  Required
                </div>
              )}
              {Purpose == "4" && (
                <div className="homeloan-otherPurpose">
                  <Form
                    name="OtherPurpose"
                    value={OtherPurpose}
                    onChange={this.onChange}
                    validators={["required"]}
                    error={errors && errors.OtherPurpose}
                    width={"47%"}
                  />
                </div>
              )}
              {(!EditView || IsDraft) && (
                <div className="homeloan-propertydetail-section flex mt-bg">
                  <Form
                    formName="checkBoxGroup"
                    name="ForwardTo"
                    label="Human Resource Department"
                    checkGroupArray={loanApproverList}
                    checkedGroupValue={ForwardTo}
                    onChange={(name, value) => this.onChange(name, value)}
                    multiple={false}
                    validators={["required"]}
                    error={errors && errors.ForwardTo}
                    width="100%"
                  />
                </div>
              )}
              <div className="homeloan-view-recommended text-black mt-sm">
                <h5>Notes</h5>
                <Form
                  type="textArea"
                  name="Note"
                  value={Note}
                  onChange={this.onChange}
                  width={"75%"}
                />
              </div>
            </div>
            <div className="homeloan-section-footer flex justify-between">
              <div className="homeloan-section-footer-item">
                <Button
                  bg="subtle"
                  onClick={() => this.props.history.goBack()}
                  title="Cancel"
                />
              </div>
              <div className="homeloan-section-footer-item flex">
                {isSelfApplied && (
                  <div className="homeloan-section-footer-item mr-md">
                    <Button
                      bg="primary"
                      onClick={() => this.handleFormSubmit(true)}
                      title="Save as Draft"
                      isDisabled={isLoading}
                    />
                  </div>
                )}
                <Button
                  bg="primary"
                  title="Send Request"
                  onClick={() => this.handleFormSubmit(false)}
                  isDisabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  staffODLoanPurposeList: state.loanReducer.staffODLoanPurposeList,
  staffODLoanDetail: state.loanReducer.staffODLoanDetail,
  staffODLoanSaved: state.loanReducer.staffODLoanSaved,
  loanApproverList: state.loanReducer.loanApproverList,
  isLoading: state.loanReducer.isLoading,
});

const mapDispatchToProps = {
  getStaffODLoanPurposeList,
  saveStaffODLoanRequest,
  updateStaffODLoanRequest,
  getStaffODLoanDetails,
  getQualifiedApprovers,
  DisplayClientSideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffODLoan);
