import React, {useEffect, useState} from "react";
import FormInput from "components/form";
import DataSelect from "components/dataselect";
import {useSelector} from "react-redux";
import ExportExcel from "components/excel/exportExcel";

const exportColumns = [
  {value: "BoardId", label: "Board Id"},
  {value: "BoardName", label: "Board Name"},
  {value: "Name", label: "Name"},
  {value: "Owner", label: "Owner"},
  {value: "Members", label: "Members"},
  {value: "TotalTask", label: "Total Task"},
  {value: "Progress", label: "Progress"},
  {value: "ToDo", label: "To Do"},
  {value: "InProgress", label: "In Progress"},
  {value: "Cancelled", label: "Cancelled"},
  {value: "Completed", label: "Completed"},
  {value: "OnHold", label: "OnHold"},
  {value: "IsCompleted", label: "Is Completed"},
  {value: "StartDate", label: "Start Date"},
  {value: "CompletedDate", label: "Completed Date"},
  {value: "EndDate", label: "End Date"},
  {value: "Efficiency", label: "Efficiency"},
  {value: "LastActivityDate", label: "Last Activity Date"},
  {value: "EnableRatingAndApproval", label: "Enabled Rating and Approval"},
  {value: "Rating", label: "Rating"},
];

function Archive(props) {
  const {filter, onChange, handleExcelExport, handleDateRangePicker, onSearchClicked} =
    props;
  const exportName = "BoardDetails";
  const [exportData, setExportData] = useState([]);
  const {boardDetailsExcel} = useSelector((state: any) => state.taskReducer);

  const {branchList, departmentList} = useSelector((state: any) => state.commonReducer);

  useEffect(() => {
    if (boardDetailsExcel) {
      var items = [];
      boardDetailsExcel.forEach((el) => {
        items.push(el);
        if (el.subRows && el.subRows.length > 0) {
          el.subRows.forEach((item) => {
            items.push(item);
          });
        }
      });
      setExportData(items);
    }
  }, [boardDetailsExcel]);

  const branches = branchList
    ? branchList.map((item) => ({label: item.Text, value: item.Value}))
    : [];
  const departments = departmentList
    ? departmentList.map((item) => ({label: item.Text, value: item.Value}))
    : [];
  return (
    <div className="tasksReport-filters">
      <FormInput
        formName="reactdaterange"
        name="date"
        placeholder="Date filter"
        startDate={filter.startDate}
        endDate={filter.endDate}
        onChange={handleDateRangePicker}
        width="250px"
      />
      <FormInput
        formName="searchInput"
        name="search"
        placeholder="Search"
        value={filter.search}
        onChange={onChange}
        width="200px"
      />
      <FormInput
        onChange={onChange}
        name="owner"
        placeholder="Select Owner"
        value={filter.owner}
        width="200px"
      />
      <FormInput
        onChange={onChange}
        formName="reactselect"
        value={filter.branchId}
        label="Branch"
        name="branchId"
        options={branches}
        width="200px"
      />
      <FormInput
        onChange={onChange}
        formName="reactselect"
        value={filter.departmentId}
        label="Department"
        name="departmentId"
        options={departments}
        width="200px"
      />
      <FormInput
        formName="reactselect"
        onChange={onChange}
        name="dl"
        value={filter.dl}
        width="200px"
        options={[
          {label: "All", value: "1"},
          {label: "Overdue", value: "2"},
          {label: "Regular", value: "3"},
        ]}
      />
      <button className="button button-primary" onClick={onSearchClicked}>
        {/* {showIcon && <BiExport />} */}
        Search
      </button>
      <ExportExcel
        onClick={handleExcelExport}
        fileName={exportName}
        columns={exportColumns}
        sheets={[{sheetName: exportName, data: exportData as []}]}
        className="button button-subtle"
      />
    </div>
  );
}

export default Archive;
