import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import CommonLinearProgress from "components/common/commonLinearProgress";
import isEmpty from "isEmpty";
import copyObject from "helpers/copyObject";
import {taskDetailsInitialState, taskCheckListState} from "../../../../taskFormStates";
import {BsCardChecklist} from "react-icons/bs";
import FormGroup from "components/form";
import classNames from "classnames";
import {
  FaRegUserCircle,
  FaRegComment,
  FaRegSmile,
  FaCheck,
  FaTimes,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import Modal from "components/modal";
import {
  addTaskCheckList,
  updateTaskCheckList,
  deleteTaskCheckList,
} from "scenes/tasks/helpers/action";

const CheckListSection = (props) => {
  const {data, stage, board} = props;
  const [checkText, setCheckText] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [selectedText, setSelectedText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch();

  const ref = useRef<any>(null);
  useOutsideClick(ref, () => {
    setSelectedIndex(-1);
  });
  const onCheckAdd = async (event: any) => {
    if (event.key === "Enter") {
      let newCheck = {
        ...taskCheckListState,
        CheckListName: checkText,
        TaskDetailsRefId: data.TmTaskDetailsDto.TaskDetailsId,
      };
      let result = await dispatch(addTaskCheckList({checkList: newCheck, stage: stage}));

      let nextData = copyObject(data);
      if (result) {
        if (isEmpty(nextData.TmTaskCheckListDto)) {
          nextData.TmTaskCheckListDto = [result];
        } else {
          nextData.TmTaskCheckListDto.push(result);
        }
        setCheckText("");
      }
      props.onCheckListUpdates && props.onCheckListUpdates(nextData);
    }
  };
  const onCheckEdit = async (index, name, value) => {
    if (value.length === 0) {
      return;
    }

    let nextData = copyObject(data);
    let checkList = nextData.TmTaskCheckListDto[index];
    checkList[name] = value;

    let result = await dispatch(
      updateTaskCheckList({checkList: checkList, stage: stage})
    );
    if (result) {
      nextData.TmTaskCheckListDto[index] = result;
      setSelectedText("");
      setSelectedIndex(-1);
      props.onCheckListUpdates && props.onCheckListUpdates(nextData);
    }
  };

  const onDelete = async (index) => {
    let nextData = copyObject(data);
    let checkList = nextData.TmTaskCheckListDto[index];
    let result = await dispatch(
      deleteTaskCheckList({id: checkList.CheckListId, stage: stage})
    );
    if (result) {
      nextData.TmTaskCheckListDto.splice(index, 1);
      props.onCheckListUpdates && props.onCheckListUpdates(nextData);
    }
  };

  const onEdit = (index) => {
    setSelectedText(data.TmTaskCheckListDto[index].CheckListName);
    setSelectedIndex(index);
  };

  const onEnter = (e, index) => {
    if (e.key === "Enter") {
      onCheckEdit(index, "CheckListName", selectedText);
    }
  };

  const calculateProgress = () => {
    const dto = (data && data.TmTaskCheckListDto) || [];

    if (dto.length === 0) return 0;
    const total = dto.length;
    const completed = dto.filter((x) => x.Status).length;
    return (parseFloat(completed) / parseFloat(total)) * 100;
  };
  const getTextDecorationStyle = (status) => {
    return status ? {textDecoration: "line-through"} : {};
  };
  return (
    <div ref={ref} className="taskmodal__checklist">
      <div className="taskmodal__checklist-title">
        <BsCardChecklist />
        <h4>Checklists </h4>
      </div>
      <div className="taskmodal__checklist-progress">
        <CommonLinearProgress
          type={calculateProgress() > 50 ? "success" : "danger"}
          progress={calculateProgress()}
        />
      </div>
      {isEmpty(data.TmTaskCheckListDto)
        ? null
        : data.TmTaskCheckListDto.map((item, index) => {
            return (
              <div key={index} className="taskmodal__checklist-checks">
                {selectedIndex === index ? (
                  <FormGroup
                    name="task"
                    placeholder="Add checklist item"
                    onChange={(name, value) => setSelectedText(value)}
                    value={selectedText}
                    onKeyUp={(e) => onEnter(e, index)}
                  />
                ) : (
                  <>
                    <label className="custom-check label-right">
                      <span style={getTextDecorationStyle(item.Status)}>
                        {item.CheckListName}
                      </span>
                      <input
                        disabled={(board && board.IsArchived) || false}
                        type="checkbox"
                        name="task"
                        checked={item.Status}
                        onChange={(e) => onCheckEdit(index, "Status", e.target.checked)}
                        value={item.Status}
                      />
                      <div className="checkmark"></div>
                    </label>
                  </>
                )}
                {!board.IsArchived && (
                  <div
                    className={classNames("taskmodal__checklist-checks-action", {
                      inactive: selectedIndex !== -1 && selectedIndex !== index,
                    })}
                  >
                    {selectedIndex !== index ? (
                      <>
                        <span onClick={(e) => onEdit(index)}>
                          <FaPencilAlt />
                        </span>
                        <span
                          onClick={(e) => {
                            setDeleteIndex(index);
                            setOpenConfirm(true);
                          }}
                        >
                          <FaTrashAlt />
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          onClick={(e) =>
                            onCheckEdit(index, "CheckListName", selectedText)
                          }
                        >
                          <FaCheck />
                        </span>
                        <span>
                          <FaTimes onClick={(e) => setSelectedIndex(-1)} />
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}

      {!board.IsArchived && (
        <div className="taskmodal__checklist-input">
          <FormGroup
            name="task"
            placeholder="Add checklist item"
            onChange={(name, value) => setCheckText(value)}
            value={checkText}
            onKeyUp={onCheckAdd}
          />
        </div>
      )}
      {openConfirm && (
        <Modal
          type="confirm"
          func={() => deleteIndex > -1 && onDelete(deleteIndex)}
          open={openConfirm}
          onModalClose={() => setOpenConfirm(false)}
          title="Delete Checklist"
          okButtonTitle="Delete"
          modalMessage="Are you sure you want to delete the checklist?<br/> You will not be able to restore the deleted item."
        />
      )}
    </div>
  );
};

export default CheckListSection;
