import Modal from "components/modal";
import isEmpty from "isEmpty";
import * as React from "react";
import {connect} from "react-redux";
import EmployeeDetails from "scenes/common/employeeDetails";
import "./allowanceRequest-Details.scss";
import MyAllowanceRequestDetails from "./allowanceRequestDetails";
import AllowanceHistory from "./allowanceHistory";
import {getEmployeeAllowanceHistory, getEmployeeAllowanceRequest} from "../action";
import * as employeeRequestService from "services/employeeRequests";
import {DisplayClientSideMessage, GetCompanyInformation} from "actions/commonAction";
import {EMPLOYEE_ALLOWANCE_REQUEST_MODULE_VALUE} from "constants/constants";
import {GetAuditLogOfRequests} from "scenes/employee/requests/actions";
import AuditHistory from "components/common/auditHistory";

export interface IAppProps {
  employeeAllowanceHistory?: any;
  closeDetailsModal: any;
  getEmployeeAllowanceHistory?: any;
  allowanceRequestDetails?: any;
  isViewMode?: boolean;
  refreshRequestData: Function;
  DisplayClientSideMessage: Function;
  requestData?: any;
  GetAuditLogOfRequests: Function;
  auditLogData?: any;
  GetCompanyInformation: Function;
  getEmployeeAllowanceRequest: Function;
  companyInformation: any;
  isAdmin?: boolean;
  employeeRequest?: any;
}

interface State {
  comments: any[];
  errors: any;
  employeeDetail: any;
  empRequest: {};
}

class AllowanceDetails extends React.Component<IAppProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      errors: {},
      employeeDetail: {},
      empRequest: {},
    };
  }

  async componentDidMount() {
    const {allowanceRequestDetails, GetCompanyInformation, isAdmin} = this.props;
    if (isAdmin) {
      await this.props.getEmployeeAllowanceHistory(allowanceRequestDetails.EmployeeId);
      await this.props.getEmployeeAllowanceRequest(allowanceRequestDetails.RequestId);
    } else {
      this.props.getEmployeeAllowanceHistory();
    }

    // await GetCompanyInformation();
    if (!isEmpty(allowanceRequestDetails)) {
      this.initializeData();
      await this.props.GetAuditLogOfRequests({
        requestId: allowanceRequestDetails.RequestId,
        moduleId: EMPLOYEE_ALLOWANCE_REQUEST_MODULE_VALUE,
      });
    }
  }

  initializeData = () => {
    let {allowanceRequestDetails, requestData, employeeRequest} = this.props;
    allowanceRequestDetails = requestData?.find(
      (x) => x.RequestId === this.props.allowanceRequestDetails.RequestId
    );
    let {employeeDetail, empRequest, comments} = this.state;
    allowanceRequestDetails = {...employeeRequest, ...allowanceRequestDetails};
    if (!isEmpty(allowanceRequestDetails) && !isEmpty(requestData)) {
      employeeDetail = {
        Id: allowanceRequestDetails["EmployeeId"],
        FullName: allowanceRequestDetails["Requester"],
        Imagepath: allowanceRequestDetails["RequesterImage"],
        Designation: allowanceRequestDetails["Designation"],
        Branch: allowanceRequestDetails["Branch"],
        Department: allowanceRequestDetails["Department"],
      };
      empRequest = {
        ...allowanceRequestDetails,
        susbtitute: {
          Imagepath: allowanceRequestDetails["SubstituteEmployeeImage"],
          FullName: allowanceRequestDetails["SubstituteEmployee"],
        },
        ApprovalStatus: allowanceRequestDetails["RecommendedStatus"],
        approvers: [
          {
            Imagepath: allowanceRequestDetails["RecommendedImage"],
            FullName: allowanceRequestDetails["Reccommender"],
            ApprovalStatus: allowanceRequestDetails["RecommendedStatus"],
          },
          {
            Imagepath: allowanceRequestDetails["ApprovalImage"],
            FullName: allowanceRequestDetails["Approval"],
            ApprovalStatus: allowanceRequestDetails["ApprovalStatus"],
          },
        ],
      };
      comments = allowanceRequestDetails["IsCommented"]
        ? allowanceRequestDetails["Comments"]?.map((x) => {
            return {
              commenter: x.CommentedBy,
              commentDate: x.CommentedDate,
              comment: x.Comments,
              commenterId: x.CommenterId,
            };
          })
        : [];
      this.setState({employeeDetail, empRequest, comments});
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const {requestData, employeeRequest} = this.props;

    if (prevProps.requestData !== requestData) {
      this.initializeData();
    }
    if (prevProps.employeeRequest !== employeeRequest) {
      this.initializeData();
    }
  }

  saveComment = async (comment) => {
    const {allowanceRequestDetails} = this.props;
    let requestData = {
      requestId: allowanceRequestDetails.RequestId,
      requestType: allowanceRequestDetails["RequestType"],
      comment,
    };
    let result = await employeeRequestService.saveReviewRequestComment(requestData);
    if (result.Status) {
      await this.props.refreshRequestData();
    }
    this.props.DisplayClientSideMessage(result);
    return result.Status;
  };

  handleApproveRequest = async (commentData) => {
    const {
      allowanceRequestDetails,
      refreshRequestData,
      closeDetailsModal,
      DisplayClientSideMessage,
    } = this.props;
    let requestData = {
      requestId: allowanceRequestDetails.RequestId,
      requestType: allowanceRequestDetails["RequestType"],
      reason: "",
    };
    if (commentData) await this.saveComment(commentData);
    // debugger
    let result = await employeeRequestService.approveReviewRequests(requestData);
    if (result.Status) {
      await refreshRequestData();
      closeDetailsModal();
    }
    DisplayClientSideMessage(result);
  };

  handleRejectRequest = async (commentData) => {
    const {
      allowanceRequestDetails,
      refreshRequestData,
      closeDetailsModal,
      DisplayClientSideMessage,
    } = this.props;
    let requestData = {
      requestId: allowanceRequestDetails.RequestId,
      requestType: allowanceRequestDetails["RequestType"],
      reason: "",
    };
    // if (commentData)
    await this.saveComment(commentData);
    debugger;
    let result = await employeeRequestService.rejectReviewRequests(requestData);
    if (result.Status) {
      await refreshRequestData();
      closeDetailsModal();
    }
    DisplayClientSideMessage(result);
  };

  public render() {
    const {errors, empRequest, employeeDetail, comments} = this.state;
    const {
      employeeAllowanceHistory,
      closeDetailsModal,
      allowanceRequestDetails,
      refreshRequestData,
      companyInformation,
      isViewMode,
      auditLogData,
    } = this.props;
    return (
      <Modal
        title=""
        open={true}
        onModalClose={() => closeDetailsModal()}
        type=""
        className="modal-allowance-details"
      >
        <div className="allowance-details">
          <div className="allowance-details__header flex">
            <EmployeeDetails empDetails={employeeDetail} size={60} showApprovalStatus />
          </div>
          <div className="allowance-details__body flex items-start justify-between">
            <div className="my-allowance-details flex-column wd-70">
              <MyAllowanceRequestDetails
                allowanceRequestId={3054}
                allowanceRequestDetails={allowanceRequestDetails}
                empRequest={empRequest}
                comments={comments}
                handleRejectRequest={this.handleRejectRequest}
                errors={errors}
                isViewMode={isViewMode}
                saveComment={this.saveComment}
                refreshRequestData={refreshRequestData}
                handleApproveRequest={this.handleApproveRequest}
                isDateNepali={!companyInformation.IsEnglishDate}
              />
            </div>
            <div className="flex-column wd-30">
              {auditLogData.loading
                ? null
                : !isEmpty(auditLogData.value) && (
                    <AuditHistory auditLogData={auditLogData.value} />
                  )}
              <AllowanceHistory employeeAllowanceHistory={employeeAllowanceHistory} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeAllowanceHistory: state.employeeAllowanceReducer.employeeAllowanceHistory,
  auditLogData: state.employeeRequestReducer.auditLogData,
  companyInformation: state.commonReducer.companyInformation,
  employeeRequest: state.allowanceReducer.employeeRequest,
});

const mapDispatchToProps = {
  getEmployeeAllowanceHistory,
  getEmployeeAllowanceRequest,
  DisplayClientSideMessage,
  GetAuditLogOfRequests,
  GetCompanyInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceDetails);
