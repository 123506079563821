import RouteEnum from "constants/routeEnum";
import React from "react";
import {BiLeftArrowAlt, BiChevronRight} from "react-icons/bi";
import {useHistory} from "react-router";

const PageBookmark = ({handleClickCourse, course, coursePage}) => {
  const history = useHistory();
  return (
    <div className="course-page__bookmark">
      <div
        onClick={() => history.push(RouteEnum.lmsRoutes)}
        className="course-page__bookmark-item"
      >
        <BiLeftArrowAlt /> Home
      </div>

      <div className="course-page__bookmark-seperator">
        <BiChevronRight />
      </div>
      <div onClick={handleClickCourse} className="course-page__bookmark-item">
        {course?.Title}
      </div>
      <div className="course-page__bookmark-seperator">
        <BiChevronRight />
      </div>
      <div className="course-page__bookmark-item inactive">{coursePage?.PageName}</div>
    </div>
  );
};
export default PageBookmark;
