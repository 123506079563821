const getColumnDefs = (activeTab, props) => {
  switch (activeTab) {
    case "2":
      return [
        {
          checkboxSelection: true,
          field: "EmployeeName",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerName: "Name",
          width: 300,
        },
        {
          field: "OverTime",
          headerName: "Overtime",
        },
        {
          cellRenderer: "DateCommon",
          field: "RequestedOn",
          headerName: "Date",
          width: 200,
        },
        {field: "StartTime", headerName: "Requested Time", width: 130},
        {field: "EndTime", headerName: "Recorded Time ", width: 130},
        {field: "RequestedTimeInMin", headerName: "Requested", width: 140},
        {field: "ApprovedTimeInMIn", headerName: "Approved", width: 140},
        {field: "Reason", headerName: "Reason"},
        {cellRenderer: "Status", field: "Status", headerName: "Status"},
        {
          cellRenderer: "Buttons",
          field: "RequestId",
          headerName: "",
          initialize: props.initialize,
          width: 200,
        },
      ];
    default:
      return [
        {
          checkboxSelection: false,
          field: "EmployeeName",
          headerName: "Name",
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
        },
        {
          field: "OverTime",
          headerName: "Overtime",
        },
        {
          cellRenderer: "DateCommon",
          field: "RequestedOn",
          headerName: "Date",
          width: 200,
        },
        {field: "StartTime", headerName: "Requested Time", width: 130},
        {field: "EndTime", headerName: "Recorded Time ", width: 130},
        // {field: "RequestedTimeInMin", headerName: "Requested", width: 140},
        {
          cellRenderer: "RequestedTime",
          field: "RequestedTimeInMin",
          headerName: "Requested Hours",
          width: 140,
        },
        // {field: "ApprovedTimeInMIn", headerName: "Approved", width: 140},
        {
          cellRenderer: "ApprovedTime",
          field: "ApprovedTimeInMIn",
          headerName: "Approved Hours",
          width: 140,
        },
        {field: "Reason", headerName: "Reason"},
        {cellRenderer: "Status", field: "Status", headerName: "Status"},
        {
          cellRenderer: "Buttons",
          field: "RequestId",
          headerName: "",
          initialize: props.initialize,
          width: 200,
        },
      ];
  }
};

export default getColumnDefs;
