import React, {useEffect, useState} from "react";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  getCoursePages,
  postAnswers,
  getAnswers,
  getCourseContent,
  getCoursePage,
  getEmployeeCourse,
  getQuestionDetails,
  addCourseCompleted,
} from "scenes/lms/helpers/action";

import RouteEnum from "constants/routeEnum";
import Sidebar from "../../common/sidebars";
import PageContent from "./pageContent";
import PageBookmark from "./pageBookmark";

function CoursePage(props) {
  const {courseId, assignedId, pageId} = props.match.params;
  const {
    coursePages,
    answers: prevAnswers,
    coursePage,
    course,
    courseContent,
    questionDetails,
    courseCompleted,
  } = useSelector((state: any) => state.lmsReducer);

  const dispatch = useDispatch();
  const {user} = useSelector((state: any) => state.auth);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (courseId) {
      (async () => {
        await dispatch(getEmployeeCourse(courseId, assignedId));
        // await dispatch(getCoursePages(courseId));
        // await dispatch(getCourseCompleted(user?.EmployeeId, courseId));
      })();
    }
  }, [courseId]);

  useEffect(() => {
    if (courseCompleted !== null) {
      const comletedCourse = courseCompleted
        ? courseCompleted.find((c) => c.CoursePageRefId == pageId)
        : null;
      const isCourseCompleted = Boolean(comletedCourse);
      const data = {
        id: 0,
        employeeId: user?.EmployeeId,
        courseRefId: courseId,
        coursePageRefId: pageId,
      };
      if (!isCourseCompleted) {
        dispatch(addCourseCompleted(data));
      }
    }
  }, [pageId, courseCompleted]);

  const handleClickCourse = () => {
    if (assignedId)
      props.history.push(`${RouteEnum.lmsCourse}/${coursePage?.CourseId}/${assignedId}`);
    else props.history.push(`${RouteEnum.lmsCourse}/${coursePage?.CourseId}`);
  };
  const bookMarksProps = {handleClickCourse, course, coursePage};
  const pageContentProps = {courseId, pageId, assignedId};
  return (
    <div className="course-innerdetail">
      <div className="container-wrap">
        <div className="course-page">
          <div className="course-page__body">
            <PageBookmark {...bookMarksProps} />
            <PageContent {...pageContentProps} />
          </div>
        </div>
      </div>
      <Sidebar assignedId={assignedId} />
    </div>
  );
}

export default CoursePage;
