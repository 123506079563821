import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import "./webAttendance.style.scss";
import Title from "../../../common/title";
import AttendanceCard from "./card";
import RecordTable from "./recordTable";
import {
  CheckClockInOutAuthority,
  GetAttendanceByDate,
  SaveAttendanceTime,
  SaveAttendanceNote,
  UpdateAttendanceNote,
  GetAttendanceReport,
} from "./action";

function Attendance(props: any) {
  useEffect(() => {
    //#region "to implement asycn in tsx"
    async function checkAttendanceAuthority() {
      await props.CheckClockInOutAuthority();
    }
    checkAttendanceAuthority();
    //#endregion

    //#region GetAttendanceByDate
    async function getAttendanceByDate() {
      let date = new Date();
      await props.GetAttendanceByDate(date, date);
    }
    getAttendanceByDate();
    //#endregion
  }, []);

  return (
    <div className="common-container">
      <Title name="Time Attendance" showUserRequest={true} />
      <div className="web-attendance-body">
        <AttendanceCard {...props} />
        <RecordTable {...props} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  attendanceAuthority: state.attendanceReducer.attendanceAuthority,
  attendanceByDate: state.attendanceReducer.attendanceByDate,
  attendanceReport: state.attendanceReducer.attendanceReport,
  companyInformation: state.commonReducer.companyInformation,
});

const mapDispatchToProps = {
  CheckClockInOutAuthority,
  GetAttendanceByDate,
  SaveAttendanceTime,
  SaveAttendanceNote,
  UpdateAttendanceNote,
  GetAttendanceReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
