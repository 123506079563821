import React, {Component} from "react";
import "../../scss";
import {connect} from "react-redux";
import NewActivityForm from "./newActivityForm";
import {GetProjectActivities, ApproveProjectActivities} from "../../action";
import SearchAndFilter from "./searchAndFilter";
import ActivityReportTable from "./table";
import {Tabs, Tab} from "components/tabs/index.d";
import {MdAdd} from "react-icons/md";
import Breadcrumb from "../../../common/breadcrumb";
import Button from "components/button";
import Loaders from "components/loaders";
import RouteBaseClass from "components/routeBaseClass";
import {getPreviousNepaliMonth} from "../../common/getDates";
import {RoleName} from "constants/userDetails";
import RouteEnum from "constants/routeEnum";
import {exportFilePost} from "src/actions/commonAction";

let tabs = [
  {tabId: 1, label: "Activity to Review", key: "ActivityToReview"},
  {tabId: 2, label: "My Activities", key: "MyActivities"},
  {tabId: 3, label: "History", key: "History"},
];
export class index extends RouteBaseClass {
  constructor(props) {
    super(props, ["Employee", "Administrator"]);
    this.state = {
      isLoading: true,
      pageIndex: 0,
      rowsPerPage: 10,
      searchClient: "",
      searchSubmitter: "",
      fromDate: null,
      toDate: null,
      searchActivityType: "",
      activeTab: tabs[0],
      showNewActivityForm: false,
    };
  }

  async componentDidMount() {
    let userRole = await RoleName();
    this.setState({userRole});
    if (userRole != "Employee") {
      tabs = tabs.filter((f) => f.tabId != 2);
    }

    let {activeTab} = this.state;
    let previousMonth = null;
    if (this.props.history.location.state) {
      const {isThisMonth, isMyActivities} = this.props.history.location.state;
      if (isMyActivities) {
        activeTab = tabs[1];
      } else {
        activeTab = tabs[0];
      }
      if (!isThisMonth) {
        previousMonth = getPreviousNepaliMonth();
      }
    }

    this.setState(
      {
        activeTab,
        fromDate: previousMonth?.firstDate,
        toDate: previousMonth?.lastDate,
      },
      async () => {
        await this.getProjectActivity();
      }
    );
    this.setState({isLoading: false});
  }

  getProjectActivity = async () => {
    const {
      searchClient,
      searchSubmitter,
      fromDate,
      toDate,
      searchActivityType,
      activeTab,
      pageIndex,
      rowsPerPage,
    } = this.state;

    const data = {
      ClientName: searchClient,
      EmployeeName: searchSubmitter,
      ActivityDateFrom: fromDate,
      ActivityDateTo: toDate,
      ActivityType: searchActivityType,
      TabType: activeTab.key,
      PageIndex: pageIndex,
      PageSize: rowsPerPage,
    };

    await this.props.GetProjectActivities(data);
  };

  refreshActivityList = async () => {
    await this.getProjectActivity();
  };

  handleApprove = async (data) => {
    await this.props.ApproveProjectActivities(data.ActivityIds, this);
  };

  handleEditProject = (data) => {
    console.log("showNewActivityForm", data);
    this.setState({dataToEdit: data}, () => {
      this.setState({showNewActivityForm: true});
    });
  };

  handleRowChange = (num) => {
    this.setState({rowsPerPage: num}, async () => {
      await this.getProjectActivity();
    });
  };

  handleIndexChange = (num) => {
    this.setState({pageIndex: num}, async () => {
      await this.getProjectActivity();
    });
  };

  handleTabChange = (tab) => {
    this.setState({activeTab: tab, pageIndex: 0}, async () => {
      await this.getProjectActivity();
    });
  };

  handleInput = (name, value) => {
    this.setState({[name]: value, pageIndex: 0}, async () => {
      await this.getProjectActivity();
    });
  };

  handleDateRangePicker = (name, date) => {
    const {startDate, endDate} = date;
    this.setState({fromDate: startDate, toDate: endDate, pageIndex: 0}, async () => {
      await this.getProjectActivity();
    });
  };

  handleFormDisplay = (value) => {
    this.setState({showNewActivityForm: value, dataToEdit: null});
  };

  handleFileExport = async () => {
    const {
      searchClient,
      searchSubmitter,
      fromDate,
      toDate,
      searchActivityType,
      activeTab,
    } = this.state;

    const data = {
      ClientName: searchClient,
      EmployeeName: searchSubmitter,
      ActivityDateFrom: fromDate,
      ActivityDateTo: toDate,
      ActivityType: searchActivityType,
      TabType: activeTab.key,
    };

    await this.props.exportFilePost("v1/tt/activities/export-activity-summary", data);
  };

  render() {
    const {
      isLoading,
      activeTab,
      pageIndex,
      rowsPerPage,
      showNewActivityForm,
      dataToEdit,
    } = this.state;
    const {projectActivities} = this.props;

    return (
      <div className="common-container">
        <div className="tasktracker-title-section">
          <Breadcrumb
            dashboardRoute={RouteEnum.ActivityDashboardPath}
            name="Activity Report"
          />
          <div className="add">
            <Button
              bg="primary"
              icon={<MdAdd />}
              buttonType="icon-button"
              title="New Activity"
              onClick={() => this.setState({showNewActivityForm: true})}
            />
          </div>
        </div>

        <Tabs noBackground>
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.label}
                name={tab.tabId}
                id={tab.tabId}
                isActive={activeTab.tabId === tab.tabId}
                onClick={() => this.handleTabChange(tab)}
              >
                <>
                  <SearchAndFilter
                    handleDateRangePicker={this.handleDateRangePicker}
                    handleInput={this.handleInput}
                    parentState={this.state}
                    types={this.state.types}
                    handleFileExport={this.handleFileExport}
                  />

                  {isLoading ? (
                    <Loaders loading={isLoading} type="skeleton" name="custom-table" />
                  ) : (
                    <ActivityReportTable
                      data={projectActivities}
                      pageIndex={pageIndex}
                      rowsPerPage={rowsPerPage}
                      activeTab={activeTab}
                      handleRowChange={this.handleRowChange}
                      handleIndexChange={this.handleIndexChange}
                      handleApprove={this.handleApprove}
                      handleView={this.handleView}
                      handleEditProject={this.handleEditProject}
                    />
                  )}
                </>
              </Tab>
            );
          })}
        </Tabs>
        {showNewActivityForm && (
          <NewActivityForm
            title={
              dataToEdit ? "Update Activity" : "New Activity"
              // : dataToEdit.SubmittedBy + "'s Activity"
            }
            footerButtons={["saveAndFinish", "saveAndNext"]}
            // hideFooter={dataToEdit ? true : false}
            history={this.props.history}
            dataToEdit={dataToEdit}
            isEditPage={activeTab.key == "MyActivities"}
            isEditByApproverReviewer={activeTab.key == "ActivityToReview"}
            isFormLoading={false}
            showForm={showNewActivityForm}
            handleFormDisplay={(value) => this.handleFormDisplay(value)}
            refreshActivityList={this.refreshActivityList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projectActivities: state.taskTrackerReducer.projectActivities,
});

const mapDispatchToProps = {
  GetProjectActivities,
  ApproveProjectActivities,
  exportFilePost,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
