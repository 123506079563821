import {
  SAVE_EMPLOYEE_OVERTIME_REQUESTS,
  EMPLOYEE_OVERTIME_HISTORY,
  EMPLOYEE_SINGLE_OVERTIME_REQUEST,
  EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST,
} from "actions/types";

const initialState = {
  singleOvertimeRequest: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_EMPLOYEE_OVERTIME_REQUESTS:
      return {
        ...state,
        isEmployeeOvertimeSaved: action.payload,
        isLoading: action.isLoading,
      };
    case EMPLOYEE_SINGLE_OVERTIME_REQUEST:
      return {
        ...state,
        singleOvertimeRequest: action.payload,
      };
    case EMPLOYEE_OVERTIME_HISTORY:
      return {
        ...state,
        employeeOverTimeHistory: action.payload,
      };

    case EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST:
      return {
        ...state,
        overTimeApprovalReviewList: action.payload,
      };

    default:
      return state;
  }
}
