import Table from "components/customTable";
import React from "react";
import dayjs from "dayjs";

const DateCell = ({value}) => {
  return <span>{dayjs(value).format("YYYY-MM-DD")}</span>;
};

const PositionCell = ({value}) => {
  return (
    <span style={{cursor: "pointer"}} title={value}>
      {value}
    </span>
  );
};

function LeaveTable(props) {
  const {data} = props;
  const cols = [
    {
      Header: "Name",
      accessor: "Name",
      width: 220,
    },
    {
      Header: "Location",
      accessor: "Location",
      width: 120,
    },
    {
      Header: "Position",
      accessor: "Position",
      Cell: PositionCell,
      width: 120,
    },
    {
      Header: "Type",
      accessor: "Type",
      width: 110,
    },
    {
      Header: "Status",
      accessor: "Status",
      width: 120,
    },
    {
      Header: "From",
      accessor: "FromDateEng",
      width: 110,
      Cell: DateCell,
    },
    {
      Header: "Till",
      accessor: "ToDateEng",
      width: 110,
      Cell: DateCell,
    },
    {
      Header: "Days",
      accessor: "Days",
      width: 70,
    },
    {
      Header: "Reason",
      accessor: "Reason",
      width: 160,
    },
  ];
  return (
    <div>
      <Table isFixedWidth data={data} columns={cols} />
    </div>
  );
}

export default LeaveTable;
