import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addCoursePage, updateCoursePage} from "../../helpers/action";
import {ShowItem} from "../contents";
import QuestionGroup from "./questionGroup";
import isEmpty from "helpers/isEmpty";
import ActionButtons from "./actionButtons";
import PageTitleInput from "./pageTitleInput";
import "./pageFormStyle.scss";

const initState = {
  title: "",
  author: "",
  description: "",
  cover: [],
  courseId: 0,
  pageId: 0,
};

function PageForm(props) {
  const [state, setState] = useState(initState);
  const [error, setError] = useState("");
  const [enableUpdateQuestion, setEnableUpdateQuestion] = useState(true);
  const dispatch = useDispatch();

  const {courseId, pageId} = props.match.params;
  const {coursePages, course, coursePage, courseContent} = useSelector(
    (state: any) => state.lmsReducer
  );
  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (coursePage && pageId) {
      setState((prev) => ({
        ...prev,
        title: coursePage.PageName,
        courseId: courseId,
        pageId: pageId,
      }));
    } else {
      setState(initState);
    }
  }, [coursePage]);

  const addPage = async (ttl) => {
    setError("");
    if (!ttl) {
      setError("Title is required");
      return;
    }
    const data = {
      id: 0,
      courseId: props.courseId,
      pageName: ttl,
      pageOrder: 0,
    };
    if (isEmpty(pageId)) {
      await dispatch(addCoursePage(data, props.history));
    } else {
      data["id"] = pageId;
      await dispatch(updateCoursePage(data));
    }
    setState((prev) => ({
      ...prev,
      title: ttl,
    }));
  };
  const onSuccessAddingQuestions = async (items) => {};
  const newContentArray = courseContent && [...courseContent];
  const totalData = newContentArray?.length || 0;
  return (
    <div className="course-mainform">
      <div className="focusable course-card">
        <PageTitleInput
          name="title"
          value={state.title}
          error={error}
          handleSubmit={(value) => {
            addPage(value);
          }}
        />
      </div>

      {pageId && (
        <>
          {newContentArray?.map((item, index) => (
            <ShowItem {...item} key={index} totalData={totalData} index={index} />
          ))}
          enableUpdateQuestion{`${enableUpdateQuestion}`}
          <QuestionGroup
            {...props}
            courseId={courseId}
            pageId={pageId}
            onSuccessAddingQuestions={onSuccessAddingQuestions}
            enableUpdateQuestion={enableUpdateQuestion}
            setEnableUpdateQuestion={setEnableUpdateQuestion}
          />
          <div className="">
            <ActionButtons
              {...props}
              courseId={courseId}
              pageId={pageId}
              enableUpdateQuestion={enableUpdateQuestion}
              setEnableUpdateQuestion={setEnableUpdateQuestion}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PageForm;
