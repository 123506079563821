import React, {useState, useEffect} from "react";
import Icons from "components/icons";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import classNames from "classnames";
import {GoPin} from "react-icons/go";
import {useSelector} from "react-redux";
import {
  getDateISO,
  getFullNepaliDate,
  getFullEngDate,
} from "../../components/calendar/backup/helpers/engCalendar";
import {AD2BS, BS2AD} from "helpers/dateConvert";
import {formatDate1} from "components/calendar/backup/helpers/dateFormat";
import moment from "moment";
interface IAppProps {
  comments?: any;
  submitComment: Function;
  submitErrors?: any;
  isViewMode?: boolean;
  deleteComment?: Function;
  showDeleteButton?: boolean;
  setCommentData?: any;
}

const CommonCommentSection: React.FunctionComponent<IAppProps> = (props) => {
  const user = useSelector((state) => state["auth"].user);
  const {companyInformation} = useSelector((state: any) => state.commonReducer);
  const {
    comments,
    submitComment,
    submitErrors,
    isViewMode,
    deleteComment,
    showDeleteButton,
    setCommentData,
  } = props;
  const [comment, setComment] = useState("");
  const [allComments, setComments] = useState(comments);
  const [myComment, setMyComment] = useState(
    allComments?.find((x) => x.commenterId === user.EmployeeId)
  );
  const [errors, setErrors] = useState({comment: ""});
  useEffect(() => {
    setErrors(submitErrors);
  }, [submitErrors]);
  const onCommentChange = (name, value) => {
    setComment(value);
    if (setCommentData) setCommentData(value);
    setErrors({comment: ""});
  };

  const saveComment = async () => {
    if (isEmpty(comment)) setErrors({comment: "Required"});
    else {
      let status = await submitComment(comment);
      if (status) {
        let fullDate = !companyInformation.IsEnglishDate
          ? AD2BS(moment().format("YYYY/MM/DD")).en
          : BS2AD(moment().format("YYYY/MM/DD"));

        const {strShortMonth, day, year} = fullDate;

        let myComment = {
          commenter: "My Comment",
          // commentDate: moment(fullDate.formatedDate).format("lll"),
          commentDate: `${strShortMonth} ${day}, ${year}, ${moment().format("LT")}`,
          comment: comment,
          commenterId: user.EmployeeId,
        };
        setMyComment(myComment);
        let newComments = allComments.map((x) => {
          return {...x};
        });
        newComments.push(myComment);
        setComments(newComments);
      }
    }
  };

  const removeComment = () => {
    setMyComment("");
    setComment("");
    submitComment("");
  };
  if (isViewMode && isEmpty(allComments)) return null;
  return (
    <div className="comment">
      <div className="comment__header">
        <span>Comments</span>
      </div>
      <div className="comment__body mt-sm">
        {allComments?.map((item, index) => {
          return (
            <div className="flex items-start comment-li">
              {item.commenterId === user.EmployeeId ? (
                <GoPin
                  name="Comment"
                  className={classNames({
                    "text-xbg": true,
                  })}
                />
              ) : (
                <Icons name="Comment" />
              )}
              <div
                className={classNames({
                  "flex-column ml-sm items-start  comment-details": true,
                  "last-child": index + 1 === allComments.length,
                })}
              >
                <div className="flex">
                  <span className="commenter">{item.commenter}</span>
                  <span className="comment-date ml-sm">{item.commentDate}</span>
                  {showDeleteButton && (
                    <span onClick={() => deleteComment(item)}>
                      <Icons name="Delete" />{" "}
                    </span>
                  )}
                </div>
                <span className="comment-text">{item.comment}</span>
              </div>
            </div>
          );
        })}
        {!isEmpty(myComment)
          ? null
          : !isViewMode && (
              <div
                className={classNames({
                  "comment-input mt-sm flex": true,
                  "items-start": !isEmpty(errors?.comment),
                  "no-comment": isEmpty(allComments),
                })}
              >
                <FormGroup
                  name="comment"
                  value={comment}
                  onChange={onCommentChange}
                  label=""
                  error={errors && errors?.comment}
                  width="400px"
                  validators={["required"]}
                />
                <div className="flex cursor-pointer check-button" onClick={saveComment}>
                  <Icons name="Check" />
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default CommonCommentSection;
