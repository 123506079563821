import React from "react";
import {Card} from "components/card";
import {RegularList} from "components/common/regularList";
import {Text} from "components/text";

import AnswerListItem from "./answerListItem";

const QuestionListItem = ({question, index}) => {
  return (
    <Card flexDirection={"column"} padding={"0.5rem 0"} width={"100%"}>
      <Text title={`Q${index + 1}. ${question.Question}`} />

      {question.CourseResultSummaryStat.length > 0 && (
        <RegularList
          items={question.CourseResultSummaryStat}
          resourceName="questionOption"
          itemComponent={AnswerListItem}
        />
      )}
    </Card>
  );
};

QuestionListItem.defaultProps = {
  question: [],
};

export default QuestionListItem;
