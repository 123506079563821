import React, { useEffect } from 'react'
import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/formGroup';
import FormItem from 'components/formGroup/formItem'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {addWorkFlowAppraisal,updateWorkFlowAppraisal} from '../helpers/action';
import { AprSignatureType } from '../helpers/contants';

function AddWorkflow(props) {
    const {
        onModalClose,
        open,
        isEdit = false,
    } = props;
    return (
        <Modal
            open={open}
            onModalClose={onModalClose}
            title={`${isEdit ? "Edit" : "Add"} Appraisal Workflow`}
            width='500px'
            className='appraisals-modal'
        >
            <WorkFlowForm {...props} />
        </Modal>
    )
}

const WorkFlowForm = (props) => {
    const {
        onModalClose,
        open,
        isEdit = false,
        editData,
        // getReviewCycle
    } = props;
    const method = useForm();
    const dispatch = useDispatch()
    const { handleSubmit, setValue,watch } = method;
    const archiveValue = watch("IsArchived")
    // useEffect(() => {
    //     if (isEdit && open) {
    //         setValue("StartDate", dayjs(editData?.StartDate).format("YYYY-MM-DD"))
    //         setValue("EndDate", dayjs(editData?.EndDate).format("YYYY-MM-DD"))
    //         setValue("CycleName", editData?.CycleName)
    //         setValue("IsArchived", editData?.IsArchived)
    //     }
    // }, [isEdit, open])
    const onSubmit = async (data) => {
        // const finalData = {
        //     "AprReviewCycle": {
        //         ...data,
        //         Id: editData?.Id ?? 0
        //     },
        //     "ReviewCycleAssessmentOption": data.AssessmentOptions && data.AssessmentOptions.map(item => ({
        //         "id": 0,
        //         "reviewCycleRefId": editData?.Id ?? 0,
        //         "AssessmentOption": item
        //     }))
        // }
        // let res;
        // if (isEdit) {
        //     res = await dispatch(updateWorkFlowAppraisal({ ...finalData, }))
        // } else {
        //     res = await dispatch(addWorkFlowAppraisal(finalData))
        // }
        // if (res === true) {
        //     onModalClose();
        //     // getReviewCycle && getReviewCycle();
        // }
    }
    return (

        <FormGroup method={method} onSubmit={onSubmit} >
            <div className='appraisals-modal__container'>
                <div className="appraisals-modal__body">

                    <FormItem
                        name='WorkflowName'
                        label='Review Workflow Name'
                        // width="200px"
                        rules={{ required: "Workflow Name is required!" }}
                    />
                     <FormItem
                        name='AppraisalWorkflow'
                        label='Appraisal Workflow'
                        // width="200px"
                        formName="reactselect"
                        options={[]}
                        rules={{ required: "Appraisal Workflow Name is required!" }}
                    />
                    <FormItem
                        name='IsRequiredSignautre'
                        formName="checkgroup"
                        checked={archiveValue}
                        label='Require Signatures'
                        labelPosition="right"
                    />
                    <div className="appraisal-signature">
                        <FormItem
                            name='SignatureOptions'
                            formName="checkBoxList"
                            label={<div className='appraise-check-label '>Signatures are required from</div>}
                            rules={{ required: "Signatures is required!" }}
                            items={[
                                { label: "Employee", value: AprSignatureType.Employee },
                                { label: "Manager", value: AprSignatureType.Manager },
                                { label: "HR Manager", value: AprSignatureType.HR_Manager },
                            ]
                            }
                        />
                    </div>
                </div>
                <div className="appraisals-modal__footer appraisals-modal__footer-center">
                    <Button
                        onClick={() => onModalClose()}
                        bg="subtle" title="Cancel" />
                    <Button
                        bg='primary'
                        title={isEdit ? "Update" : "Save"}
                        htmlButtonType="submit"
                    />
                </div>
            </div>
        </FormGroup>
    )
}



export default AddWorkflow