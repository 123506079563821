import axios from 'helpers/axiosinstance';
import { MyRequistionRequestPath } from "routes/commonLinks";
import {
  APPROVED_REQUISITION_LOADING,
  CANCEL_JOB_REQUISITION,
  GET_APPROVED_REQUISITION,
  GET_APPROVER_REQUISITION,
  GET_MY_REQUISITION,
  GET_REQUISITION_DETAILS,
  GET_REQUISITION_NUMBER,
  IS_REQUISITION_DETAILS_LOADING,
  SAVE_JOB_REQUISITION,
  SET_SPINNER_LOADING,
} from "actions/types";
import { toastMessage } from "actions/validateAction";
import isEmpty from "isEmpty";
import { RoleId } from "constants/userDetails";

const requirementBaseApi = "/v1/recruitment/";

export const getApproverJobRequisition = (data) => (dispatch) => {
  dispatch({
    type: IS_REQUISITION_DETAILS_LOADING,
    payload: true,
  });
  let requestData = {
    roleId: RoleId(),
    requisitionNumber: data.RequisitionNumber,
    jobTitle: data.JobTitle,
    departmentId: data.DepartmentId,
    branchId: data.BranchId,
    statusId: data.StatusId,
    pageIndex: data.PageIndex,
    pageSize: data.PageSize,
  };
  axios
    .post(`${requirementBaseApi}requisitions/approval`, requestData)
    .then((response) => {
      dispatch({
        type: GET_APPROVER_REQUISITION,
        payload: response.data.Data,
        page: {
          index: data.PageIndex,
          total: !isEmpty(response.data.Data)
            ? response.data.Data[0].TotalRows
            : 10,
        },
      });
      dispatch({
        type: IS_REQUISITION_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get approver requisition.",
        },
      };
      toastMessage(dispatch, response);
    });
};
export const getApprovedJobRequisition = (data) => (dispatch) => {
  dispatch({ type: APPROVED_REQUISITION_LOADING });
  axios
    .get(`${requirementBaseApi}requisitions/approved`)
    .then((response) => {
      dispatch({
        type: GET_APPROVED_REQUISITION,
        payload: response.data.Data,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get approved requisition.",
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_APPROVED_REQUISITION,
        payload: null,
      });
    });
};
export const getMyJobRequisition = (data) => (dispatch) => {
  dispatch({
    type: IS_REQUISITION_DETAILS_LOADING,
    payload: true,
  });
  const { EmployeeId, PageIndex, PageSize } = data;
  const requestData = {
    employeeId: EmployeeId,
    pageIndex: PageIndex,
    pageSize: PageSize,
  };
  axios
    .post(`${requirementBaseApi}requisitions/my`, requestData)
    .then((response) => {
      dispatch({
        type: GET_MY_REQUISITION,
        payload: response.data.Data,
        page: {
          index: data.PageIndex,
          total: !isEmpty(response.data.Data)
            ? response.data.Data[0].TotalRows
            : 10,
        },
      });
      dispatch({
        type: IS_REQUISITION_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get my requisition.",
        },
      };
      toastMessage(dispatch, response);
    });
};
export const getJobRequisitionDetails = (requisitionId) => (dispatch) => {
  dispatch({
    type: IS_REQUISITION_DETAILS_LOADING,
    payload: true,
  });
  dispatch(setSpinnerLoading(true));
  axios
    .get(`${requirementBaseApi}requisitions/${requisitionId}`)
    .then((response) => {
      dispatch({
        type: GET_REQUISITION_DETAILS,
        payload: response.data.Data,
      });
      dispatch({
        type: IS_REQUISITION_DETAILS_LOADING,
        payload: false,
      });
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to get  requisition details .",
        },
      };
      toastMessage(dispatch, response);
      dispatch(setSpinnerLoading(false));
    });
};
export const getRequisitionNumber = (IsInternalJobType = false) => async (dispatch) => {

  try {
    const res = await axios.get(`${requirementBaseApi}requisitions/number?IsInternalJob=${IsInternalJobType}`)
    return res.data.Data
  }
  catch (err) {
    const response = {
      data: {
        MessageType: "Danger",
        Message: "Failed to get requisition number.",
      },
    };
    toastMessage(dispatch, response);
    return null;
  };
};

export const saveJobRequisition = (data, history) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  axios
    .post(`${requirementBaseApi}requisitions`, data)
    .then((response) => {
      dispatch(getRequisitionNumber());
      dispatch({
        type: SAVE_JOB_REQUISITION,
      });
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
      history.push(MyRequistionRequestPath);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to add job.",
        },
      };
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    });
};
export const updateJobRequisition = (data, history) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  axios
    .put(`${requirementBaseApi}requisitions`, data)
    .then((response) => {
      dispatch(getRequisitionNumber());
      dispatch({
        type: SAVE_JOB_REQUISITION,
      });
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
      history.push(MyRequistionRequestPath);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to update job.",
        },
      };
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    });
};

export const approveJobRequisition = (data) => (dispatch) => {
  dispatch({
    type: IS_REQUISITION_DETAILS_LOADING,
    payload: true,
  });
  axios
    .put(`${requirementBaseApi}requisition/approve/${data.RequisitionId}`)
    .then((response) => {
      dispatch(getApproverJobRequisition(data));
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to approve requisition.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const cancelJobRequisition = (requisitionId) => (dispatch) => {
  dispatch(setSpinnerLoading(true));
  axios
    .put(`${requirementBaseApi}requisition/cancel/${requisitionId}`)
    .then((response) => {
      dispatch({
        type: CANCEL_JOB_REQUISITION,
        payload: requisitionId,
      });
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to cancel job requisition.",
        },
      };
      toastMessage(dispatch, response);
      dispatch(setSpinnerLoading(false));
    });
};
// Set loading state
export const setSpinnerLoading = (data) => {
  return {
    type: SET_SPINNER_LOADING,
    payload: data,
  };
};
