import React, { useState, useRef, useEffect, createRef } from "react";
import { IoIosClose, IoIosAt } from "react-icons/io";
import emojiList from "./emojiList";
import FormGroup from "components/form";
import classNames from "classnames";
import CustomPopup from "components/common/customPopup";
import { FaRegSmile } from "react-icons/fa";
import SvgIcon from "components//icons/svgIcons";
import leaveTable from "scenes/employee/employeeProfile/leave/leaveTable";
import ReactDOM from "react-dom";

const EmojiButton = (props) => {
  const popupHeight = 345;
  const popupWidth = 300;

  const { open, iconName, iconSize } = props;
  const ref = useRef<any>(null);

  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <div className="emoji-selector" ref={ref}>
      <span onClick={() => setShowModal(true)}>
        <SvgIcon name={iconName || "simlePlus"} size={iconSize || 20} />
        {/* <FaRegSmile size={20} /> */}
      </span>

      {showModal && ref && ref.current && (
        <CustomPopup
          parentRef={ref}
          open={showModal}
          handleOutsideClick={() => setShowModal(false)}
        >
          <Emoji
            handleClose={onClose}
            onSelected={(item) => {
              props.onEmojiSelected && props.onEmojiSelected(item);
              props.hideOnSelect && setShowModal(false);
            }}
          />
        </CustomPopup>
      )}
    </div>
  );
};

const Emoji = (props: any) => {
  const { open } = props;
  const [searchText, setSearchText] = useState("");
  const [emojis, setEmojis] = useState([]);

  const [state, setState] = useState({ left: -1, top: -1 });

  // useEffect(() => {

  // }, [open]);

  useEffect(() => {
    setEmojis(emojiList);
  }, [emojiList]);

  const handleSCroll = () => {
    if (open) {
      // setPosition();
      //resetState();
    }
  };

  const onSearchTextChange = (name, value) => {
    let list = [...emojiList];
    if (value && value.length > 0) {
      list = list.filter((x) =>
        x.shortname.toLowerCase().includes(value.toLowerCase())
      );
    }

    setEmojis(list);
    setSearchText(value);
  };

  const createMarkup = (x) => {
    return { __html: `${x.code_decimal}` };
  };

  return (
    <>
      <div className="emoji-header">
        <div className="emoji-header-close">
          <IoIosClose
            onClick={(e) => {
              onSearchTextChange("", "");
              props.handleClose(false);
            }}
          />
          <FormGroup
            name="Search"
            value={searchText}
            placeholder="Search Emoji"
            onChange={onSearchTextChange}
          />
        </div>
      </div>
      <div className="emoji-container">
        {emojis.map((x, index) => (
          <span
            key={index}
            className="emoji-container-emoji"
            onClick={() => {
              props.onSelected && props.onSelected(x);
            }}
            dangerouslySetInnerHTML={createMarkup(x)}
          ></span>
        ))}
      </div>
    </>
  );
};
export default EmojiButton;
