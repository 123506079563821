import React, { useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Modal from "components/modal";
import Button from "components/button";
import { deleteAppraisalReviewCycle } from '../helpers/action';
import ReviewCycleModal from './reviewCycleModal';


const data = [
    {
        EmployeeImage:'/images/male.png',
        CycleName:'2022',
        AssessmentOptions:'Performance Appraisals 360 Feedback',
        State:true,
    }
]


const ButtonCell = (props) => {
    const {original,value} = props.row;
    const {getReviewCycle} = props;
    const [open, setOpen] = useState(false);
    const onModalOpen = () => setOpen(true);
    const onModalClose = () => setOpen(false);
    const dispatch = useDispatch();
    const onDelete = () => dispatch(deleteAppraisalReviewCycle(props.value));
    return (
      <div className="appraisals-table__col">
        <div className="appraisals-table__links">
          <Button onClick={() => onModalOpen()} size="small" title="Edit" bg="secondary" />
          <Button onClick={() => onDelete()} size="small" title="Delete" bg="secondary" />
        </div>
        <ReviewCycleModal 
          open={open}
          getReviewCycle={getReviewCycle}
          onModalClose={onModalClose}
          isEdit={true}
          editData={original}
        />
      </div>
    );
};

const StatusCell = (props) => {
    const {value,original} = props;
    return(
        <div className="appraisals-table__col">
            {
                value ? 
                    <div className="appraisals-table__badge appraisals-table__badge-green">Active</div> : 
                    <div className="appraisals-table__badge">Archived</div>
            }
        </div>
    )
}


function ReviewCycleTable(props) {
    const {getReviewCycle} = props;
    const {reviewCycles} = useSelector((state:any)=>state.appraisalReducer);
    const cols = [
        {
          Header: "Cycle Name",
          accessor: "CycleName",
          width: 150,
        },
        {
            Header: "Assessment Options",
            accessor: "AssessmentOptions",
            width: 250,
          },
        {
            Header: "State",
            accessor: "Status",
            width: 140,
            // Cell:StatusCell
        },
        {
          Header: "",
          accessor: "Id",
          Cell: (pr)=><ButtonCell {...pr} getReviewCycle={getReviewCycle} />,
          width: 120,
        },
    ];
  return (
    <div className="appraisals-table">
        <Table isFixedWidth data={ reviewCycles ?? []} columns={cols} />
    </div>
  )
}

export default ReviewCycleTable