// import axios from 'helpers/axiosinstance';
import axios from "helpers/axiosinstance";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_TOKEN,
  ADD_OTHER_MESSAGES,
  CLEAR_ERRORS,
  SET_USER_LOADING,
  ADD_REFRESH_LOADING,
  GET_BASE_URL,
  GET_PUBLIC_RECRUITMENT_BASE_URL,
  GET_IS_RECOMMENDER_OR_APPORVER,
  GET_HR_COMMON_SETTINGS,
  SET_USER_VISIBLE,
  SET_LOGIN_LOADING,
  CHANGE_PROFILE_PICTURE,
  ADD_LOADING,
  GET_IS_HR_OR_FINANCE,
  LOGIN_VIA_VIANET,
} from "./types";
import isEmpty from "helpers/isEmpty";
import setAuthToken from "../utils/setAuthToken";
// import { getBaseAxios } from "../helpers/Constants";
import {toastMessage} from "./validateAction";
// import configureStore from "../store/configureStore";
import {LoginInterface} from "ts/interfaces/scenesInterface";
import {actionInterface} from "ts/interfaces/reduxInterfaces";
import {getFeatureFlag, getActiveFeatureFlag} from "./permissionsAction";
import {clearLogoutLocalStorage} from "helpers/constants";
import {rigoLoadingHeaders, loadingHeaders} from "helpers/getHeaders";
import RoutesEnum from "constants/routeEnum";
import Http from "src/utils/http/http";
import HttpErrorResponseModel from "src/utils/http/httpErrorResponseModel";
import environment from "environment";
import Action from "src/utils/http/actionUtility";
import TokenService from "src/utils/tokenService";
import {getProfileDetail} from "scenes/employee/employeeProfile/action";
import {api, get, post} from "src/networkService";

var querystring = require("querystring");

export const getUser = (): any => async (dispatch: any) => {
  dispatch({type: SET_USER_LOADING, payload: true});
  // dispatch({ type: SET_USER_LOADING, payload: true });
  await axios
    .get("/v1/security/users/details", rigoLoadingHeaders)
    .then((res) => {
      dispatch({type: SET_USER_LOADING, payload: false});
      //Save to local Storage
      const {
        UserName,
        RoleName,
        Email,
        Branch,
        UserID,
        EmployeeId,
        RoleId,
        Designation,
        MappedEmployeeId,
        IsEmployeeType,
        IsShiftEmployee,
        MapToUserName,
      } = res.data;
      //Set token to ls
      const UserDetail2 = JSON.stringify({
        UserName,
        Email,
        Branch,
        UserID,
        RoleName,
        RoleId,
        EmployeeId,
        Designation,
        MappedEmployeeId,
        IsEmployeeType,
        IsShiftEmployee,
        MapToUserName,
      });
      // dispatch(adminEmployee(EmployeeId, false));
      dispatch(setCurrentUser(res.data));
      localStorage.setItem("UserDetail", UserDetail2);
    })
    .catch((err) => {
      dispatch({type: SET_USER_LOADING, payload: false});
    });
};

const saveToken = async (res: any, dispatch: Function) => {
  if (res) {
    //Save to local Storage
    let AuthToken = "bearer " + res.Token;
    let expires_in = res.ExpireMinutes;
    let RefreshToken = res.RefreshToken;
    let IdleTimeoutMinutes = res.IdleTimeoutMinutes;
    //Set token to ls

    const timeout = expires_in * 60 * 1000 - 60 * 1000;
    const expiryTimer = setTimeout(async () => {
      // this would reset localStorage before token expiry timr
      console.log("expires in ");
      await dispatch(refreshToken());
    }, timeout);
    var tokenexpiration = new Date();
    let expires: number =
      tokenexpiration.setSeconds(
        new Date().getSeconds() + (parseInt(expires_in) + 5) * 60 - 10
        // new Date().getSeconds() + parseInt(expires_in) * 60 - 10
      ) / 1000;
    let tokenDetails = {
      RefreshToken,
      expires,
      expires_in,
      IdleTimeoutMinutes,
      AuthToken,
    };
    TokenService.saveAllToken({
      AuthTokenVal: "",
      RefreshTokenVal: RefreshToken,
      IdleTimeOutVal: IdleTimeoutMinutes,
      ExpiresMinutesVal: expires_in,
      ExpiresVal: expires.toString(),
    });
    // await setAuthToken(AuthToken);
    await dispatch(setCurrentToken(AuthToken, tokenDetails, res));
    const promiseArray = [dispatch(getIsRecommenderOrApprover())];
    await Promise.all(promiseArray);
  }
};

export const loginUser =
  (data: LoginInterface, changeHistory?: any, isSwicth = false, history = null) =>
  async (dispatch: Function) => {
    dispatch({type: SET_LOGIN_LOADING, payload: true});
    isSwicth && dispatch({type: "ADD_LOADING", payload: true, loadingType: "spinner"});
    dispatch(clearErrors());
    const request = {
      userName: data.rigoId,
      password: data.password,
      strategy: data.strategy ? data.strategy : "password",
    };

    //  const headers = isSwicth ? rigoLoadingHeaders : loadingHeaders;
    // console.log(request)
    const res: any = await Http.post(
      environment.api.v1.auth.signIn,
      request,
      loadingHeaders
    );

    const isError = res instanceof HttpErrorResponseModel;
    if (isError) {
      dispatch({type: SET_LOGIN_LOADING, payload: false});
      if (res.errors) {
        dispatch({
          type: GET_ERRORS,
          payload: res?.errors,
        });
      }
    } else {
      await saveToken(res.data.Data, dispatch);
      dispatch({type: SET_LOGIN_LOADING, payload: false});
      if (res.data.Data === null) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.Message[0],
        });
      }
      if (res.data.Data && res.data.Data.IsRedirectToPwdChange === true) {
        history.push(RoutesEnum.ResetOldHashPath);
      }
      // After Switching user
      if (changeHistory) {
        changeHistory();
      }
    }
    dispatch({type: "ADD_LOADING", payload: false, loadingType: "spinner"});
  };

export const loginUserViaVianet =
  (queryParams: string, changeHistory?: any, isSwicth = false, history = null) =>
  async (dispatch: Function) => {
    dispatch({
      type: SET_LOGIN_LOADING,
      payload: true,
    });
    isSwicth && dispatch({type: "ADD_LOADING", payload: true, loadingType: "spinner"});
    const res: any = await Http.get(environment.api.v1.auth.signInViaVianet, queryParams);

    const isError = res instanceof HttpErrorResponseModel;
    if (isError) {
      dispatch({type: SET_LOGIN_LOADING, payload: false});
      if (res.errors) {
        dispatch({
          type: GET_ERRORS,
          payload: res?.errors,
        });
      }
    } else {
      await saveToken(res.data.Data, dispatch);
      dispatch({type: SET_LOGIN_LOADING, payload: false});
      if (res.data.Data === null) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.Message[0],
        });
      }
      if (res.data.Data && res.data.Data.IsRedirectToPwdChange === true) {
        history.push(RoutesEnum.ResetOldHashPath);
      }
      // After Switching user
      if (changeHistory) {
        changeHistory();
      }
    }
    dispatch({type: "ADD_LOADING", payload: false, loadingType: "spinner"});
  };

export const getBaseURL =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get("/v1/common/net-framework-app-base-url", rigoLoadingHeaders)
      .then((res) => {
        dispatch({
          type: GET_BASE_URL,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BASE_URL,
          payload: null,
        });
      });
  };

export const getPublicRecruitmentBaseURL =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get("/v1/common/public-recruitment-base-url", rigoLoadingHeaders)
      .then((res) => {
        dispatch({
          type: GET_PUBLIC_RECRUITMENT_BASE_URL,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PUBLIC_RECRUITMENT_BASE_URL,
          payload: null,
        });
      });
  };

export const refreshToken =
  (): any =>
  async (dispatch: any): Promise<any> => {
    const refresh_token = localStorage.RefreshToken;
    dispatch({type: ADD_LOADING, payload: true, loadingType: "rigoLoading"});
    dispatch({type: ADD_REFRESH_LOADING, payload: true});
    if (refresh_token) {
      let newRefresh = refresh_token.replace(" ", "+");
      let request = querystring.stringify({
        token: newRefresh,
      });
      await axios
        .post(`${environment.api.v1.auth.refreshToken}?${request}`)
        .then(async (res) => {
          dispatch({
            type: ADD_LOADING,
            payload: false,
            loadingType: "rigoLoading",
          });
          dispatch({type: ADD_REFRESH_LOADING, payload: false});
          if (res.data.Status) {
            await saveToken(res.data.Data, dispatch);
          } else {
            await dispatch(logoutUser("refresh_error"));
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_LOADING,
            payload: false,
            loadingType: "rigoLoading",
          });
          dispatch({type: ADD_REFRESH_LOADING, payload: false});
          console.log(err);
          // dispatch({ type: SET_LOGIN_LOADING, payload: false });
          if (err.response != "undefined") {
            dispatch({
              type: GET_ERRORS,
              payload: err?.response?.data?.error_description,
            });
          }
        });
    }
  };

export const getIsRecommenderOrApprover =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get(`/v1/ltoa/isRecommenderOrApprover`)
      .then((res) => {
        if (res.data.Status) {
          dispatch({
            type: GET_IS_RECOMMENDER_OR_APPORVER,
            payload: res.data.Data,
          });
        } else {
          dispatch({
            type: GET_IS_RECOMMENDER_OR_APPORVER,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_IS_RECOMMENDER_OR_APPORVER,
          payload: false,
        });
      });
  };

export const getHrCommonSettings =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get(`/v1/common/settings`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_HR_COMMON_SETTINGS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {});
  };

//Log user out

export const logoutUser =
  (type = "none", history = null) =>
  async (dispatch: any) => {
    const token = localStorage.AuthToken;
    // Remove the token from local storage
    clearLogoutLocalStorage();
    if (token) {
      var res = await axios.post("/v1/auth/signOut", rigoLoadingHeaders).catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Failed to Logout",
          },
        };
        // toastMessage(dispatch, response);
      });
      const response = {
        data: {
          MessageType: "Success",
          Message: "Logout Success",
        },
      };
      toastMessage(dispatch, response);
    }

    // Set current user to {} which wil set isAuthen to false
    dispatch(setCurrentUser({}));
    dispatch(setCurrentToken("", "", ""));
    // Remove auth header for future requests
    setAuthToken(false);
    // window.loc ation.href = getBaseAxios() + "/react/login";

    if (type === "inactivity") {
      dispatch(
        otherMessage(" Your login session has expired. Please login again to continue.")
      );
      return false;
    }
    if (type === "refresh_error") {
      dispatch(
        otherMessage(
          // "You have been logged out due to some error while updating your login session"
          " Your login session has expired. Please login again to continue."
        )
      );
      return false;
    }
  };

// export const logoutUser =
//   (type = "none", history = null) =>
//   async (dispatch: any) => {
//     const token = localStorage.AuthToken;
//     // Remove the token from local storage
//     clearLogoutLocalStorage();

//     axios
//       .post("/v1/auth/signOut", rigoLoadingHeaders)
//       .then((res) => {
//         // Set current user to {} which wil set isAuthen to false
//         dispatch(setCurrentUser({}));
//         dispatch(setCurrentToken("", "", ""));
//         // Remove auth header for future requests
//         setAuthToken(false);
//         // window.loc ation.href = getBaseAxios() + "/react/login";
//         const response = {
//           data: {
//             MessageType: "Success",
//             Message: "Logout Success",
//           },
//         };
//         if (type === "inactivity") {
//           dispatch(
//             otherMessage(
//               " Your login session has expired. Please login again to continue."
//             )
//           );
//           return false;
//         }
//         if (type === "refresh_error") {
//           dispatch(
//             otherMessage(
//               // "You have been logged out due to some error while updating your login session"
//               " Your login session has expired. Please login again to continue."
//             )
//           );
//           return false;
//         }

//         toastMessage(dispatch, response);
//       })
//       .catch((err) => {
//         const response = {
//           data: {
//             MessageType: "Danger",
//             Message: "Failed to Logout",
//           },
//         };
//         // toastMessage(dispatch, response);
//       });
//   };

export const otherMessage = (message: string) => (dispatch: Function) => {
  dispatch({
    type: ADD_OTHER_MESSAGES,
    payload: {
      type: "Success",
      message,
      timeout: -1,
    },
  });
};

//Send Forgot Username to Email
export const sendUsernameToEmail = (email) => (dispatch) => {
  axios
    .get(`/v1/security/users/send-username-to-email?email=${email}`, rigoLoadingHeaders)
    .then((res) => {
      toastMessage(dispatch, res);
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to send email",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

//Send Reset Forgot Password
export const forgotPassword = (email) => (dispatch) => {
  axios
    .post(`/v1/security/users/forgot-password`, {email}, rigoLoadingHeaders)
    .then((res) => {
      toastMessage(dispatch, res);
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to send email",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

//Send Reset Forgot Password
export const changePassword = (data, onModalClose) => (dispatch) => {
  axios
    .post(`/v1/security/users/change-password`, data, rigoLoadingHeaders)
    .then((res) => {
      console.log({res});
      if (res.data.Status) {
        onModalClose();
      }
      toastMessage(dispatch, res);
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to change password",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

export const changeOldHashPassword = (data, history) => (dispatch) => {
  axios
    .post(`/v1/security/users/change-oldhash-password`, data, rigoLoadingHeaders)
    .then((res) => {
      toastMessage(dispatch, res);
      if (history && res.data.Status) {
        history.push("/");
      }
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to change password",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

// Reset Password
export const changeProfileImage = (data, noToaster, employeeId) => (dispatch) => {
  axios
    .post(`/v1/employee-record/profile-pictures`, data)
    .then((res) => {
      if (res.data.Status) {
        dispatch(getUser());
        employeeId && dispatch(getProfileDetail(employeeId));
      }
      !noToaster && toastMessage(dispatch, res);
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to reset password, Please reload and try again",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

// Reset Password
export const resetPassword = (data, history) => (dispatch) => {
  axios
    .post(`/v1/security/users/reset-password`, data, rigoLoadingHeaders)
    .then((res) => {
      if (res.data.Status) {
        history.push("/login");
      }
      toastMessage(dispatch, res);
    })
    .catch((err) => {
      let response = {
        data: {
          MessageType: "Danger",
          Message: "Failed to reset password, Please reload and try again",
        },
      };
      if (err.response) {
        response = err.response;
      }
      toastMessage(dispatch, response);
    });
};

//Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const setCurrentToken = (token, tokenDetails, loginData) => {
  return {
    type: SET_CURRENT_TOKEN,
    payload: token,
    tokenDetails,
    loginData,
  };
};
// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const getIsHRorFinance =
  (): any =>
  async (dispatch: any): Promise<any> => {
    await axios
      .get(`/v1/loan/is-hr-or-finance-user`)
      .then((res) => {
        if (res.data.Status) {
          dispatch({
            type: GET_IS_HR_OR_FINANCE,
            payload: res.data.Data,
          });
        } else {
          dispatch({
            type: GET_IS_HR_OR_FINANCE,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_IS_HR_OR_FINANCE,
          payload: false,
        });
      });
  };

export const loginViaVianet = () => async (dispatch) => {
  dispatch({
    type: LOGIN_VIA_VIANET,
    payload: null,
    loginViaVianetLoading: true,
  });

  var res: any = await get(
    `${environment.api.v1.auth.signInWithOauth}`,
    dispatch,
    null,
    true
  ).catch((ex) => {});

  if (res?.Status) {
    dispatch({
      type: LOGIN_VIA_VIANET,
      payload: res.Data,
      loginViaVianetLoading: false,
    });
    if (res.Data) {
      window.location = res.Data;
    }
  } else {
    dispatch({
      type: LOGIN_VIA_VIANET,
      payload: null,
      loginViaVianetLoading: false,
    });
  }
};
