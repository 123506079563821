import React, {useEffect, useState} from "react";
import FormInput from "components/form";
import {useDispatch, useSelector} from "react-redux";
import {getAppraisalEmployeeBatchRollout} from "../helpers/action";
import Button from "components/button";
import Table from "components/customTable";
import Pagination from "components/common/pagination";
import Modal from "components/modal";
import {FcCheckmark} from "react-icons/fc";
import dayjs from "dayjs";
import Avatar from "react-avatar";

const EmpCell = (props) => {
  const {original} = props.row;
  return (
    <div className="appraisals-table__col">
      <div className="appraisals-table__emp">
        <div className="appraisals-table__emp-image">
          {/* <img src={original.Photo} alt="" /> */}
          <Avatar size="32px" round src={original.Photo} name={original.EmployeeName} />
        </div>
        <div className="appraisals-table__emp-link">{original.EmployeeName}</div>
      </div>
    </div>
  );
};

const initialFilters = {
  pageIndex: 0,
  pageSize: 10,
  reviewCycleId: null,
  branchId: null,
  departmentId: null,
  isHide: true,
};

function SelectEmployee(props) {
  const [filters, setFilters] = useState(initialFilters);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const {branchList, departmentList, jobStatusList} = useSelector(
    (state: any) => state.commonReducer
  );
  const {reviewCyclesDropDown} = useSelector((state: any) => state.appraisalReducer);
  useEffect(() => {
    getRollout();
  }, [filters]);

  const getRollout = () =>
    dispatch(
      getAppraisalEmployeeBatchRollout({
        ...filters,
        branchId: filters?.branchId?.value,
        reviewCycleId: filters?.reviewCycleId?.value,
        departmentId: filters?.departmentId?.value,
      })
    );

  const {employeeBatchRollouts} = useSelector((state: any) => state.appraisalReducer);
  const cols = [
    {
      Header: "Employee Name",
      accessor: "Id",
      Cell: EmpCell,
      width: 200,
    },

    {
      Header: "Position",
      accessor: "Position",
      width: 160,
    },
    {
      Header: "Email Address",
      accessor: "EmailAddress",
      width: 160,
    },
    {
      Header: "Manager Name",
      accessor: "ManagerName",
      width: 160,
    },
  ];

  const onChange = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRowChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageSize: num,
        pageIndex: 0,
      };
    });
  };

  const handleIndexChange = (num) => {
    setFilters((filter: any) => {
      return {
        ...filter,
        pageIndex: num,
      };
    });
  };
  const onRowSelect = (data) => {
    console.log({data});
    // data && data.length > 0 && setSelected(data);
  };
  return (
    <div className="appraisals-container">
      <div className="appraisals-filters">
        <FormInput
          formName="reactselect"
          onChange={onChange}
          name="reviewCycleId"
          label="Review Cycle"
          value={filters.reviewCycleId}
          width="200px"
          options={reviewCyclesDropDown ?? []}
        />
        <FormInput
          formName="reactselect"
          onChange={onChange}
          name="branchId"
          label="Filter Branch"
          value={filters.branchId}
          width="200px"
          options={branchList ?? []}
        />
        <FormInput
          formName="reactselect"
          onChange={onChange}
          name="departmentId"
          label="Filter Department"
          value={filters.departmentId}
          width="200px"
          options={departmentList ?? []}
        />
      </div>
      <div className="appraisals-row">
        <FormInput
          name="isHide"
          value={filters?.isHide}
          onChange={(name, value) => {
            onChange("isHide", name.target.checked);
          }}
          formName="checkgroup"
          checked={filters?.isHide}
          label="Hide employees who were previously received assessment form for this assessment cycle"
          labelPosition="right"
        />
      </div>
      <div className="appraisals-row">
        <div className="appraisals-text-title">
          {selected && selected.length} employees selected
        </div>
        <div className="appraisals-text-sub">
          out of {employeeBatchRollouts && employeeBatchRollouts.length} employees found
        </div>
      </div>
      <div className="appraisals-tables">
        <Table
          isRowSelect
          onRowSelect={onRowSelect}
          isFixedWidth
          data={employeeBatchRollouts ?? []}
          columns={cols}
        />
        {employeeBatchRollouts && employeeBatchRollouts.length > 0 && (
          <Pagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={filters.pageIndex}
            rowsPerPage={filters.pageSize}
            total={employeeBatchRollouts[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
}

export default SelectEmployee;
