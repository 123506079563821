import React, { useState } from 'react'
import Button from 'components/button'
import FormGroup from 'components/form'
import IdentificationForm from './identificationForm';
import Modal from 'components/modal'
import isEmpty from 'isEmpty'
import DocumentViewer from 'components/common/commonDocumentViewer'
import { useDispatch, useSelector } from 'react-redux';
import Confirm from 'components/common/commonConfimation'
import Icons from 'components/icons'
import { deleteEmployeeDocument } from '../action';
import {
  getEmployeeAdditionalDocuments,
  getEmployeeAddressDetails,
  getEmployeeCitizenshipDetails,
  getEmployeeDrivingDetails,
  getEmployeePassportDetails
} from '../action';
import { setEditMode } from 'scenes/travel/admin/travelAllowanceExpense/actions';
import { Date_Format_Reverse } from "constants/dateFormat";
import {getUploadPath} from 'constants/userDetails'

function Identification(props) {
    let [citizenOpen, setCitizen] = useState(false);
    let [passportOpen, setPassport] = useState(false);
    let [drivingOpen, setDriving] = useState(false);
    let [editData, setEditData] = useState(null);
    // let [docsData, setDocsData] = useState([]);
    const dispatch = useDispatch();

    const { citizenShipDetails, EmployeeId } = props;
    const {
      employeeAdditionalDocs,
      employeeAddressDetails,
      employeeCitizenshipDetails,
      employeeDrivingDetails,
      employeePassportDetails
    } = useSelector((state:any)=>state.employeeProfile);
    let docsData = [];
    let noDocsData = [];
    if (!isEmpty(citizenShipDetails?.CitizenshipNo)) {
      docsData.push({
        type: '1',
        id: citizenShipDetails.CitizenshipId,
        ...citizenShipDetails
      });
    } else {
      noDocsData.push('1')
    }
    if (!isEmpty(employeeDrivingDetails?.DrivingLicenceNo)) {
      docsData.push({
        type: '2',
        id: employeeDrivingDetails.DrivingLicenceId,
        ...employeeDrivingDetails
      });
    } else {
      noDocsData.push('2')
    }
    if (!isEmpty(employeePassportDetails?.PassportNo)) {
      docsData.push({
        type: '3',
        id: employeePassportDetails.PassportId,
        ...employeePassportDetails
      });
    } else {
      noDocsData.push('3')
    }

    const openCorrespondingDocModal = (modalType) => {
      if (modalType == '1') setCitizen(true);
      else if (modalType == '2') setDriving(true);
      else setPassport(true);
      setEditData(null);
    }

    const toggleEditButton = (item) => {
      if (item.type == '1')
        setCitizen(true);
      else if (item.type == '2')
        setDriving(true);
      else if (item.type == '3')
        setPassport(true);

      if (item)
        setEditData(item);
      else {
        setCitizen(false);
        setDriving(false);
        setPassport(false);
        setEditData(null);
      }
    }

    const onItemDelete = (item) => {
      if (item.type == '1') {
        dispatch(getEmployeeCitizenshipDetails(props.EmployeeId));
      }
      if (item.type == '2')
        dispatch(getEmployeeDrivingDetails(props.EmployeeId));
      if (item.type == '3')
        dispatch(getEmployeePassportDetails(props.EmployeeId));
    }

    return (
        <div className="employee-docs__identification">
            <div className="tertiary-title">
                <h3>Identifications & Documents</h3>
            </div>
            <div className="employee-block">
              <ul className="employee-docs__additional-list">
                {
                    !isEmpty(docsData) &&
                    docsData.map((item, i)=><Docs key={i} doc={item} EmployeeId={EmployeeId} toggleEditButton={(doc) => toggleEditButton(doc)}
                    onItemDelete={(doc) => onItemDelete(doc)}
                    />)
                    //  (<Docs title="Citizenship" key={citizenShipDetails.CitizenshipNo} doc={citizenShipDetails} EmployeeId={EmployeeId} />)
                        // : <span className="nodata">No Additional Documents Found</span>
                }
              </ul>
              <ul className="employee-docs__additional-list">
                {
                    !isEmpty(noDocsData) &&
                    noDocsData.map((item, i)=>
                    <Button
                    bg="primary-light"
                    title={item == '1' ? 'Citizenship' : item == '2' ? 'Driving License' : 'Passport'}
                    buttonType="icon-button"
                    leftIcon={<Icons name="Add" />}
                    onClick={() => openCorrespondingDocModal(item)}
                />)
                    //  (<Docs title="Citizenship" key={citizenShipDetails.CitizenshipNo} doc={citizenShipDetails} EmployeeId={EmployeeId} />)
                }
              </ul>
            </div>
            <div className="employee-docs__identification-row">
              <Modal
              open={citizenOpen}
              onModalClose={() => setCitizen(false)}
              title="Citizenship Documents"
              >
                <IdentificationForm
                  editData={editData}
                  EmployeeId={EmployeeId}
                  type="1"
                  title="Citizenship"
                  onModalClose={() => setCitizen(false)}
                />
              </Modal>
              <Modal
              open={drivingOpen}
              onModalClose={() => setDriving(false)}
              title="Driving License"
              >
                <IdentificationForm 
                  editData={editData} 
                  type="2"
                  EmployeeId={EmployeeId} 
                  title="Driving License" 
                  onModalClose={() => setDriving(false)}
                />
              </Modal>
              <Modal
              open={passportOpen}
              onModalClose={() => setPassport(false)}
              title="Passport"
              >
              <IdentificationForm
                editData={editData}
                EmployeeId={EmployeeId}
                  type="3"
                  title="Passport"
                onModalClose={() => setPassport(false)}
              />
              </Modal>
            </div>
            
        </div>
    )
}

const Docs = (props) => {
    const {doc, EmployeeId, title, toggleEditButton, onItemDelete} = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const onModalClose = () => setOpen(false);
    const onDelete = () => dispatch(deleteEmployeeDocument(doc, onItemDelete));

    let docTitle = doc.type == '1' ? doc.CitizenshipNo : doc.type == '2'
      ? doc.DrivingLicenceNo : doc.PassportNo;
    return (
        <li className="employee-docs__additional-item">
            <div className="employee-docs__additional-name">
                <div className="employee-docs__additional-title">
                    {title}
                </div>
                <div className="employee-docs__additional-desc">
                    {doc.type == '1' ? 'Citizenship Number' :
                    doc.type == '2' ? 'Driving License' :
                    'Passport Number'}
                    : {docTitle}
                </div>
                <div className="employee-docs__additional-desc">
                    Issued on: {doc.IssuingDateEng && Date_Format_Reverse(doc.IssuingDateEng)}
                </div>
                {doc.type != '1' && <div className="employee-docs__additional-desc">
                    Expires on: {Date_Format_Reverse(doc.ValidUptoEng) || Date_Format_Reverse(new Date)}
                </div>}
            </div>
            {doc.ServerFileName ?  <div className="employee-docs__additional-file">
                <DocumentViewer document={getUploadPath(doc.ServerFileName) } >
                {/* <DocumentViewer document={'/Uploads/' + doc.ServerFileName} > */}
                    <a>
                        <Icons name="File" justSVG />
                    </a>
                </DocumentViewer>
            </div> : <div className="employee-docs__additional-file"></div>}
            <div className="employee-docs__additional-actions">
                <a onClick={()=> props.toggleEditButton(doc)}>
                    <Icons name="Edit" justSVG />
                </a>
                <Confirm
                    action={() => onDelete()}    
                >
                    <a>
                        <Icons name="Delete" justSVG />
                    </a>
                </Confirm>

            </div>
            {/* <Modal
                    open={open}
                    onModalClose={onModalClose}
                    title="Update Addittional Documents"
                >
                    <AddDocument onModalClose={onModalClose} doc={doc} EmployeeId={EmployeeId} />
            </Modal> */}
        </li>
    )
}

export default Identification
