import {
  AuthToken,
  RefreshToken,
  IdleTimeOut,
  ExpiresMinutes,
  Expires,
  UserDetail,
} from "constants/constants";
import store from "src/store/configureStore";

interface TokenInterface {
  saveAllToken: Function;
  clearAllToken: Function;
  adduserDetails: Function;
  getAuthToken: Function;
  getExpiresMinutes: Function;
  getRefreshToken: Function;
  getIdleTimeOut: Function;
  getUserDetail: Function;
  getExpires: Function;
}

class TokenService {
  saveAllToken({
    AuthTokenVal,
    RefreshTokenVal,
    IdleTimeOutVal,
    ExpiresMinutesVal,
    ExpiresVal,
  }) {
    localStorage.setItem(ExpiresMinutes, ExpiresMinutesVal);
    // localStorage.setItem(AuthToken, AuthTokenVal);
    localStorage.setItem(RefreshToken, RefreshTokenVal);
    localStorage.setItem(IdleTimeOut, IdleTimeOutVal);
    localStorage.setItem(Expires, ExpiresVal);
  }
  clearAllToken() {
    localStorage.setItem(ExpiresMinutes, null);
    localStorage.setItem(AuthToken, null);
    localStorage.setItem(RefreshToken, null);
    localStorage.setItem(IdleTimeOut, null);
    localStorage.setItem(UserDetail, null);
    localStorage.setItem(Expires, null);
  }
  adduserDetails(userDetails) {
    localStorage.setItem(UserDetail, userDetails);
  }
  // getAuthToken = () => localStorage.getItem(AuthToken);
  getAuthToken = () => {
    const state = store.getState();
    return state?.auth?.token ?? "";
  };
  updateAuthToken = (token) => localStorage.setItem(AuthToken, token);
  getExpiresMinutes = () => localStorage.getItem(ExpiresMinutes);
  getRefreshToken = () => localStorage.getItem(RefreshToken);
  getIdleTimeOut = () => localStorage.getItem(IdleTimeOut);
  getUserDetail = () => JSON.parse(localStorage.getItem(UserDetail) || "{}");
  getExpires = () => localStorage.getItem(Expires);
}

export default new TokenService();
