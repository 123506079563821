import React, {Component} from "react";
import Icons from "components/icons";
import Button from "components/button";
import classnames from "classnames";
import {MdDelete, MdEdit} from "react-icons/md";
import {expandIcon} from "src/scenes/taskTracker/common/expandIcon";
import {Enum_ProjectStatus} from "scenes/taskTracker/enums";

export class TableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: true,
    };
    this.dropdown = "";
  }

  onDrop() {
    this.setState({drop: !this.state.drop});
  }

  onModalClose = (name) => {
    this.setState({[name]: false});
  };

  onApprove = (e, data) => {
    e.stopPropagation();
    this.props.handleApprove(data);
  };

  onView = (e, data) => {
    e.stopPropagation();
    this.props.handleView(data);
  };

  onEditProject = (e, data) => {
    e.stopPropagation();
    this.props.editSelectedTemplate(data);
  };

  onDeleteProject = (e, data) => {
    e.stopPropagation();
    this.props.handleConfirmDelRecord(data.Id);
  };

  render() {
    const {props} = this;
    const {drop} = this.state;
    let trClass = "table-row";
    if (drop) {
      trClass = trClass + " expanded__tr";
    }

    let statusIcon = "";
    let statusColor = "";
    if (props.Status == Enum_ProjectStatus.Saved) {
      statusIcon = "CalendarPlus";
      statusColor = "#f38b00";
    } else if (props.Status == Enum_ProjectStatus.Forwarded) {
      statusIcon = "Forwarded";
      statusColor = "#f38b00";
    } else if (props.Status == Enum_ProjectStatus.Reviewed) {
      statusIcon = "Like";
      statusColor = "#007eda";
    } else if (props.Status == Enum_ProjectStatus.Approved) {
      statusIcon = "Doublecheck";
      statusColor = "#619a17";
    } else if (props.Status == Enum_ProjectStatus.Denied) {
      statusIcon = "Cross";
      statusColor = "#e23a3a";
    }

    return (
      <>
        <tr
          className="table-row expanded_tr"
          role="row"
          key={props.index}
          id={`toggleIcon${props.index}`}
        >
          <td className="e-rowcell" role="gridcell">
            {props.TemplateDetailData &&
              props.TemplateDetailData.length > 0 &&
              expandIcon(drop)}
          </td>
          <td className="e-rowcell " role="gridcell">
            <span className="primary-color">{props.CategoryName}</span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span className="primary-color">
              {props.TemplateDetailData &&
                props.TemplateDetailData.length > 0 &&
                props.TemplateDetailData.length}{" "}
              {props.TemplateDetailData && props.TemplateDetailData.length > 1
                ? "items"
                : "item"}
            </span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <span></span>
          </td>
          <td className="e-rowcell " role="gridcell">
            <div className="flex flex-gap-10">
              <Button
                title="Edit"
                icon={<Icons name="Edit" />}
                leftIcon={<MdEdit />}
                buttonType="icon-button"
                bg="white"
                onClick={(e) => this.onEditProject(e, props)}
              />
              <Button
                title="Delete"
                icon={<Icons name="Delete" />}
                leftIcon={<MdDelete />}
                buttonType="icon-button"
                bg="white"
                onClick={(e) => this.onDeleteProject(e, props)}
              />
            </div>
          </td>
        </tr>

        {props.TemplateDetailData &&
          props.TemplateDetailData.map((templateDetail, i) => {
            const rowClasses = classnames({
              "table-details": true,
              "custom-row": true,
              "expanded__tr-details": true,
              [`details-${props.index}`]: true,
              "row-expand": true,
            });
            return (
              <tr className={rowClasses} key={i}>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
                <td>
                  <span className="detail detail-col">
                    <div className="project">
                      <span className="pl-12">{templateDetail.TypeName}</span>
                    </div>
                  </span>
                </td>
                <td>
                  <span className="detail detail-col">
                    <div className="project">
                      <span className="pl-12">
                        {templateDetail.ResponsiblePersonName}
                      </span>
                    </div>
                  </span>
                </td>
                <td>
                  <span className="detail"></span>
                </td>
              </tr>
            );
          })}
      </>
    );
  }
}

export default TableRows;
