import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import "./style.scss";
import {Tabs, Tab} from "components/tabs/index.d";
import DateAndPlaceToReport from "./datePlaceToReport";
import Checklist from "./checklist";
import AssessIds from "./assessIds";
import WelcomeMessage from "./welcomeMessage";
import OnBoardingEmpProfile from "./onBoardEmpProfile";
import {
  GetBranches,
  GetDepartments,
  GetDesignations,
  DisplayClientSideMessage,
  GetHiringMethods,
} from "actions/commonAction";
import isEmpty from "isEmpty";
import {
  InitiateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
  SaveAccessIds,
  SaveCheckLists,
  SaveCheckListsAndFinish,
  clearEmployeeOnboardingCheckList,
} from "../action";

// let tabs =
class EmployeeOnBoardingPackage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      activeTab: "Date and Place",
      activeTabIndex: 0,
      dataToSubmit: null,
      tabs: [
        {
          name: "Date and Place",
          label: "Date and Place",
          key: "0",
          component: (
            <DateAndPlaceToReport
              handleSubmit={this.handleFormSubmit}
              changeActiveTab={this.changeActiveTab}
              employeeId={props.match.params.id}
              {...props}
            />
          ),
        },
        {
          name: "Welcome Message",
          label: "Welcome Message",
          key: "1",
          component: (
            <WelcomeMessage
              changeActiveTab={this.changeActiveTab}
              handleSubmit={this.handleFormSubmit}
              employeeId={props.match.params.id}
              {...props}
            />
          ),
        },
        {
          name: "Access and IDs",
          label: "Access and IDs",
          key: "2",
          component: (
            <AssessIds
              handleSubmit={this.handleFormSubmit}
              changeActiveTab={this.changeActiveTab}
              employeeId={props.match.params.id}
              {...props}
            />
          ),
        },
        {
          name: "Checklist",
          label: "Checklist",
          key: "3",
          component: (
            <Checklist
              handleSubmit={this.handleFormSubmit}
              changeActiveTab={this.changeActiveTab}
              employeeId={props.match.params.id}
              {...props}
            />
          ),
        },
      ],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const {id} = this.props.match.params;
    if (id) {
      let {departmentList, branchList, designationList, hiringMethodLists} = this.props;
      if (isEmpty(branchList)) {
        this._isMounted && (await this.props.GetBranches());
        branchList = this.props.branchList;
      }
      if (isEmpty(hiringMethodLists)) {
        this._isMounted && (await this.props.GetHiringMethods());
        hiringMethodLists = this.props.hiringMethodLists;
      }
      if (isEmpty(designationList)) {
        this._isMounted && (await this.props.GetDesignations());
        designationList = this.props.designationList;
      }
      if (isEmpty(departmentList)) {
        this._isMounted && (await this.props.GetDepartments());
        departmentList = this.props.departmentList;
      }
      this._isMounted && (await this.props.GetOnboardingEmployeeDetail(id));
      if (isEmpty(this.props.onboardingEmployeeDetail))
        this._isMounted && (await this.props.GetOnboardingEmployeeDetail(id));
      if (!isEmpty(this.props.onboardingEmployeeDetail)) {
        const {EmployeeDto, EmployeePhotoDto, EmployeeOnBoardingDto} =
          this.props.onboardingEmployeeDetail;
        // let HiringMethod = hiringMethodLists
        //   ? hiringMethodLists.find(
        //       (d) => d.value === EmployeeOnBoardingDto.HiringSourceId
        //     )
        //   : null;
        let HiringMethod = EmployeeOnBoardingDto.HiringMethod;
        let empDepartment = departmentList?.find(
          (d) => d.value === EmployeeDto?.DepartmentId
        );
        let empBranch = branchList?.find((d) => d.value === EmployeeDto?.BranchId);
        let empDesignation = designationList?.find(
          (d) => d.value === EmployeeDto?.DesignationId
        );

        let employeeInfo = {
          Photo: `${EmployeePhotoDto?.Photo}`,
          Name:
            EmployeeDto.FirstName +
            " " +
            (EmployeeDto?.MiddleName ? EmployeeDto.MiddleName + " " : "") +
            EmployeeDto?.LastName,
          Department: empDepartment,
          Designation: empDesignation,
          Branch: empBranch,
          AppointmentDate: moment(
            new Date(EmployeeOnBoardingDto?.AppointmentDate)
          ).format("DD MMM, YYYY"),
          HiringMethod: HiringMethod ? HiringMethod : null,
          EmpOnBoardId: !isEmpty(EmployeeOnBoardingDto.EmpOnBoardId)
            ? EmployeeOnBoardingDto.EmpOnBoardId
            : null,
        };
        this.setState({employeeInfo});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFormSubmit = async (data, activeTabIndex, Stage) => {
    let {employeeInfo} = this.state;
    if (activeTabIndex === "0") {
      let formData = {
        employeeOnBoardingDto: {
          Stage,
          ...data,
          // WelcomeMessage: data,
        },
      };
      await this.props.InitiateOnboardingEmployee(formData);
      this.setState({
        activeTab: "Welcome Message",
        activeTabIndex: "1",
        dataToSubmit: data,
      });
    } else if (activeTabIndex === "1") {
      let formData = {
        employeeOnBoardingDto: {
          ...data,
          Stage,
          // WelcomeMessage: data,
        },
      };
      await this.props.InitiateOnboardingEmployee(formData);
      // this.setState({ activeTab: 'Date and Place', activeTabIndex: "1", dataToSubmit: formData })

      // const { saveOnboardingEmployee } = this.props;
      this.setState({
        activeTab: "Access and IDs",
        activeTabIndex: "2",
        dataToSubmit: data,
      });

      // if (saveOnboardingEmployee) this.props.history.push('/admin/employee_onboarding/');
    } else if (activeTabIndex === "2") {
      let formData = {
        AccessIds: data,
        OnBoardRefId: parseInt(employeeInfo.EmpOnBoardId),
      };
      // debugger;
      await this.props.SaveAccessIds(formData);
      this.setState({
        activeTab: "Checklist",
        activeTabIndex: "3",
        dataToSubmit: formData,
      });

      // const { saveOnboardingAccessId } = this.props;
      // if (saveOnboardingAccessId) this.props.history.push('/admin/employee_onboarding/');
    } else if (activeTabIndex === "3") {
      let checkList =
        data &&
        data.map((d) => {
          return {Name: d.Name, IsSelected: d.Checked};
        });
      let formData = {
        CheckLists: checkList,
        // data &&
        // data.map((d) => {
        //   return {Name: d.Name};
        // }),
        OnBoardRefId: parseInt(employeeInfo.EmpOnBoardId),
      };
      // debugger;
      if (Stage === "Save") {
        await this.props.SaveCheckLists(formData);
        this.props.clearEmployeeOnboardingCheckList();
      } else if (Stage === "SaveAndFinished") {
        await this.props.SaveCheckListsAndFinish(formData);
        this.props.clearEmployeeOnboardingCheckList();
      }
      // this.setState({ activeTab: 'Date and Place', activeTabIndex: "1", dataToSubmit: formData })

      // const { saveOnboardingCheckLists } = this.props;
      // if (saveOnboardingAccessId)
      this.props.history.push("/admin/employee_onboarding/");
    }
  };

  changeActiveTab = (tab) => {
    if (tab === "0") this.setState({activeTab: "Date and Place"});
    else if (tab === "1") this.setState({activeTab: "Welcome Message"});
    else if (tab === "2") this.setState({activeTab: "Access and IDs"});
    else if (tab === "3") this.setState({activeTab: "Checklist"});
  };

  onClose = () => {
    this.props.history.push("/admin/employee_onboarding/");
  };

  render() {
    const {tabs, employeeInfo, activeTab} = this.state;
    // let isDisabled = activeTab
    return (
      <>
        {employeeInfo && <OnBoardingEmpProfile employeeInfo={employeeInfo} />}
        <div className="profile-tab subscription-tab-disabled">
          <Tabs noBackground activeTab={activeTab}>
            {tabs.map((tab) => {
              return (
                <Tab id={tab.name} key={tab.key} label={tab.label} name={tab.name}>
                  {tab.component ? tab.component : <span>{tab.label}</span>}
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  branchList: state.commonReducer.branchList,
  departmentList: state.commonReducer.departmentList,
  designationList: state.commonReducer.designationList,
  onboardingEmployeeDetail: state.employeeOnboarding.onboardingEmployeeDetail,
  saveOnboardingEmployee: state.employeeOnboarding.saveOnboardingEmployee,
  saveOnboardingCheckLists: state.employeeOnboarding.saveOnboardingCheckLists,
  hiringMethodLists: state.commonReducer.hiringMethodLists,
});

const mapDispatchToProps = {
  GetBranches,
  GetDesignations,
  GetDepartments,
  InitiateOnboardingEmployee,
  GetOnboardingEmployeeDetail,
  GetHiringMethods,
  SaveAccessIds,
  SaveCheckLists,
  clearEmployeeOnboardingCheckList,
  DisplayClientSideMessage,
  SaveCheckListsAndFinish,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOnBoardingPackage);
