import classnames from "classnames";
import React, {useEffect, useState} from "react";
import LeaveTable from "./leaveTable";
import {useDispatch, useSelector} from "react-redux";
import {getScheduleEmployeeUnavailability} from "../../helpers/action";
import Form from "components/form";
import dayjs from "dayjs";
import OffDayTable from "./dayOffTable";

const tabs = [
  {id: 1, name: "on_leave", label: "On Leave"},
  {id: 2, name: "day_off", label: "Day Off"},
];

function Unavailability(props) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [teamId, setTeamId] = useState(null);
  var curr = new Date();
  var firstDayOfMonth = dayjs(new Date(curr.getFullYear(), curr.getMonth(), 1)).format(
    "YYYY/MM/DD"
  );
  var lastDayOfMonth = dayjs(new Date(curr.getFullYear(), curr.getMonth() + 1, 0)).format(
    "YYYY/MM/DD"
  );
  const [date, setDate] = useState({startDate: firstDayOfMonth, endDate: lastDayOfMonth});
  const {scheduleEmployeeUnavailability, teamList} = useSelector(
    (state: any) => state.roosterReducer
  );
  const dispatch = useDispatch();
  const onTabClick = (tabItem) => {
    setActiveTab(tabItem);
  };
  // useEffect(()=>{
  //     dispatch(getScheduleEmployeeUnavailability({...date,teamId:14}));
  // },[]);

  useEffect(() => {
    console.log({date});
    if (date.startDate && date.endDate && teamId) {
      dispatch(getScheduleEmployeeUnavailability({...date, teamId: teamId.value}));
    }
  }, [date, teamId]);
  return (
    <div className="unavailable">
      <div className="unavailable-filters">
        <Form
          name="daterange"
          width="200px"
          formName="customdateinput"
          isDateRange
          isNepali={false}
          onChange={(name, value) => setDate(value)}
          value={date}
          enableOkButton
        />
        <Form
          width="200px"
          name="teamId"
          value={teamId}
          onChange={(name, value) => setTeamId(value)}
          formName="reactselect"
          placeholder="Select Team"
          options={teamList}
        />
      </div>
      <div className="unavailable-tabs">
        <ul className="unavailable-tabs__list">
          {tabs &&
            tabs.map((tab) => (
              <SchedulerTab
                active={activeTab}
                key={tab.id}
                tab={tab}
                onTabClick={onTabClick}
              />
            ))}
        </ul>
        {activeTab.id == 1 && (
          <LeaveTable data={scheduleEmployeeUnavailability?.OnLeave ?? []} />
        )}
        {activeTab.id == 2 && (
          <OffDayTable data={scheduleEmployeeUnavailability?.DayOff ?? []} />
        )}
      </div>
    </div>
  );
}

export const SchedulerTab = ({tab, onTabClick, active}) => {
  const tabClass = classnames("unavailable-tabs__item", {
    "unavailable-tabs__active": active.id === tab.id,
  });
  return (
    <li className={tabClass} onClick={() => onTabClick(tab)}>
      <div className="unavailable-tabs__label">{tab.label}</div>
    </li>
  );
};

export default Unavailability;
