import React from "react";
import "./style.scss";
import isEmpty from "isEmpty";
import {useSelector} from "react-redux";
import {currencyCommaSeperator} from "../../helpers/constants";

function LoanInformation() {
  const consumerLoanDetails = useSelector(
    (state) => state.sbiLoanReducer.consumerLoanDetails
  );
  if (isEmpty(consumerLoanDetails)) return null;
  const {
    LoanDetail: {LoanAmount, PurposeOfLoan, PurposeOfLoanName, RepaymentTenure},
  } = consumerLoanDetails || {
    LoanDetail: {
      LoanAmount: "",
      PurposeOfLoan: "",
      PurposeOfLoanName: "",
      RepaymentTenure: "",
    },
  };

  return (
    <div className="homeloan-view-employeedetails homeloan-view-vehicledetails healthCard__health mt-md">
      <div className="healthCard-heading">
        <div className="text-bg mb-sm">Loan Information</div>
      </div>
      <div className="healthCard__health__details">
        <div className="placement-details">
          <div className="placement-details__block">
            <div className="placement-details__table">
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">Loan Purpose</div>
                <div className="placement-details__col placement-val">
                  {PurposeOfLoanName || '-'}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">Pays In</div>
                <div className="placement-details__col placement-val">
                  {RepaymentTenure} Months
                  {/* NPR {currencyCommaSeperator(vehicleLoanDetail?.RequestAmount)} */}
                </div>
              </div>
              <div className="placement-details__row">
                <div className="placement-details__col placement-title">
                  Loan Requested
                </div>
                <div className="placement-details__col placement-val">
                  NPR {currencyCommaSeperator(LoanAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   vehicleLoanDetail: state.loanReducer.vehicleLoanDetail,
// });

export default LoanInformation;
