import React, { Component } from "react";
import { connect } from "react-redux";
import { GetCountryList, GetCurrencyList } from "actions/commonAction";
import FormGroup from 'components/form'
import Modal from 'components/modal'
import { IoIosRose } from 'react-icons/io';
import { MdArrowForward, MdClose } from 'react-icons/md'
import isEmpty from 'isEmpty'
import { updateCompanySettings } from './helpers/actions';
import Loaders from 'components/loaders'
import CropImage from './helpers/cropImage'
import Button from 'components/button'
import CropCoverImage from './helpers/cropCoverImage'

export class Company extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            selectedCountry: [],
            selectedCurrency: [],
            isModalOpen: false,
            isCoverImageModalOpen: false,
            loading: false,
            title: 'Preview Logo',
            modalType: '',
            logoImage: null,
            bannerImage: null,
            coverImage: null,

            modalImage: null,
            isImageOpen: false,
            modalImageShow: '',
            companyInfo: {},
            errors: '',
            crop: {},
            finalBanner: null,
            finalLogo: null,
            finalCoverImage: null,
            welcomeText: '',
        };

        this.handleDropdown = this.handleDropdown.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveButton = this.onSaveButton.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onImageClose = this.onImageClose.bind(this);

    }
    public banner = '';
    async componentDidMount() {
        this.setState({ ...this.state, loading: true })
        await this.props.GetCountryList();
        await this.props.GetCurrencyList();
        const { companyInformation } = this.props;
        if (!isEmpty(companyInformation)) {
            this.setState({
                ...this.state,
                // finalLogo:companyInformation.CompanyLogo.split("/images/")[1],
                // finalBanner:companyInformation.LoginPageImage.split("/images/")[1]
                finalLogo: companyInformation.CompanyLogo,
                finalBanner: companyInformation.LoginPageImage,
                welcomeText: companyInformation.WelcomeText,
                coverImage: companyInformation.CoverImage
            })
        }
        this.setState({ ...this.state, loading: false })

    }
    componentDidUpdate(prevProps, prevState) {
        const { countryList, currencyList, companyInformation, uploadedFileList, uploadType } = this.props;
        if (!isEmpty(companyInformation)) {
            if (prevProps.countryList !== countryList && !isEmpty(countryList)) {
                const country = countryList.find(item => item.value === companyInformation.CountryId)
                this.setState({ ...this.state, selectedCountry: country })
            }
            if (prevProps.currencyList !== currencyList && !isEmpty(currencyList)) {
                const currency = currencyList.find(item => item.label === companyInformation.CurrencyCode)
                this.setState({ ...this.state, selectedCurrency: currency })
            }
        }

        if (!isEmpty(uploadType) && uploadType !== prevProps.uploadType) {

            if (uploadType.type === 'banner') {
                this.setState({ ...this.state, finalBanner: uploadType.files[0].FileLocation + uploadType.files[0].ServerFileName }, () => this.onSave())
            }
            if (uploadType.type === 'logo') {
                this.setState({ ...this.state, finalLogo: uploadType.files[0].FileLocation + uploadType.files[0].ServerFileName }, () => this.onSave())
            }
            if (uploadType.type === 'coverImage') {
                this.setState({ ...this.state, finalCoverImage: uploadType.files[0].FileLocation + uploadType.files[0].ServerFileName }, () => this.onSave())
            }
            // if(this.state.modalType === 'logo'){
            //     this.setState({finalLogo:uploadedFileList[0].GeneratedFileName},()=>this.onSave())
            // }else{
            //     this.setState({finalBanner:uploadedFileList[0].GeneratedFileName},()=>this.onSave())
            // }
            // this.setState({
            //     logoImage:'',
            //     bannerImage:'',
            //     title:'',
            //     modalImage:'',
            //     modalType:'',
            //     isModalOpen:false
            // });

        }
    }

    onSave() {
        const { updateCompanySettings, companyInformation } = this.props;
        const { selectedCurrency, selectedCountry, finalBanner, finalLogo, finalCoverImage, welcomeText } = this.state;

        const data = {
            CompanyId: companyInformation.CompanyId,
            CountryId: selectedCountry && selectedCountry.value ? selectedCountry.value : 0,
            CurrencyCode: selectedCurrency && selectedCurrency ? selectedCurrency.label : null,
            CompanyLogo: finalLogo.toString().substring(finalLogo.indexOf("Logo")),
            LoginPageImage: finalBanner.toString().substring(finalBanner.indexOf("Logo")),
            CoverImage: finalCoverImage && finalCoverImage.toString().substring(finalCoverImage.indexOf("Logo")),
            WelcomeText: welcomeText
        }
        this.onModalClose()
        updateCompanySettings(data)
    }

    resetImages = () => {
        this.setState({ ...this.state, finalBanner: '', finalLogo: '' })
    }

    resetCoverImages = () => {
        this.setState({ ...this.state, finalCoverImage: '' })
    }
    handleDropdown = (name, selected) => {
        this.setState({ [name]: selected }, () => {
            this.onSave()
        })
    };
    handleChange = (name, val) => {
        this.setState({ [name]: val })
    }

    handleBlur = (name, val) => {
        this.onSave()
    }

    onModalClose = () => {
        this.setState({
            ...this.state,
            isModalOpen: false,
            isCoverImageModalOpen: false
        })
    }

    onImageClose = () => {
        this.setState({
            title: '',
            isImageOpen: false,
            modalImageShow: ''
        })
    }
    onCrop = crop => {
        console.log(crop)
        this.setState({ crop });
    };

    onImageChange(e) {
        const accept = ["image/png", "image/jpeg", "image/svg+xml"]
        const { name, files } = e.target;
        if (accept.includes(files[0].type)) {
            // this.setState({[name]:files[0],errors:''})
            this.setState(prevState => ({
                ...prevState,
                [name]: files[0],
                errors: {
                    ...prevState.errors,
                    [name]: ''
                }
            }))
        } else {
            // this.setState({errors:'Please choose JPG/PNG/SVG file type'});
            this.setState(
                prevState => ({
                    ...prevState,
                    errors: {
                        ...prevState.errors,
                        [name]: 'Please choose JPG/PNG/SVG file type'
                    }
                }),
                () => console.log(this.state.errors)
            )
        }
    }
    
    onSaveButton() {

    }
    _renderModal() {
        const {
            state: { isModalOpen, modalImage, title, modalType },
            props: { companyInformation: { CompanyLogo, LoginPageImage } },
            onModalClose } = this;

        return (
            <Modal
                open={isModalOpen}
                ref="crop"
                onModalClose={onModalClose}
                className="preview-modal"
                title={"Update Company Logo & Page Banner"}
            >
                <CropImage
                    ref={ref => this.banner = ref}
                    state={this.state}
                    onSave={this.onSave}
                    resetImages={this.resetImages}
                    onModalClose={this.onModalClose}
                // companyLogo = {getBaseAxios() + '/images/' + CompanyLogo}
                // companyBanner = {getBaseAxios() + '/images/' + LoginPageImage}
                />
            </Modal>


        )
    }

    render() {
        const { countryList, currencyList, auth } = this.props;
        const { loading, isCoverImageModalOpen } = this.state
        const publicRecruitmentBaseUrl = auth.publicRecruitmentBaseUrl ? auth.publicRecruitmentBaseUrl.PublicRecruitmentBaseUrl : "";

        if (loading) {
            return (
                // <BackLoader open={loading} />
                <Loaders type="spinner" loading={loading} />
            )
        }
        return (
            <div className="company">
                <div className="company-container">
                    <div className="company-table">
                        <div className="company-table__header">
                            <div className="company-row">
                                <div className="company-column">
                                    <h3>Types</h3>
                                </div>
                            </div>
                        </div>
                        <div className="company-table__main">
                            <div className="company-row">
                                <div className="company-column">
                                    <span className="col-name">Country</span>
                                </div>
                                <div className="company-column">
                                    <FormGroup
                                        formName="reactselect"
                                        name="selectedCountry"
                                        placeholder="Select country"
                                        value={this.state.selectedCountry}
                                        options={countryList}
                                        onChange={this.handleDropdown}
                                        width="200px"
                                    />
                                </div>
                            </div>
                            <div className="company-row">
                                <div className="company-column">
                                    <span className="col-name">Currency</span>
                                </div>
                                <div className="company-column">
                                    <FormGroup
                                        formName="reactselect"
                                        name="selectedCurrency"
                                        placeholder="Select currency"
                                        value={this.state.selectedCurrency}
                                        options={currencyList}
                                        onChange={this.handleDropdown}
                                        width="200px"
                                    />
                                </div>
                            </div>

                            <div className="company-row">
                                <div className="company-column">
                                    <span className="col-name">Logo & Banner</span>
                                </div>
                                <div className="company-column">
                                    <Button
                                        buttonType="icon-button"
                                        leftIcon={<IoIosRose />}
                                        onClick={() => this.setState({
                                            ...this.state,
                                            isModalOpen: true,
                                        })}
                                        title="Preview Logo"
                                        bg="white"
                                    />
                                </div>
                            </div>

                        </div>
                        {/* <div className="comapny-table__footer">
                        <div className="company-row">
                            <div className="company-column">
                                <button className="btn btn-blue">Save All</button>
                            </div>
                        </div>
                    </div> */}
                    </div>

                    {publicRecruitmentBaseUrl !== '' &&

                        <div className="company-table">
                            <div className="company-table__header">
                                <div className="company-row">
                                    <div className="company-column">
                                        <h3>Public Recruitment</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="company-table__main">
                                <div className="company-row">
                                    <div className="company-column">
                                        <span className="col-name">Welcome Text</span>
                                    </div>
                                    <div className="company-column">
                                        <FormGroup
                                            formName="textarea"
                                            name="welcomeText"
                                            placeholder="Welcome text..."
                                            value={this.state.welcomeText}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            width="200px"
                                        />
                                    </div>
                                </div>

                                <div className="company-row">
                                    <div className="company-column">
                                        <span className="col-name">Cover Image</span>
                                        {this.state.bannerImage_url}
                                    </div>
                                    <div className="company-column">
                                        <Button
                                            buttonType="icon-button"
                                            leftIcon={<IoIosRose />}
                                            onClick={() => this.setState({
                                                ...this.state,
                                                isCoverImageModalOpen: true,
                                            })}
                                            title="Preview Cover Image"
                                            bg="white"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>
                {
                    this._renderModal()
                }
                <CropCoverImage isOpen={isCoverImageModalOpen}
                    resetImages={this.resetCoverImages}
                    companyInformation={this.props.companyInformation}
                    onModalClose={() => this.setState({ ...this.state, isCoverImageModalOpen: false })} />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    companyInformation: state.commonReducer.companyInformation,
    uploadedFileList: state.commonReducer.uploadedFileList,
    uploadType: state.commonReducer.uploadType,
    countryList: state.commonReducer.countryList,
    currencyList: state.commonReducer.currencyList,
    auth: state.auth
});

const mapDispatchToProps = {
    GetCountryList,
    GetCurrencyList,
    updateCompanySettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
