import {
  SET_SPINNER_LOADING,
  GET_DISCIPLINARY_CASE_DETAILS,
  DISCIPLINARY_CASE_DETAILS_LOADING,
  GET_DISCIPLINARY_ACTION_LIST,
  GET_DISCIPLINARY_STAGES,
  MOVE_DISCIPLINARY_ACTION_TO_NS,
} from "actions/types";
import axios from "helpers/axiosinstance";
import isEmpty from "isEmpty";
import {toastMessage} from "actions/validateAction";
import RouteEnum from "constants/routeEnum";
import {getErrorResponse} from "helpers/getApiResponse";

//#region disciplinary-case
export const RegisterDisciplinaryCase =
  (data, getDisciplinaryActionList, handleFormDisplay) => async (dispatch) => {
    dispatch(setSpinnerLoading(true));
    await axios
      .post("/v1/disciplinary-action/cases", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.Status) {
          getDisciplinaryActionList();
          handleFormDisplay(false);
        }
        toastMessage(dispatch, response);
        dispatch(setSpinnerLoading(false));
      })
      .catch((err) => {
        dispatch(setSpinnerLoading(false));
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to register disciplinary case.",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const EditDisciplinaryCase =
  (data, getDisciplinaryActionList, handleFormDisplay) => async (dispatch) => {
    dispatch(setSpinnerLoading(true));
    await axios
      .put("/v1/disciplinary-action/cases", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.Status) {
          getDisciplinaryActionList();
          handleFormDisplay(false);
        }
        toastMessage(dispatch, response);
        dispatch(setSpinnerLoading(false));
      })
      .catch((err) => {
        dispatch(setSpinnerLoading(false));
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to register disciplinary case.",
          },
        };
        toastMessage(dispatch, response);
      });
  };
//#endregion

//#region disciplinary-action
export const getDisciplinaryCaseDetails = (dispCaseId) => async (dispatch) => {
  dispatch({
    type: GET_DISCIPLINARY_CASE_DETAILS,
    payload: null,
  });
  dispatch(setSpinnerLoading(true));
  dispatch({type: DISCIPLINARY_CASE_DETAILS_LOADING});
  await axios
    .get(`/v1/disciplinary-action/cases/${dispCaseId}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_DISCIPLINARY_CASE_DETAILS,
          payload: response.data,
        });
      }
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get disciplinary case details.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const closeDisciplinaryCase = (data, onClose) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  await axios
    .post("/v1/disciplinary-action/cases/close", data)
    .then((response) => {
      if (response.data.Status) {
        onClose();
      }
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to close disciplinary case.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addCounsellingNote = (data, onClose) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  await axios
    .post("/v1/disciplinary-action/actions/counselling", data)
    .then(async (response) => {
      if (response.data.Status) {
        await dispatch(getDisciplinaryCaseDetails(data.CaseRefId));
        onClose();
      }
      toastMessage(dispatch, response);
      dispatch(setSpinnerLoading(false));
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to add counselling note.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addVerbalWarningNote = (data, onClose) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));
  await axios
    .post("/v1/disciplinary-action/actions/verbalwarning", data)
    .then(async (response) => {
      if (response.data.Status) {
        await dispatch(getDisciplinaryCaseDetails(data.CaseRefId));
        onClose();
      }
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to add verbal warning note.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const addWrittenWarningNote =
  (data, disciplinaryCaseId, onClose) => async (dispatch) => {
    dispatch(setSpinnerLoading(true));
    let fd = new FormData();

    // data.fileList.map((x, i) => {
    //   fd.append(`WrittenWarningNoteDocDto,${i + 1}`, x);
    // });
    // fd.append("WrittenWarningNoteDto", JSON.stringify(data));
    await axios
      .post("/v1/disciplinary-action/actions/writtenwarning", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async (response) => {
        if (response.data.Status) {
          await dispatch(getDisciplinaryCaseDetails(disciplinaryCaseId));
          onClose();
        }
        dispatch(setSpinnerLoading(false));
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        dispatch(setSpinnerLoading(false));
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to add written warning note.",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const addActionTakeNote =
  (data, disciplinaryCaseId, onClose) => async (dispatch) => {
    dispatch(setSpinnerLoading(true));
    let fd = new FormData();

    // data.fileList.map((x, i) => {
    //   fd.append(`ActionTakenNoteDtoDocDto,${i + 1}`, x);
    // });
    // fd.append("ActionTakenNoteDto", JSON.stringify(data));
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post("/v1/disciplinary-action/actions/taken", data, config)
      .then(async (response) => {
        if (response.data.Status) {
          await dispatch(getDisciplinaryCaseDetails(disciplinaryCaseId));
          onClose();
        }
        dispatch(setSpinnerLoading(false));
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        dispatch(setSpinnerLoading(false));
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to add action taken note.",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const addDisciplinaryComments = (data, onClose) => async (dispatch) => {
  dispatch(setSpinnerLoading(true));

  await axios
    .post("/v1/disciplinary-action/actions/comment", data)
    .then(async (response) => {
      if (response.data.Status) {
        await dispatch(getDisciplinaryCaseDetails(data.CaseId));
        onClose();
      }
      dispatch(setSpinnerLoading(false));
      toastMessage(dispatch, response);
    })
    .catch((err) => {
      dispatch(setSpinnerLoading(false));
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to add comments.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const moveDisciplinaryActionToNextStage = async (data) => {
  //dispatch(setSpinnerLoading(true));
  try {
    let result = await axios.put("/v1/disciplinary-action/cases/movetostage", data);
    return result.data;
  } catch (err) {
    return getErrorResponse(err?.message);
  }
};

//#endregion

// Set loading state
export const setSpinnerLoading = (data) => {
  return {
    type: SET_SPINNER_LOADING,
    payload: data,
  };
};

export const GetDisciplinaryStages = () => async (dispatch) => {
  await axios
    .get("/v1/disciplinary-action/stages")
    .then((response) => {
      dispatch({
        type: GET_DISCIPLINARY_STAGES,
        payload: response.data,
      });
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get disciplinary stages.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetDisciplinaryActionList = (data) => async (dispatch) => {
  await axios
    .post("/v1/disciplinary-action/cases/list", data)
    .then((response) => {
      if (response.data.Status) {
        dispatch({
          type: GET_DISCIPLINARY_ACTION_LIST,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response.data);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.Message ? err.Message : "Failed to get disciplinary.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const MoveDisciplinaryActionToNextStage = (data, props) => async (dispatch) => {
  await axios
    .put("/v1/disciplinary-action/cases/movetostage", data)
    .then((response) => {
      if (response.data.Status) {
        props.history.push(
          RouteEnum.ViewDisciplinaryActionPath + response.data.data.disciplinaryCaseId
        );
        dispatch({
          type: MOVE_DISCIPLINARY_ACTION_TO_NS,
          payload: response.data.data,
        });
      }
      toastMessage(dispatch, response.data);
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to move to next stage.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetDisciplinaryDetail = (disciplinaryCaseId) => async (dispatch) => {
  await axios
    .delete("/v1/hr/disciplinary-case-detail", disciplinaryCaseId)
    .then((response) => {
      if (response.data.Status) {
      } else {
        toastMessage(dispatch, response.data);
      }
    })
    .catch((err) => {
      const response = {
        data: {
          MessageType: "Danger",
          Message: err.message ? err.message : "Failed to get disciplinary case detail.",
        },
      };
      toastMessage(dispatch, response);
    });
};

export const DeleteDisciplinaryCase =
  (disciplinaryCaseId, onDeleteSuccess) => async (dispatch) => {
    await axios
      .delete(`/v1/disciplinary-action/cases?disciplinaryCaseId=${disciplinaryCaseId}`)
      .then((response) => {
        onDeleteSuccess();
        toastMessage(dispatch, response.data);
      })
      .catch((err) => {
        const response = {
          data: {
            MessageType: "Danger",
            Message: err.message ? err.message : "Failed to delete disciplinary case.",
          },
        };
        toastMessage(dispatch, response);
      });
  };
