import React, {useState} from "react";
import FormInput from "components/form";
import DataSelect from "components/dataselect";
import {useSelector} from "react-redux";
import ExportExcel from "components/excel/exportExcel";

const exportColumns = [
  {value: "BoardId", label: "Board Id"},
  {value: "BoardName", label: "Board Name"},
  {value: "TaskName", label: "Task Name"},
  {value: "Text", label: "Activity"},
  {value: "CreatedBy", label: "User"},
  {value: "CreatedDate", label: "Date"},
  {value: "Time", label: "Time"},
];
const exportName = "Board-Activities";

const SearchFilter = (props) => {
  const {filter, onChange, handleDateRangePicker, handleExcelExport} = props;
  const [exportData, setExportData] = useState([]);

  const handleExcelExportClick = async () => {
    var data = await handleExcelExport(true);
    setExportData(data);
  };

  return (
    <div className="activityReport-filters">
      {filter.fromDate}
      <FormInput
        formName="reactdaterange"
        name="date"
        placeholder="Date filter"
        startDate={filter.startDate}
        endDate={filter.endDate}
        onChange={handleDateRangePicker}
        width="300px"
      />
      <FormInput
        onChange={onChange}
        name="board"
        placeholder="search by board name"
        value={filter.board}
        width="250px"
      />
      <FormInput
        onChange={onChange}
        name="employee"
        placeholder="search by employee"
        value={filter.employee}
        width="250px"
      />

      <ExportExcel
        onClick={handleExcelExportClick}
        fileName={exportName}
        columns={exportColumns}
        sheets={[{sheetName: exportName, data: exportData as []}]}
        className="button button-subtle"
      />
    </div>
  );
};

export default SearchFilter;
