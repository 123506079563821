import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import Button from "components/button";
import shortid from "shortid";
import Icons from "components/icons";
import {
  GetChecklistItems,
  SaveEmployeeOnboardingCheckList,
  setCheckList,
  GetOnboardingEmployeeDetail,
} from "../action";
import Loaders from "components/loaders";

export default function Checklist(props) {
  const [checklist, setChecklist] = useState({});
  const [checklistMenu, setChecklistMenu] = useState([]);
  const [addCheckListItem, setAddCheckListItem] = useState(null);
  const [isRowAdding, setIsRowAdding] = useState(false);
  const [checklistError, setCheckListError] = useState("");
  const dispatch = useDispatch();

  const {checkListCategories, onboardingEmployeeDetail, checkList, requesting} =
    useSelector((state) => state.employeeOnboarding);

  useEffect(() => {
    dispatch(GetChecklistItems());
    dispatch(GetOnboardingEmployeeDetail(props.match.params.id));
  }, []);

  useEffect(() => {
    if (!isEmpty(onboardingEmployeeDetail?.EmployeeOnboardingCheckList)) {
      let data = [...onboardingEmployeeDetail.EmployeeOnboardingCheckList];
      setChecklistMenu(data);
      setChecklist({});
    } else if (!isEmpty(checkListCategories)) {
      // setChecklist(checkListCategories[0]);
      // setChecklistMenu(checkListCategories[0].subCategory);
    }
  }, [checkListCategories]);

  useEffect(() => {
    dispatch(SaveEmployeeOnboardingCheckList(checklistMenu));
  }, [checklistMenu]);

  useEffect(() => {
    setChecklist(checkList);
  }, [checkList]);

  const addRow = (flag) => {
    setIsRowAdding(flag);
  };

  const handleFieldChange = (name, value) => {
    dispatch(setCheckList(value));
    let selectedCategory =
      checkListCategories && checkListCategories.find((ch) => ch?.Id == value?.Id);
    setChecklistMenu(
      !isEmpty(selectedCategory?.subCategory) ? selectedCategory?.subCategory : []
    );
  };

  const onClose = () => {
    props.history.push("/admin/employee_onboarding/");
  };

  const handleFormSubmit = (data) => {
    let formData = [...checklistMenu];
    formData =
      formData &&
      formData.map((f) => {
        return {
          ...f,
          IsSelected: f.Checked,
        };
      });
    props.handleSubmit(formData, "3", data);
  };

  const setSelectTask = (menu, Checked) => {
    let item = checklistMenu.find((c) => c.Id == menu.Id);
    item = {
      ...item,
      Checked,
    };
    setChecklistMenu((prev) => prev.map((m) => (m.Id == menu.Id ? item : m)));
  };

  const removeAccessIdRow = (menu) => {
    let newItems = checklistMenu.filter((m) => m.Id != menu.Id);
    setChecklistMenu(newItems);
  };

  const addToCheckListItem = () => {
    let newItems = [...checklistMenu];
    let Id = shortid.generate();

    if (addCheckListItem) {
      newItems.push({
        Id,
        Name: addCheckListItem,
        Checked: true,
      });
      setChecklistMenu(newItems);
      setIsRowAdding(false);
      setAddCheckListItem(null);
      setCheckListError(null);
    } else {
      setCheckListError("please fill the checklist");
    }
  };
  return (
    <div className="date-and-place">
      <div className="date-and-place__container">
        <div className="date-and-place__body">
          <div className="date-and-place__header">Check Lists</div>
          <div className="date-and-place__header">
            <FormGroup
              formName="reactselect"
              onChange={(name, value) => handleFieldChange(name, value)}
              name="messageTemplate"
              value={checklist}
              options={checkListCategories}
              placeholder="Checklist"
              loadingType="skeleton"
              width="270px"
            />
          </div>
          <div className="">
            {checklistMenu &&
              checklistMenu.map((menu) => {
                return (
                  <div key={menu.Id} className="flex mb-sm">
                    <FormGroup
                      formName="checkgroup"
                      name="task"
                      onChange={(e) => setSelectTask(menu, e.target.checked)}
                      value={menu.Id}
                      label={menu.Name}
                      checked={menu.Checked}
                      width="270px"
                      labelPosition="right"
                    />
                    <span
                      onClick={() => removeAccessIdRow(menu)}
                      className="pointers ml-sm"
                    >
                      <Icons name="Trash" />
                    </span>
                  </div>
                );
              })}
            {isRowAdding && (
              <>
                <div className="flex mb-sm">
                  <FormGroup
                    // formName="textInput"
                    name="checklisting"
                    onChange={(name, value) => {
                      setAddCheckListItem(value);
                      value
                        ? setCheckListError(null)
                        : setCheckListError("please fill the checklist");
                    }}
                    value={addCheckListItem}
                    // label={menu.Name}
                    // checked={menu.Checked}
                    width="250px"
                  />
                  <span onClick={() => addToCheckListItem()} className="pointers">
                    <Icons name="Check" color="green" />
                  </span>
                  <span onClick={() => addRow(false)} className="pointers ml-sm">
                    <Icons name="Trash" />
                  </span>
                </div>
                {checklistError && <div className="mb-sm warning">{checklistError}</div>}
              </>
            )}
          </div>
          <Button
            bg="success"
            isDisabled={isRowAdding}
            title="Add Checklist"
            onClick={() => addRow(true)}
          />
          <div className="date-and-place__footer">
            <Button
              bg="primary"
              title="Save and Finish"
              onClick={() => handleFormSubmit("SaveAndFinished")}
              isDisabled={requesting}
            />
            <Button
              bg="primary"
              title="Save"
              onClick={() => handleFormSubmit("Save")}
              isDisabled={requesting}
            />
            <Button bg="subtle" onClick={() => onClose()} title="Cancel" />
          </div>
        </div>
      </div>
      {requesting ? <Loaders loading={requesting} /> : null}
    </div>
  );
}
