import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Table from "components/customTable";
import Pagination from "components/common/pagination";
import EmployeeEditForm from "./employeeEditForm";
import Modal from "components/modal";
import classNames from "classnames";

const ButtonCell = (props) => {
  const {original} = props.row;
  const [open, setOpen] = useState(false);
  const onModalOpen = () => setOpen(true);
  const onModalClose = () => setOpen(false);
  const dispatch = useDispatch();
  const onEdit = () => {
    console.log("Edit");
  };
  return (
    <div className="shifts-table__col">
      <div className="shifts-table__links">
        <span onClick={() => onModalOpen()}>Edit</span>
      </div>
      <Modal
        open={open}
        onModalClose={onModalClose}
        className="hide-modal-overflow"
        title={
          original?.EmployeeId + " - " + original?.EmployeeName ?? "Edit Employee Team"
        }
        width="450px"
      >
        <EmployeeEditForm editData={props?.row?.original} onModalClose={onModalClose} />
      </Modal>
    </div>
  );
};

const EmployeeIdCell = (props) => {
  const {original} = props.row;
  return (
    <div className="shifts-table__col">
      <div className="shifts-table__links">
        <span>{original?.EmployeeId}</span>
      </div>
    </div>
  );
};
const TeamCell = (props) => {
  const {original} = props.row;
  const title = original.IsArchivedTeam
    ? `${original?.TeamName} (Archived)`
    : original?.TeamName;
  return (
    <div
      className={classNames({"shifts-table__archive-cell": original.IsArchivedTeam})}
      title={title}
    >
      {original?.TeamName}
    </div>
  );
};

function TeamTable(props) {
  const {pageIndex, pageSize, handleRowChange, handleIndexChange} = props;
  const {teamEmployeeList} = useSelector((state: any) => state.roosterReducer);
  const cols = [
    {
      Header: "Employee Id",
      accessor: "Id",
      Cell: EmployeeIdCell,
      width: 100,
    },
    {
      Header: "Employee Name",
      accessor: "EmployeeName",
      width: 240,
    },
    {
      Header: "Team",
      accessor: "TeamName",
      Cell: TeamCell,
      width: 160,
    },
    {
      Header: "Role",
      accessor: "RoleName",
      width: 160,
    },
    {
      Header: "Shift Pattern",
      accessor: "ShiftPatternName",
      width: 160,
    },
    {
      Header: "Actions",
      accessor: "EmployeeId",
      Cell: ButtonCell,
      width: 120,
    },
  ];
  return (
    <div>
      <Table isFixedWidth data={teamEmployeeList ?? []} columns={cols} />
      {teamEmployeeList && teamEmployeeList.length > 0 && (
        <Pagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={pageSize}
          total={teamEmployeeList[0].TotalRows}
        />
      )}
    </div>
  );
}

export default TeamTable;
