import React, {useEffect, useState} from "react";
import Sidebar from "./../components/sidebar";
import classnames from "classnames";
import {useSelector} from "react-redux";

function Boards(props) {
  const [showSideBar, setShowSideBar] = useState(true);
  const {board} = useSelector((state: any) => state.taskReducer);

  useEffect(() => {
    if (board && board.IsArchived) {
      setShowSideBar(false);
    }
  }, [board]);

  const handleSideBtnClick = (val) => {
    let isArchived = (board && board.IsArchived) || false;
    if (isArchived) return;
    setShowSideBar(val);
  };
  return (
    <div
      className={classnames("tasks", {"tasks-close": !showSideBar})}
      style={{position: "relative"}}
    >
      <Sidebar showSideBar={showSideBar} setShowSideBar={handleSideBtnClick} />

      <div className="tasks-main">{props.children}</div>
    </div>
  );
}

export default Boards;
