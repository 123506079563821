import React, {useEffect, useState} from "react";
import {Tabs, Tab} from "components/tabs/index.d";
import {DateFormat} from "helpers/dateFormat";
import isEmpty from "helpers/isEmpty";
import WorkScheduleTable from "./scheduleTableView";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {GetMyWorkSchedule} from "scenes/employee/action";

const WorkSchedule = (props) => {
  const {user} = useSelector((state: any) => state.auth);
  const [employeeScheduleData, setEmployeeScheduleData] = useState(null);
  const [tableData, setTableData] = useState({week: [], month: []});
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMyWorkSchedule();
  }, []);

  useEffect(() => {
    if (employeeScheduleData) {
      setTableData({
        week: convertToTableData(employeeScheduleData.week),
        month: convertToTableData(employeeScheduleData.month),
      });
    }
  }, [employeeScheduleData]);

  const fetchMyWorkSchedule = async () => {
    if (user?.IsShiftEmployee) {
      var startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      var endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

      var startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
      var endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

      let monthData = await dispatch(
        GetMyWorkSchedule(user.EmployeeId, startOfMonth, endOfMonth)
      );

      let weekData = await dispatch(
        GetMyWorkSchedule(user.EmployeeId, startOfWeek, endOfWeek)
      );

      setEmployeeScheduleData({
        week: weekData, //convertAndSetToTableData(weekData),
        month: monthData, //convertAndSetToTableData(monthData),
      });
    }
  };

  const convertToTableData = (ls) => {
    var shiftData = {};
    var maxCol = 0;
    var list = [];
    if (ls.length === 0) return [];
    ls.reduce((prev, el) => {
      var noCol = 0;
      var name = DateFormat(el.ScheduleDate, "MMMM DD, dddd");
      if (!shiftData[name]) {
        shiftData[name] = [el];
        noCol = 1;
      } else {
        shiftData[name].push(el);
        noCol = shiftData[name].length;
      }
      if (maxCol < noCol) maxCol = noCol;
      return shiftData;
    });

    for (var key in shiftData) {
      var row = {name: key};
      var data = shiftData[key];
      for (var i = 0; i < maxCol; i++) {
        row[`shift${i}`] = "";
        if (i < data.length) {
          var x = data[i];
          row[`shift${i}`] = `${x.Name} ${
            !isEmpty(x.ShortName) ? `(${x.ShortName})` : ""
          } ${x.BeginsAt}-${x.EndsAt}`;
        }
      }
      list.push(row);
    }
    return list;
  };

  return (
    <div className="mywork">
      <h3 className="mywork__title">My Work Schedule</h3>
      <div className="mywork-block">
        <Tabs>
          <Tab name="week" id="1" label="This Week">
            <WorkScheduleTable data={tableData.week} type="week" />
          </Tab>
          <Tab name="month" id="2" label="This Month">
            <WorkScheduleTable data={tableData.month} type="month" />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default React.memo(WorkSchedule);
