import React, {useState} from "react";
import {contentTypes} from "constants/enums";
import {contents, questionContent} from "scenes/lms/helpers/constants";
import {BsTrash} from "react-icons/bs";
import {RiArrowDownLine, RiArrowUpLine, RiEdit2Line} from "react-icons/ri";
import {FiEdit} from "react-icons/fi";
import {BsArrowUp, BsArrowDown} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteCourseContent,
  getCourseContent,
  getCoursePage,
  updateContentOrder,
  updateCourseContent,
} from "scenes/lms/helpers/action";
import Docs from "assets/images/document.svg";
import PDF from "assets/images/pdf.svg";
import Content from "../pageForm/content";
import DeleteBtn from "scenes/lms/common/deleteBtn";
import {MdDelete} from "react-icons/md";
import {BsPencilSquare} from "react-icons/bs";
import {HiArrowNarrowDown, HiArrowNarrowUp} from "react-icons/hi";
import SvgIcons from "components/icons/svgIcons";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
function ShowItem(props) {
  const dispatch = useDispatch();
  const {totalData, index, Id} = props;
  const [state, setState] = useState({isEditMode: false});
  const {coursePages, course, coursePage, courseContent} = useSelector(
    (state: any) => state.lmsReducer
  );

  const getComponents = () => {
    const {isEditMode} = state;
    if (isEditMode)
      return <Content {...props} handleCancel={() => changeEditMode(false)} />;
    else return <Text {...props} />;

    // switch (parseInt(props.Type)) {
    //   case contentTypes.text.id:
    //     return <Text {...props} />;
    //   case contentTypes.image.id:
    //     return <Image {...props} />;
    //   case contentTypes.video.id:
    //     return <Video {...props} />;
    //   case contentTypes.video_link.id:
    //     return <VideoLink {...props} />;
    //   case contentTypes.attachment.id:
    //     return <Attachments {...props} />;
    //   case contentTypes.pdf.id:
    //     return <PDFS {...props} />;
    //   default:
    //     return null;
    // }
  };
  const onCourseContentDelete = async () => {
    await dispatch(deleteCourseContent(props.Id));
    await dispatch(getCoursePage(props.CoursePageId));
  };

  const updatOrder = async (order) => {
    let newOrder = index + 1 + order;
    await dispatch(updateContentOrder({id: Id, newOrder}));
    await dispatch(getCourseContent(props.CoursePageId));
  };

  const changeEditMode = (val) => setState({...state, isEditMode: val});
  const isFirst = props.ContentOrder === 1;
  const isLast = props.ContentOrder === courseContent.length;
  return (
    <div className="course-card fr-box fr-basic">
      <div className="course-content__header">
        <div className="course-content__type">
          {/* {contents.map((item: any) => {
            if (item.id === parseInt(props.Type)) {
              return (
                <div className="course-chip">
                  <span className="course-chip__icon">{item.icon}</span>
                  <span className="course-chip__title">{item.title}</span>
                </div>
              );
            }
          })} */}
        </div>
        {/* totalData, index */}
        <div className="course-content__icons">
          {!state.isEditMode && (
            <div onClick={() => changeEditMode(true)} className="course-content__icon">
              <SvgIcons name="edit" color="#8d8d8d" size="14px" />
            </div>
          )}
          {totalData != index + 1 && (
            <div onClick={() => updatOrder(1)} className="course-content__icon">
              <SvgIcons name="downarrow" color="#8d8d8d" size="14px" />
            </div>
          )}
          {index > 0 && (
            <div onClick={() => updatOrder(-1)} className="course-content__icon">
              <SvgIcons name="uparrow" color="#8d8d8d" size="14px" />
            </div>
          )}
          <DeleteBtn
            title="Delete question!!"
            onConfirm={() => onCourseContentDelete()}
            className="course-content__icon"
          />
          {/* <div onClick={() => onCourseContentDelete()} className="course-content__icon">
            <BsTrash />
          </div> */}
        </div>
      </div>
      {getComponents()}
    </div>
  );
}

export const Text = (props) => {
  return (
    <div className="fr-element">
      <FroalaEditorView model={props?.Description} />
    </div>
  );
};

export const Image = (props) => {
  return (
    <div className="course-content__images">
      {props?.Attachments &&
        props?.Attachments.map((item) => {
          return (
            <div className="course-content__image">
              <img src={item.FileLocation + item.ServerFileName} alt="" />
            </div>
          );
        })}
    </div>
  );
};

export const Video = (props) => {
  return (
    <div className="course-content__images">
      {props?.Attachments &&
        props?.Attachments.map((item) => {
          return (
            <div className="course-content__image">
              {/* <img src={item.FileLocation + item.ServerFileName} alt="" /> */}
              <video width="320" height="240" autoPlay muted>
                <source src={item.FileLocation + item.ServerFileName} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        })}
    </div>
  );
};

export const VideoLink = (props) => {
  return (
    <div className="course-content__images">
      <div className="course-content__image">
        {/* <img src={item.FileLocation + item.ServerFileName} alt="" /> */}
        <video width="320" height="240" autoPlay muted>
          <source src={props?.VideoLink} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export const PDFS = (props) => {
  return (
    <div className="course-content__images">
      {props?.Attachments &&
        props?.Attachments.map((item) => {
          return (
            <div className="course-content__doc">
              <div className="course-content__doc-icon">
                <img src={PDF} />
              </div>
              <div className="course-content__doc-text">{item.UserFileName}</div>
            </div>
          );
        })}
    </div>
  );
};

export const Attachments = (props) => {
  return (
    <div className="course-content__images">
      {props?.Attachments &&
        props?.Attachments.map((item) => {
          return (
            <div className="course-content__doc">
              <div className="course-content__doc-icon">
                <img src={Docs} />
              </div>
              <div className="course-content__doc-text">{item.UserFileName}</div>
            </div>
          );
        })}
    </div>
  );
};

export default ShowItem;
