import React from "react";
import Icons from "components/icons";
import {useSelector} from "react-redux";
import FormGroup from "components/form";

function AssessIDTable(props) {
  const {employeeListSelect, companyInformation} = useSelector(
    (state) => state.commonReducer
  );

  const {handleFieldChange, onClose, rowData} = props;

  return (
    <div className="employee-onboarding_access-table">
      <div className="employee-onboarding_access-table-body">
        <div className="employee-onboarding_access-table__header">
          <span style={{width: "260px"}}>Type</span>
          <span style={{width: "260px"}}>Responsible person</span>
          <span style={{width: "260px"}}>Due date</span>
          <span style={{width: "260px"}}>Note</span>
          <span style={{width: "260px"}}></span>
        </div>
        {rowData &&
          rowData.map((row, index) => {
            return (
              <div
                className={`employee-onboarding_access-table__header ${
                  index == 0
                    ? "employee-onboarding_access-table__row"
                    : "employee-onboarding_access-table__row_otherRow"
                }`}
                index={row.Id}
                key={row.Id}
              >
                <FormGroup
                  // formName="reactselect"
                  onChange={(name, value) => handleFieldChange(name, value, row)}
                  name="TypeName"
                  value={row.TypeName}
                  loadingType="skeleton"
                  placeholder="Name Type"
                  width="250px"
                />
                <FormGroup
                  formName="reactselect"
                  onChange={(name, value) => handleFieldChange(name, value, row)}
                  name="ResponsiblePerson"
                  value={row.ResponsiblePerson}
                  options={employeeListSelect}
                  loadingType="skeleton"
                  placeholder="Responsible Person"
                  width="250px"
                />
                <FormGroup
                  enableOkButton
                  formName="customdateinput"
                  // isNepali={!companyInformation?.IsEnglishDate}
                  loadingType="skeleton"
                  name="DueDate"
                  onChange={(name, value) => handleFieldChange(name, value, row)}
                  placeholder="Due Date"
                  value={row.DueDate}
                  width="250px"
                />
                <FormGroup
                  // formName="reactselect"
                  onChange={(name, value) => handleFieldChange(name, value, row)}
                  name="Note"
                  value={row.Note}
                  // options={accessIdTemplates}
                  loadingType="skeleton"
                  placeholder="Note"
                  width="400px"
                />
                <span className="close-icon" onClick={() => onClose(row)}>
                  <Icons name="Close" />
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AssessIDTable;
