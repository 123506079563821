import {
  SET_USER_VISIBLE,
  SET_CURRENT_USER,
  SET_CURRENT_TOKEN,
  SET_USER_LOADING,
  CHANGE_PROFILE_PICTURE,
  GET_BASE_URL,
  GET_PUBLIC_RECRUITMENT_BASE_URL,
  GET_IS_RECOMMENDER_OR_APPORVER,
  GET_HR_COMMON_SETTINGS,
  SET_LOGIN_LOADING,
  GET_IS_HR_OR_FINANCE,
  LOGIN_VIA_VIANET,
} from "actions/types";
import isEmpty from "helpers/isEmpty";
import {Action, Reducer} from "redux";

export interface AuthState {
  isAuthenticated: boolean;
  user: object;
  loading: boolean;
  loginLoading: boolean;
  loginViaVianetLoading: boolean;
  token: object;
  visible: boolean;
  loginData: any;
  loginViaVianetData: any;
  tokenDetails: any;
  baseUrl: string;
  publicRecruitmentBaseUrl: string;
  isRecommenderOrApprover: boolean;
  hrCommonSettings: any;
  isHRorFinance: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  loginLoading: false,
  loginViaVianetLoading: false,
  loginData: null,
  loginViaVianetData: null,
  token: {},
  visible: false,
  tokenDetails: null,
  baseUrl: "",
  publicRecruitmentBaseUrl: "",
  isRecommenderOrApprover: false,
  hrCommonSettings: {
    EmpDashboardShowAllowanceRequest: false,
    EmpDashboardShowOvertimeRequest: false,
    EmpDashboardShowTimeRequest: false,
    EmpTimeAttRequestThreshold: -1,
  },
  isHRorFinance: false,
};

interface LoginAction {
  type: string;
  payload: any;
  loginData: any;
  loginViaVianetData: any;
  tokenDetails: any;
  loginViaVianetLoading: boolean;
}

export const reducer: Reducer<AuthState> = (
  state: AuthState | undefined,
  incomingAction: Action
): AuthState => {
  if (state === undefined) {
    return initialState;
  }
  const action = incomingAction as LoginAction;
  switch (action.type) {
    case SET_CURRENT_TOKEN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        token: action.payload,
        loginData: action.loginData,
        tokenDetails: action.tokenDetails,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_BASE_URL:
      return {
        ...state,
        baseUrl: action.payload,
      };
    case GET_PUBLIC_RECRUITMENT_BASE_URL:
      return {
        ...state,
        publicRecruitmentBaseUrl: action.payload,
      };

    case GET_IS_RECOMMENDER_OR_APPORVER:
      return {
        ...state,
        isRecommenderOrApprover: action.payload,
      };
    case GET_HR_COMMON_SETTINGS:
      return {
        ...state,
        hrCommonSettings: action.payload,
      };
    case SET_USER_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    case SET_LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.payload,
      };
    case CHANGE_PROFILE_PICTURE:
      return {
        ...state,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_IS_HR_OR_FINANCE:
      return {
        ...state,
        isHRorFinance: action.payload,
      };
    case LOGIN_VIA_VIANET:
      return {
        ...state,
        loginViaVianetData: action.payload,
        loginViaVianetLoading: action.loginViaVianetLoading,
      };
    default:
      return state;
  }
};
