import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import classnames from "classnames";
import FormGroup from "components/form";
import React from "react";
import { connect } from "react-redux";
import {DisplayClientSideMessage} from "actions/commonAction";
import isEmpty from "isEmpty";
import { addAllowanceExpense, editAllowanceExpense } from "./actions";

export class AddExpenseAllowance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsAllowance: this.props.initialValues.IsAllowance,
      formData: {
        AllowanceId: 0,
        IsAllowance: "true",
        Name: "",
        CalcMethod: null,
        IsBillRequired: false,
      },
      errors: {},
      pristine: true,
    };
  }

  componentDidMount() {
    this.setState({ formData: this.props.initialValues });
  }
  handleInputChange = (name, value) => {
    this.setFormState(name, value);
  };
  handleRadioChange = (event) => {
    let { name, value } = event.target;
    this.setFormState(name, value);
  };
  handleCheckboxChange = (event) => {
    let { name, checked } = event.target;
    this.setFormState(name, checked);
  };

  setFormState = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      pristine:
        JSON.stringify({
          ...prevState.formData,
          [name]: value,
        }) === JSON.stringify(this.props.initialValues)
          ? true
          : false,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    const { formData } = this.state;
    let errors = {};
    if (isEmpty(formData.Name)) {
      errors.Name = "Required";
    }
    if (formData.IsAllowance == "true" && isEmpty(formData.CalcMethod)) {
      let message = {
        MessageType: "warning",
        Message: "Please select a calculation type.",
      };
      this.props.DisplayClientSideMessage(message);
      return;
    } else {
      if (
        this.props.allowanceExpense &&
        this.props.allowanceExpense.find(
          (x) => x.AllowanceId !== formData.AllowanceId && x.Name === formData.Name
        )
      ) {
        errors.Name = "Allowance expense name already taken. Please choose another name!";
      } else {
        const {isEdit} = this.props.allowanceExpenseReducer;
        isEdit
          ? this.props.editAllowanceExpense(formData, this.props.handleModalShow)
          : this.props.addAllowanceExpense(formData, this.props.handleModalShow);
      }
    }
    this.setState({ errors });
  };
  render() {
    const { handleSubmit, reset, submitting } = this.props;
    const { formData, errors, pristine } = this.state;
    return (
      <form onSubmit={this.handleFormSubmit}>
        <input type="hidden" name="AllowanceId"></input>
        <div className="form-body">
          <div className="form-row">
            <div className="radio-label">
              <span>Type the name of</span>
            </div>
            <RadioGroup
              name="IsAllowance"
              onChange={this.handleRadioChange}
              value={formData.IsAllowance}
              className="action-radio"
            >
              <FormControlLabel value="true" control={<Radio />} label="Allowance" />
              <FormControlLabel value="false" control={<Radio />} label="Expense" />
            </RadioGroup>
          </div>

          <div className="form-row">
            <div className="filters-input__item">
              <FormGroup
                name="Name"
                type="text"
                size="med"
                error={errors.Name}
                label="Name"
                value={formData.Name}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          {formData.IsAllowance === "true" ? (
            <div className="form-row">
              <div className="radio-label">
                <span>Calculation</span>
              </div>
              <RadioGroup
                name="CalcMethod"
                onChange={this.handleRadioChange}
                value={formData.CalcMethod}
                error={errors.CalcMethod}
                className="action-radio"
              >
                <FormControlLabel value="1" control={<Radio />} label="Per day" />
                <FormControlLabel value="2" control={<Radio />} label="Per night" />
                <FormControlLabel value="3" control={<Radio />} label="Per trip" />
              </RadioGroup>
            </div>
          ) : (
            <div className="form-row">
              <FormGroup
                formName="checkgroup"
                label="Bill required"
                name="IsBillRequired"
                labelPosition="right"
                checked={formData.IsBillRequired ? true : false}
                onChange={this.handleCheckboxChange}
                value={formData.IsBillRequired}
              />
            </div>
          )}

          <div className="form-row footer-grp button-group justify-center">
            <button
              type="button"
              className={classnames({
                "footer__cancel button button-white": true,
                //disabled: pristine || submitting
              })}
              //disabled={pristine || submitting}
              onClick={() => this.props.handleModalShow(false, "normal")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={classnames({
                "footer__save button button-primary": true,
                disabled: pristine || submitting,
              })}
              disabled={pristine || submitting}
            >
              {submitting ? "Saving" : "Save"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  allowanceExpenseReducer: state.allowanceExpenseReducer,
  initialValues: state.allowanceExpenseReducer.allowanceExpenseFormData, // pull initial values from account reducer pull initial values from account reducer
  allowanceExpense: state.allowanceExpenseReducer.allowanceExpense,

});

export default connect(mapStateToProps, {
  addAllowanceExpense,
  editAllowanceExpense,
  DisplayClientSideMessage
})(AddExpenseAllowance);
