import React from 'react'
import {CALENDAR_WEEK} from '../../constants';

interface PropsInterface{
  
}

function CalendarDays(props:PropsInterface) {
  return (
    <div className="cal-popup__grid">
        {Object.keys(CALENDAR_WEEK.mid).map(day=>{
            return <div key={day} className="cal-popup__cell cal-popup__day">
                {
                    //@ts-ignore
                    CALENDAR_WEEK.mid[day]
                }
           </div>
        })}
    </div>
  )
}

export default CalendarDays