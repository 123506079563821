import React, {useEffect, useState} from "react";
import Tabs, {Tab} from "components/tabs/index.d";
import Active from "./active";
import {GetEmployeeDeductionList} from "../../action";
import {useDispatch, useSelector} from "react-redux";

function Deduction(props) {
  const [state, setState] = useState({
    data: [],
    activeDeductions: [],
    disabledDeductions: [],
  });

  const {employeeDeductions} = useSelector((state: any) => state.employeeProfile);

  useEffect(() => {
    parseDeductions(employeeDeductions);
  }, [employeeDeductions]);

  const parseDeductions = (deductions) => {
    var activeDeductions = deductions.filter((x) => x.IsEnabled);
    var disabledDeductions = deductions.filter((x) => !x.IsEnabled);

    setState({
      ...state,
      data: deductions,
      activeDeductions,
      disabledDeductions,
    });
  };

  return (
    <div className="emp-payroll__block">
      <div className="emp-payroll__title">Deductions, Loan & Advances</div>
      <Tabs>
        <Tab label="Active" name="Active">
          <Active activeData={state.activeDeductions} />
        </Tab>
        <Tab label="Disabled" name="Disabled">
          <Active activeData={state.disabledDeductions} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Deduction;
