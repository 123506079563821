import React, {Component, createRef} from "react";
import ExportExcel from "components/excel/exportExcel";
import {AgGridReact} from "ag-grid-react";
import CommonPagination from "components/common/pagination";
import Icons from "components/icons";
import SvgIcons from "components/icons/svgIcons";
import Button from "components/button";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {ChangeDetectionStrategyType} from "ag-grid-react/lib/changeDetectionService";
import {connect, useDispatch} from "react-redux";
import {rejectOvertime} from "./helpers/action";
import * as employeeRequestService from "services/employeeRequests";
import GetDetailsPage from "scenes/employee/requests/common/getDetailsPage";
import Modal from "components/modal";
import OvertimeRequestForm from "scenes/employee/overtimeRequest";
import FormGroup from "components/form";
import {DisplayClientSideMessage} from "actions/commonAction";
import changeMinToHourMinTimeFormat from "helpers/changeMinToHourMinTimeFormat";

import getColumnDefs from "./helpers/getColumnDefs";
import {ExportOvertimeRequest} from "./helpers/action";
import {initialHeader} from "./constants";

const Status = (props) => {
  const isActive = props.data.Status === "Approved";
  const isPending = props.data.Status === "Pending";
  const isForwarded = props.data.Status === "Forwarded";
  const isRejected = props.data.Status === "Rejected";
  const isRecommended = props.data.Status === "Recommended";
  return (
    <div
      className={`working-state ${
        isActive
          ? "working"
          : isPending
          ? "pending"
          : isForwarded
          ? "forwarded"
          : isRecommended
          ? "recom"
          : "retired"
      }`}
    >
      {isActive ? (
        <>
          <Icons name="Check" justSVG />
        </>
      ) : isPending ? (
        <SvgIcons color="#ffa126" size="17" name="clock" />
      ) : isForwarded ? (
        <SvgIcons color="#40a3ff" size="17" name="forwarded" />
      ) : isRecommended ? (
        <SvgIcons color="#0052ab" size="17" name="thumbs-up" />
      ) : (
        <>
          <Icons name="Close" justSVG />
        </>
      )}
      {props.data.Status}
    </div>
  );
};

const DateCommon = (props) => {
  return (
    <div className="">
      {props.data.RequestedOn}
      {/* {props.data.StartDate}  -  { props.data.EndDate} */}
    </div>
  );
};

const RequestedTime = (props) => {
  let duration = "";
  if (props.data && props.data.RequestedTimeInMin) {
    let diff = parseInt(props.data.RequestedTimeInMin);
    duration = changeMinToHourMinTimeFormat(diff);
    // console.log({duration});
  }
  return <div className="">{duration}</div>;
};

const ApprovedTime = (props) => {
  let duration = "";
  if (props.data && props.data.ApprovedTimeInMIn) {
    let diff = parseInt(props.data.ApprovedTimeInMIn);
    duration = changeMinToHourMinTimeFormat(diff);
  }
  return <div className="">{duration}</div>;
};

const Buttons = (props) => {
  const isEdit = props.data.Status === "Approved" || props.data.Status === "Rejected";
  const isForwarded = props.data.Status === "Forwarded";
  let [isDetailsPageShow, setDetailsPageShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const onModalSet = (val) => setOpen(val);
  const rowData = Object.assign(
    {RequestType: "Overtime", OvetimeType: props.data.OverTime},
    props.data
  );
  // console.log(rowData)
  // RequestId

  let requestData = {
    nextReviewerEmployeeId: null,
    requestId: props.data.RequestId,
    requestType: "Overtime",
    reason: "",
  };
  const onApprove = async () => {
    let result = await employeeRequestService.approveReviewRequests(requestData);
    if (result.Status) {
      await props.colDef.initialize();
    }
    if (result) {
      dispatch(DisplayClientSideMessage(result));
    }
  };
  const onDropClick = (e) => {
    const {
      eGridCell,
      eGridCell: {offsetParent: row},
    } = props;
    // if(row.style)
    let rows = document.getElementsByClassName("ag-row");
    //@ts-ignore
    rows.forEach((item) => {
      if (item.style.zIndex) {
        item.style.zIndex = null;
      }
    });
    row.style.zIndex = 10;
  };
  const dispatch = useDispatch();
  const onReject = async () => {
    // dispatch(rejectOvertime(rowData.RequestId, props.colDef.initialize));
    let result = await employeeRequestService.rejectReviewRequests(requestData);
    if (result.Status) {
      await props.colDef.initialize();
    }
    // console.log({result});
    if (result) {
      dispatch(DisplayClientSideMessage(result));
    }
  };

  return (
    <div className="flex allowance-buttons">
      {isEdit ? (
        <Button
          title="Edit"
          buttonType="icon-button"
          onClick={() => onModalSet(true)}
          leftIcon={<Icons name="Edit" justSVG />}
          bg="primary"
        />
      ) : isForwarded ? (
        <Button
          title="Details"
          onClick={() => {
            setDetailsPageShow(true);
            // console.log({props});
          }}
          leftIcon={<SvgIcons size="17" name="list" />}
          buttonType="icon-button"
          bg="subtle"
        />
      ) : (
        <Button
          title="Approve"
          onClick={() => onApprove()}
          leftIcon={<Icons name="CircleCheck" justSVG />}
          buttonType="icon-button"
          bg="success"
        />
      )}
      <Modal
        title="Overtime Request"
        open={open}
        onModalClose={() => onModalSet(false)}
        type=""
        className="time-request-modal over-time"
      >
        <OvertimeRequestForm
          onModalClose={() => onModalSet(false)}
          overtimeRequestId={rowData}
          editMode={true}
          isAdmin
          refreshRequestData={props.colDef.initialize}
        />
      </Modal>

      <Button
        customDropIcon={<Icons name="Ellipse" justSVG />}
        buttonType="drop-button"
        buttonClass="allowance-buttons__drop"
        onDropClick={(e) => onDropClick(e)}
        dropComponent={
          <ul>
            <li className="flex" onClick={() => onReject()}>
              <Icons name="Close" justSVG />
              <span>Reject</span>
            </li>
          </ul>
        }
        dropAbove={rowData.RowNum !== 1}
        justDrop={true}
        bg="subtle"
      />
      {isDetailsPageShow && (
        <GetDetailsPage
          selectedRow={rowData}
          closeDetailsModal={() => setDetailsPageShow(false)}
          isViewMode={true}
          isAdmin={true}
          // isReview={isReview}
          refreshRequestData={props.colDef.initialize}
          requestData={props.agGridReact.props.rowData}
          page={"Admin "}
        />
      )}
    </div>
  );
};

class Tables extends Component<any, any> {
  constructor(props) {
    super(props);
    let activeTab = props.activeTab;

    this.state = {
      columnDefs: getColumnDefs(activeTab, props),
      domLayout: "autoHeight",
      defaultColDef: {
        width: 145,
        resizable: false,
      },
      exportColumn: [],
      exportData: [],
      frameworkComponents: {
        Status,
        DateCommon,
        RequestedTime,
        ApprovedTime,
        Buttons,
      },
      getRowHeight: function (params) {
        return 58;
      },
      rowData: [],
      rowSelection: "multiple",
      selectedRows: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab) {
      const columnDefs = getColumnDefs(this.props.activeTab, this.props);
      this.gridApi.setColumnDefs(columnDefs);
    }
    if (prevProps.overtimeExportList !== this.props.overtimeExportList) {
      const exportData =
        this.props.overtimeExportList &&
        this.props.overtimeExportList.length > 0 &&
        this.props.overtimeExportList.map((overtime) => {
          return {
            EmployeeName: overtime.EmployeeName,
            OverTime: overtime.OverTime,
            RequestedOn: overtime.RequestedOn,
            StartTime: overtime.StartTime,
            EndTime: overtime.EndTime,
            RequestedTimeInMin: overtime.RequestedTimeInMin,
            ApprovedTimeInMIn: overtime.ApprovedTimeInMIn,
            Reason: overtime.Reason,
            Status: overtime.Status,
          };
        });
      this.setState({exportData});
      const newArray = initialHeader.map((item) => {
        return {label: item.headerName, value: item.field};
      });
      this.setState({exportColumn: newArray});
    }
  }

  public gridApi;
  public gridColumnApi;

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    const updateData = (data) => {
      this.setState({rowData: data});
    };

    if (this.props.data) {
      updateData(this.props.data);
    }
  };

  setAutoHeight = () => {
    this.gridApi.setDomLayout("autoHeight");
    const grid: any = document.querySelector("#myGrid");
    if (grid) grid.style.height = "";
  };

  getSelectedRowData = () => {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);

    const ids = selectedData.map((item) => item.RequestId);
    this.props.postToPay(ids);
  };

  onSelectionChanged = (event) => {
    var rowCount = event.api.getSelectedNodes().length;
    this.setState({selectedRows: rowCount});
  };

  handleExcelExport = async () => {
    const {
      pageIndex,
      rowsPerPage,
      activeTab,
      employeeName,
      startDate,
      endDate,
      overtimeType,
      departments,
      branches,
    } = this.props;
    let searchData = {
      PageIndex: 0,
      PageSize: 999999,
      EmployeeName: employeeName,
      RequestEndDate: endDate,
      RequestStartDate: startDate,
      OverTime: overtimeType,
      Department: departments,
      Status: activeTab,
      Branch: branches,
    };
    await this.props.ExportOvertimeRequest(searchData);
  };

  render() {
    const {exportColumn, exportData, selectedRows} = this.state;
    const {
      data,
      handleRowChange,
      handleIndexChange,
      pageIndex,
      rowsPerPage,
      history,
      activeTab,
    } = this.props;
    const isApprovedTab = activeTab === "2";

    return (
      <>
        {isApprovedTab ? (
          <div className="flex">
            <Button
              bg="primary"
              isDisabled={selectedRows === 0}
              onClick={() => this.getSelectedRowData()}
              title="Post to pay"
            />
            <ExportExcel
              onClick={this.handleExcelExport}
              fileName="Overtime Request"
              columns={exportColumn}
              sheets={[{sheetName: "Overtime Request", data: exportData}]}
              className="button button-primary"
            />
          </div>
        ) : null}
        <div className="table empTable">
          <div className="arrear-table">
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                getRowHeight={this.state.getRowHeight}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged}
                rowData={data}
                rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                rowMultiSelectWithClick={isApprovedTab}
                rowSelection={this.state.rowSelection}
                suppressRowClickSelection={true}
              />
            </div>
            {data && data.length > 0 && (
              <CommonPagination
                handleRowChange={handleRowChange}
                handleIndexChange={handleIndexChange}
                pageIndex={pageIndex}
                rowsPerPage={rowsPerPage}
                total={data[0].TotalRow}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  ExportOvertimeRequest,
};

export default connect(null, mapDispatchToProps)(Tables);
