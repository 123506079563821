import React from "react";
import {Card} from "components/card";
import {COURSE_SKELETON} from "constants/colors";

const CourseResultCard = () => {
  return (
    <Card flexDirection={"column"} padding={"1.5rem"} width={"100%"}>
      <Card gap={"1rem"}>
        <Card background={COURSE_SKELETON} height={"1.5rem"} width="4rem"></Card>
        <Card background={COURSE_SKELETON} height={"1.5rem"} width="10rem"></Card>
      </Card>
      <Card gap={"1rem"} marginTop="1.5rem">
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5rem"></Card>
      </Card>
      <Card gap={"1.5rem"} marginTop="1.8rem">
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5.5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5.5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5.5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5.5rem"></Card>
        <Card background={COURSE_SKELETON} height={"2.5rem"} width="5.5rem"></Card>
      </Card>
      <Card
        background={COURSE_SKELETON}
        marginTop={"2rem"}
        height={"8rem"}
        width="100%"
      />
    </Card>
  );
};

export default CourseResultCard;
