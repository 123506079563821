import React, {Component} from "react";
import Form from "components/form";
import Button from "components/button";

export class SearchAndFilter extends Component {
  render() {
    const {
      searchClient,
      searchSubmitter,
      fromDate,
      toDate,
      searchActivityType,
      activeActivityType,
    } = this.props.parentState;

    return (
      <div className="filters">
        <div className="filters-grid">
          <Form
            formName="textinput"
            name="searchClient"
            value={searchClient}
            onChange={this.props.handleInput}
            placeholder="Search Client"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="textinput"
            name="searchSubmitter"
            value={searchSubmitter}
            onChange={this.props.handleInput}
            placeholder="Search Submitter"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="textinput"
            name="searchActivityType"
            value={searchActivityType}
            onChange={this.props.handleInput}
            placeholder="Search Activity Type"
          />
        </div>

        <div className="filters-grid">
          <Form
            formName="reactdaterange"
            startDate={fromDate}
            endDate={toDate}
            onChange={this.props.handleDateRangePicker}
            placeholder="Date filter"
          />
        </div>

        <Button onClick={this.props.handleFileExport} title="Export" bg="primary" />
        {/* <div className="filters-grid">
        </div> */}
      </div>
    );
  }
}

export default SearchAndFilter;
