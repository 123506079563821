import {
  GET_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_DISTRICT_LIST,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_LIST_WITH_PHOTO,
  GET_EDUCATION_LEVELS,
  GET_PROVINCE_LIST,
  GET_DISTRICT_BY_PROVINCE,
  GET_LOCALBODY_BY_DISTRICT,
  GET_JOB_STATUS,
  GET_EXPERIENCE_DURATION,
  GET_SALARY_PAY_DURATION,
  GET_COMPANY_INFORMATION,
  GET_NATIONALITY,
  UPLOAD_FILES,
  UPDATE_COMPANY_INFORMATION,
  GET_COMPANY_LOGO,
  GET_BRANCHES,
  GET_DEPARTMENTS,
  SORT_DRAG,
  GET_DESIGNATION,
  GET_LEVELS,
  GET_BASE_URL,
  GET_SUB_DEPARTMENTS,
  GET_EMPLOYEE_LIST_SEARCH,
  IS_LOADING,
  GET_LEVELS_WITH_GROUPS,
  SET_LEVELS_WITH_GROUPS_LOADING,
  GET_JOB_PROFILE_DROP_LIST,
  GET_JOB_DESCRIPTION_DROP_LIST,
  SET_JOB_PROFILE_DROP_LIST_LOADING,
  SET_JOB_DESCRIPTION_DROP_LIST_LOADING,
  SET_SELECTED_RECORD,
  GET_COMPANY_HOLIDAYS,
  GET_COMPANY_HOLIDAYS_WHOLE,
  GET_OVERTIME_TYPES,
  GET_BLOOD_GROUP_LIST,
  GET_PAYROLL_INCOME_HEADS,
  GET_HIRING_METHODS,
  GET_APP_VERSION,
  GET_EMP_MENUS,
  GET_SERVER_DATE_TIME,
  COMPANY_INFORMATION_REQUESTED,
} from "actions/types";

const initialState = {
  countryList: [],
  currencyList: [],
  districtList: [],
  employeeList: [],
  employeeListWithPhotos: [],
  employeeWithPhoto: [],
  myDetail: {},
  educationLevelList: [],
  provinceList: [],
  districtByProvienceList: [],
  localBodyList: [],
  jobStatusList: [],
  experienceDurationList: [],
  salaryPayDurationList: [],
  uploadedFileList: [],
  companyInformation: [],
  isRequestedForCompanyInformation: false,
  nationality: [],
  branchList: [],
  departmentList: [],
  subDepartmentList: [],
  province: [],
  designationList: [],
  levelList: [],
  isLoading: true,
  uploadType: "",
  companyLogo: {},
  employeeListSelect: [],
  employeeSearchList: [],
  baseUrl: {},
  levelsWithGroupsList: [],
  jobFamilyList: {loading: true, value: []},
  jobRoleList: [],
  jobProfileList: {loading: true, value: []},
  jobProfileWithFamilyAndRoleList: [],
  jobDescriptionList: {loading: true, value: []},
  selectedRecords: [],
  companyHolidays: [],
  companyHolidaysWhole: [],
  informationalVersion: "",
  oauthEnabled: false,
  empMenuLinks: [],
  employeeListForSbiLoan: [],
  serverDateTime: new Date(),
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case GET_SERVER_DATE_TIME:
      return {
        ...state,
        serverDateTime: action.payload,
      };
    case GET_APP_VERSION: {
      return {
        ...state,
        informationalVersion: action.payload.InformationalVersion,
        oauthEnabled: action.payload.OauthEnabled,
      };
    }
    case GET_EMP_MENUS: {
      return {
        ...state,
        empMenuLinks: action.payload,
      };
    }

    case IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SORT_DRAG:
      return {
        ...state,
      };
    case GET_BASE_URL:
      return {
        ...state,
        baseUrl: action.payload,
      };
    case GET_COUNTRY_LIST:
      let formatedCountryList = [];
      action &&
        action.payload.length > 0 &&
        action.payload.map((m) => {
          formatedCountryList.push({
            label: m.CountryName,
            value: m.CountryId,
          });
        });
      return {
        ...state,
        countryList: formatedCountryList,
      };

    case GET_CURRENCY_LIST:
      let formatedCurrencyList = [];
      action &&
        action.payload &&
        action.payload.length > 0 &&
        action.payload.map((m) => {
          formatedCurrencyList.push({
            label: m.CurrencyCode,
            value: m.Id,
            // currencyId: m.CurrencyId,
            // currencyDescription: m.CurrencyDescription
          });
        });
      return {
        ...state,
        currencyList: formatedCurrencyList,
      };
    case GET_COMPANY_LOGO:
      return {
        ...state,
        companyLogo: action.payload,
      };
    case GET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload,
      };
    case GET_EMPLOYEE_LIST_SEARCH:
      let formotedList =
        action.payload && action.payload.length > 0
          ? action.payload.map((m) => {
              return {
                ...m,
                label: m.FullName,
                value: m.EmployeeId,
                img: m.Imagepath,
                designation: m.Designation,
              };
            })
          : [];
      return {
        ...state,
        employeeSearchList: formotedList,
      };
    case GET_EMPLOYEE_LIST:
      let formatedEmployeeList = [];
      action &&
        action.payload &&
        action.payload.length > 0 &&
        action.payload.map((m) => {
          formatedEmployeeList.push({
            designation: m.Designation,
            label: m.FullName,
            value: m.EmployeeId,
            img: m.Imagepath,
          });
        });
      return {
        ...state,
        employeeList: action.payload,
        employeeListSelect: formatedEmployeeList,
      };

    case GET_EMPLOYEE_LIST_WITH_PHOTO:
      return {
        ...state,
        employeeListWithPhoto: action.formatedEmployeeListWithPhoto,
        myDetail: action.formatedMyDetail,
        employeeWithPhotos: action.payload,
      };

    case GET_EDUCATION_LEVELS: {
      return {
        ...state,
        educationLevelList: action.payload,
      };
    }

    case GET_PROVINCE_LIST: {
      return {
        ...state,
        provinceList: action.payload,
      };
    }

    case GET_DISTRICT_BY_PROVINCE: {
      return {
        ...state,
        districtByProvienceList: action.payload,
      };
    }

    case GET_LOCALBODY_BY_DISTRICT: {
      return {
        ...state,
        localBodyList: action.payload,
      };
    }

    case GET_JOB_STATUS: {
      return {
        ...state,
        jobStatusList: action.payload,
      };
    }
    case GET_EXPERIENCE_DURATION: {
      return {
        ...state,
        experienceDurationList: action.payload,
      };
    }
    case GET_SALARY_PAY_DURATION: {
      return {
        ...state,
        salaryPayDurationList: action.payload,
      };
    }
    case UPLOAD_FILES:
      return {
        ...state,
        uploadedFileList: action.payload,
        uploadType: {files: action.payload, type: action.uploadType},
      };
    case COMPANY_INFORMATION_REQUESTED: {
      return {
        ...state,
        isRequestedForCompanyInformation: action.payload,
      };
    }
    case GET_COMPANY_INFORMATION:
      return {
        ...state,
        companyInformation: action.payload,
      };
    case UPDATE_COMPANY_INFORMATION:
      return {
        ...state,
        companyInformation: {
          ...state.companyInformation,
          CurrencyCode: action.payload.CurrencyCode,
          CountryId: action.payload.CountryId,
          CompanyLogo: action.payload.CompanyLogo,
          LoginPageImage: action.payload.LoginPageImage,
        },
      };
    case GET_NATIONALITY:
      return {
        ...state,
        nationality: action.payload,
      };
    case GET_BRANCHES:
      return {
        ...state,
        branchList: action.payload,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departmentList: action.payload,
      };
    case GET_SUB_DEPARTMENTS:
      return {
        ...state,
        subDepartmentList: action.payload,
      };
    case GET_DESIGNATION:
      return {
        ...state,
        designationList: action.payload,
      };
    case GET_LEVELS:
      return {
        ...state,
        levelList: action.payload,
      };
    case SET_LEVELS_WITH_GROUPS_LOADING:
      return {
        ...state,
        levelsWithGroupsList: [],
        jobFamilyList: {
          loading: action.payload,
          value: [],
        },
      };
    case GET_LEVELS_WITH_GROUPS:
      return {
        ...state,
        levelsWithGroupsList: action.payload,
        jobFamilyList: {
          loading: false,
          value: action.payload?.map((x) => {
            return {value: x.LevelId, label: x.LevelName};
          }),
        },
        jobRoleList: action.payload
          ?.filter(
            (
              (set) => (f) =>
                !set.has(f.LevelGroupId) && set.add(f.LevelGroupId)
            )(new Set())
          )
          .map((x) => {
            return {value: x.LevelGroupId, label: x.GroupName};
          }),
      };
    case SET_JOB_PROFILE_DROP_LIST_LOADING:
      return {
        ...state,
        jobProfileList: {loading: action.payload, value: []},
      };
    case SET_JOB_DESCRIPTION_DROP_LIST_LOADING:
      return {
        ...state,
        jobDescriptionList: {loading: action.payload, value: []},
      };
    case GET_JOB_PROFILE_DROP_LIST:
      return {
        ...state,
        jobProfileList: {
          loading: false,
          value: action.payload?.map((x) => {
            return {label: x.ProfileName, value: x.ProfileId};
          }),
        },
        jobProfileWithFamilyAndRoleList: action.payload,
      };
    case GET_JOB_DESCRIPTION_DROP_LIST:
      return {
        ...state,
        jobDescriptionList: {lodaing: false, value: action.payload},
      };
    case SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecords: action.payload,
      };
    case GET_COMPANY_HOLIDAYS:
      return {
        ...state,
        companyHolidays: action.payload,
      };
    case GET_COMPANY_HOLIDAYS_WHOLE:
      return {
        ...state,
        companyHolidaysWhole: action.payload,
      };
    case GET_OVERTIME_TYPES:
      return {
        ...state,
        overTimeTypes: action.payload,
      };
    case GET_BLOOD_GROUP_LIST:
      return {
        ...state,
        bloodGroupList: action.payload,
      };
    case GET_PAYROLL_INCOME_HEADS:
      return {
        ...state,
        payrollIncomeHeads: action.payload,
      };
    case GET_HIRING_METHODS:
      return {
        ...state,
        hiringMethodLists: action.payload,
      };
    case "GET_SBI_ALL_EMPLOYEES_SPECIFIC": {
      return {
        ...state,
        employeeListForSbiLoan: action.payload,
      };
    }
    default:
      return state;
  }
}
