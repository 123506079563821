import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./components.styles.scss";
import Icon from "components/icons";
import home from "assets/images/icons/home-lg-alt.svg";
import users from "assets/images/icons/users-class.svg";
import shortid from "shortid";
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import RouteEnum from "constants/routeEnum";
import BoardAdd from "../components/boardAdd";
import {getBoards} from "../helpers/action";
import isEmpty from "isEmpty";
import SvgIcons from "components/icons/svgIcons";
import {useEffect} from "react";
import AddBoardButton from "./AddBoardButton";

let secondaryData = [
  {
    BoardId: shortid.generate(),
    link: `${RouteEnum.TaskBoardTodo}`,
    BoardName: "My ToDo",
    IsRecurring: false,
  },
  // {
  //   BoardId: shortid.generate(),
  //   link: RouteEnum.TaskBoardListLinks,
  //   BoardName: "Reoccuring Task",
  // },
];

interface sideInterface {
  title: string;
  data?: any;
  boardType: number;
  leftIcon: any;
}

function Sidebar(props) {
  const {showSideBar, setShowSideBar} = props;
  const [search, setSearch] = React.useState("");
  const [PersonalBoards, setPersonalBoards] = React.useState([]);
  const [TeamBoards, setTeamBoards] = React.useState([]);
  const {boards, board, recurringBoards} = useSelector((state: any) => state.taskReducer);
  //  const {board} = useSelector((state: any) => state.taskReducer);
  // const recurringBoards = useSelector((state: any) => state.taskReducer.recurringBoards);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getBoards(""));
  }, []);

  React.useEffect(() => {
    if (!isEmpty(boards)) {
      const finalBoards = [...secondaryData, ...boards.PersonalBoards];
      setPersonalBoards(finalBoards);
      setTeamBoards(boards.TeamBoards);
    }
  }, [boards, recurringBoards]);

  const onChange = (e) => {
    setSearch(e.target.value);
    dispatch(getBoards(e.target.value));
  };
  return (
    <div
      className={classnames("tasks-sidebar", {
        "tasks-sidebar-hide": !showSideBar,
      })}
    >
      {!(board && board.IsArchived) && (
        <span
          className={classnames({
            "btn-collapse": true,
            "btn-collapse-hide": !showSideBar,
          })}
          onClick={() => setShowSideBar(!showSideBar)}
        >
          <SvgIcons name="angleLeft" color="#3a3d43" />
        </span>
      )}
      <div
        className={classnames("tasks-sidebar-container", {
          "tasks-sidebar-container-hide": !showSideBar,
        })}
      >
        <NavLink to={RouteEnum.TaskBoardListLinks}>
          <h3 className="tasks-sidebar__title">Boards</h3>
        </NavLink>

        <div className="tasks-sidebar__search">
          <Icon justSVG name="Search" />
          <input
            type="text"
            value={search}
            onChange={(e) => onChange(e)}
            placeholder="Filter boards"
          />
        </div>
        <SidebarComp
          boardType={1}
          title="My Board"
          data={PersonalBoards}
          leftIcon={home}
        />
        <SidebarComp
          boardType={2}
          title="Team Board"
          data={TeamBoards}
          leftIcon={users}
        />
      </div>
    </div>
  );
}

const SidebarComp = ({title, leftIcon, data, boardType}: sideInterface) => {
  const [active, setActive] = React.useState("");

  return (
    <div className="tasks-component">
      <div className="tasks-component__primary">
        <div className="tasks-component__primary-icon">
          <img src={leftIcon} alt="Icon" />
        </div>
        <div className="tasks-component__primary-title">{title}</div>
        {boardType === 1 && <AddBoardButton boardType={boardType} />}
      </div>
      <div className="tasks-component__secondary">
        {data &&
          data.map((item: any, index: any) => {
            const secondClass = classnames({
              "tasks-component__secondary-item": true,
              active: item.BoardId === active,
            });

            return (
              <NavLink
                key={index}
                to={
                  item.link
                    ? item.link
                    : `${RouteEnum.TaskBoardSingleLink}${item.BoardId}`
                }
                className="tasks-component__secondary-item"
                activeClassName="active"
              >
                <div className="tasks-component__secondary-icon">
                  <Icon justSVG name="List" />
                </div>
                <div className="tasks-component__secondary-title">{item.BoardName}</div>
              </NavLink>
              // <div key={item.id} onClick={()=> setActive(item.id)} className={secondClass}>
              //     <div className="tasks-component__secondary-icon">
              //         <Icon justSVG name="List" />
              //     </div>
              //     <div className="tasks-component__secondary-title">
              //         {item.title}
              //     </div>
              // </div>
            );
          })}
      </div>
    </div>
  );
};

const SideBarAddBoard = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)} className="tasks-component__primary-icon">
        <Icon justSVG name="Add" />
      </div>
      {open && (
        <BoardAdd
          boardType={props.boardType}
          onModalClose={() => setOpen(false)}
          open={open}
        />
      )}
    </>
  );
};

export default Sidebar;
