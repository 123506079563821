import React, {Component} from "react";
import Pagination from "components/common/pagination";
import LegalClientReactTable from "./legalClientReactTable";

export class LegalClientTable extends Component {
  constructor(props) {
    super(props);
    this.widths = {
      clientName: 280,
      type: 130,
      activeProject: 180,
      blank1: 220,
      blank2: 250,
      expand: 30,
    };

    // this.sortingOptions = () =>
    //   SortSettingsModel({
    //     columns: [
    //       { field: "ClientName", direction: "Ascending" },
    //       { field: "TypeName", direction: "Ascending" },
    //       { field: "ActiveProjectCount", direction: "Ascending" },
    //     ],
    //   });

    this.row = [];
  }
  componentDidUpdate(prevProps) {
    const ele = document.querySelector(".e-headercell.e-mousepointer");
    setTimeout(() => {
      if (ele) {
        ele.click();
      }
    }, [500]);
  }

  render() {
    const {data, handleRowChange, handleIndexChange, pageIndex, rowsPerPage} = this.props;

    // let element = document.getElementById('grid');
    // let height=element ? parseInt(element.offsetHeight)+40:0;
    // console.log(height)

    return (
      <div className="default-table">
        <div className="pending-settlement-grid">
          <LegalClientReactTable dataSource={data} {...this.props} />
          {data?.length > 0 && (
            <Pagination
              handleRowChange={handleRowChange}
              handleIndexChange={handleIndexChange}
              pageIndex={pageIndex}
              rowsPerPage={rowsPerPage}
              total={data[0].TotalRows}
            />
          )}
        </div>
      </div>
    );
  }
}

export default LegalClientTable;
