import React from "react";
import FormGroup from "components/form";
import Button from "components/button";
import {connect} from "react-redux";
import isEmpty from "isEmpty";
import {InputValidator} from "helpers/inputValidator";
import {
  AddMessageTemplate,
  GetAllMessageTemplate,
  UpdateMessageTemplate,
  ClearSaveMessageTemplate,
} from "../messageTemplateAction";
import "../style.scss";

const initialState = {
  date: "",
  open: false,
  Id: 0,
  Name: null,
  Message: null,
  openNewTemplate: false,
  templateLists: null,
  errors: null,
  editView: false,
  editViewData: {},
};
interface IAppProps {
  AddMessageTemplate: any;
  GetAllMessageTemplate: any;
  ClearSaveMessageTemplate: any;
  UpdateMessageTemplate: any;
  showForm: any;
  closeForm: any;
  editViewData?: any;
  editView: any;
}

interface State {
  date: any;
  open: any;
  Name: any;
  Message: any;
  openNewTemplate: boolean;
  templateLists: any;
  errors?: any;
  editView?: boolean;
}

class MessageTemplateForm extends React.Component<IAppProps, State> {
  state = initialState;

  componentDidMount = async () => {
    const {editView, editViewData} = this.props;
    this.props.ClearSaveMessageTemplate();
    if (editView) {
      this.setState({
        ...editViewData,
        editView: true,
      });
    }
  };

  handleFieldChange = (name, value) => {
    this.setState({[name]: value} as Pick<State, keyof State>, () => {
      this.updateErrors();
    });
  };

  updateErrors = async () => {
    const {errors} = this.state;
    if (!isEmpty(errors)) {
      let errors = await InputValidator(document, this.state);
      this.setState({errors});
    }
  };

  handleFormSubmit = async () => {
    let {Id, Message, Name, editView} = this.state;

    let errors = await InputValidator(document, this.state);

    if (!isEmpty(errors)) {
      this.setState({errors});
      return;
    }

    let templateData = {
      Id,
      Name,
      Message,
    };

    if (editView) {
      await this.props.UpdateMessageTemplate(templateData, this.props.closeForm);
      this.props.GetAllMessageTemplate();
    } else {
      await this.props.AddMessageTemplate(templateData, this.props.closeForm);
      this.props.GetAllMessageTemplate();
    }
  };

  render() {
    const {editView, errors, Message, Name} = this.state;

    return (
      <div className="salaryTemplate-form">
        <div className="salaryTemplate-form__header ml-12">Message Template</div>
        <div className="salaryTemplate-form__body">
          <div className="form-row">
            <FormGroup
              autoFocus
              name="Name"
              value={Name}
              onChange={this.handleFieldChange}
              label="Name"
              error={errors?.Name}
              width="100%"
              validators={["required"]}
            />
          </div>
          <div className="form-row">
            <FormGroup
              formName="texteditor"
              value={Message}
              toolbarId={"welcomeMessage"}
              placeholder={"Write a message"}
              name="Message"
              onChange={(name, value) => {
                this.handleFieldChange(name, value);
              }}
              error={errors && errors.Message}
              width="100%"
            />
          </div>
        </div>
        <div className="salaryTemplate-form__footer flex justify-center">
          <Button bg="highlight" title="Cancel" onClick={this.props.closeForm} />
          <Button
            bg="primary"
            title={editView ? "Update" : "Save"}
            onClick={() => this.handleFormSubmit()}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  AddMessageTemplate,
  GetAllMessageTemplate,
  UpdateMessageTemplate,
  ClearSaveMessageTemplate,
};

export default connect(null, mapDispatchToProps)(MessageTemplateForm);
